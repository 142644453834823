import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { statusOrcamento } from 'src/core/consts/statusSolicitacao'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import {
  ListagemQuery,
  ListagemResponse,
  ObjetoDescricaoGenerico,
  ResumoMedicoSecretariaResponse
} from 'src/types'
import { useDebouncedCallback } from 'use-debounce/lib'
import useAuth from './useAuth'

export interface IBuscarDadosListagem<T, F> {
  (query: F, primeiraBusca?: Boolean): Promise<
    AxiosResponse<ListagemResponse<T>>
  >
}

function useListagem<T, F>(
  qtdPorPagina = 10,
  fnBuscarDados: IBuscarDadosListagem<T, F>,
  fnBuscarStatus?: () => Promise<AxiosResponse<ObjetoDescricaoGenerico[]>>,
  fnBuscarMedicos?: () => Promise<
    AxiosResponse<ResumoMedicoSecretariaResponse[]>
  >
) {
  const [loadingPagina, setLoadingPagina] = useState(false)
  const [loading, setLoading] = useState(true)
  const [termoPesquisa, setTermoPesquisa] = useState('')
  const [pagina, setPagina] = useState(1)
  const [qtdPaginas, setQtdPaginas] = useState(1)
  const [dados, setDados] = useState<T[]>([])
  const [qtdAllDados, setQtdAllDados] = useState(0)
  const [allDados, setAllDados] = useState<T[]>([])

  //Filtro status
  const [filtroStatus, setFiltroStatus] = useState<string>(' ')
  const [loadingFiltroStatus, setLoadingFiltroStatus] = useState(true)
  const [listaStatus, setListaStatus] = useState<ObjetoDescricaoGenerico[]>([])

  //Filtro tag
  const [filtroTag, setFiltroTag] = useState<string>(' ')

  //Filtro tag
  const [filtroResponsavelId, setFiltroResponsavelId] = useState<string>(' ')

  // Filtro status proposta
  const [filtroStatusProposta, setFiltroStatusProposta] = useState('')

  const [filtroCriadoPorHospital, setFiltroCriadoPorHospital] = useState('0')

  // Filtro Geral
  const [filtroGeral, setFiltroGeral] = useState<F>()
  const [primeiraBusca, setPrimeiraBusca] = useState(true)

  //Filtro UF
  const [filtroUF, setFiltroUF] = useState<F>()

  //Filtro Medico
  const [filtroMedico, setFiltroMedico] = useState<string>(' ')
  const [listaMedicos, setListaMedicos] = useState<
    ResumoMedicoSecretariaResponse[]
  >([])
  const [loadingMedicoFiltro, setLoadingMedicoFiltro] = useState(true)
  const { signOut, user, perfilAtual } = useAuth()
  const [debouncedTermoPesquisa, setDebouncedTermoPesquisa] = useState('')
  const debounced = useDebouncedCallback(() => {
    setDebouncedTermoPesquisa(termoPesquisa)
    carregarListagem({ resetarPaginacao: true })
  }, 1200)

  //DataInicio e DataFim
  const [filtroDataInicio, setFiltroDataInicio] = useState('')
  const [filtroDataFim, setFiltroDataFim] = useState('')

  //LISTAGEM
  const [listagemAsync, setListagemAsync] = useState(true)

  const handleTermoPesquisa = (
    event: React.ChangeEvent<HTMLInputElement>,
    clear?: boolean
  ) => {
    setLoading(true)
    setTermoPesquisa(clear ? '' : event.target.value)
    if (!clear) {
      debounced.callback()
    }
  }

  const handleLimparFiltros = () => {
    setLoading(true)
    setTermoPesquisa('')
    setFiltroStatus(' ')
    setFiltroTag(' ')
    setFiltroStatusProposta(' ')
    setFiltroResponsavelId(' ')
    setFiltroGeral(null)
    debounced.callback()
  }

  const handlePesquisarDados = () => {
    setLoading(true)
    debounced.callback()
  }

  const handleSetTermoPesquisarCodigo = (codigo: any) => {
    setTermoPesquisa(codigo)
  }

  const handleSetTermoPesquisar = (
    event: React.ChangeEvent<HTMLInputElement>,
    clear?: boolean
  ) => {
    setTermoPesquisa(
      clear ? '' : event.target.value ? event.target.value : termoPesquisa
    )
  }

  const handleCustomPagina = (page, event?) => {
    setLoading(true)
    setPagina(page)
  }

  function handleProxPagina() {
    if (qtdAllDados > allDados?.length) {
      setPagina(oldState => oldState + 1)
    }
  }

  function handlePrevPagina() {
    if (pagina > 1) {
      setPagina(oldState => oldState - 1)
    }
  }

  const carregarListagem = async ({
    resetarPaginacao,
    status,
    tag,
    responsavelId,
    statusProposta,
    newDados,
    criadoPorHospital,
    filtro,
    medicoId,
    dataInicio,
    dataFim
  }: {
    resetarPaginacao: Boolean
    status?: string
    medicoId?: string
    tag?: string
    responsavelId?: string
    statusProposta?: string
    newDados?: any
    criadoPorHospital?: '0' | '1' | '2'
    filtro?: F
    dataInicio?: string
    dataFim?: string
  }) => {
    if (newDados) {
      return setDados(newDados)
    }
    try {
      if (resetarPaginacao === true) {
        setLoadingPagina(true)
      }
      setLoading(true)
      setFiltroStatus(status || filtroStatus || ' ')
      setFiltroMedico(medicoId || filtroMedico || ' ')
      setFiltroTag(tag || filtroTag || ' ')
      setFiltroResponsavelId(responsavelId || filtroResponsavelId || ' ')
      setFiltroStatusProposta(statusProposta || filtroStatusProposta || ' ')
      setFiltroDataFim(dataFim)
      setFiltroDataInicio(dataInicio)

      const filtroFinal = {
        ...filtro,
        pagina,
        qtdPorPagina,
        termoPesquisa: termoPesquisa === undefined ? '' : termoPesquisa,
        status: status || filtroStatus,
        medicoId: medicoId || filtroMedico,
        tag: tag || filtroTag,
        responsavelId: responsavelId || filtroResponsavelId,
        statusProposta: statusProposta || filtroStatusProposta,
        criadoPorHospital: criadoPorHospital || filtroCriadoPorHospital,
        perfilAtual: perfilAtual,
        dataFim: dataFim === undefined ? '' : dataFim,
        dataInicio: dataInicio === undefined ? '' : dataInicio
      }

      const response = await fnBuscarDados(filtroFinal, primeiraBusca)

      setQtdAllDados(response.data.qtdTotal)
      setAllDados(oldState =>
        resetarPaginacao
          ? response.data.items
          : [...oldState, ...response.data.items]
      )
      setDados(response.data.items)
      setQtdPaginas(
        Math.ceil(Math.max(1, response.data.qtdTotal) / qtdPorPagina)
      )

      if (resetarPaginacao) {
        setPagina(1)
      }

      setPrimeiraBusca(false)
      setLoading(false)
      setLoadingPagina(false)
    } catch (e) {
      showNotificationErrorAPI(e)
      setLoading(false)
      setLoadingPagina(false)
    }
  }

  const carregarListagemAsync = async ({
    resetarPaginacao,
    status,
    tag,
    responsavelId,
    statusProposta,
    newDados,
    criadoPorHospital,
    filtro,
    medicoId,
    dataInicio,
    dataFim
  }: {
    resetarPaginacao: Boolean
    status?: string
    medicoId?: string
    tag?: string
    responsavelId?: string
    statusProposta?: string
    newDados?: any
    criadoPorHospital?: '0' | '1' | '2'
    filtro?: F
    dataInicio?: string
    dataFim?: string
  }) => {
    // if (newDados) {
    //   return setDados(newDados)
    // }
    try {
      // setLoading(true)

      if (resetarPaginacao) {
        setPagina(1)
      }
      setFiltroStatus(status || filtroStatus || ' ')
      setFiltroMedico(medicoId || filtroMedico || ' ')
      setFiltroTag(tag || filtroTag || ' ')
      setFiltroResponsavelId(responsavelId || filtroResponsavelId || ' ')
      setFiltroStatusProposta(statusProposta || filtroStatusProposta || ' ')
      setFiltroDataFim(dataFim)
      setFiltroDataInicio(dataInicio)
      setLoading(false)
      setTimeout(() => {
        setListagemAsync(true)
      }, 200)
    } catch (e) {
      showNotificationErrorAPI(e)
      setLoading(false)
    }
  }

  async function obterListagemAsync(
    resetarPaginacao = true,
    filtro: F = {} as F
  ) {
    setLoading(true)
    const filtroFinal = {
      ...filtro,
      pagina,
      qtdPorPagina,
      termoPesquisa: termoPesquisa === undefined ? '' : termoPesquisa,
      status: status || filtroStatus,
      perfilAtual: perfilAtual
    }
    const response = await fnBuscarDados(filtroFinal, primeiraBusca)

    setQtdAllDados(response.data.qtdTotal)
    setAllDados(response.data.items)
    setDados(response.data.items)
    setQtdPaginas(Math.ceil(Math.max(1, response.data.qtdTotal) / qtdPorPagina))

    if (resetarPaginacao) {
      setPagina(1)
    }

    setPrimeiraBusca(false)
    setLoading(false)
    setListagemAsync(false)
  }

  useEffect(() => {
    if (listagemAsync && !loading) {
      obterListagemAsync()
    }
    setTimeout(() => {
      setListagemAsync(false)
    }, 2000)
  }, [listagemAsync])

  const carregarListaStatus = async () => {
    if (fnBuscarStatus === undefined) {
      return
    }
    try {
      setLoadingFiltroStatus(true)

      const response = await fnBuscarStatus()
      if (perfilAtual === SynPerfis.PARCEIRO) {
        var statusFiltrado = response.data.filter(
          value => value.id !== statusOrcamento.PROCESSO_CANCELADO.Id
        )
        setListaStatus(statusFiltrado)
      } else {
        setListaStatus(response.data)
      }

      setLoadingFiltroStatus(false)
    } catch (e) {
      showNotificationErrorAPI(e)
      setLoadingFiltroStatus(false)
    }
  }

  const carregarListaMedicos = async () => {
    if (fnBuscarMedicos === undefined) {
      return
    }
    try {
      setLoadingMedicoFiltro(true)

      const response = await fnBuscarMedicos()
      setListaMedicos(response.data)

      setLoadingMedicoFiltro(false)
    } catch (e) {
      showNotificationErrorAPI(e)
      setLoadingMedicoFiltro(false)
    }
  }

  const handleFiltroStatus = (event: any) => {
    const status = event.target.value
    setFiltroStatus(status)
    carregarListagem({
      resetarPaginacao: true,
      status: status,
      dataInicio: filtroDataInicio,
      dataFim: filtroDataFim
    })
  }

  const handleFiltroData = (dataInicio: string, dataFim: string) => {
    setFiltroDataInicio(dataInicio)
    setFiltroDataFim(dataFim)
    carregarListagem({
      resetarPaginacao: true,
      dataInicio: dataInicio,
      dataFim: dataFim
    })
  }

  const handleFiltroMedico = (event: any) => {
    const medico = event.target.value
    setFiltroMedico(medico)
    carregarListagem({
      resetarPaginacao: true,
      medicoId: medico,
      dataInicio: filtroDataInicio,
      dataFim: filtroDataFim
    })
  }

  const handleFiltroTag = (event: any) => {
    const tag = event.target.value
    setFiltroTag(tag)
    carregarListagem({
      resetarPaginacao: true,
      status: filtroStatus,
      tag: tag,
      dataInicio: filtroDataInicio,
      dataFim: filtroDataFim
    })
  }

  const handleFiltroResponsavel = (event: any) => {
    const responsavelId = event.target.value
    setFiltroResponsavelId(responsavelId)
    carregarListagem({
      resetarPaginacao: true,
      status: filtroStatus,
      tag: filtroTag,
      responsavelId,
      dataInicio: filtroDataInicio,
      dataFim: filtroDataFim
    })
  }

  const handleFiltroStatusProposta = (event: any) => {
    const statusProposta = event.target.value
    setFiltroStatus(statusProposta)
    carregarListagem({
      resetarPaginacao: true,
      status: filtroStatus,
      tag: filtroTag,
      statusProposta,
      dataInicio: filtroDataInicio,
      dataFim: filtroDataFim
    })
  }

  function handleFiltroCriadoPorHospital(value) {
    switch (value) {
      case '0':
        setFiltroCriadoPorHospital('0')
        carregarListagem({
          resetarPaginacao: true,
          criadoPorHospital: '0',
          dataInicio: filtroDataInicio,
          dataFim: filtroDataFim
        })
        break
      case '1':
        setFiltroCriadoPorHospital('1')
        carregarListagem({
          resetarPaginacao: true,
          criadoPorHospital: '1',
          dataInicio: filtroDataInicio,
          dataFim: filtroDataFim
        })
        break
      case '2':
        setFiltroCriadoPorHospital('2')
        carregarListagem({
          resetarPaginacao: true,
          criadoPorHospital: '2',
          dataInicio: filtroDataInicio,
          dataFim: filtroDataFim
        })
        break
    }
  }

  function atualizarItemListagem(id, novoValor, key = 'id') {
    const novosDados = allDados.map(item =>
      item[key] === id ? novoValor : item
    )
    setAllDados(novosDados as any)
  }

  function removerItemListagem(id, key = 'id') {
    const novosDados = allDados.filter(item => item[key] !== id)
    setAllDados(novosDados as any)
    setQtdAllDados(qtdAllDados - 1)
  }

  useEffect(() => {
    carregarListagem({
      resetarPaginacao: false,
      filtro: filtroGeral,
      dataInicio: filtroDataInicio,
      dataFim: filtroDataFim
    })
    carregarListaStatus()
    carregarListaMedicos()
  }, [pagina])

  useEffect(() => {
    if (!primeiraBusca) {
      carregarListagem({
        filtro: filtroGeral,
        resetarPaginacao: true,
        dataInicio: filtroDataInicio,
        dataFim: filtroDataFim
      })
    }
  }, [filtroGeral])

  function obterDadosUnicos(dados: T[]) {
    return dados
    const myList = dados
    const unique = [...new Set(myList)]

    return unique
  }
  return {
    loading,
    termoPesquisa,
    handleTermoPesquisa,
    pagina,
    handleCustomPagina,
    qtdPaginas,
    dados: obterDadosUnicos(dados),
    nenhumDado: !loading && dados.length === 0 && allDados.length === 0,
    // && !debouncedTermoPesquisa,
    //&& filtroStatus !== ' ',
    nenhumResultado:
      !loading &&
      dados.length === 0 &&
      (debouncedTermoPesquisa || filtroStatus !== ' '),
    dadosCarregados: !loading && dados.length > 0,
    allDadosCarregados: allDados.length > 0,
    carregarListagem,
    carregarListagemAsync,
    filtroStatus,
    loadingFiltroStatus,
    listaStatus,
    handleFiltroStatus,
    handleFiltroTag,
    handleFiltroResponsavel,
    filtroTag,
    filtroResponsavelId,
    filtroStatusProposta,
    handleFiltroStatusProposta,
    handleProxPagina,
    handlePrevPagina,
    qtdAllDados,
    allDados: obterDadosUnicos(allDados),
    filtroCriadoPorHospital,
    handleFiltroCriadoPorHospital,
    setFiltroGeral,
    filtroGeral,
    listaMedicos,
    filtroMedico,
    loadingMedicoFiltro,
    handleFiltroMedico,
    atualizarItemListagem,
    handleSetTermoPesquisar,
    handleSetTermoPesquisarCodigo,
    handlePesquisarDados,
    handleFiltroData,
    handleLimparFiltros,
    removerItemListagem,
    loadingPagina
  }
}

export default useListagem
