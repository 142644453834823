import { yupResolver } from '@hookform/resolvers/yup';
import { Box, DialogProps, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'src/components/ui/Button';
import yup from 'src/components/yupCustom';
import { onlyNumber, verificarCNPJ, verificarCPF } from 'src/core/helpers/common';
import moment from 'moment';
import { ObterCep } from 'src/api/cep/cep';
import DadosBasicos from '../basico/dadosBasicos';
import Address from '../address/address';
import UBox from 'src/components/ui/Ubox';
import { IFormsCadastro } from '../interface';
import DadosBancarios from '../dados-bancarios/dadosBancarios';
import HttpsIcon from '@material-ui/icons/Https'
import ModalLiberarAcesso from 'src/components/modals/ModalSenhaMaster';

const useStyles = makeStyles(theme => ({
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    margin: theme.spacing(1, 0, 0)
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  noMargin: {
    margin: theme.spacing(0)
  }
}));

const schema = yup.object().shape({
  nomeBanco: yup.string().max(30).required().label('Titular da conta'),
  cpfCnpj: yup
    .string()
    .test('cnpj-teste', 'Documento inválido', value => {
      if (!value) return false;
      const documento = onlyNumber(value);
      return documento.length > 11 ? verificarCNPJ(documento) : verificarCPF(documento);
    })
    .required()
    .label('Documento'),
  codigoBanco: yup.string().required().label('Código do banco'),
  agencia: yup.string().max(4).required().label('Agência'),
  digitoAgencia: yup.string().max(1).label('Dígito da agência'),
  numeroConta: yup.string().max(13).required().label('Número da conta'),
  digitoConta: yup.string().max(2).required().label('Dígito da conta'),
  tipoConta: yup.string().label('Tipo de conta'),
  name: yup.string().label('Nome obrigatório'),
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
  document: yup
    .string()
    .test('cnpj-teste', 'Documento inválido', value => {
      if (!value) return false;
      const documento = onlyNumber(value);
      return documento.length > 11 ? verificarCNPJ(documento) : verificarCPF(documento);
    })
    .required()
    .label('Documento'),
  birthdate: yup
    .string()
    .required()
    .test('nas', 'Por favor escolha uma data valida', value => {
      if (value.replace(' ', '').length < 10) return false;
      return moment(value, 'DD/MM/YYYY').isBetween('1922-12-31', moment(), 'day');
    })
    .label('Data de nascimento'),
  monthlyIncome: yup
    .number()
    .required()
    .label('Valor da renda')
    .test('renda', 'O valor da renda não pode ser R$0,00', value => Number(value) !== 0),
  professionalOccupation: yup.string().required('Ocupação profissional obrigatória'),
  phoneNumbers: yup
    .string()
    .required()
    .test('valida-telefone', 'O telefone é inválido', value => onlyNumber(value).length >= 10)
    .label('Telefone'),
  cep: yup.string().required('CEP obrigatório'),
  street: yup.string().required('Rua obrigatória'),
  complementary: yup.string().required('Complemento obrigatório'),
  streetNumber: yup.string().required('Número obrigatório'),
  neighborhood: yup.string().required('Bairro obrigatório'),
  city: yup.string().required('Cidade obrigatória'),
  state: yup.string().required('Estado obrigatório'),
  referencePoint: yup.string().required('Ponto de referência obrigatório')
});

interface Props extends DialogProps {
  onClose?: () => void;
  dadosBancariosUsuario: (data: IFormsCadastro) => void;
  ignorarSubmit?: boolean;
  showNext?: () => void;
  showBack?: () => void;
  editarDadosBancarios?: any;
}

export default function FormPF(props: Props) {
  const classes = useStyles();
  const { dadosBancariosUsuario, ignorarSubmit, showBack, showNext } = props;
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [viewButton, setViewButton] = useState(true);
  const [podeSalvar, setPodeSalvar] = useState(false)
  const [showModal, setShowModal] = useState(false)

  console.log('props.editarDadosBancarios', props.editarDadosBancarios)

  const { register, errors, control, setValue, watch, trigger, handleSubmit } = useForm<IFormsCadastro>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      nomeBanco: props?.editarDadosBancarios?.nomeBanco || '',
      cpfCnpj: props?.editarDadosBancarios?.cpfCnpj || '',
      codigoBanco: props?.editarDadosBancarios?.codigoBanco || '',
      agencia: props?.editarDadosBancarios?.agencia || '',
      digitoAgencia: props?.editarDadosBancarios?.digitoAgencia || '',
      numeroConta: props?.editarDadosBancarios?.numeroConta || '',
      digitoConta: props?.editarDadosBancarios?.digitoConta || '',
      tipoConta: props?.editarDadosBancarios?.tipoConta || '',
      name: props?.editarDadosBancarios?.registerInformation?.name || '',
      email: props?.editarDadosBancarios?.registerInformation?.email || '',
      document: props?.editarDadosBancarios?.registerInformation?.document || '',
      birthdate: props?.editarDadosBancarios?.registerInformation?.birthdate || '',
      monthlyIncome: props?.editarDadosBancarios?.registerInformation?.monthly_income || null,
      professionalOccupation: props?.editarDadosBancarios?.registerInformation?.professional_occupation || '',
      phoneNumbers: props?.editarDadosBancarios?.registerInformation?.phone_numbers[0].ddd + props?.editarDadosBancarios?.registerInformation?.phone_numbers[0].number || '',
      cep: props?.editarDadosBancarios?.registerInformation?.address?.zip_code || '',
      street: props?.editarDadosBancarios?.registerInformation?.address?.street || '',
      complementary: props?.editarDadosBancarios?.registerInformation?.address?.complementary || '',
      streetNumber: props?.editarDadosBancarios?.registerInformation?.address?.street_number || '',
      neighborhood: props?.editarDadosBancarios?.registerInformation?.address?.neighborhood || '',
      city: props?.editarDadosBancarios?.registerInformation?.address?.city || '',
      state: props?.editarDadosBancarios?.registerInformation?.address?.state || '',
      referencePoint: props?.editarDadosBancarios?.registerInformation?.address?.reference_point || ''
    }
  });

  const command = watch();

  const onSubmit = async (data: IFormsCadastro) => {
    setLoading(true);
    if (ignorarSubmit) {
      dadosBancariosUsuario(data);
      setViewButton(true);
      showNext();
    } else {
      dadosBancariosUsuario(data);
    }
    setTimeout(() => setLoading(false), 2000);
  };

  const handleNext = async () => {
    let isValid;
    switch (step) {
      case 0:
        isValid = await trigger([
          'nomeBanco',
          'cpfCnpj',
          'codigoBanco',
          'agencia',
          'digitoAgencia',
          'numeroConta',
          'digitoConta',
          'tipoConta'
        ]);
        if (isValid) {
          setStep(1);
        }
        break;
      case 1:
        isValid = await trigger([
          'name',
          'email',
          'document',
          'birthdate',
          'monthlyIncome',
          'professionalOccupation',
          'phoneNumbers'
        ]);
        if (isValid) {
          setStep(2);
        }
        // setStep(2);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleDesbloquear = () => {
    setShowModal(true)
  }

  async function buscarCep(cep) {
    try {
      const cepNumeros = onlyNumber(String(cep));
      if (cepNumeros.length === 8) {
        setLoadingCep(true);
        const { data } = await ObterCep(cepNumeros);
        if (data?.localidade) {
          setValue('city', data.localidade, { shouldValidate: true });
        }
        if (data?.logradouro) {
          setValue('street', data.logradouro, { shouldValidate: true });
        }
        if (data?.uf) {
          setValue('state', data.uf.toUpperCase(), { shouldValidate: true });
        }
        if (data?.bairro) {
          setValue('neighborhood', data.bairro.toUpperCase(), { shouldValidate: true });
        }
        setLoadingCep(false);
      }
    } catch (error) {
      setLoadingCep(false);
    }
  }

  useEffect(() => {
    buscarCep(command.cep);
  }, [command.cep]);

  const handleItemValue = (name, item) => {
    setValue(name, item, { shouldValidate: true });
  };

  useEffect(() => {
    register('monthlyIncome');
    register('nomeBanco');
    register('cpfCnpj');
    register('codigoBanco');
    register('agencia');
    register('digitoAgencia');
    register('numeroConta');
    register('digitoConta');
    register('tipoConta');
  }, [register]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleItemValue('tipoConta', event.target.value);
  };

  useEffect(() => {
    if (command.cpfCnpj) {
      setValue('document', command.cpfCnpj, { shouldValidate: true })
    }
  }, [command.cpfCnpj])

  useEffect(() => {
    if (command.nomeBanco) {
      setValue('name', command.nomeBanco, { shouldValidate: true })
    }
  }, [command.nomeBanco])

  function dadosModal() {
    return {
      copy:
        'A funcionalidade de editar dados bancário exige autorização master',
      titulo: 'Acesso Restrito',
      open: showModal,
      handleClose: () => setShowModal(false),
      onConfirm: ehConfirm => setPodeSalvar(ehConfirm)
    }
  }

  return (
    <>
      <ModalLiberarAcesso {...dadosModal()} />
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form} noValidate>
        <Grid container spacing={1} direction="row">
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" alignItems="center" marginY={2}>
              <span style={{ fontSize: '20px', fontWeight: 500, color: '#455890' }}>
                {step === 0 ? 'Dados Bancários' : step === 1 ? 'Dados pessoais' : 'Endereço'}
              </span>
              <span style={{ fontSize: '14px', fontWeight: 500, color: '#a4a5a8' }}>
                Passo {step + 1} de 3
              </span>
            </Box>
          </Grid>

          <UBox hidden={step !== 0}>
            <>
              <DadosBancarios
                control={control}
                errors={errors}
                register={register}
                command={command}
                isPJ={false}
                handleItemValue={handleItemValue}
                handleRadioChange={handleRadioChange}
                editarDadosBancarios={props.editarDadosBancarios}
              />
            </>
          </UBox>

          <UBox hidden={step !== 1}>
            <>
              <DadosBasicos
                control={control}
                errors={errors}
                register={register}
                command={command}
                handleItemValue={handleItemValue}
              />
            </>
          </UBox>

          <UBox hidden={step !== 2}>
            <>
              <Address
                control={control}
                errors={errors}
                register={register}
                loadingCep={loadingCep}
                type="normal"
              />
            </>
          </UBox>
        </Grid>

        {viewButton && (
          <Box style={{ display: 'flex', gap: '12px', marginTop: '16px' }}>
            {step !== 0 && (
              <Grid item xs={12} sm={12}>
                <Button
                  disabled={loading}
                  type="button"
                  fullWidth
                  variant="contained"
                  color="default"
                  onClick={handleBack}
                >
                  Voltar
                </Button>
              </Grid>
            )}
            {ignorarSubmit && (
              <>
                {step === 0 && (
                  <Grid item xs={12} sm={12}>
                    <Button
                      disabled={loading}
                      type="button"
                      fullWidth
                      variant="contained"
                      color="default"
                      onClick={showBack}
                    >
                      Voltar
                    </Button>
                  </Grid>
                )}
              </>
            )}
            {ignorarSubmit && (
              <>
                {step !== 2 ? (
                  <Grid item xs={12} sm={12}>
                    <Button
                      disabled={loading}
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      Continuar
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12}>
                    <Button
                      loading={loading}
                      disabled={loading}
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Continuar
                    </Button>
                  </Grid>
                )}
              </>
            )}

            {!ignorarSubmit && (
              <>
                {step !== 2 ? (
                  <Grid item xs={12} sm={12}>
                    {!!props?.editarDadosBancarios ? (
                      <>
                        {podeSalvar ? (
                          <Button
                            disabled={loading}
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                          >
                            Continuar
                          </Button>) : (
                          <Button
                            variant="contained"
                            color="secondary"
                            type="button"
                            fullWidth
                            onClick={handleDesbloquear}
                            startIcon={<HttpsIcon />}
                          >
                            Desbloquear
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button
                        disabled={loading}
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        Continuar
                      </Button>
                    )}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12}>
                    <Button
                      loading={loading}
                      disabled={loading}
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Salvar
                    </Button>
                  </Grid>
                )}
              </>
            )}
          </Box>
        )}
      </form>
    </>
  );
}
