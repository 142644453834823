import { useContext } from 'react'

import AtividadeContext from '../contexts/Atividade/context'

const useAtividade = () => {
  const auth = useContext(AtividadeContext)
  return auth
}

export default useAtividade
