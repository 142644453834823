import styled from 'styled-components'

import Button from 'src/components/ui/Button'
import { LinearProgress } from '@material-ui/core'
import UBox from 'src/components/ui/Ubox'

export const StyledButton = styled(Button)`
  border-radius: 4px;
  background: var(--main-blue, #605dec);
  background-color: var(--main-blue, #605dec);

  &:hover {
    background-color: var(--button-primary-bg-hover, #3d3ace);
  }

  &.verde {
    background: var(--main-light-success, #36bf76);
    background-color: var(--main-light-success, #36bf76);
  }

  & .MuiButton-label {
    color: #fff;
  }
`

export const StyledLinearProgress = styled(LinearProgress)`
  height: 10px;
`

export const StyledContent = styled(UBox)`
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px -3px 13px #ddd;
  padding: 8px 12px;
  margin: 16px 0 0;
  width: 100%;

  .MuiTypography-h6 {
    font-weight: 700;
    line-height: 1.4;
  }
`

export const StyledHeader = styled(UBox)`
  padding: 0px 16px;

  .MuiTypography-h6 {
    font-weight: 700;
    line-height: 1.4;
  }
`

export const StyledTipoProcedimento = styled(UBox)`
  display: flex;
  width: 100%;
  max-width: 340px;
  height: 52px;
  flex-shrink: 0;
  padding: 16px;

  font-weight: 700;
  margin-top: 12px;

  border-radius: 12px;
  border: 1px solid var(--stroke-grey, #ddd);
  // background: #fff;

  /* Elevation 4 */
  box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.12);

  &.ativo {
    background: #36bf76;
    color: #fff;
    border: 1px solid #36bf76;
  }
`

export const StyledParcela = styled(UBox)`
  display: flex;
  width: 100%;
  max-width: 340px;
  // height: 52px;
  flex-shrink: 0;
  padding: 16px;

  font-weight: 700;
  margin-top: 12px;

  border-radius: 12px;
  border: 1px solid var(--stroke-grey, #3f51b5);
  color: #3f51b5;

  & .MuiSvgIcon-root {
    fill: #3f51b5;
  }
  // background: #fff;

  /* Elevation 4 */
  box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.12);

  &.ativo {
    background: #36bf76;
    color: #fff;
    border: 1px solid #36bf76;

    & .MuiSvgIcon-root {
      fill: #fff;
    }
  }
`

export const StyledResumoSimulacao = styled(UBox)`
  small {
    font-weight: 600;
  }

  .MuiTypography-root.MuiTypography-body2 b,
  .MuiTypography-root.MuiTypography-subtitle1 {
    color: var(--main-blue, #605dec);
  }
`
