import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import { Button } from 'src/components/ui/Button'
import TextField from 'src/components/ui/TextField'
import { useQuery } from 'src/hooks/useQuery'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { obterEspecialidades } from 'src/api/dominio'
import { editarPreCadastro, obterDadosPreCadastro } from 'src/api/medico'
import {
  EditarCadastroResponse,
  ObjetoDescricaoGenerico,
  PreCadastroCommand
} from 'src/types'
import yup from '../../components/yupCustom'
import PasswordField from 'src/components/ui/PasswordField'
import { onlyNumber, verificarCPF } from 'src/core/helpers/common'
import Swal from 'sweetalert2'

const useStyles = makeStyles(theme => ({
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    margin: theme.spacing(1, 0, 0)
    // minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  noMargin: {
    margin: theme.spacing(0)
  }
}))

export interface IEditarDadosMedicoForm {
  nome: string
  crm: string
  ufCRM: string
  cro: string
  ufCRO: string
  cpf: string
  telefone: string
  email: string
  especialidades: ObjetoDescricaoGenerico[]
  senha: string
  observacoes: string
  confirmarSenha?: string
}

export function mapToForm(item: EditarCadastroResponse) {
  let orcamento: IEditarDadosMedicoForm = {
    nome: item.nome,
    crm: item.crm,
    cro: item.cro,
    cpf: item.cpf,
    telefone: item.telefone,
    email: item.email,
    senha: item.senha,
    especialidades: item.especialidades,
    observacoes: item.observacoes,
    confirmarSenha: item.senha,
    ufCRM: item.ufCRM,
    ufCRO: item.ufCRO
  }
  return orcamento
}

type Props = {
  fecharForm: () => void
}

export const FormMedico = (props: Props) => {
  const classes = useStyles()
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const query = useQuery()

  //#region Definicao de listas e loading
  const [loadingMedico, setLoadingMedico] = useState(true)
  const [especialidades, setEspecialidades] = useState<
    ObjetoDescricaoGenerico[]
  >([])

  const [loadingEspecialidades, setLoadingEspecialidades] = useState<boolean>(
    true
  )

  const schema = yup.object().shape({
    nome: yup.string().min(3).max(100).required().label('Nome'),
    telefone: yup
      .string()
      .required()
      .test('valida-telefone', 'O telefone é inválido', value => {
        if (onlyNumber(value).length < 10) return false

        return true
      })
      .label('Telefone'),
    email: yup.string().email().required().label('E-mail'),
    especialidades: yup.array().min(1).label('Especialidades'),
    //locaisAtendimento: yup.array().min(1).label('Locais de atendimento'),
    observacoes: yup.string().max(100).label('Observações'),
    senha: yup.string().max(50),
    confirmarSenha: yup
      .string()
      .oneOf([yup.ref('senha'), null], 'Senhas devem ser iguais'),
    cpf: yup
      .string()
      .test('cpf-teste', 'CPF inválido', value => {
        if (!value) return false

        return verificarCPF(onlyNumber(value))
      })
      .required()
      .label('CPF')
  })

  let defaultValues: IEditarDadosMedicoForm = {
    nome: '',
    crm: '',
    ufCRM: '',
    cro: '',
    ufCRO: '',
    cpf: '',
    telefone: '',
    email: '',
    especialidades: [],
    senha: '',
    observacoes: '',
    confirmarSenha: ''
  }

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    formState,
    handleSubmit,
    trigger
  } = useForm<IEditarDadosMedicoForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  const command = watch()

  useEffect(() => {
    register('nome')
    register('crm')
    register('ufCRM')
    register('cro')
    register('ufCRO')
    register('cpf')
    register('telefone')
    register('email')
    register('senha')
    register('observacoes')
    register('confirmarSenha')
    register('especialidades')
  }, [register])

  useEffect(() => {
    carregarEspecialidades()
    carregarDadosFormulario()
  }, [])

  const onSubmit = async (data: PreCadastroCommand) => {
    const result = await trigger()
    if (!result) return
    setLoading(true)
    try {
      await editarPreCadastro(data)
      window.scrollTo(0, 0)
      await Swal.fire({
        title: 'Parabéns!',
        text: 'Dados atualizado com sucesso',
        icon: 'success',
        confirmButtonText: 'Continuar'
      })
    } catch (error) {
      showNotificationErrorAPI(error)
    }

    setLoading(false)
  }

  const handleRegisterObjeto = (name, objetos: ObjetoDescricaoGenerico[]) => {
    setValue(name, objetos, { shouldValidate: true })
  }
  const carregarDadosFormulario = async () => {
    obterDadosPreCadastro()
      .then(response => {
        const medico = response.data
        const form = mapToForm(medico)
        for (const key in form) {
          let value = form[key]
          setValue(key, value, { shouldValidate: false })
        }
        setLoadingMedico(false)
      })
      .catch(e => {
        showNotificationErrorAPI(e)
        history.goBack()
      })
  }

  const carregarEspecialidades = async () => {
    return obterEspecialidades()
      .then(response => setEspecialidades(response.data))
      .finally(() => {
        setLoadingEspecialidades(false)
      })
  }
  return (
    <Box pb={5}>
      <CssBaseline />
      {/* <Grid container justify="space-between"> */}
      {/* <Grid> command: {JSON.stringify(command.telefone)}</Grid> */}
      {/* <Grid> command: {JSON.stringify(formulario)}</Grid> */}
      {/* </Grid> */}
      <Grid item xs={12} justify="flex-start">
        <Box>
          <Typography variant="h5">Editar meus dados</Typography>
        </Box>
      </Grid>
      <Box pb={5}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.form}
          noValidate={true}
        >
          <Grid container spacing={1} direction="row">
            <Grid item xs={12}>
              <Typography
                className={classes.subTitle}
                color="primary"
                display="block"
                variant="caption"
              >
                Dados pessoais
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                erro={errors}
                inputRef={register}
                InputLabelProps={{ shrink: true }}
                name="nome"
                label="Nome completo *"
                defaultValue=""
                InputProps={{
                  autoComplete: 'new-password'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                erro={errors}
                name="telefone"
                maskTelCel
                value={command.telefone}
                label="Telefone"
                type="tel"
                control={control}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                inputRef={register}
                erro={errors}
                name="email"
                label="E-mail"
                InputLabelProps={{ shrink: true }}
                defaultValue=""
                InputProps={{
                  autoComplete: 'new-password'
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes.subTitle}
                color="primary"
                display="block"
                variant="caption"
              >
                Dados médicos
              </Typography>
            </Grid>
            <Grid container spacing={1} className={classes.noMargin}>
              <Grid item xs={7} sm={4}>
                <TextField
                  required={true}
                  inputRef={register}
                  InputLabelProps={{ shrink: true }}
                  erro={errors}
                  name="crm"
                  label="CRM"
                  defaultValue=""
                  InputProps={{
                    autoComplete: 'new-password'
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <TextField
                  required={true}
                  inputRef={register}
                  InputLabelProps={{ shrink: true }}
                  erro={errors}
                  name="ufCRM"
                  label="UfCRM"
                  defaultValue=""
                  InputProps={{
                    autoComplete: 'new-password'
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={7} sm={4}>
                <TextField
                  required={true}
                  inputRef={register}
                  InputLabelProps={{ shrink: true }}
                  erro={errors}
                  name="cro"
                  label="CRO"
                  defaultValue=""
                  InputProps={{
                    autoComplete: 'new-password'
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <TextField
                  required={true}
                  inputRef={register}
                  InputLabelProps={{ shrink: true }}
                  erro={errors}
                  name="ufCRO"
                  label="UfCRO"
                  defaultValue=""
                  InputProps={{
                    autoComplete: 'new-password'
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  erro={errors}
                  name="especialidades"
                  label="Especialidades"
                  value={command.especialidades}
                  options={especialidades}
                  closeOnSelect
                  loading={loadingEspecialidades}
                  showCheck
                  showAddButton
                  getOptionSelected={option =>
                    !!command.especialidades.find(l => l.id === option.id)
                  }
                  getOptionLabel={option => option.descricao}
                  onChange={(event, newValue: ObjetoDescricaoGenerico[]) => {
                    handleRegisterObjeto(
                      'especialidades',
                      newValue as ObjetoDescricaoGenerico[]
                    )
                  }}
                  InputProps={{
                    autoComplete: 'new-password'
                  }}
                />
              </Grid>
            </Grid>

            {/* <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              erro={errors}
              name="locaisAtendimento"
              label="Locais de atendimento"
              value={command.locaisAtendimento}
              options={locais}
              loading={loadingLocais}
              showCheck
              showAddButton
              getOptionSelected={option =>
                !!command.locaisAtendimento.find(l => l.id === option.id)
              }
              onClickAddButton={texto => handleShowModal(texto)}
              getOptionLabel={option => option.descricao}
              onChange={(event, newValue: ObjetoDescricaoGenerico[]) => {
                handleRegisterObjeto(
                  'locaisAtendimento',
                  newValue as ObjetoDescricaoGenerico[]
                )
              }}
            />
          </Grid> */}
            <Grid item xs={12} md={12}>
              <TextField
                erro={errors}
                multiline={true}
                rows={3}
                inputRef={register}
                InputLabelProps={{ shrink: true }}
                name="observacoes"
                label="Observações"
                defaultValue=""
                InputProps={{
                  autoComplete: 'new-password'
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes.subTitle}
                color="primary"
                display="block"
                variant="caption"
              >
                Dados de acesso
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                erro={errors}
                name="cpf"
                placeholder="Ex: 999.999.999-99"
                label="CPF"
                hiddenLabel
                control={control}
                mask="999.999.999-99"
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  autoComplete: 'new-password'
                }}
                disabled
              />
            </Grid>
            <Grid item md={6} />
            <Grid item xs={12} md={6}>
              <PasswordField
                inputRef={register}
                erro={errors}
                name="senha"
                label="Senha"
                defaultValue=""
                InputProps={{
                  autoComplete: 'off'
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PasswordField
                inputRef={register}
                erro={errors}
                name="confirmarSenha"
                label="Confirme sua senha"
                defaultValue=""
                InputProps={{
                  autoComplete: 'off'
                }}
              />
            </Grid>
          </Grid>
          <Grid container justify="flex-end" style={{ marginTop: '24px' }}>
            <Grid item xs={12} sm={4}>
              <Button
                loading={loading}
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  )
}
