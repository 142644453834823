import React, { createContext, useState } from 'react'
import { toggleVerNovamente } from 'src/api/atualizacoes'

export interface ModalAtualizacoesContent {
  aberto?: boolean
  abrirModal?: () => void
  fecharModal?: (verNovamente: boolean) => void
}

export const ModalAtualizacoesContext = createContext<ModalAtualizacoesContent>(
  {} as any
)

export default function ModalProvider({ children }) {
  const [aberto, setAberto] = useState(false)

  function abrirModal() {
    setAberto(true)
  }

  async function fecharModal(verNovamente: boolean) {
    setAberto(false)
    if (!verNovamente) {
      await toggleVerNovamente(verNovamente)
    }
  }

  return (
    <ModalAtualizacoesContext.Provider
      value={{ abrirModal, fecharModal, aberto }}
    >
      {children}
    </ModalAtualizacoesContext.Provider>
  )
}
