import React, { useEffect, useState } from 'react'
import Drawer from 'src/components/ui/Drawer'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { obterPacotePorId } from 'src/api/pacote'

import ResumoPacoteInteligente from '../resumo'
import { obterPacoteInteligentePorId } from 'src/api/pacoteInteligente'
import { IPacoteInteligenteForm } from '../FormPacoteInteligente'
import ResumoPacoteInteligenteOps from '../resumoOperacional'

type Props = {
  id: string
  open: boolean
  configurado?: boolean
  exibirValor?: boolean
  medico?: boolean
  esconderInclusos?: boolean
  selecionado?: boolean
  handleOnClose: () => void
}

function DrawerPacoteInteligenteVisualizarDetalhes({
  id,
  open,
  configurado = false,
  exibirValor = false,
  medico = false,
  selecionado = false,
  esconderInclusos = false,
  handleOnClose
}: Props) {
  const [pacote, setPacote] = useState<IPacoteInteligenteForm>(null)
  const [loadingDetalhe, setLoadingDetalhe] = useState(false)

  useEffect(() => {
    if (!id) {
      return
    }
    setPacote(null)
    setLoadingDetalhe(true)
    obterPacoteInteligentePorId(id)
      .then(response => {
        setPacote(response.data)
        setLoadingDetalhe(false)
      })
      .catch(e => {
        showNotificationErrorAPI(e)
        handleOnClose()
      })
  }, [open])

  return (
    <Drawer
      loading={loadingDetalhe}
      anchor="right"
      open={open}
      titulo="Visualizar pacote"
      handleOnClose={handleOnClose}
    >
      {!!pacote && (
        <ResumoPacoteInteligenteOps
          adaptarValor={false}
          exibirValor={exibirValor}
          medico={medico}
          selecionado={selecionado}
          esconderIncluso={esconderInclusos}
          exibirDadosMedicos={configurado}
          pacote={pacote}
        />
      )}
    </Drawer>
  )
}

export default DrawerPacoteInteligenteVisualizarDetalhes
