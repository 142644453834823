import React from 'react'
import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  makeStyles,
  IconButton
} from '@material-ui/core'
import ModalGenerico from './ModalGenerico'
import { OPMEOrcado } from 'src/types'
import formatarCNPJ from 'src/core/formatacoes/formatarCNPJ'
import CloseIcon from '@material-ui/icons/Close'
import Button from 'src/components/ui/Button'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'

const useStyles = makeStyles(theme => ({
  modalCard: {
    backgroundColor: theme.palette.grey[50],
    marginBottom: '20px',
    border: '1px solid #efefef'
  }
}))

interface Props {
  item: OPMEOrcado
  open: boolean
  onClose: () => void
}

export default function InfoModal({ item, open, onClose }: Props) {
  const styles = useStyles()

  if (!item) {
    return
  }

  return (
    <ModalGenerico open={open} onClose={onClose}>
      <Grid container spacing={1}>
        <Box p={3}>
          <div style={{ position: 'absolute', top: '10px', right: '20px' }}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Box mb={1}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography variant="h6">Dados do Fornecedor</Typography>
            </Grid>
          </Box>

          <Grid item xs={12}>
            <Card className={styles.modalCard}>
              <Box m={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <strong>Fornecedor:</strong> {item.nomeParceiro}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <strong>CNPJ:</strong> {formatarCNPJ(item.cnpjParceiro)}
                    </Typography>
                  </Grid>
                  <br />
                  <br />
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <strong>Responsável:</strong> {item.nomeResponsavel}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <strong>Telefone:</strong> {formatarTelefone(item.telefoneResponsavel)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <strong>E-mail:</strong> {item.emailResponsavel}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Card>

            <Typography variant="h6" style={{ marginBottom: '10px' }}>
              Dados do OPME
            </Typography>
            <Typography variant="body1">
              <strong>Fabricante:</strong> {item.fabricante}
            </Typography>
            <Divider style={{ margin: '10px 0' }} />
            <Typography variant="body1">
              <strong>Referência:</strong>{' '}
              {item.referencia ? item.referencia : 'Não informado'}
            </Typography>
            <Divider style={{ margin: '10px 0' }} />
            <Typography variant="body1">
              <strong>Cód. ANVISA:</strong>{' '}
              {item.codAnvisa ? item.codAnvisa : 'Não informado'}
            </Typography>
            <Divider style={{ margin: '10px 0' }} />
            <Typography variant="body1">
              <strong>Descrição:</strong>{' '}
              {item.descricao ? item.descricao : 'Não informado'}
            </Typography>
            <Divider style={{ margin: '10px 0' }} />
            <Typography variant="body1">
              <strong>Observação:</strong>{' '}
              {item.observacao ? item.observacao : 'Não informado'}
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </ModalGenerico>
  )
}
