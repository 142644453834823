const StatusProposta = {
  AGUARDANDO_APROVACAO_MEDICO: {
    Id: 1,
    Descricao: 'Aguardando aprovação médica',
    Color: '#465b82'
  },
  APROVADA_PELO_MEDICO: { Id: 2, Descricao: 'Aprovada pelo médico', Color: '#8bc34a' },
  RECUSADA_PELO_MEDICO: { Id: 3, Descricao: 'Recusada pelo médico', Color: '#ea3f3f' },
  AGUARDANDO_APROVACAO_PACIENTE: {
    Id: 4,
    Descricao: 'Aguardando aprovação paciente',
    Color: '#E67F24'
  },
  APROVADA_PELO_PACIENTE: { Id: 5, Descricao: 'Aprovada pelo paciente', Color: '#8e44ad' },
  RECUSADA_PELO_PACIENTE: { Id: 7, Descricao: 'Recusada pelo paciente', Color: '#ea3f3f' },
  VENCEDORA: { Id: 6, Descricao: 'Proposta vencedora', Color: '#009432' },
  ENCERRADA: { Id: 8, Descricao: 'Proposta Encerrada', Color: '#2E2E2E' },
  DESATIVADA: { Id: 9, Descricao: 'Proposta Desativada', Color: '#A7A7A7' },
  PENDENTE_REVISAO: { Id: 10, Descricao: 'Pendente de revisão de valores', Color: '#660099' },
  RECUSADA_PELO_HOSPITAL: { Id: 11, Descricao: 'Recusada pelo hospital', Color: '#ea3f3f' }
}
export default StatusProposta
