import { Box, CircularProgress } from '@material-ui/core'
import React from 'react'
import Drawer from 'src/components/ui/Drawer'
import useAtividade from 'src/hooks/useAtividade'
import { ItemListagemSolicitacaoOrcamentoResponse } from 'src/types'
import Atividades from 'src/components/atividade/Atividades'

interface Props {
  open: boolean
  handleOnClose: () => void
  solicitacao?: ItemListagemSolicitacaoOrcamentoResponse
}

export default function DrawerAtividades({
  open,
  handleOnClose,
  solicitacao
}: Props) {
  const { loading } = useAtividade()
  return (
    <Drawer
      open={open}
      anchor="right"
      handleOnClose={handleOnClose}
      titulo="Atividades"
    >
      {loading ? (
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      ) : (
        <Atividades solicitacao={solicitacao} />
      )}
    </Drawer>
  )
}
