import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  CssBaseline,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField as TextFieldMU,
  Tooltip,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { FiInfo } from 'react-icons/fi'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import {
  adicionarComorbidade,
  adicionarLocalAtendimento,
  obterComorbidades,
  obterExames,
  obterLocaisAtendimentoPorUfMedico,
  obterLocaisAtendimentoPorUfMedicoComId,
  obterPacientePorCPF
} from 'src/api/dominio'
import {
  obterOrcamentoPorId,
  obterValoresOrcamentoInteligente,
  salvarSolicitacaoOrcamentoInteligente,
  ValoresInteligentesResponse
} from 'src/api/solicitacao-orcamento'
import { ModalAddGenerico } from 'src/components/modals/ModalAddGenerico'
import {
  StepConnectorSyn,
  StepIconSyn
} from 'src/components/stepper/stepConnector'
import { StepContentSyn } from 'src/components/stepper/stepContentSyn'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import { TextField } from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import equipeAnestesias from 'src/core/consts/equipeAnestesia'
import genero from 'src/core/consts/genero'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { formatarIdade } from 'src/core/formatacoes/formatarData'
import {
  onlyNumber,
  primeiraLetraMaiusculo,
  verificarCPF
} from 'src/core/helpers/common'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useListagem from 'src/hooks/useListagem'
import { useQuery } from 'src/hooks/useQuery'
import {
  IComboResponse,
  IExame,
  IOrcamentoDetalhadoResponse,
  IPaciente,
  ObjetoDescricaoGenerico,
  SolicitarOcamentoInteligenteCommand
} from 'src/types'
import Swal from 'sweetalert2'

import DateInput from 'src/components/ui/DateInput'

import estados from 'src/core/consts/estados'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { TiposAnestesias } from 'src/core/consts/tiposAnestesia'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import useAuth from 'src/hooks/useAuth'
import {
  listagemMeusPacotesInteligentes,
  ListagemPacoteInteligenteQuery
} from 'src/api/pacoteInteligente'
import { IPacoteInteligenteForm } from 'src/components/pacoteInteligente/FormPacoteInteligente'
import DrawerAjustarPacoteInteligente from '../drawerAjustarPacoteInteligente'
import {
  mapearPacoteParaRequest,
  Steps
} from '../../pacote/pacoteInteligente/formConfiguracao'
import ResumoPacoteInteligenteOps from 'src/components/pacoteInteligente/resumoOperacional'
import { ResumoOrcamento } from '../resumo'
import UBox from 'src/components/ui/Ubox'
import { INTERNACOES_OBJ } from 'src/core/consts/internacoes'
import {
  buscarTodosMembrosEquipe,
  ItemListagemMembroEquipe
} from 'src/api/membroEquipe'
import CurrencyInput from 'src/components/ui/CurrencyInput'
import Button from 'src/components/ui/Button'
import { MdEdit } from 'react-icons/md'
import { FormConfiguracaoV2 } from '../../pacote/pacoteInteligente/formConfiguracaoV2'
import DrawerPropostas from './drawerPropostas'
import { ModalTransformTemplate } from '../modalTransformtemplate/modalTransformTemplate'
import { p } from 'chart.js/dist/chunks/helpers.core'
import { FcCalendar } from 'react-icons/fc'
import { BiPlusMedical } from 'react-icons/bi'

const useStyles = makeStyles(theme => ({
  container: {},
  BtnCancel: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.common.white,
    '@media (max-width: 1300px)': {
      marginBotton: '10px'
    }
  },
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  infoTxt: {
    color: theme.palette.info.light
  },
  noMargin: {
    margin: theme.spacing(0)
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))

export interface IOrcamentoForm {
  id?: string
  codigo?: number
  criado?: string
  dataHoraServidor?: string
  pacienteId?: string
  nomePaciente: string
  cpfPaciente: string
  telefonePaciente: string
  dataNascimentoPaciente: string
  emailPaciente: string
  sexoPaciente: number
  centroCirurgicoAmbulatorial?: number
  podeDiasUteis?: boolean
  podeFinalDeSemana?: boolean
  temPlanoDeSaude?: number

  responsavelPacienteId?: string
  cpfResponsavel?: string
  nomeResponsavel?: string

  medicoId?: string
  medicoNome?: string
  medicoEspecialidades?: ObjetoDescricaoGenerico[]

  exames: IExame[]
  avaliacaoPreAnestesica?: boolean
  riscoCirurgico?: boolean
  observacaoExames?: string

  pacote: ObjetoDescricaoGenerico
  comorbidades: ObjetoDescricaoGenerico[]
  reservaSangue: string
  hospitais: ObjetoDescricaoGenerico[]
  observacaoSangue?: string
  observacaoComorbidades?: string

  solicitacaoSemLocalAtendimento: boolean
  temDisponibilidade: boolean

  disponibilidade: string
  anestesia?: string
  observacoes: string
  uf?: string

  combos?: IComboResponse[]
  semComorbidade?: boolean

  valorMinHonorarios?: number
  valorMaxHonorarios?: number
  valorEquipeAnestesia?: number
  equipeAnestesia?: number
  urlLogomarca?: string
  medicoSolicitacaoId?: string
  nomeAssistente?: string

  honorarioEquipeMedica?: number
  semHonorarioMedico?: boolean
  membros?: ItemListagemMembroEquipe[]
}

export function mapToForm(item: IOrcamentoDetalhadoResponse): IOrcamentoForm {
  let orcamento: IOrcamentoForm = {
    id: item.id,
    podeDiasUteis: item.podeDiasUteis,
    podeFinalDeSemana: item.podeFinalDeSemana,
    centroCirurgicoAmbulatorial: item.centroCirurgicoAmbulatorial,
    medicoSolicitacaoId: item.medicoSolicitacaoId,
    codigo: item.codigo,
    criado: item.criado,
    dataHoraServidor: item.dataHoraServidor,
    cpfPaciente: item.pacienteCpf,
    nomePaciente: item.pacienteNome,
    emailPaciente: item.pacienteEmail,
    telefonePaciente: item.pacienteTelefone,
    sexoPaciente: item.pacienteSexo,
    pacienteId: item.pacienteId,
    dataNascimentoPaciente: item.pacienteDataNascimento,
    comorbidades: item.comorbidades,
    pacote: item.pacote,
    reservaSangue: item.qtdReservaSange.toString(),
    hospitais: item.locaisAtendimento,
    disponibilidade: item.disponibilidade,
    solicitacaoSemLocalAtendimento: item.solicitacaoSemLocalAtendimento,
    temDisponibilidade: !!item.disponibilidade,
    anestesia: item.anestesia,
    observacoes: item.observacoes,
    medicoId: item.medicoId,
    medicoNome: item.medicoNome,
    medicoEspecialidades: item.medicoEspecialidades,
    riscoCirurgico: item.riscoCirurgico,
    avaliacaoPreAnestesica: item.avaliacaoPreAnestesica,
    observacaoExames: item.observacaoExames,
    cpfResponsavel: item.cpfResponsavel,
    nomeResponsavel: item.nomeResponsavel,
    observacaoSangue: item.observacaoSangue,
    exames: item.exames,
    observacaoComorbidades: item.observacaoComorbidades,
    uf: item.uf,
    semComorbidade: item.semComorbidade,
    valorMaxHonorarios: item.valorMaxHonorarios,
    valorMinHonorarios: item.valorMinHonorarios,
    valorEquipeAnestesia: item.valorEquipeAnestesia,
    nomeAssistente: item.nomeAssistente,
    equipeAnestesia: !!item.valorEquipeAnestesia
      ? equipeAnestesias.medica
      : equipeAnestesias.hospital,
    urlLogomarca: item.urlLogomarca,
    responsavelPacienteId: item.responsavelPacienteId
  }
  return orcamento
}

type Props = {
  fecharForm?: () => void
  pacoteSelecionado?: IPacoteInteligenteForm
  setPacoteSelecionado?: (pacote: IPacoteInteligenteForm) => void
  activeStep?: number
  setActiveStep?: (step: number) => void
  medicoId?: string
  medicoCpf?: string
  isback?: (boolean) => void
}

export const FormOrcamentoInteligenteV3 = (props: Props) => {
  const {
    activeStep,
    setActiveStep,
    pacoteSelecionado,
    setPacoteSelecionado,
    medicoCpf,
    medicoId,
    isback
  } = props
  const classes = useStyles()
  const history = useHistory()

  const query = useQuery()

  //#region Definicao de listas e loading
  const [loadingOrcamento, setLoadingOrcamento] = useState(true)

  const [listaPacotes, setListaPacotes] = useState<ObjetoDescricaoGenerico[]>(
    []
  )
  const [loadingMembros, setLoadingMembros] = useState<boolean>(true)
  const [listaMembrosEquipe, setListaMembrosEquipe] = useState<
    ItemListagemMembroEquipe[]
  >([])

  const [listaHospitais, setListaHospitais] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [loadingHospitais, setLoadingHospitais] = useState<boolean>(true)

  const [valoresOrcamentos, setValoresOrcamentos] = useState<
    ValoresInteligentesResponse[]
  >(null)

  const [
    loadingValoresOrcamentos,
    setLoadingValoresOrcamentos
  ] = useState<boolean>(true)

  const [estadosHospitais, setEstadosHospitais] = useState<any[]>([])

  const [listaComorbidades, setListaComorbidades] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [loadingComorbidades, setLoadingComorbidades] = useState<boolean>(true)
  const [semComorbidade, setSemComorbidade] = useState<boolean>(false)
  const [verificaAnestesia, setVerificaAnestesia] = useState<boolean>(false)

  const [verificouEquipe, setVerificouEquipe] = useState(false)

  const [
    comorbidadeMensagemErro,
    setComorbidadeMensagemErro
  ] = useState<boolean>(false)

  const [loadingPaciente, setLoadingPaciente] = useState(false)
  const [showFormPaciente, setShowFormPaciente] = useState(false)
  const [pacienteResponsavel, setPacienteResponsavel] = useState(false)

  const [loadingPacote, setLoadingPacote] = useState(false)

  const [ajustarPacote, setAjustarPacote] = useState(false)
  const [etapaAjuste, setEtapaAjuste] = useState<Steps>(undefined)

  const [erroTempoCirurgico, setErroTempoCirurgico] = useState(false)

  const [listaExames, setListaExames] = useState<IExame[]>([])
  const [loadingExames, setLoadingExames] = useState<boolean>(true)

  const [maiorValor, setMaiorValor] = useState(null)
  const [menorValor, setMenorValor] = useState(null)
  const [openDrawerPropostas, setOpenDrawerPropostas] = useState(false)
  const [ehFocoValor, setFocoValor] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [isloading, setIsLoading] = useState(false)
  const [nomeUsuario, setNomeUsuario] = useState([])
  const { signOut, user, perfilAtual, ehTenantSyn, tenantAtual } = useAuth()

  //#endregion

  //#region Modal
  const [textoModal, setTextoModal] = useState('')
  const [showModal, toggleModal] = useState<string>(null)

  const existeEtapaFinalDeSemana =
    !!pacoteSelecionado?.propostaFinalDeSemana ||
    !!pacoteSelecionado?.propostaCentroCirurgicoAmbulatorial

  const existeEtapaDeSangue = !!pacoteSelecionado?.possuiSangue
  const qtdEtapasDinamicas =
    (existeEtapaFinalDeSemana ? 1 : 0) + (existeEtapaDeSangue ? 1 : 0)
  const lastStep = 6 + qtdEtapasDinamicas

  const stepAtual = activeStep + 1

  const handleShowModal = (texto, modal) => {
    setTextoModal(texto)
    toggleModal(modal)
  }

  const dadosModal = () => {
    if (!showModal) return
    let dados = null
    switch (showModal) {
      case 'hospitais':
        dados = {
          titulo: 'Adicionar local',
          label: 'local de atendimento',
          saveSubmit: adicionarLocalAtendimento,
          handleValue: valor => {
            handleNovoValor('hospitais', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
      case 'comorbidades':
        dados = {
          titulo: 'Adicionar comorbidade',
          label: 'Comorbidade',
          saveSubmit: adicionarComorbidade,
          handleValue: valor => {
            handleNovoValor('comorbidades', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
    }
    return dados
  }
  //#endregion

  //#region Formulário

  const schema = yup.object().shape({
    nomePaciente: yup
      .string()
      .required()
      .test('nome válido', 'Deve informar nome e sobrenome', value => {
        if (!value) return false
        let nomeArray = value.split(' ').filter(texto => texto.trim())
        return nomeArray.length > 1
      })
      .label('Nome completo'),
    nomeResponsavel: yup
      .string()
      .test('nome-responsavel', 'Nome do responsável é obrigatório', value => {
        if (pacienteResponsavel && !value) {
          return false
        }
        return true
      })
      .test('nome válido', 'Deve informar nome e sobrenome', value => {
        if (pacienteResponsavel) {
          let nomeArray = value.split(' ').filter(texto => texto.trim())

          return nomeArray.length > 1
        }

        return true
      })
      .label('Nome completo'),
    cpfPaciente: yup
      .string()
      .nullable()
      .test('cpf-teste', 'CPF inválido', value => {
        if (pacienteResponsavel) return true

        if (!value) return false

        return verificarCPF(onlyNumber(value))
      })
      .label('CPF'),
    cpfResponsavel: yup
      .string()
      .nullable()
      .test(
        'cpf-responsavel',
        'CPF do responsável é obrigatório',
        function (value) {
          if (pacienteResponsavel && !value) {
            return false
          }
          return true
        }
      )
      .test('cpf-teste', 'CPF inválido', value => {
        if (pacienteResponsavel && value) {
          return verificarCPF(onlyNumber(value))
        }
        return true
      })
      .label('CPF'),
    telefonePaciente: yup.string().required().label('Telefone'),
    dataNascimentoPaciente: yup
      .string()
      .required()
      .test('nas', 'Por favor escolha uma data válida', value => {
        return moment(value).isBetween('1922-12-31', moment(), 'day')
      })
      .label('Data de nascimento'),
    emailPaciente: yup.string().email().label('E-mail'),
    sexoPaciente: yup
      .number()
      .required()
      .label('Sexo')
      .test('sexo-teste', 'Informe o sexo', value => {
        if (!value) return false
        return true
      }),
    pacote: yup.object().nullable().required().label('Pacote instantâneo'),
    comorbidades: yup.array().label('Comorbidades'),
    observacaoExames: yup.string().label('Observações dos exames'),
    observacaoSangue: yup.string().label('Observações'),
    reservaSangue: yup.string().label('Reserva de sangue'),
    observacoes: yup.string().label('Observações'),
    observacaoComorbidades: yup.string().label('Observação das comorbidades'),
    combos: yup.array().label('Combos'),
    podeDiasUteis: yup
      .bool()
      .label('Pode dias úteis')
      .test('pode-finalSemana', 'Informe se aceita dias úteis', value => {
        if (!pacoteSelecionado?.propostaFinalDeSemana) {
          return true
        }
        if (!command.podeFinalDeSemana) {
          if (!value) return false
        }
        return true
      }),
    podeFinalDeSemana: yup
      .bool()
      .label('Pode final de semana')
      .test('pode-finalSemana', 'Informe se aceita final de semana', value => {
        if (!pacoteSelecionado?.propostaFinalDeSemana) {
          return true
        }
        if (!command.podeDiasUteis) {
          if (!value) return false
        }
        return true
      }),
    centroCirurgicoAmbulatorial: yup
      .number()
      .required()
      .label('Pode ambulatório')
      .test(
        'pode-ambulatorio',
        'Informe se aceita "Centro cirúrgico" ou "Centro cirúrgico ambulatorial"',
        value => {
          if (!pacoteSelecionado?.propostaCentroCirurgicoAmbulatorial) {
            return true
          }
          if (!value) return false
          return true
        }
      ),
    temPlanoDeSaude: yup
      .number()
      .required()
      .label('Pode Plano de Saúde')
      .test(
        'pode-plano-de-saude',
        'Informe se o paciente possui plano de saúde',
        value => {
          if (!pacoteSelecionado?.planoDeSaude) {
            return true
          }
          if (!value) return false
          return true
        }
      )
  })

  let defaultValues: IOrcamentoForm = {
    pacienteId: '',
    nomePaciente: '',
    emailPaciente: '',
    telefonePaciente: '',
    cpfPaciente: '',
    dataNascimentoPaciente: '',
    sexoPaciente: 0,
    podeFinalDeSemana: false,
    podeDiasUteis: false,
    centroCirurgicoAmbulatorial: 0,
    temPlanoDeSaude: 0,

    exames: [],
    avaliacaoPreAnestesica: false,
    riscoCirurgico: false,
    observacaoExames: '',

    pacote: null,
    comorbidades: [],
    hospitais: [],
    observacaoComorbidades: '',

    reservaSangue: '',
    temDisponibilidade: false,
    equipeAnestesia: 0,
    anestesia: '',
    disponibilidade: '',
    observacoes: '',
    solicitacaoSemLocalAtendimento: false,

    semHonorarioMedico: pacoteSelecionado?.semHonorarioMedico,
    combos: [],
    urlLogomarca: undefined
  }

  // defaultValues = solicitacaoMock

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    formState,
    handleSubmit,
    trigger
  } = useForm<IOrcamentoForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  const command = watch()

  // REGISTRA VALORES REACT FORM HOOKS
  useEffect(() => {
    register('medicoSolicitacaoId')
    register('id')
    register('sexoPaciente')
    register('hospitais')
    register('pacote')
    register('telefonePaciente')
    register('comorbidades')
    register('dataNascimentoPaciente')
    register('pacienteId')
    register('combos')
    register('valorMinHonorarios')
    register('valorMaxHonorarios')
    register('equipeAnestesia')
    register('responsavelPacienteId')

    register('honorarioEquipeMedica')
    register('semHonorarioMedico')

    register('membros')

    register('centroCirurgicoAmbulatorial')
    register('podeFinalDeSemana')
    register('temPlanoDeSaude')
    register('podeDiasUteis')
  }, [register])

  // VINCULAR MUDANÇA DE VALOR
  const handleItemValue = (name: string, item) => {
    setValue(name, item, {
      shouldValidate: true
    })
    if (name === 'podeDiasUteis') {
      pacoteSelecionado.podeDiasUteis = item
      setPacoteSelecionado(pacoteSelecionado)
    }
    if (name === 'podeFinalDeSemana') {
      pacoteSelecionado.podeFinalDeSemana = item
      setPacoteSelecionado(pacoteSelecionado)
    }
  }

  const handleNovoValor = (name, item: any) => {
    switch (name) {
      case 'comorbidades':
        carregarComorbidades()
        setValue('comorbidades', [...command.comorbidades, item], {
          shouldValidate: true
        })
        break
      case 'hospitais':
        carregarHospitais()
        setValue('hospitais', [...command.hospitais, item], {
          shouldValidate: true
        })
        break
    }
  }
  //#endregion

  //#region MOUNT
  useEffect(() => {
    inicio()
  }, [])

  async function inicio() {
    await carregarMembros()
    carregarComorbidades()
    verificaEhEdicao()
    verificaEhSecretaria()
  }

  function verificaEhSecretaria() {
    const medicoSolicitacaoId = query.get('medicoSolicitacaoId')
    if (medicoSolicitacaoId) {
      setValue('medicoSolicitacaoId', medicoSolicitacaoId)
      obterLocaisAtendimentoPorUfMedicoCom(medicoSolicitacaoId)
    } else {
      carregarHospitais()
    }
  }

  const obterLocaisAtendimentoPorUfMedicoCom = async (medicoSolicitacaoId?) => {
    return obterLocaisAtendimentoPorUfMedicoComId(medicoSolicitacaoId)
      .then(response => {
        setListaHospitais(response.data)
        let listaTodosEstadosHospitais = response.data?.map(item => item.grupo)
        let estadosHospitais = [...new Set(listaTodosEstadosHospitais)]
        if (estadosHospitais.length === 1) {
          setValue('uf', estadosHospitais[0])
        }
        setEstadosHospitais(
          estados.filter(e => estadosHospitais.includes(e.Initials)).sort()
        )
      })
      .finally(() => {
        setLoadingHospitais(false)
      })
  }

  function verificaEhEdicao() {
    const id = query.get('id')
    if (!id) {
      setLoadingOrcamento(false)
      return
    }

    obterOrcamentoPorId(id)
      .then(response => {
        const orcamento = response.data
        const form = mapToForm(orcamento)
        setPacienteResponsavel(!!form.responsavelPacienteId)

        for (const key in form) {
          let value = form[key]
          if (key === 'dataNascimentoPaciente') {
            value = moment(value).format('yyyy-MM-DD')
          }

          if (key === 'semComorbidade') {
            setSemComorbidade(value)
            continue
          }
          setValue(key, value, { shouldValidate: false })
          if (key === 'medicoId') {
            setValue('medicoSolicitacaoId', value)
          }
        }
        setLoadingOrcamento(false)
      })
      .catch(e => {
        showNotificationErrorAPI(e)
        history.goBack()
      })
  }

  function verificaSeTemPacoteSelecionado() {
    const idPacote = query.get('idPacote')
    const descricaoPacote = query.get('descricaoPacote')

    if (!!idPacote && !!descricaoPacote) {
      let pacote: ObjetoDescricaoGenerico = {
        id: idPacote,
        descricao: descricaoPacote
      }

      setValue('pacote', pacote)
    }

    if (!!pacoteSelecionado) {
      let pct: ObjetoDescricaoGenerico = {
        id: pacoteSelecionado?.id,
        descricao: pacoteSelecionado?.descricao
      }

      setValue('pacote', pct)

      let pacote: IPacoteInteligenteForm = pacoteSelecionado
      pacote.honorarioEquipeMedica = pacote.honorarioEquipeMedica * 100
      if (pacote.responsavelAnestesia === 'M') {
        pacote.tiposAnestesia = pacote.tiposAnestesia.map(i => {
          i.valor = i.valor * 100
          return i
        })
      }

      if (!!pacote?.membros) {
        var membros = pacote?.membros.map(i => {
          i.valor = parseInt((i.valor * 100).toFixed(0))
          return i
        })

        membros = membros.filter(m =>
          listaMembrosEquipe.find(lm => lm.id === m.id)
        )

        pacote.membros = membros
      }

      if (pacote?.opmesInclusos?.length > 0) {
        pacote.opmes = [...pacote.opmesInclusos, ...pacote?.opmes]
        pacote.opmesInclusos = []
      }

      setPacoteSelecionado(pacote)
      handleItemValue('honorarioEquipeMedica', pacote.honorarioEquipeMedica)
    }
  }

  async function carregarMembros() {
    let medicoSolicitacaoId = query.get('medicoSolicitacaoId')
    var cpfOuMedicoId =
      perfilAtual === SynPerfis.MEDICO ? user.cpf : medicoSolicitacaoId

    //TODO AJustar para quando é o próprio médico
    var response = await buscarTodosMembrosEquipe(cpfOuMedicoId)

    var { data } = response
    setListaMembrosEquipe(data)
    setLoadingMembros(false)
  }
  //#endregion

  //#region Carregar Opções do Sistema
  const { loading, dados } = useListagem(1000, listagem)
  function listagem(
    querys: ListagemPacoteInteligenteQuery,
    primeiraBusca?: boolean
  ) {
    querys['perfilAtual'] = perfilAtual

    const doc = query.get('doc')
    if (!!doc) {
      return listagemMeusPacotesInteligentes(doc, querys)
    } else {
      return listagemMeusPacotesInteligentes(user.cpf, querys)
    }
  }

  useEffect(() => {
    if (!loadingMembros) verificaSeTemPacoteSelecionado()
  }, [loadingMembros])

  useEffect(() => {
    setListaPacotes(dados.filter(i => !!i.configurado))
  }, [dados])

  const carregarExames = async () => {
    return obterExames()
      .then(response => setListaExames(response.data || []))
      .finally(() => {
        setLoadingExames(false)
      })
    // return setListaExames(mockExames)
  }

  const carregarHospitais = async () => {
    return obterLocaisAtendimentoPorUfMedico()
      .then(response => {
        setListaHospitais(response.data)
        let listaTodosEstadosHospitais = response.data?.map(item => item.grupo)
        let estadosHospitais = [...new Set(listaTodosEstadosHospitais)]
        if (estadosHospitais.length === 1) {
          setValue('uf', estadosHospitais[0])
        }
        setEstadosHospitais(
          estados.filter(e => estadosHospitais.includes(e.Initials)).sort()
        )
      })
      .finally(() => {
        setLoadingHospitais(false)
      })
  }

  const carregarComorbidades = async () => {
    return obterComorbidades()
      .then(response => {
        setListaComorbidades(response.data)
      })
      .finally(() => {
        setLoadingComorbidades(false)
      })
  }

  //#endregion

  async function obterValores() {
    setValoresOrcamentos(null)
    setLoadingValoresOrcamentos(true)

    const { paciente, pacote, request } = prepararRequest()
    return obterValoresOrcamentoInteligente(request)
      .then(response => {
        const valores = response.data

        setValoresOrcamentos(valores)
        setLoadingValoresOrcamentos(false)
      })
      .catch(e => showNotificationErrorAPI(e))
  }

  function prepararRequest() {
    let paciente: IPaciente = {
      id: command.pacienteId,
      comorbidades: command.comorbidades.map(c => c.id),
      cpf: onlyNumber(command.cpfPaciente),
      dataNascimento: moment(command.dataNascimentoPaciente).toJSON(),
      email: command.emailPaciente,
      nome: command.nomePaciente,
      telefone: onlyNumber(command.telefonePaciente),
      sexo: command.sexoPaciente,
      cpfResponsavel: pacienteResponsavel ? command.cpfResponsavel : null,
      nomeResponsavel: command.nomeResponsavel
    }

    let pacote = mapearPacoteParaRequest(
      JSON.parse(JSON.stringify(pacoteSelecionado)),
      pacoteSelecionado?.id
    )
    let valueCentroCirurgicoAmbulatorial = false
    let valuePlanoDeSaude = false

    if (command.centroCirurgicoAmbulatorial === 2) {
      valueCentroCirurgicoAmbulatorial = true
    }

    if (command.temPlanoDeSaude === 1) {
      valuePlanoDeSaude = true
    }

    let request: SolicitarOcamentoInteligenteCommand = {
      id: command.id,
      paciente: paciente,
      pacoteInteligente: pacote,
      qtdReservaSangue: !!command.reservaSangue
        ? parseInt(command.reservaSangue)
        : 0,

      observacoes: command.observacoes,
      observacaoExames: command.observacaoExames,
      observacaoSangue: command.observacaoSangue,
      observacaoComorbidades: command.observacaoComorbidades,
      valorMinHonorarios: command.valorMinHonorarios,
      valorMaxHonorarios: command.valorMaxHonorarios,

      responsavelPacienteId: pacienteResponsavel
        ? command.responsavelPacienteId
        : null,
      medicoSolicitacaoId: command.medicoSolicitacaoId,
      perfilAtual: perfilAtual,
      temPlanoDeSaude: valuePlanoDeSaude,
      podeDiasUteis: !pacoteSelecionado?.propostaFinalDeSemana
        ? true
        : command.podeDiasUteis,
      podeFinalDeSemana: !pacoteSelecionado?.propostaFinalDeSemana
        ? false
        : command.podeFinalDeSemana,
      centroCirurgicoAmbulatorial: !pacoteSelecionado?.propostaCentroCirurgicoAmbulatorial
        ? false
        : valueCentroCirurgicoAmbulatorial
    }
    return { request, pacote, paciente }
  }

  const onSubmit = async (data: IOrcamentoForm, tornarTemplate?: boolean) => {
    setIsLoading(true)
    const aceitouTemplate = tornarTemplate
    const { paciente, pacote, request } = prepararRequest()

    const payload = {
      ...request,
      template: aceitouTemplate
    }
    return salvarSolicitacaoOrcamentoInteligente(payload)
      .then(response => {
        const id = response.data
        Swal.fire({
          title: 'Parabéns!',
          text: 'Sua solicitação de orçamento foi salva com sucesso.',
          icon: 'success',
          confirmButtonText: 'Continuar',
          showCancelButton: !(
            perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
            perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL
          ),
          cancelButtonText: 'Imprimir guia'
        }).then(result => {
          if (
            perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
            perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL
          ) {
            history.replace(SynRoutes.listagemOrcamentosHospital)
            setIsLoading(false)
            return
          }
          if (result.dismiss === Swal.DismissReason.cancel) {
            history.replace(SynRoutes.listagemOrcamentos + `?print=${id}`)
            setIsLoading(false)
            return
          }
          if (
            command.solicitacaoSemLocalAtendimento === true &&
            pacote.opmes.length === 0
          ) {
            history.replace(`${SynRoutes.detalhesPropostasMedico}?id=${id}`)
            setIsLoading(false)
          } else {
            history.replace(SynRoutes.listagemOrcamentos)
            setIsLoading(false)
          }
        })
      })
      .catch(e => showNotificationErrorAPI(e))
  }
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))
  //#region stepper
  useEffect(() => {
    let ehAnestesiaLocal =
      pacoteSelecionado?.tiposAnestesia?.filter(
        w =>
          w.id === TiposAnestesias.local_realizado_pelo_medico ||
          w.id === TiposAnestesias.local_realizado_pelo_medico_dev
      ).length > 0 && pacoteSelecionado?.tiposAnestesia?.length === 1
    setVerificaAnestesia(ehAnestesiaLocal)
  }, [pacoteSelecionado])
  const normalise = value => ((value - 0) * 100) / (lastStep - 0)

  const formularioCamposDoStep = () => {
    const campos = [
      ['pacote']
    ]
    if (pacoteSelecionado?.planoDeSaude === true) {
      campos.push([
        'cpfPaciente',
        'nomePaciente',
        'telefonePaciente',
        'sexoPaciente',
        'dataNascimentoPaciente',
        'temPlanoDeSaude'])
    } else {
      campos.push([
        'cpfPaciente',
        'nomePaciente',
        'telefonePaciente',
        'sexoPaciente',
        'dataNascimentoPaciente'])
    }

    campos.push(['comorbidades'])

    if (!!pacoteSelecionado?.possuiSangue) {
      campos.push(['reservaSangue'])
    }

    campos.push(['observacoes'])

    if (
      !!pacoteSelecionado?.propostaCentroCirurgicoAmbulatorial &&
      !!pacoteSelecionado?.propostaFinalDeSemana
    ) {
      campos.push([
        'centroCirurgicoAmbulatorial',
        'podeFinalDeSemana',
        'podeDiasUteis'
      ])
    }

    if (
      !!pacoteSelecionado?.propostaCentroCirurgicoAmbulatorial &&
      !pacoteSelecionado?.propostaFinalDeSemana
    ) {
      campos.push(['centroCirurgicoAmbulatorial'])
    }

    if (
      !!pacoteSelecionado?.propostaFinalDeSemana &&
      !pacoteSelecionado?.propostaCentroCirurgicoAmbulatorial
    ) {
      campos.push(['podeFinalDeSemana', 'podeDiasUteis'])
    }

    campos.push(['honorarioEquipeMedica', 'membros'])

    return campos
  }

  const camposDoStep = formularioCamposDoStep()

  const handleNext = async () => {
    if (activeStep === 1) {
      const idade = formatarIdade(stringToDate(command.dataNascimentoPaciente))

      if (idade < 18) {
        await setPacienteResponsavel(true)
        const idadeResult = await trigger(['cpfResponsavel', 'nomeResponsavel'])
        if (!idadeResult) return
      }
    }
    var campos = camposDoStep[activeStep]
    if (campos) {
      const result = await trigger(campos)
      if (!result) {
        return
      }
    }

    setActiveStep(activeStep + 1)
  }

  const debugSteps = false
  const handleBackToStep = stepNumber => {
    if (!stepNumber && stepNumber !== 0) {
      setActiveStep(activeStep - 1)
      return
    }

    if (debugSteps || stepNumber < activeStep) {
      setActiveStep(stepNumber)
    }
  }

  const getSteps = number => {
    const steps = []
    for (let index = 0; index < number; index++) {
      steps.push(
        <Step key={index}>
          <StepLabel
            StepIconComponent={v => StepIconSyn({ ...v, key: index + 1 })}
            onClick={() => handleBackToStep(index)}
          ></StepLabel>
          <StepContent></StepContent>
        </Step>
      )
    }
    return steps
  }

  function handleAjusteEtapa(etapa: Steps) {
    if (etapa === Steps.membrosEquipe) {
      setVerificouEquipe(true)
    }
    setAjustarPacote(true)
    setEtapaAjuste(etapa)
  }

  function handleAjusteEtapaSolicitacao(activeTab: number) {
    setActiveStep(activeTab)
  }

  function handleFecharAjustarPacote() {
    setAjustarPacote(false)
  }

  function handleFecharPropostas() {
    setOpenDrawerPropostas(false)
  }

  function handleBack() {
    setOpenDrawerPropostas(false)
    setActiveStep(activeStep - 1)
  }

  function handleOpenDrawerPropostas() {
    setOpenDrawerPropostas(true)
  }

  function handleSalvarAjuste(dados) {
    const novoPacote = {
      ...pacoteSelecionado,
      ...dados
    }
    setPacoteSelecionado(novoPacote)
    setAjustarPacote(false)
    setErroTempoCirurgico(false)
  }

  const handleRadios = e => {
    handleItemValue('sexoPaciente', parseInt(e.target.value))
  }
  const handleRadiosEquipeAnestesia = e => {
    handleItemValue('equipeAnestesia', parseInt(e.target.value))
    if (e.target.value === '2') {
      setValue('valorEquipeAnestesia', 0)
      focarCampoValor(0)
    } else {
      focarCampoValor(1)
    }
  }
  //#endregion

  useEffect(() => {
    if (!command.cpfPaciente && !command.cpfResponsavel) return

    const cpfFormatado = onlyNumber(
      !!pacienteResponsavel ? command.cpfResponsavel : command.cpfPaciente
    )
    if (cpfFormatado.length === 11) {
      setLoadingPaciente(true)
      obterPacientePorCPF(cpfFormatado)
        .then(response => {
          const paciente = response.data

          if (!!paciente && !pacienteResponsavel) {
            setValue('comorbidades', paciente.comorbidades, {
              shouldValidate: true
            })
            setValue('nomePaciente', paciente.nome, { shouldValidate: true })
            if (!!paciente.dataNascimento) {
              let dataFormatada = moment(paciente.dataNascimento).format(
                'yyyy-MM-DD'
              )
              setValue('dataNascimentoPaciente', dataFormatada, {
                shouldValidate: true
              })
            }
            setValue('telefonePaciente', paciente.telefone, {
              shouldValidate: true
            })
            setValue('pacienteId', paciente.id)
            setValue('sexoPaciente', paciente.sexo)
            setValue('emailPaciente', paciente.email, { shouldValidate: true })
            setValue('responsavelPacienteId', null)
          } else if (!!paciente && pacienteResponsavel) {
            setValue('pacienteId', null)
            setValue('cpfPaciente', '')
            setValue('nomeResponsavel', paciente.nome)
            setValue('responsavelPacienteId', paciente.id)
          } else {
            limparFormPac(pacienteResponsavel)
          }
          setShowFormPaciente(true)
        })
        .catch(e => {
          showNotificationErrorAPI(e)
          limparFormPac(pacienteResponsavel)
          setShowFormPaciente(false)
        })
        .finally(() => {
          setTimeout(() => setLoadingPaciente(false), 100)
        })
    }
  }, [command.cpfPaciente, command.cpfResponsavel])

  function limparFormPac(ehResponsavel: boolean) {
    if (ehResponsavel) {
      setValue('nomeResponsavel', '')
      setValue('responsavelPacienteId', null)
      return
    }
    setValue('pacienteId', null)
    setValue('nomePaciente', null)
    setValue('dataNascimentoPaciente', '')
    setValue('telefonePaciente', '')
    setValue('emailPaciente', null)
    setValue('sexoPaciente', 0)
  }

  function toggleComorbidades() {
    setValue('comorbidades', [])
    setSemComorbidade(!semComorbidade)
    setComorbidadeMensagemErro(false)
  }

  function stringToDate(str: string) {
    if (!str) {
      return
    }
    const isoString = str
      .replace('/', '-')
      .replace('/', '-')
      .split('-')
      .reverse()
      .join('-')

    return new Date(isoString)
  }

  function focarCampoValor(foco) {
    setFocoValor(foco)
  }

  function verificarSeExiste(item: boolean, texto: string) {
    if (!item) return ''
    return texto
  }

  function obterItensContemplados() {
    var itens = []

    let dados: IPacoteInteligenteForm = pacoteSelecionado

    itens.push(' valor do hospital')
    itens.push(
      verificarSeExiste(
        !!dados?.tiposAnestesia.find(i => !!i.selecionado),
        'anestesia'
      )
    )

    itens.push(
      verificarSeExiste(
        !!dados?.examesDuranteInternacao.find(i => !!i.selecionado),
        'exames '
      )
    )
    itens.push(
      verificarSeExiste(dados?.opmes?.length !== 0, "OPME's, taxa de OPME")
    )
    itens.push('taxa de serviço')
    itens.push(
      verificarSeExiste(
        !!dados?.reservaSangue,
        'reserva de sangue, bolsa(s) de sangue'
      )
    )

    itens.push(
      verificarSeExiste(!dados?.semHonorarioMedico, ' honorários equipe médica')
    )

    itens = itens.filter(i => !!i)

    if (itens.length > 0) {
      var texto = itens.slice(0, -1).join(', ')
      texto += (itens.length > 1 ? ' e ' : '') + itens.slice(-1)
      return primeiraLetraMaiusculo(`${texto}`)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    var listaStepRevisao = [5, 6]
    if (
      activeStep === 5 ||
      (!ajustarPacote && listaStepRevisao.includes(activeStep))
    ) {
      obterValores()
    }
  }, [activeStep, ajustarPacote])

  function obterValorEquipeMedica(somarHonorarios = false) {
    var honorario = pacoteSelecionado?.honorarioEquipeMedica

    var equipe = pacoteSelecionado?.membros.reduce(
      (partialSum, i) => partialSum + i.valor,
      0
    )
    if (somarHonorarios) {
      return honorario + equipe
    }
    return equipe
  }

  function formatarValor(valor: number, forcaAdaptar = false) {
    let valorFormatado = valor / (forcaAdaptar ? 100 : 1)

    return formatarDinheiro(valorFormatado)
  }

  function retornaPrimeiraEtapa(callback) {
    if (activeStep === 0) return 0

    return callback
  }

  const handlePodeAmbulatorio = e => {
    let valueRadio = e.target.value
    let valorBoolean = false

    handleItemValue('centroCirurgicoAmbulatorial', parseInt(valueRadio))

    if (valueRadio === '2') {
      valorBoolean = true
    }
    pacoteSelecionado.podeCentroCirurgicoAmbulatorial = valorBoolean
    setPacoteSelecionado(pacoteSelecionado)
  }

  const handlePodePlanoDeSaude = e => {
    let valueRadio = e.target.value
    let valorBoolean = false

    handleItemValue('temPlanoDeSaude', parseInt(valueRadio))

    if (valueRadio === '1') {
      valorBoolean = true
    }
    pacoteSelecionado.temPlanoDeSaude = valorBoolean
    setPacoteSelecionado(pacoteSelecionado)
  }

  useEffect(() => {
    if (valoresOrcamentos?.length > 0) {
      let maxValor = -Infinity
      let minValor = Infinity

      valoresOrcamentos.forEach(orcamento => {
        if (orcamento.valor > maxValor) {
          maxValor = orcamento.valor
        }
        if (orcamento.valor < minValor) {
          minValor = orcamento.valor
        }
      })

      setMaiorValor(maxValor)
      setMenorValor(minValor)
    }
  }, [valoresOrcamentos])

  function handleTornarTemplate() {
    setOpenModal(true)
    setIsLoading(true)
  }

  function handleConfirmarTornarTemplate(tornarTemplate: boolean) {
    onSubmit(command, tornarTemplate)
    setOpenModal(false)
  }

  const resumoRef = useRef(null)
  const [showButton, setShowButton] = useState(false)

  const handleScroll = () => {
    if (resumoRef.current) {
      const { top } = resumoRef.current.getBoundingClientRect()
      if (top < window.innerHeight) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    }
  }

  function gerarDescricao(item) {
    let partes = []

    if (
      item?.propostaCentroCirurgicoAmbulatorial === true &&
      item?.propostaDiasUteis === true
    ) {
      partes.push(
        'em qualquer dia e horário da semana no centro cirúrgico ambulatorial.'
      )
    }
    if (
      item?.propostaCentroCirurgicoAmbulatorial === true &&
      item?.propostaFinalDeSemana === true
    ) {
      partes.push(
        'somente em finais de semana no centro cirúrgico ambulatorial.'
      )
    }
    if (
      item?.propostaCentroCirurgicoAmbulatorial === false &&
      item?.propostaDiasUteis === true
    ) {
      partes.push('em qualquer dia e horário da semana no centro cirúrgico.')
    }
    if (
      item?.propostaCentroCirurgicoAmbulatorial === false &&
      item?.propostaFinalDeSemana === true
    ) {
      partes.push('somente em finais de semana no centro cirúrgico.')
    }
    if (
      item?.propostaDiasUteis === false &&
      item?.propostaFinalDeSemana === false
    ) {
      partes.push('em qualquer dia e horário no centro cirúrgico ambulatorial.')
    }
    let descricao = 'Para realização '
    descricao += partes
    return descricao
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    setNomeUsuario(user.perfisPorTenant.filter(p => p.tenantId === tenantAtual))
  }, [user])

  return (
    <>
      {loadingOrcamento && (
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      )}
      <Box
        hidden={loadingOrcamento}
        component="main"
        className={`fadeInTransition ${classes.container}`}
      >
        <CssBaseline />
        {/* erros: {JSON.stringify(errors.centroCirurgicoAmbulatorial)} */}
        {/* pacote: {JSON.stringify(pacoteSelecionado)} */}
        <Grid container>
          {!ehXS && activeStep !== 0 && (
            <Grid item xs={12} sm={1}>
              <Box mt={5} pt={2} ml={4}>
                <Stepper
                  connector={<StepConnectorSyn />}
                  activeStep={activeStep}
                  style={{ margin: 0, padding: 0, background: 'none' }}
                  orientation="vertical"
                >
                  {getSteps(lastStep)}
                </Stepper>
              </Box>
            </Grid>
          )}

          <Grid item xs={12} sm={activeStep === 0 ? 12 : 10}>
            <Box
              mt={retornaPrimeiraEtapa(ehXS ? 2 : 5)}
              pt={retornaPrimeiraEtapa(ehXS ? 0 : 2)}
              p={retornaPrimeiraEtapa(ehXS ? 2 : 0)}
            >
              <form className={classes.form} noValidate={true}>
                <StepContentSyn
                  noMarginTop
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 0}
                  hideBack
                  handleBackToStep={handleBackToStep}
                  handleNext={() => {
                    if (!pacoteSelecionado?.mediaTempoCirurgico) {
                      setErroTempoCirurgico(true)
                      return Swal.fire({
                        title: 'Atenção!',
                        icon: 'warning',
                        text:
                          'Média de tempo cirúrgico é um campo obrigatório. Favor ajustar'
                      })
                    }
                    handleNext()
                  }}
                  textoTitulo=""
                >
                  {loadingPacote || loadingMembros ? (
                    <LoadingGenerico />
                  ) : (
                    !!pacoteSelecionado &&
                    !loadingMembros && (
                      <ResumoPacoteInteligenteOps
                        unificarOpmes
                        selecionado
                        medico
                        listaMembros={listaMembrosEquipe}
                        pacote={pacoteSelecionado}
                        esconderIncluso={true}
                        adaptarValor={false}
                        forcarHonorarios
                        exibirAjusteEtapa
                        handleAjusteEtapa={handleAjusteEtapa}
                        handleAjusteEtapaSolicitacao={
                          handleAjusteEtapaSolicitacao
                        }
                        exibirDadosMedicos
                        erro={erroTempoCirurgico}
                      />
                    )
                  )}
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 1}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={'Quem é o seu paciente?'}
                >
                  <Grid container spacing={1}>
                    <Alert severity="info">
                      Caso seja menor de idade ou não possuir CPF, marque a
                      opção abaixo "Paciente necessita de responsável"
                    </Alert>
                    <Box>
                      <Grid
                        container
                        spacing={1}
                        style={{ width: '100%' }}
                        className={classes.noMargin}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Checkbox
                              checked={pacienteResponsavel}
                              onClick={() =>
                                setPacienteResponsavel(!pacienteResponsavel)
                              }
                            />
                            Paciente necessita de responsável
                          </Typography>
                        </Grid>
                        {pacienteResponsavel && (
                          <>
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                              <Typography variant="subtitle2">
                                Responsável
                              </Typography>
                              <Divider style={{ margin: '10px 0' }} />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                erro={errors}
                                loading={loadingPaciente}
                                name="cpfResponsavel"
                                placeholder="Ex: 999.999.999-99"
                                label="CPF do responsável"
                                type="tel"
                                hiddenLabel
                                control={control}
                                mask="999.999.999-99"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                InputProps={{
                                  autoComplete: 'new-password'
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                loading={loadingPaciente}
                                erro={errors}
                                name="nomeResponsavel"
                                label="Nome completo do responsável"
                                inputRef={register}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                inputProps={{ maxLength: 100 }}
                              />
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                              <Typography variant="subtitle2">
                                Paciente
                              </Typography>
                              <Divider style={{ margin: '10px 0' }} />
                            </Grid>
                          </>
                        )}
                        {!pacienteResponsavel && (
                          <Grid item xs={12}>
                            <TextField
                              loading={loadingPaciente}
                              erro={errors}
                              name="cpfPaciente"
                              placeholder="Ex: 999.999.999-99"
                              label="CPF"
                              type="tel"
                              hiddenLabel
                              control={control}
                              mask="999.999.999-99"
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={6}>
                          <TextField
                            erro={errors}
                            name="nomePaciente"
                            label="Nome completo"
                            inputRef={register}
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{ maxLength: 100 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DateInput
                            errors={errors}
                            name="dataNascimentoPaciente"
                            label="Data de nascimento"
                            value={command.dataNascimentoPaciente}
                            control={control}
                            handleChange={value =>
                              handleItemValue('dataNascimentoPaciente', value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            erro={errors}
                            name="telefonePaciente"
                            maskTelCel
                            value={command.telefonePaciente}
                            label="Telefone"
                            type="tel"
                            control={control}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            erro={errors}
                            name="emailPaciente"
                            label="E-mail"
                            inputRef={register}
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{ maxLength: 100 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <RadioGroup
                            onChange={handleRadios}
                            value={command.sexoPaciente}
                          >
                            <Grid container direction="row">
                              <FormControlLabel
                                control={
                                  <Radio
                                    value={genero.Masculino}
                                    color="primary"
                                    name="pacienteSexo"
                                  />
                                }
                                label="Masculino"
                              />
                              <FormControlLabel
                                control={
                                  <Radio
                                    value={genero.Feminino}
                                    color="primary"
                                    name="pacienteSexo"
                                  />
                                }
                                label="Feminino"
                              />
                            </Grid>
                          </RadioGroup>
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            <FormHelperText error={!!errors.sexoPaciente}>
                              {errors.sexoPaciente?.message}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <UBox ml={0.5} render={!!pacoteSelecionado?.planoDeSaude}>
                          <Typography variant="h6">
                            Paciente possui plano de saúde?
                          </Typography>
                          <Grid container spacing={0}>
                            <Grid item xs={12}>
                              <RadioGroup
                                style={{ flexDirection: 'row' }}
                                onChange={handlePodePlanoDeSaude}
                                value={command?.temPlanoDeSaude || 0}
                              >
                                <FormControlLabel
                                  control={
                                    <Radio
                                      value={1}
                                      color="primary"
                                      name="temPlanoDeSaude"
                                    />
                                  }
                                  label="Sim"
                                />
                                <FormControlLabel
                                  control={
                                    <Radio
                                      value={2}
                                      color="primary"
                                      name="temPlanoDeSaude"
                                    />
                                  }
                                  label="Não"
                                />
                              </RadioGroup>
                              <FormControl fullWidth className={classes.formControl}>
                                <FormHelperText error={!!errors.temPlanoDeSaude}>
                                  {errors.temPlanoDeSaude?.message}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </UBox>
                      </Grid>
                    </Box>
                  </Grid>
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 2}
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  handleNext={() => {
                    if (!semComorbidade && command.comorbidades.length === 0) {
                      return setComorbidadeMensagemErro(true)
                    }
                    handleNext()
                  }}
                  textoTitulo={
                    'Seu paciente possui comorbidades? Caso não tenha clique em "CONTINUAR"'
                  }
                >
                  <>
                    <Typography style={{ margin: '20px 0 0' }}>
                      <Checkbox
                        checked={semComorbidade}
                        onChange={() => toggleComorbidades()}
                      />{' '}
                      Não possui comorbidade
                    </Typography>
                    {comorbidadeMensagemErro && (
                      <Typography variant="body2" style={{ color: 'red' }}>
                        Marcar caso o paciente não tenha comorbidade
                      </Typography>
                    )}
                    {!semComorbidade && (
                      <Autocomplete
                        multiple
                        erro={errors}
                        name="comorbidades"
                        label="Comorbidades"
                        loading={loadingComorbidades}
                        options={listaComorbidades}
                        value={command.comorbidades}
                        getOptionSelected={option =>
                          !!command.comorbidades.find(l => l.id === option.id)
                        }
                        showCheck
                        showAddButton
                        getOptionLabel={option => option.descricao}
                        onChange={(
                          event,
                          newValue: ObjetoDescricaoGenerico[]
                        ) => {
                          setSemComorbidade(false)
                          setComorbidadeMensagemErro(false)
                          handleItemValue(
                            'comorbidades',
                            newValue as ObjetoDescricaoGenerico[]
                          )
                        }}
                      />
                    )}
                    <Typography variant="body2" style={{ marginTop: '20px' }}>
                      Caso não encontre o item na lista acima, favor descrevê-lo
                      no campo abaixo
                    </Typography>
                    <TextField
                      erro={errors}
                      multiline={true}
                      rows={4}
                      inputRef={register}
                      name="observacaoComorbidades"
                      label="Observações"
                      inputProps={{ maxLength: 500 }}
                    />
                  </>
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 3}
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Caso tenha alguma consideração a fazer, sinta-se à vontade para informar abaixo'
                  }
                >
                  <TextField
                    erro={errors}
                    multiline={true}
                    rows={4}
                    inputRef={register}
                    name="observacoes"
                    label="Observações"
                    inputProps={{ maxLength: 500 }}
                  />
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={existeEtapaDeSangue && activeStep === 4}
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Será necessário reserva de sangue? Caso não necessite clique em "Continuar"'
                  }
                >
                  <TextField
                    erro={errors}
                    name="reservaSangue"
                    placeholder="Ex: 2 concentrados de hemácias"
                    label="Qtd. concentrados de hemácias"
                    control={control}
                    type="tel"
                    mask="999"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <Alert severity="info" style={{ marginTop: '20px' }}>
                    Caso necessitar de plasma, concentrados de plaquetas ou
                    outros, favor informar no campo abaixo de observações
                  </Alert>
                  <TextField
                    erro={errors}
                    multiline={true}
                    rows={4}
                    name="observacaoSangue"
                    label="Observações"
                    inputRef={register}
                    inputProps={{ maxLength: 500 }}
                  />
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={
                    existeEtapaFinalDeSemana &&
                    activeStep === (existeEtapaDeSangue ? 5 : 4)
                  }
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  handleNext={() => {
                    if (!semComorbidade && command.comorbidades.length === 0) {
                      return setComorbidadeMensagemErro(true)
                    }
                    handleNext()
                  }}
                >
                  <>
                    <UBox
                      my={2}
                      render={!!pacoteSelecionado?.propostaFinalDeSemana}
                    >
                      <UBox>
                        <Typography variant="h6">
                          Deseja gerar propostas para realizar a cirurgia
                          durante:
                        </Typography>
                        <Typography>
                          (Você deve selecionar uma ou mais opções)
                        </Typography>
                      </UBox>
                      <UBox>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!command.podeDiasUteis}
                                onChange={(e, value) =>
                                  handleItemValue('podeDiasUteis', value)
                                }
                                value={command.podeDiasUteis}
                              />
                            }
                            label="Dias úteis (segunda a sábado até as 12h)"
                          />
                        </FormGroup>
                        <FormControl fullWidth className={classes.formControl}>
                          <FormHelperText error={!!errors.podeDiasUteis}>
                            {errors.podeDiasUteis?.message}
                          </FormHelperText>
                        </FormControl>
                      </UBox>
                      <UBox>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!command.podeFinalDeSemana}
                                onChange={(e, value) =>
                                  handleItemValue('podeFinalDeSemana', value)
                                }
                                value={command.podeFinalDeSemana}
                              />
                            }
                            label="Final de semana e feriados (sábado a partir de 12h até domingo 23h59 e feriados)"
                          />
                        </FormGroup>
                        <FormControl fullWidth className={classes.formControl}>
                          <FormHelperText error={!!errors.podeFinalDeSemana}>
                            {errors.podeFinalDeSemana?.message}
                          </FormHelperText>
                        </FormControl>
                      </UBox>
                    </UBox>

                    <UBox
                      my={2}
                      render={
                        !!pacoteSelecionado?.propostaCentroCirurgicoAmbulatorial
                      }
                    >
                      <Typography variant="h6">
                        Deseja gerar propostas para:
                      </Typography>
                      <Typography>(Você deve selecionar uma opção)</Typography>
                      <Grid item xs={12} style={{ padding: '10px' }}>
                        <RadioGroup
                          style={{ flexDirection: 'row' }}
                          value={command?.centroCirurgicoAmbulatorial || 0}
                          onChange={handlePodeAmbulatorio}
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                value={1}
                                color="primary"
                                name="centroCirurgicoAmbulatorial"
                              />
                            }
                            label="Centro cirúrgico"
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                value={2}
                                color="primary"
                                name="centroCirurgicoAmbulatorial"
                              />
                            }
                            label="Centro cirúrgico ambulatorial"
                          />
                        </RadioGroup>
                        <FormControl fullWidth className={classes.formControl}>
                          <FormHelperText
                            error={!!errors.centroCirurgicoAmbulatorial}
                          >
                            {errors.centroCirurgicoAmbulatorial?.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </UBox>
                  </>
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 4 + qtdEtapasDinamicas}
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Por favor confirme os honorários do médico principal e de sua equipe'
                  }
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <UBox
                        my={2}
                        style={{
                          border: '1px solid #ccc',
                          borderRadius: '8px',
                          padding: '20px',
                          background: '#fff',
                          fontWeight: 400,
                          maxWidth: '500px',
                          margin: '0 auto',
                          textAlign: 'left'
                        }}
                      >
                        <UBox mb={2}>
                          <Typography
                            variant="body1"
                            color="secondary"
                            align="center"
                          >
                            <b>Honorário médico + Equipe médica</b>
                          </Typography>
                        </UBox>

                        <Grid container>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              Honorário médico
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: 'right' }}>
                            <Typography variant="body1">
                              {formatarValor(
                                pacoteSelecionado?.honorarioEquipeMedica,
                                true
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <Typography variant="body1">
                              Equipe médica
                            </Typography>
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: 'right' }}>
                            <Typography variant="body1">
                              {formatarValor(obterValorEquipeMedica(), true)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <UBox
                          my={1}
                          pt={1}
                          style={{ borderTop: '2px dashed #ddd' }}
                        >
                          <Typography variant="body1" align="right">
                            <b>
                              {formatarValor(
                                obterValorEquipeMedica(true),
                                true
                              )}
                            </b>
                          </Typography>
                        </UBox>
                      </UBox>
                    </Grid>
                  </Grid>

                  <UBox mt={2}>
                    <UBox display="flex" justifyContent="space-between">
                      <Typography variant="body1" color="primary">
                        <b>Equipe médica</b>
                      </Typography>

                      <Button
                        onClick={() => handleAjusteEtapa(Steps.membrosEquipe)}
                        size="small"
                        variant="outlined"
                      >
                        <MdEdit style={{ marginRight: 4 }} />
                        Ajustar equipe
                      </Button>
                    </UBox>

                    {pacoteSelecionado?.membros?.map((membro, i) => (
                      <Grid key={i} container spacing={0}>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                          }}
                        >
                          <Typography variant="body1">
                            {membro?.descricao?.toUpperCase()} (
                            {formatarValor(membro?.valor, true)})
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <UBox my={1}>
                            <Divider />
                          </UBox>
                        </Grid>
                      </Grid>
                    ))}
                  </UBox>
                  <UBox mt={3}>
                    <UBox>
                      <Typography variant="body1" color="primary">
                        <b>Honorário do médico principal</b>
                      </Typography>
                    </UBox>
                    <UBox pt={2} hidden={!!command.semHonorarioMedico}>
                      <CurrencyInput
                        fullWidth
                        erro={errors}
                        size="small"
                        variant="outlined"
                        name="honorario"
                        label="Honorário médico"
                        hiddenLabel
                        control={control}
                        type="tel"
                        InputLabelProps={{
                          shrink: true
                        }}
                        defaultValue={command?.honorarioEquipeMedica}
                        onValueChange={value => {
                          handleItemValue('honorarioEquipeMedica', value)
                          var novoHonorario = value || 0
                          pacoteSelecionado.honorarioEquipeMedica = novoHonorario
                          setPacoteSelecionado(pacoteSelecionado)
                        }}
                        value={command?.honorarioEquipeMedica || 0}
                      />
                    </UBox>
                    <UBox>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!command.semHonorarioMedico}
                              onChange={(e, value) => {
                                pacoteSelecionado.semHonorarioMedico = value
                                handleItemValue('semHonorarioMedico', value)
                              }}
                              value={command.semHonorarioMedico}
                            />
                          }
                          label="Não cobrar honorário médico!"
                          style={{ fontSize: '12px' }}
                        />
                      </FormGroup>
                    </UBox>
                  </UBox>
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === 5 + qtdEtapasDinamicas}
                  hideBack={false}
                  hideNext={true}
                  handleBackToStep={handleBackToStep}
                  textoTitulo={
                    'Ótimo! Agora revise as informações com ATENÇÃO antes de salvar'
                  }
                  showFinish={showButton} // Control visibility based on state
                  handleFinish={handleSubmit(handleTornarTemplate)}
                  textFinish={command.temPlanoDeSaude === 1 ? 'Salvar' : 'Enviar para o paciente'}
                  loading={isloading}
                >
                  <Box mb={ehXS ? 3 : 0}>
                    <Typography variant="subtitle1">
                      Caso precise alterar alguma informação, clique no botão
                      'Voltar' até chegar na etapa desejada.
                    </Typography>
                    <ResumoOrcamento
                      pacoteSelecionado={pacoteSelecionado as any}
                      pacoteInteligente
                      esconderIncluso={true}
                      forcarHonorarios
                      orcamento={command}
                      isMedico
                      exibirAjusteEtapa={true}
                      handleAjusteEtapa={handleAjusteEtapa}
                      handleAjusteEtapaSolicitacao={
                        handleAjusteEtapaSolicitacao
                      }
                    />
                  </Box>
                  <Grid ref={resumoRef}>
                    <UBox
                      render={command?.temPlanoDeSaude !== 1}
                      textLoading="Gerando orçamentos..."
                      loading={loadingValoresOrcamentos}
                      mt={2}
                    >
                      <b>
                        Resumo
                        {valoresOrcamentos?.length < 2
                          ? ' da proposta para o ' +
                          nomeUsuario[0]?.descricao +
                          ':'
                          : ' das propostas para o ' +
                          nomeUsuario[0]?.descricao +
                          ':'}
                      </b>
                      {/* </Typography> */}
                      <Grid container spacing={1} ref={resumoRef}>
                        {valoresOrcamentos?.length <= 2 && (
                          <>
                            {valoresOrcamentos?.map(item => (
                              <UBox
                                key={item?.internacaoId}
                                render={true}
                                component={Grid}
                                item
                                xs={12}
                                sm={12}
                              >
                                <UBox mt={2} mb={2} component={Card}>
                                  <Box p={2}>
                                    <Grid container direction="column">
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          width: '100%',
                                          overflowX: 'hidden'
                                        }}
                                        color="primary"
                                      >
                                        <UBox maxWidth="90%" mb={2}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              whiteSpace: 'nowrap',
                                              textTransform: 'uppercase'
                                            }}
                                            color="primary"
                                          >
                                            <b>{'Proposta ' + item.descricao}</b>
                                          </Typography>
                                          <Typography
                                            variant="subtitle2"
                                            style={{ whiteSpace: 'nowrap' }}
                                          >
                                            {item?.propostaCentroCirurgicoAmbulatorial ===
                                              true ? (
                                              <UBox
                                                display="flex"
                                                alignItems="center"
                                                style={{ gap: '8px' }}
                                              >
                                                <BiPlusMedical />
                                                <b>
                                                  <small>
                                                    Centro cirúrgico ambulatorial
                                                  </small>
                                                </b>
                                              </UBox>
                                            ) : (
                                              <UBox
                                                display="flex"
                                                alignItems="center"
                                                style={{ gap: '8px' }}
                                              >
                                                <BiPlusMedical />
                                                <b>
                                                  <small>Centro cirúrgico</small>
                                                </b>
                                              </UBox>
                                            )}
                                          </Typography>
                                          {item?.propostaFinalDeSemana && (
                                            <Typography
                                              variant="subtitle2"
                                              style={{ whiteSpace: 'nowrap' }}
                                            >
                                              <UBox
                                                display="flex"
                                                alignItems="center"
                                                style={{ gap: '8px' }}
                                              >
                                                <FcCalendar />
                                                <b>
                                                  <small>Final de semana</small>
                                                </b>
                                              </UBox>
                                            </Typography>
                                          )}
                                        </UBox>

                                        <UBox
                                          render={
                                            item.propostaCentroCirurgicoAmbulatorial ===
                                            false &&
                                            item.propostaFinalDeSemana === false
                                          }
                                        >
                                          <Alert
                                            severity="warning"
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'flex-start',
                                              padding: '6px'
                                            }}
                                            iconMapping={{
                                              warning: (
                                                <FiInfo
                                                  style={{ color: 'orange' }}
                                                />
                                              )
                                            }}
                                          >
                                            Para realização em qualquer dia e
                                            horário da semana no centro cirúrgico.
                                          </Alert>
                                        </UBox>

                                        <UBox
                                          render={
                                            item.propostaCentroCirurgicoAmbulatorial ===
                                            true ||
                                            item.propostaFinalDeSemana === true
                                          }
                                        >
                                          <Alert
                                            severity="warning"
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'flex-start',
                                              padding: '6px'
                                            }}
                                            iconMapping={{
                                              warning: (
                                                <FiInfo
                                                  style={{ color: 'orange' }}
                                                />
                                              )
                                            }}
                                          >
                                            {gerarDescricao(item)}
                                            <br />
                                            {item.propostaDiasUteis === true &&
                                              'Segunda a sábado até as 12h'}
                                            {item.propostaFinalDeSemana ===
                                              true &&
                                              'Sábado a partir de 12h até domingo 23h59 e feriados.'}
                                          </Alert>
                                        </UBox>

                                        <UBox maxWidth="90%" mt={1}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{ whiteSpace: 'nowrap' }}
                                          >
                                            <b>
                                              <small>Valor total:</small>
                                            </b>{' '}
                                            {formatarDinheiro(item?.valor)}
                                          </Typography>
                                        </UBox>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </UBox>
                              </UBox>
                            ))}
                          </>
                        )}

                        {valoresOrcamentos?.length > 2 && (
                          <>
                            {/* <UBox
                            render={!!valoresOrcamentos.valor}
                            component={Grid}
                            item
                            xs={12}
                            sm={6}
                          >
                            <UBox mt={2} mb={2} component={Card}>
                              <Box p={2}>
                                <Grid
                                  container
                                  direction="row"
                                  alignItems="center"
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      width: '100%',
                                      overflowX: 'hidden'
                                    }}
                                  >
                                    <UBox maxWidth="90%" mb={2}>
                                      <Typography
                                        variant="subtitle2"
                                        style={{
                                          whiteSpace: 'nowrap',
                                          textTransform: 'uppercase'
                                        }}
                                        color="primary"
                                      >
                                        <b>
                                          {!!pacoteSelecionado?.pqa &&
                                            INTERNACOES_OBJ.PQA.descricao}
                                          {!!pacoteSelecionado?.dayClinic &&
                                            INTERNACOES_OBJ.DAY_CLINIC
                                              .descricao}
                                        </b>
                                      </Typography>
                                    </UBox>
                                    <Typography
                                      variant="subtitle2"
                                      style={{ whiteSpace: 'nowrap' }}
                                    >
                                      <b>
                                        <small>Valor total:</small>
                                      </b>{' '}
                                      {formatarDinheiro(
                                        valoresOrcamentos[0]?.valor
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </UBox>
                          </UBox> */}
                            <UBox component={Grid} item xs={12} sm={12}>
                              <UBox mt={2} mb={2} component={Card}>
                                <Box p={2}>
                                  <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      style={{
                                        width: '100%',
                                        overflowX: 'hidden'
                                      }}
                                    >
                                      <UBox maxWidth="90%" mb={2}>
                                        <Typography
                                          variant="subtitle2"
                                          style={{
                                            textTransform: 'uppercase'
                                          }}
                                          color="primary"
                                        >
                                          <b>
                                            {`Esta solicitação gerou um total de ${valoresOrcamentos?.length
                                              } ${valoresOrcamentos?.length > 1
                                                ? ' propostas'
                                                : ' proposta'
                                              }`}
                                          </b>
                                        </Typography>
                                      </UBox>
                                      <UBox
                                        render={valoresOrcamentos?.length >= 2}
                                        component={Grid}
                                        item
                                        xs={12}
                                        sm={12}
                                        style={{
                                          display: 'flex',
                                          gap: '12px',
                                          flexDirection: ehXS ? 'column' : 'row'
                                        }}
                                      >
                                        <UBox>
                                          <Alert
                                            severity="info"
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              padding: '8px 16px'
                                            }}
                                          >
                                            <strong>Menor</strong> proposta:{' '}
                                            {formatarDinheiro(menorValor)}
                                          </Alert>
                                        </UBox>
                                        <UBox>
                                          <Alert
                                            severity="info"
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              padding: '8px 16px'
                                            }}
                                          >
                                            <strong>Maior</strong> proposta:{' '}
                                            {formatarDinheiro(maiorValor)}
                                          </Alert>
                                        </UBox>
                                      </UBox>
                                      <UBox
                                        maxWidth="90%"
                                        mt={1}
                                        render={valoresOrcamentos?.length === 1}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          style={{ whiteSpace: 'nowrap' }}
                                        >
                                          <b>
                                            <small>Valor total:</small>
                                          </b>{' '}
                                          {formatarDinheiro(maiorValor)}
                                        </Typography>
                                      </UBox>
                                      <UBox maxWidth="100%" mt={1}>
                                        <Button
                                          onClick={() =>
                                            handleOpenDrawerPropostas()
                                          }
                                          fullWidth
                                          size="small"
                                          variant="outlined"
                                        >
                                          Visualizar propostas
                                        </Button>
                                      </UBox>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </UBox>
                            </UBox>
                            <UBox
                              render={!!valoresOrcamentos}
                              component={Grid}
                              item
                              xs={12}
                              sm={12}
                            >
                              <Alert severity="info">
                                Os valores das propostas apresentadas contemplam:{' '}
                                {obterItensContemplados()}
                              </Alert>
                            </UBox>
                          </>
                        )}
                      </Grid>
                    </UBox>
                  </Grid>
                  {valoresOrcamentos?.length <= 2 && (
                    <UBox maxWidth="100%" mt={1}>
                      <Button
                        onClick={handleBack}
                        fullWidth
                        size="large"
                        variant="outlined"
                      >
                        <MdEdit />
                        Alterar honorários
                      </Button>
                    </UBox>
                  )}
                </StepContentSyn>
              </form>
            </Box>
          </Grid>
        </Grid>
        {!!showModal && <ModalAddGenerico open={true} {...dadosModal()} />}
      </Box>
      <DrawerAjustarPacoteInteligente
        Form={FormConfiguracaoV2}
        open={ajustarPacote}
        etapa={etapaAjuste}
        pacote={pacoteSelecionado}
        handleOnClose={handleFecharAjustarPacote}
        handleSalvarAjuste={handleSalvarAjuste}
      />

      <DrawerPropostas
        propostas={valoresOrcamentos}
        open={openDrawerPropostas}
        handleOnClose={handleFecharPropostas}
        handleBack={handleBack}
      />

      {openModal && (
        <ModalTransformTemplate
          open={openModal}
          handleClose={() => handleConfirmarTornarTemplate(false)}
          showConfirm={() => handleConfirmarTornarTemplate(true)}
        />
      )}
    </>
  )
}

export function LoadingGenerico(props) {
  return (
    <Grid
      direction="column"
      item
      xs={12}
      style={{
        padding: 48,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <CircularProgress />
      <Typography variant="subtitle1" component="p">
        Carregando...
      </Typography>
    </Grid>
  )
}
