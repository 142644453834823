import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Search } from '@material-ui/icons'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputAdornment,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  Menu,
  Chip,
  Switch
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'
import formatarCPF from 'src/core/formatacoes/formatarCPF'
import useListagem from 'src/hooks/useListagem'
import {
  listagemMedico,
  listagemAnotacoesMedico,
  obterResumoMedico,
  listagemMedicoHospital
} from 'src/api/medico/index'
import {
  ItemListagemMedicoResponse,
  ItemAnotacaoMedico,
  ResumoMedicoComAssistente,
  ItemListagemSolicitacaoOrcamentoResponse,
  ItemListagemMedicoParceiroResponse
} from 'src/types'
import DrawerObs from 'src/pages/dashboard/operacional/medicos/drawerObs'
import DrawerResumoMedico from 'src/pages/dashboard/operacional/medicos/drawerResumoMedico'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { renderChipStatusMedico } from 'src/core/consts/statusMedico'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import useWindowInfiniteScroll from 'src/hooks/useWindowInfiniteScroll'
import Button from 'src/components/ui/Button'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { SynRoutes } from '../../../../../core/consts/synRoutes'
import AddIcon from '@material-ui/icons/Add'
import { useQuery } from 'src/hooks/useQuery'
import { ResumoImpressaoOrcamento } from '../../../medico/solicitacao/resumoImpressao'
import StatusMedicoLocalAtendimento, {
  renderChipStatusMedicoLocalAtendimento
} from 'src/core/consts/statusMedicoLocalAtendimento'

function listagemMedicos() {
  const history = useHistory()
  const query = useQuery()
  const {
    loading,
    dados,
    handleTermoPesquisa,
    termoPesquisa,
    nenhumDado,
    nenhumResultado,
    dadosCarregados,
    carregarListagem,
    allDados,
    qtdAllDados,
    handleProxPagina,
    allDadosCarregados,
    loadingPagina,
    handleFiltroCriadoPorHospital,
    filtroCriadoPorHospital
  } = useListagem(9, listagemMedicoHospital)

  const [lastSelectedCard, setLastSelectedCard] = useState(null)
  const [mostrarObs, setMostrarObs] = useState(false)
  const [medicoId, setMedicoId] = useState('')
  const [anotacoes, setAnotacoes] = useState<ItemAnotacaoMedico[]>(null)
  const [itemPrint, setItemPrint] = useState(null)

  const [mostrarDetalhesMedico, setMostrarDetalhesMedico] = useState(false)
  const [resumoMedico, setResumoMedico] = useState<ResumoMedicoComAssistente>(
    null
  )
  const [loadingResumoMedico, setLoadingResumoMedico] = useState(true)

  const [filtroStatus, setFiltroStatus] = useState<string>(' ')

  useWindowInfiniteScroll({
    loadMoreFunc: handleProxPagina,
    data: allDados,
    stopLoadingData: allDados.length >= qtdAllDados
  })

  function handleImprimir(item: ItemListagemSolicitacaoOrcamentoResponse) {
    setItemPrint(item.id)
  }

  function handleFecharImprimir() {
    setItemPrint(null)
  }

  useEffect(() => {
    if (query.has('print')) {
      let print = query.get('print')
      setItemPrint(print)
      history.replace(SynRoutes.listagemOrcamentosHospital)
    }
  }, [])

  function handleOpenObs(item: ItemListagemMedicoParceiroResponse) {
    setLastSelectedCard(item)
    setMostrarObs(true)
    setMedicoId(item.medico.id)
    listagemAnotacoesMedico(item.medico.id)
      .then(response => {
        setAnotacoes(response.data.items)
      })
      .catch(err => showNotificationErrorAPI(err))
  }

  function handleCloseObs() {
    setAnotacoes(null)
    setMostrarObs(false)
  }

  function handleOpenDetalhes(item: ItemListagemMedicoParceiroResponse) {
    setLastSelectedCard(item)
    setLoadingResumoMedico(true)
    setMostrarDetalhesMedico(true)
    setMedicoId(item.medico.id)

    obterResumoMedico(item.medico.id)
      .then(response => {
        setResumoMedico(response.data)
        setLoadingResumoMedico(false)
      })
      .catch(err => {
        showNotificationErrorAPI(err)
        setLoadingResumoMedico(false)
        setMostrarDetalhesMedico(false)
      })
  }

  function handleCloseDetalhes() {
    setResumoMedico(null)
    setMostrarDetalhesMedico(false)
  }

  function setClassToHighlight(item) {
    if (item?.medico?.id === lastSelectedCard?.medico?.id) return 'highlight'

    return null
  }

  function handleSolicitarOrcamento(medicoId: string) {
    history.push(`${SynRoutes.formOrcamentoHospital}?medicoId=${medicoId}`)
  }

  function handleNovoMedico() {
    history.push(`${SynRoutes.formCadastroMedico}`)
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5">Médicos</Typography>
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              placeholder="Pesquisar por nome, CRM, CPF, local de atendimento, etc..."
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              value={termoPesquisa}
              onChange={handleTermoPesquisa}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl variant="outlined" fullWidth size="small">
              <Select
                value={filtroCriadoPorHospital}
                onChange={e => handleFiltroCriadoPorHospital(e.target.value)}
              >
                <MenuItem value="0">Todos os médicos</MenuItem>
                <MenuItem value="1">Criados por hospital</MenuItem>
                <MenuItem value="2">Se cadastraram</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box textAlign="right">
              <Button
                variant="contained"
                color="primary"
                onClick={handleNovoMedico}
                startIcon={<AddIcon />}
              >
                Novo médico
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {!loading && (
        <Box mt={3} mb={2} textAlign="left" my={3}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color="primary">
                {qtdAllDados
                  ? qtdAllDados > 1
                    ? `${qtdAllDados} médicos`
                    : '1 médico'
                  : '0 médicos'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}

      {nenhumDado && !nenhumResultado && allDados.length === 0 && (
        <div>Nenhum médico cadastrado ainda.</div>
      )}

      {nenhumResultado && (
        <div>Nenhum resultado encontrado para sua pesquisa.</div>
      )}

      {allDadosCarregados && !loadingPagina && (
        <div className="fadeInTransition">
          <Grid container spacing={2}>
            {allDados.map(item => {
              return (
                <Grid item xs={12} md={4} key={item.medico.id}>
                  <Card
                    variant="outlined"
                    style={{ height: '100%' }}
                    className={setClassToHighlight(item)}
                  >
                    <CardHeader
                      action={
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {popupState => (
                            <React.Fragment>
                              <IconButton
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                aria-label="mais"
                                {...bindTrigger(popupState)}
                              >
                                <MoreVertIcon />
                              </IconButton>

                              <Menu
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                                {...bindMenu(popupState)}
                              >
                                <MenuItem
                                  onClick={() => handleOpenDetalhes(item)}
                                >
                                  Ver detalhes
                                </MenuItem>
                                {item.statusLocalAtendimento ===
                                  StatusMedicoLocalAtendimento.object.APROVADO
                                    .status && (
                                  <MenuItem
                                    onClick={() =>
                                      handleSolicitarOrcamento(item.medico.id)
                                    }
                                  >
                                    Solicitar orçamento
                                  </MenuItem>
                                )}
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      }
                      style={{ paddingBottom: 0 }}
                      title={
                        <Link
                          component="button"
                          variant="body1"
                          color="primary"
                          align="left"
                          onClick={() => handleOpenDetalhes(item)}
                        >
                          {item.medico.nome}
                        </Link>
                      }
                      titleTypographyProps={{ variant: 'subtitle1' }}
                      subheaderTypographyProps={{ variant: 'body2' }}
                      /* subheader={`CPF: ${
                        formatarCPF(item.cpf) || 'Não informado'
                      }`} */
                    />

                    <CardContent style={{ paddingTop: 8, paddingBottom: 16 }}>
                      {/* <Box>
                        <Typography variant="body2">
                          {`CRM: ${item.crm}`}
                        </Typography>
                      </Box> */}

                      <Box>
                        <Typography variant="body2">
                          {`Telefone: ${formatarTelefone(
                            item.medico.telefone
                          )}`}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography variant="body2">
                          {`Email: ${item.medico.email}`}
                        </Typography>
                      </Box>

                      <Box mt={1}>
                        <Typography variant="body2">
                          Especialidades:{' '}
                          {item.medico.especialidades?.map(e => e).join(', ')}
                        </Typography>
                      </Box>
                      <Box mt={1}>
                        {renderChipStatusMedicoLocalAtendimento(
                          item.statusLocalAtendimento
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
        </div>
      )}
      <Box
        mt={3}
        hidden={qtdAllDados === allDados.length}
        textAlign="center"
      >
        <Button
          fullWidth
          onClick={handleProxPagina}
          variant="text"
          disabled={loading}
          loading={loading}
          endIcon={<MdKeyboardArrowDown />}
        >
          Carregar mais
        </Button>
      </Box>

      <DrawerObs
        medicoId={medicoId}
        open={mostrarObs}
        handleOnClose={handleCloseObs}
        anotacoes={anotacoes || undefined}
        carregarListagem={carregarListagem}
      />
      <DrawerResumoMedico
        open={mostrarDetalhesMedico}
        handleOnClose={handleCloseDetalhes}
        resumoMedico={resumoMedico}
        loading={loadingResumoMedico}
        parceiro
      />

      <ResumoImpressaoOrcamento
        item={itemPrint}
        onClose={handleFecharImprimir}
      />
    </Box>
  )
}

export default listagemMedicos
