import React, { useEffect } from 'react'
import UBox from '../ui/Ubox'
import { FiArrowDown } from 'react-icons/fi'

interface IProps {
  cssSeletor: string
}

export default function scrollToBottom({ cssSeletor }: IProps) {
  const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
    const { top, left, bottom, right } = el.getBoundingClientRect()
    const { innerHeight, innerWidth } = window
    return partiallyVisible
      ? ((top > 0 && top < innerHeight) ||
          (bottom > 0 && bottom < innerHeight)) &&
          ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
      : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth
  }

  function scrollToBottom() {
    try {
      var yBottom =
        document.body.scrollHeight || document.documentElement.scrollHeight

      window.scrollTo({ left: 0, top: yBottom, behavior: 'smooth' })
    } catch (error) {
      window.scrollTo(0, yBottom)
    }
  }

  function scrollEvent() {
    if (!!window && !!document) {
      var elemento = document.querySelector(cssSeletor)
      var btnScroll = document.getElementById('btnScroll')

      if (
        elemento &&
        elementIsVisibleInViewport(elemento) &&
        !!btnScroll &&
        !!btnScroll.style
      ) {
        // console.log('visível => ', elementIsVisibleInViewport(elemento))
        btnScroll.style.display = 'none'
      } else {
        btnScroll.style.display = 'flex'
      }
    }
  }

  useEffect(() => {
    window?.addEventListener('scroll', scrollEvent)

    //ao destruir componente
    return () => window.removeEventListener('scroll', scrollEvent)
  }, [])

  return (
    <UBox
      style={{
        borderRadius: '2px',
        // background: 'var(--main-blue, #605DEC)',
        background: 'var(--main-blue, #21ebc7)',
        position: 'fixed',
        bottom: '24px',
        right: '12px',
        color: '#fff',
        fontSize: '1.4rem',
        zIndex: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50px',
        height: '50px',
        animation: 'fadeIn 1s ',
        cursor: 'pointer'
      }}
      // p={2}
      onClick={() => scrollToBottom()}
      id="btnScroll"
    >
      <FiArrowDown />
    </UBox>
  )
}
