import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { Alert } from '@material-ui/lab'

import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect } from 'react'
import {
  RiArrowLeftLine,
  RiCheckDoubleLine,
  RiDeleteBin6Line
} from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { Pagamento } from 'src/api/pagamento'
import Button from 'src/components/ui/Button'
import UBox from 'src/components/ui/Ubox'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { formatarDataHoraLocal } from 'src/core/formatacoes/formatarData'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import {
  showNotification,
  showNotificationErrorAPI
} from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import usePagamento from 'src/hooks/usePagamento'
import { useQuery } from 'src/hooks/useQuery'
import { MdNotInterested } from 'react-icons/md'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import ModalLiberarAcesso from 'src/components/modals/ModalSenhaMaster'
import Swal from 'sweetalert2'
import DrawerPagamentoAvulso from './drawerPagamentoAvulso'
import DrawerRecusarPagamentoAvulso from '../operacional/pagamento/drawerRecusarPagamentoAvulso'
import DrawerDetalhesPagamentoAvulso from './drawerDetalhesPagamentoAvulso'

const padrao = {
  background: '#fff',
  borderRadius: '8px',
  boxShadow: '1px 1px 8px #ddd'
}
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    gap: '40px'
  },
  boxResumo: {
    ...padrao,
    maxWidth: '500px',
    margin: '24px 0'
  },
  boxPagamento: {
    ...padrao,
    width: 'calc(100% - 400px)'
    // width: '60%'
  },
  boxPagamentoDetalhe: {
    '& .qr-code': {
      maxWidth: '210px'
    },
    '& .box-instructions p': {
      width: 'calc(100% - 48px)'
    },
    '& .icon-instructions': {
      background: theme.palette.secondary.main,
      color: '#fff',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 20,
      marginRight: '8px'
    },
    '& .back-green': {
      background: '#1cc3a5'
      // background: '#1cc332'
    },
    '& .text-green': {
      color: '#1cc3a5'
      // color: '#1cc332',
    }
  },
  boxFormaPagamento: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',

    '& .item': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '180px',
      gap: '8px',
      minHeight: '84px',
      background: '#eee',
      borderRadius: '8px',
      cursor: 'pointer',
      textAlign: 'center',
      // borderBottom: '1px solid #ddd',
      // borderRight: '1px solid #ddd',

      '&.active': {
        background: theme.palette.secondary.main,
        color: '#fff'
      },

      '&:last-child': {
        borderRight: 'none'
      }
    }
  }
}))

export default function AcompanharPagamento() {
  const { signOut, user, perfilAtual } = useAuth()
  const { confirmarPagamentoAvulso } = usePagamento()
  const { recusarPagamentoAvulso } = usePagamento()
  const styles = useStyles()
  const theme = useTheme()
  const query = useQuery()
  const history = useHistory()
  const ehMedico =
    perfilAtual === SynPerfis.MEDICO || perfilAtual === SynPerfis.SECRETARIA

  const ehPerfilSyn = [
    SynPerfis.OPERACIONAL,
    SynPerfis.FINANCEIRO,
    SynPerfis.COMERCIAL_MEDICOS,
    SynPerfis.COMERCIAL_B2B,
    SynPerfis.COMERCIAL_EXPANSAO
  ].includes(perfilAtual)
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))
  const [mostrarDrawer, setMostrarDrawer] = React.useState(false)
  const [mostrarDrawerDetalhes, setMostrarDrawerDetalhes] = React.useState(
    false
  )
  const [mostrarModalSenhaMaster, setMostrarModalSenhaMaster] = React.useState(
    false
  )
  const [
    mostrarDrawerRecusarPagamento,
    setMostrarDrawerRecusarPagamento
  ] = React.useState(false)

  const [pedidoSelecionado, setPedidoSelecionado] = React.useState<Pagamento>(
    null
  )

  const handleShowDrawer = () => {
    setMostrarDrawer(true)
  }

  const handleFecharDrawer = () => {
    setMostrarDrawer(false)
    setMostrarDrawerDetalhes(false)
    setMostrarDrawerRecusarPagamento(false)
    setPedidoSelecionado(null)
  }

  const handleEditarPagamentoAvulso = (pedido: Pagamento) => {
    setPedidoSelecionado(pedido)
    setMostrarDrawer(true)
  }

  const handleDetalhesPedido = (pedido: Pagamento) => {
    setPedidoSelecionado(pedido)
    setMostrarDrawerDetalhes(true)
  }

  const handleConfirmarPedido = (pedido: Pagamento) => {
    setPedidoSelecionado(pedido)
    setMostrarModalSenhaMaster(true)
  }

  const handleRecusarPedido = (pedido: Pagamento) => {
    setPedidoSelecionado(pedido)
    // setMostrarModalSenhaMaster(true)
    setMostrarDrawerRecusarPagamento(true)
  }

  const confirmarPedido = async (confirmado: boolean) => {
    if (!confirmado) return

    try {
      await confirmarPagamentoAvulso(pedidoSelecionado?.id)
      showNotification(
        'Sucesso',
        'Pagamento confirmado com sucesso.',
        'success',
        true
      )
    } catch (error) {}
  }

  const recusarPedido = async (recusar: boolean) => {
    if (!recusar) return

    try {
      await recusarPagamentoAvulso(
        pedidoSelecionado?.id,
        pedidoSelecionado?.motivoRecusa
      )
      showNotification(
        'Sucesso',
        'Pagamento recusado com sucesso.',
        'success',
        true
      )
    } catch (error) {}
  }

  function dadosModalConfirmar() {
    return {
      copy: 'A funcionalidade de confirmar pagamento exige autorização master',
      titulo: 'Confirmar pagamento',
      open: mostrarModalSenhaMaster,
      handleClose: () => setMostrarModalSenhaMaster(false),
      onConfirm: confirmado => confirmarPedido(confirmado)
    }
  }

  // const [tab, setTab] = React.useState(0)

  // const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
  //   setTab(newValue)
  // }
  // const [loading, setLoading] = useState(true)

  const propostaId = query.get('p')
  const { obterResumoPagamento, loading, resumo } = usePagamento()

  useEffect(() => {
    obterResumoPagamento(propostaId)
  }, [])

  function obterPedidos() {
    if (ehMedico) {
      return resumo?.pedidos?.filter(p => p.status === 'pago')
    }

    return resumo?.pedidos
  }

  if (loading) {
    return (
      <Box textAlign="center">
        <CircularProgress />
        <div>Carregando...</div>
      </Box>
    )
  }

  return (
    <UBox mb={5}>
      <Box>
        <Link
          component="button"
          color="primary"
          onClick={() => history.goBack()}
          style={{ marginBottom: '20px' }}
        >
          <RiArrowLeftLine />
          <Typography
            style={{ marginLeft: 10 }}
            variant="subtitle1"
            component="span"
          >
            Voltar
          </Typography>
        </Link>
      </Box>
      <Box mb={1}>
        <Typography variant="h5">
          Acompanhar pagamento da proposta {resumo?.codigoProposta}
        </Typography>
      </Box>
      <UBox
        mb={0}
        render={perfilAtual === SynPerfis.PACIENTE && !resumo?.pago}
        justifyContent="flex-end"
        display="flex"
      >
        <Button
          onClick={() => history.push(`${SynRoutes.pagamento}?p=${propostaId}`)}
          variant="contained"
          color="primary"
        >
          Ir para pagamento
        </Button>
      </UBox>
      <UBox p={2}>
        {/* className={styles.boxResumo} */}
        <UBox p={0} mt={2}>
          <Typography variant="h6" color="primary">
            <b style={{ borderBottom: '4px solid #21ebc7' }}>
              {resumo?.procedimento}
            </b>
          </Typography>
          <br />

          <Typography variant="subtitle2" color="primary">
            Médico
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Typography variant="body2" component="span">
                <strong>{resumo?.medico}</strong>
              </Typography>
            </Grid>
          </Grid>
          <br />
          {resumo?.localAtendimento && (
            <>
              <Typography variant="subtitle2" color="primary">
                Local de atendimento
              </Typography>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body2" component="span">
                    <strong>{resumo?.localAtendimento}</strong>
                  </Typography>
                </Grid>
              </Grid>
              <br />
            </>
          )}
          <Grid container>
            <Grid item xs={12} sm={7}>
              <Typography variant="subtitle2" color="primary">
                Paciente
              </Typography>
              <Typography variant="body2" component="span">
                <strong>{resumo?.paciente.nome}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="subtitle2" color="primary">
                Documento
              </Typography>
              <Typography variant="body2" component="span">
                <strong>{resumo?.paciente.cpf}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="subtitle2" color="primary">
                Idade
              </Typography>
              <Typography variant="body2" component="span">
                <strong>{resumo?.paciente.idade} anos</strong>
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Divider />
          <br />
          <Grid container>
            {!!resumo?.pago && (
              <Grid>
                <Alert severity="info">
                  <Typography variant="body2" component="span">
                    Parabéns! Agora que sua cirurgia está 100% paga, nossa
                    equipe de operação entrará em contato para seguir com os
                    próximos passos. Por favor fique atento(a) ao seu celular e
                    e-mail, nossos especialistas enviarão mensagens via WhatsApp
                    ou entrarão em contato por ligação.
                    <br />
                    <br />
                    Caso tenha alguma dúvida, favor entrar contato nos telefones
                    abaixo:
                    <br />
                    <br />
                    (98)99224-9283
                    <br />
                    (98)98455-5329
                  </Typography>
                </Alert>
                <br />
              </Grid>
            )}
            <Grid item xs={12} sm={7}>
              <UBox mb={2}>
                <Typography variant="subtitle2" color="primary">
                  Status de pagamento da proposta
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    color: resumo?.pago ? '#30b150' : '#ca5f21',
                    border: resumo?.pago
                      ? '2px solid #30b150'
                      : '2px solid #ca5f21',
                    borderRadius: '8px',
                    padding: '2px 8px',
                    display: 'inline-block'
                  }}
                >
                  <b>{resumo?.statusProposta}</b>
                </Typography>
                <br />
              </UBox>
            </Grid>

            <Grid item xs={12} sm={5}>
              <Typography variant="subtitle2" color="primary">
                Valor total do procedimento
              </Typography>
              <Typography variant="body2" component="span">
                <strong>{formatarDinheiro(resumo?.valorTotal)}</strong>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
              {/* <Typography variant="subtitle2" color="primary">
                Qtd de pedidos de pagamento realizados
              </Typography>
              <Typography variant="body2" component="span">
                <strong>{resumo?.pedidos?.length}</strong>
              </Typography>
              <br />
              <br /> */}

              <Typography variant="subtitle2" color="primary">
                Valor dos pagamentos processados
              </Typography>

              <Typography variant="body2" component="span">
                <strong>{formatarDinheiro(resumo?.valorPago)}</strong>
              </Typography>
            </Grid>
            {/* <br />
              <br /> */}
            {!ehMedico && (
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2" color="primary">
                  Valor dos pagamentos em processamento
                </Typography>
                <Typography variant="body2" component="span">
                  <strong>
                    {formatarDinheiro(resumo?.valorEmProcessamento)}
                  </strong>
                </Typography>
              </Grid>
            )}
            {/* <br />
              <br /> */}

            <Grid item xs={12} sm={3}>
              <Typography variant="subtitle2" color="primary">
                Saldo devedor
              </Typography>
              <Typography variant="body2" component="span">
                <strong>{formatarDinheiro(resumo?.saldoDevedor)}</strong>
              </Typography>
            </Grid>

            {!!ehPerfilSyn && (
              <Grid item xs={12} sm={2}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleShowDrawer}
                >
                  Adicionar Registro de Pagamento
                </Button>
              </Grid>
            )}

            <Grid item xs={12} sm={12}>
              <UBox mt={4}>
                <Typography variant="h6" color="primary">
                  Pagamentos
                </Typography>

                {/*
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  // centered
                >
                  <Tab
                    value={0}
                    label={
                      <UBox>
                        {resumo?.pedidos.filter(pedido => !pedido.integral)
                          .length > 0 ? (
                          <UBox>
                            {
                              resumo?.pedidos.filter(pedido => !pedido.integral)
                                .length
                            }{' '}
                            Fracionados
                          </UBox>
                        ) : (
                          `${
                            resumo?.pedidos.filter(pedido => !!pedido.integral)
                              .length
                          } Fracionado`
                        )}
                      </UBox>
                    }
                  />

                  <Tab
                    value={1}
                    label={
                      <UBox>
                        {resumo?.pedidos.filter(pedido => !!pedido.integral)
                          .length > 1 ? (
                          <UBox>
                            {
                              resumo?.pedidos.filter(
                                pedido => !!pedido.integral
                              ).length
                            }{' '}
                            Integrais
                          </UBox>
                        ) : (
                          `${
                            resumo?.pedidos.filter(pedido => !!pedido.integral)
                              .length
                          } Integral`
                        )}
                      </UBox>
                    }
                  />
                </Tabs> */}

                {/* <UBox mt={2} render={tab === 0}>
                  {!resumo?.pedidos.filter(pedido => !pedido.integral)
                    .length && (
                    <Typography variant="body2">
                      Não encontramos nenhum pedido de pagamento fracionado.
                    </Typography>
                  )}

                  {resumo?.pedidos
                    .filter(pedido => !pedido.integral)
                    .map((pedido, index) => (
                      <CardPedido key={index} pedido={pedido} />
                    ))}
                </UBox> */}

                <UBox mt={2}>
                  {!obterPedidos()?.length && (
                    <Typography variant="body2">
                      Ainda não encontramos nenhum pedido de pagamento.
                    </Typography>
                  )}

                  {obterPedidos()?.map((pedido, index) => (
                    <CardPedido
                      key={index}
                      pedido={pedido}
                      handleEditarPedido={handleEditarPagamentoAvulso}
                      handleRecusarPedido={handleRecusarPedido}
                      handleConfirmarPedido={handleConfirmarPedido}
                      handleDetalhesPedido={handleDetalhesPedido}
                    />
                  ))}
                </UBox>
              </UBox>
            </Grid>
          </Grid>
        </UBox>
      </UBox>

      {mostrarDrawer && (
        <DrawerPagamentoAvulso
          pedido={pedidoSelecionado}
          propostaId={propostaId}
          open={mostrarDrawer}
          handleOnClose={handleFecharDrawer}
        />
      )}

      {mostrarDrawerDetalhes && (
        <DrawerDetalhesPagamentoAvulso
          pedido={pedidoSelecionado}
          propostaId={propostaId}
          open={mostrarDrawerDetalhes}
          handleOnClose={handleFecharDrawer}
        />
      )}

      {mostrarModalSenhaMaster && (
        <ModalLiberarAcesso
          infoText={<>Tem certeza que deseja confirmar esse pagamento?</>}
          cancelButtonText="Sair"
          confirmButtonText="Confirmar"
          {...dadosModalConfirmar()}
        />
      )}

      {mostrarDrawerRecusarPagamento && (
        <DrawerRecusarPagamentoAvulso
          open
          handleOnClose={handleFecharDrawer}
          pedido={pedidoSelecionado}
        />
      )}
    </UBox>
  )
}

interface IPropsCard {
  pedido: Pagamento
  handleEditarPedido: (pedido: Pagamento) => void
  handleConfirmarPedido: (pedido: Pagamento) => void
  handleDetalhesPedido: (pedido: Pagamento) => void
  handleRecusarPedido: (pedido: Pagamento) => void
}

function CardPedido({
  pedido,
  handleEditarPedido,
  handleConfirmarPedido,
  handleRecusarPedido,
  handleDetalhesPedido
}: IPropsCard) {
  const history = useHistory()
  const { perfilAtual } = useAuth()
  const { removerPagamentoAvulso } = usePagamento()

  const ehHospital =
    perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
    perfilAtual === SynPerfis.TESOURARIA_HOSPITAL ||
    perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL

  const ehPerfilSyn = [
    SynPerfis.OPERACIONAL,
    SynPerfis.FINANCEIRO,
    SynPerfis.COMERCIAL_MEDICOS,
    SynPerfis.COMERCIAL_B2B,
    SynPerfis.COMERCIAL_EXPANSAO
  ].includes(perfilAtual)

  function navigateBlank(url) {
    window.open(url, '_blank')
  }

  function handleExcluirPagamentoAvulso(pedido: Pagamento) {
    Swal.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja apagar este pagamento?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Apagar',
      confirmButtonColor: '#c60a0a'
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        try {
          await removerPagamentoAvulso(pedido.id)
          showNotification(
            'Sucesso',
            'Pagamento foi removido com sucesso.',
            'success',
            true
          )
        } catch (err) {
          showNotificationErrorAPI(err)
        }
      }
    })
  }

  return (
    <Card
      key={pedido.id}
      style={{
        margin: '12px 0',
        padding: 5,
        boxShadow: 'none',
        background: '#eee'
      }}
    >
      <CardHeader
        title={
          <UBox>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Typography variant="body2" color="primary">
                <b style={{ marginRight: '16px' }}>Status:</b>
                <b
                  style={{
                    color: pedido.status === 'pago' ? '#15913e' : '#c95e21',
                    background:
                      pedido.status === 'pago' ? '#dcf8e5' : '#f8e3d7',
                    border:
                      pedido.status === 'pago'
                        ? '1px solid #a3f5bd'
                        : '1px solid #f8c7aa',
                    padding: '4px 8px',
                    borderRadius: '4px'
                  }}
                >
                  {pedido.status}
                </b>
              </Typography>
              {ehPerfilSyn && (
                <UBox>
                  {pedido?.status === 'pago' && pedido?.registroManual && (
                    <Button
                      onClick={() => handleDetalhesPedido(pedido)}
                      variant="text"
                      size="small"
                    >
                      ver detalhes
                    </Button>
                    // <Tooltip title="Confirmar">
                    //    <IconButton onClick={() => handleConfirmarPedido(pedido)}>
                    //     <RiCheckDoubleLine size={22} />
                    //   </IconButton>
                    // </Tooltip>
                  )}
                  {pedido?.status === 'pendente de confirmação' && (
                    <Tooltip title="Confirmar">
                      <IconButton onClick={() => handleConfirmarPedido(pedido)}>
                        <RiCheckDoubleLine size={22} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {pedido?.status === 'pendente de confirmação' && (
                    <Tooltip title="Recusar">
                      <IconButton onClick={() => handleRecusarPedido(pedido)}>
                        <MdNotInterested size={22} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {pedido?.status === 'pendente de confirmação' && (
                    <Tooltip title="Editar">
                      <IconButton onClick={() => handleEditarPedido(pedido)}>
                        <HiOutlinePencilAlt size={22} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {pedido?.status === 'pendente de confirmação' && (
                    <Tooltip title="Excluir">
                      <IconButton
                        onClick={() => handleExcluirPagamentoAvulso(pedido)}
                      >
                        <RiDeleteBin6Line size={20} />
                      </IconButton>
                    </Tooltip>
                  )}
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {popupState => (
                      <>
                        {pedido.status === 'pendente' && (
                          <IconButton
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            aria-label="mais"
                            {...bindTrigger(popupState)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        )}
                        <Menu
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          {...bindMenu(popupState)}
                        >
                          {pedido.status === 'pendente' && !!pedido.urlQrCode && (
                            <MenuItem
                              onClick={() => {
                                popupState.close()
                                navigateBlank(pedido.urlQrCode)
                              }}
                            >
                              Ver qr code
                            </MenuItem>
                          )}
                          {pedido.status === 'pendente' && !!pedido.urlBoleto && (
                            <MenuItem
                              onClick={() => {
                                popupState.close()
                                navigateBlank(pedido.urlBoleto)
                              }}
                            >
                              Ver boleto
                            </MenuItem>
                          )}
                        </Menu>
                      </>
                    )}
                  </PopupState>
                </UBox>
              )}
            </Grid>
            {!!pedido?.motivoRecusa &&
              pedido.status === 'pendente de confirmação' &&
              perfilAtual === SynPerfis.OPERACIONAL && (
                <Alert severity="warning">
                  <Typography variant="body2" component="span">
                    <strong>Motivo da recusa: </strong>
                    <br />
                    {pedido.motivoRecusa}
                  </Typography>
                </Alert>
              )}
          </UBox>
        }
        subheader={
          <>
            <Grid container>
              <Grid item xs={12} sm={3}>
                <Typography variant="body2" color="primary">
                  Realizado:
                </Typography>
                <Typography variant="body2" color="primary">
                  <b>{formatarDataHoraLocal(pedido.criado)}</b>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant="body2" color="primary">
                  Data do pagamento:
                </Typography>
                <Typography variant="body2" color="primary">
                  <b>
                    {!!pedido.modificado
                      ? formatarDataHoraLocal(pedido.modificado)
                      : 'não identificado'}
                  </b>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant="body2" color="primary">
                  Forma de pagamento:
                </Typography>
                <Typography variant="body2" color="primary">
                  <b>{pedido.tipo}</b>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant="body2" color="primary">
                  Valor:
                </Typography>
                <Typography variant="body2" color="primary">
                  <b>{formatarDinheiro(pedido.valor)}</b>
                  {!!pedido.juros && (
                    <>
                      <br />
                      <b style={{ color: '#ad602a' }}>
                        + {formatarDinheiro(pedido.juros)} de juros
                      </b>
                    </>
                  )}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                                <Typography variant="body2" color="primary">
                                  Status:
                                </Typography>
                                <Typography variant="body2" color="primary">
                                  <b>{pedido.status}</b>
                                </Typography>
                              </Grid> */}
            </Grid>
          </>
        }
      />
    </Card>
  )
}
