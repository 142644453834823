/* eslint-disable camelcase */
import axios, { AxiosResponse } from 'axios'
import { IDadosPessoais } from 'src/components/pagamento/formDadosPessoais'
import config from 'src/config'
import api from 'src/services/api'
import { ItemGenerico } from '../dashboard'
import { ObjetoDescricaoGenerico } from 'src/types'
import { IPagamentoAvulsoRequest } from 'src/contexts/Pagamento/context'

export interface Paciente {
  nome: string
  cpf: string
  idade: string
}

export interface Pagamento {
  id: string
  status: string
  valor: number
  juros: number
  tipo: string
  integral: boolean

  criado: string
  criadaPor: string
  modificado: string
  modificadoPor: string
  urlBoleto: string
  urlQrCode: string
  codigoBoleto: string
  pixCopiaCola: string

  codigoTransacao: string
  codigoEstabelecimento: string
  dataPagamento: string
  formaPagamento: string
  meioPagamento: string
  tipoPagamento: string
  urlComprovante: string
  motivoRecusa: string
  registroManual: boolean
}

export interface PagamentoResumoResponse {
  codigoProposta: string
  statusProposta: string
  procedimento: string
  pago: boolean
  medico: string
  localAtendimento: string
  paciente: Paciente
  valorTotal: number
  valorPago: number
  valorEmProcessamento: number
  saldoDevedor: number
  pedidos: Pagamento[]
  parcelamento: Parcelamento
  dadosPessoais: IDadosPessoais
}

export interface InstrucoesNotaFiscalResponse {
  contatoParceiros: ContatoParceiro[]
}

export interface ContatoParceiro {
  descricao: string
  telefone: string
  email: string
}

export interface Parcelamento {
  valorSemJuros: number
  parcelas: Parcelas[]
}

export interface Parcelas {
  qtdeParcelas: number
  valor: number
  valorTotal: number
  juros: number
}

export const obterResumoPorPropostaId: (
  id: string
) => Promise<AxiosResponse<PagamentoResumoResponse>> = id => {
  return api.get(`/pagamento/proposta/${id}`)
}

export const obterInstrucoesNFPorPropostaId: (
  id: string
) => Promise<AxiosResponse<InstrucoesNotaFiscalResponse>> = id => {
  return api.get(`/pagamento/proposta/${id}/instrucoes`)
}

export const calcularParcelas: (
  valor: number
) => Promise<AxiosResponse<Parcelamento>> = valor => {
  return api.post('/pagamento/obterParcelas', { valor })
}

export interface DestinatarioComprovanteResponse {
  tipo: number
  nome: string
  valor: string
  id: string
}

export const obterRecebedoresComprovantes: (
  id: string
) => Promise<AxiosResponse<DestinatarioComprovanteResponse[]>> = id => {
  return api.get(`/pagamento/proposta/${id}/recebedores`)
}
export interface PagamentoPixRequest {
  propostaId: string
  formaPagamento?: string
  valor?: number
  integral: boolean

  cidade: string
  cep: string
  endereco: string
  estado: string

  documento: string
  email: string
  nome: string
  telefone: string
}

export interface PagamentoPixResponse {
  urlPix: string
  codigoPix: string
}

export const realizarPagamentoPix: (
  request: PagamentoPixRequest
) => Promise<AxiosResponse<PagamentoPixResponse>> = request => {
  var body: any = request
  body.valor = request.valor?.toFixed(2)
  body.formaPagamento = 'pix'
  return api.post('/pagamento/pix', body)
}

export interface PagamentoBoletoRequest {
  propostaId: string
  formaPagamento?: string
  valor?: number
  integral: boolean
  cidade: string
  cep: string
  endereco: string
  estado: string

  documento: string
  email: string
  nome: string
  telefone: string
}

export interface PagamentoBoletoResponse {
  urlBoleto: string
  barcode: string
  codigoBarra: string
  pdfBoleto: string
}

export const realizarPagamentoBoleto: (
  request: PagamentoBoletoRequest
) => Promise<AxiosResponse<PagamentoBoletoResponse>> = request => {
  var body: any = request
  body.valor = request.valor?.toFixed(2)
  body.formaPagamento = 'boleto'

  return api.post('/pagamento/boleto', body)
}

export interface PagamentoCartaoCreditoRequest {
  propostaId: string
  formaPagamento?: string
  valor?: number
  integral: boolean

  tokenCartao: string
  numeroParcela: number
  cidade: string
  cep: string
  endereco: string
  estado: string

  documento: string
  email: string
  nome: string
  telefone: string
}

export interface PagamentoCartaoCreditoResponse {
  fluxoAntifraude: any
}

export const realizarPagamentoCartaoCredito: (
  request: PagamentoCartaoCreditoRequest
) => Promise<AxiosResponse<PagamentoCartaoCreditoResponse>> = request => {
  var body: any = request

  body.valor = request.valor?.toFixed(2)
  body.formaPagamento = 'credito'
  return api.post('/pagamento/cartaocredito', body)
}

export interface PagarmeTokenRequest {
  holderDocument: string
  expMonth: number
  expYear: number
  number: string
  holderName: string
  cvv: string
}

export interface PagarmeTokenResponse {
  id: string
  type: string
  created_at: string
  expires_at: string
}

export const criarTokenPagarme: (
  request: PagarmeTokenRequest
) => Promise<AxiosResponse<PagarmeTokenResponse>> = request => {
  const body = {
    card: {
      holder_document: request.holderDocument.trim(),
      exp_month: request.expMonth,
      exp_year: request.expYear,
      number: request.number.trim(),
      holder_name: request.holderName.trim(),
      cvv: request.cvv.trim()
    },
    type: 'card'
  }

  // const options = {
  //   method: 'POST',
  //   url: 'https://api.pagar.me/core/v5/tokens',
  //   params: { appId: config.PublicKeyPagarme },
  //   headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  //   data: body
  // }
  return axios.request({
    method: 'POST',
    url: 'https://api.pagar.me/core/v5/tokens',
    params: { appId: config.PublicKeyPagarme },
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    data: body
  })
  // return axios.request<PagarmeTokenRequest, PagarmeTokenResponse>(options)
  // return api.post(`/tokens?appId=${config.PublicKeyPagarme}`, body, {
  //   baseURL: 'https://api.pagar.me/core/v5'
  // })
}

export const salvarDadosPessoaisPagador: (
  request: IDadosPessoais
) => Promise<AxiosResponse<any>> = request => {
  var body: any = request

  return api.post('/dadospagador/salvar', body)
}

export interface PagamentoAvulsoResponse {
  id?: string
  dataPagamento: string
  valor: number
  juros: number
  formaPagamento: string
  tipoPagamento: string
  codigoEstabelecimento: string
  urlComprovante: string
}

export const realizarPagamentoAvulso: (
  request: IPagamentoAvulsoRequest
) => Promise<AxiosResponse<PagamentoAvulsoResponse>> = request => {
  const fromData = new FormData()

  for (var key in request) {
    fromData.append(key, request[key])
  }

  return api.post('/pagamento/RegistrarPagamentoManual', fromData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const excluirPagamentoAvulso: (
  pedidoId: string
) => Promise<AxiosResponse<void>> = pedidoId => {
  return api.delete(`/pagamento/${pedidoId}`)
}

export const confirmarPagamentoManual: (
  pedidoId: string
) => Promise<AxiosResponse<void>> = pedidoId => {
  return api.post('/pagamento/ConfirmarPagamentoManual', { pedidoId })
}

export const recusarPagamentoManual: (
  pedidoId: string,
  motivoRecusa: string
) => Promise<AxiosResponse<void>> = (pedidoId, motivoRecusa) => {
  return api.post('/pagamento/RecusarPagamentoManual', {
    pedidoId,
    motivoRecusa
  })
}
