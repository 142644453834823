import { Chip } from '@material-ui/core'

const StatusMedicoLocalAtendimento = {
  object: {
    PENDENTE: {
      status: 1,
      backgroundColor: '#ffca28',
      descricao: 'Convite pendente'
    },
    APROVADO: {
      status: 2,
      backgroundColor: '#66bb6a',
      descricao: 'Aprovado'
    }
  },
  array: [{},
    {
      status: 1,
      backgroundColor: '#ffca28',
      descricao: 'Convite pendente'
    },
    {
      status: 2,
      backgroundColor: '#66bb6a',
      descricao: 'Aprovado'
    }
  ]
}

export function renderChipStatusMedicoLocalAtendimento(status: number) {
  var statusML
  switch (status) {
    case 1:
      statusML = StatusMedicoLocalAtendimento.object.PENDENTE
      break
    case 2:
      statusML = StatusMedicoLocalAtendimento.object.APROVADO
      break
  }
  return (
    <Chip
      label={statusML?.descricao}
      size="small"
      style={{
        backgroundColor: statusML?.backgroundColor
      }}
    />
  )
}

export default StatusMedicoLocalAtendimento
