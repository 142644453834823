import { yupResolver } from '@hookform/resolvers/yup'
import {
  AppBar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Tab,
  Tabs,
  TextField as TextFieldUI
} from '@material-ui/core'

import Close from '@material-ui/icons/Close'
import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Button } from 'src/components/ui/Button'
import TextField from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import NumberFormat from 'react-number-format'
import { NumberFormatPorcentagem } from 'src/components/ui/PorcentagemField'
import PasswordField from 'src/components/ui/PasswordField'
import { criarTaxaOpme } from 'src/api/proposta'
import { CriarTaxaSynPersonalizadaCommand } from 'src/types'
import { verificarCPF, onlyNumber } from 'src/core/helpers/common'

export function ModalEditarTaxaOpme({
  descricao,
  titulo,
  open,
  handleClose,
  dinheiro,
  buttonLabel,
  submitTaxa,
  valorTotalSemComissao
}: {
  descricao: any
  titulo: string
  open: boolean
  handleClose: any
  dinheiro?: boolean
  buttonLabel?: string
  valorTotalSemComissao: number
  submitTaxa?: (
    descontoId: string,
    usuario: string,
    valor: number,
    valorPorcentagem: number
  ) => void
}) {
  const { watch, register, errors, control, handleSubmit } = useForm<{
    cpf: string
    senha: string
    senhaMaster: string
    valor: string
  }>({
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        cpf: yup
          .string()
          .test('cpf-teste', 'CPF inválido', value => {
            if (!value) return false

            return verificarCPF(onlyNumber(value))
          })
          .required()
          .label('CPF'),
        senha: yup.string().required().label('Senha'),
        senhaMaster: yup.string().required().label('Senha master'),
        valor: yup.string().required().label('Valor')
      })
    ),
    defaultValues: { valor: '0' }
  })
  const [loading, setLoading] = useState(false)
  const command = watch()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [tabValue, setTabValue] = useState<0 | 1>(0)

  const onSubmit = async data => {
    setLoading(true)

    let valor =
      tabValue === 0
        ? parseFloat(command.valor)
        : (parseFloat(command.valor) / 100) * valorTotalSemComissao

    let body: CriarTaxaSynPersonalizadaCommand = {
      cpf: command.cpf,
      senha: command.senha,
      senhaMaster: command.senhaMaster,
      valor: valor,
      valorTotalSemComissao: valorTotalSemComissao
    }

    criarTaxaOpme(body)
      .then(({ data }) => {
        submitTaxa(
          data.id,
          data.usuario || data.cpf,
          data.valorComissao,
          data.porcentagem
        )
        handleClose()
      })
      .catch(error => showNotificationErrorAPI(error))
      .finally(() => setLoading(false))
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
        {fullScreen && (
          <AppBar style={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
              <Typography style={{ flex: 1 }} variant="h6"></Typography>
              <Button autoFocus variant="text" type="submit" color="inherit">
                Salvar
              </Button>
            </Toolbar>
          </AppBar>
        )}
        {/* <DialogTitle id="form-dialog-title"></DialogTitle> */}
        <DialogContent>
          <Box mb={2} mt={1}>
            <Typography variant="h6">{titulo}</Typography>
          </Box>

          <TextField
            erro={errors}
            autoFocus
            name="cpf"
            label="CPF"
            mask={'999.999.999-99'}
            control={control}
            type="text"
          />

          <PasswordField
            erro={errors}
            label="Senha"
            name="senha"
            inputRef={register}
          />

          <PasswordField
            erro={errors}
            label="Senha Master"
            name="senhaMaster"
            inputRef={register}
          />

          {dinheiro && (
            <Tabs
              value={tabValue}
              onChange={(e, value) => setTabValue(value)}
              textColor="primary"
              indicatorColor="primary"
              style={{ marginBottom: '1em' }}
            >
              <Tab label="Valor monetário (R$)" />
              <Tab label="Porcentagem (%)" />
            </Tabs>
          )}

          <>
            {tabValue === 0 ? (
              <Controller
                render={({ onChange, value }) => (
                  <NumberFormat
                    style={{ width: '100%' }}
                    decimalScale={2}
                    fixedDecimalScale
                    allowedDecimalSeparators={[',']}
                    onFocus={e => e.target.select()}
                    decimalSeparator=","
                    thousandSeparator="."
                    isNumericString
                    prefix="R$ "
                    type="tel"
                    value={value}
                    onValueChange={values => onChange(values.floatValue)}
                    customInput={TextFieldUI}
                    variant="outlined"
                  />
                )}
                name="valor"
                control={control}
              />
            ) : (
              <Controller
                render={({ onChange, value }) => (
                  <NumberFormat
                    decimalScale={2}
                    style={{ width: '100%' }}
                    fixedDecimalScale
                    allowedDecimalSeparators={[',']}
                    onFocus={e => e.target.select()}
                    decimalSeparator=","
                    thousandSeparator="."
                    isNumericString
                    type="tel"
                    value={value}
                    onValueChange={values => onChange(values.floatValue)}
                    allowNegative={false}
                    isAllowed={values => {
                      if (
                        !values.floatValue ||
                        (values.floatValue <= 100 && values.floatValue >= 0)
                      ) {
                        return true
                      }
                    }}
                    customInput={TextFieldUI}
                    variant="outlined"
                  />
                )}
                name="valor"
                control={control}
              />
            )}
          </>
        </DialogContent>
        {!fullScreen && (
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="default">
              Cancelar
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              type="submit"
              color="primary"
            >
              {buttonLabel || 'Adicionar'}
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  )
}
