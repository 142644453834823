import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Drawer from 'src/components/ui/Drawer'
import useAtividade from 'src/hooks/useAtividade'
import {
  ItemListagemSolicitacaoOrcamentoResponse,
  OperacionalResponse
} from 'src/types'
import Atividades from 'src/components/atividade/Atividades'
import yup from '../yupCustom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { IAddAtividadeCommand } from 'src/api/atividade'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import TextField from '../ui/TextField'
import UBox from '../ui/Ubox'
import { FiPlus } from 'react-icons/fi'
import Button from '../ui/Button'
import DateInput from '../ui/DateInput'
import moment from 'moment'
import HourInput from '../ui/HourInput'
import { IAtividade, IUsuarioAtividade } from 'src/contexts/Atividade/context'
import { isSchema } from 'yup'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import { listarUsuarios } from 'src/api/solicitacao-orcamento'
import Alert from '@material-ui/lab/Alert'
import { isTemplateExpression } from 'typescript'

interface Props {
  open: boolean
  handleOnClose: () => void
  solicitacao?: ItemListagemSolicitacaoOrcamentoResponse
  atividadeId?: string
}

interface ISchema extends IAddAtividadeCommand {
  dataLembrete: string
  horaLembrete: string
  usuarios: string[]
}

const schema = yup.object().shape({
  titulo: yup.string().required().label('Titulo'),
  descricao: yup.string().label('Descricao'),
  solicitacaoId: yup.string().required().label('Solicitação'),
  dataLembrete: yup.string().required().label('Data do lembrete'),
  horaLembrete: yup.string().required().label('Hora do lembrete')
  // tipoLembrete: yup.string().required().label('Tipo do lembrete')
})

export default function DrawerSalvarAtividade({
  open,
  handleOnClose,
  solicitacao,
  atividadeId
}: Props) {
  const { adicionarAtividade, buscarAtividade } = useAtividade()

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    handleSubmit
  } = useForm<ISchema>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      titulo: '',
      solicitacaoId: solicitacao?.id,
      dataLembrete: moment().format('yyyy-MM-DD')
    }
  })

  useEffect(() => {
    register('solicitacaoId')
    register('dataLembrete')
    register('horaLembrete')
    register()
  }, [register])

  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingUsuarios, setLoadingUsuarios] = useState(false)
  const [listaUsuarios, setUsuarios] = useState<IUsuarioAtividade[]>([])
  const [lstUsuarios, setLstUsuarios] = useState<IUsuarioAtividade[]>([])
  const [textoModal, setTextoModal] = useState('')
  const [showModal, toggleModal] = useState<string>(null)

  const command = watch()

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  async function verificarEdicao() {
    if (!atividadeId) return
    setLoading(true)
    try {
      let atividade = await buscarAtividade(atividadeId)

      //Mapear objeto
      for (const key in atividade) {
        if (key === 'solicitacao') {
          setValue('solicitacaoId', atividade[key].id, {
            shouldValidate: false
          })
        }

        if (key === 'dataHora') {
          var dataHora = atividade[key]

          setValue(
            'dataLembrete',
            moment.utc(dataHora).local().format('YYYY-MM-DD'),
            {
              shouldValidate: false
            }
          )
          setValue(
            'horaLembrete',
            moment.utc(dataHora).local().format('HH:mm'),
            {
              shouldValidate: false
            }
          )
        } else setValue(key, atividade[key], { shouldValidate: false })
      }
      setLstUsuarios(atividade.usuarios)
      setLoading(false)
    } catch (error) {
      showNotificationErrorAPI(error)
    }
  }

  useEffect(() => {
    verificarEdicao()
  }, [])

  useEffect(() => {
    listarUsuarios()
      .then(response => setUsuarios(response.data))
      .catch(err => showNotificationErrorAPI(err))
    setLoadingUsuarios(false)
  }, [])

  const onSubmit = async (data: ISchema) => {
    setSubmitting(true)
    try {
      var dataMoment = moment(
        `${data.dataLembrete} ${data.horaLembrete}:00`,
        'YYYY-MM-DD HH:mm'
      )
      if (!!atividadeId) data.id = atividadeId
      data.dataHoraLembrete = dataMoment.toISOString()
      //enviar para o backend apenas os ids
      let filtroUsuarioId = []
      for (let index = 0; index < lstUsuarios?.length; index++) {
        let element1 = lstUsuarios[index].id
        filtroUsuarioId.push(element1)
      }
      data.usuarios = filtroUsuarioId
      adicionarAtividade(data)
      handleOnClose()
    } catch (error) {
      setSubmitting(false)
      showNotificationErrorAPI(error)
    }
  }
  const handleItemValue = (name: string, item) => {
    setValue(name, item, {
      shouldValidate: true
    })
  }
  const handleItemOperacioanis = (name: string, item) => {
    setLstUsuarios(item)
  }

  const handleShowModal = (texto, modal) => {
    setTextoModal(texto)
    toggleModal(modal)
  }
  return (
    <Drawer
      open={open}
      anchor="right"
      handleOnClose={handleOnClose}
      titulo="Salvar atividade"
    >
      <UBox render={loading} textAlign="center">
        <CircularProgress />
        <div>Carregando...</div>
      </UBox>

      <UBox hidden={loading} component="form" onSubmit={handleSubmit(onSubmit)}>
        {/* dados: {JSON.stringify(command)}
        <br />
        erros: {JSON.stringify(errors)} */}
        {/* dados: {JSON.stringify(lstUsuarios)} */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              erro={errors}
              autoFocus
              margin="normal"
              name="titulo"
              label="Titulo"
              inputRef={register}
              type="text"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              erro={errors}
              rows={4}
              multiline
              margin="normal"
              name="descricao"
              label="Descrição"
              inputRef={register}
              type="text"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>{' '}
          <Grid item xs={7}>
            <DateInput
              errors={errors}
              name="dataLembrete"
              label="Data para receber lembrete"
              value={command.dataLembrete}
              control={control}
              showIcon={false}
              handleChange={value => {
                handleItemValue('dataLembrete', value)
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <HourInput
              errors={errors}
              name="horaLembrete"
              label="Hora lembrete(24h)"
              value={command.horaLembrete}
              control={control}
              handleChange={value => {
                handleItemValue('horaLembrete', value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info">
              Caso queira registrar uma atividade para outra pessoa, selecione
              na lista abaixo o nome desejado. Caso deseje uma copia para você
              marque seu nome na lista.
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="warning">
              Se desejar uma atividade apenas para você, não selecione ninguém
              na lista.
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              erro={errors}
              name="Usuarios"
              label="Usuarios"
              loading={loadingUsuarios}
              options={listaUsuarios}
              value={lstUsuarios}
              getOptionSelected={option =>
                !!lstUsuarios.find(l => l.id === option.id)
              }
              showCheck
              showAddButton
              onClickAddButton={texto => handleShowModal(texto, 'lstUsuarios')}
              getOptionLabel={option => option?.nome}
              onChange={(event, newValue: IUsuarioAtividade[]) => {
                handleItemOperacioanis(
                  'lstUsuarios',
                  newValue as IUsuarioAtividade[]
                )
              }}
            />{' '}
          </Grid>
          <Grid item xs={12}>
            <Button
              loading={submitting}
              type="submit"
              disabled={submitting}
              // startIcon={<FiPlus />}
            >
              salvar
            </Button>
          </Grid>
        </Grid>
      </UBox>
    </Drawer>
  )
}
