import { useState } from 'react'
import MaskedInput from 'react-text-mask'
import { onlyNumber } from '../helpers/common'

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void
}

export const MaskTelCel = inputValue => {
  return onlyNumber(inputValue).length <= 10
    ? '(99) 9999-99999'
    : '(99) 99999-9999'
}

export const TextMaskCustomCpfCnpj = (props: TextMaskCustomProps) => {
  const cpf = [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ]

  const cnpj = [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/
  ]

  const [isCpf, setIsCpf] = useState(true)
  const { inputRef, ...other } = props

  const handleChange = event => {
    let value = event.target['value']

    if (event['keyCode'] === 8) {
      var onlyNumbers = value.replace(/\D/g, '')
      value = onlyNumbers.slice(0, onlyNumbers.length - 1)
    } else value += event['key']

    setValues(value)
  }
  const handlePaste = e => {
    let value = e.clipboardData.getData('Text')
    setValues(value)
  }

  function setValues(inputValue) {
    var onlyNumbers = inputValue.replace(/\D/g, '')
    if (onlyNumbers.length > 11) setIsCpf(false)
    else setIsCpf(true)
  }
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={isCpf ? cpf : cnpj}
      onPaste={handlePaste}
      onKeyDown={handleChange}
      placeholderChar={'\u2000'}
    />
  )
}

export const TextMaskCustomCpf = (props: TextMaskCustomProps) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
    />
  )
}

export const TextMaskCustomTel = (props: TextMaskCustomProps) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
    />
  )
}

export const TextMaskCustomCell = (props: TextMaskCustomProps) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
    />
  )
}

export const TextMaskCustomDate = (props: TextMaskCustomProps) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  )
}

export const GenericMask = props => {
  const { inputRef, ...other } = props
  return (
    <input {...other} type="text" style={{ color: 'blue' }} ref={inputRef} />
  )
}
