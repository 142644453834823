import Chip from '@material-ui/core/Chip'

export const StatusMedico = {
  CADASTRO_PENDENTE: {
    status: 0,
    backgroundColor: '#ffca28',
    descricao: 'Cadastro pendente'
  },
  SEM_PACOTE_CADASTRADO: {
    status: 1,
    backgroundColor: '#e67e22',
    descricao: 'Sem pacote cadastrado'
  },
  CONFIGURADO: {
    status: 2,
    backgroundColor: '#66bb6a',
    descricao: 'Configurado'
  }
}

export function renderChipStatusMedico(status: number) {
  switch (status) {
    case 0:
      return <Chip
        label={StatusMedico.CADASTRO_PENDENTE.descricao}
        size="small"
        style={
          {
            backgroundColor: StatusMedico.CADASTRO_PENDENTE.backgroundColor
          }
        }
      />
    case 1:
      return <Chip
      label={StatusMedico.SEM_PACOTE_CADASTRADO.descricao}
      size="small"
      style={
        {
          backgroundColor: StatusMedico.SEM_PACOTE_CADASTRADO.backgroundColor
        }
      }
    />
    case 2:
      return <Chip
      label={StatusMedico.CONFIGURADO.descricao}
      size="small"
      style={
        {
          backgroundColor: StatusMedico.CONFIGURADO.backgroundColor
        }
      }
    />
  }
}
