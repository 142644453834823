import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  MenuItem,
  Select,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiArrowLeftLine, RiCloseFill } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import { Button } from 'src/components/ui/Button'
import TextField from 'src/components/ui/TextField'
import estados from 'src/core/consts/estados'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import {
  adicionarLocalAtendimento,
  obterEspecialidades,
  obterLocaisAtendimento
} from 'src/api/dominio'
import {
  salvarCadastroHospital,
  salvarPreCadastro,
  buscarPorCpf
} from 'src/api/medico'
import {
  ISalvarCadastroHospitalCommand,
  ItemListagemMedicoResponse,
  ObjetoDescricaoGenerico,
  PreCadastroCommand,
  ResumoMedicoResponse
} from 'src/types'
import yup from 'src/components/yupCustom'
import { ModalAddGenerico } from 'src/components/modals/ModalAddGenerico'
import PasswordField from 'src/components/ui/PasswordField'
import { onlyNumber, verificarCPF } from 'src/core/helpers/common'
import Swal from 'sweetalert2'
import useAuth from 'src/hooks/useAuth'
import { Alert } from '@material-ui/lab'
import UBox from 'src/components/ui/Ubox'
import { useQuery } from 'src/hooks/useQuery'

const useStyles = makeStyles(theme => ({
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    margin: theme.spacing(1, 0, 0)
    // minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  noMargin: {
    margin: theme.spacing(0)
  }
}))

const schema = yup.object().shape({
  nome: yup.string().min(3).max(100).required().label('Nome'),
  crm: yup
    .string()
    .max(12)
    .test('cro-valor', 'CRM ou CRO são obrigatórios', function (value) {
      if (!this.parent.cro && !value) return false

      return true
    })
    .label('CRM'),
  ufCrm: yup
    .string()
    .test('crm-valido', 'UF é obrigatório', function (value) {
      if (this.parent.crm && !value) return false

      return true
    })
    .label('UF CRM'),
  cro: yup
    .string()
    .max(12)
    .test('cr,-valor', 'CRM ou CRO são obrigatórios', function (value) {
      if (!this.parent.crm && !value) return false

      return true
    })
    .label('CRO'),
  ufCro: yup
    .string()
    .test('crm-valido', 'UF é obrigatório', function (value) {
      if (this.parent.cro && !value) return false

      return true
    })
    .label('UF CRO'),
  telefone: yup.string().required().label('Telefone'),
  email: yup.string().email().required().label('E-mail'),
  especialidades: yup.array().min(1).label('Especialidades'),
  observacoes: yup.string().max(100).label('Observações'),
  cpf: yup
    .string()
    .test('cpf-teste', 'CPF inválido', value => {
      if (!value) return false

      return verificarCPF(onlyNumber(value))
    })
    .required()
    .label('CPF')
})

interface Props {
  modal?: boolean
  close?: () => void
  setMedico?: (medico: any) => void
}

export default function PreCadastroMedico({ modal, close, setMedico }: Props) {
  const query = useQuery()
  const classes = useStyles()
  const history = useHistory()

  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  const [especialidades, setEspecialidades] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [loadingEspecialidades, setLoadingEspecialidades] = useState<boolean>(
    true
  )

  const [loading, setLoading] = useState<boolean>(false)

  const [
    medicoExistente,
    setMedicoExistente
  ] = useState<ItemListagemMedicoResponse>()
  const [
    medicoListado,
    setMedicoListado
  ] = useState<ItemListagemMedicoResponse>()
  const [loadingMedico, setLoadingMedico] = useState(false)

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    handleSubmit
  } = useForm<ISalvarCadastroHospitalCommand>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      //locaisAtendimento: [],
      // cpf: '31151541001',
      // nome: 'Medico 1',
      // email: 'email@email.com',
      // crm: '92331',
      // telefone: '61995951212',
      nome: '',
      email: '',
      especialidades: [],
      ufCrm: '',
      ufCro: ''
    }
  })
  const command = watch()

  useEffect(() => {
    carregarEspecialidades()
  }, [])

  useEffect(() => {
    register('nome')
    register('email')
    register('ufCrm')
    register('ufCro')
    register('especialidades')
  }, [register])

  useEffect(() => {
    if (verificarCPF(onlyNumber(command?.cpf))) {
      verificarMedicoExiste(onlyNumber(command?.cpf))
    } else {
      setMedicoExistente(null)
      setMedicoListado(null)
    }
  }, [command?.cpf])

  async function verificarMedicoExiste(cpf: string) {
    setLoadingMedico(true)
    try {
      const response = await buscarPorCpf(cpf)

      var medico = response.data
      setMedicoExistente(medico)
      salvarValor('nome', medico?.nome)
      salvarValor('telefone', medico?.telefone)
      salvarValor('email', medico?.email)

      if (!!medico?.crm && !!medico?.ufCRM) {
        salvarValor('crm', medico?.crm)
        salvarValor('ufCrm', medico?.ufCRM)
      }
      if (!!medico?.cro && !!medico?.ufCRO) {
        salvarValor('cro', medico?.cro)
        salvarValor('ufCro', medico?.ufCRO)
      }
      salvarValor('especialidades', medico?.especialidades)
    } catch (err) {
      setMedicoExistente(null)
      setMedicoListado(null)
      salvarValor('nome', '')
      salvarValor('telefone', '')
      salvarValor('email', '')
      salvarValor('crm', '')
      salvarValor('ufCrm', '')
      salvarValor('cro', '')
      salvarValor('ufCro', '')
      salvarValor('especialidades', [])
    }

    setLoadingMedico(false)
  }

  function salvarValor(key: keyof ISalvarCadastroHospitalCommand, value: any) {
    setValue(key, value)
  }

  const handleRegister = (name, e: React.ChangeEvent<{ value: unknown }>) => {
    setValue(name, e.target.value, { shouldValidate: true })
  }

  const handleRegisterObjeto = (name, objetos: ObjetoDescricaoGenerico[]) => {
    setValue(name, objetos, { shouldValidate: true })
  }

  const carregarEspecialidades = async () => {
    return obterEspecialidades()
      .then(response => setEspecialidades(response.data))
      .finally(() => {
        setLoadingEspecialidades(false)
      })
  }

  const onSubmit = async (data: any) => {
    setLoading(true)
    const body = {
      nome: data.nome,
      crm: data.crm,
      ufCrm: data.ufCrm,
      cro: data.cro,
      ufCro: data.ufCro,
      telefone: onlyNumber(data.telefone),
      email: data.email,
      especialidades: data.especialidades.map(i => i.id),
      observacoes: data.observacoes,
      cpf: onlyNumber(data.cpf)
    }

    if (!!medicoExistente?.id) {
      body['id'] = medicoExistente.id
    }

    try {
      const response = await salvarCadastroHospital(body)
      if (!modal) {
        await Swal.fire({
          title: 'Cadastro concluído com sucesso!',
          icon: 'success',
          confirmButtonText: 'Continuar'
        })
        if (query.has('returnUrl')) {
          const returnUrl = query.get('returnUrl')
          history.replace(`${returnUrl}`)
          return
        }
        return history.replace(SynRoutes.home)
      }

      setMedico(response.data)
      close()
    } catch (error) {
      showNotificationErrorAPI(error)
    }

    setLoading(false)
  }

  return (
    <Box style={modal && { padding: '20px' }}>
      <CssBaseline />
      <Grid container justify="space-between">
        <UBox component={Grid} item xs={6} pb={2}>
          {!modal && (
            <Link
              component="button"
              color="primary"
              onClick={() => history.goBack()}
            >
              <RiArrowLeftLine />
              <Typography
                style={{ marginLeft: 10 }}
                variant="subtitle1"
                component="span"
              >
                Voltar
              </Typography>
            </Link>
          )}
        </UBox>
      </Grid>
      <Grid item xs={12}>
        <UBox
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography variant="h5">Cadastro de médico</Typography>
          {modal && (
            <RiCloseFill
              style={{ fontSize: '2rem', cursor: 'pointer' }}
              onClick={close}
            />
          )}
        </UBox>
        {/* <Alert severity="info">
          Só será permitido solicitar orçamentos em nome do médico após a
          autorização do mesmo.
        </Alert> */}
      </Grid>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
        noValidate={true}
      >
        <Grid container spacing={1} direction="row">
          <Grid item xs={12}>
            <Typography
              className={classes.subTitle}
              color="primary"
              display="block"
              variant="caption"
            >
              <b>Dados de acesso</b>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              erro={errors}
              name="cpf"
              placeholder="Ex: 999.999.999-99"
              label="CPF"
              hiddenLabel
              disabled={loading}
              control={control}
              mask="999.999.999-99"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: loadingMedico && <CircularProgress size={20} />
              }}
            />
          </Grid>
          {medicoExistente && (
            <Grid item xs={12}>
              {!!medicoExistente?.existeNoTenant && (
                <Alert severity="info">
                  O médico <strong>{medicoExistente.nome}</strong> já consta
                  como cadastrado no nosso sistema
                </Alert>
              )}

              {/* {!medicoExistente?.existeNoTenant && (
                <div>
                  <Alert severity="info">
                    O médico <strong>{medicoExistente.nome}</strong> já consta
                    como cadastrado no nosso sistema
                  </Alert>
                  <div className="m-1"></div>
                  <Button
                    fullWidth
                    onClick={() => {
                      setMedico(medicoExistente)
                      close()
                    }}
                  >
                    Continuar com este médico
                  </Button>
                </div>
              )} */}
            </Grid>
          )}
          {medicoListado && (
            <Grid item xs={12}>
              <Alert severity="info">
                O médico <strong>{medicoListado.nome}</strong> já está na sua
                lista de médicos
              </Alert>
              <div className="m-1"></div>
              <Button
                fullWidth
                onClick={() => {
                  setMedico(medicoListado)
                  close()
                }}
              >
                Continuar com este médico
              </Button>
            </Grid>
          )}
          <UBox
            component={Grid}
            container
            p={1}
            spacing={1}
            render={!medicoExistente?.existeNoTenant}
          >
            <Grid item xs={12}>
              <Typography
                className={classes.subTitle}
                color="primary"
                display="block"
                variant="caption"
              >
                <b>Dados pessoais</b>
              </Typography>
              {/* {JSON.stringify(command)}
            <br />
            <br />
            {JSON.stringify(errors)}
            <br />
            <br />
            {JSON.stringify(medicoExistente)} */}
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                erro={errors}
                inputRef={register}
                InputLabelProps={{
                  shrink: true
                }}
                name="nome"
                label="Nome completo *"
                defaultValue=""
                InputProps={{
                  autoComplete: 'new-password'
                }}
                disabled={
                  loading ||
                  //  || !!medicoExistente
                  !!medicoListado
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                required={true}
                maskTelCel
                control={control}
                erro={errors}
                name="telefone"
                label="Telefone"
                defaultValue=""
                type="tel"
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  autoComplete: 'new-password'
                }}
                disabled={loading || !!medicoExistente || !!medicoListado}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                inputRef={register}
                erro={errors}
                name="email"
                label="E-mail"
                defaultValue=""
                InputProps={{
                  autoComplete: 'new-password'
                }}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={
                  loading ||
                  // || !!medicoExistente
                  !!medicoListado
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                className={classes.subTitle}
                color="primary"
                display="block"
                variant="caption"
              >
                <b>Dados médicos</b>
              </Typography>
            </Grid>

            <Grid container spacing={1} className={classes.noMargin}>
              <Grid item xs={7} sm={4}>
                <TextField
                  required={true}
                  mask="999999999999"
                  control={control}
                  erro={errors}
                  name="crm"
                  label="CRM"
                  InputLabelProps={{
                    shrink: true
                  }}
                  defaultValue=""
                  InputProps={{
                    autoComplete: 'new-password'
                  }}
                  disabled={loading || !!medicoExistente || !!medicoListado}
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  {/* <InputLabel id="label-uf">UF CRM</InputLabel> */}
                  <Select
                    error={!!errors.ufCrm}
                    displayEmpty
                    className={classes.selectEmpty}
                    labelId="label-uf"
                    defaultValue=""
                    placeholder="UF CRM"
                    name="ufCrm"
                    value={command.ufCrm}
                    inputRef={register}
                    onChange={(e, v) => {
                      handleRegister('ufCrm', e)
                    }}
                    disabled={loading || !!medicoExistente || !!medicoListado}
                  >
                    <MenuItem value="">
                      <em>UF CRM</em>
                    </MenuItem>
                    {estados.map((estado, i) => (
                      <MenuItem key={i} value={estado.Initials}>
                        {estado.Initials}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={!!errors.ufCrm}>
                    {errors.ufCrm?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={7} sm={4}>
                <TextField
                  required={true}
                  mask="999999999999"
                  control={control}
                  erro={errors}
                  name="cro"
                  label="CRO"
                  defaultValue=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    autoComplete: 'new-password'
                  }}
                  disabled={loading || !!medicoExistente || !!medicoListado}
                />
              </Grid>
              <Grid item xs={5} md={2}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  {/* <InputLabel id="label-uf">UF CRM</InputLabel> */}
                  <Select
                    error={!!errors.ufCro}
                    displayEmpty
                    className={classes.selectEmpty}
                    defaultValue=""
                    placeholder="UF CRO"
                    name="ufCro"
                    inputRef={register}
                    onChange={(e, v) => {
                      handleRegister('ufCro', e)
                    }}
                    disabled={loading || !!medicoExistente || !!medicoListado}
                  >
                    <MenuItem value="">
                      <em>UF CRO</em>
                    </MenuItem>
                    {estados.map((estado, i) => (
                      <MenuItem key={i} value={estado.Initials}>
                        {estado.Initials}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={!!errors.ufCro}>
                    {errors.ufCro?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  erro={errors}
                  name="especialidades"
                  label="Especialidades"
                  value={command.especialidades}
                  options={especialidades}
                  closeOnSelect
                  loading={loadingEspecialidades}
                  showCheck
                  InputLabelProps={{
                    shrink: true
                  }}
                  getOptionLabel={option => option.descricao}
                  onChange={(event, newValue: ObjetoDescricaoGenerico[]) => {
                    handleRegisterObjeto(
                      'especialidades',
                      newValue as ObjetoDescricaoGenerico[]
                    )
                  }}
                  InputProps={{
                    autoComplete: 'new-password'
                  }}
                  showAddButton
                  disabled={loading || !!medicoExistente || !!medicoListado}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                erro={errors}
                multiline={true}
                rows={3}
                inputRef={register}
                name="observacoes"
                label="Observações"
                defaultValue=""
                InputProps={{
                  autoComplete: 'new-password'
                }}
                disabled={loading || !!medicoExistente || !!medicoListado}
              />
            </Grid>
          </UBox>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end'
            }}
          >
            <Button
              loading={loading}
              type="submit"
              fullWidth={ehXS}
              variant="contained"
              color="primary"
              disabled={
                loading ||
                !!medicoExistente?.existeNoTenant ||
                !!medicoListado ||
                loadingMedico
              }
              secondaryLoading
            >
              {!medicoExistente ? 'Salvar' : 'Adicionar médico'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}
