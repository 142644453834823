import { useState, useEffect, forwardRef } from 'react'
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  makeStyles,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from '@material-ui/core'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'
import { formatarData } from 'src/core/formatacoes/formatarData'
import {
  IPacoteOrcado,
  IPaciente,
  ItemExameLaboratorioResponse
} from 'src/types'
import { ModeloImpressaoPadrao } from 'src/components/modelosImpressao/modeloPadrao'
import Ubox from 'src/components/ui/Ubox'
import { statusOrcamento } from 'src/core/consts/statusSolicitacao'
import informarcaoAlert from 'src/core/informacoes/informacaoAlert'
import useAuth from 'src/hooks/useAuth'
import logoHSD from 'src/images/LOGO-HSD-VERDE.png'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 10000,
    color: '#fff'
  },
  boxPrint: {
    padding: theme.spacing(6, 4),
    width: '100%',
    '& @page': {
      size: '210mm 297mm'
    }
  },
  divider: {
    margin: '30px 0',
    backgroundColor: '#b6b6b6',
    borderColor: '#b6b6b6',
    color: '#b6b6b6'
  },
  hospitalCard: {
    color: '#465a81',
    padding: '16px',
    textAlign: 'center',
    border: '1px solid #465b82',
    maxWidth: '300px'
  }
}))

export interface IOPMESelecionado {
  id: string
  nomeOPME: string
  quantidade: number
  fabricante: string
  codAnvisa?: string
  referencia?: string
  observacao?: string
  descricao?: string
}

export interface DadosImpressao {
  localAtendimento: string
  listaOPME?: IOPMESelecionado[]
  pacoteProcedimento?: IPacoteOrcado
  medico?: string
  paciente?: IPaciente
  contestacao?: string
  valorTotal?: number
  examesLaboratorio: ItemExameLaboratorioResponse
  temAnestesia: boolean
  temPreAnestesica: boolean
  temRiscoCirurgico: boolean
  temExames: boolean
  temReservaSangue: boolean
  temTaxaSyn: boolean
  temBolsaSangue: boolean
  propostaSemOpme: boolean
  propostaSemHonorarioMedico: boolean
  propostaSemLocalAtendimento: boolean
  codigoSolicitacao: string
  codigoProposta?: string
  informacaoExame?: string
  criado?: string
  valorAnestesia?: number
  valorTotalOPME?: number
  valorReservaSangue?: number
  valorComissaoSyn?: number
  valorBancoSangue?: number
  valorSala?: number
  honorarioMedico?: number
  status?: number
  descricaoAnestesia?: string
  descricaoMaterial?: string
  descricaoEquipamento?: string
  descricaoDiarias?: string
  tempoCirurgico?: string
  observacaoExame?: string
  observacaoPacotePaciente?: string
  observacaoSangue?: string
  statusSolicitacao?: string
  observacaoHospital?: string
  urlLogomarca?: string
  propostaCentroCirurgicoAmbulatorial?: boolean
  propostaFinalDeSemana?: boolean
  propostaDiasUteis?: boolean
}

interface PropsResumo {
  onClose?: () => void
  dados: DadosImpressao
}

const ImpressaoProposta = forwardRef(({ onClose, dados }: PropsResumo, ref) => {
  const { tenantAtual } = useAuth()
  const permitirLogo = ['640f7ab6-ddb5-4ba5-9237-e26c9e156980', 'd70861b3-5c24-4a55-8ddc-85c7dd21be2b']
  const theme = useTheme()
  const styles = useStyles()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))
  const [loading, setLoading] = useState(false)
  const [ehLogoPermitida, setEhLogoPermitida] = useState(false)

  useEffect(() => {
    verificarHSD()
  }, [tenantAtual])

  function verificarHSD() {
    return setEhLogoPermitida(permitirLogo.includes(tenantAtual))
  }
  function verificarSeExiste(item: boolean, texto: string) {
    if (!item) return ''
    return texto
  }
  useEffect(() => {
    podeVisualizarDetalhamento()
  }, [dados])

  function podeVisualizarDetalhamento() {
    return [
      statusOrcamento.PAGAMENTO_CONFIRMADO.Id,
      statusOrcamento.EM_AGENDAMENTO.Id,
      statusOrcamento.REALIZADO.Id
    ].includes(dados?.statusSolicitacao)
  }
  useEffect(() => {
    quebrarDetalhe()
  }, [dados?.listaOPME?.length])

  function quebrarDetalhe() {
    return dados?.listaOPME?.length === 2 || dados?.listaOPME?.length === 3
  }

  function ehQuebraLinha(index) {
    if (!index) return false
    return index > 3 && index !== 5 ? index % 5 === 0 : index % 3 === 0
  }
  function obterHeadTabelaOPME() {
    return (
      <Grid
        container
        style={{
          borderBottom: '1px solid #555',
          color: '#555',
          paddingBottom: '4px'
        }}
      >
        <Grid item xs={8}>
          <Typography style={{ fontSize: 12 }}>
            <strong>OPME</strong>
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography style={{ fontSize: 12 }}>
            <strong>Referência</strong>
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="body2" align="right">
            <strong>Qtd.</strong>
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Backdrop className={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ display: 'none' }}>
        <div ref={ref as any}>
          <ModeloImpressaoPadrao
            codigo={dados?.codigoSolicitacao}
            criado={dados?.criado}
            urlLogomarca={!!ehLogoPermitida ? logoHSD : dados?.urlLogomarca}
          >
            <div>
              <Box mt={3} mb={2} textAlign="center">
                <Typography variant="body1" align="center">
                  <strong>Proposta {dados?.codigoProposta}</strong>
                </Typography>
              </Box>

              <Grid container direction="row" justify="space-between">
                <Grid item xs={12} sm={12}>
                  <Typography style={{ fontSize: 12 }}>
                    <strong>Médico solicitante: </strong>
                    {dados?.medico}
                  </Typography>
                  <Typography style={{ fontSize: 12 }}>
                    <strong>Procedimento: </strong>{' '}
                    {dados?.pacoteProcedimento?.descricao}
                  </Typography>
                  {dados?.pacoteProcedimento?.procedimentos.map(i => {
                    return (
                      <Typography variant="body2" key={i.id}>
                        Cód.{' '}
                        {`${!!i.detalhe ? i.detalhe : ''} - ${i.descricao}`}
                      </Typography>
                    )
                  })}
                </Grid>
                <Box mt={1}>
                  <Typography style={{ fontSize: 12 }}>
                    <strong>Paciente: </strong>{' '}
                  </Typography>
                </Box>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={ehXS ? { margin: '10px 0' } : null}
                >
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography style={{ fontSize: 12 }}>
                        <strong>Nome: </strong> {dados?.paciente?.nome}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2" align="right">
                        <strong>Nascimento: </strong>{' '}
                        {formatarData(dados?.paciente?.dataNascimento)}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography style={{ fontSize: 12 }}>
                        <strong>Sexo: </strong>{' '}
                        {dados?.paciente?.sexo === 1 ? 'Masculino' : 'Feminino'}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{ fontSize: 12 }}>
                        <strong>Telefone: </strong>{' '}
                        {formatarTelefone(dados?.paciente?.telefone)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" align="right">
                        <strong>Email: </strong>{' '}
                        {dados?.paciente?.email
                          ? dados?.paciente?.email
                          : 'Não informado'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider className={styles.divider} />

              <Box mt={0}>
                <Typography style={{ fontSize: 12 }}>
                  <strong>
                    Observação: Validade da proposta: 30 dias a contar da data
                    de geração da mesma.{' '}
                  </strong>{' '}
                </Typography>
              </Box>

              <Ubox
                mt={0}
                mb={1}
                render={
                  !!dados?.propostaCentroCirurgicoAmbulatorial ||
                  !!dados?.propostaFinalDeSemana ||
                  !!dados?.propostaDiasUteis
                }
              >
                <Box width="100%" textAlign="left" my={2}>
                  <Typography variant="subtitle2">
                    {informarcaoAlert(dados)}
                  </Typography>
                  <Typography variant="subtitle2">
                    {!!dados?.propostaFinalDeSemana &&
                      'Sábado a partir de 12h até domingo 23h59 e feriados.'}
                    {!!dados?.propostaDiasUteis &&
                      'Segunda a sábado até as 12h.'}
                  </Typography>
                </Box>
              </Ubox>

              <Ubox
                mt={0}
                mb={1}
                render={
                  !dados?.propostaCentroCirurgicoAmbulatorial &&
                  !dados?.propostaFinalDeSemana &&
                  !dados?.propostaDiasUteis
                }
              >
                <Box width="100%" textAlign="left" my={2}>
                  <Typography variant="subtitle2">
                    Qualquer dia e horário no centro cirúrgico.
                  </Typography>
                </Box>
              </Ubox>

              <Ubox render={dados?.status === 77}>
                {dados?.propostaSemLocalAtendimento && (
                  <Box width="100%" textAlign="left" my={2}>
                    <Typography variant="subtitle2">
                      Proposta não contempla local de atendimento.
                    </Typography>
                  </Box>
                )}

                {dados?.propostaSemHonorarioMedico && (
                  <Box width="100%" textAlign="left" my={2}>
                    <Typography variant="subtitle2">
                      Proposta não contempla honorários da equipe médica.
                    </Typography>
                  </Box>
                )}
                {dados?.listaOPME?.length === 0 ? (
                  <Box width="100%" textAlign="left" my={2}>
                    <Typography variant="subtitle2">
                      Proposta não contempla itens OPME
                    </Typography>
                  </Box>
                ) : (
                  <Box my={3}>
                    <Box mb={2}>
                      <Typography style={{ fontSize: 12 }}>
                        <b>Lista de OPME:</b>
                      </Typography>
                    </Box>
                    <div style={{ overflowX: 'hidden' }}>
                      {obterHeadTabelaOPME()}
                      {dados?.listaOPME?.map((o, index) => {
                        return (
                          <>
                            <Grid
                              container
                              key={o.id}
                              style={{
                                borderBottom: '1px solid #cecece',
                                padding: '8px 4px'
                              }}
                            >
                              <Grid item xs={8}>
                                <Typography
                                  variant="body2"
                                  style={{ whiteSpace: 'nowrap' }}
                                >
                                  {o.nomeOPME}
                                  <br />
                                  <strong>Fabricante:</strong>{' '}
                                  {o.fabricante
                                    ? o.fabricante
                                    : 'Não informado'}
                                  <br />
                                  <strong>Cód ANVISA:</strong>{' '}
                                  {o.codAnvisa ? o.codAnvisa : 'Não informado'}
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                {o.referencia ? o.referencia : 'Não informado'}
                              </Grid>
                              <Grid item xs={2}>
                                <Typography
                                  variant="body2"
                                  style={{ whiteSpace: 'nowrap' }}
                                  align="right"
                                >
                                  {o.quantidade
                                    ? o.quantidade
                                    : 'Não informado'}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <b>Descricao: </b>
                                {o.descricao ? o.descricao : 'Não informado'}
                              </Grid>
                            </Grid>
                            {dados?.listaOPME?.length !== index + 1 &&
                              ehQuebraLinha(index) && (
                                <>
                                  <div className="pagebreak-syn"></div>
                                  {obterHeadTabelaOPME()}
                                </>
                              )}
                          </>
                        )
                      })}
                    </div>
                  </Box>
                )}

                {dados?.examesLaboratorio?.exames.length !== 0 && (
                  <>
                    <Divider className={styles.divider} />
                    <Box my={4}>
                      <Typography variant="h6">Lista de exames</Typography>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Código</TableCell>
                            <TableCell>Descrição</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dados?.examesLaboratorio?.exames.map(
                            (item, index) => {
                              return (
                                <>
                                  <TableRow key={item.id}>
                                    <TableCell>{item.codigo}</TableCell>
                                    <TableCell>{item.descricao}</TableCell>
                                  </TableRow>
                                </>
                              )
                            }
                          )}
                        </TableBody>
                      </Table>
                    </Box>
                  </>
                )}

                {dados?.contestacao && (
                  <Typography style={{ fontSize: 12 }}>
                    <strong>Motivo da proposta ter sido recusada: </strong>
                    {dados?.contestacao}
                  </Typography>
                )}

                <Box my={4}>
                  {(dados?.listaOPME?.length === 3 ||
                    dados?.listaOPME?.length === 9) &&
                    !dados?.propostaSemLocalAtendimento && (
                      <>
                        <div className="pagebreak-syn"></div>
                      </>
                    )}
                  {!dados?.propostaSemLocalAtendimento && (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                      }}
                    >
                      <Typography style={{ fontSize: 12 }}>
                        <strong>Local de atendimento: </strong>
                        <br />
                        <b>{dados?.localAtendimento}</b>
                      </Typography>
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}
                  >
                    <Typography style={{ fontSize: 12 }}>
                      <b>
                        Valor total da proposta:
                        <br />
                        {formatarDinheiro(dados?.valorTotal)}
                      </b>
                    </Typography>
                  </div>
                  {(dados?.listaOPME?.length === 2 ||
                    dados?.listaOPME?.length === 8 ||
                    dados?.listaOPME?.length === 19) &&
                    !dados?.propostaSemLocalAtendimento && (
                      <>
                        <div className="pagebreak-syn"></div>
                      </>
                    )}
                  <Box mt={2}>
                    <Typography style={{ fontSize: 12 }}>
                      <b>O valor total da proposta contempla:</b>
                    </Typography>
                    {dados?.temAnestesia && dados?.valorAnestesia > 0 && (
                      <Typography style={{ fontSize: 12 }}>
                        <b>
                          Valor de Anestesia:{' '}
                          {formatarDinheiro(dados?.valorAnestesia)}
                        </b>
                      </Typography>
                    )}
                    {dados?.listaOPME?.length > 0 && dados?.valorTotalOPME > 0 && (
                      <Typography style={{ fontSize: 12 }}>
                        <b>
                          Valor total da lista de OPME:{' '}
                          {formatarDinheiro(dados?.valorTotalOPME)}
                        </b>
                      </Typography>
                    )}
                    {dados?.temBolsaSangue && dados?.valorBancoSangue > 0 && (
                      <Typography style={{ fontSize: 12 }}>
                        <b>
                          Valor bolsa de sangue:{' '}
                          {formatarDinheiro(dados?.valorBancoSangue)}
                        </b>
                      </Typography>
                    )}
                    {dados?.temReservaSangue && dados?.valorReservaSangue > 0 && (
                      <Typography style={{ fontSize: 12 }}>
                        <b>
                          Valor reserva de sangue:{' '}
                          {formatarDinheiro(dados?.valorReservaSangue)}
                        </b>
                      </Typography>
                    )}
                    {!!dados?.valorComissaoSyn && dados?.valorComissaoSyn > 0 && (
                      <Typography style={{ fontSize: 12 }}>
                        <b>
                          Taxa de serviço:{' '}
                          {formatarDinheiro(dados?.valorComissaoSyn)}
                        </b>
                      </Typography>
                    )}

                    {dados?.valorSala > 0 && (
                      <Typography style={{ fontSize: 12 }}>
                        <b>
                          Valor do hospital:{' '}
                          {formatarDinheiro(dados?.valorSala)}
                        </b>
                      </Typography>
                    )}
                    {dados?.honorarioMedico > 0 && (
                      <Typography style={{ fontSize: 12 }}>
                        <b>
                          Valor dos honorários da equipe médica:{' '}
                          {formatarDinheiro(dados?.honorarioMedico)}
                        </b>
                      </Typography>
                    )}

                    <Typography style={{ fontSize: 12 }}>
                      <br />
                      <span style={{ textTransform: 'initial' }}>
                        {dados?.informacaoExame}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Ubox>
              {/* 1ª Etapa */}
              <Ubox
                render={
                  dados?.status === 2 ||
                  dados?.status === 1 ||
                  dados?.status === 4 ||
                  dados?.status === 5 ||
                  dados?.status === 6
                }
              >
                <Box width="100%" textAlign="left" my={2}>
                  {dados?.propostaSemHonorarioMedico && (
                    <Box width="100%" textAlign="left">
                      <Typography variant="subtitle2">
                        Proposta não contempla honorários da equipe médica.
                      </Typography>
                    </Box>
                  )}

                  {dados?.propostaSemLocalAtendimento && (
                    <Typography variant="subtitle2">
                      Proposta não contempla local de atendimento.
                    </Typography>
                  )}
                  {!dados?.propostaSemLocalAtendimento && (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                      }}
                    >
                      <Typography style={{ fontSize: 12 }}>
                        <strong>Local de atendimento: </strong>
                        {dados?.localAtendimento}
                      </Typography>
                    </div>
                  )}
                  <Ubox
                    mt={2}
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}
                  >
                    <Typography style={{ fontSize: 12 }}>
                      <strong> Valor total da proposta:</strong>
                      {formatarDinheiro(dados?.valorTotal)}
                    </Typography>
                  </Ubox>
                </Box>
                <Box width="100%" textAlign="left" my={2}>
                  <Typography style={{ fontSize: 12 }}>
                    <b>O valor total da proposta contempla:</b>
                  </Typography>
                  {dados?.temAnestesia && dados?.valorAnestesia > 0 && (
                    <Typography style={{ fontSize: 12 }}>
                      <b>Tipo(s) de anestesia(s): </b>
                      {dados?.descricaoAnestesia}
                    </Typography>
                  )}
                  <Typography style={{ fontSize: 12 }}>
                    <b>Equipamento(s) hospitalar(es): </b>
                    {dados?.descricaoEquipamento
                      ? dados?.descricaoEquipamento
                      : 'nenhum equipamento'}
                  </Typography>
                  <Typography style={{ fontSize: 12 }}>
                    <b>Materiais específicos: </b>
                    {dados?.descricaoMaterial
                      ? dados?.descricaoMaterial
                      : 'nenhum material'}
                  </Typography>

                  <Typography style={{ fontSize: 12 }}>
                    <b>Diária(s): </b>
                    {!!dados?.descricaoDiarias
                      ? dados?.descricaoDiarias
                      : 'nenhuma diária'}
                  </Typography>

                  <Typography style={{ fontSize: 12 }}>
                    <b>OPME(s): </b>
                    {dados?.listaOPME?.length > 0 &&
                      dados?.listaOPME?.map((o, index) => {
                        return (
                          <>
                            {o.nomeOPME}{' '}
                            {!!o.observacao ? ` - ${o.observacao}` : ''}
                            {dados?.listaOPME?.length - 1 > index ? ', ' : ''}
                          </>
                        )
                      })}
                    {dados?.listaOPME?.length === 0 && 'sem OPME'}
                  </Typography>
                  {(statusOrcamento.PAGAMENTO_CONFIRMADO.Id ===
                    dados?.statusSolicitacao ||
                    statusOrcamento.EM_AGENDAMENTO.Id ===
                      dados?.statusSolicitacao ||
                    statusOrcamento.REALIZADO.Id ===
                      dados?.statusSolicitacao) &&
                    dados?.listaOPME?.length > 0 && (
                      <Typography style={{ fontSize: 12 }}>
                        <b>Fornecedor(es) OPME(s): </b>
                        {dados?.listaOPME?.map((o, index) => {
                          return (
                            <>
                              {o.fabricante}
                              {dados?.listaOPME?.length - 1 > index ? ', ' : ''}
                            </>
                          )
                        })}
                      </Typography>
                    )}

                  <Typography style={{ fontSize: 12 }}>
                    <b>Exame(s) durante a internação: </b>
                    {!!dados?.observacaoExame || !!dados?.informacaoExame
                      ? dados?.observacaoExame
                      : 'não informado'}
                    <br />
                    {!!dados?.observacaoExame || !!dados?.informacaoExame
                      ? dados?.informacaoExame
                      : ''}
                  </Typography>
                  <Typography style={{ fontSize: 12 }}>
                    <b>Média de tempo do procedimento: </b>
                    {dados?.tempoCirurgico
                      ? dados?.tempoCirurgico + 'h'
                      : 'não informado'}
                  </Typography>
                  <Typography style={{ fontSize: 12 }}>
                    <b>Considerações do pacote para paciente: </b>
                    {dados?.observacaoPacotePaciente
                      ? dados?.observacaoPacotePaciente
                      : 'não informado'}
                  </Typography>
                  {!!dados?.observacaoSangue && (
                    <Typography style={{ fontSize: 12 }}>
                      <b>Reserva de sangue: </b>
                      {dados?.observacaoSangue
                        ? dados?.observacaoSangue
                        : 'não informado'}
                    </Typography>
                  )}
                  {dados?.honorarioMedico > 0 && (
                    <Typography style={{ fontSize: 12 }}>
                      <b>Honorários equipe médica</b>
                    </Typography>
                  )}
                  {/* {JSON.stringify(dados)} */}
                  {dados?.observacaoHospital && (
                    <Typography style={{ fontSize: 12 }}>
                      <b>Observação do hospital: </b>
                      {dados?.observacaoHospital}
                    </Typography>
                  )}
                  {dados?.temTaxaSyn && (
                    <Typography style={{ fontSize: 12 }}>
                      <b>Taxa de serviço</b>
                    </Typography>
                  )}
                </Box>
              </Ubox>
              {/* 2ª Etapa */}
              {podeVisualizarDetalhamento() && (
                <Box width="100%" textAlign="left" my={2}>
                  <div className="pagebreak-syn"></div>
                  <Typography style={{ fontSize: 12 }}>
                    <b>Detalhamento</b>
                    <br />
                    <br />
                  </Typography>

                  {dados?.temAnestesia && dados?.valorAnestesia > 0 && (
                    <Typography style={{ fontSize: 12 }}>
                      <b>
                        Valor de Anestesia:{' '}
                        {formatarDinheiro(dados?.valorAnestesia)}
                      </b>
                    </Typography>
                  )}
                  {dados?.temBolsaSangue && dados?.valorBancoSangue > 0 && (
                    <Typography style={{ fontSize: 12 }}>
                      <b>
                        Valor bolsa de sangue:{' '}
                        {formatarDinheiro(dados?.valorBancoSangue)}
                      </b>
                    </Typography>
                  )}
                  {dados?.temReservaSangue && dados?.valorReservaSangue > 0 && (
                    <Typography style={{ fontSize: 12 }}>
                      <b>
                        Valor reserva de sangue:{' '}
                        {formatarDinheiro(dados?.valorReservaSangue)}
                      </b>
                    </Typography>
                  )}
                  {dados?.listaOPME?.length > 0 && dados?.valorTotalOPME > 0 && (
                    <Typography style={{ fontSize: 12 }}>
                      <b>
                        Valor total da lista de OPME:{' '}
                        {formatarDinheiro(dados?.valorTotalOPME)}
                      </b>
                    </Typography>
                  )}
                  {!!dados?.valorComissaoSyn && dados?.valorComissaoSyn > 0 && (
                    <Typography style={{ fontSize: 12 }}>
                      <b>
                        Taxa de serviço:{' '}
                        {formatarDinheiro(dados?.valorComissaoSyn)}
                      </b>
                    </Typography>
                  )}

                  {dados?.valorSala > 0 && (
                    <Typography style={{ fontSize: 12 }}>
                      <b>
                        Valor do hospital: {formatarDinheiro(dados?.valorSala)}
                      </b>
                    </Typography>
                  )}
                  {dados?.honorarioMedico > 0 && (
                    <Typography style={{ fontSize: 12 }}>
                      <b>
                        Valor do honorários da equipe médica:{' '}
                        {formatarDinheiro(dados?.honorarioMedico)}
                      </b>
                    </Typography>
                  )}
                </Box>
              )}
              <Box mt={2}>
                {/* <div className="pagebreak-syn"></div> */}
                <Typography style={{ fontSize: 12 }}>
                  <strong>Observação: </strong>
                  <br />
                  Não abrangem OPME's, materiais, perneiras, manta térmica e
                  exames não previstos na proposta, além de intervenções de
                  outras especialidades, reoperações, hemoterapia, nutrição
                  especial, quimioterápicos e terapias renais.
                  <br />
                  Cobranças adicionais incluem diárias extras de enfermaria por
                  R$ 1.600 e de apartamento por R$ 1.800, além de diárias de UTI
                  não programadas, que serão cobradas como conta aberta.
                  <br />O orçamento é válido para cirurgias sem intercorrências
                  e para pacientes sem alergia a látex; quaisquer complicações
                  serão cobradas como conta aberta (direto com o hospital).
                </Typography>
              </Box>
            </div>
          </ModeloImpressaoPadrao>
        </div>
      </div>
    </>
  )
})

export default ImpressaoProposta
