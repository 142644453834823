import { AxiosResponse } from 'axios'
import { IResumoNotificacao } from 'src/components/notificacoes/menu'
import { IBuscarDadosListagem } from 'src/hooks/useListagem'
import { INotificacao } from 'src/signalR/Notificacao/notificacao'
import { ListagemQuery, ListagemResponse } from 'src/types'

import api from '../../services/api'

export default {
  // buscarAtividade: (id: string): Promise<AxiosResponse<IAtividade>> => {
  //   return api.get(`/atividades/${id}`)
  // },

  marcarTodasComoLida: (): Promise<AxiosResponse> => {
    return api.post('/notificacao/visualizadas/todas')
  },

  marcarComoLida: (id: string): Promise<AxiosResponse> => {
    return api.post('/notificacao/visualizadas', { id })
  },

  obterResumoNotificacoes: (): Promise<AxiosResponse<IResumoNotificacao>> => {
    return api.get('/notificacao/resumo')
  },
  listagemNotificacoes: (
    query: ListagemQuery
  ): Promise<AxiosResponse<ListagemResponse<INotificacao>>> => {
    return api.get(`/notificacao?${new URLSearchParams(query)}`)
  }
}
