import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { obterFavorecidosBV } from 'src/api/financiamento'
import Drawer from 'src/components/ui/Drawer'
import formatarCPF from 'src/core/formatacoes/formatarCPF'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { SimulacaoFavorecidosResponse } from 'src/types'

type Props = {
  id: string
  open: boolean
  handleOnClose: (alterou?: boolean) => void
}

const useStylesFavorito = makeStyles(theme => ({
  boxResumo: {
    border: '1px solid #eee',
    backgroundColor: '#efefef',
    borderRadius: 4,
    '& .MuiTableCell-root.MuiTableCell-head': {
      padding: theme.spacing(1, 2),
      background: '#f9f9f9',
      color: theme.palette.grey[800]
    }
  },
  fontMarca: {
    fontStyle: 'italic',
    fontWeight: 500,
    fontSize: '0.8rem',
    color: theme.palette.grey[700]
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))

function DrawerFavorecidos(props: Props) {
  const [loading, setLoading] = useState(false)
  const [
    lstSimulacaoFavorecidosResponse,
    setSimulacaoFavorecidosResponse
  ] = useState<SimulacaoFavorecidosResponse[]>()
  const classes = useStylesFavorito()

  useEffect(() => {
    //Popular valores vindo da tela anterior
    if (props.open) {
      setLoading(true)
      obterFavorecidosBV(props.id)
        .then(response => {
          setLoading(false)
          setSimulacaoFavorecidosResponse(response.data)
        })
        .catch(err => {
          setLoading(false)
          showNotificationErrorAPI(err)
        })
    }
  }, [props.open])

  return (
    <Drawer
      loading={loading}
      anchor="right"
      open={props.open}
      titulo="Resumo Favorecidos"
      handleOnClose={() => props.handleOnClose()}
    >
      <Box mt={2} p={2} className={classes.boxResumo}>
        {/* command: {JSON.stringify(simulacaoFinanciamentoResponse)} */}
        {lstSimulacaoFavorecidosResponse?.map(item => {
          return (
            <>
              <br />
              <Typography variant="subtitle2" color="primary">
                Dados do favorecido
              </Typography>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" component="span">
                    <strong>Nome: </strong> {item?.nomeTitular}
                  </Typography>
                </Grid>{' '}
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" component="span">
                    <strong>Cpf: </strong> {formatarCPF(item?.cpfCnpj)}
                  </Typography>
                </Grid>{' '}
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" component="span">
                    <strong>Valor a receber: </strong>{' '}
                    {formatarDinheiro(item?.valorReceber)}
                  </Typography>
                </Grid>{' '}
                <Grid item xs={12} sm={12}>
                  <Typography variant="body2" component="span">
                    <Box hidden={item?.integrado}>Favorecido cadastrado na BV</Box>
                    <Box hidden={!item?.integrado}>
                      Favorecido não esta cadastrado na BV
                    </Box>
                  </Typography>
                </Grid>{' '}
              </Grid>
            </>
          )
        })}
      </Box>
    </Drawer>
  )
}

export default DrawerFavorecidos
