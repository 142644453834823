import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Drawer from 'src/components/ui/Drawer'
import useAtividade from 'src/hooks/useAtividade'
import {
  ItemListagemSolicitacaoOrcamentoResponse,
  ObjetoDescricaoGenerico,
  OperacionalResponse
} from 'src/types'
import yup from 'src/components/yupCustom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { IAddAtividadeCommand } from 'src/api/atividade'
import {
  showNotification,
  showNotificationErrorAPI
} from 'src/core/helpers/notification'
import { FiPlus } from 'react-icons/fi'
import UBox from 'src/components/ui/Ubox'
import TextField from 'src/components/ui/TextField'
import { IAtividade, IUsuarioAtividade } from 'src/contexts/Atividade/context'
import { isSchema } from 'yup'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import { listarUsuarios } from 'src/api/solicitacao-orcamento'
import Alert from '@material-ui/lab/Alert'
import { isTemplateExpression } from 'typescript'
import formaPagamento from 'src/core/consts/formaPagamento'
import meioPagamento, { MEIO_PAGAMENTO } from 'src/core/consts/meioPagamento'
import HourInput from 'src/components/ui/HourInput'
import moment from 'moment'
import CurrencyInput from 'src/components/ui/CurrencyInput'
import usePagamento from 'src/hooks/usePagamento'
import Button from 'src/components/ui/Button'
import { IPagamentoAvulsoRequest } from 'src/contexts/Pagamento/context'
import { Pagamento } from 'src/api/pagamento'
import {
  formatarData,
  formatarDataHoraLocal,
  formatarHoraLocal
} from 'src/core/formatacoes/formatarData'
import { DropzoneArea } from 'material-ui-dropzone'
import { FaRegFilePdf } from 'react-icons/fa'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDropzoneArea-root': {
      minHeight: '200px'
    },
    '& .MuiDropzoneArea-text': {
      marginBottom: 0
    },
    '& .MuiDropzoneArea-text.MuiTypography-h5': {
      fontSize: '1.2rem',
      padding: '20px 40px 10px'
    },
    '& .MuiDropzoneArea-icon': {
      color: 'rgba(100, 100, 100, 0.87)'
    }
  }
}))

const stylePDF = {
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  border: '1px solid #ddd',
  borderRadius: '8px',
  boxShadow: '3px 3px 3px #eee',
  cursor: 'pointer',
  width: '150px',
  height: '150px',
  display: 'flex'
  // flexDirection: 'column'
}

const styleComp = {
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  border: '1px solid #ddd',
  borderRadius: '8px',
  boxShadow: '3px 3px 3px #eee',
  cursor: 'pointer',
  width: '150px',
  height: '150px'
}

interface Props {
  pedido?: Pagamento
  propostaId: string
  open: boolean
  handleOnClose: () => void
}

export default function DrawerPagamentoAvulso({
  pedido,
  open,
  propostaId,
  handleOnClose
}: Props) {
  const { pagamentoAvulso, loadingAvulso } = usePagamento()
  const [mostrarAnexo, setMostrarAnexo] = useState(!pedido?.urlComprovante)
  let style = useStyles()

  return (
    <Drawer
      open={open}
      anchor="right"
      handleOnClose={handleOnClose}
      titulo={'Detalhes pagamento avulso'}
    >
      <UBox component={Grid} container xs={12} spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Pagamento registrado por:</Typography>
          <Typography variant="body2">{pedido?.criadaPor}</Typography>
        </Grid>
      </UBox>
      <UBox component={Grid} container xs={12} spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Data do pagamento:</Typography>
          <Typography variant="body2">
            {formatarDataHoraLocal(pedido?.dataPagamento)}
          </Typography>
        </Grid>
      </UBox>
      <UBox component={Grid} container xs={12} spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Pagamento confirmado por:</Typography>
          <Typography variant="body2">{pedido?.modificadoPor}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Data de confirmação:</Typography>
          <Typography variant="body2">
            {formatarDataHoraLocal(pedido?.modificado)}
          </Typography>
        </Grid>
      </UBox>
      <Grid container xs={12} spacing={1}>
        <Grid item xs={6}>
          <Typography variant="subtitle2">Valor:</Typography>
          <Typography variant="body2">
            {formatarDinheiro(pedido?.valor)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">Juros:</Typography>
          <Typography variant="body2">
            {formatarDinheiro(pedido?.juros)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Forma de pagamento:</Typography>
          <Typography variant="body2">
            {formaPagamento.find(f => f.value === pedido?.formaPagamento)?.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Meio de Pagamento:</Typography>
          <Typography variant="body2">
            {meioPagamento.find(f => f.value === pedido?.tipoPagamento)?.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Código de Transação:</Typography>
          <Typography variant="body2">
            {pedido?.codigoEstabelecimento}
          </Typography>
          {!pedido?.codigoEstabelecimento && (
            <Typography variant="body2">Não informado</Typography>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} className={style.root}>
          <UBox mb={1}>
            <Typography variant="subtitle2">Comprovante:</Typography>
            {!pedido?.urlComprovante && (
              <Typography variant="body2">Não informado</Typography>
            )}

            <UBox render={!!pedido?.urlComprovante}>
              {pedido?.urlComprovante?.includes('pdf') ? (
                <div
                  onClick={() => window.open(pedido.urlComprovante, '_blank')}
                  style={{
                    fontSize: '100px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'red',
                    ...stylePDF
                  }}
                >
                  <FaRegFilePdf />
                  <p
                    style={{
                      fontSize: '14px',
                      margin: 0,
                      marginTop: 4,
                      padding: 0,
                      paddingTop: 0,
                      fontWeight: 600
                    }}
                  >
                    Baixar PDF
                  </p>
                </div>
              ) : (
                <div
                  onClick={() => window.open(pedido.urlComprovante, '_blank')}
                  style={{
                    background: `url(${pedido.urlComprovante})`,
                    ...styleComp
                  }}
                ></div>
              )}
            </UBox>
          </UBox>
        </Grid>
      </Grid>
      {/* <Grid item xs={12}>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleOnClose}
        >
          fechar
        </Button>
      </Grid> */}
    </Drawer>
  )
}
