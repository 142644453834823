import { yupResolver } from '@hookform/resolvers/yup'
import { Box, InputAdornment, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FiUser } from 'react-icons/fi'
import { RiArrowLeftLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { esqueciSenha } from 'src/api/auth'
import { Button } from 'src/components/ui/Button'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { onlyNumber, verificarCPF } from 'src/core/helpers/common'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import { EsqueciSenhaCommand } from 'src/types'
import Swal from 'sweetalert2'
import { TextField } from '../../components/ui/TextField'
import yup from '../../components/yupCustom'

const useStyles = makeStyles(theme => ({
  [theme.breakpoints.down('xs')]: {
    container: {
      minHeight: '100vh'
    }
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    margin: theme.spacing(1),
    width: 100
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(2, 0, 3)
  },
  inputIcon: { color: theme.palette.grey[500] }
}))

const schema = yup.object().shape({
  cpf: yup
    .string()
    .test('cpf-teste', 'CPF inválido', value => {
      if (!value) return false

      return verificarCPF(onlyNumber(value))
    })
    .required()
    .label('CPF')
})

export default function EsqueciSenha() {
  const classes = useStyles()
  const { signIn, signed } = useAuth()
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)

  const {
    register,
    errors,
    control,
    watch,
    handleSubmit
  } = useForm<EsqueciSenhaCommand>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: { cpf: '' }
  })
  const command = watch()

  const onSubmit = async (data: EsqueciSenhaCommand) => {
    try {
      setLoading(true)

      await esqueciSenha(data)

      setLoading(false)

      await Swal.fire({
        title: 'Sucesso!',
        text:
          'Dentro de instantes você deve receber um e-mail contendo as instruções para redefinir a senha.',
        icon: 'success',
        confirmButtonText: 'Ok'
      })

      history.replace(SynRoutes.signin)
    } catch (e) {
      showNotificationErrorAPI(e)
      setLoading(false)
    }
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      className="fadeInTransition syn-container-out"
    >
      <CssBaseline />
      <Box className={classes.paper + ' syn-box-out'}>
        <Link
          component="button"
          color="secondary"
          onClick={() => history.goBack()}
        >
          <RiArrowLeftLine />
          <Typography
            style={{ marginLeft: 10 }}
            variant="subtitle1"
            component="span"
          >
            Voltar
          </Typography>
        </Link>
        <Box my={3}>
          <Typography variant="h6">Esqueci minha senha</Typography>
        </Box>
        <Typography variant="body1">
          Digite o CPF da sua conta para que possamos enviar um e-mail com as
          instruções para definir uma nova senha.
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            mask={'999.999.999-99'}
            control={control}
            value={command.cpf}
            erro={errors}
            name="cpf"
            label="CPF"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FiUser className={classes.inputIcon} />
                </InputAdornment>
              )
            }}
          />

          <Button
            loading={loading}
            disabled={loading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Continuar
          </Button>
        </form>
      </Box>
    </Container>
  )
}
