import React, { useEffect, useState } from 'react'
import Drawer from 'src/components/ui/Drawer'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { obterPacotePorId } from 'src/api/pacote'

import ResumoMembroEquipe from './Resumo'
import { obterPacoteInteligentePorId } from 'src/api/pacoteInteligente'
import {
  MembroEquipeResponse,
  obterMembroEquipePorId
} from 'src/api/membroEquipe'

type Props = {
  id: string
  open: boolean
  handleOnClose: () => void
}

function DrawerResumoMembro({ id, open, handleOnClose }: Props) {
  const [membro, setMembro] = useState<MembroEquipeResponse>(null)
  const [loadingDetalhe, setLoadingDetalhe] = useState(false)

  useEffect(() => {
    if (!id) {
      return
    }
    setMembro(null)
    setLoadingDetalhe(true)
    obterMembroEquipePorId(id)
      .then(response => {
        setMembro(response.data)
        setLoadingDetalhe(false)
      })
      .catch(e => {
        showNotificationErrorAPI(e)
        handleOnClose()
      })
  }, [open])

  return (
    <Drawer
      loading={loadingDetalhe}
      anchor="right"
      open={open}
      titulo="Visualizar membro de equipe"
      handleOnClose={handleOnClose}
    >
      {!!membro && <ResumoMembroEquipe membro={membro} />}
    </Drawer>
  )
}

export default DrawerResumoMembro
