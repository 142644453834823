export default [
  { id: '001', descricao: 'Banco Do Brasil S.A (BB)' },
  { id: '237', descricao: 'Bradesco S.A' },
  { id: '260', descricao: 'Nu Pagamentos S.A (Nubank)' },
  { id: '104', descricao: 'Caixa Econômica Federal (Cef)' },
  { id: '033', descricao: 'Banco Santander Brasil S.A' },
  { id: '077', descricao: 'Banco Inter S.A' },
  { id: '341', descricao: 'Itaú Unibanco S.A' },
  { id: '246', descricao: 'Banco ABC Brasil S.A.' },
  { id: '748', descricao: 'Banco Cooperativo Sicredi S.A.' },
  { id: '117', descricao: 'Advanced Cc Ltda' },
  { id: '121', descricao: 'Banco Agibank S.A.' },
  { id: '172', descricao: 'Albatross Ccv S.A' },
  { id: '188', descricao: 'Ativa Investimentos S.A' },
  {
    id: '280',
    descricao: 'Avista S.A. Crédito, Financiamento e Investimento'
  },
  { id: '080', descricao: 'B&T Cc Ltda' },
  { id: '654', descricao: 'Banco A.J.Renner S.A.' },
  { id: '075', descricao: 'Banco ABN AMRO S.A' },
  { id: '025', descricao: 'Banco Alfa S.A.' },
  { id: '641', descricao: 'Banco Alvorada S.A.' },
  { id: '065', descricao: 'Banco Andbank (Brasil) S.A.' },
  { id: '213', descricao: 'Banco Arbi S.A.' },
  { id: '096', descricao: 'Banco B3 S.A.' },
  { id: '024', descricao: 'Banco BANDEPE S.A.' },
  { id: '318', descricao: 'Banco BMG S.A.' },
  { id: '752', descricao: 'Banco BNP Paribas Brasil S.A.' },
  { id: '107', descricao: 'Banco BOCOM BBM S.A.' },
  { id: '063', descricao: 'Banco Bradescard S.A.' },
  { id: '036', descricao: 'Banco Bradesco BBI S.A.' },
  { id: '122', descricao: 'Banco Bradesco BERJ S.A.' },
  { id: '204', descricao: 'Banco Bradesco Cartões S.A.' },
  { id: '394', descricao: 'Banco Bradesco Financiamentos S.A.' },
  { id: '218', descricao: 'Banco BS2 S.A.' },
  { id: '208', descricao: 'Banco BTG Pactual S.A.' },
  { id: '336', descricao: 'Banco C6 S.A – C6 Bank' },
  { id: '473', descricao: 'Banco Caixa Geral – Brasil S.A.' },
  { id: '412', descricao: 'Banco Capital S.A.' },
  { id: '040', descricao: 'Banco Cargill S.A.' },
  { id: '368', descricao: 'Banco Carrefour' },
  { id: '266', descricao: 'Banco Cédula S.A.' },
  { id: '739', descricao: 'Banco Cetelem S.A.' },
  { id: '233', descricao: 'Banco Cifra S.A.' },
  { id: '745', descricao: 'Banco Citibank S.A.' },
  { id: '241', descricao: 'Banco Clássico S.A.' },
  { id: '756', descricao: 'Banco Cooperativo do Brasil S.A. – BANCOOB' },
  { id: '222', descricao: 'Banco Credit Agricole Brasil S.A.' },
  { id: '505', descricao: 'Banco Credit Suisse (Brasil) S.A.' },
  { id: '069', descricao: 'Banco Crefisa S.A.' },
  { id: '003', descricao: 'Banco da Amazônia S.A.' },
  { id: '083', descricao: 'Banco da China Brasil S.A.' },
  { id: '707', descricao: 'Banco Daycoval S.A.' },
  {
    id: '051',
    descricao: 'Banco de Desenvolvimento do Espírito Santo S.A.'
  },
  { id: '300', descricao: 'Banco de La Nacion Argentina' },
  { id: '495', descricao: 'Banco de La Provincia de Buenos Aires' },
  { id: '494', descricao: 'Banco de La Republica Oriental del Uruguay' },
  { id: '335', descricao: 'Banco Digio S.A' },
  { id: '047', descricao: 'Banco do Estado de Sergipe S.A.' },
  { id: '037', descricao: 'Banco do Estado do Pará S.A.' },
  { id: '041', descricao: 'Banco do Estado do Rio Grande do Sul S.A.' },
  { id: '004', descricao: 'Banco do Nordeste do Brasil S.A.' },
  { id: '196', descricao: 'Banco Fair Corretora de Câmbio S.A' },
  { id: '265', descricao: 'Banco Fator S.A.' },
  { id: '224', descricao: 'Banco Fibra S.A.' },
  { id: '626', descricao: 'Banco Ficsa S.A.' },
  { id: '094', descricao: 'Banco Finaxis S.A.' },
  { id: '612', descricao: 'Banco Guanabara S.A.' },
  { id: '012', descricao: 'Banco Inbursa S.A.' },
  { id: '604', descricao: 'Banco Industrial do Brasil S.A.' },
  { id: '653', descricao: 'Banco Indusval S.A.' },
  { id: '249', descricao: 'Banco Investcred Unibanco S.A.' },
  { id: '184', descricao: 'Banco Itaú BBA S.A.' },
  { id: '029', descricao: 'Banco Itaú Consignado S.A.' },
  { id: '479', descricao: 'Banco ItauBank S.A' },
  { id: '376', descricao: 'Banco J. P. Morgan S.A.' },
  { id: '74', descricao: 'Banco J. Safra S.A.' },
  { id: '217', descricao: 'Banco John Deere S.A.' },
  { id: '076', descricao: 'Banco KDB S.A.' },
  { id: '757', descricao: 'Banco KEB HANA do Brasil S.A.' },
  { id: '600', descricao: 'Banco Luso Brasileiro S.A.' },
  { id: '243', descricao: 'Banco Máxima S.A.' },
  { id: '720', descricao: 'Banco Maxinvest S.A.' },
  { id: '389', descricao: 'Banco Mercantil de Investimentos S.A.' },
  { id: '370', descricao: 'Banco Mizuho do Brasil S.A.' },
  { id: '746', descricao: 'Banco Modal S.A.' },
  { id: '066', descricao: 'Banco Morgan Stanley S.A.' },
  { id: '456', descricao: 'Banco MUFG Brasil S.A.' },
  {
    id: '007',
    descricao: 'Banco Nacional de Desenvolvimento Econômico e Social – BNDES'
  },
  { id: '169', descricao: 'Banco Olé Bonsucesso Consignado S.A.' },
  { id: '111', descricao: 'Banco Oliveira Trust Dtvm S.A' },
  { id: '079', descricao: 'Banco Original do Agronegócio S.A.' },
  { id: '212', descricao: 'Banco Original S.A.' },
  { id: '712', descricao: 'Banco Ourinvest S.A.' },
  { id: '623', descricao: 'Banco PAN S.A.' },
  { id: '611', descricao: 'Banco Paulista S.A.' },
  { id: '643', descricao: 'Banco Pine S.A.' },
  { id: '658', descricao: 'Banco Porto Real de Investimentos S.A.' },
  { id: '747', descricao: 'Banco Rabobank International Brasil S.A.' },
  { id: '633', descricao: 'Banco Rendimento S.A.' },
  { id: '741', descricao: 'Banco Ribeirão Preto S.A.' },
  { id: '120', descricao: 'Banco Rodobens S.A.' },
  { id: '422', descricao: 'Banco Safra S.A.' },
  { id: '743', descricao: 'Banco Semear S.A.' },
  { id: '754', descricao: 'Banco Sistema S.A.' },
  { id: '630', descricao: 'Banco Smartbank S.A.' },
  { id: '366', descricao: 'Banco Société Générale Brasil S.A.' },
  { id: '637', descricao: 'Banco Sofisa S.A.' },
  { id: '464', descricao: 'Banco Sumitomo Mitsui Brasileiro S.A.' },
  { id: '082', descricao: 'Banco Topázio S.A.' },
  { id: '634', descricao: 'Banco Triângulo S.A.' },
  { id: '018', descricao: 'Banco Tricury S.A.' },
  { id: '655', descricao: 'Banco Votorantim S.A.' },
  { id: '610', descricao: 'Banco VR S.A.' },
  { id: '119', descricao: 'Banco Western Union do Brasil S.A.' },
  { id: '124', descricao: 'Banco Woori Bank do Brasil S.A.' },
  { id: '348', descricao: 'Banco Xp S/A' },
  { id: '081', descricao: 'BancoSeguro S.A.' },
  {
    id: '021',
    descricao: 'BANESTES S.A. Banco do Estado do Espírito Santo'
  },
  {
    id: '755',
    descricao: 'Bank of America Merrill Lynch Banco Múltiplo S.A.'
  },
  { id: '268', descricao: 'Barigui Companhia Hipotecária' },
  { id: '250', descricao: 'BCV – Banco de Crédito e Varejo S.A.' },
  { id: '144', descricao: 'BEXS Banco de Câmbio S.A.' },
  { id: '253', descricao: 'Bexs Corretora de Câmbio S/A' },
  { id: '134', descricao: 'Bgc Liquidez Dtvm Ltda' },
  { id: '017', descricao: 'BNY Mellon Banco S.A.' },
  { id: '301', descricao: 'Bpp Instituição De Pagamentos S.A' },
  { id: '126', descricao: 'BR Partners Banco de Investimento S.A.' },
  { id: '070', descricao: 'BRB – Banco de Brasília S.A.' },
  {
    id: '092',
    descricao: 'Brickell S.A. Crédito, Financiamento e Investimento'
  },
  {
    id: '173',
    descricao: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.'
  },
  { id: '142', descricao: 'Broker Brasil Cc Ltda' },
  {
    id: '292',
    descricao: 'BS2 Distribuidora de Títulos e Valores Mobiliários S.A.'
  },
  { id: '011', descricao: 'C.Suisse Hedging-Griffo Cv S.A (Credit Suisse)' },
  {
    id: '288',
    descricao: 'Carol Distribuidora de Títulos e Valor Mobiliários Ltda'
  },
  { id: '130', descricao: 'Caruana Scfi' },
  { id: '159', descricao: 'Casa Credito S.A' },
  { id: '016', descricao: 'Ccm Desp Trâns Sc E Rs' },
  { id: '089', descricao: 'Ccr Reg Mogiana' },
  {
    id: '114',
    descricao: 'Central Cooperativa De Crédito No Estado Do Espírito Santo'
  },
  {
    id: '320',
    descricao: 'China Construction Bank (Brasil) Banco Múltiplo S.A.'
  },
  { id: '477', descricao: 'Citibank N.A.' },
  { id: '180', descricao: 'Cm Capital Markets Cctvm Ltda' },
  { id: '127', descricao: 'Codepe Cvc S.A' },
  { id: '163', descricao: 'Commerzbank Brasil S.A. – Banco Múltiplo' },
  { id: '060', descricao: 'Confidence Cc S.A' },
  { id: '085', descricao: 'Coop Central Ailos' },
  {
    id: '097',
    descricao: 'Cooperativa Central de Crédito Noroeste Brasileiro Ltda.'
  },
  { id: '286', descricao: 'Cooperativa de Crédito Rural De Ouro' },
  {
    id: '279',
    descricao: 'Cooperativa de Crédito Rural de Primavera Do Leste'
  },
  {
    id: '273',
    descricao:
      'Cooperativa de Crédito Rural de São Miguel do Oeste – Sulcredi/São Miguel'
  },
  { id: '098', descricao: 'Credialiança Ccr' },
  { id: '010', descricao: 'Credicoamo' },
  { id: '133', descricao: 'Cresol Confederação' },
  { id: '182', descricao: 'Dacasa Financeira S/A' },
  { id: '487', descricao: 'Deutsche Bank S.A. – Banco Alemão' },
  { id: '140', descricao: 'Easynvest – Título Cv S.A' },
  { id: '149', descricao: 'Facta S.A. Cfi' },
  { id: '285', descricao: 'Frente Corretora de Câmbio Ltda.' },
  {
    id: '278',
    descricao: 'Genial Investimentos Corretora de Valores Mobiliários S.A.'
  },
  // {
  //   id: '274',
  //   descricao: 'BMP MONEY PLUS'
  // },
  { id: '138', descricao: 'Get Money Cc Ltda' },
  { id: '064', descricao: 'Goldman Sachs do Brasil Banco Múltiplo S.A.' },
  { id: '177', descricao: 'Guide Investimentos S.A. Corretora de Valores' },
  { id: '146', descricao: 'Guitta Corretora de Câmbio Ltda' },
  { id: '078', descricao: 'Haitong Banco de Investimento do Brasil S.A.' },
  { id: '062', descricao: 'Hipercard Banco Múltiplo S.A.' },
  {
    id: '189',
    descricao: 'HS Financeira S/A Crédito, Financiamento e Investimentos'
  },
  { id: '269', descricao: 'HSBC Brasil S.A. – Banco de Investimento' },
  {
    id: '271',
    descricao: 'IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.'
  },
  { id: '157', descricao: 'Icap Do Brasil Ctvm Ltda' },
  { id: '132', descricao: 'ICBC do Brasil Banco Múltiplo S.A.' },
  { id: '492', descricao: 'ING Bank N.V.' },
  { id: '139', descricao: 'Intesa Sanpaolo Brasil S.A. – Banco Múltiplo' },
  { id: '652', descricao: 'Itaú Unibanco Holding S.A.' },
  { id: '488', descricao: 'JPMorgan Chase Bank, National Association' },
  { id: '399', descricao: 'Kirton Bank S.A. – Banco Múltiplo' },
  {
    id: '293',
    descricao: 'Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.'
  },
  {
    id: '105',
    descricao: 'Lecca Crédito, Financiamento e Investimento S/A'
  },
  { id: '145', descricao: 'Levycam Ccv Ltda' },
  { id: '113', descricao: 'Magliano S.A' },
  { id: '323', descricao: 'Mercado Pago – Conta Do Mercado Livre' },
  { id: '128', descricao: 'MS Bank S.A. Banco de Câmbio' },
  { id: '137', descricao: 'Multimoney Cc Ltda' },
  { id: '014', descricao: 'Natixis Brasil S.A. Banco Múltiplo' },
  {
    id: '191',
    descricao: 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.'
  },
  { id: '753', descricao: 'Novo Banco Continental S.A. – Banco Múltiplo' },
  { id: '613', descricao: 'Omni Banco S.A.' },
  { id: '290', descricao: 'Pagseguro Internet S.A' },
  { id: '254', descricao: 'Paraná Banco S.A.' },
  {
    id: '326',
    descricao: 'Parati – Crédito Financiamento e Investimento S.A.'
  },
  {
    id: '194',
    descricao: 'Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda'
  },
  { id: '174', descricao: 'Pernambucanas Financ S.A' },
  { id: '100', descricao: 'Planner Corretora De Valores S.A' },
  { id: '125', descricao: 'Plural S.A. – Banco Múltiplo' },
  { id: '093', descricao: 'Pólocred Scmepp Ltda' },
  { id: '108', descricao: 'Portocred S.A' },
  { id: '283', descricao: 'Rb Capital Investimentos Dtvm Ltda' },
  { id: '101', descricao: 'Renascenca Dtvm Ltda' },
  { id: '270', descricao: 'Sagitur Corretora de Câmbio Ltda.' },
  { id: '751', descricao: 'Scotiabank Brasil S.A. Banco Múltiplo' },
  {
    id: '276',
    descricao: 'Senff S.A. – Crédito, Financiamento e Investimento'
  },
  { id: '545', descricao: 'Senso Ccvm S.A' },
  { id: '190', descricao: 'Servicoop' },
  { id: '183', descricao: 'Socred S.A' },
  {
    id: '299',
    descricao: 'Sorocred Crédito, Financiamento e Investimento S.A.'
  },
  {
    id: '118',
    descricao: 'Standard Chartered Bank (Brasil) S/A–Bco Invest.'
  },
  { id: '197', descricao: 'Stone Pagamentos S.A' },
  {
    id: '340',
    descricao: 'Super Pagamentos e Administração de Meios Eletrônicos S.A.'
  },
  { id: '095', descricao: 'Travelex Banco de Câmbio S.A.' },
  { id: '143', descricao: 'Treviso Corretora de Câmbio S.A.' },
  { id: '131', descricao: 'Tullett Prebon Brasil Cvc Ltda' },
  { id: '129', descricao: 'UBS Brasil Banco de Investimento S.A.' },
  { id: '091', descricao: 'Unicred Central Rs' },
  { id: '136', descricao: 'Unicred Cooperativa' },
  {
    id: '099',
    descricao:
      'UNIPRIME Central – Central Interestadual de Cooperativas de Crédito Ltda.'
  },
  {
    id: '084',
    descricao:
      'Uniprime Norte do Paraná – Coop de Economia eCrédito Mútuo dos Médicos, Profissionais das Ciências'
  },
  { id: '298', descricao: 'Vips Cc Ltda' },
  {
    id: '301',
    descricao: 'DOCK IP S.A.'
  },
  {
    id: '310',
    descricao: 'Vortx Distribuidora de Títulos e Valores Mobiliários Ltda'
  },
  { id: '102', descricao: 'Xp Investimentos S.A' }
] as any[]
