import { Box, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useEffect } from 'react'
import FormPacoteInteligente, {
  IPacoteInteligenteForm
} from 'src/components/pacoteInteligente/FormPacoteInteligente'
import Drawer from 'src/components/ui/Drawer'
import { FormPacote, IPacoteForm } from '../pacote/formPacote'
import {
  FormConfiguracao,
  Steps
} from '../pacote/pacoteInteligente/formConfiguracao'
import UBox from 'src/components/ui/Ubox'
import { FormConfiguracaoV2 } from '../pacote/pacoteInteligente/formConfiguracaoV2'
import { stepsFormulario } from './formUnificadoV2/formUnificadoV2'

type Props = {
  Form?: any
  etapa: Steps
  pacote: IPacoteInteligenteForm
  open: boolean
  handleOnClose: () => void
  handleSalvarAjuste?: (dados: IPacoteInteligenteForm) => void
}

function DrawerAjustarPacoteInteligente({ Form, ...props }: Props) {
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))

  function obterForm() {
    // if (!!Form) {
    //   return (
    // <Form
    //   pacote={props.pacote}
    //   etapaAjuste={props.etapa}
    //   handleSalvarAjuste={props.handleSalvarAjuste}
    // />

    // <FormConfiguracaoV2
    //   pacote={pacoteInstantaneoSelecionado}
    //   setPacote={pacote => {
    //     setPacoteInstantaneoSelecionado(pacote)
    //   }}
    //   activeStep={activeStepConfiguracao}
    //   setActiveStep={step => {
    //     handleChangeStep(step)
    //   }}
    //   handleConfiguracao={handleConfiguracao}
    //   // orcamentoDetalhado={orcamentoDetalhado}
    //   medicoCpf={medicoCpf}
    //   medicoId={medicoSolicitacaoId}
    //   stepsFormulario={stepsFormulario}
    // />
    //   )
    // }

    return (
      //   <FormConfiguracao
      //   pacote={props.pacote}
      //   etapaAjuste={props.etapa}
      //   handleSalvarAjuste={props.handleSalvarAjuste}
      // />
      <FormConfiguracaoV2
        // pacote={props.pacote}
        etapaAjuste={props.etapa}
        handleSalvarAjuste={props.handleSalvarAjuste}
        isback={() => {}}
        pacote={props.pacote}
        setPacote={pacote => {
          // setPacoteInstantaneoSelecionado(pacote)
        }}
        activeStep={props.etapa}
        setActiveStep={step => {
          // handleChangeStep(step)
        }}
        // handleConfiguracao={handleConfiguracao}
        // medicoCpf={medicoCpf}
        // medicoId={medicoSolicitacaoId}
        stepsFormulario={JSON.parse(JSON.stringify(stepsFormulario))}
      />
    )
  }

  return (
    <Drawer
      anchor="right"
      open={props.open}
      titulo="Ajustar pacote para o paciente"
      handleOnClose={props.handleOnClose}
      BackdropProps={{ invisible: false }}
      disableBackdropClick
      noPadding={ehXS}
      // elevation={2}
      disableEscapeKeyDown={true}
    >
      {/* {JSON.stringify(props)} */}
      <Box mt={3}>{obterForm()}</Box>
    </Drawer>
  )
}

export default DrawerAjustarPacoteInteligente
