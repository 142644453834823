import React, { useEffect, useState } from 'react'
import Drawer from 'src/components/ui/Drawer'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { obterPacotePorId } from 'src/api/pacote'
import ResumoPacote from 'src/pages/dashboard/medico/pacote/resumo'
import DrawerVisualizarDetalhes from 'src/pages/dashboard/operacional/pacote/drawerVisualizarDetalhes'
import { SimulacaoFinanciamentoResponse } from 'src/types'
import { Box, Typography, Chip } from '@material-ui/core'
import formatarCPF from 'src/core/formatacoes/formatarCPF'
import { formatarData } from 'src/core/formatacoes/formatarData'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'
import UBox from '../../../../components/ui/Ubox'

type Props = {
  id: string
  open: boolean
  item: SimulacaoFinanciamentoResponse
  handleOnClose: () => void
}

function DrawerVisualizarDetalhesFinanciamento({
  id,
  open,
  handleOnClose,
  item
}: Props) {
  const [detalheVisualizacao, setDetalheVisualizacao] = useState(null)
  const [loadingDetalhe, setLoadingDetalhe] = useState(false)

  useEffect(() => {
    if (!id) {
      return
    }
    setDetalheVisualizacao(null)
    setLoadingDetalhe(true)
    obterPacotePorId(id)
      .then(response => {
        setDetalheVisualizacao(response.data)
        setLoadingDetalhe(false)
      })
      .catch(e => {
        showNotificationErrorAPI(e)
        handleOnClose()
      })
  }, [open])

  return (
    <Drawer
      loading={loadingDetalhe}
      anchor="right"
      open={open}
      titulo="Visualizar pacote"
      handleOnClose={handleOnClose}
    >
      <Typography variant="body1" color="primary">
        <b style={{ fontWeight: 600 }}>Solicitação de financiamento</b>
      </Typography>
      <UBox p={1} style={{ border: '1px solid #eee', borderRadius: '4px' }}>
        <UBox display="flex" width="100%">
          <Box width="50%">
            <Typography variant="body2">
              <small style={{ fontWeight: 600 }}>Nome</small>
              <br />
              {item.nome}
            </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="body2">
              <small style={{ fontWeight: 600 }}>Data de nascimento</small>
              <br />
              {formatarData(item.dataNascimento)}
            </Typography>
          </Box>
        </UBox>
        <UBox display="flex" width="100%">
          <Box width="50%">
            <Typography variant="body2">
              <small style={{ fontWeight: 600 }}>CPF</small>
              <br />

              {formatarCPF(item.cpf)}
            </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="body2">
              <small style={{ fontWeight: 600 }}>Telefone</small>
              <br />
              {formatarTelefone(item.telefone)}
            </Typography>
          </Box>
        </UBox>
        <UBox display="flex" width="100%">
          <Box width="50%">
            <Typography variant="body2">
              <small style={{ fontWeight: 600 }}>Renda</small>
              <br />
              {formatarDinheiro(item.renda)}
            </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="body2">
              <small style={{ fontWeight: 600 }}>Valor solicitado</small>
              <br />
              {formatarDinheiro(item.valorTotalFinanciamento)}
            </Typography>
          </Box>
        </UBox>
        <UBox render={!!item.valorParcialLiberado}>
          <Typography variant="body2">
            <small style={{ fontWeight: 600 }}>Valor parcial liberado</small>
            <br />
            <Chip
              label={formatarDinheiro(item.valorParcialLiberado)}
              variant="default"
              size="small"
              style={{
                backgroundColor: '#82c05f',
                fontWeight: 500,
                color: '#fff'
              }}
            />
          </Typography>
        </UBox>
      </UBox>

      <UBox mt={2}>
        <Typography variant="body1" color="primary">
          <b style={{ fontWeight: 600 }}>Resumo da solicitação</b>
        </Typography>
      </UBox>
      {!!detalheVisualizacao && <ResumoPacote pacote={detalheVisualizacao} />}
    </Drawer>
  )
}

export default DrawerVisualizarDetalhesFinanciamento
