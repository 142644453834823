import { Grid } from '@material-ui/core'
import TextField from 'src/components/ui/TextField'
type Props = {
  control?: any
  errors?: any
  register?: any
  loadingCep?: boolean
  type: 'normal' | 'company'
}
export default function Address({ control, errors, register, loadingCep, type }: Props) {
  const loadingDados = false
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <TextField
          disabled={loadingCep}
          type="tel"
          size="medium"
          erro={errors}
          mask="99999-999"
          control={control}
          inputProps={{ maxLength: 64 }}
          name={type === 'company' ? 'companyCep' : 'cep'}
          label="Seu CEP"
          InputLabelProps={{
            shrink: true
          }}
          loading={loadingCep}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          disabled={loadingDados}
          size="medium"
          erro={errors}
          inputRef={register}
          inputProps={{ maxLength: 100 }}
          name={type === 'company' ? 'companyState' : 'state'}
          label="Seu estado"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} sm={5}>
        <TextField
          disabled={loadingDados}
          size="medium"
          erro={errors}
          inputRef={register}
          inputProps={{ maxLength: 100 }}
          name={type === 'company' ? 'companyCity' : 'city'}
          label="Sua cidade"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField
          disabled={loadingDados}
          size="medium"
          erro={errors}
          inputRef={register}
          inputProps={{ maxLength: 100 }}
          name={type === 'company' ? 'companyStreet' : 'street'}
          label="Seu endereço"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <TextField
          disabled={loadingDados}
          size="medium"
          erro={errors}
          inputRef={register}
          inputProps={{ maxLength: 100 }}
          name={type === 'company' ? 'companyNeighborhood' : 'neighborhood'}
          label="Bairro"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          disabled={loadingDados}
          size="medium"
          erro={errors}
          inputRef={register}
          inputProps={{ maxLength: 100 }}
          name={type === 'company' ? 'companyStreetNumber' : 'streetNumber'}
          label="Número"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          disabled={loadingDados}
          size="medium"
          erro={errors}
          inputRef={register}
          control={control}
          inputProps={{ maxLength: 100 }}
          name={type === 'company' ? 'companyComplementary' : 'complementary'}
          label="Complemento"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          disabled={loadingDados}
          size="medium"
          erro={errors}
          inputRef={register}
          control={control}
          inputProps={{ maxLength: 100 }}
          name={type === 'company' ? 'companyReferencePoint' : 'referencePoint'}
          label="ponto de referência"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
    </Grid>
  )
}
