import {
  Box,
  Chip,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { IAny, ItemInternacao } from 'src/types'

import { MdEdit } from 'react-icons/md'

import clsx from 'clsx'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'

import useAuth from 'src/hooks/useAuth'
import { SynPerfis } from 'src/core/consts/synPerfis'
import {
  IPacoteInteligenteForm,
  ItemMaterialInteligente,
  ItemOpmeInteligente
} from './FormPacoteInteligente'
import UBox from 'src/components/ui/Ubox'
import { Steps } from 'src/pages/dashboard/medico/pacote/pacoteInteligente/formConfiguracao'
import { FiMinus, FiPlus, FiTrash } from 'react-icons/fi'
import INTERNACOES, { INTERNACOES_OBJ } from 'src/core/consts/internacoes'
import { ItemListagemMembroEquipe } from 'src/api/membroEquipe'

interface IPacoteProps extends IAny {
  adaptarValor?: boolean
  exibirValor?: boolean
  selecionado?: boolean
  unificarOpmes?: boolean
  forcarHonorarios?: boolean
  esconderExames?: boolean
  esconderIncluso?: boolean
  listaMembros?: ItemListagemMembroEquipe[]
  pacote: IPacoteInteligenteForm
  medico?: boolean
  exibirAjusteEtapa?: boolean
  handleAjusteEtapa?: (etapa: Steps) => void
  handleAjusteEtapaSolicitacao?: (activeTab: number) => void
  erro?: boolean
  exibirDadosMedicos?: boolean
}

const useStylesResumo = makeStyles(theme => ({
  boxResumo: {
    border: '1px solid #eee',
    backgroundColor: '#efefef',
    borderRadius: 4,
    '& .MuiTableCell-root.MuiTableCell-head': {
      padding: theme.spacing(1, 2),
      background: '#f9f9f9',
      color: theme.palette.grey[800]
    },
    '& h3': {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  esconderEditar: {
    '& button': {
      display: 'none'
    }
  },
  fontMarca: {
    fontStyle: 'italic',
    fontWeight: 500,
    fontSize: '0.8rem',
    color: theme.palette.grey[700]
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))

const ResumoPacoteInteligenteOps = (props: IPacoteProps) => {
  let {
    pacote,
    unificarOpmes = false,
    forcarHonorarios = false,
    exibirDadosMedicos = false,
    esconderIncluso = false,
    medico = false,
    adaptarValor = true,
    exibirValor = false,
    selecionado = false,
    esconderExames = false,
    listaMembros = [],
    ...rest
  } = props
  const classes = useStylesResumo()

  const { user, perfilAtual } = useAuth()
  const ehPaciente = perfilAtual === SynPerfis.PACIENTE

  if (listaMembros?.length > 0) {
    var membros = pacote.membros
    //filtra membros inativos
    membros = membros.filter(m => listaMembros.find(lm => lm.id === m.id))
    pacote.membros = membros
  }

  function obterInternacao(): ItemInternacao {
    var int = pacote?.valoresInternacao?.filter(
      i => i.id.toLowerCase() === INTERNACOES_OBJ.INT.id
    )

    if (!!int) {
      return int[0]
    }
  }

  function obterUti(): ItemInternacao {
    var uti = pacote?.valoresInternacao?.filter(
      i => i.id.toLowerCase() === INTERNACOES_OBJ.UTI.id
    )

    if (!!uti) {
      return uti[0]
    }
  }

  if (medico) {
    unificarOpmes = true
  }

  type BtnAjustarProps = {
    onClick: () => void
    erro?: boolean
  }

  function BtnAjustar(props: BtnAjustarProps) {
    return (
      <IconButton
        color="primary"
        size="small"
        style={{ textTransform: 'none', opacity: 0.7 }}
        onClick={props.onClick}
        className={props.erro ? 'danger' : null}
      >
        <MdEdit /> <span style={{ fontSize: '0.9rem' }}>Ajustar</span>
      </IconButton>
    )
  }

  function formatarValor(valor: number, forcaAdaptar = false) {
    let valorFormatado = valor / (adaptarValor || forcaAdaptar ? 100 : 1)

    // if (!exibirValor) {
    //   return ''
    // }

    return formatarDinheiro(valorFormatado)
  }

  function obterTodosOpmesSelecionados() {
    let opmesInclusos = pacote?.opmesInclusos || []
    let opmes = pacote?.opmes || []

    var listaOpme = [...opmesInclusos, ...opmes]

    if (!!selecionado) {
      return listaOpme.filter(i => i.selecionado)
    }

    return listaOpme
  }

  function obterTodosItens(key: keyof IPacoteInteligenteForm) {
    let itens = pacote[key]

    if (!!selecionado) {
      return (itens as ItemMaterialInteligente[]).filter(i => i.selecionado)
    }

    return itens as ItemMaterialInteligente[]
  }

  function obterQtdDiariasIclusas(ehUti = false) {
    if (!ehUti) {
      return pacote.valoresInternacao.find(i => i.id === INTERNACOES_OBJ.INT.id)
        ?.quantidadeIncluso
    } else {
      return pacote.valoresInternacao.find(i => i.id === INTERNACOES_OBJ.UTI.id)
        ?.quantidadeIncluso
    }
  }

  function obterValorHonorario() {
    var honorario = pacote.honorarioEquipeMedica

    return honorario
  }

  function obterValorEquipeMedica() {
    var equipe = pacote?.membros.reduce(
      (partialSum, i) => partialSum + i.valor,
      0
    )

    return equipe
  }

  return (
    <Box
      mt={2}
      p={2}
      className={clsx({
        [classes.boxResumo]: true,
        [classes.esconderEditar]: !props.exibirAjusteEtapa
      })}
      {...rest}
    >
      {/* <UBox style={{ wordBreak: 'break-all' }}>
        {JSON.stringify(pacote.valoresInternacao)}
      </UBox> */}
      <Typography variant="subtitle2" component="h3" color="primary">
        Hospital / Clinica
        {/* <BtnAjustar onClick={() => props.handleAjusteEtapa(0)} /> */}
      </Typography>
      <Typography variant="body2">{pacote?.hospital?.descricao}</Typography>
      <br />
      {!!pacote.propostaDiasUteis &&
        perfilAtual === SynPerfis.OPERACIONAL && (
          <>
            <Typography variant="subtitle2" component="h3" color="primary">
              Proposta para dias úteis
            </Typography>
            <Typography variant="body2">
              Desconto de {formatarValor(pacote.descontoFinalDeSemana)}
            </Typography>
            <br />
          </>
        )}
      {!!pacote.propostaFinalDeSemana &&
        perfilAtual === SynPerfis.OPERACIONAL && (
          <>
            <Typography variant="subtitle2" component="h3" color="primary">
              Proposta permite final de semana
            </Typography>
            <Typography variant="body2">
              Desconto de {formatarValor(pacote.descontoFinalDeSemana)}
            </Typography>
            <br />
          </>
        )}
      {!!pacote.propostaCentroCirurgicoAmbulatorial &&
        perfilAtual === SynPerfis.OPERACIONAL && (
          <>
            <Typography variant="subtitle2" component="h3" color="primary">
              Proposta permite centro cirúrgico ambulatorial
            </Typography>
            <Typography variant="body2">
              Desconto de{' '}
              {formatarValor(pacote.descontoCentroCirurgicoAmbulatorial)}
            </Typography>
            <br />
          </>
        )}

      {!!pacote?.propostaFinalDeSemana && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Propostas para dias úteis
            <BtnAjustar onClick={() => props.handleAjusteEtapaSolicitacao(4)} />
          </Typography>
          <Typography variant="body2">
            {pacote.podeDiasUteis === true ? 'Sim' : 'Não'}
          </Typography>
          <br />

          <Typography variant="subtitle2" component="h3" color="primary">
            Propostas para final de semana
            <BtnAjustar onClick={() => props.handleAjusteEtapaSolicitacao(4)} />
          </Typography>
          <Typography variant="body2">
            {pacote.podeFinalDeSemana === true ? 'Sim' : 'Não'}
          </Typography>
          <br />
        </>
      )}
      {!!pacote.propostaCentroCirurgicoAmbulatorial && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Propostas para centro cirúrgico ambulatorial
            <BtnAjustar onClick={() => props.handleAjusteEtapaSolicitacao(4)} />
          </Typography>
          <Typography variant="body2">
            {pacote.podeCentroCirurgicoAmbulatorial === true ? 'Sim' : 'Não'}
          </Typography>
          <br />
        </>
      )}
      {pacote?.planoDeSaude === true && perfilAtual === SynPerfis.OPERACIONAL && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Perguntar se paciente possui plano de saúde?
          </Typography>
          <Typography variant="body2">
            {pacote?.planoDeSaude === true ? 'Sim' : ''}
          </Typography>
          <br />
        </>
      )}
      <UBox render={!medico}>
        <Typography variant="subtitle2" component="h3" color="primary">
          Valor base
          {/* <BtnAjustar onClick={() => props.handleAjusteEtapa(0)} /> */}
        </Typography>
        <Typography variant="body2">
          {formatarValor(pacote?.valorBase)}
        </Typography>
        <br />
      </UBox>
      <UBox render={!medico && !!pacote?.taxaSyn}>
        <Typography variant="subtitle2" component="h3" color="primary">
          Taxa personalizada
          {/* <BtnAjustar onClick={() => props.handleAjusteEtapa(0)} /> */}
        </Typography>
        <Typography variant="body2">
          {formatarValor(pacote?.taxaSyn)?.toString().replace('R$', '')} %
        </Typography>
        <br />
      </UBox>
      <UBox render={!medico && !!pacote?.taxaOpme}>
        <Typography variant="subtitle2" component="h3" color="primary">
          Taxa de OPME do hospital para outros fornecedores
          {/* <BtnAjustar onClick={() => props.handleAjusteEtapa(0)} /> */}
        </Typography>
        <Typography variant="body2">
          {formatarValor(pacote?.taxaOpme)?.toString().replace('R$', '')} %
        </Typography>
        <br />
      </UBox>
      <Typography variant="subtitle2" component="h3" color="primary">
        Nome da cirurgia/procedimento
        {/* <BtnAjustar onClick={() => props.handleAjusteEtapa(0)} /> */}
      </Typography>
      <Typography variant="body2">{pacote.descricao}</Typography>
      <br />

      <UBox render={!medico}>
        <Typography variant="subtitle2" component="h3" color="primary">
          Especialidades
          {/* <BtnAjustar onClick={() => props.handleAjusteEtapa(2)} /> */}
        </Typography>
        <Typography variant="body2">
          {pacote.especialidades?.length === 0 && 'Nenhuma anestesia'}
          {!!pacote.especialidades?.length &&
            pacote.especialidades?.map(e => e.descricao).join(', ')}
        </Typography>
        <br />
      </UBox>
      <UBox render={!medico}>
        <Typography variant="subtitle2" component="h3" color="primary">
          Médicos
          {/* <BtnAjustar onClick={() => props.handleAjusteEtapa(2)} /> */}
        </Typography>
        <Typography variant="body2">
          {pacote.pacotePublico ? (
            'Disponível para todos os médicos das especialidades'
          ) : (
            <>
              {pacote.listaMedico?.length === 0 && 'Nenhum médico'}
              {!!pacote.listaMedico?.length &&
                pacote.listaMedico?.map(e => e.nome).join(', ')}
            </>
          )}
        </Typography>
        <br />
      </UBox>
      <Typography variant="subtitle2" component="h3" color="primary">
        Procedimentos
        {/* <BtnAjustar onClick={() => props.handleAjusteEtapa(1)} /> */}
      </Typography>
      <Typography variant="body2">
        {pacote.procedimentos?.map((p, index) => (
          <div key={'procedimento-' + index}>
            Cod. {p.detalhe} - {p.descricao}
          </div>
        ))}
      </Typography>
      <br />

      <Typography variant="subtitle2" component="h3" color="primary">
        Tipos de anestesia
        <BtnAjustar onClick={() => props.handleAjusteEtapa(Steps.anestesia)} />
      </Typography>
      <Typography variant="body2">
        {pacote.tiposAnestesia?.filter(i =>
          selecionado ? !!i.selecionado : true
        )?.length === 0 && 'Nenhuma anestesia'}

        {!!medico &&
          obterTodosItens('tiposAnestesia')
            ?.map(e => e.descricao)
            .join(', ')}

        {!medico &&
          !!pacote?.tiposAnestesia?.filter(
            i => !!i.valorIncluso && (selecionado ? !!i.selecionado : true)
          ).length && (
            <>
              <small>
                <b>Incluso no pacote:</b>
              </small>
              <br />
              {pacote.tiposAnestesia
                .filter(
                  i =>
                    !!i.valorIncluso && (selecionado ? !!i.selecionado : true)
                )
                .map(
                  e =>
                    `${e.descricao} ${exibirValor ? `(${formatarValor(e.valor)})` : ''
                    }`
                )
                .join(', ')}
              <br />
            </>
          )}

        {!medico &&
          !!pacote?.tiposAnestesia?.filter(
            i => !i.valorIncluso && (selecionado ? !!i.selecionado : true)
          ).length && (
            <>
              <small>
                <b>Não incluso no pacote:</b>
              </small>
              <br />
              {pacote.tiposAnestesia
                .filter(
                  i => !i.valorIncluso && (selecionado ? !!i.selecionado : true)
                )
                .map(
                  e =>
                    `${e.descricao} ${exibirValor ? `(${formatarValor(e.valor)})` : ''
                    }`
                )
                .join(', ')}
            </>
          )}
        {/* {!!pacote.tiposAnestesia?.length &&
          pacote.tiposAnestesia?.map(e => e.descricao).join(', ')} */}
      </Typography>
      <br />
      {unificarOpmes && (
        <UBox>
          <Typography variant="subtitle2" component="h3" color="primary">
            Lista de OPME
            <BtnAjustar onClick={() => props.handleAjusteEtapa(Steps.opme)} />
          </Typography>
          {obterTodosOpmesSelecionados()?.length === 0 && (
            <Typography variant="body2">Não possui</Typography>
          )}

          {!!obterTodosOpmesSelecionados()?.length && (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    {!!esconderIncluso && (
                      <TableCell align="right" width="105px">
                        Qtd.
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obterTodosOpmesSelecionados()?.map((opme, i) => (
                    <TableRow key={i}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ lineHeight: '0.75rem' }}
                      >
                        <ItemOpme
                          formatarValor={formatarValor}
                          adaptarValor={adaptarValor}
                          esconderIncluso={esconderIncluso}
                          medico={medico}
                          exibirValor={exibirValor}
                          opme={opme}
                          i={i}
                        />
                      </TableCell>
                      {!!esconderIncluso && (
                        <TableCell align="right" width="55px">
                          <b>{opme.quantidade || 1}</b>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <br />
        </UBox>
      )}

      {!unificarOpmes && (
        <UBox>
          <Typography variant="subtitle2" component="h3" color="primary">
            Lista de OPME fornecidos pelo hospital
            <BtnAjustar onClick={() => props.handleAjusteEtapa(Steps.opme)} />
          </Typography>
          {pacote?.opmesInclusos?.filter(i =>
            selecionado ? !!i.selecionado : true
          )?.length === 0 && (
              <Typography variant="body2">Não possui</Typography>
            )}

          {!!pacote.opmesInclusos?.filter(i =>
            selecionado ? !!i.selecionado : true
          )?.length && (
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      {medico && selecionado && (
                        <TableCell align="right" width="55px">
                          Qtd
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pacote.opmesInclusos
                      ?.filter(i => (selecionado ? !!i.selecionado : true))
                      ?.map((opme, i) => (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row">
                            <ItemOpme
                              formatarValor={formatarValor}
                              adaptarValor={adaptarValor}
                              esconderIncluso={esconderIncluso}
                              medico={medico}
                              exibirValor={exibirValor}
                              opme={opme}
                              i={i}
                            />
                          </TableCell>

                          {medico && selecionado && (
                            <TableCell align="right" width="55px">
                              <b>{opme.quantidade || 1}</b>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          <br />

          <Typography variant="subtitle2" component="h3" color="primary">
            Lista de OPME fornecidos por terceiros
            <BtnAjustar onClick={() => props.handleAjusteEtapa(Steps.opme)} />
          </Typography>
          {pacote.medicoInformaOpme && (
            <Typography variant="body2">
              Itens de OPME informado pelo médico
            </Typography>
          )}
          {(pacote.opmes?.filter(i => (selecionado ? !!i.selecionado : true))
            ?.length === 0 ||
            !pacote.possuiOpmeTerceiro) &&
            !pacote.medicoInformaOpme && (
              <Typography variant="body2">Não possui</Typography>
            )}
          {!!pacote.opmes?.filter(i => (selecionado ? !!i.selecionado : true))
            ?.length && (
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      {pacote.medicoInformaOpme && (
                        <TableCell align="right" width="125px">
                          Valor
                        </TableCell>
                      )}
                      {medico && selecionado && (
                        <TableCell align="right" width="55px">
                          Qtd
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pacote.opmes
                      ?.filter(i => (selecionado ? !!i.selecionado : true))
                      ?.map((opme, i) => (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row">
                            <ItemOpme
                              formatarValor={formatarValor}
                              adaptarValor={adaptarValor}
                              esconderIncluso={esconderIncluso}
                              medico={medico}
                              exibirValor={exibirValor}
                              opme={opme}
                              i={i}
                            />
                          </TableCell>
                          {pacote.medicoInformaOpme && (
                            <TableCell align="center">
                              {opme.hospitalFornece
                                ? '-'
                                : formatarValor(opme?.valorUnitario)}
                            </TableCell>
                          )}
                          {medico && selecionado && (
                            <TableCell align="right" width="55px">
                              <b>{opme.quantidade || 1}</b>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          <br />
        </UBox>
      )}
      <Typography variant="subtitle2" component="h3" color="primary">
        Equipamentos hospitalares
        <BtnAjustar
          onClick={() => props.handleAjusteEtapa(Steps.equipamentosMat)}
        />
      </Typography>
      <Typography variant="body2">
        {pacote.equipamentos?.filter(i =>
          selecionado ? !!i.selecionado : true
        )?.length === 0 && 'Nenhum equipamento'}

        {!!medico &&
          obterTodosItens('equipamentos')
            ?.map(e => e.descricao)
            .join(', ')}

        {!medico &&
          !!pacote?.equipamentos?.filter(
            i => !!i.valorIncluso && (selecionado ? !!i.selecionado : true)
          )?.length && (
            <>
              <small>
                <b>Incluso no pacote:</b>
              </small>
              <br />
              {pacote.equipamentos
                .filter(
                  i =>
                    !!i.valorIncluso && (selecionado ? !!i.selecionado : true)
                )
                .map(e => `${e.descricao}`)
                .join(', ')}
              <br />
            </>
          )}
        {!medico &&
          !!pacote?.equipamentos?.filter(
            i => !i.valorIncluso && (selecionado ? !!i.selecionado : true)
          ).length && (
            <>
              <small>
                <b>Não incluso no pacote:</b>
              </small>
              <br />
              {pacote.equipamentos
                .filter(
                  i => !i.valorIncluso && (selecionado ? !!i.selecionado : true)
                )
                .map(
                  e =>
                    `${e.descricao} ${exibirValor ? `(${formatarValor(e.valor)})` : ''
                    }`
                )
                .join(', ')}
            </>
          )}
      </Typography>
      <br />

      <Typography variant="subtitle2" component="h3" color="primary">
        Materiais específicos
        <BtnAjustar
          onClick={() => props.handleAjusteEtapa(Steps.equipamentosMat)}
        />
      </Typography>
      <Typography variant="body2">
        {pacote.materiais?.filter(i => (selecionado ? !!i.selecionado : true))
          ?.length === 0 && 'Nenhum material'}

        {!!obterTodosItens('materiais')?.length && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  {!!esconderIncluso && (
                    <TableCell align="right" width="105px">
                      Qtd.
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {obterTodosItens('materiais')?.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ lineHeight: '0.75rem' }}
                    >
                      <ItemGenerico
                        formatarValor={formatarValor}
                        esconderIncluso={esconderIncluso}
                        exibirValor={exibirValor}
                        item={item}
                        i={i}
                        medico={medico}
                      />
                    </TableCell>
                    {!!esconderIncluso && (
                      <TableCell align="right" width="55px">
                        <b>{item.quantidade || 1}</b>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Typography>
      <br />
      <UBox render={!esconderExames}>
        <Typography variant="subtitle2" component="h3" color="primary">
          Exames durante internação
          <BtnAjustar onClick={() => props.handleAjusteEtapa(Steps.exames)} />
        </Typography>
        <Typography variant="body2">
          {pacote.examesDuranteInternacao?.filter(i =>
            selecionado ? !!i.selecionado : true
          )?.length === 0 && 'Nenhum exame'}

          {!!obterTodosItens('examesDuranteInternacao')?.length && (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    {!!esconderIncluso && (
                      <TableCell align="right" width="105px">
                        Qtd.
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obterTodosItens('examesDuranteInternacao')?.map(
                    (item, i) => (
                      <TableRow key={i}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ lineHeight: '0.75rem' }}
                        >
                          <ItemGenerico
                            formatarValor={formatarValor}
                            esconderIncluso={esconderIncluso}
                            exibirValor={exibirValor}
                            item={item}
                            i={i}
                            medico={medico}
                          />
                        </TableCell>
                        {!!esconderIncluso && (
                          <TableCell align="right" width="55px">
                            <b>{item.quantidade || 1}</b>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Typography>
        <br />
      </UBox>

      {!!medico && !!pacote?.possuiSangue && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Pode solicitar reserva de sangue?
          </Typography>
          <Typography variant="body2">Sim</Typography>
          <br />
        </>
      )}

      {!medico && !!pacote?.possuiSangue && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Reserva de sangue
          </Typography>
          <Typography variant="body2">
            {formatarValor(pacote?.valorTaxaReservaSangue)}
          </Typography>
          <br />
        </>
      )}

      {!medico && !!pacote?.possuiSangue && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Valor da unidade de concentrado de hemácias
          </Typography>
          <Typography variant="body2">
            {formatarValor(pacote?.valorUnidadeConcentradoHemacias)}
          </Typography>
          <br />
        </>
      )}

      {!medico && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Tipos de internação disponíveis
            <BtnAjustar
              onClick={() => props.handleAjusteEtapa(Steps.internacao)}
            />
          </Typography>
          <Typography variant="body2">
            {pacote.valoresInternacao?.length === 0 && 'Nenhuma anestesia'}
            {!!pacote.valoresInternacao?.length &&
              pacote.valoresInternacao?.map(e => e.descricao).join(', ')}
          </Typography>
          <br />
        </>
      )}

      {!!pacote.dayClinic && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Day clinic (até 12h de internação)
            <BtnAjustar
              onClick={() => props.handleAjusteEtapa(Steps.internacao)}
            />
          </Typography>
          <Typography variant="body2">Sim</Typography>
          <br />
        </>
      )}

      {!!pacote.pqa && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            PQA (até 6h de internação)
            <BtnAjustar
              onClick={() => props.handleAjusteEtapa(Steps.internacao)}
            />
          </Typography>
          <Typography variant="body2">Sim</Typography>
          <br />
        </>
      )}

      {!pacote.dayClinic && !pacote.pqa && !!obterUti()?.id && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            UTI
            <BtnAjustar
              onClick={() => props.handleAjusteEtapa(Steps.internacao)}
            />
          </Typography>
          <Typography variant="body2">
            {`${pacote.diariasUti + obterQtdDiariasIclusas(true) || '0'
              }  diária(s) inclusa(s) de UTI`}
          </Typography>
          {!!obterUti()?.valor && perfilAtual === SynPerfis.OPERACIONAL && (
            <Typography variant="body2">
              <b>Valor de 1 diária extra:</b>
              <>
                <br />
                <span>UTI ({formatarValor(obterUti().valor)})</span>
              </>
            </Typography>
          )}
          <br />
        </>
      )}

      {!pacote.dayClinic && !pacote.pqa && !!obterInternacao()?.id && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Internação
            <BtnAjustar
              onClick={() => props.handleAjusteEtapa(Steps.internacao)}
            />
          </Typography>
          <Typography variant="body2">
            {`${pacote.diariasInternacao + obterQtdDiariasIclusas() || '0'
              } diária(s) inclusa(s) do tipo internação`}
          </Typography>
          {(!!obterInternacao()?.valor ||
            !!obterInternacao()?.valorApartamento) &&
            perfilAtual === SynPerfis.OPERACIONAL && (
              <Typography variant="body2">
                <b>Valor de 1 diária extra:</b>
                {!!obterInternacao()?.valor && (
                  <>
                    <br />
                    <span>
                      Enfermaria ({formatarValor(obterInternacao().valor)})
                    </span>
                  </>
                )}
                {!!obterInternacao()?.valorApartamento && (
                  <>
                    <br />
                    <span>
                      Apartamento (
                      {formatarValor(obterInternacao().valorApartamento)})
                    </span>
                  </>
                )}
              </Typography>
            )}
          <br />
        </>
      )}

      {props.exibirDadosMedicos && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Honorário médico
            <BtnAjustar
              onClick={() => props.handleAjusteEtapa(Steps.honorarioMedico)}
            />
          </Typography>
          {pacote.semHonorarioMedico && (
            <Typography variant="body2">
              Solicitação sem honorário médico
            </Typography>
          )}
          {!pacote.semHonorarioMedico && (
            <>
              <Typography variant="body2">
                Honorário do médico :{' '}
                {formatarValor(obterValorHonorario(), forcarHonorarios)}
              </Typography>

              <Typography variant="body2">
                Honorário da equipe :{' '}
                {formatarValor(obterValorEquipeMedica(), forcarHonorarios)}
              </Typography>
            </>
          )}
          <Typography variant="body2">
            Média de tempo do procedimento:{' '}
            {pacote.mediaTempoCirurgico ? (
              pacote.mediaTempoCirurgico + 'h'
            ) : (
              <span className={props.erro ? 'danger' : null}>
                Não informado
              </span>
            )}
          </Typography>
          <br />
        </>
      )}

      {props.exibirDadosMedicos && (
        <>
          <Typography variant="subtitle2" component="h3" color="primary">
            Equipe médica
            <BtnAjustar
              onClick={() => props.handleAjusteEtapa(Steps.membrosEquipe)}
            />
          </Typography>
          <Typography variant="body2">
            {pacote?.membros?.length > 0
              ? !!pacote.membros?.length &&
              pacote.membros?.map(e => (
                <>
                  {`${e.descricao} (${formatarValor(
                    e.valor,
                    forcarHonorarios
                  )})`}
                  <br />
                </>
              ))
              : 'Equipe não foi configurada'}
          </Typography>
          <br />
        </>
      )}

      {!!pacote.reservaSangue && (
        <>
          <Typography variant="subtitle2" color="primary">
            Reserva de sangue
          </Typography>
          <Typography variant="body2">
            {pacote.reservaSangue} concentrados de hemácias
          </Typography>
          <br />
        </>
      )}
    </Box>
  )
}

export default ResumoPacoteInteligenteOps

interface IPropItem {
  opme: ItemOpmeInteligente
  i: number
  exibirValor?: boolean
  medico?: boolean
  esconderIncluso?: boolean
  adaptarValor?: boolean
  formatarValor?: (valor: number, forcaAdaptar?: boolean) => string | number
}

interface IPropItemGenerico {
  item: ItemMaterialInteligente
  i: number
  medico?: boolean
  exibirValor?: boolean
  esconderIncluso?: boolean
  adaptarValor?: boolean
  formatarValor?: (valor: number, forcaAdaptar?: boolean) => string | number
}

function ItemOpme({
  opme,
  i,
  exibirValor,
  medico,
  esconderIncluso,
  formatarValor
}: IPropItem) {
  return (
    <UBox>
      <b style={{ color: '#555' }}>{opme.descricao}</b>
      <Typography
        variant="body2"
        component="p"
      >
        {opme?.quantidadeIncluso > 0 && !esconderIncluso && (
          <b style={{ fontSize: '10px', display: 'block' }}>
            <i>{`${opme?.quantidadeIncluso} unid. incluso no pacote`}</i>
          </b>
        )}

        {opme?.quantidadeIncluso === 0 && opme.hospitalFornece ? (
          ''
        ) : (
          <b style={{ fontSize: '10px', display: 'block' }}>
            <i>{opme?.fornecedor?.descricao}</i>
          </b>
        )}
        {exibirValor &&
          (opme.podeAcrescentar ||
            opme.podeSubtrair ||
            (opme?.quantidadeIncluso === 0 && !opme.hospitalFornece)) && (
            <span
              style={{
                display: 'block',
                color: '#b39325'
              }}
            >
              <small>
                <b>Valor unitário de {formatarValor(opme?.valorUnitario)}</b>
              </small>
            </span>
          )}

        {!!opme.podeAcrescentar && !medico && (
          <Chip
            label={
              <UBox
                display="flex"
                alignItems="center"
                style={{ color: '#3a3', gap: '4px' }}
              >
                <FiPlus />
                <b>
                  <small>pode add</small>
                </b>
              </UBox>
            }
            size="small"
            style={{
              marginRight: '6px',
              marginTop: '2px',
              padding: '2px 4px'
            }}
          />
        )}

        {!!opme.podeSubtrair && !medico && (
          <Chip
            label={
              <UBox
                display="flex"
                alignItems="center"
                style={{ color: '#c33', gap: '4px' }}
              >
                <FiMinus />{' '}
                <b>
                  <small>pode subtrair</small>
                </b>
              </UBox>
            }
            size="small"
            style={{
              marginRight: '6px',
              marginTop: '2px',
              padding: '2px 4px'
            }}
          />
        )}
      </Typography>
    </UBox>
  )
}

function ItemGenerico({
  item,
  i,
  exibirValor,
  medico,
  esconderIncluso,
  formatarValor
}: IPropItemGenerico) {
  return (
    <UBox>
      {/* {JSON.stringify(opme)} */}
      <b style={{ color: '#555' }}>{item.descricao}</b>
      <Typography
        variant="body2"
        component="p"
      >
        {item?.quantidadeIncluso > 0 && !esconderIncluso && (
          <b style={{ fontSize: '10px', display: 'block' }}>
            <i>{`${item?.quantidadeIncluso} unid. incluso no pacote`}</i>
          </b>
        )}

        {exibirValor && (item.podeAcrescentar || item.podeSubtrair) && (
          <span
            style={{
              display: 'block',
              color: '#b39325'
            }}
          >
            <small>
              <b>Valor unitário de {formatarValor(item?.valorUnitario)}</b>
            </small>
          </span>
        )}

        {!!item.podeAcrescentar && !medico && (
          <Chip
            label={
              <UBox
                display="flex"
                alignItems="center"
                style={{ color: '#3a3', gap: '4px' }}
              >
                <FiPlus />
                <b>
                  <small>pode add</small>
                </b>
              </UBox>
            }
            size="small"
            style={{
              marginRight: '6px',
              marginTop: '2px',
              padding: '2px 4px'
            }}
          />
        )}

        {!!item.podeSubtrair && !medico && (
          <Chip
            label={
              <UBox
                display="flex"
                alignItems="center"
                style={{ color: '#c33', gap: '4px' }}
              >
                <FiMinus />{' '}
                <b>
                  <small>pode subtrair</small>
                </b>
              </UBox>
            }
            size="small"
            style={{
              marginRight: '6px',
              marginTop: '2px',
              padding: '2px 4px'
            }}
          />
        )}
      </Typography>
    </UBox>
  )
}
