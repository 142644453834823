export const StatusFinanciamento = {
  PENDENTE: { Id: 0, Descricao: 'Pendente' },
  LANCADA_NA_BV: { Id: 3, Descricao: 'Lançada na BV' },
  APROVADA: { Id: 1, Descricao: 'Pré-análise aprovada' },
  RECUSADA: { Id: 2, Descricao: 'Pré-análise recusada' },
  PROPOSTA_CADASTRADA: { Id: 4, Descricao: 'Analisando crédito(BV)' },
  CA_CREDITO_APROVADO: { Id: 5, Descricao: 'Crédito aprovado' },
  CR_CREDITO_REPROVADA: { Id: 6, Descricao: 'Crédito reprovado' },
  C_CANCELADA: { Id: 7, Descricao: 'Cancelada' },
  AP_ANALISE_PAGAMENTO: { Id: 8, Descricao: 'Analise de pagamento' },
  APR_ANALISE_PAGAMENTO_RECUSADA: {
    Id: 9,
    Descricao: 'Análise de pagamento recusada'
  },
  PL_PAGAMENTO_LIBERADO: { Id: 10, Descricao: 'Pagamento liberado' },
  PR_PAGAMENTO_REALIZADO: { Id: 11, Descricao: 'Pagamento realizado' },
  PE_PAGAMENTO_ERRO: { Id: 12, Descricao: 'Pagamento erro' }
}

export const StatusFinanciamentoArray = [
  // { itens: [], id: 0, ordem: 0, descricao: 'Pendente' },
  // { itens: [], id: 3, ordem: 1, descricao: 'Lançado na BV' },
  {
    itens: [],
    minimizado: false,
    id: 2,
    ordem: 1,
    descricao: 'Pré-análise recusada'
  },
  {
    itens: [],
    minimizado: false,
    id: 1,
    ordem: 2,
    descricao: 'Pré-análise aprovada'
  },
  {
    itens: [],
    minimizado: false,
    id: 4,
    ordem: 4,
    descricao: 'Analisando crédito(BV)'
  },
  {
    itens: [],
    minimizado: false,
    id: 5,
    ordem: 5,
    descricao: 'Crédito aprovado'
  },
  {
    itens: [],
    minimizado: false,
    id: 6,
    ordem: 6,
    descricao: 'Crédito reprovado'
  },
  { itens: [], minimizado: false, id: 7, ordem: 7, descricao: 'Cancelada' },
  {
    itens: [],
    minimizado: false,
    id: 8,
    ordem: 8,
    descricao: 'Análise de pagamento'
  },
  {
    itens: [],
    minimizado: false,
    id: 9,
    ordem: 9,
    descricao: 'Análise de pag. recusada'
  },
  {
    itens: [],
    minimizado: false,
    id: 10,
    ordem: 10,
    descricao: 'Pagamento liberado'
  },
  {
    itens: [],
    minimizado: false,
    id: 11,
    ordem: 11,
    descricao: 'Pagamento realizado'
  },
  {
    itens: [],
    minimizado: false,
    id: 12,
    ordem: 12,
    descricao: 'Pagamento erro'
  }
]
