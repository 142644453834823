/* Página não utilizada */
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormControl,
  InputLabel,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'src/components/ui/Button'
import TextField from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import PasswordField from 'src/components/ui/PasswordField'
import { onlyNumber, verificarCPF } from 'src/core/helpers/common'

const useStyles = makeStyles(theme => ({
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    minWidth: 250,
    marginTop: 15
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  noMargin: {
    margin: theme.spacing(0)
  }
}))

const schema = yup.object().shape({
  nome: yup.string().min(3).max(100).required().label('Nome'),
  email: yup.string().email().required().label('E-mail'),
  senha: yup.string().required('Senha obrigatória').min(6).max(50),
  cpf: yup
    .string()
    .test('cpf-teste', 'CPF inválido', value => {
      if (!value) return false

      return verificarCPF(onlyNumber(value))
    })
    .required()
    .label('CPF')
})

interface IUser {
  nome: string
  email: string
  senha: string
  cpf: string
  perfil: string
}

export default function AdicionarUsuario() {
  const classes = useStyles()

  const [loading, setLoading] = useState<boolean>(false)

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    handleSubmit
  } = useForm<IUser>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      nome: '',
      email: '',
      senha: '',
      cpf: '',
      perfil: ''
    }
  })
  const command = watch()

  useEffect(() => {
    register('perfil')
  }, [register])

  function handleSelect(e) {
    setValue('perfil', e.target.value)
  }

  const onSubmit = async (data: IUser) => {
    console.log(data)
  }

  return (
    <Container
      component="main"
      className="fadeInTransition syn-container"
      maxWidth="md"
    >
      <CssBaseline />
      <Grid item xs={12}>
        <Typography variant="h6" component="h2">
          Cadastro de usuário
        </Typography>
      </Grid>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
        noValidate={true}
      >
        <Grid container spacing={1} direction="row">
          <Grid item xs={12} md={12}>
            <TextField
              erro={errors}
              inputRef={register}
              name="nome"
              label="Nome completo"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              inputRef={register}
              erro={errors}
              name="email"
              label="E-mail"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              erro={errors}
              name="cpf"
              placeholder="Ex: 999.999.999-99"
              label="CPF"
              hiddenLabel
              control={control}
              mask="999.999.999-99"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PasswordField
              inputRef={register}
              erro={errors}
              name="senha"
              label="Senha"
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>
                Perfil
              </InputLabel>
              <Select
                value={command.perfil}
                label="Perfil"
                onChange={handleSelect}
              >
                <MenuItem value={10}>Perfil 1</MenuItem>
                <MenuItem value={20}>Perfil 2</MenuItem>
                <MenuItem value={30}>Perfil 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justify="flex-end" style={{ marginTop: '24px' }}>
          <Grid item xs={12} sm={4}>
            <Button
              loading={loading}
              disabled={loading}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}
