import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import UBox from 'src/components/ui/Ubox'
import { ItemMaterialInteligente } from '../FormPacoteInteligente'
import { yupResolver } from '@hookform/resolvers/yup'
import yup from 'src/components/yupCustom'
import TextField from 'src/components/ui/TextField'
import CurrencyInput from 'src/components/ui/CurrencyInput'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import { ObjetoDescricaoGenerico } from 'src/types'
import { useForm } from 'react-hook-form'
import Button from 'src/components/ui/Button'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { useDebouncedCallback } from 'use-debounce/lib'

const useStyles = makeStyles(theme => ({
  formControl2: {
    top: '16px'
  }
}))

interface IProps {
  materiais?: ItemMaterialInteligente[]
  incluso?: boolean
  handleChange?: (item: ItemMaterialInteligente[], valido: boolean) => void
}
function TabelaMaterial({
  materiais = [],
  handleChange,
  incluso = false
}: IProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const [listaMaterial, setListaMaterial] = useState<ItemMaterialInteligente[]>(
    materiais
  )
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  function addOpme() {
    var novo: ItemMaterialInteligente = {
      descricao: '',
      valorUnitario: 0,
      quantidadeIncluso: 0,
      podeSubtrair: false,
      podeAcrescentar: false,
      quantidade: 0
    }
    setListaMaterial([...listaMaterial, novo])
  }

  function removerOpme(key: number) {
    var novaLista = listaMaterial.filter((o, i) => i !== key)

    atualizarPai(novaLista)
  }

  function atualizarItem(
    key: number,
    value: ItemMaterialInteligente,
    valido: boolean
  ) {
    value['valido'] = valido
    listaMaterial[key] = value

    atualizarPai(listaMaterial)
  }

  function atualizarPai(lista) {
    var formInvalido = lista.find(i => !i.valido)
    setListaMaterial(lista)
    handleChange(lista, !!formInvalido)
  }

  return (
    <UBox width="100%">
      {/* {JSON.stringify(listaOpme)} */}
      {!loading &&
        listaMaterial.map((material, i) => (
          <UBox
            // style={{
            //   background: !!material.valido ? 'none' : '#fee',
            //   borderRadius: '4px'
            // }}
            key={listaMaterial.length + '-' + i}
            px={ehXS ? 0 : 2}
          >
            <ItemMaterial
              incluso={incluso}
              material={material}
              index={i}
              atualizar={atualizarItem}
              key={listaMaterial.length + '-' + i}
              remover={removerOpme}
            />
          </UBox>
        ))}
      <Button
        variant="text"
        color="primary"
        onClick={() => addOpme()}
        startIcon={<FiPlus style={{ color: '#4fd0b9' }} />}
      >
        Adicionar material
      </Button>
    </UBox>
  )
}

export default TabelaMaterial

interface PropItemMaterial {
  index: number
  material: ItemMaterialInteligente
  incluso?: boolean
  remover: (key: number) => void
  atualizar: (
    key: number,
    value: ItemMaterialInteligente,
    valido: boolean
  ) => void
}

const schema = yup.object().shape({
  descricao: yup.string().required().label('Descricao'),
  valorUnitario: yup.string().required().label('Valor unitario'),
  quantidadeIncluso: yup.string().required().label('Quantidade incluso')
})

let validando = false
function ItemMaterial({
  material,
  index,
  incluso,
  atualizar,
  remover
}: PropItemMaterial) {
  const styles = useStyles()
  let defaultValues: ItemMaterialInteligente = {
    descricao: '', //M- médico | H - hospital
    valorUnitario: 0,
    podeAcrescentar: false,
    podeSubtrair: false,
    quantidadeIncluso: 0
  }
  if (material !== null) {
    defaultValues = material
  }
  const [debouncedTermoPesquisa, setDebouncedTermoPesquisa] = useState(material)

  const debounced = useDebouncedCallback(() => {
    setDebouncedTermoPesquisa(command)
    return validar()
  }, 0)

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    formState,
    handleSubmit,
    trigger
  } = useForm<ItemMaterialInteligente>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  const command = watch()

  // REGISTRA VALORES REACT FORM HOOKS
  useEffect(() => {
    register('id')
    register('valorUnitario')
    register('quantidadeIncluso')
    register('podeAcrescentar')
    register('podeSubtrair')
  }, [register])

  useEffect(() => {
    // if (!validando) {
    //   validar()
    // }
    // validando = true
    // setTimeout(() => {
    //   validando = false
    // }, 200)
    debounced.callback()
  }, [command])

  async function validar() {
    var valido = await trigger(['descricao'])
    atualizar(index, command, valido)
  }

  function handleChangeValor(
    key: keyof ItemMaterialInteligente,
    valor: number
  ) {
    let valorFormatado = valor / 100
    if (!valorFormatado || valorFormatado < 0) {
      valor = 0
    }

    // return valorFormatado
    setValue(key, valor, { shouldValidate: true })
  }

  const handleItemValue = (name: keyof ItemMaterialInteligente, item) => {
    //console.log(name, item)
    setValue(name, item, {
      shouldValidate: true
    })
  }

  const verificarForm = () => {
    var vazio = {}
    var resultado = JSON.stringify(errors) === JSON.stringify(vazio)
    return resultado
  }

  return (
    <UBox
      key={index}
      style={{
        background: !!verificarForm() ? 'none' : '#fee',
        borderRadius: '4px'
      }}
    >
      {/* o:{JSON.stringify(opme)} */}
      {/* {index}
      <br />
      c:{JSON.stringify(command)}
      <br />
    e:{JSON.stringify(errors)} */}
      {/* c:{JSON.stringify(command)} */}
      <Grid container spacing={1} direction="row">
        <Grid item xs={12} sm={7}>
          <TextField
            erro={errors}
            inputRef={register}
            autoFocus
            size="small"
            name="descricao"
            label="Descrição"
            inputProps={{
              maxLength: 500
            }}
          />
        </Grid>

        <UBox component={Grid} item xs={12} sm={3} render={incluso}>
          <FormControl
            className={styles.formControl2}
            fullWidth
            size="medium"
            variant="outlined"
          >
            <CurrencyInput
              fullWidth
              size="small"
              noMoney
              erro={errors}
              //   autoFocus
              variant="outlined"
              // margin="normal"
              name="quantidade"
              label="Qtd. incluso"
              hiddenLabel
              control={control}
              type="tel"
              InputLabelProps={{
                shrink: true
              }}
              // InputProps={{
              //   endAdornment: <>%</>
              // }}
              // max={100000}
              onValueChange={value =>
                handleChangeValor('quantidadeIncluso', value)
              }
              value={command?.quantidadeIncluso || 0}
            />
            <FormHelperText error={!!errors?.quantidadeIncluso}>
              {errors?.quantidadeIncluso?.message}
            </FormHelperText>
          </FormControl>
        </UBox>
        <Grid
          item
          xs={12}
          sm={1}
          style={{ display: 'flex', alignItems: 'center', padding: 0 }}
        >
          <IconButton
            className="danger"
            title="Remover OPME"
            onClick={() => remover(index)}
          >
            <FiTrash />
          </IconButton>
        </Grid>

        <UBox
          component={Grid}
          item
          xs={12}
          sm={7}
          style={{ display: 'flex', alignItems: 'center' }}
          render={incluso}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  //   disabled={submitting || !!dadosBancarioId}
                  checked={command.podeAcrescentar}
                  onChange={(e, value) =>
                    handleItemValue('podeAcrescentar', value)
                  }
                  value={command.podeAcrescentar}
                />
              }
              label="pode add"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  //   disabled={submitting || !!dadosBancarioId}
                  checked={command.podeSubtrair}
                  onChange={(e, value) =>
                    handleItemValue('podeSubtrair', value)
                  }
                  value={command.podeSubtrair}
                />
              }
              label="pode subtrair"
            />
          </FormGroup>
        </UBox>

        <UBox
          component={Grid}
          item
          xs={12}
          sm={4}
          render={
            incluso && (command?.podeAcrescentar || command?.podeSubtrair)
          }
        >
          <FormControl
            // className={styles.formControl2}
            fullWidth
            size="medium"
            variant="outlined"
          >
            <CurrencyInput
              fullWidth
              size="small"
              erro={errors}
              //   autoFocus
              variant="outlined"
              // margin="normal"
              name="valorUnitario"
              label="Valor un."
              hiddenLabel
              control={control}
              type="tel"
              InputLabelProps={{
                shrink: true
              }}
              // InputProps={{
              //   endAdornment: <>%</>
              // }}
              // max={100000}
              onValueChange={value => handleChangeValor('valorUnitario', value)}
              value={command?.valorUnitario || 0}
            />
            <FormHelperText error={!!errors?.valorUnitario}>
              {errors?.valorUnitario?.message}
            </FormHelperText>
          </FormControl>
        </UBox>
      </Grid>
      <UBox my={1}>
        <Divider />
      </UBox>
    </UBox>
  )
}
