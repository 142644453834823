import { AxiosResponse } from 'axios'
import api from 'src/services/api'
import {
  AddProcedimentoCommand,
  EspecialidadeVM,
  IExame,
  ListagemQuery,
  ListagemResponse,
  ObjetoDescricaoGenerico,
  PacienteQuery
} from 'src/types'

export const obterEspecialidades: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/especialidade')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterParceiroComercialSyn: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/parceiroComercialSyn')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterSetores: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/setoresLocalAtendimento')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterLocaisAtendimento: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/localAtendimento')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterTenants: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/usuario/interno/tenants')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterSetoresInternos: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/usuario/interno/perfis')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterLocaisAtendimentoPorUfMedico: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/localAtendimento/porUf')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterLocaisAtendimentoPorUfMedicoComId: (
  id: string
) => Promise<AxiosResponse<ObjetoDescricaoGenerico[]>> = id => {
  try {
    return api.get(`/localAtendimento/porUf?idMedico=${id}`)
  } catch (err) {
    console.log('@ERROR:', err)
  }
}

export const adicionarLocalAtendimento: (
  descricao: string
) => Promise<AxiosResponse<ObjetoDescricaoGenerico[]>> = descricao => {
  try {
    const body = { descricao }
    return api.post('/localAtendimento', body)
  } catch (err) {
    // ;+arguments.callee.name
    console.log('@ERROR: ', err)
  }
}

export const obterExames: () => Promise<AxiosResponse<IExame[]>> = () => {
  try {
    return api.get('/exame')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const adicionarExame: (
  descricao: string
) => Promise<AxiosResponse<ObjetoDescricaoGenerico[]>> = descricao => {
  try {
    const body = { descricao }
    return api.post('/exame', body)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterTiposAnestesia: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/tipoAnestesia')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const adicionarTipoAnestesia: (
  descricao: string
) => Promise<AxiosResponse<ObjetoDescricaoGenerico[]>> = descricao => {
  try {
    const body = { descricao }
    return api.post('/tipoAnestesia', body)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterEquipamentos: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/equipamento')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterMateriais: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/material')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const adicionarEquipamento: (
  descricao: string
) => Promise<AxiosResponse<ObjetoDescricaoGenerico[]>> = descricao => {
  try {
    const body = { descricao }
    return api.post('/equipamento', body)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const adicionarMaterial: (
  descricao: string
) => Promise<AxiosResponse<ObjetoDescricaoGenerico[]>> = descricao => {
  try {
    const body = { descricao }
    return api.post('/material', body)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterListaOPME: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/opme')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const adicionarOPME: (
  descricao: string
) => Promise<AxiosResponse<ObjetoDescricaoGenerico[]>> = descricao => {
  try {
    const body = { descricao }
    return api.post('/OPME', body)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterProcedimentosPorMedico: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/procedimento/porMedico')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterProcedimentos: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/procedimento')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const listagemProcedimentos: (
  query: ListagemQuery
) => Promise<
  AxiosResponse<ListagemResponse<ObjetoDescricaoGenerico>>
> = query => {
  try {
    return api.get(`/procedimento/listagem?${new URLSearchParams(query)}`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const adicionarProcedimento: (
  params: AddProcedimentoCommand
) => Promise<AxiosResponse<ObjetoDescricaoGenerico[]>> = command => {
  try {
    return api.post('/Procedimento', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterPacientePorCPF: (
  cpf: string
) => Promise<AxiosResponse<PacienteQuery>> = cpf => {
  try {
    return api.get('/Paciente/PorCpf?cpf=' + cpf)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterComorbidades: () => Promise<AxiosResponse<any[]>> = () => {
  try {
    return api.get('/comorbidade')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const adicionarComorbidade: (
  descricao: string
) => Promise<AxiosResponse<ObjetoDescricaoGenerico[]>> = descricao => {
  try {
    return api.post('/comorbidade', { descricao })
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

/* Verificação CPF e CNPJ */
export const existeCPF: (
  cpf: string,
  usuarioId: string
) => Promise<AxiosResponse<boolean>> = (cpf, usuarioId) => {
  try {
    return api.get(
      `/auth/verificarExisteCpf/${cpf}?usuarioId=${!usuarioId ? '' : usuarioId}`
    )
  } catch (err) {
    console.log('@ERROR:', err)
  }
}

export const existeCNPJ: (
  cnpj: string,
  parceiroId: string
) => Promise<AxiosResponse<boolean>> = (cnpj, parceiroId) => {
  try {
    return api.get(
      `/parceiro/verificarExisteCnpj/${cnpj}?parceiroId=${!parceiroId ? '' : parceiroId
      }`
    )
  } catch (err) {
    console.log('@ERROR:', err)
  }
}
