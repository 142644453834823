import { CardHeader, Card, Box } from '@material-ui/core'
import { styled as styledMUI } from '@material-ui/styles'

export const CardContainer = styledMUI(Card)(() => ({
  display: 'flex',
  height: '112px',
  cursor: 'pointer',
  padding: '6px',
  position: 'relative',
  textAlign: 'center',

  '& .title': {
    display: 'flex',
    width: '100%',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '1.3rem'
  },

  '& .msg': {
    display: 'none',
    position: 'absolute',
    bottom: 8,
    width: '100%',
    left: 0,
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '100%',
    color: '#a6a6a6',

    'font-weight': 500
  },

  '&:hover': {
    backgroundColor: '#E8F6FF',
    border: '1px solid #1cc3a5',
    boxShadow: '0 4px 8px rgba(0,0,0,0.08)',

    '& .title': {
      color: '#10cba5'
    },

    '& .msg': {
      display: 'block'
    }
  }
}))

export const CardHeaderContainer = styledMUI(CardHeader)(() => ({
  display: 'flex',
  width: '100%'
}))

export const BoxContent = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}))
