import React, { useEffect, useState } from 'react'
import {
  Box,
  Divider,
  Typography,
  makeStyles,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  Menu,
  MenuItem,
  Grid,
  useTheme,
  useMediaQuery,
  AppBar,
  Toolbar,
  DialogActions,
  TextField
} from '@material-ui/core'

import Tooltip from '@material-ui/core/Tooltip'
import Alert from '@material-ui/lab/Alert'
import {
  IOPMEResponse,
  ItemExameLaboratorioResponse,
  IOrcamentoLocal
} from 'src/types'
import CloseIcon from '@material-ui/icons/Close'
import Button from 'src/components/ui/Button'
import formatarDinheiro from '../../core/formatacoes/formatarDinheiro'
import calcularValorProposta from '../../core/utils/calcularValorProposta'
import { calcularTaxaSyn, enviarProposta } from '../../api/proposta'
import Swal from 'sweetalert2'
import { SynRoutes } from '../../core/consts/synRoutes'
import { useHistory } from 'react-router-dom'
import { showNotificationErrorAPI } from '../../core/helpers/notification'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { ModalAddGenerico } from 'src/components/modals/ModalAddGenerico'
import ModalGenerico from 'src/components/modals/ModalGenerico'
import { ModalEditarTaxa } from '../propostas/modals/ModalEditarTaxa'
import UBox from '../ui/Ubox'
import { Close } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  modalCard: {
    backgroundColor: theme.palette.grey[50],
    marginBottom: '20px',
    border: '1px solid #efefef'
  },
  hr: {
    margin: '3em 0'
  },
  botao: {
    color: 'white',
    backgroundColor: '#59f0d5'
  }
}))

interface Props {
  open: boolean
  descricaoExame: string
  onClose: () => void
  onFuncao: (observacao, contempa) => void
}

export default function ModalSubmitPropostaHospital({
  open,
  descricaoExame,
  onClose,
  onFuncao
}: Props) {
  const history = useHistory()
  const styles = useStyles()

  const [loading, setLoading] = useState(false)

  const theme = useTheme()
  const [qtdCaracteres] = useState(300)
  const [observacao, setObservacao] = useState('')
  const [loadingSalvar, setLoadingSalvar] = useState(false)
  const [contempla, setContempla] = useState(false)
  const [qtdObservacao, setQtdObservacao] = useState(qtdCaracteres)

  useEffect(() => {
    setContempla(false)
  }, [open])

  function onContempla() {
    setContempla(true)
  }

  function validacao(valor) {
    if (observacao.length === 0 && valor) {
      Swal.fire('Ops...', 'A observação é obrigatória', 'warning')
      return
    }
    if (!valor) {
      onFuncao(observacao, true)
    } else {
      onFuncao(observacao, false)
    }
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      {/* <DialogTitle id="form-dialog-title"></DialogTitle> */}
      <DialogContent>
        <Box mb={2} mt={1}>
          <Typography variant="h5">Revisar exames</Typography>
        </Box>
        <Box mb={2} mt={1}>
          <Typography>
            O orçamento contempla os exames durante a internação abaixo,
            solicitados pelo médico?
          </Typography>
        </Box>
        <Box p={1} mb={2}>
          <Typography variant="body2">
            <b>{descricaoExame}</b>
          </Typography>
        </Box>
        <DialogActions style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Grid container spacing={1}>
            <Grid item sm={4}>
              <Button onClick={onClose} variant="contained" color="default">
                Revisar valores
              </Button>
            </Grid>{' '}
            <Grid item sm={4}></Grid>{' '}
            <Grid item sm={2}>
              <Button
                onClick={onContempla}
                color="default"
                size="medium"
                fullWidth={true}
              >
                Não
              </Button>
            </Grid>{' '}
            <Grid item sm={2}>
              <Button
                onClick={() => validacao(false)}
                size="medium"
                fullWidth={true}
                variant="contained"
                color="primary"
              >
                Sim
              </Button>
            </Grid>
          </Grid>
        </DialogActions>

        <UBox render={contempla} mb={2}>
          <Box mt={5}>
            <TextField
              fullWidth
              required
              label="Enviar observação"
              multiline
              rows={6}
              placeholder="Digite alguma mensagem..."
              variant="outlined"
              value={observacao}
              onChange={e => {
                setObservacao(e.target.value)
                setQtdObservacao(qtdCaracteres - e.target.value.length)
              }}
              inputProps={{ maxLength: qtdCaracteres }}
            />
            {qtdObservacao <= 10 ? (
              <Typography variant="subtitle2" component="h3" color="error">
                {qtdObservacao} caracteres restantes
              </Typography>
            ) : (
              <Typography variant="subtitle2" component="h3" color="primary">
                {qtdObservacao} caracteres restantes
              </Typography>
            )}
          </Box>
          <Box mt={2} alignItems={'center'}>
            <Button
              loading={loading}
              disabled={loading}
              onClick={() => validacao(true)}
              color="primary"
              fullWidth
            >
              Enviar
            </Button>
          </Box>
        </UBox>
      </DialogContent>
    </Dialog>
  )
}
