import {
  CircularProgress,
  TextField as TextFieldMU,
  TextFieldProps
} from '@material-ui/core'
import React from 'react'

import { Control, Controller } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { MaskTelCel } from 'src/core/utils/masks'
import UBox from './Ubox'

export type TextFieldPropCustom = TextFieldProps & {
  erro?: any
  mask?: string
  control?: Control<any>
  showCaracteres?: number
  caracteres?: number
  maskTelCel?: boolean
  maskPlaceholder?: string
  loading?: boolean
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  errorMessage?: string
}

export const TextFieldSimpleCustom: React.FC<TextFieldPropCustom> = ({
  erro,
  caracteres = 0,
  ...rest
}) => {
  return (
    <TextFieldMU
      error={
        rest.errorMessage
          ? !!rest.errorMessage
          : erro?.opme
            ? !!erro?.opme
            : !!erro[rest.name]
      }
      helperText={
        rest.errorMessage
          ? rest.errorMessage
          : erro?.opme
            ? erro.opme?.message
            : erro[rest.name]?.message
      }
      variant="outlined"
      margin="normal"
      fullWidth
      inputProps={{
        form: {
          autoComplete: 'new-password'
        }
      }}
      InputProps={{
        autoComplete: 'off',
        endAdornment: (
          <React.Fragment>
            {rest.loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : null}
            {!!rest?.showCaracteres && (
              <UBox
                position="absolute"
                style={{ right: 0, bottom: '-20px', fontSize: '12px' }}
              >
                {`${caracteres}/${rest.showCaracteres}`}
              </UBox>
            )}
          </React.Fragment>
        ),
        ...rest.InputProps
      }}
      {...rest}
    />
  )
}

export const TextField: React.FC<TextFieldPropCustom> = ({
  erro,
  mask,
  maskTelCel,
  maskPlaceholder,
  control,
  disabled,
  value,
  onChange,
  onBlur,
  onFocus,
  ...rest
}) => {
  // const inputMaskRef = React.createRef()
  // const inputRef = React

  const maskCurrent = () => {
    if (!!maskTelCel && !!control) {
      return MaskTelCel(control.getValues()[rest.name])
    }

    return mask
  }

  const InputMasked = function () {
    return rest.multiline ? (
      <TextFieldSimpleCustom
        disabled={disabled}
        caracteres={!!value && typeof value === 'string' ? value?.length : 0}
        erro={erro}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        {...rest}
      />
    ) : (
      <InputMask
        disabled={disabled}
        maskplaceholder={maskPlaceholder}
        mask={maskCurrent()}
        maskChar=" "
        value={value}
        valueDefault={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {() => (
          <TextFieldSimpleCustom
            disabled={disabled}
            erro={erro}
            {...rest}
            defaultValue={value}
          />
        )}
      </InputMask>
    )
  }

  return control ? (
    <Controller
      name={rest.name}
      defaultValue={rest.defaultValue}
      as={InputMasked()}
      control={control}
    />
  ) : (
    InputMasked()
  )
}

export default TextField
