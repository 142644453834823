import { AxiosResponse } from 'axios'
import api from 'src/services/api'

export function verificarAmbiente(): Promise<AxiosResponse> {
  try {
    return api.get('/home/ambiente')
  } catch (err) {
    console.log('@Error: ', err)
  }
}
