import React, { useState, useEffect } from 'react'
import ModalGenerico from './ModalGenerico'
import {
  Box,
  Typography,
  IconButton,
  Chip,
  TextField,
  Divider,
  makeStyles,
  CircularProgress,
  Grid
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import {
  cadastrarTag,
  deleteTag,
  incluirTag,
  listarTagsPorSolicitacao,
  listarTodasTags,
  removerTag
} from 'src/api/solicitacao-orcamento'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import yup from 'src/components/yupCustom'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import Button from 'src/components/ui/Button'
import { TagResponse } from 'src/types'
import Swal from 'sweetalert2'

const useStyles = makeStyles({
  divider: {
    marginBottom: '10px'
  },
  tag: {
    margin: '0 5px 5px 0'
  }
})

const schema = yup.object().shape({
  nomeTag: yup.string().required().label('Nome')
})

interface Props {
  open: boolean
  onClose: () => void
  solicitacaoId: string
  loadAllTags?: () => void
}

export default function ModalAddTag({
  open,
  onClose,
  solicitacaoId,
  loadAllTags
}: Props) {
  const [criarTag, setCriarTag] = useState(false)
  const [loadingCriarTag, setLoadingCriarTag] = useState(false)

  const [tagsSolicitacao, setTagsSolicitacao] = useState<TagResponse[]>()
  const [todasAsTags, setTodasAsTags] = useState<TagResponse[]>()

  const [loading, setLoading] = useState(false)
  const [termoBusca, setTermoBusca] = useState('')

  const styles = useStyles()

  async function loadTags() {
    setLoading(true)

    try {
      const responseTagsSolicitacao = await listarTagsPorSolicitacao(
        solicitacaoId
      )
      setTagsSolicitacao(responseTagsSolicitacao.data)

      const responseTodasTags = await listarTodasTags()
      setTodasAsTags(responseTodasTags.data)
    } catch (err) {
      showNotificationErrorAPI(err)
    }

    setLoading(false)
  }

  useEffect(() => {
    loadTags()
  }, [open])

  const { register, trigger, errors, watch, setValue, reset } = useForm<{
    nomeTag: string
  }>({
    resolver: yupResolver(schema)
  })

  const command = watch()

  useEffect(() => {
    if (termoBusca !== '') {
      const newTags = todasAsTags.filter(t =>
        t.descricao?.toLowerCase().includes(termoBusca.toLowerCase())
      )
      setTodasAsTags(newTags)

      const newSolicitacaoTags = tagsSolicitacao.filter(t =>
        t.descricao?.toLowerCase().includes(termoBusca?.toLowerCase())
      )
      setTagsSolicitacao(newSolicitacaoTags)
    } else {
      loadTags()
    }
  }, [termoBusca])

  async function createTag() {
    /* eslint-disable no-useless-return */
    const result = await trigger()
    if (!result) return

    setLoadingCriarTag(true)

    try {
      await cadastrarTag(command.nomeTag)

      loadAllTags()
      reset()
      setCriarTag(false)
      loadTags()
    } catch (err) {
      showNotificationErrorAPI(err)
    }

    setLoadingCriarTag(false)
  }

  async function inserirTag(tagId: string) {
    try {
      const body = {
        tagId,
        solicitacaoOrcamentoId: solicitacaoId
      }

      await incluirTag(body)

      reset()

      loadTags()
    } catch (err) {
      showNotificationErrorAPI(err)
    }
  }

  async function removeTag(tagId: string) {
    try {
      const body = {
        tagId,
        solicitacaoOrcamentoId: solicitacaoId
      }

      await removerTag(body)

      loadTags()
    } catch (err) {
      showNotificationErrorAPI(err)
    }
  }

  async function excluirTag(tagId: string) {
    try {
      await Swal.fire({
        title: 'Atenção!',
        text: 'Excluir essa tag irá removê-la de todas as solicitações',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Excluir'
      }).then(async result => {
        if (result.isConfirmed) {
          await deleteTag(tagId)
        }
      })

      loadTags()
    } catch (err) {
      showNotificationErrorAPI(err)
    }
  }

  return (
    <ModalGenerico open={open} maxWidth="sm" fullWidth={true} onClose={onClose}>
      <Box p={2}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="h6">Adicionar Tags</Typography>

          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid
          container
          alignItems="flex-end"
          style={{
            marginBottom: '30px'
          }}
          spacing={1}
        >
          <Grid item xs={12} sm={8}>
            <TextField
              label="Buscar tag"
              value={termoBusca}
              fullWidth
              onChange={e => {
                setTermoBusca(e.target.value)
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Button
              style={{ padding: '3px' }}
              variant="outlined"
              color="primary"
              onClick={() => setCriarTag(true)}
              fullWidth
            >
              <AddIcon />
              Criar
            </Button>
          </Grid>
        </Grid>

        {loading ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress />
            <div>Carregando...</div>
          </div>
        ) : (
          <>
            <Box>
              <Typography variant="subtitle2">Tags ativas</Typography>
              <Divider className={styles.divider} />
            </Box>

            <Box>
              {tagsSolicitacao?.length === 0 ? (
                <Typography variant="body1">
                  {termoBusca !== ''
                    ? 'Tag não encontrada'
                    : ' A solicitação não possui tags'}
                </Typography>
              ) : (
                tagsSolicitacao?.map(item => {
                  return (
                    <Chip
                      key={item?.id}
                      className={styles.tag}
                      size="small"
                      label={item?.descricao}
                      style={{
                        margin: '0 5px 5px 0',
                        color: item.id
                          ?.toLowerCase()
                          .includes('a0c7a7c1-87a4-4e21-bce9-e06dec247c59')
                          ? '#fff'
                          : '',
                        background: item.id
                          ?.toLowerCase()
                          .includes('a0c7a7c1-87a4-4e21-bce9-e06dec247c59')
                          ? '#e84141'
                          : ''
                      }}
                      onDelete={() => removeTag(item.id)}
                    />
                  )
                })
              )}
            </Box>

            <Box my={2}>
              <Typography variant="subtitle2">
                Todas as tags - clique para incluir
              </Typography>
              <Divider className={styles.divider} />
            </Box>

            <Box>
              {todasAsTags?.length === 0 ? (
                <Typography variant="body2">
                  {termoBusca !== '' ? (
                    'Tag não encontrada'
                  ) : (
                    <>
                      Nenhuma tag cadastrada. <br />
                      Clique no botão acima para cadastrar uma tag
                    </>
                  )}
                </Typography>
              ) : (
                todasAsTags?.map(item => {
                  return (
                    <Chip
                      key={item?.id}
                      className={styles.tag}
                      size="small"
                      label={item?.descricao}
                      style={{
                        margin: '0 5px 5px 0',
                        color: item.id
                          ?.toLowerCase()
                          .includes('a0c7a7c1-87a4-4e21-bce9-e06dec247c59')
                          ? '#fff'
                          : '',
                        background: item.id
                          ?.toLowerCase()
                          .includes('a0c7a7c1-87a4-4e21-bce9-e06dec247c59')
                          ? '#e84141'
                          : ''
                      }}
                      onDelete={() => excluirTag(item?.id)}
                      onClick={() => inserirTag(item?.id)}
                    />
                  )
                })
              )}
            </Box>
          </>
        )}

        <ModalGenerico open={criarTag}>
          <Box p={2}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '20px'
              }}
            >
              <Typography variant="h6">Criar Tag</Typography>

              <IconButton
                onClick={() => {
                  setCriarTag(false)
                  reset()
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Box>
              <TextField
                label="Nome da tag"
                variant="outlined"
                name="nomeTag"
                error={!!errors?.nomeTag}
                helperText={errors?.nomeTag?.message}
                value={command.nomeTag}
                inputRef={register}
                onChange={e => {
                  setValue('nomeTag', e.target.value)
                }}
              />
            </Box>

            <Box mt={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => createTag()}
                loading={loadingCriarTag}
                disabled={loadingCriarTag}
              >
                Criar
              </Button>
            </Box>
          </Box>
        </ModalGenerico>
      </Box>
    </ModalGenerico>
  )
}
