import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import UBox from 'src/components/ui/Ubox'
import './style.css'

// import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
// import { Keyboard, Navigation, Pagination } from 'swiper'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/swiper-bundle.css'
import 'swiper/swiper.min.css'
import {
  DashboardResumoResponse,
  obterResumoDashboard
} from 'src/api/dashboard'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import 'react-vis/dist/style.css'

import Bar from './Bar'
// import GraficoSolicitacoes from './graficoSolicitacoes'

function DashboardOperacional() {
  const styles = useStyles()
  const [resumo, setResumo] = useState<DashboardResumoResponse>()
  // const chartRef = useRef();

  async function buscarResumo() {
    const { data } = await obterResumoDashboard()

    if (data) {
      setResumo(data)
    }
  }

  useEffect(() => {
    buscarResumo()
  }, [])

  if (!resumo) {
    return <LoadingGeral />
  }

  return (
    <UBox pb={4}>
      <UBox mb={2}>
        <Typography variant="h5">Dashboard</Typography>
      </UBox>
      <UBox>
        {/* <UBox height={600}>
          <GraficoSolicitacoes />
        </UBox> */}
        <UBox className={styles.swiper}>
          <Typography variant="body2">
            <b>Solicitações de orçamento</b>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <ItemInfo
                titulo="Total de solicitações"
                info={resumo.qtdSolicitacoes}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ItemInfo
                titulo="Qtd. solicitações não convertidas"
                info={resumo.qtdSolicitacoesNaoConvertidas}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ItemInfo
                titulo="Qtd. solicitações convertidas"
                info={resumo.qtdSolicitacoesConvertidas}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                <b>
                  Qtd. solicitações de orçamentos por mês comparado ao ano
                  anterior
                </b>
              </Typography>
              <Bar
                infos={resumo.solicitacoesPorMesAnoAnterior}
                infos2={resumo.solicitacoesPorMes}
                vertical
                showLegends
                width={900}
                height={400}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                <b>Status das solicitações de orçamento</b>
              </Typography>
              <Bar infos={resumo.solicitacoesStatus} width={800} height={400} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                <b>Solicitações mais pedidas</b>
              </Typography>
              <Bar
                infos={resumo.solicitacoesMaisPedidas}
                width={800}
                height={1000}
                convertido
              />
            </Grid>
          </Grid>

          <UBox mt={2}>
            <Typography variant="body2">
              <b>Propostas</b>
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <ItemInfo
                  titulo="Qtd. de propostas geradas"
                  info={resumo.qtdPropostas}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ItemInfo
                  titulo="Valor total de propostas geradas"
                  info={formatarDinheiro(resumo.valorTotalPropostasEnviadas)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ItemInfo
                  titulo="Valor total convertido"
                  info={formatarDinheiro(resumo.valorTotalPropostasRealizadas)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ItemInfo
                  titulo="Valor médico convertido"
                  info={formatarDinheiro(resumo.valorTotalMedicoRealizado)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ItemInfo
                  titulo="Valor hospital convertido"
                  info={formatarDinheiro(resumo.valorTotalHospitalRealizado)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ItemInfo
                  titulo="Valor fornecedores OPME convertido"
                  info={formatarDinheiro(resumo.valorTotalOpmeRealizado)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ItemInfo
                  titulo="Valor Syn convertido"
                  info={formatarDinheiro(resumo.valorTotalComissaoSyn)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>Qtd de propostas por status</b>
                </Typography>
                <Bar infos={resumo.propostasStatus} width={800} height={400} />
              </Grid>
            </Grid>
          </UBox>
          <UBox mt={2}>
            <Typography variant="body2">
              <b>Hospitais</b>
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <ItemInfo
                  titulo="Orçamentos entregues por hospitais"
                  info={resumo.qtdOrcamentosHospitais}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ItemInfo
                  titulo="Valor total de orçamentos gerados por hospital"
                  info={formatarDinheiro(resumo.valorTotalOrcamentosHospitais)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ItemInfo
                  titulo="Valor total convertido"
                  info={formatarDinheiro(resumo.valorTotalHospitalRealizado)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>Orçamentos realizados pelos hospitais</b>
                </Typography>
                <Bar
                  infos={resumo.orcamentosPorHospitais}
                  width={800}
                  height={800}
                  convertido
                />

                {/* <RadialChart
                  className={'donut-chart-example'}
                  innerRadius={100}
                  radius={140}
                  getAngle={d => d.theta}
                  data={[
                    { theta: 2, className: 'custom-class' },
                    { theta: 6 },
                    { theta: 2 },
                    { theta: 3 },
                    { theta: 1 }
                  ]}
                  onValueMouseOver={v => this.setState({ value: v })}
                  onSeriesMouseOut={v => this.setState({ value: false })}
                  width={300}
                  height={300}
                  padAngle={0.04}
                >
                  {23 !== false && <Hint value={2323} />}
                </RadialChart> */}
              </Grid>
              {/* <Grid item xs={3}>
                <ItemInfo
                  titulo="Orçamentos entregues por fornecedores OPME"
                  info={resumo.qtdOrcamentosOpme}
                />
              </Grid> */}
            </Grid>
          </UBox>

          <UBox mt={2}>
            <Typography variant="body2">
              <b>Fornecedores OPMES</b>
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <ItemInfo titulo="Qtd. de OPME's" info={resumo.qtdItensOpme} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ItemInfo
                  titulo="Orçamentos recebidos"
                  info={resumo.qtdOrcamentosOpme}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ItemInfo
                  titulo="Valor total de orçamentos recebidos"
                  info={formatarDinheiro(resumo.valorTotalOrcamentosOpme)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ItemInfo
                  titulo="Valor total convertido"
                  info={formatarDinheiro(resumo.valorTotalOpmeRealizado)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  <b>Os 30 OPME's mais orçados </b>
                </Typography>
                <Bar
                  infos={resumo.orcamentosPorOpmes.slice(0, 150)}
                  width={800}
                  height={800}
                  convertido
                />
              </Grid>
            </Grid>
          </UBox>
        </UBox>
        {/* <CarrouselInfo items={items} /> */}
      </UBox>
    </UBox>
  )
}

export default DashboardOperacional

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialogContent-root': {
      padding: '0 !important'
    }
  },
  body: {},
  bgPurple: {
    // background: theme.palette.secondary.main,
    color: '#fff',
    marginTop: 'auto'
  },
  closeButton: {
    cursor: 'pointer',
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  modalButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '20px'
  },
  mainDate: {
    color: '#717171',
    margin: '5px 0'
  },
  normalDescription: {
    marginTop: '5px',
    whiteSpace: 'pre-line'
  },
  swiper: {
    '& .swiper-pagination': { marginTop: '40px' },
    '& .swiper': {
      width: '100%',
      height: '100%'
    },
    '& .swiper-slide': {
      textAlign: 'center',
      fontSize: '18px',
      // background: '#333',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
      //   minHeight: theme.breakpoints.down('sm') ? 'calc(60vh - 40px)' : '45vh',
      //   marginTop: theme.breakpoints.down('sm') ? 'auto' : '0'
    },

    '& .swiper-slide img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  }
}))

type PropsCarrouselInfo = {
  items: PropsItemInfo[]
}

function CarrouselInfo({ items, ...rest }: PropsCarrouselInfo) {
  const styles = useStyles()
  return (
    // <Swiper
    //   spaceBetween={16}
    //   slidesPerView={5}
    //   keyboard={{ enabled: true }}
    //   //   onSlideChange={swiper => setPosition(swiper.activeIndex)}
    //   onSwiper={swiper => console.log(swiper)}
    //   pagination={true}
    //   navigation={true}
    //   modules={[Keyboard, Navigation, Pagination]}
    //   className={styles.swiper}
    // >
    <UBox className={styles.swiper}>
      {/* <Grid container spacing={2}>
        <Grid item xs={3}>
          <ItemInfo titulo={i.titulo} info={i.info} />
        </Grid>
      </Grid> */}
    </UBox>
    // </Swiper>
  )
}

type PropsItemInfo = {
  titulo: string
  info: any
}

const useStylesItem = makeStyles(theme => ({
  root: {
    background: '#fff',
    borderRadius: '8px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .info': {
      marginTop: 'auto',
      marginBottom: '16px'
    }
  }
}))

function ItemInfo({ info, titulo, ...rest }: PropsItemInfo) {
  const styles = useStylesItem()
  return (
    <UBox p={2} className={styles.root}>
      <Typography variant="body2" className="titulo">
        {titulo}
      </Typography>
      <Typography variant="h5" className="info">
        <b>{info}</b>
      </Typography>
    </UBox>
  )
}
