import Drawer from 'src/components/ui/Drawer'
import { CircularProgress, Box } from '@material-ui/core'
import { useState } from 'react'
import tipoDadosBancario from 'src/core/consts/tipoDadosBancario'
import CadastroDadosBancarios from 'src/pages/new-cadastro-medico'

interface Props {
  open: boolean
  handleOnClose: (atualizou, id?, tipoConta?) => void
  id?: string
  medicoId?: string
  parceiroId?: string
  tipoConta?: number
}

export default function DrawerSalvarMembro({
  open,
  handleOnClose,
  id,
  medicoId,
  parceiroId,
  tipoConta
}: Props) {
  const [loading, setLoading] = useState<boolean>(false)
  return (
    <Drawer
      open={open}
      anchor="right"
      handleOnClose={() => handleOnClose(false)}
      titulo="Salvar membro para a equipe"
    >
      {loading ? (
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      ) : (
        <CadastroDadosBancarios ignorarSubmit={false}
          dadosBancarioId={id}
          id={tipoDadosBancario.Medico === tipoConta ? medicoId : parceiroId}
          tipoConta={tipoConta}
          handleAfterSubmit={handleOnClose}
          ehMembroEquipe={true} />
      )}
    </Drawer>
  )
}
