import { Box, Tab, Tabs, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import Ubox from 'src/components/ui/Ubox'
import useAuth from 'src/hooks/useAuth'
import ListagemPacotes from './listagemPacotesCotacao'
import ListagemMeusPacotesInteligentes from './listagemMeusPacotesInteligentes'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'src/hooks/useQuery'
import { SynRoutes } from 'src/core/consts/synRoutes'
import ListagemPacoteUnificado from './listagemPacoteUnificado'

function listagem() {
  const { perfilAtual } = useAuth()
  const query = useQuery()
  const history = useHistory()

  const [tabValue, setTabValue] = useState<0 | 1>(0)

  useEffect(() => {
    const tipo = query.get('tipo') || ''
    if (tipo?.toLowerCase() === 'inteligente') {
      setTabValue(1)
    }
  })

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5">Pacotes de procedimentos</Typography>
      </Box>
      {/* <Tabs
        value={tabValue}
        onChange={(e, value) => {
          if (value === 0) {
            history.replace(`${SynRoutes.listagemPacotes}?tipo=cotacao`)
          } else {
            history.replace(`${SynRoutes.listagemPacotes}?tipo=inteligente`)
          }
          setTabValue(value)
        }}
        textColor="primary"
        indicatorColor="primary"
        style={{ marginBottom: '1em' }}
      >
        <Tab label="Pacotes cotação" value={0} />
        <Tab label="Pacotes inteligentes" value={1} />
      </Tabs> */}

      {/* <Ubox hidden={!(tabValue === 0)}>
        <ListagemPacotes />
      </Ubox> */}
      {/* <Ubox hidden={!(tabValue === 1)}> */}
      {/* <ListagemPacotes /> */}

      <ListagemPacoteUnificado />
      {/* </Ubox> */}
    </Box>
  )
}

export default listagem
