import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'

import { useHistory } from 'react-router-dom'
import { SynRoutes } from 'src/core/consts/synRoutes'
import {
  BusinessCenter,
  DynamicFeed,
  LockOpenOutlined,
  People,
  SdCardOutlined
} from '@material-ui/icons'
import { GoPackage } from 'react-icons/go'

function menuLateralExterno() {
  const history = useHistory()

  return (
    <List>

      <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemOrcamentosOperacional)}
      >
        <ListItemIcon>
          <AssignmentOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Solicitações" />
      </ListItem>

      <ListItem button onClick={() => history.push(SynRoutes.listagemMedicos)}>
        <ListItemIcon>
          <People />
        </ListItemIcon>
        <ListItemText primary="Médicos" />
      </ListItem>

      <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemPacotesOperacional)}
      >
        <ListItemIcon style={{ fontSize: '1.4rem' }}>
          <GoPackage />
        </ListItemIcon>
        <ListItemText primary="Pacotes" />
      </ListItem>

      <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemParceirosOperacional)}
      >
        <ListItemIcon>
          <BusinessCenter />
        </ListItemIcon>
        <ListItemText primary="Parceiros" />
      </ListItem>

      <ListItem button onClick={() => history.push(SynRoutes.termos)}>
        <ListItemIcon>
          <SdCardOutlined />
        </ListItemIcon>
        <ListItemText primary="Termos" />
      </ListItem>
    </List>
  )
}

export default menuLateralExterno
