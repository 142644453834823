import {
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Chip,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { Alert } from '@material-ui/lab'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useState } from 'react'
import { AiTwotoneThunderbolt } from 'react-icons/ai'
import { BiNetworkChart } from 'react-icons/bi'
import { BsBell, BsBellFill } from 'react-icons/bs'
import { FaRegCalendarCheck } from 'react-icons/fa'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import { RiDeleteBin6Line, RiEyeLine } from 'react-icons/ri'
import { salvarAgendamento } from 'src/api/agendamento'
import ButtonAlterarStatusOrcamento from 'src/components/solicitacao/ButtonStatusOrcamento'
import Button from 'src/components/ui/Button'
import UBox from 'src/components/ui/Ubox'
import { statusOrcamento } from 'src/core/consts/statusSolicitacao'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { SynRoutes } from 'src/core/consts/synRoutes'
import StatusProposta from 'src/core/consts/statusProposta'
import { formatarDataHoraLocal } from 'src/core/formatacoes/formatarData'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import {
  IAgedamentoResponse,
  ItemListagemSolicitacaoOrcamentoResponse
} from 'src/types'
import Swal from 'sweetalert2'

interface ICardProps extends CardProps {
  item: ItemListagemSolicitacaoOrcamentoResponse
  handleVisualizarDetalhes: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  handleImprimir: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  renderStatusSolicitacao: (solicitacao) => JSX.Element
  solicitacao: ItemListagemSolicitacaoOrcamentoResponse
  handleVisualizarOcorrencias: (itemId: string) => void
  handleAgendar?: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleCancelarAgendamento?: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  handleAlterarStatusProcedimentoRealizado: (itemId: string) => void
  navigate: (route: string) => void
  handleOnClose?: (
    agendado: IAgedamentoResponse,
    solicitacao?: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
}

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -3,
    top: 8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}))(Badge)

export default function CardHospitalListagem({
  item,
  solicitacao,
  handleVisualizarOcorrencias,
  handleVisualizarDetalhes,
  handleImprimir,
  renderStatusSolicitacao,
  handleAgendar,
  handleCancelarAgendamento,
  handleAlterarStatusProcedimentoRealizado,
  navigate,
  handleOnClose,
  ...rest
}: ICardProps) {
  const { user, perfilAtual } = useAuth()
  const [verTexto, setVerTexto] = React.useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingAgendamento, setLoadingAgendamento] = useState<boolean>(false)

  const handleVerTexto = () => {
    setVerTexto(!verTexto)
  }

  async function handleConfirmarAgendamento(
    itemAgendamento: IAgedamentoResponse
  ) {
    try {
      setLoadingAgendamento(true)
      const payload = {
        id: itemAgendamento.id,
        solicitacaoOrcamentoId: itemAgendamento.solicitacaoOrcamentoId,
        dataAgendamento: itemAgendamento.dataPreAgendamento,
        dataPreAgendamento: null,
        observacao: itemAgendamento.observacao
      }
      await Swal.fire({
        title: 'Atenção!',
        text: 'Deseja realmente confirmar este agendamentro?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar'
      }).then(async result => {
        if (result.isConfirmed) {
          await salvarAgendamento(payload)
          handleOnClose(payload, solicitacao)
        }
        setLoadingAgendamento(false)
      })
    } catch (error) {
      setLoadingAgendamento(false)
      showNotificationErrorAPI(error)
    }
  }

  const truncatedText =
    item?.agendamento?.observacao?.length > 30
      ? `${item?.agendamento?.observacao?.substring(0, 30)}... `
      : item?.agendamento?.observacao
  const countPendentRevisao = solicitacao?.listaPropostas?.filter((item) => item.status === StatusProposta.PENDENTE_REVISAO.Id)

  return (
    <Card variant="outlined" {...rest}>
      <CardHeader
        style={{ borderBottom: '1px solid #eee' }}
        action={
          <PopupState variant="popover" popupId="demo-popup-menu">
            {popupState => (
              <React.Fragment>
                <Tooltip title="Visualizar">
                  <IconButton
                    onClick={() => {
                      popupState.close()
                      handleVisualizarDetalhes(item)
                    }}
                  >
                    <RiEyeLine />
                  </IconButton>
                </Tooltip>
                {perfilAtual === SynPerfis.AGENDAMENTO &&
                  statusOrcamento.REALIZADO.Id !== solicitacao?.status.id && (
                    <>
                      {!item?.agendamento && (
                        <Tooltip title="Agendar">
                          <IconButton onClick={() => handleAgendar(item)}>
                            <FaRegCalendarCheck size={18} />
                          </IconButton>
                        </Tooltip>
                      )}

                      {!!item?.agendamento && (
                        <Tooltip title="Editar">
                          <IconButton onClick={() => handleAgendar(item)}>
                            <HiOutlinePencilAlt size={22} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {!!item?.agendamento && (
                        <Tooltip title="Cancelar">
                          <IconButton
                            onClick={() => handleCancelarAgendamento(item)}
                          >
                            <RiDeleteBin6Line size={20} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  )}

                <Tooltip
                  title={
                    solicitacao.qtdOcorrencias > 0
                      ? `${solicitacao.qtdOcorrencias} ocorrências`
                      : 'Nenhuma ocorrência'
                  }
                >
                  <IconButton
                    style={{ fontSize: '22px' }}
                    onClick={() => handleVisualizarOcorrencias(solicitacao.id)}
                  >
                    {solicitacao.qtdOcorrencias > 0 ? (
                      <StyledBadge
                        color="secondary"
                        badgeContent={solicitacao.qtdOcorrencias}
                      >
                        <BsBellFill style={{ color: '#3bd1b6' }} />
                      </StyledBadge>
                    ) : (
                      <BsBell />
                    )}
                  </IconButton>
                </Tooltip>

                {perfilAtual !== SynPerfis.AGENDAMENTO && (
                  <>
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      aria-label="mais"
                      {...bindTrigger(popupState)}
                    >
                      <MoreVertIcon />
                    </IconButton>

                    <Menu
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      {...bindMenu(popupState)}
                    >
                      <MenuItem
                        onClick={() => {
                          popupState.close()
                          handleVisualizarDetalhes(item)
                        }}
                      >
                        Visualizar
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          popupState.close()
                          handleImprimir(item)
                        }}
                        style={{ width: '200px' }}
                      >
                        Imprimir guia
                      </MenuItem>
                      {/* <MenuItem
                onClick={() => {
                  popupState.close()
                  handleExcluir(item.id)
                  }}
                  className="danger"
                  >
                  Excluir
                  </MenuItem> */}
                    </Menu>
                  </>
                )}
                {/* <UBox render={perfilAtual !== SynPerfis.AGENDAMENTO}>

                </UBox> */}
              </React.Fragment>
            )}
          </PopupState>
        }
        title={
          <UBox>
            <UBox display="flex" alignItems="center">
              <Typography
                variant="body2"
                color="primary"
                style={{
                  padding: '2px 4px',
                  border: '1px solid #eee',
                  borderRadius: '8px',
                  background: '#eee'
                }}
              >
                <b>{item.codigo}</b>
              </Typography>

              {item.tipo === 'inteligente' && (
                <Tooltip title="Pacote instantâneo">
                  <Chip
                    style={{
                      //  border: '1px solid #aaa',
                      background: 'none'
                    }}
                    label={
                      <AiTwotoneThunderbolt
                        className="icon"
                        style={{
                          fontSize: '26px',
                          paddingTop: '6px',
                          color: '#ebb852'
                        }}
                      />
                    }
                    size="small"
                  />
                </Tooltip>
              )}

              {item.tipo === 'cotacao' && (
                <Tooltip title="Pacote cotação">
                  <Chip
                    style={{
                      //  border: '1px solid #aaa',
                      background: 'none'
                    }}
                    label={
                      <BiNetworkChart
                        className="icon"
                        style={{
                          fontSize: '26px',
                          paddingTop: '6px',
                          color: '#1cc3a5'
                        }}
                      />
                    }
                    size="small"
                  />
                </Tooltip>
              )}
            </UBox>
            <Typography variant="body1" align="left">
              <Link
                component="button"
                variant="body1"
                color="primary"
                align="left"
                onClick={() => handleVisualizarDetalhes(item)}
              >
                {item.descricaoPacoteProcedimento?.length > 28 && (
                  <>
                    <Tooltip title={item.descricaoPacoteProcedimento}>
                      <b>
                        {item.descricaoPacoteProcedimento?.length > 28
                          ? item?.descricaoPacoteProcedimento
                            ?.slice(0, 28)
                            .concat('...')
                          : item.descricaoPacoteProcedimento}
                      </b>
                    </Tooltip>
                  </>
                )}
                {item.descricaoPacoteProcedimento?.length <= 28 && (
                  <b>{item.descricaoPacoteProcedimento}</b>
                )}
              </Link>
            </Typography>
          </UBox>
        }
        subheader={
          <small style={{}}>
            {'Solicitado em: ' + formatarDataHoraLocal(item.criado)}
          </small>
        }
        titleTypographyProps={{ variant: 'subtitle1' }}
        subheaderTypographyProps={{ variant: 'body2' }}
      />
      <CardContent>
        <Box my={1}>{item.status && renderStatusSolicitacao(item)}</Box>
        {statusOrcamento.PENDENTE_REVISAO.Id === solicitacao.status.id && (
          <Alert
            severity="warning"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              padding: '8px'
            }}
          >
            Essa solicitação está pendente de revisão de valores pelo motivo do paciente possuir plano de saúde.
          </Alert>
        )}
        <Box mt={0}>
          <Typography variant="body2" color="primary">
            <b>
              <small>Paciente</small>
            </b>
          </Typography>
          <Typography variant="body2">{item.nomePaciente}</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="body2" color="primary">
            <b>
              <small>Médico</small>
            </b>
          </Typography>
          <Typography variant="body2">{item.nomeMedico}</Typography>
        </Box>

        <UBox mt={1}>
          {!!solicitacao?.agendamento?.dataPreAgendamento ? (
            <>
              <Typography variant="body2" color="error">
                <b>
                  <small>Pré agendamento</small>
                </b>
              </Typography>
              <Box mt={0}>
                <Typography
                  variant="body2"
                  style={{ fontWeight: !!solicitacao?.agendamento ? 500 : 400 }}
                  color="error"
                >
                  {!!solicitacao?.agendamento
                    ? formatarDataHoraLocal(
                      solicitacao?.agendamento?.dataPreAgendamento
                    )
                    : 'Não informado'}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="body2" color="primary">
                <b>
                  <small>Data de agendamento</small>
                </b>
              </Typography>
              <Box mt={0}>
                <Typography
                  variant="body2"
                  style={{ fontWeight: !!solicitacao?.agendamento ? 500 : 400 }}
                >
                  {!!solicitacao?.agendamento
                    ? formatarDataHoraLocal(
                      solicitacao?.agendamento?.dataAgendamento
                    )
                    : 'Não informado'}
                </Typography>
              </Box>
            </>
          )}

          <UBox mt={1} render={!!item?.agendamento?.observacao}>
            <Typography variant="body2">
              <Alert severity="info">
                <b>
                  <small>
                    {solicitacao?.status.id ===
                      statusOrcamento.PAGAMENTO_PARCIAL.Id ||
                      solicitacao?.status.id ===
                      statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id
                      ? 'Observação do pré agendamento:'
                      : 'Observação do agendamento: '}
                  </small>
                </b>
                <br />
                {verTexto ? item?.agendamento?.observacao : truncatedText}
                {item?.agendamento?.observacao?.length > 30 && (
                  <Link
                    component="button"
                    variant="body2"
                    onClick={handleVerTexto}
                    style={{
                      fontSize: '13px',
                      fontWeight: 600,
                      marginLeft: '4px'
                    }}
                  >
                    {verTexto ? ' Ver menos' : ' Ver mais'}
                  </Link>
                )}
              </Alert>
            </Typography>
          </UBox>
        </UBox>
        {statusOrcamento.PENDENTE_REVISAO.Id === solicitacao.status.id && (
          <>
            <Box mt={1} mb={1}>
              <Divider />
            </Box>
            <Box mt={2} mb={1}>
              <Typography variant="subtitle2">Propostas</Typography>
            </Box>
            {countPendentRevisao.length === 0 ? (
              <Box>
                <Typography variant="body2">Nenhuma proposta ainda</Typography>
              </Box>
            ) : (
              <>
                {
                  countPendentRevisao.length > 0 && (
                    <Grid item xs={12} sm={12}>
                      <Typography
                        style={{
                          color: StatusProposta.PENDENTE_REVISAO.Color
                        }}
                        variant="body2"
                      >
                        {countPendentRevisao.length === 1
                          ? '1 Pendente de revisão'
                          : `${countPendentRevisao.length} Pendente de revisão`}
                      </Typography>
                    </Grid>
                  )
                }
              </>
            )}
            <UBox render={countPendentRevisao.length > 0}>
              <Link
                component="button"
                variant="body2"
                color="primary"
                align="left"
                onClick={() => {
                  // if (
                  //   perfil === 'medico' ||
                  //   perfilAtual === SynPerfis.PARCEIRO_EXTERNO ||
                  //   (perfil === 'secretaria' &&
                  //     !!permissoesSecretaria?.podeAprovarRecusarProposta)
                  // ) {
                  // }
                  // if (['operacional', 'comercial'].includes(perfil)) {
                  // }
                  // navigate(
                  //   `${SynRoutes.detalhesPropostasMedico}?id=${solicitacao.id}`
                  // )
                  navigate(
                    `${SynRoutes.detalhesPropostas}?id=${solicitacao.id}`
                  )
                }}
                style={{ marginTop: '10px' }}
              >
                {statusOrcamento.PENDENTE_REVISAO.Id === solicitacao.status.id ? 'Revisar propostas' : 'Ver propostas'}
              </Link>
            </UBox>
          </>
        )}

        {(perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL ||
          perfilAtual === SynPerfis.ADMIN_HOSPITAL) &&
          !!item?.propostaVencedoraId && (
            <UBox mt={1}>
              <Divider />
              <Box mt={1}>
                <Link
                  component="button"
                  variant="body2"
                  color="primary"
                  onClick={() => {
                    // setUltimaSolicitacao(solicitacao)
                    navigate(
                      `${SynRoutes.acompanharPagamento}?p=${item?.propostaVencedoraId}`
                    )
                  }}
                >
                  Acompanhar pagamento
                </Link>
              </Box>
            </UBox>
          )}
        <UBox mt={1} render={solicitacao.propostaVencedoraFinalDeSemana}>
          <Alert severity="warning">Apenas para finais de semana e feriados.</Alert>
        </UBox>
        <UBox mt={1} render={perfilAtual === SynPerfis.AGENDAMENTO}>
          {statusOrcamento.AGENDADO.Id === solicitacao.status.id && (
            <ButtonAlterarStatusOrcamento
              onClick={async () => {
                var swalResult = {
                  isConfirmed: false
                }

                swalResult = await Swal.fire({
                  title: 'Atenção',
                  icon: 'warning',
                  text:
                    'Essa cirurgia foi realmente REALIZADA? Após confirmar, essa ação NÃO poderá ser revertida?',
                  confirmButtonText: 'Sim',
                  showCancelButton: true,
                  cancelButtonText: 'Não'
                })

                if (swalResult?.isConfirmed) {
                  setLoading(true)
                  handleAlterarStatusProcedimentoRealizado(item.id)
                } else {
                  setLoading(false)
                }
              }}
              loadingStatus={loading}
            />
          )}
        </UBox>

        {perfilAtual === SynPerfis.AGENDAMENTO && (
          <>
            <UBox
              render={
                [
                  statusOrcamento.PAGAMENTO_PARCIAL.Id,
                  statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id
                ].includes(solicitacao?.status.id) &&
                !!item?.agendamento?.dataPreAgendamento
              }
            >
              <Box
                mt={2}
                style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleAgendar(item)}
                >
                  reagendar
                </Button>
              </Box>
            </UBox>
            <UBox
              render={
                [
                  statusOrcamento.PAGAMENTO_CONFIRMADO.Id, statusOrcamento.EM_AGENDAMENTO.Id
                ].includes(solicitacao.status.id) && !!solicitacao.agendamento?.dataPreAgendamento
              }
            >
              <Box
                mt={2}
                style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}
              >
                <Button
                  variant="contained"
                  onClick={() => handleConfirmarAgendamento(item?.agendamento)}
                  loading={loadingAgendamento}
                >
                  confirmar agendamento
                </Button>
              </Box>
            </UBox>
          </>
        )}
      </CardContent>
    </Card>
  )
}
