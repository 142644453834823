// A wrapper for <Route> that redirects to the login

import { Backdrop, CircularProgress } from '@material-ui/core'
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import LoadingPage from 'src/components/pages/LoadingPage'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { SynRoutes } from 'src/core/consts/synRoutes'
import useAuth from 'src/hooks/useAuth'

interface PrivateRouteProps extends RouteProps {}

// screen if you're not yet authenticated.
export default function PrivateRoute({ children, ...rest }: PrivateRouteProps) {
  const { signed, loading, user, perfilAtual } = useAuth()

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (signed && user.primeiroAcesso) {
          return (
            <Redirect
              to={{
                pathname: SynRoutes.definirSenha,
                state: { from: location }
              }}
            />
          )
        }

        if (signed && !user.aceitouTermo) {
          return (
            <Redirect
              to={{
                pathname: SynRoutes.termosDeUso,
                state: { from: location }
              }}
            />
          )
        }

        // if (signed && user.aceitouTermo && !user.aceitouPoliticaPrivacidade) {
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: SynRoutes.politicaDePrivacidade,
        //         state: { from: location }
        //       }}
        //     />
        //   )
        // }

        if (signed && !perfilAtual) {
          return (
            <Redirect
              to={{
                pathname: SynRoutes.selecionarPerfil,
                state: { from: location }
              }}
            />
          )
        }

        if (
          signed &&
          perfilAtual === SynPerfis.PARCEIRO &&
          user.lstSetorFuncionario?.length > 1 &&
          !user.setorFuncionario &&
          user.aceitouTermo
        ) {
          return (
            <Redirect
              to={{
                pathname: SynRoutes.selecionarSetorParceiro,
                state: { from: location }
              }}
            />
          )
        }

        return (
          <>
            {signed && !loading ? (
              children
            ) : loading ? (
              <LoadingPage />
            ) : (
              <Redirect
                to={{
                  pathname: SynRoutes.signin,
                  state: { from: location }
                }}
              />
            )}
          </>
        )
      }}
    />
  )
}
