import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useState } from 'react'

export default function InfoResponsabilidade() {
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))
  const [verMais, setVerMais] = useState(false)

  return (
    <Alert severity="warning">
      {!ehXS ? (
        <Typography variant="body2" component="span">
          A responsabilidade pelo preenchimento correto dos dados solicitados é
          inteiramente do cliente.
          <br />
          A SYN não se responsabiliza por falhas do pagamento por inserção
          incorreta dos dados ou dados inverídicos.
          <br />
          Dessa forma, confirme os dados inseridos antes de seguir para a
          próxima etapa.
          <br />
        </Typography>
      ) : (
        <Typography variant="body2" component="span">
          {`A responsabilidade pelo preenchimento${
            !verMais
              ? '... '
              : ' correto dos dados solicitados é inteiramente do cliente.'
          }`}
          {!verMais && (
            <b style={{ cursor: 'pointer' }} onClick={() => setVerMais(true)}>
              ver mais
            </b>
          )}
          {verMais && (
            <>
              <br />
              A SYN não se responsabiliza por falhas do pagamento por inserção
              incorreta dos dados ou dados inverídicos.
              <br />
              Dessa forma, confirme os dados inseridos antes de seguir para a
              próxima etapa.
              <br />
            </>
          )}
        </Typography>
      )}
    </Alert>
  )
}
