import {
  Box,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'

import { RiArrowLeftLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'

import { SynRoutes } from 'src/core/consts/synRoutes'
import React, { useEffect, useState } from 'react'

import PoliticaPrivacidade from 'src/core/docs/PoliticaPrivacidade'

import useAuth from 'src/hooks/useAuth'

import { aceitarTermoDeUso } from '../../api/auth'
import { showNotificationErrorAPI } from '../../core/helpers/notification'
import { Button } from '../../components/ui/Button'
import TipoTermo from 'src/core/consts/tipoTermo'
export default function PreCadastroMedico() {
  const history = useHistory()
  const [termoLido, setTermoLido] = useState(false)
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const ehSM = useMediaQuery(theme.breakpoints.down('sm'))
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))
  const { signed } = useAuth()
  const { setAceitouTermo, signOut } = useAuth()
  useEffect(() => {
    if (!signed) {
      history.push('sign-in')
    }
  }, [])
  function lerTermo(e) {
    if (
      Math.ceil(e.target.scrollHeight * 0.8) - e.target.scrollTop <=
      e.target.clientHeight
    ) {
      setTermoLido(true)
    }
  }

  async function aceitarTermo() {
    setLoading(true)

    try {
      await aceitarTermoDeUso(TipoTermo.privacidade)
      setAceitouTermo(TipoTermo.privacidade)
      history.push('/')
    } catch (err) {
      showNotificationErrorAPI(err)
    }

    setLoading(false)
  }

  return (
    <Container
      component="main"
      className="fadeInTransition syn-container-out"
      maxWidth="md"
      style={
        {
          // padding: '30px',
          // margin: '10px auto 10px',
          // width: '100% !important'
          // height: 'calc(100vh - 20px)'
        }
      }
    >
      <CssBaseline />
      <div
        style={{
          fontSize: '2rem',
          overflow: 'auto',
          maxHeight: 'calc(100vh - 300px)'
        }}
        dangerouslySetInnerHTML={{ __html: PoliticaPrivacidade }}
        onScroll={e => lerTermo(e)}
        data-cy="termoDeUso"
      ></div>
    </Container>
  )
}
