import { yupResolver } from '@hookform/resolvers/yup'
import { Box, TextField as TextFieldMU, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  obterHonorariosPorId,
  salvarHonorarios
} from 'src/api/solicitacao-orcamento'
import Button from 'src/components/ui/Button'
import Drawer from 'src/components/ui/Drawer'
import { NumberFormatCustom } from 'src/components/ui/NumberField'
import yup from 'src/components/yupCustom'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import Swal from 'sweetalert2'
import useAuth from 'src/hooks/useAuth'

type Props = {
  id: string
  open: boolean
  handleOnClose: (boolean?) => void
}

const schema = yup.object().shape({
  honorariosMedico: yup.string().nullable().required().label('Honorário médico')
})

interface IForm {
  honorariosMedico?: number
}

function DrawerAlterarHonorarios(props: Props) {
  const [loadingSalvar, setLoadingSalvar] = useState(false)
  const [loadingHonorarios, setLoadingHonorarios] = useState(false)
  const [rangeHonorarios, setRangeHonorarios] = useState('')
  const { signOut, user, perfilAtual } = useAuth()

  useEffect(() => {
    if (!props.id) {
      return
    }

    popularHonorarios()
    reset()
  }, [props.open])

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    formState,
    handleSubmit,
    reset,
    trigger
  } = useForm<IForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: { honorariosMedico: null }
  })

  useEffect(() => {
    register('honorariosMedico')
  }, [register])

  const command = watch()

  const handleItemValue = (name: any, item) => {
    setValue(name, item, {
      shouldValidate: true
    })
  }

  async function popularHonorarios() {
    try {
      setRangeHonorarios('')
      setLoadingHonorarios(true)
      const { data } = await obterHonorariosPorId(props.id)

      setRangeHonorarios(
        `${formatarDinheiro(data.valorMinHonorarios)} até ${formatarDinheiro(
          data.valorMaxHonorarios
        )}`
      )
      setLoadingHonorarios(false)
    } catch (e) {
      setLoadingHonorarios(false)

      showNotificationErrorAPI(e)
      props.handleOnClose()
    }
  }
  async function handleSalvar() {
    try {
      const cmd = {
        idSolicitacao: props.id,
        valorHonorario: control.getValues().honorariosMedico,
        perfilAtual: perfilAtual
      }

      var response = await salvarHonorarios(cmd)
      Swal.fire('Honorário alterado com sucesso!', '', 'success')
      setLoadingSalvar(false)
      props.handleOnClose(true)
    } catch (e) {
      setLoadingSalvar(false)
      showNotificationErrorAPI(e)
      props.handleOnClose()
    }
  }

  return (
    <Drawer
      anchor="right"
      open={props.open}
      titulo="Alterar honorários do médico"
      handleOnClose={() => props.handleOnClose()}
    >
      <form onSubmit={handleSubmit(handleSalvar)}>
        <Typography variant="body2">
          Valores de negociação devem estar entre {rangeHonorarios}
        </Typography>
        <Box mt={2}>
          <TextFieldMU
            data-cy="honorariosMedico"
            error={!!errors.honorariosMedico}
            helperText={errors.honorariosMedico?.message}
            variant="outlined"
            fullWidth
            margin="normal"
            label="Honorários médico"
            value={command.honorariosMedico}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value

              // setValue('honorariosMedico', value)
              handleItemValue('honorariosMedico', value)
            }}
            InputProps={{
              inputComponent: NumberFormatCustom as any
            }}
          />
        </Box>
        <Box mt={3}>
          <Button
            disabled={loadingSalvar}
            fullWidth
            type="submit"
            loading={loadingSalvar}
            secondaryLoading
          >
            Salvar
          </Button>
        </Box>
      </form>
    </Drawer>
  )
}

export default DrawerAlterarHonorarios
