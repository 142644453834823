import NumberFormat from 'react-number-format'

interface NumberFormatCustomProps {
  inputRef?: (instance: NumberFormat | null) => void
  onChange?: (event: { target: { name: string; value: string } }) => void
  name?: string
}

// TODO: Ajusta isso aqui irmão
export function NumberFormatPorcentagem(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      decimalScale={2}
      fixedDecimalScale
      allowedDecimalSeparators={[',']}
      decimalSeparator=","
      allowNegative={false}
      isNumericString
      suffix=" %"
      type="tel"
      isAllowed={values => {
        if (
          !values.floatValue ||
          (values.floatValue <= 100 && values.floatValue >= 0)
        ) {
          return true
        }
      }}
    />
  )
}
