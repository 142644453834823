import axios from 'axios'
import config from '../config'

const api = axios.create({
  baseURL: `${config.baseAPI}/api`
})

const use2 = api.interceptors.response.use(
  response => response,
  err => {
    return Promise.reject(err)
  }
)

const use1 = () => {
  api.interceptors.response.use(use2)
}

api.registerInterceptWithStore = () => {
  use1()
}

api.interceptors.response.use(
  response => response,
  error => {
    const resp = error.response
    //Limpa token se inválido

    if (resp?.status === 401 || resp?.status === 403) {
      if (typeof window !== 'undefined') {
        localStorage.removeItem('@authApp: user')
        localStorage.removeItem('@authApp: MostrouTutorialLogado')
        localStorage.removeItem('@authApp: token')
        localStorage.removeItem('@authApp: profile')
        localStorage.removeItem('@authApp: tenant')
        localStorage.setItem('@authApp: logout', 'true')

        location.reload()
      }
    }

    return Promise.reject(error)
  }
)

export const AddToken = token => {
  api.defaults.headers.Authorization = `Bearer ${token}`
}

export const AddTenant = tenant => {
  api.defaults.headers['X-Tenant'] = `${tenant}`
}

export const AddProfile = profile => {
  api.defaults.headers['X-Profile'] = `${profile}`
}

export const AddUserHeader = user => {
  api.defaults.headers['X-User'] = `${user}`
}

export default api
