import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import 'react-credit-cards/es/styles-compiled.css'
import { useHistory } from 'react-router-dom'
import Boleto from 'src/components/pagamento/boleto'
import Pix from 'src/components/pagamento/pix'
import CartaoCredito from 'src/components/pagamento/cartaoCredito'
import Button from 'src/components/ui/Button'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import UBox from 'src/components/ui/Ubox'
import { T_FORMAS_PAGAMENTO } from 'src/contexts/Pagamento/context'
import { SynRoutes } from 'src/core/consts/synRoutes'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import usePagamento from 'src/hooks/usePagamento'
import { useQuery } from 'src/hooks/useQuery'
import { Alert } from '@material-ui/lab'
import Steps from 'src/components/pagamento/steps'
import FormDadosPessoais from 'src/components/pagamento/formDadosPessoais'
import Pagamento from './pagamento'
import { FiArrowDown } from 'react-icons/fi'
import useAuth from 'src/hooks/useAuth'
import { BiUserCircle } from 'react-icons/bi'

const FORMA_PAGAMENTO = {
  PIX: 'pix' as T_FORMAS_PAGAMENTO,
  CARTAO_CREDITO: 'credit_card' as T_FORMAS_PAGAMENTO,
  BOLETO: 'boleto' as T_FORMAS_PAGAMENTO,
  MULTIPLAS: 'multiplas' as T_FORMAS_PAGAMENTO
}

const ARRAY_FORMA_PAGAMENTO = [
  {
    tipo: 'pix',
    icone: <i className="fa fa-brands fa-pix "></i>,
    nome: 'Pix'
  },
  {
    tipo: 'credit_card',
    icone: <i className="fa fa-solid fa-credit-card "></i>,
    nome: 'Cartão de crédito'
  },
  {
    tipo: 'boleto',
    icone: <i className="fa fa-solid fa-barcode "></i>,
    nome: 'Boleto'
  },
  {
    tipo: 'multiplas',
    icone: <i className="fa fa-square-plus"></i>,
    nome: 'Multiplas formas de pagamento'
  }
]

const padrao = {
  background: '#fff',
  borderRadius: '8px'
  // boxShadow: '1px 1px 8px #ddd'
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // margin: '30px auto',
    // margin: '0 ',
    padding: 0,
    // gap: '40px',
    '& .card-pagamento': {
      ...padrao
    },
    '& .item-selecionado': {
      ...padrao,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      // minHeight: '72px',
      marginBottom: '30px',
      lineHeight: '1.1em'
      // background: '#eee',
      // borderRadius: '8px',
      // borderBottom: '1px solid #ddd',
      // borderRight: '1px solid #ddd',
    },
    '& .logo-container': {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  },
  boxResumo: {
    ...padrao,
    width: '440px',
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(6)
  },
  boxPagamento: {
    // ...padrao,
    width: 'calc(100% - 480px)',
    // width: '60%'
    background: '#fff',
    borderRadius: '8px',
    // border: '1px solid #eee'
    boxShadow: '0px 0px 8px #ddd',
    marginBottom: '40px'
  },
  boxPagamentoDetalhe: {
    ...padrao,
    // maxWidth: '700px',
    width: '100%',
    '& .qr-code': {
      maxWidth: '210px'
    },
    '& .box-instructions p': {
      width: 'calc(100% - 48px)'
    },
    '& .icon-instructions': {
      background: theme.palette.secondary.main,
      color: '#fff',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 20,
      marginRight: '8px'
    },
    '& .back-green': {
      background: '#1cc3a5'
      // background: '#1cc332'
    },
    '& .text-green': {
      color: '#1cc3a5'
      // color: '#1cc332',
    }
  },
  boxFormaPagamento: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',

    '& .item': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '180px',
      gap: '8px',
      minHeight: '84px',
      background: '#eee',
      borderRadius: '8px',
      cursor: 'pointer',
      textAlign: 'center',
      // borderBottom: '1px solid #ddd',
      // borderRight: '1px solid #ddd',

      '&:hover': {
        background: theme.palette.secondary.main,
        color: '#fff'
      },

      '&:last-child': {
        // borderRight: 'none'
      }
    }
  },
  [theme.breakpoints.down(1401)]: {
    boxFormaPagamento: {
      flexDirection: 'column',
      '& .item': {
        width: '100%'
      }
    }
  },
  [theme.breakpoints.down('md')]: {
    container: {
      // display: 'flex',
      // alignItems: 'start',
      // justifyContent: 'start',
      // flexDirection: 'column',
      gap: '0px',
      margin: 0,
      padding: 0,
      '& .logo-container': {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
      }
    },
    boxResumo: {
      ...padrao,
      marginLeft: 0,
      width: '100%',
      // maxWidth: '700px',
      order: 1,
      marginBottom: 0
    },
    boxPagamento: {
      // ...padrao,
      width: '100%',
      order: 2,
      marginBottom: 0
    },
    boxFormaPagamento: {
      flexDirection: 'column',
      '& .item': {
        width: '100%'
      }
    }
  }
}))

export default function PagePagamento() {
  const styles = useStyles()
  const theme = useTheme()
  const query = useQuery()
  const history = useHistory()
  const { signed } = useAuth()

  const {
    loadingResumo,
    loadingPagamento,
    loading,
    obterResumoPagamento,
    urlPix,
    boleto,
    formaPagamento,
    selecionarFormaPagamento,
    resumo,
    existePagamentoFracionado,
    reiniciarContext
  } = usePagamento()

  const [primeiroLoading, setPrimeiroLoading] = useState(true)
  const [activeStep, setActiveStep] = useState(0)

  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  const propostaId = query.get('p')

  function handleStepDadosPessoais() {
    setActiveStep(0)
  }
  function handleStepPagamento() {
    setActiveStep(1)
  }

  async function start() {
    reiniciarContext()
    obterResumoPagamento(propostaId).then(carregou =>
      setPrimeiroLoading(!carregou)
    )
  }

  function navegarParaPainel() {
    history.push(`${SynRoutes.home}`)
  }

  useEffect(() => {
    if (!!resumo?.pago && !primeiroLoading) {
      history.replace(`${SynRoutes.acompanharPagamento}?p=${propostaId}`)
    }
  }, [resumo, primeiroLoading])

  useEffect(() => {
    start()
  }, [])

  if (primeiroLoading) {
    return (
      <UBox
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}
      >
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      </UBox>
    )
  }

  return (
    <UBox>
      {/* <Box mb={2}>
        <Typography variant="h5">Pagamento</Typography>
      </Box> */}

      <Container maxWidth="lg" className={styles.container}>
        <UBox className="logo-container">
          {!ehXS && <UBox m={2} mb={2} width={ehXS ? '120px' : '180px'}></UBox>}
          <UBox m={2} mb={2}>
            <img
              src="/image/logo-2.png"
              alt="logo syn saúde"
              width={ehXS ? '110' : '136'}
              // width={ehXS ? '46' : '56'}
              // style={{
              //   marginTop: ehXS && activeStep === 0 ? '16px' : '0'
              // }}
            />
          </UBox>
          <UBox
            style={{
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end'
            }}
            m={2}
            mb={2}
            width={ehXS ? '120px' : '180px'}
          >
            {signed ? (
              <Typography
                style={{
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                  cursor: 'pointer'
                }}
                color="primary"
                onClick={navegarParaPainel}
              >
                <BiUserCircle
                  style={{ fontSize: '2rem', marginRight: '8px' }}
                />
                {!ehXS && 'Minha conta'}
              </Typography>
            ) : (
              <Button variant="contained" onClick={navegarParaPainel}>
                Entrar
              </Button>
            )}
          </UBox>
        </UBox>
        <UBox pb={6} className={styles.boxPagamento}>
          <Steps
            activeStep={activeStep}
            handleStep1={handleStepDadosPessoais}
          />
          <UBox render={activeStep === 0} p={3}>
            <FormDadosPessoais handleNextStep={handleStepPagamento} />
          </UBox>

          <UBox p={3} render={activeStep === 1}>
            <Pagamento />
          </UBox>
        </UBox>
        {/* <UBox p={2} className={styles.boxResumo}>
          <UBox render={loadingResumo}>
            <LoadingGeral />
          </UBox>
          <UBox mb={2}>
            <small>
              <b>{`${obterPorcentagemValorPago().toFixed(
                2
              )}% do valor processado`}</b>
            </small>
            <Tooltip
              title={`${obterPorcentagemValorPago().toFixed(
                2
              )}% do valor processado`}
            >
              <BorderLinearProgress
                variant="determinate"
                value={obterPorcentagemValorPago()}
                title={`${obterPorcentagemValorPago().toFixed(
                  2
                )}% do valor processado`}
              />
            </Tooltip>
            <Grid container>
              <Grid item xs={6}>
                <UBox
                  style={{
                    display: 'flex',
                    alignItems: 'start',
                    gap: '8px',
                    marginTop: '4px'
                  }}
                >
                  <UBox
                    style={{
                      background: '#74ce79',
                      width: '16px',
                      height: '16px',
                      borderRadius: '50%'
                    }}
                  />
                  <small>
                    <b>Pagamentos processados</b>
                    <br />
                    <b>{formatarDinheiro(resumo?.valorPago)}</b>
                  </small>
                </UBox>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <UBox
                  style={{
                    marginTop: '4px'
                  }}
                >
                  <small>
                    <b>Valor da proposta</b>
                    <br />
                    <b>{formatarDinheiro(resumo?.valorTotal)}</b>
                  </small>
                </UBox>
              </Grid>
            </Grid>
          </UBox>
        </UBox> */}

        {/* <UBox p={2} className={styles.boxResumo}>
          <UBox render={loadingResumo}>
            <LoadingGeral />
          </UBox>
          <UBox render={!loadingResumo && !!resumo}>
            <Typography variant="h6" color="primary">
              <b style={{ borderBottom: '4px solid #21ebc7' }}>
                {resumo?.procedimento}
              </b>
            </Typography>

            <br />

            <Typography variant="subtitle2" color="primary">
              Médico
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Typography variant="body2" component="span">
                  <strong>{resumo?.medico}</strong>
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Divider />
            <br />
            <Typography variant="subtitle2" color="primary">
              Local de atendimento
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Typography variant="body2" component="span">
                  <strong>{resumo?.localAtendimento}</strong>
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Divider />
            <br />
            <Typography variant="subtitle2" color="primary">
              Paciente
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Typography variant="body2" component="span">
                  <strong>{resumo?.paciente.nome}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color="primary">
                  Documento
                </Typography>
                <Typography variant="body2" component="span">
                  <strong>{resumo?.paciente.cpf}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color="primary">
                  Idade
                </Typography>
                <Typography variant="body2" component="span">
                  <strong>{resumo?.paciente.idade} anos</strong>
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Divider />
            <br />
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle2" color="primary">
                  Valor total do procedimento
                </Typography>
                <Typography variant="body2" component="span">
                  <strong>{formatarDinheiro(resumo?.valorTotal)}</strong>
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Divider />
            <br />
            <Grid container>
              <Grid item xs={12} sm={12}>
                <UBox mt={1}>
                  <Typography variant="subtitle2" color="primary">
                    Status de pagamento
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      color: resumo?.pago ? '#30b150' : '#ca5f21',
                      border: resumo?.pago
                        ? '2px solid #30b150'
                        : '2px solid #ca5f21',
                      borderRadius: '8px',
                      padding: '2px 8px',
                      display: 'inline-block'
                    }}
                  >
                    <b>{resumo?.statusProposta}</b>
                  </Typography>
                </UBox>
                <br />
                <Typography variant="subtitle2" color="primary">
                  Qtd de pedidos de pagamento realizados
                </Typography>
                <Typography variant="body2" component="span">
                  <strong>{resumo?.pedidos?.length}</strong>
                </Typography>
                <br />
                <br />

                <Typography variant="subtitle2" color="primary">
                  Valor dos pagamentos processados
                </Typography>
                <Typography variant="body2" component="span">
                  <strong>{formatarDinheiro(resumo?.valorPago)}</strong>
                </Typography>
                <br />
                <br />

                <Typography variant="subtitle2" color="primary">
                  Valor dos pagamentos em processamento
                </Typography>
                <Typography variant="body2" component="span">
                  <strong>
                    {formatarDinheiro(resumo?.valorEmProcessamento)}
                  </strong>
                </Typography>
                <br />
                <br />

                <Typography variant="subtitle2" color="primary">
                  Saldo devedor
                </Typography>
                <Typography variant="body2" component="span">
                  <strong>{formatarDinheiro(resumo?.saldoDevedor)}</strong>
                </Typography>
                <br />
                <br />

                <Button
                  variant="contained"
                  size="small"
                  fullWidth
                  color="secondary"
                  onClick={() =>
                    history.push(
                      `${SynRoutes.acompanharPagamento}?p=${propostaId}`
                    )
                  }
                >
                  Acompanhar pagamento
                </Button>
              </Grid>
            </Grid>
          </UBox>
        </UBox> */}
      </Container>
    </UBox>
  )
}
