import {
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core'
import React from 'react'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { IUsuario, IUsuarioPerfil } from 'src/types'
import formatarDocumento from 'src/core/formatacoes/formatarDocumento'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'

interface Props {
  data: IUsuario[]
  handleExcluir: (id: string) => void
  handleResetarSenha: (item: IUsuario) => void
  handleEditar: (item: IUsuario) => void
  handleVisualizarDetalhes: (item: IUsuario) => void
}

export default function TabelaUsuarios({
  data,
  handleExcluir,
  handleVisualizarDetalhes,
  handleEditar,
  handleResetarSenha
}: Props) {
  const handlePerfisName = (tenant: IUsuarioPerfil) => {
    if (tenant?.perfis) {
      return tenant.perfis?.map(perfil => perfil.nome).join(', ')
    }
  }
  const handlePerfisId = (tenant: IUsuarioPerfil) => {
    if (tenant?.perfis) {
      return tenant.perfis?.map(perfil => perfil.id).join(', ')
    }
  }
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>CPF</TableCell>
            <TableCell>Telefone</TableCell>
            <TableCell>E-mail</TableCell>
            <TableCell>Tenants</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map(item => {
            return (
              <TableRow key={item.id}>
                <TableCell>{item?.nome}</TableCell>
                <TableCell style={{ minWidth: '150px' }}>{formatarDocumento(item?.cpf)}</TableCell>
                <TableCell style={{ minWidth: '150px' }}>{formatarTelefone(item?.telefone)}</TableCell>
                <TableCell>{item?.email}</TableCell>
                <TableCell >
                  {item.perfisTenant.map(tenant => (
                    <>
                      <Tooltip title={handlePerfisName(tenant)} placement="top" key={handlePerfisId(tenant)}>
                        <Chip
                          style={{ margin: 2 }}
                          key={tenant.tenantId.toString()}
                          label={tenant?.descricao}
                        />
                      </Tooltip>
                    </>
                  ))}
                </TableCell>

                <TableCell style={{ textAlign: 'right' }}>
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {popupState => (
                      <React.Fragment>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          aria-label="mais"
                          {...bindTrigger(popupState)}
                        >
                          <MoreVertIcon />
                        </IconButton>

                        <Menu
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          {...bindMenu(popupState)}
                        >
                          <MenuItem
                            onClick={() => {
                              popupState.close()
                              handleEditar(item)
                            }}
                          >
                            Editar
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              popupState.close()
                              handleResetarSenha(item)
                            }}
                          >
                            Resetar senha
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              popupState.close()
                              handleExcluir(item?.id)
                            }}
                            className="danger"
                          >
                            Excluir
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer >
  )
}
