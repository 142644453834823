import { AxiosResponse } from 'axios'
import api from 'src/services/api'
import {
  ConvidarMedicoCommand,
  ItemListagemMedicoResponse,
  ListagemQuery,
  ListagemResponse,
  PreCadastroCommand,
  ItemAnotacaoMedico,
  ResumoMedicoResponse,
  SalvarAssistenteCommand,
  ItemListagemAssistenteMedicoResponse,
  ResumoMedicoComAssistente,
  VincularPacoteMedicoLocalAtendimentoCommand,
  ItemListagemVinculoMedicoLocalAtendimento,
  ObjetoDescricaoGenerico,
  ISalvarCadastroHospitalCommand,
  ItemListagemMedicoParceiroResponse,
  IAdicionarUfsMedicoCommand,
  ICriarComboCommand,
  IComboResponse,
  IAtualizarComboExameCommand,
  EditarCadastroResponse
} from 'src/types'

export const salvarPreCadastro: (
  command: PreCadastroCommand
) => Promise<AxiosResponse<void>> = command => {
  try {
    const body = {
      ...command,
      especialidades: command.especialidades.map(e => e.id)
      //locaisAtendimento: command.locaisAtendimento.map(e => e.id)
    }
    return api.post('/medico/precadastro', body)
  } catch (err) {
    // ;+arguments.callee.name
    console.log('@ERROR: ', err)
  }
}
export const editarPreCadastro: (
  command: PreCadastroCommand
) => Promise<AxiosResponse<void>> = command => {
  try {
    const body = {
      ...command,
      especialidades: command.especialidades.map(e => e.id)
      //locaisAtendimento: command.locaisAtendimento.map(e => e.id)
    }
    return api.post('/medico/editarCadastro', body)
  } catch (err) {
    // ;+arguments.callee.name
    console.log('@ERROR: ', err)
  }
}
export function obterDadosPreCadastro(): Promise<
  AxiosResponse<EditarCadastroResponse>
> {
  return api.get('/medico/buscarPorId')
}

export const listagemMedico: (
  query: ListagemQuery
) => Promise<
  AxiosResponse<ListagemResponse<ItemListagemMedicoResponse>>
> = query => {
  try {
    const { perfisPipedrive, ...rest } = query
    var params = new URLSearchParams(rest)
    perfisPipedrive?.forEach(element => {
      params.append('perfisPipedrive', element)
    })
    return api.get(`/medico/listagem?${params}`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterItemListagemPorId: (
  medicoId: string
) => Promise<AxiosResponse<ItemListagemMedicoResponse>> = medicoId => {
  return api.get(`/medico/listagem/${medicoId}`)
}

export const listagemMedicoHospital: (
  query: ListagemQuery
) => Promise<
  AxiosResponse<ListagemResponse<ItemListagemMedicoParceiroResponse>>
> = query => {
  try {
    console.log(new URLSearchParams(query))
    return api.get(`/medico/listagem/parceiro?${new URLSearchParams(query)}`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const ConvidarMedico: (
  command: ConvidarMedicoCommand
) => Promise<AxiosResponse<void>> = command => {
  try {
    return api.post('/ConviteMedico', command)
  } catch (err) {
    // ;+arguments.callee.name
    console.log('@ERROR: ', err)
  }
}

export const BuscarMedicoPorNome: (
  nome: string
) => Promise<AxiosResponse<ResumoMedicoResponse[]>> = nome => {
  try {
    return api.get(`/medico?nome=${nome}`)
  } catch (err) {
    // ;+arguments.callee.name
    console.log('@ERROR: ', err)
  }
}

export const obterMedicos: () => Promise<
  AxiosResponse<ResumoMedicoResponse[]>
> = () => {
  try {
    return api.get('/medico/buscarMedicos')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterMedicosSecretaria: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/medico/listagemMedicos')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const listagemMedicoPorUsuario: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/medico/listagemMedicoPorUsuario')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterResumoMedico: (
  id: string
) => Promise<AxiosResponse<ResumoMedicoComAssistente>> = id => {
  try {
    return api.get(`/medico/${id}/resumo`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

/* ------------------- Anotações --------------------------- */

export const listagemAnotacoesMedico: (
  medicoId: string
) => Promise<
  AxiosResponse<ListagemResponse<ItemAnotacaoMedico>>
> = medicoId => {
  try {
    return api.get(`/medico/${medicoId}/anotacoes`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const excluirAnotacaoMedico: (
  anotacaoId: string
) => Promise<AxiosResponse<void>> = anotacaoId => {
  try {
    return api.delete(`/medico/anotacoes/${anotacaoId}`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const salvarAnotacao: (
  medicoId: string,
  anotacao: ItemAnotacaoMedico
) => Promise<AxiosResponse<void>> = (medicoId, anotacao) => {
  try {
    return api.post(`/medico/${medicoId}/anotacoes`, anotacao)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

/* --------------- Assistentes --------------------- */

export const salvarAssistente: (
  command: SalvarAssistenteCommand
) => Promise<AxiosResponse> = command => {
  try {
    return api.post('/assistenteMedico', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const buscarAssistentePorId: (
  secretariaId?: string
) => Promise<
  AxiosResponse<ItemListagemAssistenteMedicoResponse>
> = secretariaId => {
  try {
    return api.get(`/assistenteMedico/obterSecretariaPorId/${secretariaId}`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const buscarPermissoesPorId: (
  medicoId?: string
) => Promise<
  AxiosResponse<ItemListagemAssistenteMedicoResponse>
> = medicoId => {
  try {
    return api.get(`/assistenteMedico/obterPermissoes/${medicoId}`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const buscarAssistentesPorMedicoId: (
  medicoId?: string
) => Promise<
  AxiosResponse<ListagemResponse<ItemListagemAssistenteMedicoResponse>>
> = medicoId => {
  try {
    const body = {
      medicoUsuarioId: medicoId
    }
    return api.post('/assistenteMedico/listagem', body)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const excluirAssistenteMedico: (
  assistenteId: string
) => Promise<AxiosResponse> = assistenteId => {
  try {
    return api.delete(`/assistenteMedico/${assistenteId}`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export function vincularPacoteMedicoLocalAtendimento(
  localAtendimentoId: string
): Promise<AxiosResponse> {
  return api.post(
    '/medico/vinculoPacoteLocalAtendimento',
    `"${localAtendimentoId}"`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
}

export function obterVinculosPacoteMedicoLocalAtendimento(): Promise<
  AxiosResponse<ListagemResponse<ItemListagemVinculoMedicoLocalAtendimento>>
> {
  return api.get('/medico/vinculoPacoteLocalAtendimento')
}

export function excluirVinculoPacoteMedicoLocalAtendimento(
  vinculoId: string
): Promise<AxiosResponse> {
  return api.delete(`/medico/vinculoPacoteLocalAtendimento/${vinculoId}`)
}

export function aprovarVinculoPacoteMedicoLocalAtendimento(
  vinculoId: string
): Promise<AxiosResponse> {
  return api.put(`/medico/vinculoPacoteLocalAtendimento/${vinculoId}`)
}

export const obterLocaisAtendimentoSemVinculo: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/medico/obterLocaisAtendimentoVinculo')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterMedicosVinculadosSecretaria: () => Promise<
  AxiosResponse<ObjetoDescricaoGenerico[]>
> = () => {
  try {
    return api.get('/medico/listagemMedicos')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export function salvarCadastroHospital(
  command: ISalvarCadastroHospitalCommand
): Promise<AxiosResponse> {
  return api.post('/medico/hospital', command)
}

export function buscarPorCpf(
  cpf: string
): Promise<AxiosResponse<ItemListagemMedicoResponse>> {
  return api.get(`/medico/buscar/${cpf}`)
}

export function buscarPorEspecialidades(
  especialidades: string[],
  uf?: string
): Promise<AxiosResponse<ResumoMedicoResponse[]>> {
  var body = {
    especialidades,
    uf
  }
  return api.post('/medico/especialidades', body)
}

export function obterUfsAtendimento(
  medicoId: string
): Promise<AxiosResponse<ObjetoDescricaoGenerico[]>> {
  return api.get(`/medico/${medicoId}/ufs`)
}

export function salvarUfsAtendimento(
  command: IAdicionarUfsMedicoCommand
): Promise<AxiosResponse> {
  const body = {
    ufs: command.ufs
  }
  return api.post(`/medico/${command.medicoId}/ufs`, body)
}

export function criarComboExames(command: ICriarComboCommand) {
  return api.post('/medico/combo', command)
}

export function obterComboExames(): Promise<AxiosResponse<IComboResponse[]>> {
  return api.get('/medico/combo')
}

export function atualizarComboExames(command: IAtualizarComboExameCommand) {
  return api.put('/medico/combo', command)
}
