import React from 'react'
import { Box, makeStyles, Typography, Grid, Divider } from '@material-ui/core'
import {
  ItemListagemMedicoResponse,
  ResumoMedicoComAssistente
} from 'src/types'
import formatarCPF from 'src/core/formatacoes/formatarCPF'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'
import { renderChipStatusMedico } from 'src/core/consts/statusMedico'

const useStyles = makeStyles(theme => ({
  boxResumo: {
    border: '1px solid #eee',
    backgroundColor: '#efefef',
    borderRadius: 4,
    '& .MuiTableCell-root.MuiTableCell-head': {
      padding: theme.spacing(1, 2),
      background: '#f9f9f9',
      color: theme.palette.grey[800]
    },
    padding: '20px'
  }
}))

interface Props {
  resumo: ResumoMedicoComAssistente
  parceiro?: boolean
}

export default function ResumoMedico({ resumo, parceiro }: Props) {
  const styles = useStyles()

  const medico = resumo?.medico
  const assistentes = resumo?.assistentes

  return (
    <Box className={styles.boxResumo}>
      <Box mt={2}>
        <Typography variant="subtitle2" color="primary">
          Dados pessoais
        </Typography>
      </Box>
      <Divider style={{ marginBottom: '10px' }} />
      <Grid container style={{ marginBottom: '15px' }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">
            <strong>Nome: </strong>
            {medico?.nome}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">
            <strong>CPF: </strong>
            {formatarCPF(medico?.cpf)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">
            <strong>Telefone: </strong>
            {formatarTelefone(medico?.telefone)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">
            <strong>E-mail: </strong>
            {medico?.email}
          </Typography>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Typography variant="subtitle2" color="primary">
          Dados médicos
        </Typography>
      </Box>
      <Divider style={{ marginBottom: '16px' }} />
      <Grid container style={{ marginBottom: '16px' }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">
            <strong>CRM: </strong>
            {medico?.crm}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">
            <strong>CRO: </strong>
            {medico?.cro}
          </Typography>
        </Grid>
        {!parceiro && (
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">
              <strong>Status: </strong>
              {renderChipStatusMedico(medico?.status)}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} style={{ marginTop: '5px' }}>
          <Typography variant="subtitle2">
            <strong>Especialidades: </strong>
            {medico?.especialidades?.map(e => e).join(', ')}
          </Typography>
        </Grid>
        {!parceiro && (
          <Grid item xs={12} style={{ marginTop: '5px' }}>
            <Typography variant="subtitle2">
              <strong>Observações: </strong>
              {medico?.observacao ? medico?.observacao : 'Sem observação'}
            </Typography>
          </Grid>
        )}
        {!parceiro && medico?.parceiroNome && (
          <Grid item xs={12} style={{ marginTop: '5px' }}>
            <Typography variant="subtitle2">
              <strong>Criado por: </strong>
              {medico?.parceiroNome}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Typography variant="subtitle2" color="primary">
        Assistente(s)
      </Typography>
      <Divider style={{ marginBottom: '16px' }} />
      {assistentes?.length === 0 && (
        <Typography variant="body2">Ainda não possui assistentes</Typography>
      )}
      {assistentes?.map((item, index) => {
        return (
          <Grid container key={item?.id}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">
                <strong>Nome: </strong>
                {item?.nome}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">
                <strong>Telefone: </strong>
                {formatarTelefone(item?.telefone)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">
                <strong>E-mail: </strong>
                {item?.email || 'Não informado'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">
                <strong>CPF: </strong>
                {formatarCPF(item?.cpf) || 'Não informado'}
              </Typography>
            </Grid>
            {!(index === assistentes?.length - 1) && (
              <Grid item xs={12}>
                <Divider style={{ margin: '10px 0' }} />
              </Grid>
            )}
          </Grid>
        )
      })}
    </Box>
  )
}
