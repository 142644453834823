import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { SimulacaoFinanciamentoResponse } from 'src/types'
import { FiMinimize2, FiMaximize2 } from 'react-icons/fi'

import {
  alterarStatusSolicitacaoFinanciamento,
  AlterarStatusSolicitacaoFinanciamentoCommand,
  obterSolicitacaoFinanciamento
} from 'src/api/financiamento'
import UBox from 'src/components/ui/Ubox'
import {
  StatusFinanciamento,
  StatusFinanciamentoArray
} from 'src/core/consts/statusFinanciamento'
import formatarCPF from 'src/core/formatacoes/formatarCPF'
import {
  formatarData,
  formatarDataHoraLocal
} from 'src/core/formatacoes/formatarData'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import useAuth from 'src/hooks/useAuth'
import DrawerAlterarStatusFinanciamento from './drawerAlterarStatus'
import DrawerResumoFinanciamento from './drawerResumoFinanciamento'
import DrawerVisualizarDetalhesFinanciamento from './drawerVisualizarDetalhesFinanciamento'
import DrawerFavorecidos from './drawerFavorecidos'
import CardFinanciamento from 'src/components/financiamento/card'

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided
} from 'react-beautiful-dnd'
import Button from 'src/components/ui/Button'
import { number } from 'yup'
import Swal from 'sweetalert2'

const useStyles = makeStyles(theme => ({
  cardListagem: {
    borderColor: '#eee',
    '& .titulo-card': {
      fontWeight: 600
    },
    '&.MuiPaper-rounded': {
      borderRadius: '12px'
    },
    '& .MuiCardHeader-root': {
      borderBottom: '1px solid #efefef',
      marginBottom: '8px'
    }
  },
  menu: {
    fontWeight: 600,
    color: '#9b00008a'
  },

  badgeCustom: {
    '& .MuiBadge-colorSecondary': {
      // backgroundColor: theme.palette.grey[100]
      backgroundColor: '#e91e63'
    }
  },

  [theme.breakpoints.down('sm')]: {
    cardListagem: {}
  }
}))

function listagemFinanciamento() {
  const { perfilAtual } = useAuth()
  const style = useStyles()
  const [loading, setLoading] = useState(true)
  const [loadingStatus, setLoadingStatus] = useState(false)

  const [colunasMinimizadas, setColunasMinimizadas] = useState<number[]>([])

  const [mostrarAlterarStatus, setMostrarAlterarStatus] = useState(false)
  const [mostrarResumo, setMostrarResumo] = useState(false)
  const [
    solicitacaoSelecionada,
    setSolicitacaoSelecionada
  ] = useState<SimulacaoFinanciamentoResponse>()
  const [mostrarDetalhe, setMostrarDetalhe] = useState(false)
  const [mostrarFavorecidos, setMostrarFavorecidos] = useState(false)
  const [nenhumDado, setNenhumDado] = useState(false)
  const [nenhumResultado, setNenhumResultado] = useState(false)
  const [allDadosCarregados, setAllDadosCarregados] = useState(true)
  const [lstSimulacaoFinanciamento, setLstSimulacaoFinanciamento] = useState<
    SimulacaoFinanciamentoResponse[]
  >()

  const [listaStatusFinanciamento, setListaStatusFinanciamento] = useState(
    StatusFinanciamentoArray
  )

  async function obterFinanciamentos() {
    setLoading(true)
    //Popular valores vindo da tela anterior
    await obterSolicitacaoFinanciamento(perfilAtual)
      .then(response => {
        setLstSimulacaoFinanciamento(response.data)
        let financiamentos = response.data

        let novaLista = StatusFinanciamentoArray

        StatusFinanciamentoArray.forEach(status => {
          const index = novaLista.findIndex(i => i.id === status.id)
          novaLista[index].itens = financiamentos.filter(
            i => i.status === status.id
          )
        })

        setListaStatusFinanciamento(novaLista)
      })
      .catch(err => showNotificationErrorAPI(err))
      .finally(() => setLoading(false))
  }
  const [
    itemSelecionado,
    setItemSelecionado
  ] = useState<SimulacaoFinanciamentoResponse>(undefined)
  async function handleRefazerPreAnalise(
    item: SimulacaoFinanciamentoResponse
  ) {}

  async function handleAlterarStatus(item: SimulacaoFinanciamentoResponse) {
    setSolicitacaoSelecionada(item)

    setMostrarAlterarStatus(true)
  }
  // async function handleMostrarResumo(item: SimulacaoFinanciamentoResponse) {
  //   setSolicitacaoSelecionada(item)
  //   setMostrarResumo(true)
  // }
  // function handleFecharResumo() {
  //   setMostrarResumo(false)
  //   setSolicitacaoSelecionada(undefined)
  // }

  const handleFecharDetalhes = () => {
    setMostrarDetalhe(false)
  }
  const handleFecharMostrarFavorecidos = () => {
    setMostrarFavorecidos(false)
  }

  const handleVisualizarDetalhes = (item: SimulacaoFinanciamentoResponse) => {
    setItemSelecionado(item)
    setMostrarDetalhe(true)
  }
  const handleMostrarFavorecidos = (item: SimulacaoFinanciamentoResponse) => {
    setItemSelecionado(item)
    setMostrarFavorecidos(true)
  }

  function handleFecharAlterarStatus(alterou) {
    if (alterou) {
      obterFinanciamentos()
    }
    setMostrarAlterarStatus(false)
    setSolicitacaoSelecionada(undefined)
  }

  useEffect(() => {
    obterFinanciamentos()
    setTimeout(() => {
      if (!!window && !!document) {
        var syn = window.document?.querySelector('.syn-container')
        if (!!syn) syn?.classList.add('no-padding')
      }
    }, 100)
    return () => {
      if (!!window && !!document) {
        var syn = window.document?.querySelector('.syn-container')
        if (!!syn) syn.classList.remove('no-padding')
      }
    }
  }, [])

  function toogleMinimizados(id) {
    let novaLista = JSON.parse(JSON.stringify(colunasMinimizadas))

    if (colunasMinimizadas.includes(id)) {
      //remove

      var index = colunasMinimizadas.indexOf(id)
      novaLista.splice(index, 1)
    } else {
      //add
      novaLista = [...colunasMinimizadas, id]
    }
    setTimeout(() => {
      console.log(novaLista)
      setColunasMinimizadas(novaLista)
    }, 100)
  }

  async function handleDragEnd(
    result: DropResult,
    provided: ResponderProvided
  ) {
    console.log('drag drop end', result, provided)

    const { source, destination, type, draggableId } = result

    if (!destination) return

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return
    }

    if (type === 'group') {
      //obter o id da lista a partir do droppable-id
      var listaSourceId = parseInt(source.droppableId.replace('droppable-', ''))
      var listaDestinationId = parseInt(
        destination.droppableId.replace('droppable-', '')
      )

      var indexListaSource = listaStatusFinanciamento.findIndex(
        f => f.id === listaSourceId
      )

      var indexListaDestination = listaStatusFinanciamento.findIndex(
        f => f.id === listaDestinationId
      )

      var novaLista = JSON.parse(JSON.stringify(listaStatusFinanciamento))

      var listaSource = novaLista[indexListaSource]
        .itens as SimulacaoFinanciamentoResponse[]
      var listaDestination = novaLista[indexListaDestination]
        .itens as SimulacaoFinanciamentoResponse[]
      //Salvar na lista e com o index correto

      //Remover da lista source uma lista
      const sourceIndex = source.index
      const destinationIndex = destination.index

      //Add a lista destination
      const [removedStore] = listaSource.splice(sourceIndex, 1)
      listaDestination.splice(destinationIndex, 0, removedStore)

      novaLista[indexListaSource].itens = listaSource
      novaLista[indexListaDestination].itens = listaDestination
      const estadoAnterior = JSON.parse(
        JSON.stringify(listaStatusFinanciamento)
      )
      setListaStatusFinanciamento(novaLista)
      if (indexListaSource !== indexListaDestination) {
        var item = lstSimulacaoFinanciamento.find(i => i.id === draggableId)
        setItemSelecionado(item)
        if (!!item) {
          await Swal.fire({
            title: 'Atenção!',
            text:
              'As mudanças de status por padrão são automaticas. Deseja alterar manualmente?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim'
          }).then(async result => {
            if (result.isConfirmed) {
              await alterarStatus(draggableId, listaDestinationId)
              // setListaStatusFinanciamento(novaLista)
            } else {
              setListaStatusFinanciamento(estadoAnterior)
            }
          })
        }
      }
    }
  }

  async function alterarStatus(financiamentoId: string, statusId: number) {
    try {
      setLoadingStatus(true)
      var command: AlterarStatusSolicitacaoFinanciamentoCommand = {
        id: financiamentoId,
        status: statusId
      }

      await alterarStatusSolicitacaoFinanciamento(command)
      setLoadingStatus(false)
    } catch (e) {
      setLoadingStatus(false)
      showNotificationErrorAPI(e)
    }
  }
  return (
    // <Dialog
    //   open={true}
    //   fullWidth
    //   maxWidth="xl"
    //   aria-labelledby="form-dialog-title"
    // >
    <DragDropContext onDragEnd={handleDragEnd}>
      {/* {JSON.stringify(
        listaStatusFinanciamento[1].itens.map(i => i.codigoSolicitacao)
      )} */}
      <UBox
        display="flex"
        overflow="auto"
        p={2}
        width="100%"
        height="90vh "
        style={{
          backgroundColor: 'rgb(65,36,93)',
          background:
            'linear-gradient(166deg, #455880 0%, rgb(108 108 185) 30%, rgb(94, 185, 167) 100%)',
          overflowX: 'scroll'
        }}
      >
        {loading && <LoadingGeral />}

        {!loading &&
          listaStatusFinanciamento.map(status => (
            <UBox
              key={'status-' + status.id}
              p={1}
              width={!colunasMinimizadas.includes(status.id) ? '300px' : '50px'}
              minWidth={
                !colunasMinimizadas.includes(status.id) ? '300px' : '50px'
              }
              height={
                !colunasMinimizadas.includes(status.id) ? '100%' : '100px'
              }
              mr="16px"
              style={{
                border: '1px solid #ddd',
                background: '#eee',
                borderRadius: '4px'
              }}
            >
              <UBox
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                render={!colunasMinimizadas.includes(status.id)}
              >
                <Typography variant="h6">{status.descricao}</Typography>

                <IconButton
                  size="small"
                  color="default"
                  onClick={() => toogleMinimizados(status.id)}
                >
                  <FiMinimize2 />
                </IconButton>
              </UBox>

              <UBox
                display="flex"
                alignItems="space-between"
                justifyContent="space-between"
                render={colunasMinimizadas.includes(status.id)}
              >
                <Tooltip title={status.descricao}>
                  <IconButton
                    size="small"
                    color="default"
                    onClick={() => toogleMinimizados(status.id)}
                  >
                    <FiMaximize2 />
                  </IconButton>
                </Tooltip>
              </UBox>
              <Droppable droppableId={'droppable-' + status.id} type="group">
                {(provided, snapshot) => (
                  <div
                    style={{
                      backgroundColor: snapshot.isDraggingOver
                        ? '#ccc'
                        : '#eee',
                      borderRadius: '4px',
                      height: !colunasMinimizadas.includes(status.id)
                        ? 'calc(90vh - 110px)'
                        : '40px'
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <UBox
                      component="div"
                      style={{
                        backgroundColor: snapshot.isDraggingOver
                          ? '#ccc'
                          : '#eee',
                        borderRadius: '4px',
                        overflow: 'auto',
                        // overflowY: 'scroll',
                        height: !colunasMinimizadas.includes(status.id)
                          ? 'calc(90vh - 110px)'
                          : '40px'
                      }}
                    >
                      {colunasMinimizadas.includes(status.id) && (
                        <UBox display="flex" my={1} justifyContent="center">
                          <b>+{status?.itens?.length}</b>
                        </UBox>
                      )}
                      {!colunasMinimizadas.includes(status.id) &&
                        status.itens.map(
                          (item: SimulacaoFinanciamentoResponse, i) => (
                            <Draggable
                              draggableId={item.id}
                              key={item.id}
                              index={i}
                            >
                              {(providedDrag, snapshotDrag) => (
                                <div
                                  {...providedDrag.dragHandleProps}
                                  {...providedDrag.draggableProps}
                                  ref={providedDrag.innerRef}
                                  key={i}
                                >
                                  <UBox mt={1} m={1}>
                                    <CardFinanciamento
                                      loading={
                                        loadingStatus &&
                                        item?.id === itemSelecionado?.id
                                      }
                                      handleAlterarStatus={handleAlterarStatus}
                                      handleMostrarFavorecidos={
                                        handleMostrarFavorecidos
                                      }
                                      handleVisualizarDetalhes={
                                        handleVisualizarDetalhes
                                      }
                                      item={item}
                                    />
                                  </UBox>
                                  {/* <UBox
                              style={{
                                backgroundColor: snapshotDrag.isDragging
                                  ? 'blue'
                                  : 'grey',
                                padding: '16px',
                                margin: '8px 0'
                              }}
                            >
                              {item.codigoSolicitacao}
                            </UBox> */}
                                </div>
                              )}
                            </Draggable>
                          )
                        )}
                      {provided.placeholder}
                    </UBox>
                  </div>
                )}
              </Droppable>
            </UBox>
          ))}
      </UBox>
      {itemSelecionado && (
        <DrawerVisualizarDetalhesFinanciamento
          id={itemSelecionado?.pacoteProcedimentoId}
          open={mostrarDetalhe}
          item={itemSelecionado}
          handleOnClose={handleFecharDetalhes}
        />
      )}
      <DrawerFavorecidos
        id={itemSelecionado?.id}
        open={mostrarFavorecidos}
        handleOnClose={handleFecharMostrarFavorecidos}
      />
      {/* <DrawerResumoFinanciamento
        id={solicitacaoSelecionada?.id}
        open={mostrarResumo}
        handleOnClose={handleFecharResumo}
      /> */}
      {mostrarAlterarStatus && !!solicitacaoSelecionada?.id && (
        <DrawerAlterarStatusFinanciamento
          id={solicitacaoSelecionada?.id}
          open={true}
          handleOnClose={handleFecharAlterarStatus}
        />
      )}
    </DragDropContext>

    // </Dialog>
  )

  return (
    <>
      <Box pb={4}>
        <Box mb={2}>
          <Typography variant="h5">Solicitações de financiamento</Typography>
        </Box>
        {loading && <LoadingGeral />}

        {nenhumDado &&
          !nenhumResultado &&
          lstSimulacaoFinanciamento.length === 0 && (
            <div>Não há pedido financiamento</div>
          )}

        {nenhumResultado && (
          <div>Nenhum resultado encontrado para sua pesquisa.</div>
        )}

        {allDadosCarregados && (
          <div className="fadeInTransition">
            <Grid container spacing={2}>
              {lstSimulacaoFinanciamento?.map((item, index) => {
                return (
                  <Grid item xs={12} md={4} key={item.cpf}>
                    <CardFinanciamento
                      handleAlterarStatus={handleAlterarStatus}
                      handleMostrarFavorecidos={handleMostrarFavorecidos}
                      handleVisualizarDetalhes={handleVisualizarDetalhes}
                      item={item}
                      key={index}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </div>
        )}
      </Box>
      {/* <DrawerVisualizarDetalhesFinanciamento
        id={itemSelecionado?.pacoteProcedimentoId}
        open={mostrarDetalhe}
        handleOnClose={handleFecharDetalhes}
      /> */}
      <DrawerFavorecidos
        id={itemSelecionado?.id}
        open={mostrarFavorecidos}
        handleOnClose={handleFecharMostrarFavorecidos}
      />
      {/* <DrawerResumoFinanciamento
        id={solicitacaoSelecionada?.id}
        open={mostrarResumo}
        handleOnClose={handleFecharResumo}
      /> */}
      {mostrarAlterarStatus && !!solicitacaoSelecionada?.id && (
        <DrawerAlterarStatusFinanciamento
          id={solicitacaoSelecionada?.id}
          open={true}
          handleOnClose={handleFecharAlterarStatus}
        />
      )}
    </>
  )
}

export default listagemFinanciamento
