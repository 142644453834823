import { Box, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import Button from 'src/components/ui/Button'
import Drawer from 'src/components/ui/Drawer'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { verificarCPF, onlyNumber } from 'src/core/helpers/common'

import yup from 'src/components/yupCustom'
import { TextField } from 'src/components/ui/TextField'
import { cadastrarCpf } from 'src/api/paciente'
import Swal from 'sweetalert2'
import { enviarInstrucoesNF } from 'src/api/proposta'

type Props = {
  id: string
  open: boolean
  handleOnClose: (boolean?) => void
}

const schema = yup.object().shape({
  telefone: yup
    .string()
    .required()
    .test('tel-válido', 'O telefone é inválido', function (value) {
      if (!value) return false

      var qtdNumero = onlyNumber(value).length

      return qtdNumero === 11 || qtdNumero === 10
    })
    .label('Telefone')
})

function DrawerInstrucoesNF(props: Props) {
  const [loading, setLoading] = useState(false)

  const { errors, watch, control } = useForm({
    resolver: yupResolver(schema)
  })

  const command = watch()

  async function submit() {
    setLoading(true)

    try {
      await enviarInstrucoesNF(props.id, command.telefone)

      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Instruções enviadas com sucesso',
        confirmButtonText: 'Ok'
      })

      props.handleOnClose()
    } catch (err) {
      showNotificationErrorAPI(err)
    }

    setLoading(false)
  }

  return (
    <Drawer
      anchor="right"
      open={props.open}
      titulo="Reenviar instruções NF p/ paciente"
      handleOnClose={() => props.handleOnClose()}
    >
      <Typography variant="subtitle1" style={{ marginBottom: '1em' }}>
        Insira o Whatsapp do paciente para enviar as instruções de NF.
      </Typography>
      <TextField
        control={control}
        name="telefone"
        placeholder="Telefone"
        label="Telefone (Whatsapp)"
        variant="outlined"
        fullWidth
        size="small"
        maskTelCel
        erro={errors}
        value={command.telefone}
      />

      <Box mt={3}>
        <Button
          fullWidth
          onClick={submit}
          loading={loading}
          disabled={loading}
          secondaryLoading
        >
          Enviar
        </Button>
      </Box>
    </Drawer>
  )
}

export default DrawerInstrucoesNF
