const TipoAnexo = {
  SYN: {
    id: 100,
    descricao: 'Equipe Syn'
  },
  HOSPITAL: {
    id: 1,
    descricao: 'Tesouraria Hospital/Clínica'
  },
  MEDICO: {
    id: 2,
    descricao: 'Médico'
  },
  OPME: {
    id: 3,
    descricao: 'Fornecedor OPME'
  },
  ANESTESIA: {
    id: 4,
    descricao: 'Fornecedor de anestesia'
  },
  SANGUE: {
    id: 5,
    descricao: 'Fornecedor de sangue'
  },
  MEMBROS_EQUIPE: {
    id: 6,
    descricao: 'Membros equipe médica'
  }
}

export const TipoAnexoArray = [
  {
    descricao: 'Equipe SYN',
    id: 100
  },
  {
    descricao: 'Tesouraria Hospital/Clínica',
    id: 1
  },
  {
    descricao: 'Médico',
    id: 2
  },
  {
    descricao: 'Fornecedor OPME',
    id: 3
  },
  {
    descricao: 'Fornecedor de anestesia',
    id: 4
  },
  {
    descricao: 'Fornecedor de sangue',
    id: 5
  },
  {
    descricao: 'Membros equipe médica',
    id: 6
  }
] as const

export default TipoAnexo
