import {
  Grid,
  Typography,
  Box,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import React from 'react'
import { BsWallet } from 'react-icons/bs'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import Button from 'src/components/ui/Button'
import { SynPerfis } from 'src/core/consts/synPerfis'
import useAuth from 'src/hooks/useAuth'

type Props = {
  handleNovoOrcamento: () => void
  nenhumResultado?: boolean
}

function NenhumOrcamento({ nenhumResultado, handleNovoOrcamento }: Props) {
  const { signOut, user, perfilAtual } = useAuth()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Box textAlign="center" py={3}>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography variant="h2" color="textPrimary">
            <AssignmentOutlinedIcon />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" color="textPrimary">
            Você ainda não possui solicitações de orçamento.
          </Typography>
          <p>Faça uma solicitaçao de orçamento para algum de seus pacientes.</p>
          {perfilAtual !== SynPerfis.SECRETARIA && (
            <Button
              variant="contained"
              fullWidth={isMobile}
              onClick={handleNovoOrcamento}
            >
              {!nenhumResultado ? 'Solicitar agora' : 'Continuar'}
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default NenhumOrcamento
