import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { BsCheckCircle, BsInfoCircle } from 'react-icons/bs'
import AddIcon from '@material-ui/icons/Add'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Search } from '@material-ui/icons'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useState, useEffect } from 'react'
import useListagem from 'src/hooks/useListagem'
import { listagemPacote } from 'src/api/pacote/index'
import {
  EspecialidadeResponse,
  ItemListagemPacoteProcedimentoResponse,
  ObjetoDescricaoGenerico
} from 'src/types'
// import DrawerVisualizarDetalhes from './drawerVisualizarDetalhes'
import useWindowInfiniteScroll from '../../../../hooks/useWindowInfiniteScroll'
import Button from 'src/components/ui/Button'
import { MdKeyboardArrowDown } from 'react-icons/md'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import { listarTodasEspecilidades } from 'src/api/especialidade'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import UBox from 'src/components/ui/Ubox'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { useHistory } from 'react-router-dom'
import {
  addMeuPacoteInteligente,
  ItemListagemPacoteInteligente,
  listagemPacoteInteligente,
  ListagemPacoteInteligenteQuery,
  listagemPacotesInteligentesSugeridos,
  marcarNaoRealiza,
  obterLocaisAtendimentoInteligente
} from 'src/api/pacoteInteligente'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import DrawerPacoteInteligenteVisualizarDetalhes from 'src/components/pacoteInteligente/Drawer/resumo'

import { Alert } from '@material-ui/lab'
import Swal from 'sweetalert2'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'

function ListagemPacotesSugeridos() {
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))
  const { user, perfilAtual } = useAuth()
  const [mostrarDetalhe, setMostrarDetalhe] = useState(false)
  const [step, setStep] = useState(0)

  const [
    itemSelecionado,
    setItemSelecionado
  ] = useState<ItemListagemPacoteInteligente>(undefined)
  const [locaisAtendimentos, setLocaisAtendimentos] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [
    loadingLocaisAtendimentos,
    setLoadingLocaisAtendimentos
  ] = useState<boolean>(false)

  const history = useHistory()

  const {
    loading,
    dados,
    handleTermoPesquisa,
    termoPesquisa,
    nenhumDado,
    nenhumResultado,
    dadosCarregados,
    allDadosCarregados,
    loadingPagina,
    allDados,
    qtdAllDados,
    handleProxPagina,
    filtroGeral,
    carregarListagem,
    setFiltroGeral,
    atualizarItemListagem,
    removerItemListagem
  } = useListagem(9, listagem)

  function listagem(
    query: ListagemPacoteInteligenteQuery,
    primeiraBusca?: boolean
  ) {
    query['perfilAtual'] = perfilAtual
    return listagemPacotesInteligentesSugeridos(user.cpf, query)
  }

  const [
    pacoteSelecionado,
    setPacoteSelecionado
  ] = useState<ItemListagemPacoteInteligente>(null)
  const [loadingAddPacote, setLoadingAddPacote] = useState(false)

  const [locaisSelecionados, setLocaisSelecionados] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [especialidades, setEspecialidades] = useState<
    EspecialidadeResponse[]
  >()
  const [loadingEspecialidades, setLoadingEspecialidades] = useState(false)

  useWindowInfiniteScroll({
    loadMoreFunc: handleProxPagina,
    data: allDados,
    stopLoadingData: allDados.length >= qtdAllDados
  })

  const handleVisualizarDetalhes = (item: ItemListagemPacoteInteligente) => {
    if (perfilAtual !== SynPerfis.PARCEIRO_EXTERNO) {
      setItemSelecionado(item)
      setMostrarDetalhe(true)
    }
  }

  const handleFecharDetalhes = () => {
    setMostrarDetalhe(false)
  }

  function handleChangeLocais(event, newValue: ObjetoDescricaoGenerico[]) {
    newValue = newValue.filter(v => v.id)
    var value = newValue.map(v => v.id)
    setLocaisSelecionados(newValue)
    setFiltroGeral({
      locaisAtendimento: value,
      pagina: 1,
      qtdPorPagina: 9
    })
  }

  function handleContinuar() {
    if (locaisSelecionados?.length > 0) {
      setStep(1)
    } else {
      Swal.fire(
        'Ops...',
        'É obrigatório seleciona pelo menos um hospital',
        'warning'
      )
      return
    }
  }

  async function handleNaoRealizo(item: ItemListagemPacoteInteligente) {
    if (!item.id) {
      return
    }
    setLoadingAddPacote(true)
    try {
      setPacoteSelecionado(item)
      await marcarNaoRealiza({
        cpf: user.cpf,
        pacoteInteligenteId: item.id
      })
      removerItemListagem(item.id)
    } catch (error) {
      showNotificationErrorAPI(error)
    }

    setLoadingAddPacote(false)
  }

  const handleConfigurarPacote = (id: string) => {
    history.push(
      `${SynRoutes.formConfiguracaoPacoteInteligente}?idPacote=${id}`
    )
  }

  async function handleAddMeusPacotes(item: ItemListagemPacoteInteligente) {
    if (!item.id) {
      return
    }
    setLoadingAddPacote(true)
    try {
      setPacoteSelecionado(item)

      var response = await addMeuPacoteInteligente({
        cpf: user.cpf,
        pacoteInteligenteId: item.id
      })
      let pacoteVinculadoId = response.data
      item.adicionado = true
      item.pacoteVinculadoId = pacoteVinculadoId

      atualizarItemListagem(item.id, item)

      await Swal.fire({
        title: 'Sucesso!',
        text:
          'Pacote foi adicionado a "Meus pacotes".  Deseja configura-lo nesse momento?',
        icon: 'success',
        confirmButtonText: 'Configurar pacote',
        showCancelButton: true,
        cancelButtonText: 'Agora não'
      }).then(result => {
        if (result.isConfirmed) {
          handleConfigurarPacote(pacoteVinculadoId)
        }
      })
    } catch (error) {
      showNotificationErrorAPI(error)
    }
    setLoadingAddPacote(false)
  }

  const carregarLocaisAtendimentos = async () => {
    return obterLocaisAtendimentoInteligente(user.cpf)
      .then(response => setLocaisAtendimentos(response.data || []))
      .finally(() => {
        setLoadingLocaisAtendimentos(false)
      })
  }

  const carregarEspecialidades = async () => {
    setLoadingEspecialidades(true)

    listarTodasEspecilidades()
      .then(response => setEspecialidades(response.data))
      .catch(err => showNotificationErrorAPI(err))
      .finally(() => setLoadingEspecialidades(false))
  }

  useEffect(() => {
    carregarLocaisAtendimentos()
    carregarEspecialidades()
  }, [])

  function setClassToHighlight(item) {
    if (item?.id === itemSelecionado?.id) return 'highlight'

    return null
  }

  return (
    <UBox>
      <UBox render={step === 0} p={ehXS ? 0 : 3}>
        <UBox maxWidth={ehXS ? '100%' : 700} style={{ margin: '0 auto' }}>
          <Typography variant="h6" color="primary" style={{}} align="left">
            Para que possamos sugerir pacotes de procedimentos, por favor
            selecione o(s) hospital(is) que você opera.
          </Typography>
          <Typography variant="body2" color="primary">
            Você pode selecionar quantos hospitais desejar
          </Typography>
          <Autocomplete
            multiple
            fullWidth
            erro={false}
            name="hospital"
            label="Informe os hospitais"
            loading={loadingLocaisAtendimentos}
            options={locaisAtendimentos}
            value={locaisSelecionados || []}
            // showAddButton
            closeOnSelect
            showCheck
            getOptionLabel={option => `${option?.descricao}`}
            onClickAddButton={() => {}}
            showAddButton
            onChange={handleChangeLocais}
          />
          <Alert severity="info">
            <Typography variant="body2" color="primary">
              Hospitais sem pacotes não estão na lista acima.
            </Typography>
          </Alert>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={6}>
              <UBox textAlign="right" mt={4}>
                <Button
                  // fullWidth={isMobile}
                  variant="contained"
                  color="primary"
                  onClick={handleContinuar}
                  // startIcon={<AddIcon />}
                >
                  Continuar
                </Button>
              </UBox>
            </Grid>
          </Grid>
        </UBox>
      </UBox>

      <UBox render={step === 1}>
        {/* e:{JSON.stringify(especialidades)} */}
        {/* <br /> */}
        {/* e:{JSON.stringify(especialidadeSelecionada)}
        <br />
        f:{JSON.stringify(filtroGeral?.especialidades)} */}
        <Box mb={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <UBox pt={2}>
                <TextField
                  fullWidth
                  placeholder="Pesquise por procedimento, código ou nome do médico..."
                  size="small"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  value={termoPesquisa}
                  onChange={handleTermoPesquisa}
                />
              </UBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth size="small">
                <Autocomplete
                  multiple
                  fullWidth
                  size="small"
                  erro={false}
                  name="hospital"
                  label="Informe a clinica/hospital"
                  loading={loadingLocaisAtendimentos}
                  options={locaisAtendimentos}
                  value={locaisSelecionados || []}
                  // showAddButton
                  closeOnSelect
                  showCheck
                  getOptionLabel={option => `${option?.descricao}`}
                  onClickAddButton={() => {}}
                  onChange={handleChangeLocais}
                />
                {/* <Autocomplete
                  erro={false}
                  multiple
                  size="small"
                  name="especialidades"
                  label={'Especialidades'}
                  value={especialidadeSelecionada || []}
                  getOptionSelected={option =>
                    !!especialidadeSelecionada.find(l => l.id === option.id)
                  }
                  options={especialidades || []}
                  loading={loadingEspecialidades}
                  showCheck
                  closeOnSelect
                  showAddButton
                  getOptionLabel={option => option?.descricao}
                  onChange={handleChangeEspecialidade}
                  InputProps={{
                    autoComplete: 'new-password'
                  }}
                /> */}
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        {!loading && (
          <Box mt={3} mb={2} textAlign="left" my={3}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" color="primary">
                  {qtdAllDados
                    ? qtdAllDados > 1
                      ? `${qtdAllDados} pacotes`
                      : '1 pacote'
                    : '0 pacotes'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {/* t:{JSON.stringify(nenhumResultado)} */}
        {/* t:{JSON.stringify(nenhumDado)} */}
        {nenhumResultado ||
          (nenhumDado && (
            <div>Nenhum resultado encontrado para sua pesquisa.</div>
          ))}
        {allDadosCarregados && !loadingPagina && (
          <div className="fadeInTransition">
            <Grid container spacing={2}>
              {allDados.map(item => (
                <Grid item xs={12} md={4} key={item.id}>
                  <Card
                    variant="outlined"
                    className={setClassToHighlight(item)}
                  >
                    <CardHeader
                      action={
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {popupState => (
                            <React.Fragment>
                              <UBox
                                render={
                                  perfilAtual !== SynPerfis.PARCEIRO_EXTERNO
                                }
                              >
                                <IconButton
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  aria-label="mais"
                                  {...bindTrigger(popupState)}
                                >
                                  <MoreVertIcon />
                                </IconButton>

                                <Menu
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                  }}
                                  {...bindMenu(popupState)}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      popupState.close()
                                      handleVisualizarDetalhes(item)
                                    }}
                                  >
                                    Visualizar detalhes
                                  </MenuItem>
                                </Menu>
                              </UBox>
                            </React.Fragment>
                          )}
                        </PopupState>
                      }
                      title={
                        <Link
                          component="button"
                          variant="body1"
                          color="primary"
                          align="left"
                          onClick={() => handleVisualizarDetalhes(item)}
                        >
                          <b style={{ textTransform: 'uppercase' }}>
                            {item.descricao}
                          </b>
                          <UBox>
                            <Typography variant="body2" color="secondary">
                              <b>{item.hospital}</b>
                            </Typography>
                          </UBox>
                        </Link>
                      }
                      titleTypographyProps={{ variant: 'subtitle1' }}
                      subheaderTypographyProps={{ variant: 'body2' }}
                    />
                    <CardContent style={{ borderTop: '1px solid #eee' }}>
                      {/* {JSON.stringify(item)} */}

                      {/* <UBox mb={2}>
                        <Typography variant="body2" color="primary">
                          <small>
                            <b>Valor base:</b>
                          </small>
                        </Typography>
                        <Typography variant="body2" color="primary">
                          {formatarDinheiro(item.valorBase)}
                        </Typography>
                      </UBox> */}
                      <UBox render={item?.adicionado}>
                        <Typography
                          color="secondary"
                          variant="body1"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'center'
                            justifyContent: 'flex-start'
                          }}
                        >
                          <BsCheckCircle />

                          <small
                            style={{ marginLeft: '4px', display: 'block' }}
                          >
                            {/* <b> */}
                            Adicionado a meus pacotes
                            {/* </b> */}
                          </small>
                        </Typography>
                        <UBox render={!!item?.configurado} mt={1}>
                          <Typography
                            color="secondary"
                            variant="body1"
                            style={{
                              color: '#1cc3a5'
                            }}
                          >
                            <UBox
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                color: item?.configurado ? '#1cc3a5' : 'orange'
                              }}
                            >
                              <BsCheckCircle />
                              <small
                                style={{ marginLeft: '4px', display: 'block' }}
                              >
                                Pacote configurado
                              </small>
                            </UBox>
                          </Typography>
                        </UBox>
                        <UBox render={!item?.configurado} mt={1}>
                          <Link
                            component="button"
                            variant="body2"
                            color="error"
                            align="left"
                            onClick={() =>
                              handleConfigurarPacote(item.pacoteVinculadoId)
                            }
                            style={{
                              marginTop: '8px',
                              margin: '8px auto'
                              // display: 'block'
                            }}
                          >
                            <Typography
                              color="secondary"
                              variant="body1"
                              style={{
                                color: 'orange'
                              }}
                            >
                              <UBox
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  color: 'orange'
                                }}
                              >
                                <BsInfoCircle />

                                <small
                                  style={{
                                    marginLeft: '4px',
                                    display: 'block'
                                  }}
                                >
                                  Pendente de configuração
                                </small>
                              </UBox>
                            </Typography>
                          </Link>
                          <Button
                            size="small"
                            fullWidth
                            variant="outlined"
                            // component="button"
                            // variant="body2"
                            loading={
                              loadingAddPacote &&
                              item?.id === pacoteSelecionado?.id
                            }
                            color="default"
                            // align="left"
                            onClick={() =>
                              handleConfigurarPacote(item.pacoteVinculadoId)
                            }
                            style={{
                              color: 'orange',
                              borderColor: 'orange'
                            }}
                          >
                            CONFIGURAR AGORA
                          </Button>
                        </UBox>
                      </UBox>

                      <UBox render={!item?.adicionado}>
                        {/* <Divider /> */}
                        <Button
                          size="small"
                          fullWidth
                          // variant="outlined"
                          // component="button"
                          // variant="body2"
                          loading={
                            loadingAddPacote &&
                            item?.id === pacoteSelecionado?.id
                          }
                          color="secondary"
                          // align="left"
                          onClick={() => handleAddMeusPacotes(item)}
                          // style={{ marginTop: '10px' }}
                        >
                          Adicionar a meus pacotes
                        </Button>
                        <br />
                        {/* <Button
                          size="small"
                          fullWidth
                          variant="outlined"
                          // component="button"
                          // variant="body2"
                          color="default"
                          // align="left"
                          onClick={() => {}}
                          style={{
                            marginTop: '10px',
                            color: '#ff3535',
                            borderColor: '#ff3535'
                          }}
                        >
                          Não realizo esse procedimento
                        </Button> */}
                        <UBox
                          hidden={
                            loadingAddPacote &&
                            item?.id === pacoteSelecionado?.id
                          }
                        >
                          <Link
                            component="button"
                            variant="body2"
                            color="error"
                            align="center"
                            onClick={() => handleNaoRealizo(item)}
                            style={{
                              marginTop: '8px',
                              margin: '8px auto',
                              display: 'block'
                            }}
                          >
                            Não realizo esse procedimento
                          </Link>
                        </UBox>
                      </UBox>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
        {loading && <LoadingGeral />}
        <Box
          mt={3}
          hidden={qtdAllDados === allDados.length || loading}
          textAlign="center"
        >
          <Button
            fullWidth
            onClick={handleProxPagina}
            variant="text"
            disabled={loading}
            loading={loading}
            endIcon={<MdKeyboardArrowDown />}
          >
            Carregar mais
          </Button>
        </Box>
        <DrawerPacoteInteligenteVisualizarDetalhes
          id={itemSelecionado?.id}
          open={mostrarDetalhe}
          medico={true}
          selecionado={false}
          handleOnClose={handleFecharDetalhes}
        />
      </UBox>
    </UBox>
  )
}

export default ListagemPacotesSugeridos
