import {
  DiscreteColorLegend,
  HorizontalBarSeries,
  HorizontalGridLines,
  LabelSeries,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis
} from 'react-vis'
import { ItemGenerico } from 'src/api/dashboard'

type BarProps = {
  infos: ItemGenerico[]
  infos2?: ItemGenerico[]
  width: number
  height: number
  vertical?: boolean
  convertido?: boolean
  showLegends?: boolean
}

export default function Bar(props: BarProps) {
  const data = props.infos.map((i, index) => {
    return {
      x: !!props.vertical ? index + 1 : i.valor,
      y: !!props.vertical ? i.valor : index + 1,
      label: i.descricao,
      valor: i.valor,
      convertido: i.convertido
    }
  })

  const dataConvertido = props.infos.map((i, index) => {
    return {
      x: !!props.vertical ? index + 1 : i.convertido,
      y: !!props.vertical ? i.convertido : index + 1,
      label: i.descricao,
      valor: i.convertido,
      convertido: i.convertido
    }
  })

  let data2 = null
  let labelData2 = null
  if (!!props.infos2) {
    data2 = props?.infos2.map((i, index) => {
      return {
        x: !!props.vertical ? index + 1 : i.valor,
        y: !!props.vertical ? i.valor : index + 1,
        label: i.descricao,
        valor: i.valor
      }
    })

    labelData2 = data2.map((d, idx) => ({
      x: d.x,
      y: d.y,
      label: d.valor,
      valor: d.valor,

      style: { fontSize: 12, fontWeight: 'bold' },
      yOffset: !!props.vertical ? 0 : 6,
      xOffset: !!props.vertical ? (data.length / d.x <= 2 ? 22 : 8) : -8
    }))
  }

  const labelData: any[] = data.map((d, idx) => ({
    x: d.x,
    y: d.y,
    label: d.valor,
    valor: d.valor,

    style: { fontSize: 12, fontWeight: 'bold', background: '#888888' },
    yOffset: !!props.vertical ? 0 : 6,
    xOffset: !!props.vertical ? (data.length / d.x <= 2 ? -9 : 20) : -8
  }))

  const labelDataConvertido: any[] = dataConvertido.map((d, idx) => ({
    x: d.x,
    y: d.y,
    label: !props.convertido ? d.valor : `${d.valor}  `,
    valor: d.valor,

    style: { fontSize: 12, fontWeight: 'bold', background: '#888888' },
    yOffset: !!props.vertical ? 0 : 6
    // xOffset: !!props.vertical ? (data.length / d.x <= 2 ? -9 : 20) : -8
  }))

  function getInfo2() {
    if (!!props.infos2) {
      return !!props.vertical ? (
        <VerticalBarSeries barWidth={0.7} data={data2} />
      ) : (
        <HorizontalBarSeries barWidth={0.9} data={data2} />
      )
    }

    return null
  }

  return (
    <>
      {props.showLegends && (
        <DiscreteColorLegend
          orientation="horizontal"
          items={[
            {
              title: 'Ano anterior',
              color: '#12939A',
              strokeWidth: 9
            },
            {
              title: 'Ano atual',
              color: '#79C7E3',
              strokeWidth: 9
            }
          ]}
        />
      )}
      <XYPlot
        width={props.width}
        height={props.height}
        style={{ overflow: 'visible;' }}
        margin={{
          left: !!props.vertical ? 40 : 340
        }}
        yType="linear"
        stackBy={props.convertido ? 'x' : null}
      >
        <VerticalGridLines />
        <HorizontalGridLines />
        {props.convertido && (
          <HorizontalBarSeries
            barWidth={0.9}
            data={dataConvertido}
            color="#44d473"
          />
        )}
        {/* <YAxis tickFormat={a => `${data[a]?.label}`}>teste</YAxis> */}
        {!!props.vertical ? (
          <VerticalBarSeries barWidth={0.7} data={data} />
        ) : props.convertido ? (
          <HorizontalBarSeries barWidth={0.9} data={data} color={'#f59f59'} />
        ) : (
          <HorizontalBarSeries barWidth={0.9} data={data} />
        )}

        {getInfo2()}

        {!!props.vertical ? (
          <XAxis
            tickFormat={v => data[v - 1]?.label}
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            tickTotal={data.length}
          />
        ) : (
          <XAxis />
        )}
        {!!props.vertical ? (
          <YAxis />
        ) : (
          <YAxis
            tickFormat={v => data[v - 1]?.label}
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            tickTotal={data.length}
          />
        )}
        {props.convertido && (
          <LabelSeries
            allowOffsetToBeReversed
            data={labelDataConvertido}
            stroke={2}
          />
        )}
        <LabelSeries allowOffsetToBeReversed data={labelData} stroke={2} />

        <LabelSeries data={labelData2} stroke={2} />
      </XYPlot>
    </>
  )
}
