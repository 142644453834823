import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import { GoPackage } from 'react-icons/go'

import { useHistory } from 'react-router-dom'
import { SynRoutes } from 'src/core/consts/synRoutes'
import {
  LockOutlined,
  DynamicFeed,
  People,
  BusinessCenter,
  SdCardOutlined
} from '@material-ui/icons'
import { FaStethoscope } from 'react-icons/fa'
import useAuth from 'src/hooks/useAuth'
import Ubox from 'src/components/ui/Ubox'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { RiAlertFill } from 'react-icons/ri'

function menuLateral() {
  const { perfilAtual } = useAuth()
  const history = useHistory()

  return (
    <List>
      <Ubox render={perfilAtual !== SynPerfis.AGENDAMENTO}>
        <ListItem button onClick={() => history.push(SynRoutes.listagemMedicos)}>
          <ListItemIcon>
            <FaStethoscope style={{ fontSize: '1.16rem', marginLeft: '2px' }} />
          </ListItemIcon>
          <ListItemText primary="Médicos" />
        </ListItem>
      </Ubox>

      <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemOrcamentosHospital)}
      >
        <ListItemIcon>
          <AssignmentOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Solicitações" />
      </ListItem>

      <Ubox render={perfilAtual !== SynPerfis.AGENDAMENTO}>
        <ListItem
          button
          onClick={() => history.push(SynRoutes.listagemSolicitacoesPendencias)}
        >
          <ListItemIcon>
            <RiAlertFill size={22} />
          </ListItemIcon>
          <ListItemText primary="Pendências" />
        </ListItem>
      </Ubox>

      <Ubox render={perfilAtual !== SynPerfis.AGENDAMENTO}>
        <ListItem
          button
          onClick={() => history.push(SynRoutes.listagemParceirosOperacional)}
        >
          <ListItemIcon>
            <BusinessCenter />
          </ListItemIcon>
          <ListItemText primary="Fornecedores de OPME" />
        </ListItem>
      </Ubox>
      {/* <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemPacotesHospital)}
      >
        <ListItemIcon style={{ fontSize: '1.4rem' }}>
          <GoPackage />
        </ListItemIcon>
        <ListItemText primary="Pacotes" />
      </ListItem>
      <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemOrcamentosOperacional)}
      >
        <ListItemIcon>
          <AssignmentOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Solicitações" />
      </ListItem> */}

      <ListItem button onClick={() => history.push(SynRoutes.alterarSenha)}>
        <ListItemIcon>
          <LockOutlined />
        </ListItemIcon>
        <ListItemText primary="Alterar senha" />
      </ListItem>

      <ListItem button onClick={() => history.push(SynRoutes.termos)}>
        <ListItemIcon>
          <SdCardOutlined />
        </ListItemIcon>
        <ListItemText primary="Termos" />
      </ListItem>
      {/* <ListItem button onClick={() => history.push(SynRoutes.historicoAtualizacoes)}>
        <ListItemIcon>
          <DynamicFeed />
        </ListItemIcon>
        <ListItemText primary="Atualizações" />
      </ListItem> */}
    </List>
  )
}

export default menuLateral
