import { Card, Typography, Divider, makeStyles, Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { Alert } from '@material-ui/lab'
import React from 'react'
import { BiPlusMedical } from 'react-icons/bi'
import { FcCalendar } from 'react-icons/fc'
import { FiInfo } from 'react-icons/fi'
import HeaderSolicitacao, {
  IPacienteHeader
} from 'src/components/solicitacao/HeaderSolicitacao'
import solicitacaoOrcamento from 'src/components/tables/solicitacaoOrcamento'
import UBox from 'src/components/ui/Ubox'
import { SynPerfis } from 'src/core/consts/synPerfis'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import useAuth from 'src/hooks/useAuth'
import {
  IOrcamentoDetalhadoResponse,
  IOrcamentoLocal,
  IPacoteDetalhadoResponse
} from 'src/types'
// import { Grid } from 'swiper'

const useStylesResumo = makeStyles(theme => ({
  boxResumo: {
    border: '1px solid #eee',
    backgroundColor: '#efefef',
    borderRadius: 4,
    '& .MuiTableCell-root.MuiTableCell-head': {
      padding: theme.spacing(1, 2),
      background: '#f9f9f9',
      color: theme.palette.grey[800]
    },
    '& h3': {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  esconderEditar: {
    '& button': {
      display: 'none'
    }
  },
  fontMarca: {
    fontStyle: 'italic',
    fontWeight: 500,
    fontSize: '0.8rem',
    color: theme.palette.grey[700]
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  },
  Card: {
    padding: '20px',
    marginBottom: '20px',
    border: '1px solid #efefef',
    boxShadow: 'none',
    background: theme.palette.background.paper
  }
}))

type ItemResumoOrcamentoProps = {
  orcamento: IOrcamentoLocal
  solicitacao: IOrcamentoDetalhadoResponse
  paciente: IPacienteHeader
  // pacote: IPacoteDetalhadoResponse
  taxaOpmeProposta: number
  valorTaxaOpmeProposta: number
  isHospital: boolean
  loading: boolean
  mostrarContatoPaciente: boolean
  pacoteProcedimento: IPacoteDetalhadoResponse
  verificaAnestesia: boolean
}

export default function ItemResumoOrcamento({
  orcamento,
  verificaAnestesia,
  pacoteProcedimento,
  solicitacao,
  paciente,
  taxaOpmeProposta,
  valorTaxaOpmeProposta,
  isHospital,
  loading,
  mostrarContatoPaciente
}: ItemResumoOrcamentoProps) {
  const styles = useStylesResumo()
  const { perfilAtual } = useAuth()

  function gerarDescricao(item) {
    let partes = []
    if (!!item.diasUteis) {
      partes.push('em qualquer dia da semana')
    }
    if (!!item.finalDeSemana) {
      partes.push('nos finais de semana')
    }
    if (!!item.centroCirurgicoAmbulatorial) {
      partes.push('no centro cirúrgico ambulatorial')
    }
    if (
      !item.diasUteis &&
      !item.finalDeSemana &&
      !item.centroCirurgicoAmbulatorial
    ) {
      partes.push('em qualquer dia e horário')
    }

    let descricao = 'Para realização '
    if (partes.length === 1) {
      descricao += partes[0] + '.'
    } else if (partes.length === 2) {
      descricao += partes.join(' ') + '.'
    }
    return descricao
  }

  return (
    <Box mt={1}>
      <Card className={styles.Card}>
        {orcamento?.justificativa ? (
          <>
            <Alert severity="error">O orçamento foi recusado</Alert>
            <Typography style={{ margin: '20px 0' }}>
              <strong>Justificativa:</strong> {orcamento?.justificativa}
            </Typography>
          </>
        ) : (
          <>
            {isHospital && (
              <Box mb={1}>
                <Typography variant="h6" color="secondary">
                  <b>{orcamento.nome}</b>
                </Typography>
                {/* <Typography
                  variant="subtitle2"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <b>
                    <small>
                      {orcamento?.centroCirurgicoAmbulatorial === true
                        ? 'Centro cirúrgico ambulatorial'
                        : 'Centro cirúrgico'}
                    </small>
                  </b>
                </Typography> */}

                <Typography
                  variant="subtitle2"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {orcamento?.centroCirurgicoAmbulatorial === true ? (
                    <UBox
                      display="flex"
                      alignItems="center"
                      style={{ gap: '8px' }}
                    >
                      <BiPlusMedical />
                      <b>
                        <small>Centro cirúrgico ambulatorial</small>
                      </b>
                    </UBox>
                  ) : (
                    <UBox
                      display="flex"
                      alignItems="center"
                      style={{ gap: '8px' }}
                    >
                      <BiPlusMedical />
                      <b>
                        <small>Centro cirúrgico</small>
                      </b>
                    </UBox>
                  )}
                </Typography>
                {orcamento?.finalDeSemana && (
                  <Typography
                    variant="subtitle2"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <UBox
                      display="flex"
                      alignItems="center"
                      style={{ gap: '8px' }}
                    >
                      <FcCalendar />
                      <b>
                        <small>Final de semana</small>
                      </b>
                    </UBox>
                  </Typography>
                )}
              </Box>
            )}

            <Typography variant="body2">
              <strong style={{ fontWeight: 600 }}>Valor hospital:</strong>{' '}
              {formatarDinheiro(orcamento?.valorSala)}
            </Typography>
            {!orcamento?.ehAnestesiaEquipeMedica && orcamento?.possuiAnestesia && (
              <Typography variant="body2">
                <strong style={{ fontWeight: 600 }}>Valor anestesia:</strong>{' '}
                {formatarDinheiro(orcamento?.valorAnestesia)}
              </Typography>
            )}
            {!!taxaOpmeProposta && (
              <Typography variant="body2">
                <strong style={{ fontWeight: 600 }}>Taxa de OPME:</strong>{' '}
                {formatarDinheiro(taxaOpmeProposta)
                  .toString()
                  .replace('R$', '')}
                %
              </Typography>
            )}
            {!!valorTaxaOpmeProposta && (
              <Typography variant="body2">
                <strong style={{ fontWeight: 600 }}>
                  Valor da taxa de OPME:
                </strong>{' '}
                {formatarDinheiro(valorTaxaOpmeProposta)}
              </Typography>
            )}
            {orcamento?.valorBolsaSangue != null &&
              orcamento?.valorBolsaSangue >= 0 && (
                <Typography variant="body2">
                  <strong style={{ fontWeight: 600 }}>
                    Valor bolsa de sangue:
                  </strong>{' '}
                  {orcamento?.valorBolsaSangue
                    ? formatarDinheiro(orcamento?.valorBolsaSangue)
                    : 'R$ 0,00'}
                </Typography>
              )}
            {orcamento?.valorReservaSangue != null &&
              orcamento?.valorReservaSangue >= 0 && (
                <Typography variant="body2">
                  <strong style={{ fontWeight: 600 }}>
                    Valor reserva de sangue:
                  </strong>{' '}
                  {orcamento?.valorReservaSangue
                    ? formatarDinheiro(orcamento?.valorReservaSangue)
                    : 'R$ 0,00'}
                </Typography>
              )}
            {orcamento?.valorPreAnestesica != null &&
              orcamento?.valorPreAnestesica > 0 && (
                <Typography variant="body2">
                  <strong style={{ fontWeight: 600 }}>
                    Valor avaliação pré-anestésica:
                  </strong>{' '}
                  {orcamento?.valorPreAnestesica
                    ? formatarDinheiro(orcamento?.valorPreAnestesica)
                    : 'R$ 0,00'}
                </Typography>
              )}
            {orcamento?.valorRiscoCirurgico != null &&
              orcamento?.valorRiscoCirurgico > 0 && (
                <Typography variant="body2">
                  <strong style={{ fontWeight: 600 }}>
                    Valor avaliação risco cirúrgico:
                  </strong>{' '}
                  {orcamento?.valorRiscoCirurgico
                    ? formatarDinheiro(orcamento?.valorRiscoCirurgico)
                    : 'R$ 0,00'}
                </Typography>
              )}
            {orcamento?.observacao && (
              <Typography variant="body2">
                <strong style={{ fontWeight: 600 }}>Observação:</strong>{' '}
                {orcamento?.observacao}
              </Typography>
            )}
            <UBox
              render={
                orcamento?.centroCirurgicoAmbulatorial === false &&
                orcamento?.finalDeSemana === false &&
                orcamento.diasUteis === false
              }
            >
              <Alert
                severity="warning"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '4px 8px'
                }}
                iconMapping={{
                  warning: <FiInfo style={{ color: 'orange' }} />
                }}
              >
                Qualquer dia e horário.
              </Alert>
            </UBox>

            <UBox
              render={
                !!orcamento?.centroCirurgicoAmbulatorial ||
                !!orcamento?.finalDeSemana ||
                !!orcamento?.diasUteis
              }
            >
              <Alert
                severity="warning"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '4px 8px'
                }}
                iconMapping={{
                  warning: <FiInfo style={{ color: 'orange' }} />
                }}
              >
                {gerarDescricao(orcamento)}
                <br />
                {/* {!!orcamento.diasUteis && 'Segunda a sábado até as 12h.'} */}
                {!!orcamento.finalDeSemana &&
                  'Sábado a partir de 12h até domingo 23h59 e feriados.'}
              </Alert>
            </UBox>
          </>
        )}
      </Card>
    </Box>
  )
}
