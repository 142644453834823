import React, { useEffect, useState } from 'react'
import Drawer from 'src/components/ui/Drawer'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { obterOrcamentoPorId } from 'src/api/solicitacao-orcamento'
import { obterPacotePorId, obterPacotePorIdPaciente } from 'src/api/pacote'
import { ResumoOrcamento } from '../medico/solicitacao/resumo'
import { mapToForm } from '../medico/solicitacao/formV2'
import { IPacoteDetalhadoResponse } from '../../../types'

type Props = {
  id: string
  open: boolean
  handleOnClose: () => void
  dadosSensiveis?: boolean
}

function DrawerVisualizarDetalhesParceiro({
  id,
  open,
  handleOnClose,
  dadosSensiveis
}: Props) {
  const [detalheVisualizacao, setDetalheVisualizacao] = useState(null)
  const [pacoteDetalhe, setPacoteDetalhe] = useState(null)
  const [loadingDetalhe, setLoadingDetalhe] = useState(false)

  useEffect(() => {
    if (!id || !open) {
      return
    }

    setDetalheVisualizacao(null)
    setLoadingDetalhe(true)
    obterOrcamentoPorId(id)
      .then(async response => {
        const orcamento = response.data

        setDetalheVisualizacao(mapToForm(orcamento))
        //Carregar pacote
        var data: IPacoteDetalhadoResponse = {} as IPacoteDetalhadoResponse
        if (dadosSensiveis) {
          const response = await obterPacotePorIdPaciente(
            orcamento.pacoteProcedimentoId
          )

          data = response.data
        } else {
          const response = await obterPacotePorId(
            orcamento.pacoteProcedimentoId
          )

          data = response.data
        }

        setPacoteDetalhe(data)
        setLoadingDetalhe(false)
      })
      .catch(e => {
        showNotificationErrorAPI(e)
        handleOnClose()
      })
  }, [open])

  return (
    <Drawer
      loading={loadingDetalhe}
      anchor="right"
      open={open}
      titulo="Dados do orçamento"
      handleOnClose={handleOnClose}
    >
      {!!detalheVisualizacao && (
        <ResumoOrcamento
          orcamento={detalheVisualizacao}
          pacoteSelecionado={pacoteDetalhe}
          dadosSensiveis={dadosSensiveis || false}
        />
      )}
    </Drawer>
  )
}
export default DrawerVisualizarDetalhesParceiro
