import { toast } from 'react-toastify'
import Swal, { SweetAlertIcon } from 'sweetalert2'

export const showNotification = (
  titulo = '',
  msg,
  tipo: SweetAlertIcon = 'info',
  isToast = false
) => {
  if (isToast) {
    toast[tipo](msg)
    return
  }

  Swal.fire(titulo, msg, tipo)
}

export const showNotificationImage = (urlImage, height) => {
  Swal.fire({
    imageUrl: urlImage,
    imageHeight: height
  })
}

export const showNotificationErrorAPI = error => {
  let errorMsg = ''
  try {
    errorMsg = error.response.data.erros.join(', ')
  } catch {
    errorMsg = 'Não foi possível concluir a solicitação'
  }

  Swal.fire('Ops...', errorMsg, 'error')
}

export const showNotificationAvisoAPI = error => {
  let errorMsg = ''
  try {
    errorMsg = error.response.data.erros.join(', ')
  } catch {
    errorMsg = 'Não foi possível concluir a solicitação'
  }

  Swal.fire('Ops...', errorMsg, 'warning')
}
