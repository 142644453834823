import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core'
import { Close, Search } from '@material-ui/icons'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import React, { useEffect, useState } from 'react'
import { MdArrowBack } from 'react-icons/md'
import { listagemProcedimentos } from 'src/api/dominio'
import { removerAcento } from 'src/core/helpers/common'
import useListagem from 'src/hooks/useListagem'
import Button from './Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { AutocompleteRenderOptionState } from '@material-ui/lab'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const useStyles = makeStyles(theme => ({
  main: {
    background: theme.palette.background.paper,
    padding: '20px'
  },
  boxInput: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    background: theme.palette.background.paper,
    zIndex: 10,
    padding: '10px',
    paddingLeft: '20px'
  },
  boxLista: {
    marginTop: '63px',
    height: 'calc(100vh - 63px)',
    width: '100%',
    overflow: 'auto',
    zIndex: 1,
    paddingBottom: '200px'
  },
  boxListaBotao: {
    marginTop: '116px',
    height: 'calc(100vh - 116px)',
    width: '100%',
    overflow: 'auto',
    zIndex: 1,
    paddingBottom: '100px'
  },
  itemLista: {
    '&.MuiListItem-dense': {
      paddingTop: '10px',
      paddingBottom: '10px',
      borderBottom: '1px solid #f4f4f4'
    }
  }
}))

interface IAutocompleteMobileProps {
  options: any[]
  value: any[]
  getOptionLabel: (any) => string
  toggleOpen: () => void
  onChange: (event: any, newValue: any, reason: any, details: any) => void
  onClickAddButton: (string) => void
  showAddButton: boolean
  ehProcedimento?: boolean
  buscarPorTermo?: (event: React.ChangeEvent<HTMLInputElement>) => void
  loading: boolean
  limparTermoBusca?: () => void
  multiple?: boolean
  renderOption?: (
    option: any,
    state?: AutocompleteRenderOptionState
  ) => React.ReactNode
}

export const AutocompleteMobile: React.FC<IAutocompleteMobileProps> = props => {
  const classes = useStyles()
  const {
    options,
    getOptionLabel,
    toggleOpen,
    onClickAddButton,
    onChange,
    ehProcedimento,
    value,
    buscarPorTermo,
    loading,
    limparTermoBusca,
    multiple,
    renderOption
  } = props

  const [termoBusca, setTermoBusca] = useState<string>('')

  function handleTermoBusca(event) {
    if (ehProcedimento) {
      buscarPorTermo(event)
    }
    setTermoBusca(event.target.value)
  }

  function handleLimparTermoBusca() {
    setTermoBusca('')
    if (ehProcedimento) limparTermoBusca()
  }

  function handleVoltar() {
    setTermoBusca('')
    toggleOpen()
    if (ehProcedimento) limparTermoBusca()
  }

  function opcoesFiltradas() {
    return options.filter(o =>
      removerAcento(getOptionLabel(o))
        .toLowerCase()
        .includes(removerAcento(termoBusca.toLowerCase()))
    )
  }

  function handleSelect(option: any) {
    let newValue = !value ? [] : value

    if (!multiple) {
      handleVoltar()
      newValue = option
    } else {
      let ehExclusao = newValue.find(o => o.id === option.id)
      if (ehExclusao) {
        newValue = newValue.filter(o => o.id !== option.id)
      } else {
        newValue.push(option)
      }
    }

    onChange(null, newValue, null, null)
  }

  useEffect(() => {
    return () => {
      handleVoltar()
    }
  }, [])

  return (
    <Box className={classes.main}>
      <Box pl={2} pr={1} className={classes.boxInput}>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <TextField
              variant="outlined"
              size="small"
              margin="normal"
              value={termoBusca}
              onChange={handleTermoBusca}
              fullWidth
              placeholder="Pesquisar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <>
                    {loading && (
                      <InputAdornment position="end">
                        <CircularProgress size="1.5rem" />
                      </InputAdornment>
                    )}
                    {termoBusca.length > 0 && (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          color="inherit"
                          onClick={handleLimparTermoBusca}
                          aria-label="close"
                        >
                          <Close />
                        </IconButton>
                      </InputAdornment>
                    )}
                  </>
                )
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Box pt={1}>
              <Button variant="text" size="large" onClick={handleVoltar}>
                Ok
              </Button>
            </Box>
          </Grid>
        </Grid>
        {props.showAddButton && props.onClickAddButton && (
          <Button
            fullWidth
            variant="text"
            onClick={() => onClickAddButton(termoBusca)}
          >
            Não encontrou? Clique aqui
          </Button>
        )}
      </Box>

      {!!ehProcedimento && options.length === 0 && (
        <Box
          textAlign="center"
          className={classes.boxLista}
          px={5}
          py={props.onClickAddButton ? 3 : 0}
        >
          <Typography variant="body2">
            Informe o nome ou código do procedimento
          </Typography>
        </Box>
      )}

      {loading ? (
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      ) : (
        <List
          className={
            props.showAddButton && props.onClickAddButton
              ? classes.boxListaBotao
              : classes.boxLista
          }
        >
          {opcoesFiltradas().map(option => {
            const labelId = `checkbox-list-label-${option.id}`

            return (
              <ListItem
                className={classes.itemLista}
                key={option.id}
                role={undefined}
                button
                onClick={() => handleSelect(option)}
                dense={true}
              >
                {multiple && (
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={!!value?.find(o => o.id === option.id)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                )}
                {/* <ListItemText id={labelId} primary={getOptionLabel(option)} /> */}
                <ListItemText
                  id={labelId}
                  primary={
                    !!renderOption
                      ? renderOption(option)
                      : getOptionLabel(option)
                  }
                />
              </ListItem>
            )
          })}
        </List>
      )}
    </Box>
  )
}
