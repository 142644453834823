import {
  Grid,
  IconButton,
  TextField,
  Typography,
  Paper,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { FiMinusCircle, FiPlusCircle, FiTrash } from 'react-icons/fi'
import { IAny, ObjetoDescricaoGenerico } from 'src/types'
import { Autocomplete } from '../ui/Autocomplete'
import Button from '../ui/Button'
import AddIcon from '@material-ui/icons/Add'
import { useForm } from 'react-hook-form'
import yup from '../yupCustom'
import { yupResolver } from '@hookform/resolvers/yup'
import NumberFormat from 'react-number-format'

interface ObjetoDescricaoGenericoGambiarra extends ObjetoDescricaoGenerico {
  message?: string
}

interface OPMEForm {
  opme: ObjetoDescricaoGenericoGambiarra
  quantidade: number
  marca: string
  observacao: string
}

interface AutocompleteTableProps extends IAny {
  value: any[]
  name: string
  tableTitle: string
  label: string
  errors: any
  loading: boolean
  options: any[]
  handleValue: (newValue: any) => void
  onClickAddButton: (text: string) => void
  getOptionLabel: (option: any) => any
  getOptionSelected: (option: any) => boolean
  getOptionId: (option: any) => any
}

const useStyles = makeStyles({
  table: {
    // minWidth: 650
    width: '100%',
    '& .MuiTableCell-root.MuiTableCell-body': {
      padding: '8px 0 '
    },
    '& th.MuiTableCell-root.MuiTableCell-body': {
      paddingLeft: '12px '
    },
    '& .MuiTableCell-root.MuiTableCell-head': {
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: '500',
      lineHeight: '1.5rem',
      background: '#efefef',
      padding: '4px 8px'
    },
    '& .MuiIconButton-root ': {
      fontSize: '1rem'
    }
  },
  centerInput: {
    margin: '0 10px',
    '& input.MuiInputBase-input': {
      textAlign: 'center'
    }
  },
  noBorderBottom: {
    '& .MuiTableCell-root': {
      borderBottom: 'none'
    }
  }
})

export default function AutocompleteOPME({
  value,
  name,
  label,
  errors,
  loading,
  options,
  handleValue,
  onClickAddButton,
  ...rest
}: AutocompleteTableProps) {
  const classes = useStyles()

  const [formAddOPME, setFormAddOPME] = useState(false)

  const theme = useTheme()
  const XS = useMediaQuery(theme.breakpoints.down('sm'))

  const schema = yup.object().shape({
    quantidade: yup.number().min(1).required().label('Quantidade'),
    marca: yup.string().label('Marca'),
    observacao: yup.string().label('Observação'),
    opme: yup
      .object()
      .nullable()
      .test('OPME-selecionado', 'Selecione o OPME', opmeValue => {
        if (!opmeValue || !opmeValue.id) return false

        return true
      })
      .test('OPME-ja-escolhido', 'OPME ja foi selecionado', opmeValue => {
        if (!!value.find(i => i.id === opmeValue.id)) return false

        return true
      })
      .label('OPME')
  })

  const {
    register,
    errors: OPMEFormErrors,
    trigger,
    setValue,
    watch,
    clearErrors
  } = useForm<OPMEForm>({
    defaultValues: {
      opme: {},
      quantidade: 1,
      marca: '',
      observacao: ''
    },
    resolver: yupResolver(schema)
  })

  const command = watch()

  useEffect(() => {
    register('quantidade')
  }, [])

  const filteredList = item =>
    value.filter((v, i) => rest.getOptionId(v) !== rest.getOptionId(item))

  const removeItem = item => {
    const filtered = filteredList(item)
    handleValue(filtered)
  }

  const setCount = (item, count: number) => {
    item.quantidade = count
    setItem(item)
  }

  const setItemValue = (item, name, value) => {
    item[name] = value
    setItem(item)
  }

  function setItem(item) {
    value.forEach((v, i) => {
      if (rest.getOptionId(item) === rest.getOptionId(v)) v = item
    })

    handleValue(value)
  }

  const addCount = item => {
    if (Number.isInteger(item.quantidade) && item.quantidade >= 1) {
      item.quantidade++
    } else item.quantidade = 1

    setItem(item)
  }

  const removeCount = item => {
    if (Number.isInteger(item.quantidade) && item.quantidade >= 2) {
      item.quantidade--
    } else item.quantidade = 1

    setItem(item)
  }

  function clearOPMEForm() {
    setValue('opme', {})
    setFormAddOPME(false)
    clearErrors()
  }

  async function submitOPMEForm() {
    const result = await trigger()
    /* eslint-disable no-useless-return */
    if (!result) {
      return
    }

    const novoOpme: any = command.opme

    if (!novoOpme) return
    novoOpme.quantidade = command.quantidade
    novoOpme.marca = command.marca
    novoOpme.observacao = command.observacao

    value.push(novoOpme)
    handleValue(value)

    clearOPMEForm()
  }

  return (
    <>
      {formAddOPME ? (
        <Grid container alignItems="center" spacing={1}>
          <Grid
            item
            xs={12}
            sm={8}
            style={
              !!OPMEFormErrors.quantidade
                ? !!OPMEFormErrors.opme
                    ? null
                    : { marginBottom: '30px' }
                : { marginBottom: '10px' }
            }
          >
            <Autocomplete
              erro={OPMEFormErrors}
              name={name}
              closeOnSelect
              label={label}
              loading={loading}
              options={options}
              value={command.opme || ''}
              getOptionSelected={option => {
                rest.getOptionSelected(option)
              }}
              showAddButton
              addButtonProps={{
                text: 'Não encontrou? Clique aqui'
              }}
              {...register('opme')}
              onClickAddButton={texto => onClickAddButton(texto)}
              getOptionLabel={(option: { descricao: string }) =>
                option.descricao
              }
              onChange={async (event: any, newValue: string | null) => {
                setValue('opme', newValue)
                await trigger('opme')
              }}
            />
            {/*
            <AutocompleteUi
              options={options}
              getOptionLabel={(option: { descricao: string }) =>
                option.descricao
              }
              renderInput={params => (
                <TextField
                  {...params}
                  label="OPME"
                  variant="outlined"
                  error={!!OPMEFormErrors.opme}
                  helperText={OPMEFormErrors.opme?.message}
                />
              )}
              autoComplete
              {...register('opme')}
              onChange={async (event: any, newValue: string | null) => {
                setValue('opme', newValue)
                await trigger('opme')
              }}
              value={command.opme || ''}
            /> */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={
              !!OPMEFormErrors.opme
                ? !!OPMEFormErrors.quantidade
                    ? null
                    : { marginBottom: '20px' }
                : null
            }
          >
            {/* <TextField
              style={{ width: '100%' }}
              error={!!OPMEFormErrors.quantidade}
              helperText={OPMEFormErrors?.quantidade?.message}
              type="tel"
              variant="outlined"
              label="Quantidade"
              inputRef={register}
              name="quantidade"
            /> */}
            <div>
              <NumberFormat
                variant="outlined"
                label="Quantidade"
                name="quantidade"
                onValueChange={values => {
                  setValue('quantidade', values.floatValue, {
                    shouldValidate: true
                  })
                }}
                customInput={TextField}
                defaultValue={1}
                type="tel"
                error={!!OPMEFormErrors.quantidade}
                helperText={OPMEFormErrors?.quantidade?.message}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              style={{ width: '100%' }}
              error={!!OPMEFormErrors.marca}
              helperText={OPMEFormErrors?.marca?.message}
              variant="outlined"
              label="Marca"
              inputRef={register}
              name="marca"
            />
          </Grid>

          <Grid item xs={12} sm={8}>
            <TextField
              style={{ width: '100%' }}
              error={!!OPMEFormErrors.observacao}
              helperText={OPMEFormErrors?.observacao?.message}
              variant="outlined"
              label="Observação"
              inputRef={register}
              name="observacao"
            />
          </Grid>

          <Grid item xs={12} sm={6} style={{ marginTop: '20px' }}>
            <Button
              style={{ width: '100%' }}
              onClick={() => clearOPMEForm()}
              color="default"
            >
              Cancelar
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} style={!XS ? { marginTop: '20px' } : null}>
            <Button
              style={{ width: '100%' }}
              onClick={() => {
                submitOPMEForm()
              }}
            >
              Adicionar
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Button
              style={{ width: '100%' }}
              onClick={() => setFormAddOPME(true)}
            >
              <AddIcon /> Adicionar OPME
            </Button>
          </Grid>
        </Grid>
      )}
      {!!value && !!value.length && (
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={5}>{rest.tableTitle}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {value.map((item, i) => (
                <React.Fragment key={i}>
                  {!XS ? (
                    <>
                      <TableRow className={classes.noBorderBottom}>
                        <TableCell component="th" width="auto" scope="row">
                          <Typography variant="subtitle2">
                            {rest.getOptionLabel(item)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right" width="40px">
                          <IconButton
                            title="Remover item"
                            onClick={() => removeCount(item)}
                          >
                            <FiMinusCircle />
                          </IconButton>
                        </TableCell>
                        <TableCell className={classes.centerInput} width="50px">
                          <TextField
                            data-cy="qtdOpme"
                            key={`${item.id}-${
                              item.quantidade - Math.random()
                            }`}
                            size="small"
                            label="Qtd"
                            defaultValue={item.quantidade}
                            inputProps={{ min: 1, max: 999 }}
                            onChange={e => {
                              try {
                                const number = parseInt(e.target.value)
                                if (!!Number.isInteger(number)) {
                                  setCount(item, number)
                                  return
                                }
                              } catch {}

                              setCount(item, 1)
                            }}
                            variant="outlined"
                            type="tel"
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            title="Adicionar item"
                            onClick={() => addCount(item)}
                          >
                            <FiPlusCircle />
                          </IconButton>
                        </TableCell>
                        <TableCell width="36px">
                          <IconButton
                            className="danger"
                            title="Remover OPME"
                            onClick={() => removeItem(item)}
                          >
                            <FiTrash />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <Typography
                          variant="subtitle2"
                          style={{ width: '200px', margin: '10px 0' }}
                        >
                          {rest.getOptionLabel(item)}
                        </Typography>
                      </TableRow>
                      <TableRow
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          margin: '10px 0'
                        }}
                      >
                        <IconButton
                          title="Remover item"
                          onClick={() => {
                            removeCount(item)
                          }}
                        >
                          <FiMinusCircle />
                        </IconButton>

                        <TextField
                          key={`${item.id}-${item.quantidade - Math.random()}`}
                          size="small"
                          label="Qtd"
                          defaultValue={item.quantidade}
                          inputProps={{ min: 1, max: 999 }}
                          style={{ width: '50px' }}
                          onChange={e => {
                            try {
                              const number = parseInt(e.target.value)
                              if (!!Number.isInteger(number)) {
                                setCount(item, number)
                                return
                              }
                            } catch {}

                            setCount(item, 1)
                          }}
                          variant="outlined"
                          type="tel"
                        />
                        <IconButton
                          title="Adicionar item"
                          onClick={() => addCount(item)}
                        >
                          <FiPlusCircle />
                        </IconButton>
                        <IconButton
                          className="danger"
                          title="Remover OPME"
                          onClick={() => removeItem(item)}
                        >
                          <FiTrash />
                        </IconButton>
                      </TableRow>
                    </>
                  )}
                  <TableRow key={`${i}-input`}>
                    <TableCell colSpan={5} style={{ padding: '0 8px 8px' }}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            hiddenLabel
                            placeholder="Marca"
                            fullWidth
                            variant="outlined"
                            margin="none"
                            onChange={e => {
                              let value = e.target.value
                              setItemValue(item, 'marca', value)
                            }}
                            defaultValue={item.marca}
                            InputLabelProps={{ shrink: true }}
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <TextField
                            hiddenLabel
                            placeholder="Observacao"
                            fullWidth
                            variant="outlined"
                            margin="none"
                            onChange={e => {
                              let value = e.target.value
                              setItemValue(item, 'observacao', value)
                            }}
                            defaultValue={item.observacao}
                            InputLabelProps={{ shrink: true }}
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <br />
      {/* dados:{JSON.stringify(value)} */}
    </>
  )
}
