import {
  Badge,
  Box,
  Checkbox,
  Chip,
  Container,
  FormControlLabel,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { AiOutlineCalendar } from 'react-icons/ai'
import { FiPlus } from 'react-icons/fi'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import { IAtividade } from 'src/contexts/Atividade/context'
import { formatarDataHoraLocal } from 'src/core/formatacoes/formatarData'
import useAtividade from 'src/hooks/useAtividade'
import { ItemListagemSolicitacaoOrcamentoResponse } from 'src/types'
import Button from '../ui/Button'
import UBox from '../ui/Ubox'
import DrawerSalvarAtividade from './drawerSalvarAtividade'
import Tarefa from './Tarefa'

import service from 'src/api/atividade'
import useListagem from 'src/hooks/useListagem'
import useAuth from 'src/hooks/useAuth'
import { MdKeyboardArrowDown } from 'react-icons/md'

const useStyles = makeStyles(theme => ({
  tarefa: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '4px',
    margin: '4px 8px',
    padding: '12px 24px'
  }
}))

interface Props {
  solicitacao?: ItemListagemSolicitacaoOrcamentoResponse
}

function Atividades({ solicitacao }: Props) {
  const [tab, setTab] = React.useState(0)
  const [mostrarDrawer, setMostrarDrawer] = React.useState(false)
  const { perfilAtual } = useAuth()

  let listagemConcluidas = useListagem(9, listagemAtividadesConcluidas)

  function atividadesConcluidas(): IAtividade[] {
    return (listagemConcluidas.allDados || []) as IAtividade[]
  }

  function listagemAtividadesConcluidas(query, primeiraBusca?: boolean) {
    // console.log('teste at: ', query, primeiraBusca)
    // if (!primeiraBusca) setUltimaSolicitacao()
    query['perfilAtual'] = perfilAtual
    if (!!solicitacao) {
      query['solicitacaoOrcamentoId'] = solicitacao.id
    }
    return service.obterAtividadesConcluidas(query)
  }

  const [
    atividadeSelecionada,
    setAtividadeSelecionada
  ] = React.useState<IAtividade>(null)

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  const handleEditar = (atividade: IAtividade) => {
    setAtividadeSelecionada(atividade)
    setMostrarDrawer(true)
  }

  const handleFecharDrawer = () => {
    setAtividadeSelecionada(null)
    setMostrarDrawer(false)
    fnAtualizar()
  }

  const {
    // loading,
    obterQtdePendente,
    obterQtdeFutura,
    atividadesAtrasadas,
    atividadesFuturas,
    atividadesHoje,
    // atividadesConcluidas,
    atualizarListaAtividades
  } = useAtividade()

  const [loading, setLoading] = useState<boolean>(false)

  const fnAtualizar = async (esconderLoading?: boolean) => {
    setLoading(true)
    await atualizarListaAtividades()
    setLoading(false)
  }

  useEffect(() => {
    fnAtualizar()
  }, [])

  useEffect(() => {
    if (tab === 1) {
      listagemConcluidas.carregarListagem({ resetarPaginacao: true })
    }
  }, [tab])

  function filtrarAtividadesSolicitacao(atividades: IAtividade[]) {
    if (!!solicitacao) {
      return atividades.filter(a => a.solicitacao.id === solicitacao.id)
    }

    return atividades
  }

  return (
    <Box pb={4}>
      {loading && <LoadingGeral />}

      {!loading && (
        <Container maxWidth="md" style={{ margin: '0', padding: 0 }}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            // centered
          >
            <Tab
              value={0}
              label={
                <UBox>
                  {obterQtdePendente(solicitacao?.id) > 0 ? (
                    <Badge
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      color="secondary"
                      variant="dot"
                    >
                      Pendentes
                    </Badge>
                  ) : (
                    'Pendentes'
                  )}

                  {/* {obterQtdePendente()} */}
                </UBox>
              }
            />

            <Tab
              value={2}
              label={
                <UBox>
                  {obterQtdeFutura(solicitacao?.id) > 0 ? (
                    <Badge
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      color="secondary"
                      variant="dot"
                    >
                      Futuras
                    </Badge>
                  ) : (
                    'Futuras'
                  )}
                </UBox>
              }
            />

            <Tab value={1} label="Concluídas" />
          </Tabs>
          {/* Pendente */}
          <UBox render={tab === 0} mt={3} mb={2} textAlign="left" my={3}>
            <Typography variant="body2" color="primary">
              {obterQtdePendente(solicitacao?.id) > 1 ||
              obterQtdePendente(solicitacao?.id) === 0
                ? `${obterQtdePendente(solicitacao?.id)} atividades pendentes`
                : '1 atividade pendente'}
            </Typography>

            <UBox
              render={
                !!filtrarAtividadesSolicitacao(atividadesAtrasadas) &&
                filtrarAtividadesSolicitacao(atividadesAtrasadas).length > 0
              }
              mt={3}
              mb={2}
              textAlign="left"
              my={3}
            >
              <Typography variant="body1" color="primary">
                <b>Atrasadas</b>
              </Typography>
              {filtrarAtividadesSolicitacao(atividadesAtrasadas)?.map(
                atividade => (
                  <Tarefa
                    key={atividade.id}
                    atividade={atividade}
                    mostrarHora={false}
                    handleEditar={() => handleEditar(atividade)}
                  />
                )
              )}
            </UBox>

            <UBox mt={3} mb={2} textAlign="left" my={3}>
              <Typography variant="body1" color="primary">
                <b>Planejadas para hoje</b>
              </Typography>
              <UBox
                render={!filtrarAtividadesSolicitacao(atividadesHoje).length}
              >
                Você não possui atividades pendentes para hoje
              </UBox>
              {filtrarAtividadesSolicitacao(atividadesHoje)?.map(
                (atividade, index) => (
                  <Tarefa
                    key={atividade.id}
                    atividade={atividade}
                    mostrarHora
                    handleEditar={handleEditar}
                  />
                )
              )}
            </UBox>
          </UBox>
          {/* Concluida */}
          <UBox render={tab === 1} mt={3} mb={2} textAlign="left" my={3}>
            <UBox
              render={
                !filtrarAtividadesSolicitacao(atividadesConcluidas()).length
              }
            >
              Você ainda não possui atividades concluídas
            </UBox>
            <UBox
              render={
                !!filtrarAtividadesSolicitacao(atividadesConcluidas()) &&
                filtrarAtividadesSolicitacao(atividadesConcluidas()).length > 0
              }
              mt={3}
              mb={2}
              textAlign="left"
              my={3}
            >
              <Typography variant="body1" color="primary">
                <b>Concluídas</b>
              </Typography>
              {filtrarAtividadesSolicitacao(atividadesConcluidas())?.map(
                atividade => (
                  <Tarefa key={atividade.id} atividade={atividade} concluida />
                )
              )}
            </UBox>
            {/* {JSON.stringify(listagemConcluidas?.qtdAllDados)}:
            {JSON.stringify(listagemConcluidas?.allDados.length)} */}
            <Box
              mt={3}
              hidden={
                listagemConcluidas?.qtdAllDados ===
                  listagemConcluidas?.allDados.length || loading
              }
              textAlign="center"
            >
              <Button
                fullWidth
                onClick={listagemConcluidas?.handleProxPagina}
                variant="text"
                disabled={loading}
                loading={loading}
                endIcon={<MdKeyboardArrowDown />}
              >
                Carregar mais
              </Button>
            </Box>
          </UBox>
          {/* Futuras */}
          <UBox render={tab === 2} mt={3} mb={2} textAlign="left" my={3}>
            <Typography variant="body2" color="primary">
              {obterQtdeFutura(solicitacao?.id) > 1 ||
              obterQtdeFutura(solicitacao?.id) === 0
                ? `${obterQtdeFutura(solicitacao?.id)} futuras atividades`
                : '1 futura atividade '}
            </Typography>

            <UBox
              render={
                !!filtrarAtividadesSolicitacao(atividadesFuturas) &&
                filtrarAtividadesSolicitacao(atividadesFuturas).length > 0
              }
              mt={3}
              mb={2}
              textAlign="left"
              my={3}
            >
              <Typography variant="body1" color="primary">
                <b>Futuras</b>
              </Typography>
              {filtrarAtividadesSolicitacao(atividadesFuturas)?.map(
                atividade => (
                  <Tarefa
                    key={atividade.id}
                    atividade={atividade}
                    mostrarHora={false}
                    handleEditar={() => handleEditar(atividade)}
                  />
                )
              )}
            </UBox>
          </UBox>
          <UBox render={!!solicitacao && tab === 0}>
            <Button
              variant="text"
              color="primary"
              onClick={() => setMostrarDrawer(true)}
              startIcon={<FiPlus style={{ color: '#4fd0b9' }} />}
            >
              {' '}
              Nova atividade
            </Button>
          </UBox>
        </Container>
      )}

      {mostrarDrawer && (
        <DrawerSalvarAtividade
          solicitacao={solicitacao}
          open={mostrarDrawer}
          handleOnClose={handleFecharDrawer}
          atividadeId={atividadeSelecionada?.id}
        />
      )}
    </Box>
  )
}

export default Atividades
