import Drawer from 'src/components/ui/Drawer'
import { CircularProgress, Box } from '@material-ui/core'
import { useState } from 'react'
import FormDadosPaciente from '../Form'

interface Props {
  open: boolean
  handleOnClose: (atualizou, id?) => void
  id?: string,
  temCpf?: boolean
}

export default function DrawerDadosPaciente({
  open,
  handleOnClose,
  id,
  temCpf
}: Props) {
  const [loading, setLoading] = useState<boolean>(false)
  return (
    <Drawer
      open={open}
      anchor="right"
      handleOnClose={() => handleOnClose(false)}
      titulo="Dados paciente"
    >
      {loading ? (
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      ) : (
        <FormDadosPaciente id={id} temCpf={temCpf} handleAfterSubmit={handleOnClose} />
      )}
    </Drawer>
  )
}
