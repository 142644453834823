import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { obterMedicosVinculadosSecretaria } from 'src/api/medico'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import Button from 'src/components/ui/Button'
import yup from 'src/components/yupCustom'
import { SynRoutes } from 'src/core/consts/synRoutes'
import useAuth from 'src/hooks/useAuth'
import { ObjetoDescricaoGenerico } from 'src/types'

const useStyles = makeStyles(theme => ({
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    margin: theme.spacing(1, 0, 0)
    // minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  noMargin: {
    margin: theme.spacing(0)
  }
}))
type Props = {
  fecharForm: () => void
}

export default function MedicoAssistente() {
  const classes = useStyles()
  const history = useHistory()
  const { ehTenantSyn } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const [listaMedicos, setListaMedicos] = useState<ObjetoDescricaoGenerico[]>(
    []
  )
  const [
    medicoSelecionado,
    setMedicoSelecionado
  ] = useState<ObjetoDescricaoGenerico>()

  const [loadingMedicos, setLoadingMedicos] = useState<boolean>(true)

  const schema = yup.object().shape({
    id: yup.string().required().label('Medico')
  })

  let defaultValues: ObjetoDescricaoGenerico = {
    id: undefined,
    descricao: ''
  }

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    formState,
    handleSubmit,
    trigger
  } = useForm<ObjetoDescricaoGenerico>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  const command = watch()
  // REGISTRA VALORES REACT FORM HOOKS
  useEffect(() => {
    register('id')
    register('descricao')
  }, [register])
  //#region MOUNT
  useEffect(() => {
    // carregarPacotes()
    carregarMedicosVinculadosSecretaria()
  }, [])
  const carregarMedicosVinculadosSecretaria = async () => {
    return obterMedicosVinculadosSecretaria()
      .then(response => setListaMedicos(response.data || []))
      .finally(() => {
        setLoadingMedicos(false)
      })
  }
  const handleItemValue = (item: ObjetoDescricaoGenerico) => {
    if (item?.id) {
      setMedicoSelecionado(item)
      setValue('id', item.id)
      setValue('descricao', item.descricao)
    } else {
      setMedicoSelecionado(undefined)
      setValue('id', undefined)
      setValue('descricao', '')
    }
  }

  const onSubmit = () => {
    if (!ehTenantSyn) {
      history.push(
        `${SynRoutes.formOrcamentoUnificado2}?medicoSolicitacaoId=${medicoSelecionado?.id}&doc=${medicoSelecionado?.detalhe}`
      )
    } else {
      history.push(
        `${SynRoutes.formOrcamentoUnificado}?medicoSolicitacaoId=${medicoSelecionado?.id}&doc=${medicoSelecionado?.detalhe}`
      )
    }
    setLoadingMedicos(true)
  }

  const handleRegisterObjeto = (name, objetos: ObjetoDescricaoGenerico[]) => {
    setValue(name, objetos, { shouldValidate: true })
  }

  return (
    <>
      <Container component="main" className="fadeInTransition " maxWidth="md">
        <CssBaseline />
        <Grid container justify="space-between">
          {/* <Grid> command: {JSON.stringify(command)}</Grid> */}
          {/* <Grid> command: {JSON.stringify(formulario)}</Grid> */}
        </Grid>
        <Grid item xs={12} justify="flex-start">
          <Box mt={4}>
            <Typography variant="h6">
              Selecione o médico para o qual deseja realizar a solicitação
            </Typography>
          </Box>
        </Grid>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.form}
          noValidate={true}
        >
          <Grid container justify="flex-end" style={{ marginTop: '24px' }}>
            <Grid container spacing={1} direction="row">
              <Grid item xs={12}>
                <Autocomplete
                  erro={errors}
                  name="id"
                  label="Médico"
                  loading={loadingMedicos}
                  closeOnSelect
                  value={command}
                  options={listaMedicos}
                  getOptionLabel={option => `${option.descricao}`}
                  onChange={(event, newValue) => {
                    handleItemValue(newValue as ObjetoDescricaoGenerico)
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                loading={loadingMedicos}
                disabled={loadingMedicos}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  )
}
