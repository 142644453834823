import React, { createContext, useState } from 'react'

interface ContextContent {
  autocompleteMobileOpen: boolean
  toggleAutocompleteMobile: (aberto?: boolean) => void
}

export const MobileContext = createContext<ContextContent>({} as ContextContent)

export default function MobileContextProvider({ children }) {
  const [autocompleteMobileOpen, setAutocompleteMobileOpen] = useState(false)

  function toggleAutocompleteMobile(aberto?: boolean) {
    if (aberto !== null) {
      setAutocompleteMobileOpen(aberto)
      return
    }

    setAutocompleteMobileOpen(!autocompleteMobileOpen)
  }

  return (
    <MobileContext.Provider
      value={{ autocompleteMobileOpen, toggleAutocompleteMobile }}
    >
      {children}
    </MobileContext.Provider>
  )
}
