const SetoresLocalAtendimento = {
  SOLICITACOES: {
    Id: '5d89f15c-4d2e-43ce-8eac-b64a525f2b66',
    Descricao: 'Solicitações'
  },
  COTACAO: {
    Id: '423a6b18-7499-4168-9dff-c1b5842161fd',
    Descricao: 'Cotação'
  },
  TESOURARIA: {
    Id: 'fff64ee2-19b2-4cc1-8abf-b67852f4791d',
    Descricao: 'Tesouraria'
  }
}

export default SetoresLocalAtendimento
