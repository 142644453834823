import React, { useState } from 'react'
import { Box, Typography, Link, makeStyles } from '@material-ui/core'
import useAuth from 'src/hooks/useAuth'
import { RiArrowLeftLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import PasswordField from 'src/components/ui/PasswordField'
import { useForm } from 'react-hook-form'
import { AlterarSenhaCommand } from 'src/types'
import yup from '../../components/yupCustom'
import Swal from 'sweetalert2'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from 'src/components/ui/Button'
import { alterarSenha } from 'src/api/auth'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  submit: {
    margin: theme.spacing(2, 0, 3)
  }
}))

const schema = yup.object().shape({
  senhaAtual: yup.string().min(6).max(50).required().label('Senha atual'),
  novaSenha: yup.string().min(6).required().label('Nova senha'),
  confirmacaoNovaSenha: yup
    .string()
    .min(6)
    .required()
    .label('Confirmação da nova senha')
    .test('passwords-match', 'Senhas estão diferentes', function (value) {
      return this.parent.novaSenha === value
    })
})

export default function AlterarSenha() {
  const { user } = useAuth()
  const history = useHistory()
  const styles = useStyles()

  const [loading, setLoading] = useState(false)

  const {
    errors,
    handleSubmit,
    register,
    watch
  } = useForm<AlterarSenhaCommand>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: { senhaAtual: '', novaSenha: '' }
  })

  const command = watch()

  /* useEffect(() => {
    register('senhaAtual')
    register('novaSenha')
    register('confirmacaoNovaSenha')
  }, []) */

  async function onSubmit(body: AlterarSenhaCommand) {
    try {
      setLoading(true)

      const response = await alterarSenha(body)

      setLoading(false)

      if (response.data) {
        return await Swal.fire({
          title: 'Atenção!',
          text: response.data,
          icon: 'warning',
          confirmButtonText: 'Ok'
        })
      }

      await Swal.fire({
        title: 'Sucesso!',
        text: 'A nova senha foi definida',
        icon: 'success',
        confirmButtonText: 'Ok'
      })

      history.push('/')
    } catch (err) {
      showNotificationErrorAPI(err)
      setLoading(false)
    }
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      className="fadeInTransition syn-container-out"
      style={{ paddingTop: 30 }}
    >
      <Box className="syn-box-out">
        <div className={styles.paper}>
          <Link
            component="button"
            color="secondary"
            onClick={() => history.goBack()}
          >
            <RiArrowLeftLine />
            <Typography
              style={{ marginLeft: 10 }}
              variant="subtitle1"
              component="span"
            >
              Voltar
            </Typography>
          </Link>
          <Box my={3}>
            <Typography variant="h6">Olá, {user?.nome}</Typography>
          </Box>
          <Typography variant="body1">Vamos alterar sua senha!</Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <PasswordField
              erro={errors}
              name="senhaAtual"
              label="Senha atual"
              value={command.senhaAtual}
              inputRef={register}
            />

            <PasswordField
              erro={errors}
              name="novaSenha"
              label="Nova senha"
              value={command.novaSenha}
              inputRef={register}
            />

            <PasswordField
              erro={errors}
              name="confirmacaoNovaSenha"
              label="Confirmar nova senha"
              value={command.confirmacaoNovaSenha}
              inputRef={register}
            />

            <Button
              loading={loading}
              disabled={loading}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={styles.submit}
            >
              Definir senha
            </Button>
          </form>
        </div>
      </Box>
    </Container>
  )
}
