import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Menu,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Search, Share } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import LinkIcon from '@material-ui/icons/Link'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import { Alert } from '@material-ui/lab'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useState, useEffect } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import {
  listagemAnotacoesMedico,
  listagemMedico,
  obterItemListagemPorId,
  obterResumoMedico
} from 'src/api/medico/index'
import Button from 'src/components/ui/Button'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import { renderChipStatusMedico } from 'src/core/consts/statusMedico'
import {
  PerfilPipedrive,
  PERFIS_MEDICOS_PIPEDRIVE,
  renderChipPerfilPipedrive
} from 'src/core/consts/perfilMedicoPipedrive'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useListagem from 'src/hooks/useListagem'
import useWindowInfiniteScroll from 'src/hooks/useWindowInfiniteScroll'
import DrawerDadosBancario from 'src/components/DadosBancario/Drawer'
import ClearIcon from '@material-ui/icons/Clear'
import {
  EspecialidadeResponse,
  ItemAnotacaoMedico,
  ItemListagemMedicoResponse,
  ObjetoDescricaoGenerico,
  ResumoMedicoComAssistente
} from 'src/types'
import DrawerDefinirUf from './drawerDefinirUf'
import DrawerObs from './drawerObs'
import DrawerResumoMedico from './drawerResumoMedico'
import { listarTodasEspecilidades } from 'src/api/especialidade'
import useAuth from 'src/hooks/useAuth'
import { SynPerfis } from 'src/core/consts/synPerfis'
import tipoDadosBancario from 'src/core/consts/tipoDadosBancario'
import estados from 'src/core/consts/estados'
import { FiFilter } from 'react-icons/fi'
import ModalLiberarAcesso from 'src/components/modals/ModalSenhaMaster'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { useHistory } from 'react-router-dom'
import UBox from 'src/components/ui/Ubox'
import { copyTextToClipboard } from 'src/core/helpers/common'
import { toast } from 'react-toastify'

const useStyles = makeStyles(theme => ({
  layoutFiltro: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '4px',
    margin: '4px 8px',
    padding: '12px 24px'
  }
}))
function listagemMedicos() {
  const { perfilAtual, tenantAtual, ehTenantSyn } = useAuth()
  const theme = useTheme()
  const history = useHistory()

  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))
  const style = useStyles()
  const {
    loading,
    dados,
    handleTermoPesquisa,
    termoPesquisa,
    nenhumDado,
    nenhumResultado,
    dadosCarregados,
    carregarListagem,
    allDados,
    qtdAllDados,
    handleProxPagina,
    allDadosCarregados,
    handleFiltroCriadoPorHospital,
    handleCustomPagina,
    pagina,
    filtroCriadoPorHospital,
    filtroGeral,
    loadingPagina,
    setFiltroGeral,
    atualizarItemListagem
  } = useListagem(9, listagem)

  function listagem(query, primeiraBusca?: boolean) {
    query['perfilAtual'] = perfilAtual
    query['perfisPipedrive'] = perfilPipedriveSelecionado?.map(p => p.id) || []

    return listagemMedico(query)
  }

  const [lastSelectedCard, setLastSelectedCard] = useState(null)
  const [mostrarObs, setMostrarObs] = useState(false)
  const [medicoId, setMedicoId] = useState('')
  const [anotacoes, setAnotacoes] = useState<ItemAnotacaoMedico[]>(null)

  const [dadosBancarioId, setDadosBancarioId] = useState('')
  const [mostrarDadosBancario, setMostrarDadosBancario] = useState(false)

  const [mostrarDetalhesMedico, setMostrarDetalhesMedico] = useState(false)
  const [resumoMedico, setResumoMedico] = useState<ResumoMedicoComAssistente>(
    null
  )
  const [loadingResumoMedico, setLoadingResumoMedico] = useState(true)

  const [mostrarDrawerUf, setMostrarDrawerUf] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const [filtroStatus, setFiltroStatus] = useState(' ')
  const [loadingUfs, setLoadingUfs] = useState(false)
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState(null)
  const [perfilPipedriveSelecionado, setPerfilPipedriveSelecionado] = useState(
    null
  )
  const [ufSelecionada, setUFSelecionada] = useState(null)
  const [especialidades, setEspecialidades] = useState<
    EspecialidadeResponse[]
  >()
  const [showFiltros, setShowFiltros] = useState(false)
  const [loadingEspecialidadeFiltro, setLoadingEspecialidadeFiltro] = useState(
    false
  )

  useWindowInfiniteScroll({
    loadMoreFunc: () => handleCustomPagina(pagina + 1),
    data: allDados,
    stopLoadingData: allDados.length >= qtdAllDados
  })

  const handleFiltroStatus = status => {
    setFiltroStatus(status)
    carregarListagem({ resetarPaginacao: true, status })
  }

  function handleOpenObs(item: ItemListagemMedicoResponse) {
    setLastSelectedCard(item)
    setMostrarObs(true)
    setMedicoId(item.id)
    listagemAnotacoesMedico(item.id)
      .then(response => {
        setAnotacoes(response.data.items)
      })
      .catch(err => showNotificationErrorAPI(err))
  }

  function handleCloseObs() {
    setAnotacoes(null)
    setMostrarObs(false)
  }

  function handleOpenDadosBancario(item: ItemListagemMedicoResponse) {
    setLastSelectedCard(item)
    setMedicoId(item.id)
    setDadosBancarioId(item.dadosBancarioId)
    setMostrarDadosBancario(true)
  }

  function handleOpenDetalhes(item: ItemListagemMedicoResponse) {
    setLastSelectedCard(item)
    setLoadingResumoMedico(true)
    setMostrarDetalhesMedico(true)
    setMedicoId(item.id)

    obterResumoMedico(item.id)
      .then(response => {
        setResumoMedico(response.data)
        setLoadingResumoMedico(false)
      })
      .catch(err => {
        showNotificationErrorAPI(err)
        setLoadingResumoMedico(false)
        setMostrarDetalhesMedico(false)
      })
  }

  function handleCloseDetalhes() {
    setResumoMedico(null)
    setMostrarDetalhesMedico(false)
    setMedicoId(null)
  }

  async function handleCloseDadosBancario(atualizou, id?, tipoConta?) {
    setDadosBancarioId(null)
    setMostrarDadosBancario(false)
    setMedicoId(null)
    try {
      if (!!atualizou) {
        const { data } = await obterItemListagemPorId(id)
        atualizarItemListagem(id, data)
      }
    } catch (error) {}
  }

  function handleOpenUf(item: ItemListagemMedicoResponse) {
    setMedicoId(item.id)
    setLastSelectedCard(item)
    setMostrarDrawerUf(true)
  }

  function handleCloseUf() {
    setMostrarDrawerUf(false)
    setMedicoId(null)
  }

  function setClassToHighlight(item) {
    if (item?.id === lastSelectedCard?.id) return 'highlight'

    return null
  }

  function handleChangePerfisPipedrive(
    event,
    newValue: ObjetoDescricaoGenerico[]
  ) {
    var value = newValue.filter(v => !!v.id)
    setPerfilPipedriveSelecionado(newValue)

    setFiltroGeral({
      especialidades: value,
      pagina: 1,
      qtdPorPagina: 9
    })
  }
  useEffect(() => {
    setLoadingEspecialidadeFiltro(true)

    listarTodasEspecilidades()
      .then(response => setEspecialidades(response.data))
      .catch(err => showNotificationErrorAPI(err))
    setLoadingEspecialidadeFiltro(false)
  }, [])

  function handleNovoMedico() {
    history.push(
      `${SynRoutes.formCadastroMedico}?parc=${tenantAtual}&returnUrl=${SynRoutes.listagemMedicos}`
    )

    // history.push(
    //   `${SynRoutes.cadastro}?parc=${tenantAtual}&returnUrl=${SynRoutes.listagemMedicos}`
    // )
  }
  function obterLinkCadastro() {
    return `${document.location.origin}${SynRoutes.cadastro}?t=${tenantAtual}`
  }

  function copiarLink() {
    copyTextToClipboard(obterLinkCadastro())
    toast.success(<>Link copiado com sucesso</>, {
      autoClose: 2000,
      position: 'top-center'
    })
  }

  // function obterNovoLinkCadastro() {
  //   return `${document.location.origin}${SynRoutes.newCadastro}?t=${tenantAtual}`
  // }

  // function copiarNovoLink() {
  //   copyTextToClipboard(obterNovoLinkCadastro())
  //   toast.success(<>Link copiado com sucesso</>, {
  //     autoClose: 2000,
  //     position: 'top-center'
  //   })
  // }

  return (
    <>
      <Box pb={4}>
        <Box mb={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Typography variant="h5">Médicos</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box textAlign="right">
              {/* <Button
                  fullWidth={ehXS}
                  variant="text"
                  color="primary"
                  onClick={copiarNovoLink}
                  startIcon={<LinkIcon />}
                  style={{ marginRight: ehXS ? 0 : '4px' }}
                >
                  novo cadastro
                </Button> */}
                <Button
                  fullWidth={ehXS}
                  variant="text"
                  color="primary"
                  onClick={copiarLink}
                  startIcon={<LinkIcon />}
                  style={{ marginRight: ehXS ? 0 : '4px' }}
                >
                  Copiar link de cadastro
                </Button>
                <Button
                  fullWidth={ehXS}
                  variant="contained"
                  color="primary"
                  onClick={handleNovoMedico}
                  startIcon={<AddIcon />}
                >
                  Cadastrar novo médico
                </Button>
              </Box>
            </Grid>
          </Grid>
          {/* <UBox
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end'
            }}
            textAlign="right"
          >
            <UBox
              style={{
                border: '1px solid #aaa',
                color: '#777',
                borderRadius: '4px',
                // maxWidth: '200px',
                overflow: 'hidden',
                background: '#eee',
                padding: '4px 8px'
              }}
            >
              {obterLinkCadastro()}
            </UBox>

            <Button
              fullWidth={ehXS}
              variant="text"
              color="primary"
              onClick={copiarLink}
              startIcon={<LinkIcon />}
            >
              Copiar link de cadastro do médico
            </Button>
          </UBox> */}
        </Box>

        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                placeholder="Pesquisar por nome, CRM, CPF, local de atendimento etc..."
                size="small"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: loading && (
                    <CircularProgress
                      style={{
                        fontSize: '20px',
                        width: '20px',
                        height: '20px'
                      }}
                    />
                  )
                }}
                value={termoPesquisa}
                onChange={handleTermoPesquisa}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                color="default"
                variant="text"
                onClick={() => setShowFiltros(!showFiltros)}
                startIcon={<FiFilter />}
              >
                {showFiltros ? 'Esconder' : 'Mostrar'} filtros
                {/* <FiFilter style={{ marginLeft: '10px' }} /> */}
                {/* <FilterListIcon style={{ marginLeft: '10px' }} /> */}
              </Button>
            </Grid>
            {showFiltros && (
              <Grid item xs={12} className={style.layoutFiltro}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body2">Filtros</Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Select
                        value={filtroStatus}
                        onChange={e => handleFiltroStatus(e.target.value)}
                      >
                        <MenuItem value=" ">Todos os status</MenuItem>
                        <MenuItem value="0">Cadastro pendente</MenuItem>
                        <MenuItem value="1">Sem pacote cadastrado</MenuItem>
                        <MenuItem value="2">Configurado</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Select
                        value={filtroCriadoPorHospital}
                        onChange={e =>
                          handleFiltroCriadoPorHospital(e.target.value)
                        }
                      >
                        <MenuItem value="0">Todos os médicos</MenuItem>
                        <MenuItem value="1">Criado por hospital</MenuItem>
                        <MenuItem value="2">Auto cadastro</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Select
                        value={filtroGeral?.especialidade || ' '}
                        fullWidth
                        endAdornment={
                          <InputAdornment
                            position="end"
                            style={{ marginRight: '16px' }}
                          >
                            {loadingEspecialidadeFiltro ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              <></>
                            )}
                          </InputAdornment>
                        }
                        onChange={e => {
                          setEspecialidadeSelecionada(e.target.value)
                          setFiltroGeral({
                            especialidade: e.target.value,
                            uf: ufSelecionada == null ? '' : ufSelecionada,
                            pagina: 1,
                            qtdPorPagina: 9
                          })
                        }}
                      >
                        <MenuItem value=" ">Especialidade</MenuItem>
                        {especialidades?.map((especialidade, i) => (
                          <MenuItem key={i} value={especialidade.id}>
                            {especialidade.descricao}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {ehTenantSyn && perfilAtual !== SynPerfis.COMERCIAL_EXPANSAO && (
                    <Grid item xs={12} md={2}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <Select
                          value={filtroGeral?.uf || ' '}
                          fullWidth
                          endAdornment={
                            <InputAdornment
                              position="end"
                              style={{ marginRight: '16px' }}
                            >
                              {loadingEspecialidadeFiltro ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : (
                                <></>
                              )}
                            </InputAdornment>
                          }
                          onChange={e => {
                            setUFSelecionada(e.target.value)
                            setFiltroGeral({
                              uf: e.target.value,
                              especialidade:
                                especialidadeSelecionada == null
                                  ? ''
                                  : especialidadeSelecionada,
                              pagina: 1,
                              qtdPorPagina: 9
                            })
                          }}
                        >
                          <MenuItem value=" ">Ufs</MenuItem>
                          {estados?.map((item, i) => (
                            <MenuItem key={i} value={item.Initials}>
                              {item.Name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {perfilAtual === SynPerfis.OPERACIONAL && (
                    <Grid item xs={12} md={5}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <Autocomplete
                          erro={false}
                          size="small"
                          multiple
                          name="perfisPipedrive"
                          label={'Perfil pipedrive'}
                          value={perfilPipedriveSelecionado || []}
                          getOptionSelected={option =>
                            !!perfilPipedriveSelecionado.find(
                              l => l.id === option.id
                            )
                          }
                          options={PERFIS_MEDICOS_PIPEDRIVE.map(i => {
                            return { id: i, descricao: i }
                          })}
                          showCheck
                          closeOnSelect
                          showAddButton
                          getOptionLabel={option => option.descricao}
                          onChange={handleChangePerfisPipedrive}
                          InputProps={{
                            autoComplete: 'new-password'
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {/* <Grid item xs={12} sm={3}>
                  <Button
                    variant="text"
                    color="inherit"
                    onClick={e => {
                      carregarListagem({
                        resetarPaginacao: true,
                        status: ' ',
                        tag: ' ',
                        statusProposta: ' ',
                        filtro: ' '
                      })
                      handleTermoPesquisa(e as any, true)
                    }}
                  >
                    Limpar filtros
                    <ClearIcon style={{ marginLeft: '10px' }} />
                  </Button>
                </Grid> */}
                </Grid>{' '}
              </Grid>
            )}
          </Grid>
        </Box>

        {!loading && (
          <Box mt={3} mb={2} textAlign="left" my={3}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" color="primary">
                  {qtdAllDados
                    ? qtdAllDados > 1
                      ? `${qtdAllDados} médicos`
                      : '1 médico'
                    : '0 médicos'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}

        {nenhumDado && !nenhumResultado && allDados.length === 0 && (
          <div>Nenhum médico cadastrado ainda.</div>
        )}

        {nenhumResultado && (
          <div>Nenhum resultado encontrado para sua pesquisa.</div>
        )}

        {allDadosCarregados && !loadingPagina && (
          <div className="fadeInTransition">
            <Grid container spacing={2}>
              {allDados.map(item => {
                return (
                  <Grid item xs={12} md={4} key={item.id}>
                    <Card
                      variant="outlined"
                      style={{ height: '100%' }}
                      className={setClassToHighlight(item)}
                    >
                      <CardHeader
                        action={
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-menu"
                          >
                            {popupState => (
                              <React.Fragment>
                                <IconButton
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  aria-label="mais"
                                  {...bindTrigger(popupState)}
                                >
                                  <MoreVertIcon />
                                </IconButton>

                                <Menu
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                  }}
                                  {...bindMenu(popupState)}
                                >
                                  {/* <MenuItem
                                  onClick={() => handleOpenResetarSenha(item)}
                                >
                                  ResetarSenha
                                </MenuItem> */}
                                  <MenuItem
                                    onClick={() => {
                                      popupState.close()
                                      handleOpenDetalhes(item)
                                    }}
                                  >
                                    Ver detalhes
                                  </MenuItem>
                                  {(perfilAtual ===
                                    SynPerfis.OPERACIONAL_HOSPITAL ||
                                    perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
                                    perfilAtual === SynPerfis.OPERACIONAL ||
                                    perfilAtual === SynPerfis.COMERCIAL_B2B ||
                                    perfilAtual ===
                                      SynPerfis.COMERCIAL_MEDICOS) && (
                                    <MenuItem
                                      onClick={() => {
                                        popupState.close()
                                        handleOpenDadosBancario(item)
                                      }}
                                    >
                                      Dados bancários
                                    </MenuItem>
                                  )}
                                  {ehTenantSyn && (
                                    <MenuItem
                                      onClick={() => {
                                        popupState.close()
                                        handleOpenUf(item)
                                      }}
                                    >
                                      Definir UFs
                                    </MenuItem>
                                  )}
                                </Menu>
                              </React.Fragment>
                            )}
                          </PopupState>
                        }
                        style={{ paddingBottom: 0 }}
                        title={
                          <Link
                            component="button"
                            variant="body1"
                            color="primary"
                            align="left"
                            onClick={() => handleOpenDetalhes(item)}
                          >
                            {item.nome}
                          </Link>
                        }
                        titleTypographyProps={{ variant: 'subtitle1' }}
                        subheaderTypographyProps={{ variant: 'body2' }}
                        /* subheader={`CPF: ${
                      formatarCPF(item.cpf) || 'Não informado'
                    }`} */
                      />

                      <CardContent style={{ paddingTop: 8, paddingBottom: 16 }}>
                        {/* <Box>
                        <Typography variant="body2">
                          {`CRM: ${item.crm}`}
                        </Typography>
                      </Box> */}

                        <Box>
                          <Typography variant="body2">
                            {`Telefone: ${formatarTelefone(item.telefone)}`}
                          </Typography>
                        </Box>

                        <Box>
                          <Typography variant="body2">
                            {`Email: ${item.email}`}
                          </Typography>
                        </Box>

                        <Box mt={1}>
                          <Typography variant="body2">
                            Especialidades:{' '}
                            {item.especialidades?.map(e => e).join(', ')}
                          </Typography>
                        </Box>
                        {![
                          SynPerfis.ADMIN_HOSPITAL,
                          SynPerfis.OPERACIONAL_HOSPITAL
                        ].includes(perfilAtual) && (
                          <>
                            {item?.perfilPipedrive?.length > 0 ? (
                              <Box mt={1} key={item?.perfilPipedrive}>
                                <Typography variant="body2">
                                  Perfil pipedrive:{' '}
                                  {renderChipPerfilPipedrive(
                                    item.perfilPipedrive
                                  )}
                                </Typography>
                              </Box>
                            ) : (
                              ''
                            )}
                            <Box mt={1}>
                              <Typography variant="body2">
                                {renderChipStatusMedico(item.status)}
                              </Typography>
                            </Box>
                          </>
                        )}

                        {item.dadosBancarioId && (
                          <Box mt={1}>
                            <Typography variant="body2">
                              Dados bancários configurado
                            </Typography>
                          </Box>
                        )}

                        {![
                          SynPerfis.ADMIN_HOSPITAL,
                          SynPerfis.OPERACIONAL_HOSPITAL
                        ].includes(perfilAtual) && (
                          <Box mt={1}>
                            <Link
                              component="button"
                              variant="body1"
                              color="primary"
                              align="left"
                              onClick={() => handleOpenObs(item)}
                            >
                              {`${item.qtdAnotacoes || '0'} anotações`}
                            </Link>
                          </Box>
                        )}
                      </CardContent>
                      {item.parceiroNome && (
                        <Alert severity="info" style={{ marginTop: '20px' }}>
                          {item.parceiroNome}
                        </Alert>
                      )}
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
          </div>
        )}

        {loading && <LoadingGeral />}

        <Box
          mt={3}
          hidden={qtdAllDados === allDados.length || loading}
          textAlign="center"
        >
          <Button
            fullWidth
            onClick={handleProxPagina}
            variant="text"
            disabled={loading}
            loading={loading}
            endIcon={<MdKeyboardArrowDown />}
          >
            Carregar mais
          </Button>
        </Box>

        <DrawerObs
          medicoId={medicoId}
          open={mostrarObs}
          handleOnClose={handleCloseObs}
          anotacoes={anotacoes || undefined}
          carregarListagem={carregarListagem}
        />
        <DrawerResumoMedico
          open={mostrarDetalhesMedico}
          handleOnClose={handleCloseDetalhes}
          resumoMedico={resumoMedico}
          loading={loadingResumoMedico}
        />
        {mostrarDadosBancario && (
          <DrawerDadosBancario
            open={mostrarDadosBancario}
            handleOnClose={handleCloseDadosBancario}
            id={dadosBancarioId}
            medicoId={medicoId}
            tipoConta={tipoDadosBancario.Medico}
          />
        )}

        {medicoId && mostrarDrawerUf && (
          <DrawerDefinirUf
            open={mostrarDrawerUf}
            medicoId={medicoId}
            handleOnClose={handleCloseUf}
          />
        )}
      </Box>
    </>
  )
}

export default listagemMedicos
