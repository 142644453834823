import { AxiosResponse } from 'axios'
import api from 'src/services/api'
import {
  EnviarPropostaCommand,
  DadosPropostaMedico,
  IHistoricoPropostas,
  RecusarPropostaCommand,
  AprovarPropostaCommand,
  ResumoPropostaResponse,
  MarcarPropostaVencedoraCommand,
  ITaxaResponse,
  CriarTaxaSynPersonalizadaCommand,
  TaxaSynPersonalizadaResponse,
  ISimulacaoFinanciamento,
  PendenciasCommand
} from 'src/types'

export const enviarProposta: (
  command: EnviarPropostaCommand
) => Promise<AxiosResponse<string>> = command => {
  try {
    //Se for uma proposta sem OPME envia lista opme vazia
    command = {
      ...command,
      listaOrcamentoOpmeSelecionado: command.propostaSemOPME
        ? []
        : command.listaOrcamentoOpmeSelecionado
    }

    return api.post('/proposta', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const recusarProposta: (
  command: RecusarPropostaCommand
) => Promise<AxiosResponse<string>> = command => {
  try {
    return api.post('/proposta/recusar', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const enviarInstrucoesNF: (
  id: string,
  telefone?: string
) => Promise<AxiosResponse<string>> = (id, telefone) => {
  return api.post(`/proposta/${id}/instrucoes-nf`, { telefone })
}

export const aprovarProposta: (
  command: AprovarPropostaCommand
) => Promise<AxiosResponse<string>> = command => {
  try {
    return api.post('/proposta/aprovar', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const enviarParaPaciente: (
  command: AprovarPropostaCommand
) => Promise<AxiosResponse<string>> = command => {
  try {
    return api.post('/proposta/EnviarParaPaciente', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const aprovadoPeloPaciente: (
  command: AprovarPropostaCommand
) => Promise<AxiosResponse<string>> = command => {
  try {
    return api.post('/proposta/AprovadoPeloPaciente', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const recusadoPeloPaciente: (
  command: RecusarPropostaCommand
) => Promise<AxiosResponse<string>> = command => {
  try {
    return api.post('/proposta/recusadaPeloPaciente', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const aprovarPendencia: (
  solicitacaoId: string,
) => Promise<AxiosResponse<string>> = solicitacaoId => {
  try {
    return api.post(`/proposta/aprovarPendencias/${solicitacaoId}`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const recusarPendencia: (
  solicitacaoId: string,
) => Promise<AxiosResponse<string>> = solicitacaoId => {
  try {
    return api.post(`/proposta/recusarPendencias/${solicitacaoId}`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const calcularTaxaSyn: ({
  valor: number
}) => Promise<AxiosResponse<ITaxaResponse>> = command => {
  try {
    return api.post('/proposta/taxa', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const criarTaxaSyn: (
  command: CriarTaxaSynPersonalizadaCommand
) => Promise<AxiosResponse<TaxaSynPersonalizadaResponse>> = command => {
  try {
    return api.post('/proposta/taxas', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}
export const criarTaxaOpme: (
  command: CriarTaxaSynPersonalizadaCommand
) => Promise<AxiosResponse<TaxaSynPersonalizadaResponse>> = command => {
  try {
    return api.post('/proposta/taxasOpme', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterDadosPropostaPorIdOperacional: (
  id: string
) => Promise<AxiosResponse<ResumoPropostaResponse>> = id => {
  try {
    return api.get(`/proposta/${id}/operacional`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterDadosPropostaPorIdMedico: (
  id: string
) => Promise<AxiosResponse<ResumoPropostaResponse>> = id => {
  try {
    return api.get(`/proposta/${id}/medico`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterHistoricoPropostas: (
  id: string
) => Promise<AxiosResponse<IHistoricoPropostas>> = id => {
  try {
    return api.get(`/proposta/${id}/historico`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}
export const excluirProposta: (
  id: string,
  idSolicitacao: string,
  perfilAtual: string
) => Promise<AxiosResponse> = (id, idSolicitacao, perfilAtual) => {
  try {
    return api.delete(
      `/proposta/${id}/operacional/${idSolicitacao}/${perfilAtual}`
    )
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}
export const obterHistoricoPropostasPaciente: (
  id: string
) => Promise<AxiosResponse<IHistoricoPropostas>> = id => {
  try {
    return api.get(`/proposta/${id}/historico/paciente`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

/*
export const obterHistoricoPropostasMedico: (
  id: string
) => Promise<AxiosResponse<IHistoricoPropostas>> = id => {
  try {
    return api.get(`/proposta/${id}/historico/medico`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
} */

export const marcarPropostaVencedora: (
  command: MarcarPropostaVencedoraCommand
) => Promise<AxiosResponse<string>> = command => {
  try {
    return api.post('/proposta/MarcarPropostaVencedora', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterResumoFinanciamento: (
  id: string
) => Promise<AxiosResponse<ISimulacaoFinanciamento>> = id => {
  try {
    return api.get(`/proposta/${id}/ResumoPreAnalise`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}
