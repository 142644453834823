import {
  Box,
  Card,
  Chip,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import { BiPlusMedical } from 'react-icons/bi'
import { FcCalendar } from 'react-icons/fc'
import { FiInfo } from 'react-icons/fi'
import { MdEdit } from 'react-icons/md'
import { ValoresInteligentesResponse } from 'src/api/solicitacao-orcamento'
import Button from 'src/components/ui/Button'
import Drawer from 'src/components/ui/Drawer'
import UBox from 'src/components/ui/Ubox'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import informarcaoAlert from 'src/core/informacoes/informacaoAlert'
import useAuth from 'src/hooks/useAuth'

type Props = {
  propostas: ValoresInteligentesResponse[]
  open: boolean
  handleOnClose: () => void
  handleBack: () => void
}

interface PropostaCard {
  item: ValoresInteligentesResponse
}

function PropostaCard({ item }: PropostaCard) {
  return (
    <UBox
      key={item?.internacaoId}
      render={true}
      component={Grid}
      item
      xs={12}
      sm={12}
    >
      <UBox mt={2} mb={2} component={Card}>
        <Box p={2}>
          <Grid container direction="column">
            <Grid item xs={12} style={{ width: '100%', overflowX: 'hidden' }}>
              <UBox maxWidth="90%" mb={2}>
                <Typography
                  variant="subtitle2"
                  style={{ whiteSpace: 'nowrap', textTransform: 'uppercase' }}
                  color="primary"
                >
                  <b>{'Proposta ' + item.descricao}</b>
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {item?.propostaCentroCirurgicoAmbulatorial === true ? (
                    <UBox
                      display="flex"
                      alignItems="center"
                      style={{ gap: '8px' }}
                    >
                      <BiPlusMedical />
                      <b>
                        <small>Centro cirúrgico ambulatorial</small>
                      </b>
                    </UBox>
                  ) : (
                    <UBox
                      display="flex"
                      alignItems="center"
                      style={{ gap: '8px' }}
                    >
                      <BiPlusMedical />
                      <b>
                        <small>Centro cirúrgico</small>
                      </b>
                    </UBox>
                  )}
                </Typography>
                {item?.propostaFinalDeSemana && (
                  <Typography
                    variant="subtitle2"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <UBox
                      display="flex"
                      alignItems="center"
                      style={{ gap: '8px' }}
                    >
                      <FcCalendar />
                      <b>
                        <small>Final de semana</small>
                      </b>
                    </UBox>
                  </Typography>
                )}
              </UBox>

              <Alert
                severity="warning"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: '6px'
                }}
                iconMapping={{
                  warning: <FiInfo style={{ color: 'orange' }} />
                }}
              >
                {informarcaoAlert(item)}
                <br />
                {item.propostaDiasUteis === true &&
                  'Segunda a sábado até as 12h.'}
                {item.propostaFinalDeSemana === true &&
                  'Sábado a partir de 12h até domingo 23h59 e feriados.'}
              </Alert>

              <UBox maxWidth="90%" mt={1}>
                <Typography
                  variant="subtitle2"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <b>
                    <small>Valor total:</small>
                  </b>{' '}
                  {formatarDinheiro(item?.valor)}
                </Typography>
              </UBox>
            </Grid>
          </Grid>
        </Box>
      </UBox>
    </UBox>
  )
}

function DrawerPropostas({
  propostas,
  open,
  handleOnClose,
  handleBack
}: Props) {
  const {
    signOut,
    user,
    perfilAtual,
    ehTenantSyn,
    obterTenantAtual,
    tenantAtual
  } = useAuth()
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))
  const [nomeUsuario, setNomeUsuario] = useState([])

  useEffect(() => {
    setNomeUsuario(user.perfisPorTenant.filter(p => p.tenantId === tenantAtual))
  }, [user])

  return (
    <Drawer
      anchor="right"
      open={open}
      noPadding={ehXS}
      titulo={'Propostas para o ' + nomeUsuario[0]?.descricao + ':'}
      handleOnClose={handleOnClose}
    >
      <Box mt={0} p={2}>
        <UBox maxWidth="100%" mt={1} render={propostas?.length > 2}>
          <Button
            onClick={() => handleBack()}
            fullWidth
            size="small"
            variant="outlined"
          >
            <MdEdit style={{ marginRight: 4 }} />
            Alterar honorários
          </Button>
        </UBox>

        {propostas?.map(item => (
          <PropostaCard key={item?.internacaoId} item={item} />
        ))}

        <UBox maxWidth="100%" mt={1} render={propostas?.length <= 2}>
          <Button
            onClick={() => handleBack()}
            fullWidth
            size="small"
            variant="outlined"
          >
            <MdEdit style={{ marginRight: 4 }} />
            Alterar honorários
          </Button>
        </UBox>
      </Box>
    </Drawer>
  )
}

export default DrawerPropostas
