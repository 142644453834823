/* Página não utilizada */
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Link, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiArrowLeftLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components/ui/Button'
import TextField from 'src/components/ui/TextField'
import yup from '../../components/yupCustom'
import PasswordField from 'src/components/ui/PasswordField'
import { onlyNumber, verificarCPF } from 'src/core/helpers/common'

const useStyles = makeStyles(theme => ({
  inputIcon: { color: theme.palette.grey[500] },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  input: {
    width: '300px'
  },
  formControl: {
    margin: theme.spacing(1, 0, 0)
    // minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  noMargin: {
    margin: theme.spacing(0)
  },
  link: {
    cursor: 'pointer'
  }
}))

const schema = yup.object().shape({
  senha: yup.string().required('Senha obrigatória').min(6).max(50),
  confirmarSenha: yup
    .string()
    .oneOf([yup.ref('senha'), null], 'Senhas devem ser iguais'),
  cpf: yup
    .string()
    .test('cpf-teste', 'CPF inválido', value => {
      if (!value) return false

      return verificarCPF(onlyNumber(value));
    })
    .required()
    .label('CPF')
})

interface IParceiroForm {
  cpf: string
  senha: string
  confirmarSenha: string
}

export default function CriarSenhaParceiro() {
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState<boolean>(false)

  const { register, errors, handleSubmit } = useForm<IParceiroForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      cpf: '',
      senha: '',
      confirmarSenha: ''
    }
  })

  const onSubmit = async (data: IParceiroForm) => {
    console.log(data);
  }

  return (
    <Container
      component="main"
      className="fadeInTransition syn-container"
      maxWidth="md"
    >
      <CssBaseline />
      <Grid container justify="space-between">
        <Grid item xs={6}>
          <Link
            className={classes.link}
            color="secondary"
            onClick={() => history.goBack()}
          >
            <RiArrowLeftLine />
            <Typography
              style={{ marginLeft: 10 }}
              variant="subtitle1"
              component="span"
            >
              Voltar
            </Typography>
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box textAlign="center">
          <img src="/image/logo.png" alt="logo syn saúde" width="60" />
          <Typography variant="h6" component="h2">
            Seja bem-vindo(a) à Syn!
          </Typography>
        </Box>
        <Box textAlign="center">
          <Typography variant="body2">Algum texto?</Typography>
        </Box>
      </Grid>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
        noValidate={true}
      >
        <Grid item xs={12}>
          <Typography
            className={classes.subTitle}
            color="primary"
            display="block"
            variant="caption"
          >
            Dados de acesso
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            erro={errors}
            name="cpf"
            placeholder="Ex: 999.999.999-99"
            label="CPF"
            hiddenLabel
            mask="999.999.999-99"
            inputRef={register}
            className={classes.input}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PasswordField
            inputRef={register}
            erro={errors}
            name="senha"
            label="Senha"
            defaultValue=""
            className={classes.input}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PasswordField
            inputRef={register}
            erro={errors}
            name="confirmarSenha"
            label="Confirme sua senha"
            defaultValue=""
            className={classes.input}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            loading={loading}
            disabled={loading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.input}
          >
            Salvar
          </Button>
        </Grid>
      </form>
    </Container>
  )
}
