import React, { useContext, useState, useEffect } from 'react'
import ModalGenerico from './ModalGenerico'
import { ModalAtualizacoesContext } from '../../contexts/ModalAtualizacoes'
import Button from '../ui/Button'
import { Checkbox, Typography, makeStyles, Box } from '@material-ui/core'
import { ItemAtualizacao } from 'src/types'
import { obterUltimaAtualizacao } from 'src/api/atualizacoes'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import {
  formatarData,
  formatarDataHoraLocal
} from 'src/core/formatacoes/formatarData'

const useStyles = makeStyles({
  modalButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '20px'
  },
  mainDate: {
    color: '#717171',
    margin: '5px 0'
  },
  normalDescription: {
    marginTop: '5px',
    whiteSpace: 'pre-line'
  }
})

export default function ModalAtualizacoes() {
  const styles = useStyles()
  const { aberto, fecharModal } = useContext(ModalAtualizacoesContext)

  const [atualizacao, setAtualizacao] = useState<ItemAtualizacao>()
  const [Loading, setLoading] = useState(false)

  const [verNovamente, setVerNovamente] = useState(true)

  useEffect(() => {
    setLoading(true)

    obterUltimaAtualizacao()
      .then(response => {
        setAtualizacao(response.data)
        setLoading(false)
      })
      .catch(err => {
        showNotificationErrorAPI(err)
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    setVerNovamente(true)
  }, [aberto])

  return (
    <ModalGenerico open={aberto} title="Nova atualização">
      <Box>
        <Box px={2}>
          <Typography variant="body2">
            <strong>Versão atual: {atualizacao?.versao}</strong>
          </Typography>
          <Typography variant="subtitle2" className={styles.mainDate}>
            Lançamento: {formatarDataHoraLocal(atualizacao?.dataPublicacao)}
          </Typography>
          <Typography variant="body1" className={styles.normalDescription}>
            {atualizacao?.descricao}
          </Typography>
        </Box>
        <div className={styles.modalButtons}>
          <Typography variant="body1">
            <Checkbox
              checked={!verNovamente}
              onChange={() => setVerNovamente(!verNovamente)}
            />
            Não quero ver novamente
          </Typography>

          <Button
            style={{ marginLeft: '20px' }}
            onClick={() => fecharModal(verNovamente)}
          >
            Fechar
          </Button>
        </div>
      </Box>
    </ModalGenerico>
  )
}
