import {
  Box,
  Container,
  CssBaseline,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import 'react-credit-cards/es/styles-compiled.css'
import { useHistory } from 'react-router-dom'
import UBox from 'src/components/ui/Ubox'
import { useQuery } from 'src/hooks/useQuery'

const useStyles = makeStyles(theme => ({
  formControl2: {
    top: '16px'
  },
  boxFormaPagamento2: {
    display: 'flex',
    margin: '0 -16px',
    gap: '16px',
    flexWrap: 'nowrap',
    '& .item': {
      border: '2px solid #ecf7ea',
      padding: '15px',
      textAlign: 'center',
      borderRadius: '20px',
      color: '#306025',
      background: '#ecf7ea',
      gap: '8px'
    }
  },
  boxFormaPagamento: {
    display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    margin: '0 -16px',
    gap: '16px',
    flexWrap: 'nowrap',
    '& .item': {
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      boxSizing: 'border-box',

      // width: '180px',
      // maxWidth: '30%',
      gap: '8px',
      minHeight: '84px',
      // background: '#eee',

      border: '1px solid #999',
      color: '#878787',

      borderRadius: '8px',
      cursor: 'pointer',
      textAlign: 'center',
      '&:hover': {
        // width: 'calc(100% - 4px)',
        // border: `2px solid ${theme.palette.secondary.main}`,
        // color: theme.palette.secondary.main
        // color: '#fff'
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      },
      '&.active, .active:hover': {
        // width: 'calc(100% - 4px)',
        background: theme.palette.primary.main,
        color: '#fff',
        // border: '0px solid #333'
        // color: theme.palette.secondary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  [theme.breakpoints.down(1401)]: {
    boxFormaPagamento: {
      flexDirection: 'column',
      '& .item': {
        width: '100%'
      }
    }
  },
  [theme.breakpoints.down('md')]: {
    boxPagamento: {
      // ...padrao,
      width: '100%',
      order: 2
    },
    boxFormaPagamento: {
      flexDirection: 'column',
      '& .item': {
        width: '100%'
      }
    }
  }
}))

export default function financiamentoSucesso() {
  const styles = useStyles()
  const theme = useTheme()
  const query = useQuery()
  const history = useHistory()
  const ehSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Container
        component="main"
        className="fadeInTransition syn-container-out"
        maxWidth="md"
      >
        <CssBaseline />
        <UBox component="form">
          <Box
            className="syn-box-out"
            style={{ width: ehSM ? '100%' : '800px' }}
          >
            <Grid item xs={12}>
              <Box textAlign="center">
                <img src="/image/logo.png" alt="logo syn saúde" width="60" />
                <Box mt={3} mb={4}>
                  <Typography variant="h6" component="h2">
                    Solicitação de financiamento enviada com sucesso.
                  </Typography>
                </Box>
                <UBox>
                  <Alert severity="success">
                    <Typography variant="body2" component="span">
                      Nós recebemos o seu pedido de financiamento. Agora é só
                      aguardar que o nosso time SYN entrará em contato pelo
                      telefone informado.
                    </Typography>
                  </Alert>
                  {/* {JSON.stringify(command)} */}
                </UBox>
                <Box mt={2}>
                  <Alert severity="info">
                    <a
                      href="https://api.whatsapp.com/send?phone=5598992293099&text=Olá preciso saber mais sobre financiamento de procediemento na syn"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography variant="body2" component="span">
                        Tem dúvidas? Clique aqui para falar conosco por whatsApp
                      </Typography>
                    </a>{' '}
                    <Typography variant="body2" component="span">
                      ou ligue (98)9 9229-3099
                    </Typography>
                  </Alert>
                  {/* {JSON.stringify(command)} */}
                </Box>
                <Box mt={2}>
                  <Alert severity="warning">
                    <Typography variant="body2" component="span">
                      Você pode fechar essa página
                    </Typography>
                  </Alert>
                  {/* {JSON.stringify(command)} */}
                </Box>
              </Box>
            </Grid>{' '}
            {/* {JSON.stringify(command)} */}
          </Box>
        </UBox>
      </Container>
    </>
  )
}
