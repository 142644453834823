import { yupResolver } from '@hookform/resolvers/yup'
import {
  AppBar,
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import { Button } from 'src/components/ui/Button'
import TextField from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { adicionarProcedimento, obterEspecialidades } from 'src/api/dominio'
import { AddProcedimentoCommand, ObjetoDescricaoGenerico } from 'src/types'

const useStyles = makeStyles(theme => ({
  [theme.breakpoints.up('sm')]: {
    container: {
      width: '400px'
    }
  }
}))

interface AddProcedimentoForm {
  //especialidade: ObjetoDescricaoGenerico
  descricao: string
  codigo: string
}

const schema = yup.object().shape({
  descricao: yup.string().required().label('Procedimento'),
  //especialidade: yup.object().nullable().required().label('Especialidade'),
  codigo: yup.string().required().label('Código')
})

export function ModalAddProcedimento({
  descricao,
  open,
  handleClose,
  handleValue
}) {
  const classes = useStyles()
  const [especialidades, setEspecialidades] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [loadingEspecialidades, setLoadingEspecialidades] = useState<boolean>(
    false
  )

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    handleSubmit
  } = useForm<AddProcedimentoForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: { descricao: descricao }
  })

  const [loading, setLoading] = useState(false)
  const command = watch()

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    //carregarEspecialidades()
  }, [])

  // useEffect(() => {
  //   register('especialidade')
  // }, [register])

  // const handleItemValue = (name: string, item) => {
  //   console.log(name, item)
  //   setValue(name, item, {
  //     shouldValidate: true
  //   })
  // }

  // const carregarEspecialidades = async () => {
  //   return obterEspecialidades()
  //     .then(response => setEspecialidades(response.data))
  //     .finally(() => {
  //       setLoadingEspecialidades(false)
  //     })
  // }

  const onSubmit = async (data: AddProcedimentoForm) => {
    setLoading(true)

    adicionarProcedimento({
      ...data,
      especialidadeId: undefined
      //especialidadeId: data.especialidade.id
    })
      .then(response => {
        handleValue(response.data)
        handleClose()
      })
      .catch(error => showNotificationErrorAPI(error))
      .finally(() => setLoading(false))
  }

  return (
    <Dialog
      open={true}
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
        {fullScreen && (
          <AppBar style={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
              <Typography style={{ flex: 1 }} variant="h6"></Typography>
              <Button autoFocus variant="text" type="submit" color="inherit">
                Salvar
              </Button>
            </Toolbar>
          </AppBar>
        )}

        <DialogContent>
          <Box mb={2} mt={1}>
            <Typography variant="h6">Adicionar procedimento</Typography>
          </Box>
          {/* <DialogContentText>
            Não encontrou o local de atendimento? Por favor, adicione!
          </DialogContentText> */}
          {/* dados: {JSON.stringify(command)} */}
          <Container maxWidth="xs" className={classes.container}>
            <Grid container>
              {/* <Grid item xs={12}>
                <Autocomplete
                  erro={errors}
                  name="especialidade"
                  label="Especialidade"
                  value={command.especialidade}
                  options={especialidades}
                  loading={loadingEspecialidades}
                  getOptionLabel={option => option.descricao}
                  onChange={(event, newValue) => {
                    handleItemValue(
                      'especialidade',
                      newValue as ObjetoDescricaoGenerico
                    )
                  }}
                />
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  erro={errors}
                  autoFocus
                  margin="normal"
                  name="descricao"
                  label="Procedimento"
                  inputRef={register}
                  type="text"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  erro={errors}
                  autoFocus
                  margin="normal"
                  name="codigo"
                  label="Código principal"
                  inputRef={register}
                  type="text"
                />
              </Grid>
            </Grid>
          </Container>
        </DialogContent>

        {!fullScreen && (
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="default">
              Cancelar
            </Button>
            <Button loading={loading} disabled={loading} type="submit" color="primary">
              Adicionar
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  )
}
