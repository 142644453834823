import React, { useEffect, useState } from 'react'
import Drawer from 'src/components/ui/Drawer'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { obterOrcamentoPorId } from 'src/api/solicitacao-orcamento'
import { obterPacotePorId, obterPacotePorIdPaciente } from 'src/api/pacote'
import { IParceiroFuncionario, ObjetoDescricaoGenerico } from 'src/types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid } from '@material-ui/core'

import { useForm } from 'react-hook-form'
import SynTipoParceiro from 'src/core/consts/synTipoParceiro'

import DrawerVisualizarDetalhes from './drawerVisualizarDetalhes'
import yup from 'src/components/yupCustom'
import { onlyNumber, verificarCPF } from 'src/core/helpers/common'
import TextField from 'src/components/ui/TextField'
import Button from 'src/components/ui/Button'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import { obterSetores } from 'src/api/dominio'
import { salvarUsuario } from 'src/api/usuarios'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

type Props = {
  item: IParceiroFuncionario
  open: boolean
  handleOnClose: () => void
  dadosSensiveis?: boolean
}

const contactSchema = yup.object().shape({
  id: yup.string().nullable(),
  nome: yup.string().required().label('Nome do responsável'),
  celular: yup.string().label('Telefone'),
  email: yup.string().email().required().label('E-mail'),
  cpf: yup.string().test('cpf-teste', 'CPF inválido', value => {
    if (!value) return false

    return verificarCPF(onlyNumber(value))
  }),
  especialidades: yup
    .array()
    .nullable()
    .min(1)
    .label('Setor')
    .test('setores-validacoes', 'Setor inválido', value => {
      if (!value || value?.length <= 0) return false

      return true
    })

    .label('CPF')
})

function DrawerSalvarUsuario({
  item,
  open,
  handleOnClose,
  dadosSensiveis
}: Props) {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [loadingSubmitButton, setLoadingSubmitButton] = useState(false)

  const [setores, setSetores] = useState<ObjetoDescricaoGenerico[]>(null)
  const [loadingSetores, setLoadingSetores] = useState(false)

  const edicao = !!item

  const defaultValuesContato: IParceiroFuncionario = {
    id: item?.id || null,
    nome: item?.nome || '',
    celular: item?.celular || '',
    email: item?.email || '',
    especialidades: item?.especialidades || [],
    cpf: item?.cpf || ''
  }

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    handleSubmit,
    trigger
  } = useForm<IParceiroFuncionario>({
    mode: 'onChange',
    resolver: yupResolver(contactSchema),
    defaultValues: defaultValuesContato
  })

  var command = watch()

  useEffect(() => {
    register('especialidades')
    register('id')
  }, [register])

  useEffect(() => {
    carregarSetores()
  }, [])

  async function carregarSetores() {
    setLoadingSetores(true)
    return obterSetores()
      .then(response => setSetores(response.data))
      .finally(() => {
        setLoadingSetores(false)
      })
  }

  const handleRegisterObjeto = (name, objetos: ObjetoDescricaoGenerico[]) => {
    setValue(name, objetos, { shouldValidate: true })
  }

  const onSubmit = async (data: IParceiroFuncionario) => {
    setLoadingSubmitButton(true)
    var funcionario = JSON.parse(JSON.stringify(data))

    const { celular, cpf, especialidades, ...rest } = funcionario
    const newCelular = onlyNumber(celular)
    const newCpf = onlyNumber(cpf)

    var usuario = {
      ...rest,
      celular: newCelular,
      cpf: newCpf,
      especialidades: especialidades.map(i => i.id)
    }

    salvarUsuario(usuario)
      .then(() => {
        Swal.fire({
          title: 'Parabéns!',
          text: 'O usuário foi salvo com sucesso.',
          icon: 'success',
          confirmButtonText: 'Continuar'
        }).then(() => {
          handleOnClose()
          setLoadingSubmitButton(false)
        })
      })
      .catch(err => {
        showNotificationErrorAPI(err)
        setLoadingSubmitButton(false)
      })
  }

  return (
    <Drawer
      loading={loading}
      anchor="right"
      open={open}
      titulo="Salvar usuário"
      handleOnClose={handleOnClose}
    >
      {/* c:{JSON.stringify(item)} */}
      {/* <br /> */}
      {/* c:{JSON.stringify(errors)}
      <br /> */}
      {/* c:{JSON.stringify(setores)}
      <br /> */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: '100%' }}
        noValidate={true}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              erro={errors}
              name="nome"
              placeholder="Nome..."
              label="Nome"
              inputRef={register}
              control={control}
              value={command.nome}
              InputProps={{
                autoComplete: 'new-password'
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              maskTelCel
              style={{ marginTop: 0 }}
              control={control}
              erro={errors}
              name="celular"
              placeholder="Telefone..."
              label="Telefone"
              InputProps={{
                autoComplete: 'new-password'
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              style={{ marginTop: 0 }}
              erro={errors}
              name="cpf"
              placeholder="CPF..."
              label="CPF"
              mask="999.999.999-99"
              disabled={!!command.id || edicao}
              control={control}
              InputProps={{
                autoComplete: 'new-password'
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ marginTop: 0 }}
              erro={errors}
              name="email"
              placeholder="E-mail..."
              label="E-mail"
              inputRef={register}
              value={command.email}
              control={control}
              InputProps={{
                autoComplete: 'new-password'
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              erro={errors}
              name="especialidades"
              label="Setor"
              value={command.especialidades}
              getOptionSelected={option =>
                !!command.especialidades.find(l => l.id === option.id)
              }
              options={setores || []}
              loading={loadingSetores}
              showCheck
              closeOnSelect
              getOptionLabel={option => option.descricao}
              onChange={(event, newValue: ObjetoDescricaoGenerico[]) => {
                handleRegisterObjeto(
                  'especialidades',
                  newValue as ObjetoDescricaoGenerico[]
                )
              }}
              InputProps={{
                autoComplete: 'new-password'
              }}
              errorMessage={
                !!errors?.especialidades ? 'Deve ter pelo menos 1 setor' : null
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                loading={loadingSubmitButton}
              >
                Salvar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Drawer>
  )
}
export default DrawerSalvarUsuario
