import styled from 'styled-components'

export const Container = styled.div`
  border: none;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: auto;
`

export const Calendar = styled.div`
  width: 60px;
  position: absolute;
  height: 54px;
  right: 1px;
  top: 17px;
  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: none;
  border-left: 1px solid #efe6e6;
  /* border: 1px solid rgba(0, 0, 0, 0.23); */
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const Icon = styled.input`
  height: 56px;
  width: 100%;
  background-color: #e6e6e6;
  border: none;
  opacity: 0;
`
