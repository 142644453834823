import { Box, Typography } from '@material-ui/core'
import Ubox from 'src/components/ui/Ubox'
import ListagemPacotesInteligentes from 'src/pages/dashboard/operacional/pacote/listagemPacotesInteligentes'

function listagemPacoteProcedimento() {
  return (
    <Ubox>
      <Box mb={2}>
        <Typography variant="h5">Pacotes de procedimentos</Typography>
      </Box>
      <ListagemPacotesInteligentes />
    </Ubox>
  )
}

export default listagemPacoteProcedimento
