import { makeStyles } from '@material-ui/core'
import { useEffect } from 'react'
import 'react-credit-cards/es/styles-compiled.css'
import { AiOutlineCheck, AiOutlineRight } from 'react-icons/ai'
import UBox from 'src/components/ui/Ubox'
import usePagamento from 'src/hooks/usePagamento'

const useStyles = makeStyles(theme => ({
  root: {
    // border: '1px solid #eae9e9',
    border: '0',
    background: 'rgb(244 244 244)',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    padding: '10px 16px'
  },
  step: {
    color: '#777',
    lineHeight: '1',
    '&.active': {
      fontWeight: 'bold',
      color: '#474747'
    },
    '& .icon': {
      width: '24px',
      height: '24px',
      minWidth: '24px',
      minHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      background: theme.palette.grey[300]
    },
    '&.done': {
      color: '#25a047',
      fontWeight: 'bold',
      opacity: '0.5',
      cursor: 'pointer',
      '& .icon': {
        color: '#fff',
        background: '#25a047'
      }
    }
  }
}))

interface IProps {
  activeStep: number
  handleStep1: () => void
}

function getSteps() {
  return ['Dados pessoais', 'Pagamento']
}

export default function Steps({ activeStep, handleStep1 }: IProps) {
  const classes = useStyles()
  const steps = getSteps()

  // const handleNext = () => {
  //   setActiveStep(prevActiveStep => prevActiveStep + 1)
  // }

  // const handleBack = () => {
  //   setActiveStep(prevActiveStep => prevActiveStep - 1)
  // }

  // const handleReset = () => {
  //   setActiveStep(0)
  // }
  // const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    // loadingPix,
    resumo
    // urlPix,
    // urlPixFracionado,
    // pagamentoPix
  } = usePagamento()

  useEffect(() => {}, [])

  if (!resumo) return <></>

  return (
    <UBox className={classes.root}>
      <div style={{ display: 'flex' }}>
        <UBox
          className={`${classes.step} ${activeStep === 0 ? 'active' : ''} ${
            activeStep > 0 ? 'done' : ''
          }`}
          display="flex"
          alignItems="center"
          onClick={() => activeStep > 0 && handleStep1()}
        >
          <span className="icon">
            {activeStep > 0 ? <AiOutlineCheck /> : '1'}
          </span>
          <UBox className="title" component="span" ml={1}>
            Dados pessoais
          </UBox>
        </UBox>

        <UBox mx={2} style={{ display: 'flex', alignItems: 'center' }}>
          <AiOutlineRight />
        </UBox>
        <UBox
          className={`${classes.step} ${activeStep === 1 ? 'active' : ''}`}
          display="flex"
          alignItems="center"
        >
          <span className="icon">2</span>
          <UBox component="span" className="title" ml={1}>
            Dados de pagamento
          </UBox>
        </UBox>
      </div>
      {/* <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map(label => (
        <Step key={label} style={{ margin: 0, padding: 0 }}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper> */}
    </UBox>
  )
}
