import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiArrowLeftLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { definirSenhaToken } from 'src/api/auth'
import { Button } from 'src/components/ui/Button'
import PasswordField from 'src/components/ui/PasswordField'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import { useQuery } from 'src/hooks/useQuery'
import { DefinirSenhaCommand } from 'src/types'
import Swal from 'sweetalert2'
import yup from '../../components/yupCustom'

const useStyles = makeStyles(theme => ({
  [theme.breakpoints.down('xs')]: {
    container: {
      minHeight: '100vh'
    }
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    margin: theme.spacing(1),
    width: 100
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(2, 0, 3)
  },
  inputIcon: { color: theme.palette.grey[500] }
}))

const schema = yup.object().shape({
  senha: yup.string().min(6).max(50).required().label('Senha'),
  confirmaSenha: yup
    .string()
    .min(6)
    .required()
    .label('Confirmação de senha')
    .test('passwords-match', 'Confirmação está diferente', function (value) {
      return this.parent.senha === value
    })
})

export default function DefinirSenha() {
  const classes = useStyles()
  const { signIn, signed } = useAuth()
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const query = useQuery()

  const {
    register,
    errors,
    control,
    watch,
    handleSubmit
  } = useForm<DefinirSenhaCommand>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: { senha: '' }
  })
  const command = watch()

  const onSubmit = async (data: DefinirSenhaCommand) => {
    try {
      setLoading(true)

      data.token = query.get('token')
      await definirSenhaToken(data)

      setLoading(false)

      await Swal.fire({
        title: 'Sucesso!',
        text: 'A nova senha foi definida',
        icon: 'success',
        confirmButtonText: 'Ok'
      })

      history.replace(SynRoutes.signin)
    } catch (e) {
      showNotificationErrorAPI(e)
      setLoading(false)
    }
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      className="fadeInTransition syn-container"
      style={{ paddingTop: 30 }}
    >
      <CssBaseline />
      <div className={classes.paper}>
        <Link color="secondary" onClick={() => history.goBack()}>
          <RiArrowLeftLine />
          <Typography
            style={{ marginLeft: 10 }}
            variant="subtitle1"
            component="span"
          >
            Voltar
          </Typography>
        </Link>
        <Box my={3}>
          <Typography variant="h6">Definir nova senha</Typography>
        </Box>
        <Typography variant="body1">
          Digite uma nova senha para sua conta.
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <PasswordField
            erro={errors}
            name="senha"
            label="Senha"
            inputRef={register}
          />

          <PasswordField
            erro={errors}
            name="confirmaSenha"
            label="Confirmar senha"
            inputRef={register}
          />

          <Button
            loading={loading}
            disabled={loading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Definir senha
          </Button>
        </form>
      </div>
    </Container>
  )
}
