import {
  Badge,
  createStyles,
  Menu,
  MenuItem,
  MenuProps,
  Tab,
  Tabs,
  Theme,
  Typography,
  withStyles
} from '@material-ui/core'
import React, { useEffect } from 'react'
import LoadingGeral from '../ui/LoadingGeral'
import UBox from '../ui/Ubox'
import { NotificacaoMenuItem } from './menu'
import _notificacoes from 'src/api/notificacao'
import useListagem from 'src/hooks/useListagem'
import useAuth from 'src/hooks/useAuth'
import { INotificacao } from 'src/signalR/Notificacao/notificacao'
import Button from '../ui/Button'
import { useHistory } from 'react-router-dom'
import { SynRoutes } from 'src/core/consts/synRoutes'
import tipoNotificacao from 'src/core/consts/tipoNotificacao'

const AntTabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: '1px solid #e8e8e8'
    },
    indicator: {
      backgroundColor: theme.palette.secondary.main
    }
  })
)(Tabs)

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      '&:hover': {
        // color: '#40a9ff',
        color: theme.palette.primary.main,
        opacity: 1
      },
      '&$selected': {
        // color: '#1890ff',
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightMedium
      },
      '&:focus': {
        color: theme.palette.secondary.main
        // color: '#40a9ff'
      }
    },
    selected: {}
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />)

export interface StyledTabProps {
  label: any
  value?: any
}

export const StyledMenuItem = withStyles(theme => ({
  root: {
    borderBottom: '1px solid #eee',
    '&:last': {
      borderBottom: 'none'
    }
  }
}))(MenuItem)
interface Props {
  ehMobile?: boolean
}
const ResumoNotificacoes = ({ ehMobile = false }: Props) => {
  const [tab, setTab] = React.useState(1)
  const [mostrarLoading, setMostrarLoading] = React.useState(true)

  const { resumoNotificacoes, loadingResumo, atualizarResumo } = useAuth()

  // const todas = useListagem(10, listagem)
  const novas = useListagem(10, listagemNovas)
  const visualizadas = useListagem(10, listagemVisualizadas)

  const history = useHistory()

  // function listagem(query, primeiraBusca?: boolean) {
  //   query['tipo'] = 1
  //   return _notificacoes.listagemNotificacoes(query)
  // }

  function listagemNovas(query, primeiraBusca?: boolean) {
    query['tipo'] = 2
    return _notificacoes.listagemNotificacoes(query)
  }

  function listagemVisualizadas(query, primeiraBusca?: boolean) {
    query['tipo'] = 3
    return _notificacoes.listagemNotificacoes(query)
  }

  async function carregarDados() {
    atualizarResumo()
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  async function verMais() {
    try {
      // if (tab === 0) {
      //   todas.handleProxPagina()
      // }
      if (tab === 1) {
        novas.handleProxPagina()
      } else if (tab === 2) {
        visualizadas.handleProxPagina()
      }
    } catch (error) {}
  }

  async function marcarComoLida(notificacao: INotificacao) {
    try {
      if (!notificacao.visualizado) {
        const response = await _notificacoes.marcarComoLida(notificacao.id)
        carregarDados()
        notificacao.visualizado = true
        novas.atualizarItemListagem(notificacao.id, notificacao)

        novas.carregarListagem({ resetarPaginacao: true, filtro: {} })
        visualizadas.carregarListagem({ resetarPaginacao: true, filtro: {} })

        // novas.carregarListagemAsync({ resetarPaginacao: true })
        // visualizadas.carregarListagemAsync({ resetarPaginacao: true })
        // todas.atualizarItemListagem(notificacao.id, notificacao)
      }
      carregarDados()
      if (notificacao.tipo === tipoNotificacao.SOLICITACAO_FINANCIAMENTO) {
        history.push(SynRoutes.listagemFinanciamentos)
      } else if (notificacao.tipo === tipoNotificacao.SOLICITACAO_EDITADA) {
        history.push(
          `${SynRoutes.listagemOrcamentosOperacional}?codigo=${notificacao.codigo}`
        )
      } else if (notificacao.tipo === tipoNotificacao.NOVA_OCORRENCIA) {
        history.push(
          `${SynRoutes.listagemOrcamentosOperacional}?codigo=${notificacao.codigo}&id=${notificacao.solicitacaoOrcamentoId}`
        )
      }
    } catch (error) {}
  }

  async function marcarTodasComoLida() {
    try {
      if (resumoNotificacoes.qtdNovas > 0) {
        const response = await _notificacoes.marcarTodasComoLida()
        carregarDados()
      }
    } catch (error) {}
  }

  useEffect(() => {
    carregarDados()
  }, [])

  useEffect(() => {
    // if (tab === 0) {
    //   todas.carregarListagemAsync({ resetarPaginacao: true, filtro: {} })
    //   // todas.carregarListagem({ resetarPaginacao: true, filtro: {} })
    // } else
    if (tab === 1) {
      // novas.carregarListagemAsync({ resetarPaginacao: true, filtro: {} })
      novas.carregarListagemAsync({ resetarPaginacao: true, filtro: {} })
    } else if (tab === 2) {
      visualizadas.carregarListagemAsync({ resetarPaginacao: true, filtro: {} })
    }
  }, [tab])

  return (
    <>
      <UBox style={{ width: ehMobile ? '100%' : '420px' }}>
        <UBox
          p={ehMobile ? 1 : 1}
          px={ehMobile ? 2 : 2}
          display="flex"
          justifyContent="space-between"
          alignItems="end"
        >
          <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
            {!ehMobile && 'Notificações'}
          </Typography>

          <Typography
            variant="caption"
            style={{
              fontWeight: 600,
              cursor: 'pointer',
              textDecoration: 'underline'
            }}
            onClick={marcarTodasComoLida}
          >
            marcar todas como lida
          </Typography>
        </UBox>
        <UBox>
          <AntTabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            // centered
          >
            {/* <AntTab
              value={0}
              label={
                <UBox>
                  <Typography
                    variant="caption"
                    style={{
                      fontWeight: 600,
                      cursor: 'pointer'
                    }}
                  >
                    Todas
                    <UBox
                      ml={1}
                      render={!!resumoNotificacoes?.qtdTodas}
                      style={{
                        padding: '2px 6px',
                        background: tab === 0 ? '#1cc3a5' : '#ccc',
                        color: '#fff',
                        borderRadius: '6px',
                        display: 'inline-block'
                      }}
                    >
                      {resumoNotificacoes?.qtdTodas}
                    </UBox>
                  </Typography>
                </UBox>
                //   </Badge>
              }
            /> */}

            <AntTab
              value={1}
              label={
                <UBox>
                  <Typography
                    variant="caption"
                    style={{
                      fontWeight: 600,
                      cursor: 'pointer'
                    }}
                  >
                    Novas
                    <UBox
                      render={!!resumoNotificacoes?.qtdNovas}
                      ml={1}
                      style={{
                        padding: '2px 6px',
                        background: tab === 1 ? '#1cc3a5' : '#ccc',
                        color: '#fff',
                        borderRadius: '6px',
                        display: 'inline-block'
                      }}
                    >
                      {resumoNotificacoes?.qtdNovas}
                    </UBox>
                  </Typography>
                </UBox>
              }
            />
            <AntTab
              value={2}
              label={
                <Typography
                  variant="caption"
                  style={{
                    fontWeight: 600,
                    cursor: 'pointer'
                  }}
                >
                  Visualizadas
                </Typography>
              }
            />
          </AntTabs>
        </UBox>
      </UBox>
      <UBox
        hidden={
          // !todas?.loading &&
          !novas?.loading && !visualizadas?.loading
        }
      >
        {/* <LoadingGeral /> */}
      </UBox>

      {
        // (tab === 0 && !todas?.allDados?.length) ||
        ((tab === 1 && !novas?.allDados?.length) ||
          (tab === 2 && !visualizadas?.allDados?.length)) && (
          <UBox p={2} py={4}>
            Ainda não temos nenhuma nova notificação.
          </UBox>
        )
      }
      {/* {tab === 0 &&
        // !todas?.loading &&
        todas?.allDados?.map((n, i) => (
          <NotificacaoMenuItem
            ehMobile={ehMobile}
            key={i}
            notificacao={n}
            marcarComoLida={() => marcarComoLida(n)}
            showBackground
          />
        ))} */}

      {tab === 1 &&
        // !novas?.loading &&
        novas?.allDados?.map((n, i) => (
          <NotificacaoMenuItem
            key={i}
            ehMobile={ehMobile}
            notificacao={n}
            marcarComoLida={() => marcarComoLida(n)}
            showBackground
          />
        ))}
      {tab === 2 &&
        // !visualizadas?.loading &&
        visualizadas?.allDados?.map((n, i) => (
          <NotificacaoMenuItem
            key={i}
            ehMobile={ehMobile}
            notificacao={n}
            marcarComoLida={() => marcarComoLida(n)}
            showBackground
          />
        ))}
      <StyledMenuItem>
        {/* {todas.qtdPaginas}-{todas.pagina}
        <br />
        {novas.qtdPaginas}-{novas.pagina}
        <br />
        {visualizadas.qtdPaginas}-{visualizadas.pagina}
        <br /> */}
        <Button
          disabled={
            // (tab === 0 && todas.qtdPaginas === todas.pagina) ||
            (tab === 1 && novas.qtdPaginas === novas.pagina) ||
            (tab === 2 && visualizadas.qtdPaginas === visualizadas.pagina)
          }
          secondaryLoading={
            // todas?.loading ||
            novas?.loading || visualizadas?.loading
          }
          loading={
            // todas?.loading ||
            novas?.loading || visualizadas?.loading
          }
          fullWidth
          color="primary"
          variant="contained"
          onClick={verMais}
        >
          Ver mais
        </Button>
      </StyledMenuItem>
    </>
  )
}

export default ResumoNotificacoes
