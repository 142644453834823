import { useTheme, useMediaQuery, DialogActions, Box } from '@material-ui/core'
import { DialogProps } from '@material-ui/core/Dialog'

import { ReactElement, useCallback, useEffect, useState } from 'react'
import ModalGenerico from '../modals/ModalGenerico'
import Cropper from 'react-easy-crop'
import './cropper.css'
import getCropped from './cropImage'
import Button from '../ui/Button'

interface Props extends DialogProps {
  image: string
  children?: ReactElement
  open: boolean
  onClose?: () => void
  title?: string
  actions?: ReactElement
  onCropped: (File) => void
}

export default function ModalCropper(props: Props) {
  const { image, onCropped } = props
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))
  const minZoom = 0.4

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const [zoom, setZoom] = useState(1)

  useEffect(() => {
    setZoom(1)
  }, [props.open])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels)
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  async function getCroppedImg() {
    var imgCropped = await getCropped(image, croppedAreaPixels)
    console.log(imgCropped)
    onCropped(imgCropped)
  }

  return (
    <ModalGenerico
      {...props}
      title={props.title || 'Recortar imagem'}
      fullScreen={ehXS}
      actions={
        <>
          <Button variant="outlined" onClick={props.onClose}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={getCroppedImg}>
            Recortar
          </Button>
        </>
      }
    >
      <div className="box-cropper">
        <div className="crop-container">
          <Cropper
            image={image}
            crop={crop}
            minZoom={minZoom}
            zoom={zoom}
            showGrid={false}
            restrictPosition={false}
            cropSize={{ width: 240, height: 100 }}
            aspect={4 / 3}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className="controls">
          <input
            type="range"
            value={zoom}
            min={minZoom}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={e => {
              setZoom(parseFloat(e.target.value))
            }}
            className="zoom-range"
          />
        </div>
      </div>
    </ModalGenerico>
  )
}
