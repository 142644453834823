import { AxiosResponse } from 'axios'
import api from 'src/services/api'

export interface IAgendamentoForm {
  id?: string
  dataAgendamento?: string
  dataPreAgendamento?: string
  observacao: string
  solicitacaoOrcamentoId: string
}

export interface ICancelamentoAgendamentoForm {
    motivo: string;
}

export const cancelarAgendamento: (
    cancelamento: ICancelamentoAgendamentoForm
) => Promise<AxiosResponse<string>> = cancelamento => {
  return api.post('/agendamento/cancelamento', cancelamento)
}

export const salvarAgendamento: (
    agendamento: IAgendamentoForm
) => Promise<AxiosResponse<string>> = agendamento => {
  return api.post('/agendamento', agendamento)
}
