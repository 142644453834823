import { AxiosResponse } from 'axios'
import api from 'src/services/api'
import { EspecialidadeResponse } from 'src/types'

export function listarTodasEspecilidades(): Promise<
  AxiosResponse<EspecialidadeResponse[]>
> {
  try {
    return api.get('/especialidade')
  } catch (err) {
    console.log('@Error: ', err)
  }
}
