import { AxiosResponse } from 'axios'
import api from 'src/services/api'
import {
  DadosOrcamentoVisualizacaoParceiro,
  ExcluirOrcamentoCommand,
  ItemParceiroResponse,
  RecusarOrcamentoHospitalCommand,
  SalvarOrcamentoHospitalCommand,
  SalvarOrcamentoOpmeCommand
} from 'src/types'

export const salvarOrcamentoOpme: (
  command: SalvarOrcamentoOpmeCommand
) => Promise<AxiosResponse<string>> = command => {
  try {
    return api.post('/orcamento/opme', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const salvarOrcamentoHospital: (
  command: SalvarOrcamentoHospitalCommand
) => Promise<AxiosResponse<string>> = command => {
  try {
    return api.post('/orcamento/hospital', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const parceiroVerProposta: (
  solicitacaoId: string
) => Promise<
  AxiosResponse<DadosOrcamentoVisualizacaoParceiro>
> = solicitacaoId => {
  try {
    return api.get(`/orcamento/${solicitacaoId}/parceiro`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterParceiros: (
  solicitacaoId: string
) => Promise<AxiosResponse<ItemParceiroResponse[]>> = solicitacaoId => {
  try {
    return api.get('/orcamento/parceiros/' + solicitacaoId)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const excluirOrcamentoPorParceiroId: (
  command: ExcluirOrcamentoCommand
) => Promise<AxiosResponse<void>> = command => {
  try {
    return api.delete('/orcamento/parceiro', { params: command })
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const hospitalRecusarOrcamento: (
  command: RecusarOrcamentoHospitalCommand
) => Promise<AxiosResponse> = command => {
  try {
    return api.post('/orcamento/hospital/recusar', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}
