import { AxiosResponse } from 'axios'
import api from 'src/services/api'
import {
  IParceiro,
  ListagemParceirosQuery,
  ListagemResponse,
  ObjetoDescricaoGenerico
} from '../../types'

export const salvarParceiro: (
  parceiro: IParceiro
) => Promise<AxiosResponse<void>> = parceiro => {
  const funcionarios = parceiro.funcionarios.map(f => {
    const especialidadesId = f.especialidades.map(e => e.id)

    return { ...f, especialidades: especialidadesId }
  })

  const body = {
    ...parceiro,
    funcionarios,
    localAtendimentoId: parceiro.localAtendimento?.id,
    taxaOPME: parceiro.taxaOPME ? parseFloat(parceiro.taxaOPME) : null
  }
  try {
    return api.post('/parceiro', body)
  } catch (err) {
    console.log('@Error:', err)
  }
}

export const listarParceiros: (
  query: ListagemParceirosQuery
) => Promise<AxiosResponse<ListagemResponse<IParceiro>>> = query => {
  try {
    return api.get(`/parceiro/listagem?${new URLSearchParams(query)}`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterItemListagemPorId: (
  parceiroId: string
) => Promise<AxiosResponse<IParceiro>> = parceiroId => {
  return api.get(`/parceiro/listagem/${parceiroId}`)
}

export const buscarParceiroPorId: (
  parceiroId: string
) => Promise<AxiosResponse<IParceiro>> = parceiroId => {
  try {
    return api.get(`/parceiro/${parceiroId}`)
  } catch (err) {
    console.log('@Error:', err)
  }
}

export const obterTaxaOpmePorLocalAtendimentoId: (
  localAtendimentoId: string
) => Promise<AxiosResponse<number>> = localAtendimentoId => {
  try {
    return api.post(`/parceiro/${localAtendimentoId}/taxaopme`)
  } catch (err) {
    console.log('@Error:', err)
  }
}

export const obterFornecedoresPorLocalAtendimentoId: (
  localAtendimentoId: string
) => Promise<AxiosResponse<ObjetoDescricaoGenerico[]>> = localAtendimentoId => {
  try {
    return api.post(`/parceiro/${localAtendimentoId}/fornecedoresOpme`)
  } catch (err) {
    console.log('@Error:', err)
  }
}

export const excluirParceiro: (
  parceiroId: string
) => Promise<AxiosResponse<void>> = parceiroId => {
  try {
    return api.delete(`/parceiro/${parceiroId}`)
  } catch (err) {
    console.log('@Error:', err)
  }
}
