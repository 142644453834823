import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import {
  obterResumoPorPropostaId,
  PagamentoBoletoResponse,
  PagamentoResumoResponse,
  realizarPagamentoBoleto,
  realizarPagamentoPix
} from 'src/api/pagamento'
import Button from 'src/components/ui/Button'
import UBox from 'src/components/ui/Ubox'
import yup from 'src/components/yupCustom'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { TIPO_CONTA } from 'src/core/consts/tipoDadosBancario'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import { useQuery } from 'src/hooks/useQuery'
import { Resumo } from './resumo'

const padrao = {
  background: '#fff',
  borderRadius: '8px',
  boxShadow: '1px 1px 8px #ddd'
}
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    gap: '40px'
  },
  boxResumo: {
    ...padrao,
    maxWidth: '500px',
    margin: '24px 0'
  },
  boxPagamento: {
    ...padrao,
    width: 'calc(100% - 400px)'
    // width: '60%'
  },
  boxPagamentoDetalhe: {
    '& .qr-code': {
      maxWidth: '210px'
    },
    '& .box-instructions p': {
      width: 'calc(100% - 48px)'
    },
    '& .icon-instructions': {
      background: theme.palette.secondary.main,
      color: '#fff',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 20,
      marginRight: '8px'
    },
    '& .back-green': {
      background: '#1cc3a5'
      // background: '#1cc332'
    },
    '& .text-green': {
      color: '#1cc3a5'
      // color: '#1cc332',
    }
  },
  boxFormaPagamento: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',

    '& .item': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '180px',
      gap: '8px',
      minHeight: '84px',
      background: '#eee',
      borderRadius: '8px',
      cursor: 'pointer',
      textAlign: 'center',
      // borderBottom: '1px solid #ddd',
      // borderRight: '1px solid #ddd',

      '&.active': {
        background: theme.palette.secondary.main,
        color: '#fff'
      },

      '&:last-child': {
        borderRight: 'none'
      }
    }
  }
}))

export default function ResumoPagamento() {
  const { signOut, user, perfilAtual } = useAuth()
  const styles = useStyles()
  const theme = useTheme()
  const query = useQuery()
  const history = useHistory()

  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))
  const [loading, setLoading] = useState(true)

  const [resumo, setResumo] = useState<PagamentoResumoResponse>(null)

  const propostaId = query.get('p')

  async function obterResumoPagamento() {
    try {
      const { data: resumo } = await obterResumoPorPropostaId(propostaId)

      setResumo(resumo)
    } catch (error) {
      showNotificationErrorAPI(error)
      history.goBack()
    }

    setLoading(false)
  }

  function navegarParaPagamento() {
    const url = `${SynRoutes.pagamento}?p=${propostaId}`
    window.open(url)
  }

  useEffect(() => {
    obterResumoPagamento()
  }, [])

  if (loading) {
    return (
      <Box textAlign="center">
        <CircularProgress />
        <div>Carregando...</div>
      </Box>
    )
  }

  return (
    <UBox>
      <Box mb={2}>
        <Typography variant="h5">Pagamento</Typography>
      </Box>
      <UBox p={2}>
        <Typography variant="h6">
          Legal, você está a poucos passos de realizar sua cirurgia
        </Typography>
        <Typography variant="subtitle1" color="secondary">
          Confirme os dados abaixo e clique em avançar para seguir com o
          pagamento do procedimento.
        </Typography>
        {/* className={styles.boxResumo} */}
        <UBox p={0} mt={2}>
          <Typography variant="h6" color="primary">
            <b style={{ borderBottom: '4px solid #21ebc7' }}>
              {resumo.procedimento}
            </b>
          </Typography>
          <br />

          <Typography variant="subtitle2" color="primary">
            Médico
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Typography variant="body2" component="span">
                <strong>{resumo?.medico}</strong>
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Divider />
          <br />
          <Typography variant="subtitle2" color="primary">
            Local de atendimento
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Typography variant="body2" component="span">
                <strong>{resumo?.localAtendimento}</strong>
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Divider />
          <br />
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="primary">
                Paciente
              </Typography>
              <Typography variant="body2" component="span">
                <strong>{resumo?.paciente.nome}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="subtitle2" color="primary">
                Documento
              </Typography>
              <Typography variant="body2" component="span">
                <strong>{resumo?.paciente.cpf}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="subtitle2" color="primary">
                Idade
              </Typography>
              <Typography variant="body2" component="span">
                <strong>{resumo?.paciente.idade} anos</strong>
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Divider />
          <br />
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle2" color="primary">
                Valor total do procedimento
              </Typography>
              <Typography variant="body2" component="span">
                <strong>{formatarDinheiro(resumo?.valorTotal)}</strong>
              </Typography>
            </Grid>
          </Grid>
        </UBox>
        <UBox mt={4} maxWidth="500px" display="flex" justifyContent="start">
          <Button
            color="secondary"
            // fullWidth
            onClick={() => {
              navegarParaPagamento()
            }}
          >
            Avançar
          </Button>
        </UBox>
        {/* <Resumo resumo={resumo}></Resumo> */}
      </UBox>
    </UBox>
  )
}
