import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

type ButtonStatusOrcamentoProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  loadingStatus?: boolean
}

const useStyles = makeStyles(theme => ({
  botao: {
    color: '#27bea4',
    borderColor: theme.palette.common.white,
    // backgroundColor: '#27bea4',

    fontWeight: 600,
    '& text': {
      color: '#137061'
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}))

function ButtonAlterarStatusOrcamento(props: ButtonStatusOrcamentoProps) {
  const classes = useStyles()
  return (
    <>
      <Box
        borderRadius={8}
        py={1}
        textAlign={'center'}
        style={{
          border: '1px solid #27bea4',
          backgroundColor: 'rgb(220 251 247)'
        }}
      >
        {props.loadingStatus ? (
          <CircularProgress color="inherit" size={25} />
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box mr={1} style={{ color: 'rgb(24 105 91)', fontWeight: 400 }}>
              Procedimento já foi realizado?
            </Box>
            <Button
              variant="text"
              size="small"
              onClick={props.onClick}
              className={classes.botao}
            >
              Sim
            </Button>
          </Box>
        )}
      </Box>
    </>
  )
}

export default ButtonAlterarStatusOrcamento
