export default [
  { Id: 2, Name: 'ACRE', Initials: 'AC' },
  { Id: 3, Name: 'ALAGOAS', Initials: 'AL' },
  { Id: 4, Name: 'AMAPÁ', Initials: 'AP' },
  { Id: 5, Name: 'AMAZONAS', Initials: 'AM' },
  { Id: 6, Name: 'BAHIA', Initials: 'BA' },
  { Id: 7, Name: 'CEARÁ', Initials: 'CE' },
  { Id: 8, Name: 'DISTRITO FEDERAL', Initials: 'DF' },
  { Id: 9, Name: 'ESPÍRITO SANTO', Initials: 'ES' },
  { Id: 10, Name: 'GOIÁS', Initials: 'GO' },
  { Id: 11, Name: 'MARANHÃO', Initials: 'MA' },
  { Id: 12, Name: 'MATO GROSSO', Initials: 'MT' },
  { Id: 13, Name: 'MATO GROSSO DO SUL', Initials: 'MS' },
  { Id: 14, Name: 'MINAS GERAIS', Initials: 'MG' },
  { Id: 15, Name: 'PARÁ', Initials: 'PA' },
  { Id: 16, Name: 'PRAÍBA', Initials: 'PB' },
  { Id: 17, Name: 'PARANÁ', Initials: 'PR' },
  { Id: 18, Name: 'PERNAMBUCO', Initials: 'PE' },
  { Id: 19, Name: 'PIAUÍ', Initials: 'PI' },
  { Id: 20, Name: 'RIO DE JANEIRO', Initials: 'RJ' },
  { Id: 21, Name: 'RIO GRANDE DO NORTE', Initials: 'RN' },
  { Id: 22, Name: 'RIO GRANDE DO SUL', Initials: 'RS' },
  { Id: 23, Name: 'RONDÔNIA', Initials: 'RO' },
  { Id: 24, Name: 'RORAIMA', Initials: 'RR' },
  { Id: 25, Name: 'SÃO PAULO', Initials: 'SP' },
  { Id: 1, Name: 'SANTA CATARINA', Initials: 'SC' },
  { Id: 26, Name: 'SERGIPE', Initials: 'SE' },
  { Id: 27, Name: 'TOCANTINS', Initials: 'TO' }
] as const
