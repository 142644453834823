import {
  Box,
  CircularProgress,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Button from 'src/components/ui/Button'
import Drawer from 'src/components/ui/Drawer'
import {
  showNotification,
  showNotificationErrorAPI
} from 'src/core/helpers/notification'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { verificarCPF, onlyNumber } from 'src/core/helpers/common'

import yup from 'src/components/yupCustom'
import { TextField } from 'src/components/ui/TextField'
import { cadastrarCpf } from 'src/api/paciente'
import Swal from 'sweetalert2'
import Ubox from 'src/components/ui/Ubox'
import { DropzoneArea } from 'material-ui-dropzone'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import TipoAnexo, { TipoAnexoArray } from 'src/core/consts/tipoAnexo'
import {
  anexarComprovante,
  ComprovanteCommand,
  ComprovanteResponse,
  obterComprovantesHospital,
  obterComprovantesOperacional,
  obterComprovantesPorDestinatario
} from 'src/api/solicitacao-orcamento'
import { formatarDataHoraLocal } from 'src/core/formatacoes/formatarData'
import { C } from 'chart.js/dist/chunks/helpers.core'
import { FaFilePdf, FaRegFilePdf } from 'react-icons/fa'
import {
  DestinatarioComprovanteResponse,
  obterRecebedoresComprovantes
} from 'src/api/pagamento'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { AutocompleteRenderOptionState } from '@material-ui/lab'
import useAuth from 'src/hooks/useAuth'

type Props = {
  id: string
  propostaId: string
  open: boolean
  handleOnClose: (boolean?) => void
  carregarListagem?: () => void
}
const btnRed = {
  border: '1px solid red',
  color: 'red'
}

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDropzoneArea-root': {
      minHeight: '200px'
    },
    '& .MuiDropzoneArea-text': {
      marginBottom: 0
    },
    '& .MuiDropzoneArea-text.MuiTypography-h5': {
      fontSize: '1.2rem',
      padding: '20px 40px 10px'
    },
    '& .MuiDropzoneArea-icon': {
      color: 'rgba(100, 100, 100, 0.87)'
    }
  },
  boxLogomarca: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    height: '100px',
    width: '240px',
    background: theme.palette.grey[100],
    border: '1px solid ' + theme.palette.grey[200],
    borderRadius: '2px',
    backgroundRepeat: 'none',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%'
    // cursor: 'pointer'
  }
}))

const schema = yup.object().shape({
  // arquivo: yup.string().nullable().label('Arquivo'),
  // tipo: yup.number().required().nullable().label('Tipo de anexo'),
  destinatario: yup.object().required().nullable().label('Para'),
  observacao: yup.string().nullable().label('Observação')
})

interface IFormAnexo {
  arquivo?: File
  tipo: number
  destinatario: DestinatarioComprovanteResponse
  observacao: string
}

function DrawerAnexos(props: Props) {
  const [loading, setLoading] = useState(false)
  const [comprovantes, setComprovantes] = useState<ComprovanteResponse[]>(null)
  const [recebedores, setRecebedores] = useState<
    DestinatarioComprovanteResponse[]
  >(null)

  const [ehListagem, setListagem] = useState(true)

  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))

  async function obterListagem() {
    try {
      setLoading(true)
      const response = await obterComprovantesOperacional(props.id)
      setComprovantes(response.data)
      setLoading(false)
    } catch (error) {
      showNotificationErrorAPI(error)
    }
  }

  async function obterListagemRecebedores() {
    try {
      setLoading(true)
      const response = await obterRecebedoresComprovantes(props.propostaId)
      setRecebedores(response.data)
      setLoading(false)
    } catch (error) {
      showNotificationErrorAPI(error)
    }
  }

  useEffect(() => {
    obterListagem()
    obterListagemRecebedores()
  }, [props.open])

  return (
    <Drawer
      anchor="right"
      open={props.open}
      titulo="Comprovantes de pagamento"
      handleOnClose={() => props.handleOnClose()}
    >
      {/* {JSON.stringify(recebedores)} */}
      {/* <Ubox hidden={!recebedores}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recebedores?.map(i => (
              <TableRow key={i.nome}>
                <TableCell>{i.nome}</TableCell>
                <TableCell>{formatarDinheiro(i.valor)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Ubox> */}
      <Ubox hidden={!ehListagem}>
        <ListagemComprovante
          addComprovante={() => setListagem(false)}
          loading={loading}
          comprovantes={comprovantes}
          recebedores={recebedores}
        />
      </Ubox>

      <Ubox render={!ehListagem}>
        <FormComprovante
          id={props.id}
          obterListagem={obterListagem}
          recebedores={recebedores}
          handleOnClose={() => {
            setListagem(true)
          }}
        />
      </Ubox>
    </Drawer>
  )
}

export default DrawerAnexos

interface ListagemProps {
  loading: boolean
  comprovantes: ComprovanteResponse[]
  addComprovante: () => void
  recebedores: DestinatarioComprovanteResponse[]
}
function ListagemComprovante({
  comprovantes,
  loading,
  recebedores,
  addComprovante
}: ListagemProps) {
  function obterComprovantesPorDestinatarioId(id: string) {
    if (!comprovantes) return []

    let items = comprovantes?.filter(c => c.destinatarioId === id)

    return items || []
  }
  return (
    <>
      {loading && <CircularProgress />}
      {/* {JSON.stringify(comprovantes)} */}

      <Ubox hidden={loading}>
        <Ubox mt={1} mb={3} display="flex" justifyContent="flex-end">
          <Button
            loading={loading}
            disabled={loading}
            secondaryLoading
            size="small"
            onClick={addComprovante}
          >
            Adicionar comprovante
          </Button>
        </Ubox>

        {recebedores?.map(recebedor => (
          <GrupoComprovante
            key={recebedor.id}
            titulo={'Comprovantes para ' + recebedor.nome}
            comprovantes={obterComprovantesPorDestinatarioId(recebedor.id)}
          />
        ))}
        {/* <GrupoComprovante
          titulo="Comprovantes para equipe Syn"
          comprovantes={obterComprovantesPorTipo(TipoAnexo.SYN.id)}
        />
        <GrupoComprovante
          titulo="Comprovantes para Hospital / Clínica"
          comprovantes={obterComprovantesPorTipo(TipoAnexo.HOSPITAL.id)}
        />

        <GrupoComprovante
          titulo="Comprovantes para Médico"
          comprovantes={obterComprovantesPorTipo(TipoAnexo.MEDICO.id)}
        />

        <GrupoComprovante
          titulo="Comprovantes para Fornecedores OPME"
          comprovantes={obterComprovantesPorTipo(TipoAnexo.OPME.id)}
        />
        <GrupoComprovante
          titulo="Comprovantes para Fornecedores OPME"
          comprovantes={obterComprovantesPorTipo(TipoAnexo..id)}
        /> */}
      </Ubox>
    </>
  )
}

interface PropsForm {
  id: string
  recebedores: DestinatarioComprovanteResponse[]
  handleOnClose: () => void
  obterListagem: () => void
}

function FormComprovante({
  id,
  handleOnClose,
  obterListagem,
  recebedores
}: PropsForm) {
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const [uploading, setUploading] = useState(false)
  const [arquivo, setArquivo] = useState(null)
  const [urlArquivo, setUrlArquivo] = useState('')

  useEffect(() => {
    if (arquivo) {
      const novaUrl = URL.createObjectURL(arquivo)
      setUrlArquivo(novaUrl)
    }
  }, [arquivo])

  const {
    errors,
    watch,
    control,
    setValue,
    register,
    reset,
    handleSubmit
  } = useForm<IFormAnexo>({
    resolver: yupResolver(schema)
  })

  const command = watch()

  async function submit(values: IFormAnexo) {
    if (!arquivo) {
      Swal.fire({
        icon: 'warning',
        title: 'Comprovante é obrigatório!',
        text: 'Informe um arquivo de comprovante válido.',
        confirmButtonText: 'Ok'
      })

      return
    }
    const command: ComprovanteCommand = {
      solicitacaoId: id,
      tipo: values.destinatario.tipo, // values.tipo,
      destinatarioId: values.destinatario.id,
      arquivo: arquivo,
      observacao: values.observacao
    }

    setUploading(true)

    try {
      const response = await anexarComprovante(command)

      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Arquivo anexado com sucesso',
        confirmButtonText: 'Ok'
      })
      obterListagem()
      resetForm()
      handleOnClose()
    } catch (err) {
      showNotificationErrorAPI(err)
    }

    setUploading(false)
  }

  function atualizarArquivo(files: File[]) {
    setArquivo(files[0])
  }

  function registerForm() {
    register('arquivo')
    register('tipo')
    register('destinatario')
  }

  function resetForm() {
    reset()
    registerForm()
  }

  useEffect(() => {
    registerForm()
  }, [])

  return (
    <form onSubmit={handleSubmit(submit)} noValidate={true}>
      {/* {JSON.stringify(command)}
      {JSON.stringify(errors)} */}

      <Ubox>
        <Ubox className={classes.root}>
          <Autocomplete
            erro={errors}
            name="destinatario"
            label="Para"
            value={command.destinatario}
            options={recebedores}
            getOptionLabel={(option: DestinatarioComprovanteResponse) =>
              `${option.nome} (${formatarDinheiro(option?.valor)})`
            }
            renderOption={(
              option: DestinatarioComprovanteResponse,
              state: AutocompleteRenderOptionState
            ) => {
              return (
                <Ubox
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: ehXS ? 'none' : '1px solid #eee',
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ marginLeft: '5px', width: '100%' }}
                  >
                    <strong>{option.nome.toUpperCase()}</strong>
                    <div
                      style={{
                        textTransform: 'capitalize',
                        // margin: '-12px 0 0 ',
                        padding: '0',
                        display: 'flex',

                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                    >
                      <small>
                        {
                          TipoAnexoArray.find(i => i.id === option.tipo)
                            .descricao
                        }
                      </small>

                      <small style={{ marginLeft: '20px' }}>
                        {formatarDinheiro(option?.valor)}
                      </small>
                    </div>
                  </Typography>
                </Ubox>
              )
            }}
            onChange={(event, value) => {
              setValue('destinatario', value, {
                shouldValidate: true
              })
            }}
          />

          <Box>
            <TextField
              erro={errors}
              fullWidth
              showCaracteres={500}
              label="Observação"
              multiline
              rows={3}
              placeholder="Informe mais detalhes sobre esse comprovante..."
              variant="outlined"
              name="observacao"
              value={command.observacao}
              inputProps={{ maxLength: 500 }}
              inputRef={register}
            />
          </Box>

          <Box mt={2}>
            <Typography variant="subtitle2">
              Insira arquivos do tipo JPG, JPEG, PNG ou PDF
            </Typography>
          </Box>
          {}
          <DropzoneArea
            onChange={atualizarArquivo}
            dropzoneText="Clique aqui ou arraste para anexar um comprovante de pagamento."
            acceptedFiles={[
              'image/jpeg, image/png, image/jpg, application/pdf,'
            ]}
            onAlert={(message, variant) =>
              console.log(`${variant}: ${message}`)
            }
            showPreviews={true}
            previewText="Comprovante:"
            showPreviewsInDropzone={false}
            filesLimit={1}
            showAlerts={[]}
            alertSnackbarProps={{
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
            }}
            onDrop={e => {
              // showNotification('Logomarca!', 'Arquivo adicionado', 'success')
            }}
            onDelete={e => {
              // showNotification('Logomarca!', 'Arquivo removido', 'success')
            }}
            onDropRejected={e => {
              showNotification('Ops...', 'Arquivo inválido', 'warning')
            }}
          />
          {/* <Ubox hidden={!ehVoltar}>
            <Box mt={3}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                type="button"
                // onClick={voltarEdicao}
              >
                Voltar
              </Button>
            </Box>
          </Ubox> */}
        </Ubox>
      </Ubox>
      <Box mt={3}>
        <Button
          fullWidth
          type="submit"
          loading={uploading}
          disabled={uploading}
          secondaryLoading
        >
          Enviar
        </Button>
        <Ubox mt={1}>
          <Button
            fullWidth
            disabled={uploading}
            onClick={() => {
              resetForm()
              handleOnClose()
            }}
            color="default"
          >
            Cancelar
          </Button>
        </Ubox>
      </Box>
    </form>
  )
}

interface PropsGrupo {
  comprovantes: ComprovanteResponse[]
  titulo: string
}

function GrupoComprovante({ titulo, comprovantes }: PropsGrupo) {
  return (
    <Ubox mb={2}>
      <Typography variant="subtitle2" color="primary">
        <b>{titulo}</b>
      </Typography>
      {!comprovantes.length && (
        <Ubox mt={1}>
          <Typography variant="body1">
            Ainda não possui comprovantes anexados.
          </Typography>
        </Ubox>
      )}
      {comprovantes.map((c, index) => (
        <Comprovante key={index} comprovante={c} />
      ))}
    </Ubox>
  )
}

interface PropsComprovante {
  comprovante: ComprovanteResponse
}

const styleComp = {
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  border: '1px solid #ddd',
  borderRadius: '8px',
  boxShadow: '3px 3px 3px #eee',
  cursor: 'pointer',
  width: '150px',
  height: '150px'
}

const stylePDF = {
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  border: '1px solid #ddd',
  borderRadius: '8px',
  boxShadow: '3px 3px 3px #eee',
  cursor: 'pointer',
  width: '150px',
  height: '150px',
  display: 'flex'
  // flexDirection: 'column'
}

function Comprovante({ comprovante }: PropsComprovante) {
  return (
    <Ubox>
      <Ubox mt={2} mb={2}>
        {comprovante.contentType.includes('pdf') ? (
          <div
            onClick={() => window.open(comprovante.url, '_blank')}
            style={{
              fontSize: '100px',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'red',
              ...stylePDF
            }}
          >
            <FaRegFilePdf />
            <p
              style={{
                fontSize: '14px',
                margin: 0,
                marginTop: 4,
                padding: 0,
                paddingTop: 0,
                fontWeight: 600
              }}
            >
              Baixar PDF
            </p>
          </div>
        ) : (
          <div
            onClick={() => window.open(comprovante.url, '_blank')}
            style={{ background: `url(${comprovante.url})`, ...styleComp }}
          ></div>
        )}
        <Ubox mt={1} hidden={!comprovante.observacao}>
          <Typography variant="body2">{comprovante.observacao}</Typography>
        </Ubox>
        <Typography variant="caption" style={{ fontStyle: 'italic' }}>
          Enviado por {comprovante.enviadoPor} ás{' '}
          {formatarDataHoraLocal(comprovante.enviado)}
        </Typography>
      </Ubox>
      <Divider />
    </Ubox>
  )
}

type PropsGenerico = {
  tipo: number
  solicitacaoId: string
  destinatarioId: string
}

export function ComprovanteGenerico({
  tipo,
  destinatarioId,
  solicitacaoId
}: PropsGenerico) {
  const [loading, setLoading] = useState(true)
  const [comprovantes, setComprovantes] = useState<ComprovanteResponse[]>(null)
  const { perfilAtual } = useAuth()

  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))

  async function obterListagem() {
    try {
      setLoading(true)
      const response = await obterComprovantesPorDestinatario(
        solicitacaoId,
        tipo,
        destinatarioId,
        perfilAtual
      )
      setComprovantes(response.data)
      setLoading(false)
    } catch (error) {
      showNotificationErrorAPI(error)
    }
  }

  useEffect(() => {
    obterListagem()
  }, [])

  return (
    <Ubox>
      {loading && <CircularProgress />}

      <Ubox render={!loading && !!comprovantes}>
        <GrupoComprovante titulo="Comprovantes:" comprovantes={comprovantes} />
      </Ubox>
    </Ubox>
  )
}

type PropsHospital = {
  id: string
}
export function ComprovanteHospital(props: PropsHospital) {
  const [loading, setLoading] = useState(true)
  const [comprovantes, setComprovantes] = useState<ComprovanteResponse[]>(null)

  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))

  async function obterListagem() {
    try {
      setLoading(true)
      const response = await obterComprovantesHospital(props.id)
      setComprovantes(response.data)
      setLoading(false)
    } catch (error) {
      showNotificationErrorAPI(error)
    }
  }

  useEffect(() => {
    obterListagem()
  }, [])

  return (
    <Ubox>
      {loading && <CircularProgress />}

      <Ubox render={!loading && !!comprovantes}>
        <GrupoComprovante titulo="Comprovantes:" comprovantes={comprovantes} />
      </Ubox>
    </Ubox>
  )
}
