import { Box, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import Button from 'src/components/ui/Button'
import Drawer from 'src/components/ui/Drawer'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { verificarCPF, onlyNumber } from 'src/core/helpers/common'

import yup from 'src/components/yupCustom'
import { TextField } from 'src/components/ui/TextField'
import { cadastrarCpf } from 'src/api/paciente'
import Swal from 'sweetalert2'

type Props = {
  id: string
  open: boolean
  handleOnClose: (boolean?) => void
  carregarListagem: () => void
}

const schema = yup.object().shape({
  cpf: yup
    .string()
    .required()
    .test('cpf-válido', 'O CPF é inválido', function (value) {
      if (!value) return false
      return verificarCPF(onlyNumber(value))
    })
    .label('CPF')
})

function DrawerCadastrarCpf(props: Props) {
  const [loading, setLoading] = useState(false)

  const { errors, watch, control } = useForm({
    resolver: yupResolver(schema)
  })

  const command = watch()

  async function submit() {
    const body = {
      solicitacaoId: props.id,
      cpf: onlyNumber(command.cpf)
    }

    setLoading(true)

    try {
      await cadastrarCpf(body)

      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'CPF cadastrado com sucesso',
        confirmButtonText: 'Ok'
      })

      props.carregarListagem()
      props.handleOnClose()
    } catch (err) {
      showNotificationErrorAPI(err)
    }

    setLoading(false)
  }

  return (
    <Drawer
      anchor="right"
      open={props.open}
      titulo="Cadastrar CPF do paciente"
      handleOnClose={() => props.handleOnClose()}
    >
      <Typography variant="subtitle1" style={{ marginBottom: '1em' }}>
        Insira o CPF do paciente para que ele possa acessar o sistema
      </Typography>
      <TextField
        control={control}
        name="cpf"
        placeholder="CPF"
        label="CPF"
        variant="outlined"
        fullWidth
        size="small"
        mask={'999.999.999-99'}
        erro={errors}
        value={command.cpf}
      />

      <Box mt={3}>
        <Button fullWidth onClick={submit} loading={loading} disabled={loading} secondaryLoading>
          Salvar
        </Button>
      </Box>
    </Drawer>
  )
}

export default DrawerCadastrarCpf
