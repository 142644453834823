import { yupResolver } from '@hookform/resolvers/yup'
import { Alert } from '@material-ui/lab'
import { Grid } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'src/components/ui/Button'
import TextField from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import {
  onlyNumber,
  verificarCNPJ,
  verificarCPF
} from 'src/core/helpers/common'
import { SynPerfis } from 'src/core/consts/synPerfis'
import useAuth from 'src/hooks/useAuth'
import moment from 'moment'
import UBox from 'src/components/ui/Ubox'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import DateInput from 'src/components/ui/DateInput'
import {
  showNotification,
  showNotificationErrorAPI
} from 'src/core/helpers/notification'
import {
  alterarDadosPaciente,
  ISalvarDadosPacienteRequest,
  obterDadosPacientePorId
} from 'src/api/paciente'

const useStyles = makeStyles(theme => ({
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    minWidth: 250,
    marginTop: 15
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  noMargin: {
    margin: theme.spacing(0)
  }
}))

const schema = yup.object().shape({
  nome: yup
    .string()
    .required()
    .test('nome válido', 'Deve informar nome e sobrenome', value => {
      if (!value) return false
      let nomeArray = value.split(' ').filter(texto => texto.trim())
      return nomeArray.length > 1
    })
    .label('Nome completo'),
  telefone: yup.string().required().label('Telefone'),
  dataNascimento: yup
    .string()
    .required()
    .test('nas', 'Por favor escolha uma data válida', value => {
      return moment(value).isBetween('1922-12-31', moment(), 'day')
    })
    .label('Data de nascimento'),
  email: yup.string().email().label('E-mail')
})

interface IFormDadosPaciente {
  id?: string
  nome: string
  email: string
  cpf: string
  dataNascimento: string
  telefone: string
}

const dadosPacienteDefault = {
  id: '',
  nome: '',
  email: '',
  cpf: '',
  dataNascimento: '',
  telefone: ''
}

interface IProps {
  id: string
  temCpf: boolean
  handleAfterSubmit: (atualizou, id?, temCpf?) => void
}

export default function FormDadosPaciente({
  id,
  temCpf,
  handleAfterSubmit
}: IProps) {
  const classes = useStyles()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const {
    watch,
    register,
    setValue,
    errors,
    control,
    handleSubmit
  } = useForm<IFormDadosPaciente>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: dadosPacienteDefault
  })
  const command = watch()
  useEffect(() => {
    register('id')
    register('nome')
    register('telefone')
    register('dataNascimento')
  }, [register])

  const handleItemValue = item => {
    setValue('dataNascimento', item, {
      shouldValidate: true
    })
  }
  useEffect(() => {
    obterDadosPaciente(id)
  }, [id])
  async function obterDadosPaciente(value) {
    try {
      if (id) {
        setLoading(true)
        const { data } = await obterDadosPacientePorId(value)

        mapear(data)
        setLoading(false)
      }
    } catch (error) {
      showNotificationErrorAPI(error)
      setLoading(false)
    }
  }

  function mapear(dadosPaciente: IFormDadosPaciente) {
    const keysForm = Object.keys(dadosPacienteDefault)
    for (const key in dadosPaciente) {
      if (keysForm.includes(key)) {
        setValue(key as any, dadosPaciente[key], { shouldValidate: true })
      }
    }
  }

  const onSubmit = async () => {
    try {
      let data: ISalvarDadosPacienteRequest = {
        id: id,
        cpf: onlyNumber(command.cpf),
        dataNascimento: moment(command.dataNascimento).toJSON(),
        email: command.email,
        nome: command.nome,
        telefone: onlyNumber(command.telefone)
      }
      setSubmitting(true)
      await alterarDadosPaciente(data)
      showNotification(
        'Parabéns',
        'Dados do paciente alterados com sucesso.',
        'success',
        true
      )
      setTimeout(() => handleAfterSubmit(true, id), 300)
      setSubmitting(false)
    } catch (error) {
      setSubmitting(false)
      showNotificationErrorAPI(error)
    }
  }

  return (
    <>
      {/* <div style={{ wordWrap: 'break-word' }}>
        Command:{JSON.stringify(command)},
        {id}
      </div> */}
      <UBox render={loading}>
        <LoadingGeral />
      </UBox>
      <UBox hidden={loading}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.form}
          noValidate={true}
        >
          <Grid container spacing={1} direction="row">
            <Grid item xs={12} md={12}>
              <Alert severity="info">
                Aqui você pode alterar os dados do paciente
              </Alert>
              <TextField
                disabled={submitting}
                erro={errors}
                autoFocus
                value={command.nome}
                inputRef={register}
                inputProps={{ maxLength: 100 }}
                showCaracteres={100}
                name="nome"
                label="Nome"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                inputRef={register}
                disabled={submitting}
                erro={errors}
                name="email"
                inputProps={{ maxLength: 30 }}
                value={command.email}
                showCaracteres={30}
                label="E-mail"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                erro={errors}
                name="telefone"
                maskTelCel
                value={command.telefone}
                label="Telefone"
                type="tel"
                control={control}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            {temCpf && (
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={true}
                  erro={errors}
                  name="cpf"
                  placeholder="Ex: 999.999.999-99"
                  label="CPF"
                  type="tel"
                  hiddenLabel
                  control={control}
                  mask="999.999.999-99"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <DateInput
                errors={errors}
                name="dataNascimento"
                label="Data de nascimento"
                value={command.dataNascimento}
                control={control}
                handleChange={value => handleItemValue(value)}
              />
            </Grid>
          </Grid>
          <Grid container justify="flex-end" style={{ marginTop: '24px' }}>
            <Grid item xs={12} sm={4}>
              <Button
                loading={submitting}
                disabled={submitting}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </form>
      </UBox>
    </>
  )
}
