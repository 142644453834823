import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField as TextFieldMU,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RiCloseFill } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import {
  adicionarEquipamento,
  adicionarExame,
  adicionarMaterial,
  adicionarOPME,
  adicionarTipoAnestesia,
  obterEquipamentos,
  obterListaOPME,
  obterMateriais,
  obterProcedimentos,
  obterTiposAnestesia
} from 'src/api/dominio'
import { salvarPacote } from 'src/api/pacote'
import { ModalAddGenerico } from 'src/components/modals/ModalAddGenerico'
import { ModalAddProcedimento } from 'src/components/modals/ModalAddProcedimento'
import AutocompleteOPME from 'src/components/OPME/AutocompleteOPME'
import {
  StepConnectorSyn,
  StepIconSyn
} from 'src/components/stepper/stepConnector'
import { StepContentSyn } from 'src/components/stepper/stepContentSyn'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import AutocompleteProcedimentos from 'src/components/ui/AutocompleteProcedimentos'
import { NumberFormatCustom } from 'src/components/ui/NumberField'
import { TextField } from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { useQuery } from 'src/hooks/useQuery'
import {
  IPacoteDetalhadoResponse,
  ObjetoDescricaoGenerico,
  ObjetoQuantidade,
  OPME,
  SalvarPacoteCommand
} from 'src/types'
import Swal from 'sweetalert2'

const useStyles = makeStyles(theme => ({
  container: {
    // padding: '30px 20px 40px ',
    // margin: '20px auto',
    // borderRadius: '8px'
  },
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  noMargin: {
    margin: theme.spacing(0)
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))

const schema = yup.object().shape({
  descricao: yup.string().required().label('Nome do procedimento'),
  procedimentos: yup.array().min(1).required().label('Procedimentos'),
  exames: yup.array().min(0).label('Exames'),
  tiposAnestesia: yup.array().min(0).label('Tipo de anestesia'),
  mediaTempoCirurgico: yup
    .string()
    .required()
    .label('Média de tempo')
    .test('media-tempo', 'Média de tempo', value => {
      if (value.trim().length < 5 || value === '00:00') return false
      return true
    }),
  opmes: yup.array().label('Lista de OPME'),
  equipamentos: yup.array().label('Outros materiais e equipamentos hospitalar'),
  materiais: yup.array().label('Outros materiais e equipamentos hospitalar'),
  diariasUti: yup.string().label('Diária de UTI'),
  diariasEnfermaria: yup.string().label('Diária de enfermaria'),
  diariasApartamento: yup.string().label('Diária de apartamento'),
  honorarioEquipeMedica: yup
    .string()
    .nullable()
    .required()
    .label('Honorário da equipe'),
  observacoes: yup.string().label('Observações'),
  examesPosOperatorio: yup.string().label('Exames pós-operatório')
})

export interface IPacoteForm {
  id?: string
  descricao: string
  procedimentos: ObjetoDescricaoGenerico[]
  exames: ObjetoDescricaoGenerico[]
  tiposAnestesia: ObjetoDescricaoGenerico[]
  mediaTempoCirurgico: string
  opmes: ObjetoQuantidade[]
  equipamentos: ObjetoDescricaoGenerico[]
  materiais: ObjetoDescricaoGenerico[]
  diariasUti: string
  diariasApartamento: string
  diariasEnfermaria: string
  honorarioEquipeMedica: number
  observacoes: string
  pqa: boolean
  dayClinic?: boolean
  examesPosOperatorio: string
}

export function mapearPacoteParaRequest(form: IPacoteForm) {
  const pacote: SalvarPacoteCommand = {
    id: form.id,
    descricao: form.descricao,
    observacoes: form.observacoes,
    diariasEnfermaria: !!form.diariasEnfermaria
      ? parseInt(form.diariasEnfermaria)
      : null,
    diariasApartamento: !!form.diariasApartamento
      ? parseInt(form.diariasApartamento)
      : null,
    dayClinic: form.dayClinic,
    diariasUti: !!form.diariasUti ? parseInt(form.diariasUti) : null,
    equipamentos: form.equipamentos?.map(e => e.id),
    materiais: form.materiais?.map(e => e.id),
    exames: form.exames?.map(e => e.id),
    examesPosOperatorio: form.examesPosOperatorio,
    tiposAnestesia: form.tiposAnestesia?.map(e => e.id),
    procedimentos: form.procedimentos?.map(p => p.id),
    honorarioEquipeMedica: form.honorarioEquipeMedica,
    mediaTempoCirurgico: form.mediaTempoCirurgico,
    opmes: form.opmes?.map(o => {
      let opmes: OPME = {
        opmeId: o.id,
        marca: o.marca,
        observacao: o.observacao,
        quantidade: o.quantidade
      }
      return opmes
    })
  }
  return pacote
}

type Props = {
  pacote?: IPacoteForm
  etapaAjuste?: number
  handleSalvarAjuste?: (dados: IPacoteForm) => void
  close: () => void
  setPacote: (pacote: any) => void
}

export default function FormPacote(props: Props) {
  const query = useQuery()

  const classes = useStyles()
  const history = useHistory()
  //#region Definicao de listas e loading
  const [loading, setLoading] = useState(false)

  const [listaProcedimentos, setListaProcedimentos] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [loadingProcedimentos, setLoadingProcedimentos] = useState<boolean>(
    true
  )

  const [listaTiposAnestesia, setListaTiposAnestesia] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [loadingTiposAnestesia, setLoadingTiposAnestesia] = useState<boolean>(
    true
  )

  const [listaOPME, setListaOPME] = useState<ObjetoDescricaoGenerico[]>([])
  const [loadingOPME, setLoadingOPME] = useState<boolean>(true)

  const [listaEquipamentos, setListaEquipamentos] = useState<
    ObjetoDescricaoGenerico[]
  >([])

  const [listaMateriais, setListaMateriais] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [loadingEquipamentos, setLoadingEquipamentos] = useState<boolean>(true)
  const [showUTIDays, setShowUTIDays] = useState<boolean>(false)

  //#endregion
  const [pacote, setPacote] = useState<IPacoteDetalhadoResponse>()
  const [ajustePacote, setAjustePacote] = useState(false)
  const [textoModal, setTextoModal] = useState('')
  const [showModal, toggleModal] = useState<string>(null)

  const [showModalProcedimento, toggleModalProcedimento] = useState(false)
  const handleShowModal = (texto, modal) => {
    setTextoModal(texto)
    toggleModal(modal)
  }

  const [defaultCheckedRadio, setDefaultCheckedRadio] = useState('')

  const handleShowModalProcedimento = (texto: string) => {
    setTextoModal(texto)
    toggleModalProcedimento(true)
  }

  const dadosModal = () => {
    if (!showModal) return
    let dados = null
    switch (showModal) {
      case 'exame':
        dados = {
          titulo: 'Adicionar exame',
          label: 'Exame',
          saveSubmit: adicionarExame,
          handleValue: valor => {
            handleNovoValor('exames', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
      case 'tiposAnestesia':
        dados = {
          titulo: 'Adicionar tipo de anestesia',
          label: 'Tipo de anestesia',
          saveSubmit: adicionarTipoAnestesia,
          handleValue: valor => {
            handleNovoValor('tiposAnestesia', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
      case 'opmes':
        dados = {
          titulo: 'Adicionar OPME',
          label: 'OPME',
          saveSubmit: adicionarOPME,
          handleValue: valor => {
            handleNovoValor('opmes', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
      case 'equipamento':
        dados = {
          titulo: 'Adicionar material/equipamento hospitalar',
          label: 'Material/Equipamento hospitalar',
          saveSubmit: adicionarEquipamento,
          handleValue: valor => {
            handleNovoValor('equipamentos', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
      case 'materiais':
        dados = {
          titulo: 'Adicionar material hospitalar',
          label: 'Material hospitalar',
          saveSubmit: adicionarMaterial,
          handleValue: valor => {
            handleNovoValor('materiais', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
    }
    return dados
  }

  //#region Formulário
  const defaultValues: IPacoteForm = {
    descricao: '',
    procedimentos: [],
    exames: [],
    tiposAnestesia: [],
    mediaTempoCirurgico: '',
    opmes: [],
    equipamentos: [],
    materiais: [],
    diariasUti: '',
    diariasEnfermaria: '',
    diariasApartamento: '',
    honorarioEquipeMedica: null,
    observacoes: '',
    pqa: false,
    dayClinic: false,
    examesPosOperatorio: ''
  }

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    formState,
    handleSubmit,
    trigger
  } = useForm<IPacoteForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  const command = watch()

  // REGISTRA VALORES REACT FORM HOOKS
  useEffect(() => {
    register('id')
    // register('descricao')
    register('procedimentos')
    register('exames')
    register('tiposAnestesia')
    register('equipamentos')
    register('materiais')
    register('opmes')
    register('honorarioEquipeMedica')
    register('pqa')
    register('dayClinic')
    register('medico')
  }, [register])

  // VINCULAR MUDANÇA DE VALOR
  const handleItemValue = (name: string, item) => {
    //console.log(name, item)
    setValue(name, item, {
      shouldValidate: true
    })
  }

  const handleRadios = e => {
    let valueRadio = e.target.value
    handleItemValue('diariasUti', 0)
    handleItemValue('diariasEnfermaria', 0)
    handleItemValue('diariasApartamento', 0)
    handleItemValue('pqa', false)
    handleItemValue('dayClinic', false)
    setShowUTIDays(false)
    setDefaultCheckedRadio(valueRadio)

    if (valueRadio === 'withInternation') {
      setShowUTIDays(true)
    } else if (valueRadio === 'dayClinic') {
      handleItemValue('dayClinic', true)
    } else {
      handleItemValue('pqa', true)
    }
  }

  const getDefaultCheckedRadio = pacote => {
    pacote.pqa && setDefaultCheckedRadio('noInternation')
    pacote.dayClinic && setDefaultCheckedRadio('dayClinic')
    !pacote.pqa &&
      !pacote.dayClinic &&
      setDefaultCheckedRadio('withInternation')

    !pacote.pqa && !pacote.dayClinic && setShowUTIDays(true)
  }

  const handleNovoValor = (name, item: any) => {
    switch (name) {
      case 'equipamentos':
        carregarEquipamentos()
        setValue('equipamentos', [...command.equipamentos, item], {
          shouldValidate: true
        })
        break
      case 'materiais':
        carregarMateriais()
        setValue('materiais', [...command.materiais, item], {
          shouldValidate: true
        })
        break
      case 'tiposAnestesia':
        carregarTiposAnestesia()
        setValue('tiposAnestesia', [...command.tiposAnestesia, item], {
          shouldValidate: true
        })
        break
      case 'opmes':
        carregarOPME()
        item.quantidade = 1
        setValue('opmes', [...command.opmes, item], {
          shouldValidate: true
        })
        break
      case 'procedimento':
        carregarProcedimentos()
        setValue('procedimento', item, {
          shouldValidate: true
        })
        break
    }
  }
  //#endregion

  //#region MOUNT
  useEffect(() => {
    carregarProcedimentos()
    carregarTiposAnestesia()
    carregarOPME()
    carregarMateriais()
    carregarEquipamentos()
  }, [])
  //#endregion

  //#region Carregar Opções do Sistema
  const carregarProcedimentos = async () => {
    return obterProcedimentos()
      .then(response => setListaProcedimentos(response.data || []))
      .finally(() => {
        setLoadingProcedimentos(false)
      })
    // return setListaProcedimentos(mockProcedimentos)
  }

  const carregarTiposAnestesia = async () => {
    return obterTiposAnestesia()
      .then(response => setListaTiposAnestesia(response.data || []))
      .finally(() => {
        setLoadingTiposAnestesia(false)
      })
  }

  const carregarOPME = async () => {
    return obterListaOPME()
      .then(response => setListaOPME(response.data || []))
      .finally(() => {
        setLoadingOPME(false)
      })
    // return setListaOPME(mockOPME)
  }

  const carregarEquipamentos = async () => {
    return obterEquipamentos()
      .then(response => setListaEquipamentos(response.data || []))
      .finally(() => {
        setLoadingEquipamentos(false)
      })
    // return setListaEquipamentos(mockEquipamentos)
  }

  async function carregarMateriais() {
    return obterMateriais()
      .then(res => setListaMateriais(res.data || []))
      .catch(err => showNotificationErrorAPI(err))
  }

  //#endregion

  const onSubmit = async () => {
    //console.log('@submit', data)

    let pacote = mapearPacoteParaRequest(command)
    return salvarPacote(pacote)
      .then(response => {
        Swal.fire({
          title: 'Parabéns!',
          text: 'Seu pacote foi configurado com sucesso.',
          icon: 'success',
          confirmButtonText: 'Continuar',
          willClose: () => {
            //Verifica se tem url de retorno
            if (query.has('returnUrl')) {
              const returnUrl = query.get('returnUrl')
              history.replace(
                `${returnUrl}?idPacote=${response.data}&descricaoPacote=${command.descricao}`
              )
              return
            }

            history.replace(SynRoutes.listagemPacotes)
          }
        })
      })
      .catch(error => showNotificationErrorAPI(error))
      .finally()
  }

  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  //#region stepper
  const [activeStep, setActiveStep] = React.useState(0)
  // MIN = Minimum expected value
  // MAX = Maximium expected value
  // Function to normalise the values (MIN / MAX could be integrated)
  const lastStep = 8
  const normalise = value => ((value - 0) * 100) / (lastStep - 0)

  const formularioCamposDoStep = [
    ['descricao'],
    ['procedimentos'],
    /* ['exames'], */
    ['tiposAnestesia'],
    ['opmes'],
    ['equipamentos', 'materiais'],
    [
      'pqa',
      'diariasUti',
      'diariasEnfermaria',
      'diariasApartamento',
      'honorarioEquipeMedica',
      'mediaTempoCirurgico'
    ],
    ['examesPosOperatorio'],
    ['observacoes']
  ]

  const handleNext = async () => {
    var campos = formularioCamposDoStep[activeStep]
    if (campos) {
      const result = await trigger(campos)
      if (!result) {
        return
      }
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const debugSteps = false
  const handleBackToStep = stepNumber => {
    if (!stepNumber && stepNumber !== 0) {
      setActiveStep(activeStep - 1)
      return
    }

    if (debugSteps || stepNumber < activeStep) {
      setActiveStep(stepNumber)
    }
  }

  const getSteps = number => {
    const steps = []
    for (let index = 0; index < number; index++) {
      steps.push(
        <Step key={index}>
          <StepLabel
            StepIconComponent={v => StepIconSyn({ ...v, key: index + 1 })}
            onClick={() => handleBackToStep(index)}
          ></StepLabel>
          <StepContent></StepContent>
        </Step>
      )
    }
    return steps
  }

  function handleSalvarAjuste() {
    props.setPacote(command)
    props.close()
  }
  //#endregion
  // if (loading) {
  //   return (
  //     <Box textAlign="center">
  //       <CircularProgress />
  //       <div>Carregando...</div>
  //     </Box>
  //   )
  // }

  return (
    <Box>
      {loading && (
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      )}
      <Box
        component="main"
        hidden={loading}
        className={`fadeInTransition ${classes.container}`}
        style={{ padding: '20px' }}
      >
        <CssBaseline />
        {/* command: {JSON.stringify(command)} */}
        {/* erros: {JSON.stringify(errors)} */}
        {/* <br /> */}
        {/* locais: {JSON.stringify(locais)} */}
        {ehXS && !ajustePacote && (
          <LinearProgress
            variant="determinate"
            value={normalise(activeStep + 1)}
          />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            fontSize: '2rem'
          }}
        >
          <RiCloseFill style={{ cursor: 'pointer' }} onClick={props.close} />
        </div>
        <Grid container>
          {!ehXS && !ajustePacote && (
            <Grid item xs={12} sm={1}>
              <Stepper
                connector={<StepConnectorSyn />}
                activeStep={activeStep}
                style={{ margin: 0, padding: 0, background: 'none' }}
                orientation="vertical"
              >
                {getSteps(lastStep)}
              </Stepper>
            </Grid>
          )}

          <Grid item xs={12} sm={ajustePacote ? 12 : 10}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={classes.form}
              noValidate={true}
            >
              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 0}
                showFinish={ajustePacote}
                handleFinish={handleSubmit(handleSalvarAjuste)}
                hideBack
                handleNext={handleNext}
                textoTitulo={
                  'Digite o nome para o pacote que deseja configurar.'
                }
              >
                <TextField
                  erro={errors}
                  name="descricao"
                  value={command.descricao}
                  placeholder="Digite o nome..."
                  label="Nome da cirurgia"
                  inputRef={register}
                />
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 1}
                handleBackToStep={handleBackToStep}
                hideBack={ajustePacote}
                showFinish={ajustePacote}
                handleFinish={handleSubmit(handleSalvarAjuste)}
                handleNext={handleNext}
                textoTitulo={
                  'Informe os códigos que representam os procedimentos escolhidos.'
                }
              >
                <AutocompleteProcedimentos
                  erro={errors}
                  name="procedimentos"
                  label="Procedimentos"
                  value={command.procedimentos}
                  options={ehXS ? [] : listaProcedimentos}
                  getOptionLabel={option =>
                    `${option.detalhe || ''} - ${option.descricao}`
                  }
                  handleValue={newValue => {
                    console.log(newValue)
                    handleItemValue('procedimentos', newValue)
                  }}
                  onClickAddButton={texto => handleShowModalProcedimento(texto)}
                />
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 2}
                hideBack={ajustePacote}
                showFinish={ajustePacote}
                handleFinish={handleSubmit(handleSalvarAjuste)}
                handleBackToStep={handleBackToStep}
                handleNext={handleNext}
                textoTitulo={'Qual o tipo de anestesia necessária?'}
              >
                <Autocomplete
                  multiple
                  erro={errors}
                  name="tiposAnestesia"
                  label="Tipos de anestesia"
                  loading={loadingTiposAnestesia}
                  options={listaTiposAnestesia}
                  value={command.tiposAnestesia}
                  getOptionSelected={option =>
                    !!command.tiposAnestesia.find(l => l.id === option.id)
                  }
                  showCheck
                  showAddButton
                  onClickAddButton={texto =>
                    handleShowModal(texto, 'tiposAnestesia')
                  }
                  getOptionLabel={option => option.descricao}
                  onChange={(event, newValue: ObjetoDescricaoGenerico[]) => {
                    handleItemValue(
                      'tiposAnestesia',
                      newValue as ObjetoDescricaoGenerico[]
                    )
                  }}
                />
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 3}
                hideBack={ajustePacote}
                showFinish={ajustePacote}
                handleFinish={handleSubmit(handleSalvarAjuste)}
                handleBackToStep={handleBackToStep}
                handleNext={handleNext}
                textoTitulo={
                  'Informe os materiais necessários para realizar o procedimento.'
                }
              >
                <Grid item xs={12}>
                  <AutocompleteOPME
                    tableTitle="Lista de OPME"
                    value={command.opmes}
                    name="opmes"
                    label="OPME"
                    handleValue={newValue => {
                      handleItemValue(
                        'opmes',
                        newValue as ObjetoDescricaoGenerico[]
                      )
                    }}
                    onClickAddButton={texto => handleShowModal(texto, 'opmes')}
                    options={listaOPME}
                    loading={loadingOPME}
                    errors={errors}
                    showAddButton
                    getOptionSelected={option => {
                      return !!command.opmes.find(l => l.id === option.id)
                    }}
                    getOptionLabel={option => option.descricao}
                    getOptionId={option => option.id}
                  />
                </Grid>
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 4}
                hideBack={ajustePacote}
                showFinish={ajustePacote}
                handleFinish={handleSubmit(handleSalvarAjuste)}
                handleBackToStep={handleBackToStep}
                handleNext={handleNext}
                textoTitulo={
                  'Outros materiais ou equipamentos são necessários?'
                }
              >
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    multiple
                    erro={errors}
                    name="equipamentos"
                    label="Equipamentos "
                    loading={loadingEquipamentos}
                    options={listaEquipamentos}
                    value={command.equipamentos}
                    getOptionSelected={option =>
                      !!command.equipamentos.find(l => l.id === option.id)
                    }
                    showCheck
                    showAddButton
                    getOptionLabel={option => option.descricao}
                    onChange={(event, newValue: ObjetoDescricaoGenerico[]) => {
                      handleItemValue(
                        'equipamentos',
                        newValue as ObjetoDescricaoGenerico[]
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    multiple
                    erro={errors}
                    name="materiais"
                    label="Materiais "
                    options={listaMateriais || []}
                    value={command.materiais}
                    getOptionSelected={option =>
                      !!command.materiais.find(l => l.id === option.id)
                    }
                    showCheck
                    showAddButton
                    addButtonProps={{
                      text: 'Não encontrou? Clique aqui'
                    }}
                    onClickAddButton={texto =>
                      handleShowModal(texto, 'materiais')
                    }
                    getOptionLabel={option => option.descricao}
                    onChange={(event, newValue: ObjetoDescricaoGenerico[]) => {
                      handleItemValue(
                        'materiais',
                        newValue as ObjetoDescricaoGenerico[]
                      )
                    }}
                  />
                </Grid>
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 5}
                hideBack={ajustePacote}
                showFinish={ajustePacote}
                handleFinish={handleSubmit(handleSalvarAjuste)}
                handleBackToStep={handleBackToStep}
                handleNext={handleNext}
                textoTitulo={'Precisamos de mais algumas informações.'}
              >
                <Grid container spacing={1}>
                  {/* <Grid item xs={12}>
                    <strong>Diárias</strong>
                  </Grid> */}

                  <Grid item xs={12}>
                    <RadioGroup
                      onChange={handleRadios}
                      value={defaultCheckedRadio}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            value="noInternation"
                            color="primary"
                            name="pqa"
                          />
                        }
                        label="PQA (até 6h de internação)"
                      />
                      <Alert severity="info">
                        Cirurgias de pequeno porte em que o paciente tem alta
                        hospitalar do centro cirúrgico até 6h após o
                        procedimento.
                      </Alert>
                      <FormControlLabel
                        control={
                          <Radio
                            value="dayClinic"
                            color="primary"
                            name="dayClinic"
                          />
                        }
                        label="Day Clinic (até 12h de internação)"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            value="withInternation"
                            color="primary"
                            name="withInternation"
                          />
                        }
                        label="Cirurgia com diária"
                      />
                    </RadioGroup>
                  </Grid>

                  <Box hidden={!showUTIDays}>
                    <Grid
                      container
                      spacing={1}
                      style={{ margin: 0, width: '100%' }}
                    >
                      <Grid item xs={12} md={4}>
                        <TextField
                          erro={errors}
                          name="diariasUti"
                          placeholder="Diárias"
                          type="tel"
                          value={command.diariasUti}
                          disabled={command.pqa}
                          label="Diárias UTI"
                          control={control}
                          mask="9999"
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          erro={errors}
                          InputLabelProps={{
                            shrink: true
                          }}
                          placeholder="Diárias"
                          type="tel"
                          value={command.diariasEnfermaria}
                          name="diariasEnfermaria"
                          disabled={command.pqa}
                          label="Diárias Enf."
                          control={control}
                          mask="9999"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          erro={errors}
                          InputLabelProps={{
                            shrink: true
                          }}
                          placeholder="Diárias"
                          type="tel"
                          value={command.diariasApartamento}
                          name="diariasApartamento"
                          disabled={command.pqa}
                          label="Diárias Apto."
                          control={control}
                          mask="9999"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextFieldMU
                      error={!!errors.honorarioEquipeMedica}
                      helperText={errors.honorarioEquipeMedica?.message}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      label="Honorário da equipe médica"
                      value={command.honorarioEquipeMedica}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value = event.target.value
                        handleItemValue('honorarioEquipeMedica', value)
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      erro={errors}
                      name="mediaTempoCirurgico"
                      label="Média de tempo (hh:mm)"
                      mask="99:99"
                      type="tel"
                      maskPlaceholder="00:00"
                      control={control}
                    />
                  </Grid>
                </Grid>
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 6}
                hideBack={ajustePacote}
                showFinish={ajustePacote}
                handleFinish={handleSubmit(handleSalvarAjuste)}
                handleBackToStep={handleBackToStep}
                handleNext={handleNext}
                textoTitulo={
                  'Quais exames seu paciente deve realizar durante a internação?'
                }
              >
                <Alert severity="info">
                  Caso você precise de algum tipo de exame ou procedimento
                  específico durante a internação, informe no campo abaixo.{' '}
                  <br />
                  <br />
                  Exemplo: Exames de imagem de controle pós-operatório, exames
                  laboratoriais pós-operatório, histopatológico de peça
                  cirúrgica, entre outros.
                </Alert>
                <TextField
                  erro={errors}
                  multiline={true}
                  rows={4}
                  inputRef={register}
                  name="examesPosOperatorio"
                  label="Exames durante a internação"
                />
              </StepContentSyn>

              <StepContentSyn
                activeStep={activeStep}
                lastStep={lastStep}
                show={activeStep === 7}
                hideBack={ajustePacote}
                handleFinish={handleSubmit(handleSalvarAjuste)}
                handleBackToStep={handleBackToStep}
                handleNext={handleNext}
                showFinish
                textoTitulo={
                  'Caso tenha alguma consideração a fazer, sinta-se à vontade para informar abaixo'
                }
              >
                <TextField
                  erro={errors}
                  multiline={true}
                  rows={4}
                  inputRef={register}
                  name="observacoes"
                  label="Observações"
                />
              </StepContentSyn>
            </form>
          </Grid>
        </Grid>
        {!!showModal && <ModalAddGenerico open={true} {...dadosModal()} />}
        {!!showModalProcedimento && (
          <ModalAddProcedimento
            open={true}
            descricao={textoModal}
            handleClose={() => toggleModalProcedimento(false)}
            handleValue={valor => {
              handleNovoValor('procedimento', valor)
            }}
          />
        )}
      </Box>
    </Box>
  )
}
