import React from 'react'
import usePagamento from 'src/hooks/usePagamento'
import UBox from '../ui/Ubox'
import { Grid, LinearProgress, Tooltip, withStyles } from '@material-ui/core'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'

export default function progressoPagamento() {
  const {
    resumo,
    limparFracionado,
    urlPixFracionado,
    boletoFracionado,
    pagamentoCreditoFracionado
  } = usePagamento()

  function obterPorcentagemValorPago(): number {
    var porcentagem = (resumo?.valorPago / resumo?.valorTotal) * 100
    return porcentagem
  }

  return (
    <UBox mb={2}>
      <small>
        {`${obterPorcentagemValorPago().toFixed(2)}% do valor já pago`}
      </small>
      <Tooltip
        title={`${obterPorcentagemValorPago().toFixed(2)}% do valor processado`}
      >
        <BorderLinearProgress
          variant="determinate"
          value={obterPorcentagemValorPago()}
          title={`${obterPorcentagemValorPago().toFixed(
            2
          )}% do valor processado`}
        />
      </Tooltip>
      <Grid container>
        <Grid item xs={6}>
          <UBox
            style={{
              display: 'flex',
              alignItems: 'start',
              gap: '8px',
              marginTop: '4px',
              lineHeight: 1.1
            }}
          >
            <UBox
              style={{
                background: '#74ce79',
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                marginTop: '4px'
              }}
            />
            <small>
              Pagamentos
              <br />
              <b>{formatarDinheiro(resumo?.valorPago)}</b>
            </small>
          </UBox>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <UBox
            style={{
              marginTop: '4px',
              lineHeight: 1.1
            }}
          >
            <small>
              Valor da proposta
              <br />
              <b>{formatarDinheiro(resumo?.valorTotal)}</b>
            </small>
          </UBox>
        </Grid>
      </Grid>
    </UBox>
  )
}

export const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 8,
    borderRadius: 4
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#74ce79'
  }
}))(LinearProgress)
