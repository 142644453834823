import { Box, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Button from 'src/components/ui/Button'
import Drawer from 'src/components/ui/Drawer'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import {
  alterarStatusProposta,
  alterarStatusPropostaEncerrada
} from 'src/api/solicitacao-orcamento'
import { AlterarStatusPropostaCommand, DadosPropostaHistorico } from 'src/types'
import { Alert } from '@material-ui/lab'
import StatusProposta from 'src/core/consts/statusProposta'
import usePagamento from 'src/hooks/usePagamento'
import { Pagamento } from 'src/api/pagamento'

type Props = {
  pedido: Pagamento
  open: boolean
  handleOnClose: (boolean?) => void
}

function DrawerRecusarPagamentoAvulso(props: Props) {
  const { recusarPagamentoAvulso } = usePagamento()
  const [loadingStatus, setLoadingStatus] = useState(true)
  const [loadingSalvar, setLoadingSalvar] = useState(false)
  const [observacao, setObservacao] = useState('')
  const [qtdCaracteres] = useState(150)
  const [qtdObservacao, setQtdObservacao] = useState(qtdCaracteres)

  useEffect(() => {
    if (props.open === true) {
      setLoadingStatus(false)
    }
    if (!props.pedido) {
      return
    }
    setObservacao('')
  }, [props.open])

  async function handleSalvar() {
    try {
      if (!observacao || observacao.length <= 0) {
        return
      }

      setLoadingSalvar(true)

      await recusarPagamentoAvulso(props.pedido.id, observacao)

      setLoadingSalvar(false)
      props.handleOnClose(true)
    } catch (e) {
      setLoadingSalvar(false)
      showNotificationErrorAPI(e)
      props.handleOnClose()
    }
  }

  return (
    <Drawer
      loading={loadingStatus}
      anchor="right"
      open={props.open}
      titulo={'Recusar a proposta'}
      handleOnClose={() => props.handleOnClose()}
    >
      {/* {props?.isDisabled === true && (
        <Alert severity="warning">
          <Typography variant="subtitle2">
            A proposta será desativada, e não podera ser utilizada
            posteriormente.
          </Typography>
        </Alert>
      )} */}

      <Box mt={2}>
        <TextField
          fullWidth
          label={'Motivo da recusa'}
          multiline
          rows={6}
          placeholder="Digite o motivo da alteração do status"
          variant="outlined"
          value={observacao}
          onChange={e => {
            setObservacao(e.target.value)
            setQtdObservacao(qtdCaracteres - e.target.value.length)
          }}
          inputProps={{ maxLength: qtdCaracteres }}
        />
        {qtdObservacao <= 10 ? (
          <Typography variant="subtitle2" component="h3" color="error">
            {qtdObservacao} caracteres restantes
          </Typography>
        ) : (
          <Typography variant="subtitle2" component="h3" color="primary">
            {qtdObservacao} caracteres restantes
          </Typography>
        )}
      </Box>

      <Box mt={3}>
        <Button
          disabled={loadingSalvar}
          fullWidth
          onClick={handleSalvar}
          loading={loadingSalvar}
          secondaryLoading
        >
          Recusar
        </Button>
      </Box>
    </Drawer>
  )
}

export default DrawerRecusarPagamentoAvulso
