import { Box } from '@material-ui/core'
import { useEffect, useState } from 'react'
import {
  alterarStatusSolicitacaoFinanciamento,
  AlterarStatusSolicitacaoFinanciamentoCommand
} from 'src/api/financiamento'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import Button from 'src/components/ui/Button'
import Drawer from 'src/components/ui/Drawer'
import { StatusFinanciamentoArray } from 'src/core/consts/statusFinanciamento'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { ObjetoDescricaoGenerico } from 'src/types'

type Props = {
  id: string
  // status: ObjetoDescricaoGenerico
  open: boolean
  handleOnClose: (alterou?: boolean) => void
}

function DrawerAlterarStatusFinanciamento(props: Props) {
  const [loadingStatus, setLoadingStatus] = useState(false)
  const [statusSelecionado, setStatusSelecionado] = useState(undefined)
  const [loadingSalvar, setLoadingSalvar] = useState(false)

  // useEffect(() => {

  // }, [props.open])

  function handleOnChange(event, newValue) {
    setStatusSelecionado(newValue)
  }

  async function handleSalvar() {
    try {
      setLoadingSalvar(true)
      var command: AlterarStatusSolicitacaoFinanciamentoCommand = {
        id: props.id,
        status: parseInt(statusSelecionado.id)
      }

      await alterarStatusSolicitacaoFinanciamento(command)
      setLoadingSalvar(false)
      props.handleOnClose(true)
    } catch (e) {
      setLoadingSalvar(false)
      showNotificationErrorAPI(e)
      props.handleOnClose()
    }
  }

  return (
    <Drawer
      loading={loadingStatus}
      anchor="right"
      open={props.open}
      titulo="Alterar status da solicitaçao"
      handleOnClose={() => props.handleOnClose()}
    >
      {/* command: {JSON.stringify(props)} */}
      <Autocomplete
        erro={{}}
        name="pacote"
        label="Status"
        loading={loadingStatus}
        value={statusSelecionado}
        options={StatusFinanciamentoArray}
        getOptionLabel={option => `${option.descricao}`}
        onChange={handleOnChange}
      />
      <Box mt={3}>
        <Button
          disabled={!statusSelecionado || loadingSalvar}
          fullWidth
          onClick={handleSalvar}
          loading={loadingSalvar}
          secondaryLoading
        >
          Salvar
        </Button>
      </Box>
    </Drawer>
  )
}

export default DrawerAlterarStatusFinanciamento
