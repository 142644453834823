import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField as TextFieldMU,
  makeStyles
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { J } from 'chart.js/dist/chunks/helpers.core';
import { NumberFormatCustom } from 'src/components/ui/NumberField';
import TextField from 'src/components/ui/TextField';
import Ubox from 'src/components/ui/Ubox';

type Props = {
  control?: any;
  errors?: any;
  register?: any;
  command?: any;
  handleItemValue?: any;
  isPJ?: boolean;
  handleChechedItem?: any;
  editarDadosBancarios?: any;
};

const useStyles = makeStyles((theme) => ({
  formControl2: {
    top: '16px'
  },
  [theme.breakpoints.down('md')]: {}
}));

export default function DadosBasicos({
  control,
  errors,
  register,
  command,
  handleItemValue,
  isPJ,
  handleChechedItem,
  editarDadosBancarios
}: Props) {
  return (
    <Grid container spacing={1}>
      <Ubox render={!isPJ && command.cpfCnpj.length > 0}>
        <Alert severity="info"> O nome e {isPJ ? 'CNPJ' : 'CPF'} devem ser os mesmos dos dados bancários.</Alert>
      </Ubox>
      <Grid item xs={12} md={12}>
        <TextField
          inputRef={register}
          erro={errors}
          name="name"
          value={command.name}
          disabled={!isPJ && command.name.length > 0}
          label={isPJ ? 'Nome do representante *' : 'Nome do recebedor *'}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          erro={errors}
          name="document"
          placeholder="Informe documento"
          label="CPF do Titular *"
          hiddenLabel
          control={control}
          mask="999.999.999-99"
          disabled={!!editarDadosBancarios || (!isPJ && command.document.length > 0)}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          erro={errors}
          control={control}
          name="birthdate"
          label="Data de nascimento *"
          mask="99/99/9999"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          control={control}
          erro={errors}
          inputRef={register}
          inputProps={{ maxLength: 64 }}
          name="email"
          label="E-mail *"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          size="medium"
          erro={errors}
          type="tel"
          maskTelCel
          control={control}
          inputProps={{ maxLength: 100, minLength: 10 }}
          name="phoneNumbers"
          label="Telefone *"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          control={control}
          erro={errors}
          inputRef={register}
          name="professionalOccupation"
          label="Ocupação profissional *"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextFieldMU
          data-cy="monthlyIncome"
          variant="outlined"
          fullWidth
          margin="normal"
          label='Sua renda *'
          error={!!errors.monthlyIncome}
          helperText={errors.monthlyIncome?.message}
          onFocus={(e) => e.target.select()}
          value={command.monthlyIncome}
          defaultValue={command.monthlyIncome}
          InputProps={{
            inputComponent: NumberFormatCustom as any
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            handleItemValue('monthlyIncome', value);
          }}
        />
      </Grid>
      {isPJ && (
        <>
          {/* <Grid item xs={12} md={12}>
            <TextField
              control={control}
              erro={errors}
              inputRef={register}
              name="motherName"
              label="Nome da mãe *"
            />
          </Grid> */}

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={command.selfDeclaredLegalRepresentative}
                  color="primary"
                  name="selfDeclaredLegalRepresentative"
                  onChange={handleChechedItem}
                />
              }
              label="Se identifica como representante legal do CNPJ?"
            />
            {errors?.selfDeclaredLegalRepresentative && (
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                {errors?.selfDeclaredLegalRepresentative?.message}
              </p>
            )}
            {/* error={errors.selfDeclaredLegalRepresentative} */}
          </Grid>
        </>
      )}
    </Grid>
  );
}
