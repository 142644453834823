import React from 'react'
import Drawer from 'src/components/ui/Drawer'
import ResumoMedico from './resumoMedico'
import { ResumoMedicoComAssistente } from 'src/types'
import { CircularProgress, Box } from '@material-ui/core'

interface Props {
  open: boolean
  handleOnClose: () => void
  resumoMedico: ResumoMedicoComAssistente
  loading: boolean
  parceiro?: boolean
}

export default function DrawerResumoMedico({
  open,
  handleOnClose,
  resumoMedico,
  loading,
  parceiro
}: Props) {
  return (
    <Drawer
      open={open}
      anchor="right"
      handleOnClose={handleOnClose}
      titulo="Detalhes do médico"
    >
      {loading ? (
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      ) : (
        <ResumoMedico resumo={resumoMedico} parceiro={parceiro} />
      )}
    </Drawer>
  )
}
