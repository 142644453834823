import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import ClearIcon from '@material-ui/icons/Clear'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { BsInfoCircle } from 'react-icons/bs'
import { FiFilter } from 'react-icons/fi'
import { GoSearch } from 'react-icons/go'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { obterLocaisAtendimento } from 'src/api/dominio'
import { listarTodasEspecilidades } from 'src/api/especialidade'
import { obterMedicos } from 'src/api/medico'
import {
  bloquearPacoteInteligente,
  deletarPacoteInteligente,
  ItemListagemPacoteInteligente,
  liberarPacoteInteligente,
  listagemPacoteInteligente,
  ListagemPacoteInteligenteQuery
} from 'src/api/pacoteInteligente'
import ModalLiberarAcesso from 'src/components/modals/ModalSenhaMaster'
import DrawerPacoteInteligenteVisualizarDetalhes from 'src/components/pacoteInteligente/Drawer/resumo'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import Button from 'src/components/ui/Button'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import UBox from 'src/components/ui/Ubox'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { formatarData } from 'src/core/formatacoes/formatarData'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import useListagem from 'src/hooks/useListagem'
import {
  EspecialidadeResponse,
  ObjetoDescricaoGenerico,
  ResumoMedicoResponse
} from 'src/types'
import Swal from 'sweetalert2'
import useWindowInfiniteScroll from '../../../../hooks/useWindowInfiniteScroll'

const useStyles = makeStyles(theme => ({
  layoutFiltro: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '4px',
    margin: '4px 8px',
    padding: '12px 24px'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}))

function ListagemPacotesInteligentes() {
  var style = useStyles()
  const { perfilAtual, ehTenantSyn } = useAuth()
  const [mostrarDetalhe, setMostrarDetalhe] = useState(false)
  const [dataInicio, setDataInicio] = useState('')
  const [dataFim, setDataFim] = useState(null)
  const [dataInicioDirty, setDataInicioDirty] = useState(false)
  const [dataFimDirty, setDataFimDirty] = useState(false)
  const [diasFiltro, setDiasFiltro] = useState('periodo')

  const [showFiltros, setShowFiltros] = useState(false)
  const [
    itemSelecionado,
    setItemSelecionado
  ] = useState<ItemListagemPacoteInteligente>(undefined)

  const history = useHistory()

  const {
    loading,
    dados,
    handleTermoPesquisa,
    termoPesquisa,
    nenhumDado,
    nenhumResultado,
    dadosCarregados,
    allDadosCarregados,
    loadingPagina,
    allDados,
    qtdAllDados,
    handleProxPagina,
    filtroGeral,
    atualizarItemListagem,
    removerItemListagem,
    setFiltroGeral,
    carregarListagem,
    handleLimparFiltros,
    handleFiltroData
  } = useListagem(9, listagem)

  function listagem(
    query: ListagemPacoteInteligenteQuery,
    primeiraBusca?: boolean
  ) {
    query['perfilAtual'] = perfilAtual
    query['medicos'] = medicosSelecionados.map(e => e.id)
    query['especialidades'] = especialidadeSelecionada.map(e => e.id)
    if (!!hospitalSelecionado) {
      query['hospitalId'] = hospitalSelecionado?.id
    }
    if (!!ufSelecionada) {
      query['uf'] = ufSelecionada?.id
    }
    query['mostrarLiberados'] = mostrarLiberados
    query['mostrarAguardandoLiberacao'] = mostrarAguardandoLiberacao

    if (!!dataInicio) {
      query['dataInicio'] = dataInicio
    }

    if (!!dataFim) {
      query['dataFim'] = dataFim
    }
    return listagemPacoteInteligente(query)
  }
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [medicosSelecionados, setMedicosSelecionados] = useState<
    ResumoMedicoResponse[]
  >([])
  const [especialidades, setEspecialidades] = useState<
    EspecialidadeResponse[]
  >()
  const [loadingEspecialidades, setLoadingEspecialidades] = useState(false)
  const [loadingLiberacao, setLoadingLiberacao] = useState(false)

  const [mostrarLiberados, setMostrarLiberados] = useState(true)
  const [mostrarAguardandoLiberacao, setMostrarAguardandoLiberacao] = useState(
    true
  )

  const [pacoteSelecionado, setPacoteSelecionado] = useState(null)

  const [listaMedicos, setListaMedicos] = useState<ResumoMedicoResponse[]>([])
  const [loadingMedicos, setLoadingMedicos] = useState<boolean>(true)

  const [listaUf, setListaUf] = useState<ObjetoDescricaoGenerico[]>(null)
  const [ufSelecionada, setUFSelecionada] = useState<any>(null)
  const [showModal, setShowModal] = useState(false)
  const [podeExcluirItem, setPodeExcluirItem] = useState<ItemListagemPacoteInteligente>()

  const [
    hospitalSelecionado,
    setHospitalSelecionado
  ] = useState<ObjetoDescricaoGenerico>(null)
  const [locaisAtendimentos, setLocaisAtendimentos] = useState<
    ObjetoDescricaoGenerico[]
  >([])

  const [
    loadingLocaisAtendimentos,
    setLoadingLocaisAtendimentos
  ] = useState<boolean>(true)

  function handleLimparFiltrosC() {
    setMedicosSelecionados([])
    setEspecialidadeSelecionada([])
    setHospitalSelecionado(null)
    setUFSelecionada(null)
    setMostrarLiberados(true)
    setMostrarAguardandoLiberacao(true)
    setDataInicio('')
    setDataFim('')
    handleLimparFiltros()
  }
  const handleSetDiaFiltro = e => {
    var valor = e.target.value
    setDiasFiltro(valor)
  }

  const limparFiltroData = () => {
    setDiasFiltro('periodo')
    setDataInicio('')
    setDataFim('')
    handleFiltroData('', '')
  }
  const handleFiltroPorData = () => {
    if (diasFiltro === 'periodo') {
      limparFiltroData()
    } else if (diasFiltro === 'hoje') {
      CarregarDataAtualFiltro()
    } else if (diasFiltro === '3Dias') {
      CarregarDataPodDiaFiltro(3)
    } else if (diasFiltro === '7Dias') {
      CarregarDataPodDiaFiltro(7)
    } else if (diasFiltro === '15Dias') {
      CarregarDataPodDiaFiltro(15)
    } else if (diasFiltro === '30Dias') {
      CarregarDataPodDiaFiltro(30)
    }
  }

  const CarregarDataPodDiaFiltro = dias => {
    var dataFiltro = new Date()
    var day = dataFiltro.getDate().toString().padStart(2, '0')
    var year = dataFiltro.getFullYear()
    var month = (dataFiltro.getMonth() + 1).toString().padStart(2, '0')
    setDataFim(year + '-' + month + '-' + day)

    var dataFim = year + '-' + month + '-' + day

    dataFiltro.setDate(dataFiltro.getDate() - dias)
    year = dataFiltro.getFullYear()
    month = (dataFiltro.getMonth() + 1).toString().padStart(2, '0')
    var dayModificado = dataFiltro.getDate().toString().padStart(2, '0')

    setDataInicio(year + '-' + month + '-' + dayModificado)

    handleFiltroData(year + '-' + month + '-' + dayModificado, dataFim)
  }

  const CarregarDataAtualFiltro = () => {
    var dt = new Date()
    var year = dt.getFullYear()
    var month = (dt.getMonth() + 1).toString().padStart(2, '0')
    var day = dt.getDate().toString().padStart(2, '0')

    setDataInicio(year + '-' + month + '-' + day)
    setDataFim(year + '-' + month + '-' + day)
    setDiasFiltro('hoje')

    handleFiltroData(
      year + '-' + month + '-' + day,
      year + '-' + month + '-' + day
    )
  }

  useWindowInfiniteScroll({
    loadMoreFunc: handleProxPagina,
    data: allDados,
    stopLoadingData: allDados.length >= qtdAllDados
  })

  const handleVisualizarDetalhes = (item: ItemListagemPacoteInteligente) => {
    if (perfilAtual !== SynPerfis.PARCEIRO_EXTERNO) {
      setItemSelecionado(item)
      setMostrarDetalhe(true)
    }
  }

  function handleNovoPacote() {
    history.push(SynRoutes.formPacoteInteligente)
  }

  function handleEditar(id) {
    history.push(`${SynRoutes.formPacoteInteligente}?idPacote=${id}`)
  }

  function handleDelete(item: ItemListagemPacoteInteligente) {
    setShowModal(true)
    setPodeExcluirItem(item)
  }

  async function confirmDelete(podeExcluir: boolean) {
    if (podeExcluir === true) {
      setLoadingLiberacao(true)
      if (!podeExcluirItem?.id) {
        return
      }
      try {
        await deletarPacoteInteligente(podeExcluirItem?.id)
        removerItemListagem(podeExcluirItem?.id)
      } catch (error) {
        showNotificationErrorAPI(error)
      }
      setLoadingLiberacao(false)
    }
  }

  function dadosModal() {
    return {
      copy:
        'A funcionalidade de deletar exige autorização master',
      titulo: 'Acesso Restrito',
      open: showModal,
      handleClose: () => setShowModal(false),
      onConfirm: ehConfirm => confirmDelete(ehConfirm)
    }
  }

  const handleFecharDetalhes = () => {
    setMostrarDetalhe(false)
  }

  function handleChangeEspecialidade(
    event,
    newValue: ObjetoDescricaoGenerico[]
  ) {
    var value = newValue.map(v => v.id)
    setEspecialidadeSelecionada(newValue)

    setFiltroGeral({
      especialidades: value,
      pagina: 1,
      qtdPorPagina: 9
    })
  }

  function handleChangeHospital(event, newValue: ObjetoDescricaoGenerico) {
    setHospitalSelecionado(newValue)

    setFiltroGeral({
      pagina: 1,
      qtdPorPagina: 9
    })
  }

  function handleChangeUF(event, newValue: ObjetoDescricaoGenerico[]) {
    setUFSelecionada(newValue)

    setFiltroGeral({
      pagina: 1,
      qtdPorPagina: 9
    })
  }

  function handleChangeMedicos(event, newValue: ResumoMedicoResponse[]) {
    var value = newValue.map(v => v.id)
    setMedicosSelecionados(newValue)

    setFiltroGeral({
      medicos: value,
      pagina: 1,
      qtdPorPagina: 9
    })
  }

  async function handleLiberar(item: ItemListagemPacoteInteligente) {
    var result = await Swal.fire({
      title: `<p>Deseja realmente liberar o pacote "${item.descricao.toUpperCase()}" para os médicos?</p>`,
      icon: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await liberarPacote(item)
      },
      allowOutsideClick: () => !Swal.isLoading()
    })

    if (result.isDismissed) {
      return
    }
  }

  async function liberarPacote(item: ItemListagemPacoteInteligente) {
    if (!item.id) {
      return
    }

    setLoadingLiberacao(true)
    setPacoteSelecionado(item)
    try {
      var response = await liberarPacoteInteligente(item.id)
      item.status = 3
      atualizarItemListagem(item.id, item)

      await Swal.fire({
        title: 'Sucesso!',
        text: 'Pacote foi liberado para utilização dos médicos.',
        icon: 'success'
      })
    } catch (error) {
      showNotificationErrorAPI(error)
    }
    setLoadingLiberacao(false)
  }

  async function handleBloquear(item: ItemListagemPacoteInteligente) {
    var result = await Swal.fire({
      title: `Deseja realmente bloquear o pacote "${item.descricao.toUpperCase()}" para os médicos?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await bloquearPacote(item)
      },
      allowOutsideClick: () => !Swal.isLoading()
    })

    if (result.isDismissed) {
      return
    }
  }

  async function bloquearPacote(item: ItemListagemPacoteInteligente) {
    if (!item.id) {
      return
    }

    setLoadingLiberacao(true)
    setPacoteSelecionado(item)
    try {
      var response = await bloquearPacoteInteligente(item.id)
      item.status = 1
      atualizarItemListagem(item.id, item)

      await Swal.fire({
        title: 'Sucesso!',
        text: 'Pacote foi bloqueado para utilização dos médicos.',
        icon: 'success'
      })
    } catch (error) {
      showNotificationErrorAPI(error)
    }
    setLoadingLiberacao(false)
  }

  const carregarLocaisAtendimentos = async () => {
    return obterLocaisAtendimento()
      .then(response => {
        var ufs = response.data.map(i => i.grupo)
        let ufsDistintas = [...new Set(ufs)]
          .filter(i => !!i)
          .map(i => {
            return { id: i, descricao: i }
          })

        setListaUf(ufsDistintas || [])
        setLocaisAtendimentos(response.data || [])
      })
      .finally(() => {
        setLoadingLocaisAtendimentos(false)
      })
  }

  useEffect(() => {
    setLoadingEspecialidades(true)

    listarTodasEspecilidades()
      .then(response => setEspecialidades(response.data))
      .catch(err => showNotificationErrorAPI(err))

    obterMedicos()
      .then(response => {
        setLoadingMedicos(false)
        setListaMedicos(response.data)
      })
      .catch(() => { })

    carregarLocaisAtendimentos()

    setLoadingEspecialidades(false)
  }, [])

  useEffect(() => {
    handleFiltroPorData()
  }, [diasFiltro])

  useEffect(() => {
    carregarListagem({ resetarPaginacao: true })
  }, [mostrarLiberados, mostrarAguardandoLiberacao])

  function setClassToHighlight(item) {
    if (item?.id === itemSelecionado?.id) return 'highlight'

    return null
  }

  function obterCorCard(status: number) {
    //aguardando revisão
    if (status === 1) return '#f7b422'
    //revisar opme
    if (status === 2) return '#e64a4a'
    //revisado
    if (status === 3) return '#1cc3a5'
  }
  return (
    <Box>
      {showModal && <ModalLiberarAcesso isDelete={true} {...dadosModal()} />}
      {/* <ModalLiberarAcesso {...dadosModal()} /> */}
      <UBox>
        {/* e:{JSON.stringify(especialidades)} */}
        {/* <br /> */}
        {/* e:{JSON.stringify(especialidadeSelecionada)}
        <br />
        f:{JSON.stringify(filtroGeral?.especialidades)} */}
        <Box mb={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <UBox>
                <TextField
                  fullWidth
                  placeholder="Pesquise por procedimento, código ou nome do médico..."
                  size="small"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  value={termoPesquisa}
                  onChange={handleTermoPesquisa}
                />
              </UBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                color="default"
                variant="text"
                onClick={() => setShowFiltros(!showFiltros)}
                startIcon={<FiFilter />}
              >
                {showFiltros ? 'Esconder' : 'Mostrar'} filtros
                {/* <FiFilter style={{ marginLeft: '10px' }} /> */}
                {/* <FilterListIcon style={{ marginLeft: '10px' }} /> */}
              </Button>
            </Grid>
            {showFiltros && (
              <Grid item xs={12} className={style.layoutFiltro}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Autocomplete
                        erro={false}
                        size="small"
                        multiple
                        name="especialidades"
                        label={'Especialidades'}
                        value={especialidadeSelecionada || []}
                        getOptionSelected={option =>
                          !!especialidadeSelecionada.find(
                            l => l.id === option.id
                          )
                        }
                        options={especialidades || []}
                        loading={loadingEspecialidades}
                        showCheck
                        closeOnSelect
                        showAddButton
                        getOptionLabel={option => option?.descricao}
                        onChange={handleChangeEspecialidade}
                        InputProps={{
                          autoComplete: 'new-password'
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Autocomplete
                        erro={false}
                        size="small"
                        multiple
                        name="medicosFiltro"
                        label={'Medicos'}
                        value={medicosSelecionados || []}
                        getOptionSelected={option =>
                          !!medicosSelecionados.find(l => l.id === option.id)
                        }
                        options={listaMedicos || []}
                        loading={loadingMedicos}
                        showCheck
                        closeOnSelect
                        showAddButton
                        getOptionLabel={option => option?.nome}
                        onChange={handleChangeMedicos}
                        InputProps={{
                          autoComplete: 'new-password'
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options={listaUf}
                      value={ufSelecionada?.descricao}
                      multiple={false}
                      size="small"
                      name="uf"
                      label="Informe a UF do hospital"
                      erro={{}}
                      closeOnSelect
                      getOptionLabel={option => `${option.descricao}`}
                      // getOptionSelected={option => option === command?.uf}
                      onClickAddButton={() => { }}
                      onChange={handleChangeUF}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      fullWidth
                      erro={{}}
                      size="small"
                      name="hospital"
                      label="Informe o hospital"
                      loading={loadingLocaisAtendimentos}
                      options={locaisAtendimentos.filter(i =>
                        !!ufSelecionada ? i.grupo === ufSelecionada?.id : true
                      )}
                      value={hospitalSelecionado}
                      // showAddButton
                      multiple={false}
                      closeOnSelect
                      getOptionLabel={option =>
                        `${!!option.grupo ? option.grupo : 'N/A'} - ${option.descricao
                        }`
                      }
                      onClickAddButton={() => { }}
                      onChange={handleChangeHospital}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <FormGroup>
                      <FormControlLabel
                        value={mostrarLiberados}
                        control={
                          <Checkbox
                            checked={mostrarLiberados}
                            onChange={(e, value) => {
                              setMostrarLiberados(value)
                            }}
                          />
                        }
                        label="Mostrar pacotes liberados"
                      />
                      <FormControlLabel
                        value={mostrarAguardandoLiberacao}
                        control={
                          <Checkbox
                            checked={mostrarAguardandoLiberacao}
                            onChange={(e, value) => {
                              setMostrarAguardandoLiberacao(value)
                            }}
                          />
                        }
                        label="Mostrar pacotes em configuração"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Select
                        value={diasFiltro}
                        onChange={e => {
                          handleSetDiaFiltro(e)
                        }}
                      >
                        <MenuItem value="periodo">Período</MenuItem>
                        <MenuItem value="hoje">Hoje</MenuItem>
                        <MenuItem key="2" value="3Dias">
                          Últimos 3 dias
                        </MenuItem>
                        <MenuItem key="3" value="7Dias">
                          Últimos 7 dias
                        </MenuItem>
                        <MenuItem key="4" value="15Dias">
                          Últimos 15 dias
                        </MenuItem>
                        <MenuItem key="5" value="30Dias">
                          Últimos 30 dias
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <TextField
                      id="dataInicio"
                      error={dataInicioDirty && !formatarData(dataInicio)}
                      helperText={
                        dataInicioDirty && !formatarData(dataInicio)
                          ? 'Data inválida'
                          : ''
                      }
                      label="De"
                      type="date"
                      defaultValue={dataInicio}
                      value={dataInicio}
                      className={style.textField}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onKeyDown={e => {
                        setDataInicioDirty(true)
                      }}
                      onChange={e => {
                        setDataInicio(e.target.value)
                      }}
                    />

                    <TextField
                      error={dataFimDirty && !formatarData(dataFim)}
                      helperText={
                        dataFimDirty && !formatarData(dataFim)
                          ? 'Data inválida'
                          : ''
                      }
                      id="dataFim"
                      label="Até"
                      type="date"
                      defaultValue={dataFim}
                      value={dataFim}
                      className={style.textField}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onKeyDown={e => {
                        setDataFimDirty(true)
                      }}
                      onChange={e => {
                        setDataFim(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1} >
                    {diasFiltro === 'periodo' && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleFiltroData(dataInicio, dataFim)}
                      >
                        Filtrar
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
              <Button
                loading={loading}
                disabled={loading}
                size="small"
                fullWidth={true}
                variant="contained"
                color="secondary"
                startIcon={<GoSearch />}
                onClick={e => {
                  carregarListagem({
                    resetarPaginacao: true,
                    dataInicio: dataInicio,
                    dataFim: dataFim
                  })
                }}
              >
                Pesquisar (Enter)
              </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Button
                size="small"
                fullWidth={true}
                variant="contained"
                color="primary"
                startIcon={<ClearIcon />}
                onClick={e => {
                  limparFiltroData()
                  handleLimparFiltrosC()
                }}
              >
                Limpar filtros
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            {/* <TextField
            fullWidth
            placeholder="Pesquise por procedimento ou código..."
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            value={termoPesquisa}
            onChange={handleTermoPesquisa}
          /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box textAlign="right">
              <Button
                // fullWidth={isMobile}
                variant="contained"
                color="primary"
                onClick={handleNovoPacote}
                startIcon={<AddIcon />}
              >
                Cadastrar novo pacote
              </Button>
            </Box>
          </Grid>
        </Grid>

        {!loading && (
          <Box mt={3} mb={2} textAlign="left" my={3}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" color="primary">
                  {qtdAllDados
                    ? qtdAllDados > 1
                      ? `${qtdAllDados} pacotes`
                      : '1 pacote'
                    : '0 pacotes'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {nenhumResultado && (
          <div>Nenhum resultado encontrado para sua pesquisa.</div>
        )}
        {allDadosCarregados && !loadingPagina && (
          <div className="fadeInTransition">
            <Grid container spacing={2}>
              {allDados.map(item => (
                <Grid item xs={12} md={4} key={item?.id}>
                  <Card
                    variant="outlined"
                    className={setClassToHighlight(item)}
                  >
                    <CardHeader
                      action={
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {popupState => (
                            <React.Fragment>
                              <UBox
                                render={
                                  perfilAtual !== SynPerfis.PARCEIRO_EXTERNO
                                }
                              >
                                <IconButton
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  aria-label="mais"
                                  {...bindTrigger(popupState)}
                                >
                                  <MoreVertIcon />
                                </IconButton>

                                <Menu
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                  }}
                                  {...bindMenu(popupState)}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      popupState.close()
                                      handleVisualizarDetalhes(item)
                                    }}
                                  >
                                    Visualizar detalhes
                                  </MenuItem>

                                  <MenuItem
                                    onClick={() => {
                                      popupState.close()
                                      handleEditar(item.id)
                                    }}
                                  >
                                    Editar
                                  </MenuItem>

                                  <MenuItem
                                    onClick={() => {
                                      popupState.close()
                                      handleDelete(item)
                                    }}
                                    style={{ color: '#cc3025', fontWeight: 500 }}
                                  >
                                    Excluir
                                  </MenuItem>

                                  {item.status === 2 && (
                                    <MenuItem
                                      onClick={() => {
                                        popupState.close()
                                        handleVisualizarDetalhes(item)
                                      }}
                                    >
                                      Revisar itens OPME
                                    </MenuItem>
                                  )}
                                </Menu>
                              </UBox>
                            </React.Fragment>
                          )}
                        </PopupState>
                      }
                      title={
                        <Link
                          component="button"
                          variant="body1"
                          color="primary"
                          align="left"
                          onClick={() => handleVisualizarDetalhes(item)}
                        >
                          <b style={{ textTransform: 'uppercase' }}>
                            {item?.descricao}
                          </b>
                          <UBox>
                            <Typography variant="body2" color="secondary">
                              <b>{item?.hospital}</b>
                            </Typography>
                            <Tooltip
                              title={
                                item?.status === 1
                                  ? 'Disponivel para solicitações'
                                  : 'Necessário revisar itens de OPME'
                              }
                            >
                              <Box
                                mr={1}
                                // '#20a773'
                                style={{
                                  width: '40px',
                                  height: '6px',
                                  background: obterCorCard(item?.status),
                                  borderRadius: '6px'
                                }}
                              ></Box>
                            </Tooltip>
                          </UBox>
                        </Link>
                      }
                      titleTypographyProps={{ variant: 'subtitle1' }}
                      subheaderTypographyProps={{ variant: 'body2' }}
                    />
                    <CardContent style={{ borderTop: '1px solid #eee' }}>
                      <UBox
                        mb={1}
                        render={item?.status === 1}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          color: obterCorCard(item?.status)
                        }}
                      >
                        <BsInfoCircle />

                        <small style={{ marginLeft: '4px', display: 'block' }}>
                          Aguardando revisão do responsável
                        </small>
                      </UBox>

                      <UBox mb={2}>
                        <Typography variant="body2" color="primary">
                          {/* <b> */}

                          {!!item?.dataExpiracao
                            ? `Válido até ${formatarData(item?.dataExpiracao)}`
                            : 'Sem data de expiração'}
                          {/* </b> */}
                        </Typography>
                      </UBox>
                      <UBox mb={2}>
                        <Typography variant="body2" color="primary">
                          <b>Valor base:</b>
                        </Typography>
                        <Typography variant="body2" color="primary">
                          {formatarDinheiro(item?.valorBase)}
                        </Typography>
                      </UBox>
                      <UBox mb={2}>
                        <Typography variant="body2" color="primary">
                          <b>Disponibilidade para médicos:</b>
                        </Typography>
                        <Typography variant="body2" color="primary">
                          {item?.pacotePublico
                            ? 'Todos os médicos da especialidade'
                            : item?.medicos?.join(', ')}
                        </Typography>
                      </UBox>
                      <UBox>
                        <Typography variant="body2" color="primary">
                          <b>Especialidades:</b>
                        </Typography>
                        {item?.especialidades.map((e, i) => (
                          <Chip
                            key={i}
                            label={e}
                            size="small"
                            style={{ marginRight: '6px', marginTop: '4px' }}
                          />
                        ))}
                      </UBox>
                      <UBox mt={2} render={item?.status === 1}>
                        <Button
                          size="small"
                          fullWidth
                          variant="outlined"
                          // component="button"
                          // variant="body2"
                          loading={
                            loadingLiberacao &&
                            pacoteSelecionado?.id === item?.id
                          }
                          color="secondary"
                          // align="left"
                          onClick={() => {
                            handleLiberar(item)
                          }}
                        // style={{ marginTop: '10px' }}
                        >
                          Liberar para médicos
                        </Button>
                      </UBox>
                      <UBox mt={2} render={item?.status === 2}>
                        <Button
                          size="small"
                          fullWidth
                          // variant="outlined"
                          // component="button"
                          // variant="body2"
                          // loading={
                          //   loadingAddPacote &&
                          //   item?.id === pacoteSelecionado?.id
                          // }
                          color="secondary"
                          // align="left"
                          onClick={() => { }}
                        // style={{ marginTop: '10px' }}
                        >
                          Revisar itens OPME
                        </Button>
                      </UBox>
                      <UBox mt={2} render={item?.status === 3}>
                        <Button
                          size="small"
                          fullWidth
                          variant="outlined"
                          // component="button"
                          // variant="body2"
                          loading={
                            loadingLiberacao &&
                            pacoteSelecionado?.id === item?.id
                          }
                          color="secondary"
                          // align="left"
                          onClick={() => {
                            handleBloquear(item)
                          }}
                        // style={{ marginTop: '10px' }}
                        >
                          Bloquear para médicos
                        </Button>
                      </UBox>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
        {loading && <LoadingGeral />}
        <Box
          mt={3}
          hidden={qtdAllDados === allDados.length || loading}
          textAlign="center"
        >
          <Button
            fullWidth
            onClick={handleProxPagina}
            variant="text"
            disabled={loading}
            loading={loading}
            endIcon={<MdKeyboardArrowDown />}
          >
            Carregar mais
          </Button>
        </Box>
        <DrawerPacoteInteligenteVisualizarDetalhes
          id={itemSelecionado?.id}
          exibirValor={true}
          open={mostrarDetalhe}
          handleOnClose={handleFecharDetalhes}
        />
      </UBox>
    </Box>
  )
}

export default ListagemPacotesInteligentes
