import { createContext } from 'react'
import { IAddAtividadeCommand } from 'src/api/atividade'

export interface IAtividadeContext {
  loading: boolean
  obterQtdePendente: (solicitacaoId?: string) => number
  obterQtdeFutura: (solicitacaoId?: string) => number
  // qtdePendente:
  // qtdeConcluida: number
  atividadesHoje: IAtividade[]
  atividadesFuturas: IAtividade[]
  atividadesAtrasadas: IAtividade[]
  atividadesConcluidas: IAtividade[]
  adicionarAtividade: (atividade: IAddAtividadeCommand) => void
  removerAtividade: (atividadeId: string) => void
  concluirAtividade: (atividadeId: string) => void
  atualizarListaAtividades: () => void
  atualizarHubConnection: () => void
  buscarAtividade: (id: string) => Promise<IAtividade>
}

export interface IAtividade {
  id: string
  titulo: string
  descricao: string
  dataHora: string
  solicitacao: ISolicitacaoAtividade
  usuarios: IUsuarioAtividade[]
  concluida: boolean
  concluidaPor?: string
  dataConcluida: string
  criadaPor?: string
  loading: boolean
}

export interface ISolicitacaoAtividade {
  codigo: string
  descricao: string
  id: string
}

export interface IUsuarioAtividade {
  nome: string
  id: string
}

const AuthContext = createContext<IAtividadeContext>({} as any)

export default AuthContext
