const SynTipoParceiro = {
  OPME: { id: '9aa4c20f-9912-4e77-a989-c59fdcf426d6', descricao: 'OPME' },
  HOSPITAL_CLINICA: {
    id: '2756800b-a1c7-4ab4-aee5-68f78bcb5db4',
    descricao: 'Hospital/Clínica'
  }
}

export const SynTipoParceiroArray = [
  {
    descricao: 'OPME',
    id: '9aa4c20f-9912-4e77-a989-c59fdcf426d6'
  },
  {
    id: '2756800b-a1c7-4ab4-aee5-68f78bcb5db4',
    descricao: 'Hospital/Clínica'
  }
] as const

export default SynTipoParceiro
