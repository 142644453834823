import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  TextField,
  Typography,
  FormControl,
  Select,
  useMediaQuery
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom'
import { SynRoutes } from 'src/core/consts/synRoutes'
import DrawerVisualizarParceiros from './drawerVisualizarParceiros'
import useListagem from 'src/hooks/useListagem'
import {
  buscarParceiroPorId,
  listarParceiros,
  excluirParceiro,
  obterItemListagemPorId
} from 'src/api/parceiro'
import { IParceiro } from 'src/types'
import formatarCNPJ from 'src/core/formatacoes/formatarCNPJ'
import Swal from 'sweetalert2'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import SynTipoParceiro, {
  SynTipoParceiroArray
} from 'src/core/consts/synTipoParceiro'
import ButtonUI from 'src/components/ui/Button'
import { MdKeyboardArrowDown } from 'react-icons/md'
import useWindowInfiniteScroll from 'src/hooks/useWindowInfiniteScroll'
import SetoresLocalAtendimento from '../../../../core/consts/setorLocalAtendimento'
import estados from 'src/core/consts/estados'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import { SynPerfis } from 'src/core/consts/synPerfis'
import useAuth from 'src/hooks/useAuth'
import { listarTodasEspecilidades } from 'src/api/especialidade'
import { EspecialidadeResponse } from '../../../../../src/types'
import UBox from 'src/components/ui/Ubox'
import DrawerDadosBancario from 'src/components/DadosBancario/Drawer'
import tipoDadosBancario from 'src/core/consts/tipoDadosBancario'

function listagemParceiros() {
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))
  const { user, perfilAtual, ehTenantSyn } = useAuth()
  const ehComercialB2b = perfilAtual === SynPerfis.COMERCIAL_B2B
  const ehComercialMed = perfilAtual === SynPerfis.COMERCIAL_MEDICOS
  const ehComercialExpansao = perfilAtual === SynPerfis.COMERCIAL_EXPANSAO

  const [dadosBancarioId, setDadosBancarioId] = useState('')
  const [mostrarDadosBancario, setMostrarDadosBancario] = useState(false)

  const [mostrarDetalhe, setMostrarDetalhe] = useState(false)
  const [itemSelecionado, setItemSelecionado] = useState<IParceiro>(undefined)
  const [lastSelectedCard, setLastSelectedCard] = useState(null)
  const [tipoParceiroSelecionado, setTipoParceiroSelecionado] = useState(null)
  const [uf, setUF] = useState(null)
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState(null)
  const [especialidades, setEspecialidades] = useState<
    EspecialidadeResponse[]
  >()
  const [loadingEspecialidadeFiltro, setLoadingEspecialidadeFiltro] = useState(
    false
  )

  const history = useHistory()

  const {
    loading,
    handleTermoPesquisa,
    nenhumResultado,
    termoPesquisa,
    carregarListagem,
    allDados,
    qtdAllDados,
    handleProxPagina,
    setFiltroGeral,
    filtroGeral,
    atualizarItemListagem
  } = useListagem(9, listagem)

  function listagem(query, primeiraBusca?: boolean) {
    query['perfilAtual'] = perfilAtual
    return listarParceiros(query)
  }

  useWindowInfiniteScroll({
    data: allDados,
    loadMoreFunc: handleProxPagina,
    stopLoadingData: allDados.length >= qtdAllDados
  })

  function handleVisualizarDetalhes(item: IParceiro) {
    if (perfilAtual !== SynPerfis.PARCEIRO_EXTERNO) {
      setLastSelectedCard(item)
      buscarParceiroPorId(item.id)
        .then(response => {
          setItemSelecionado(response.data)
          setMostrarDetalhe(true)
        })
        .catch(err => {
          showNotificationErrorAPI(err)
        })
    }
  }

  function handleOpenDadosBancario(item: IParceiro) {
    setLastSelectedCard(item)
    setDadosBancarioId(item?.dadosBancarioId)
    setMostrarDadosBancario(true)
  }

  async function handleCloseDadosBancario(atualizou, id?, tipoConta?) {
    setMostrarDadosBancario(false)
    setDadosBancarioId(null)
    try {
      if (!!atualizou) {
        const { data } = await obterItemListagemPorId(id)
        atualizarItemListagem(id, data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    setLoadingEspecialidadeFiltro(true)

    listarTodasEspecilidades()
      .then(response => setEspecialidades(response.data))
      .catch(err => showNotificationErrorAPI(err))

    setLoadingEspecialidadeFiltro(false)
  }, [])

  function handleExcluirParceiro(parceiroId: string) {
    Swal.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja apagar este parceiro?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Apagar'
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        excluirParceiro(parceiroId)
          .then(() => {
            carregarListagem({ resetarPaginacao: false })
          })
          .catch(err => showNotificationErrorAPI(err))
      }
    })
  }

  const handleFecharDetalhes = () => {
    setMostrarDetalhe(false)
    setItemSelecionado(undefined)
  }

  function handleNovoParceiro() {
    history.push(SynRoutes.formParceirosOperacional)
  }

  function setClassToHighlight(item) {
    if (item?.id === lastSelectedCard?.id) return 'highlight'

    return null
  }

  function setTipoParceiro(tipo: string) {
    if (tipo === SynTipoParceiro.OPME.id) return SynTipoParceiro.OPME.descricao
    if (tipo === SynTipoParceiro.HOSPITAL_CLINICA.id) {
      return SynTipoParceiro.HOSPITAL_CLINICA.descricao
    }
  }

  return (
    <Box>
      <Box
        mb={2}
        display="flex"
        flexDirection={ehXS ? 'column' : 'row'}
        justifyContent="space-between"
      >
        <Typography variant="h5">Parceiros</Typography>

        <Box textAlign="right">
          {perfilAtual !== SynPerfis.FINANCEIRO && (
            <Button
              fullWidth={ehXS}
              variant="contained"
              color="primary"
              onClick={handleNovoParceiro}
              startIcon={<AddIcon />}
            >
              Cadastrar parceiro
            </Button>
          )}
        </Box>
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              placeholder="Pesquise pelo parceiro..."
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              value={termoPesquisa}
              onChange={handleTermoPesquisa}
            />
          </Grid>
          {ehTenantSyn && (
            <Grid item xs={12} md={2}>
              <FormControl variant="outlined" fullWidth size="small">
                <Select
                  value={filtroGeral?.tipoParceiro || ' '}
                  onChange={e => {
                    setTipoParceiroSelecionado(
                      e.target
                        .value as typeof SynTipoParceiroArray[number]['id']
                    )
                    setFiltroGeral({
                      tipoParceiro: e.target
                        .value as typeof SynTipoParceiroArray[number]['id'],
                      pagina: 1,
                      qtdPorPagina: 9,
                      uf: uf
                    })
                  }}
                >
                  <MenuItem value=" ">Todos os tipos</MenuItem>
                  {SynTipoParceiroArray.map(item => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.descricao}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}

          {ehTenantSyn &&
            !ehComercialExpansao &&
            perfilAtual !== SynPerfis.PARCEIRO_EXTERNO && (
              <Grid item xs={12} md={2}>
                <FormControl variant="outlined" fullWidth size="small">
                  <Select
                    value={filtroGeral?.uf || ' '}
                    onChange={e => {
                      setUF(e.target.value)
                      setFiltroGeral({
                        uf: e.target.value,
                        pagina: 1,
                        qtdPorPagina: 9,
                        tipoParceiro: tipoParceiroSelecionado
                      })
                    }}
                  >
                    <MenuItem value=" ">UF</MenuItem>
                    {estados.map((estado, i) => (
                      <MenuItem key={i} value={estado.Initials}>
                        {estado.Initials}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" fullWidth size="small">
              <Select
                value={filtroGeral?.especialidade || ' '}
                disabled={
                  loadingEspecialidadeFiltro
                  //  || tipoParceiroSelecionado !==
                  //   '9aa4c20f-9912-4e77-a989-c59fdcf426d6'
                }
                fullWidth
                endAdornment={
                  <InputAdornment
                    position="end"
                    style={{ marginRight: '16px' }}
                  >
                    {loadingEspecialidadeFiltro ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <></>
                    )}
                  </InputAdornment>
                }
                onChange={e => {
                  setEspecialidadeSelecionada(e.target.value)
                  setFiltroGeral({
                    especialidade: e.target.value,
                    uf: uf,
                    pagina: 1,
                    qtdPorPagina: 9,
                    tipoParceiro: tipoParceiroSelecionado
                  })
                }}
              >
                <MenuItem value=" ">Especialidade</MenuItem>
                {especialidades?.map((especialidade, i) => (
                  <MenuItem key={i} value={especialidade.id}>
                    {especialidade.descricao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {!loading && (
        <Box mt={3} mb={2} textAlign="left" my={3}>
          <Typography variant="h6" color="primary">
            {qtdAllDados
              ? qtdAllDados > 1
                ? `${qtdAllDados} parceiros`
                : '1 parceiro'
              : '0 parceiros'}
          </Typography>
        </Box>
      )}

      {nenhumResultado && (
        <div>Nenhum resultado encontrado para sua pesquisa.</div>
      )}
      <div className="fadeInTransition">
        <Grid container spacing={2} key={qtdAllDados.toString()}>
          {allDados?.map(item => (
            <Grid item xs={12} md={4} key={item.id}>
              <Card variant="outlined" className={setClassToHighlight(item)}>
                <CardHeader
                  action={
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {popupState => (
                        <React.Fragment>
                          <>
                            {perfilAtual !== SynPerfis.PARCEIRO_EXTERNO &&
                              perfilAtual !== SynPerfis.FINANCEIRO && (
                                <>
                                  <IconButton
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    aria-label="mais"
                                    {...bindTrigger(popupState)}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>

                                  <Menu
                                    anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right'
                                    }}
                                    {...bindMenu(popupState)}
                                  >
                                    <MenuItem
                                      onClick={() => {
                                        popupState.close()
                                        handleVisualizarDetalhes(item)
                                      }}
                                    >
                                      Visualizar
                                    </MenuItem>
                                    {(perfilAtual ===
                                      SynPerfis.OPERACIONAL_HOSPITAL ||
                                      perfilAtual ===
                                        SynPerfis.ADMIN_HOSPITAL ||
                                      perfilAtual === SynPerfis.OPERACIONAL ||
                                      perfilAtual === SynPerfis.COMERCIAL_B2B ||
                                      perfilAtual ===
                                        SynPerfis.COMERCIAL_MEDICOS) && (
                                      <MenuItem
                                        onClick={() => {
                                          popupState.close()
                                          handleOpenDadosBancario(item)
                                        }}
                                      >
                                        Dados bancários
                                      </MenuItem>
                                    )}

                                    <MenuItem
                                      onClick={() => {
                                        popupState.close()
                                        history.push(
                                          `${SynRoutes.formParceirosOperacional}?id=${item.id}`
                                        )
                                      }}
                                    >
                                      Editar
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        popupState.close()
                                        handleExcluirParceiro(item.id)
                                      }}
                                      className="danger"
                                    >
                                      Excluir
                                    </MenuItem>
                                  </Menu>
                                </>
                              )}
                          </>
                        </React.Fragment>
                      )}
                    </PopupState>
                  }
                  title={
                    <>
                      {perfilAtual !== SynPerfis.PARCEIRO_EXTERNO ? (
                        <Link
                          component="button"
                          variant="body1"
                          color="primary"
                          align="left"
                          onClick={() => handleVisualizarDetalhes(item)}
                        >
                          {item.nomeFantasia}
                        </Link>
                      ) : (
                        <>{item.nomeFantasia}</>
                      )}

                      <Typography variant="subtitle2">
                        {setTipoParceiro(item.tipo)}
                      </Typography>
                      {item.uf && (
                        <Typography variant="subtitle2">
                          UF: {item.uf}
                        </Typography>
                      )}
                      {item.dadosBancarioId && (
                        <Box mt={1}>
                          <Typography variant="body2">
                            Dados bancários configurado
                          </Typography>
                        </Box>
                      )}
                    </>
                  }
                  subheader={`CNPJ: ${formatarCNPJ(item.cnpj)}`}
                  titleTypographyProps={{ variant: 'subtitle1' }}
                  subheaderTypographyProps={{ variant: 'body2' }}
                />

                {item.podeSolicitar && (
                  <Alert severity="info">
                    <Typography variant="subtitle2">
                      Pode solicitar orçamentos
                    </Typography>
                  </Alert>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>

      {loading && <LoadingGeral />}

      <Box
        mt={3}
        hidden={qtdAllDados === allDados.length || loading}
        textAlign="center"
      >
        <ButtonUI
          fullWidth
          onClick={handleProxPagina}
          variant="text"
          disabled={loading}
          loading={loading}
          endIcon={<MdKeyboardArrowDown />}
        >
          Carregar mais
        </ButtonUI>
      </Box>

      <DrawerVisualizarParceiros
        open={mostrarDetalhe}
        handleOnClose={handleFecharDetalhes}
        parceiro={itemSelecionado}
      />

      {mostrarDadosBancario && lastSelectedCard?.id && (
        <DrawerDadosBancario
          open={mostrarDadosBancario}
          handleOnClose={handleCloseDadosBancario}
          id={dadosBancarioId}
          parceiroId={lastSelectedCard?.id}
          tipoConta={tipoDadosBancario.Parceiro}
        />
      )}
    </Box>
  )
}

export default listagemParceiros
