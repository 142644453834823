import { createContext, useContext, useEffect, useState } from 'react'

import { authLogin } from '../api/auth'
import useLocalStorage from '../hooks/useLocalStorage'
import { AddProfile, AddTenant, AddToken, AddUserHeader } from '../services/api'

import { useHistory } from 'react-router-dom'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { SynRoutes } from 'src/core/consts/synRoutes'
import {
  AuthResponse,
  AuthSynSaude,
  PerfilTentantResponse,
  UserAuth
} from 'src/types'
import { ModalAtualizacoesContext } from './ModalAtualizacoes'
import { mostrarTutorial } from 'src/api/usuario'
import { IResumoNotificacao } from 'src/components/notificacoes/menu'

import _notificacoes from 'src/api/notificacao'

import TipoTermo from 'src/core/consts/tipoTermo'
import SynTipoParceiro from 'src/core/consts/synTipoParceiro'
const AuthContext = createContext<AuthSynSaude>({} as any)

const tenantSyn = '3317a776-e06b-4afc-b755-b8880f612a8d'
export const AuthProvider = ({ children }) => {
  const [
    mostrouTutorialLogado,
    setMostrouTutorialLogado,
    removeMostrouTutorialLogado
  ] = useLocalStorage('@authApp: MostrouTutorialLogado')
  const [storageUser, setStorageUser, removeStorageUser] = useLocalStorage(
    '@authApp: user'
  )
  const [
    storageProfile,
    setStorageProfile,
    removeStorageProfile
  ] = useLocalStorage('@authApp: profile')
  const [
    storageTenant,
    setStorageTenant,
    removeStorageTenant
  ] = useLocalStorage('@authApp: tenant')
  const [storageToken, setStorageToken, removeStorageToken] = useLocalStorage(
    '@authApp: token'
  )
  const [
    storageLogout,
    setStorageLogout,
    removeStorageLogout
  ] = useLocalStorage('@authApp: logout')

  const history = useHistory()

  const [
    resumoNotificacoes,
    setResumoNotificacoes
  ] = useState<IResumoNotificacao>(null)
  const [loadingResumo, setLoadingResumo] = useState(false)

  const [user, setUser] = useState<UserAuth>(null)
  const [loading, setLoading] = useState(true)
  const [hubConectado, setHubConectado] = useState<boolean>(false)

  const [tutorialAberto, setTutorialAberto] = useState(false)

  const { abrirModal } = useContext(ModalAtualizacoesContext)

  useEffect(() => {
    if (!!storageLogout) {
      removeStorageLogout()
      // alert('Usuário não tem acesso ou sessão expirada')
    }

    if (storageUser && storageToken) {
      setUser(storageUser)
      AddToken(storageToken)
      AddUserHeader(storageUser?.id)
    }

    if (storageProfile && storageTenant) {
      AddTenant(storageTenant)
      AddProfile(storageProfile)
    }

    setLoading(false)
  }, [])

  useEffect(() => {
    verificaMostraTutorial()
  }, [
    user?.mostrarTutorial,
    user?.aceitouTermo,
    user?.aceitouPoliticaPrivacidade
  ])

  function verificaMostraTutorial() {
    if (
      user?.mostrarTutorial &&
      storageProfile === SynPerfis.MEDICO &&
      !mostrouTutorialLogado &&
      user?.aceitouTermo &&
      user?.aceitouPoliticaPrivacidade
    ) {
      setTutorialAberto(true)
      setMostrouTutorialLogado(true)
    }
  }

  useEffect(() => {
    if (
      !!user &&
      !storageProfile &&
      (!(
        user.perfisPorTenant.length > 1 ||
        user.perfisPorTenant[0].perfis.length > 1
      ) ||
        !(user.perfis.length > 1))
    ) {
      setPerfilAtual(user.perfis[0], user.perfisPorTenant[0])
    }
    setTimeout(() => {
      if (!!storageUser?.id && !!storageProfile) {
        // console.log('auth', storageUser, user, storageProfile)
        atualizarResumo()
      }
    }, 1000)
  }, [user, storageUser, storageProfile])

  const signIn = async values => {
    setLoading(true)
    setStorageProfile(null)
    try {
      const response = await authLogin(values)

      if (
        response.data.verResumoAtualizacoes &&
        !response.data.primeiroAcesso &&
        response.data.perfis?.find(p => p === SynPerfis.OPERACIONAL)
      ) {
        abrirModal()
      }

      setUserSyn(response.data)

      return true
    } catch (error) {
      setLoading(false)
      // alert('Erro ao logar')
      throw new Error('errado')
    }
  }

  const setPrimeiroAcessoConfigurado = () => {
    let newUser = user
    newUser.primeiroAcesso = false
    setUser(newUser)
    setStorageUser(newUser)
  }

  function setAceitouTermo(value: number) {
    let newUser = user
    if (value === TipoTermo.privacidade) {
      newUser.aceitouPoliticaPrivacidade = true
    } else {
      newUser.aceitouTermo = true
    }
    verificaMostraTutorial()
    setUser(newUser)
    setStorageUser(newUser)
  }

  function obterNome() {
    if (!storageProfile) return ''
    if (storageProfile === SynPerfis.MEDICO) {
      return `Dr(a). ${user.perfisNome?.find(w => w.idPerfil === storageProfile)?.nome
        }`
    }

    return user.perfisNome?.find(w => w.idPerfil === storageProfile)?.nome
  }

  async function toggleTutorial(aberto: boolean, habilitado?: boolean) {
    if (!storageProfile) return ''
    if (
      aberto &&
      storageProfile === SynPerfis.MEDICO &&
      !!user &&
      user?.aceitouTermo &&
      user?.aceitouPoliticaPrivacidade
    ) {
      setTutorialAberto(aberto)
    } else {
      setTutorialAberto(false)
    }
    if (habilitado !== undefined) {
      try {
        await mostrarTutorial(habilitado)

        let novoUsuario = user
        novoUsuario.mostrarTutorial = habilitado
        setUser(novoUsuario)
        setStorageUser(novoUsuario)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const setUserSyn: (response: AuthResponse) => void = response => {
    let user = {
      id: response.id,
      nome: response.nome,
      cpf: response.cpf,
      perfis: response.perfis,
      perfisPorTenant: response.perfisPorTenant,
      primeiroAcesso: response.primeiroAcesso,
      tipoParceiro: response.tipoParceiro,
      resetarToken: response.resetarToken,
      verResumoAtualizacoes: response.verResumoAtualizacoes,
      setorFuncionario: response.setorFuncionario,
      lstSetorFuncionario: response.lstSetorFuncionario,
      aceitouTermo: response.aceitouTermo,
      temPacote: response.temPacote,
      perfisNome: response.perfisNome,
      mostrarTutorial: response.mostrarTutorial,
      verDashboard: response.verDashboard,
      aceitouPoliticaPrivacidade: response.aceitouPoliticaPrivacidade
    }
    setUser(user)
    setStorageUser(user)
    setStorageToken(response.authToken)
    AddToken(response.authToken)
    AddUserHeader(user?.id)

    // setTimeout(() => {
    setLoading(false)
    // }, 3000)
  }

  const signOut = (redirecionar = true) => {
    //Todo: Ver se devo ignorar loading
    // setLoading(false)

    removeStorageUser()
    removeStorageToken()
    removeStorageProfile()
    removeStorageTenant()
    setTutorialAberto(false)
    setUser(null)
    setMostrouTutorialLogado(false)
    //Verificar redirecionamento por history
    if (redirecionar) {
      history.replace(SynRoutes.signin)
    }
  }

  const setPerfilAtual = (perfil: string, tenant: PerfilTentantResponse) => {
    if (
      user?.mostrarTutorial &&
      perfil === SynPerfis.MEDICO &&
      user?.aceitouTermo &&
      user?.aceitouPoliticaPrivacidade
    ) {
      setMostrouTutorialLogado(true)
      setTutorialAberto(true)
    }

    switch (perfil) {
      case SynPerfis.ADMIN_HOSPITAL:
      case SynPerfis.TESOURARIA_HOSPITAL:
      case SynPerfis.COTACAO_HOSPITAL:
      case SynPerfis.OPERACIONAL_HOSPITAL:
      // case SynPerfis.AGENDAMENTO:
        setSetorParceiro(perfil)
        if (!!user) {
          user.tipoParceiro = SynTipoParceiro.HOSPITAL_CLINICA.id
          setUser(user)
        }
        if (!!storageUser) {
          storageUser.tipoParceiro = SynTipoParceiro.HOSPITAL_CLINICA.id
          setStorageUser(user)
        }
        break
      // default:
      //   setSetorParceiro(null)
      //   break
    }

    AddTenant(tenant.tenantId)
    AddProfile(perfil)
    setStorageProfile(perfil)
    setStorageTenant(tenant.tenantId)
  }

  function obterTenantAtual() {
    return user.perfisPorTenant.find(i => i.tenantId === storageTenant)
  }

  const setSetorParceiro = (setor: string) => {
    user.setorFuncionario = setor
    setStorageUser(user)
  }

  async function atualizarResumo() {
    try {
      if (storageUser && !!user && !!user?.id && !!storageProfile) {
        setLoadingResumo(true)
        const { data: resumo } = await _notificacoes.obterResumoNotificacoes()
        setResumoNotificacoes(resumo)
        setLoadingResumo(false)
      }
    } catch (error) { }
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        perfilAtual: storageProfile,
        tenantAtual: storageTenant,
        ehTenantSyn: storageTenant === tenantSyn,
        hubConectado,
        obterTenantAtual,
        setHubConectado,
        signIn,
        signOut,
        setUserSyn,
        setPerfilAtual,
        setSetorParceiro,
        setPrimeiroAcessoConfigurado,
        loading,
        setAceitouTermo,
        obterNome,
        toggleTutorial,
        tutorialAberto,
        loadingResumo,
        atualizarResumo,
        resumoNotificacoes
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
