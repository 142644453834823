import { Chip } from '@material-ui/core'
import React from 'react'
import {
  obterCorPorStatusId,
  obterDescricaoPorStatusId
} from 'src/core/consts/statusSolicitacao'

type ChipStatusOrcamentoProps = {
  statusId: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
  perfil?:
  | 'parceiro'
  | 'operacional'
  | 'paciente'
  | 'medico'
  | 'comercial'
  | 'secretaria'
  fontSize?: string
  orcamentoAprovado?: boolean
  existePropostaAguardandoPaciente?: boolean
  ehAgendamento?: boolean
  preAgendamento?: boolean
}

function ChipStatusOrcamento(props: ChipStatusOrcamentoProps) {
  return (
    <Chip
      label={obterDescricaoPorStatusId({
        statusId: props.statusId,
        perfil: props.perfil,
        orcamentoAprovado: props.orcamentoAprovado,
        existePropostaAguardandoPaciente: props.existePropostaAguardandoPaciente,
        ehAgendamento: props.ehAgendamento,
        preAgendamento: props.preAgendamento
      })}
      size="small"
      onClick={props.onClick}
      style={{
        // color: obterCorPorStatusId({
        //   statusId: props.statusId,
        //   perfil: props.perfil,
        //   orcamentoAprovado: props.orcamentoAprovado
        // }).color,
        border:
          '2px solid ' +
          obterCorPorStatusId({
            statusId: props.statusId,
            perfil: props.perfil,
            orcamentoAprovado: props.orcamentoAprovado,
            ehAgendamento: props.ehAgendamento,
            preAgendamento: props.preAgendamento
          }).backgroundColor,
        color: obterCorPorStatusId({
          statusId: props.statusId,
          perfil: props.perfil,
          orcamentoAprovado: props.orcamentoAprovado,
          ehAgendamento: props.ehAgendamento,
          preAgendamento: props.preAgendamento
        }).backgroundColor,
        // backgroundColor: obterCorPorStatusId({
        //   statusId: props.statusId,
        //   perfil: props.perfil,
        //   orcamentoAprovado: props.orcamentoAprovado
        // }).backgroundColor,
        background: 'none',
        fontWeight: 600,
        fontSize: props.fontSize ? props.fontSize : ''
      }}
    />
  )
}

export default ChipStatusOrcamento
