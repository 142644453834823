function informarcaoAlert(item) {
  const itemCentroAmbulatorial =
    item?.centroCirurgicoAmbulatorial ||
    item?.propostaCentroCirurgicoAmbulatorial
  const itemFinalDeSemana = item?.finalDeSemana || item?.propostaFinalDeSemana
  const itemDiasUteis =
    item?.diasUteis || item?.propostaDiasUteis || !itemFinalDeSemana

  if (itemCentroAmbulatorial && itemDiasUteis) {
    return 'Para realização em qualquer dia e horário da semana no centro cirúrgico ambulatorial.'
  } else if (itemCentroAmbulatorial && itemFinalDeSemana) {
    return 'Para realização somente em finais de semana no centro cirúrgico ambulatorial.'
  } else if (!itemCentroAmbulatorial && itemDiasUteis) {
    return 'Para realização em qualquer dia e horário da semana no centro cirúrgico.'
  } else if (!itemCentroAmbulatorial && itemFinalDeSemana) {
    return 'Para realização somente em finais de semana no centro cirúrgico.'
  } else if (!itemCentroAmbulatorial && !itemDiasUteis && !itemFinalDeSemana) {
    return 'Para realização em qualquer dia e horário no centro cirúrgico.'
  } else {
    return 'Informação não disponível.'
  }
}

export default informarcaoAlert
