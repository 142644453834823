import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core'
import { Button } from 'src/components/ui/Button'

interface IConfirmTemplateProps {
  open: boolean
  handleClose: () => void
  showConfirm: () => void
}

export function ModalTransformTemplate({
  open,
  handleClose,
  showConfirm
}: IConfirmTemplateProps) {
  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <Box mb={2} mt={1}>
          <Typography variant="h6">Deseja tornar este pacote seu template?</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="default">
          Não
        </Button>
        <Button
          type="button"
          color="primary"
          onClick={showConfirm}>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  )
}
