import React, { useEffect, useState } from 'react'
import Drawer from 'src/components/ui/Drawer'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { obterOrcamentoPorId } from 'src/api/solicitacao-orcamento'
import { obterPacotePorId, obterPacotePorIdPaciente } from 'src/api/pacote'

// import { ResumoOrcamento } from '../../medico/solicitacao/resumo'
// import { mapToForm } from '../../medico/solicitacao/formV2'
// import { IPacoteDetalhadoResponse } from '../../../../types'
import { obterPacoteInteligentePorId } from 'src/api/pacoteInteligente'
import { IPacoteInteligenteForm } from 'src/components/pacoteInteligente/FormPacoteInteligente'
import { debug } from 'console'
import { IPacoteDetalhadoResponse } from 'src/types'
import { mapToForm } from 'src/pages/dashboard/medico/solicitacao/formV2'
import { ResumoOrcamento } from 'src/pages/dashboard/medico/solicitacao/resumo'
import ResumoOrcamentoHospital from 'src/pages/dashboard/parceiro/hospital/components/resumoOrcamento'

type Props = {
  id: string
  open: boolean
  handleOnClose: () => void
  dadosSensiveis?: boolean
  exibirValores?: boolean
}

function DrawerVisualizarDetalhes({
  id,
  open,
  handleOnClose,
  dadosSensiveis,
  exibirValores = false
}: Props) {
  const [detalheVisualizacao, setDetalheVisualizacao] = useState(null)
  const [pacoteDetalhe, setPacoteDetalhe] = useState(null)
  const [pacoteInteligente, setPacoteInteligente] = useState(false)
  const [loadingDetalhe, setLoadingDetalhe] = useState(false)

  // useEffect(() => {
  //   if (!id || !open) {
  //     return
  //   }
  //   setDetalheVisualizacao(null)
  //   setLoadingDetalhe(true)
  //   obterOrcamentoPorId(id)
  //     .then(async response => {
  //       const orcamento = response.data
  //       setDetalheVisualizacao(mapToForm(orcamento))
  //       //Carregar pacote
  //       var data: IPacoteDetalhadoResponse | IPacoteInteligenteForm = {} as any
  //       setPacoteInteligente(orcamento.pacoteInstantaneo)
  //       if (orcamento.pacoteInstantaneo) {
  //         const response = await obterPacoteInteligentePorId(
  //           orcamento.pacoteInteligenteId
  //         )

  //         data = response.data
  //       } else if (dadosSensiveis) {
  //         // TODO: VERIFICAR DADOS Sensiveis
  //         const response = await obterPacotePorIdPaciente(
  //           orcamento.pacoteProcedimentoId
  //         )

  //         data = response.data
  //       } else {
  //         const response = await obterPacotePorId(
  //           orcamento.pacoteProcedimentoId
  //         )

  //         data = response.data
  //       }

  //       setPacoteDetalhe(data)
  //       setLoadingDetalhe(false)
  //     })
  //     .catch(e => {
  //       showNotificationErrorAPI(e)
  //       handleOnClose()
  //     })
  // }, [open])

  return (
    <Drawer
      loading={loadingDetalhe}
      anchor="right"
      open={open}
      titulo="Ver solicitação"
      handleOnClose={handleOnClose}
      style={{ padding: 60 }}
    >
      {/* {!!detalheVisualizacao && (
        <ResumoOrcamento
          pacoteInteligente={pacoteInteligente}
          orcamento={detalheVisualizacao}
          pacoteSelecionado={pacoteDetalhe}
          exibirValores={exibirValores}
          dadosSensiveis={dadosSensiveis || false}
        />
      )} */}

      <ResumoOrcamentoHospital solicitacaoId={id} isHospital />
    </Drawer>
  )
}

export default DrawerVisualizarDetalhes
