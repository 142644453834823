import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Grid,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Link,
  TableContainer,
  Paper
} from '@material-ui/core'
import { ItemListagemSolicitacaoOrcamentoResponse } from '../../types'
import StatusProposta from '../../core/consts/statusProposta'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { SynRoutes } from '../../core/consts/synRoutes'
import ChipStatusOrcamento from 'src/components/solicitacao/ChipStatusOrcamento'
import UBox from '../ui/Ubox'
import useAuth from 'src/hooks/useAuth'
import { SynPerfis } from 'src/core/consts/synPerfis'

interface Props {
  data: ItemListagemSolicitacaoOrcamentoResponse[]
  ultimaSolicitacaoVista: ItemListagemSolicitacaoOrcamentoResponse
  setUltimaSolicitacao: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleAlterarStatus: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleExcluir: (id: string) => void
  handleLogStatus: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
  handleVisualizarDetalhes: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  handleVisualizarParceiros: (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  handleAbrirModalTag: (item: ItemListagemSolicitacaoOrcamentoResponse) => void
}

export default function TableSolicitacaoOrcamento({
  data,
  ultimaSolicitacaoVista,
  setUltimaSolicitacao,
  handleAlterarStatus,
  handleExcluir,
  handleLogStatus,
  handleVisualizarDetalhes,
  handleVisualizarParceiros,
  handleAbrirModalTag
}: Props) {
  const history = useHistory()
  const { signOut, user, perfilAtual } = useAuth()
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Solicitação</TableCell>
            <TableCell>Médico</TableCell>
            <TableCell>Paciente</TableCell>
            <TableCell>Orçamentos</TableCell>
            <TableCell>Propostas</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ultimaSolicitacaoVista !== undefined && (
            <TableRow key={ultimaSolicitacaoVista.id}>
              <TableCell>
                (
                {perfilAtual !== SynPerfis.PARCEIRO_EXTERNO ? (
                  <Link
                    component="button"
                    variant="body1"
                    color="primary"
                    align="left"
                    onClick={() =>
                      handleVisualizarDetalhes(ultimaSolicitacaoVista)
                    }
                  >
                    {`${ultimaSolicitacaoVista.codigo} - `}
                    {ultimaSolicitacaoVista.descricaoPacoteProcedimento}
                  </Link>
                ) : (
                  <>
                    {`${ultimaSolicitacaoVista.codigo} - `}
                    {ultimaSolicitacaoVista.descricaoPacoteProcedimento}
                  </>
                )}
                )
              </TableCell>
              <TableCell>{ultimaSolicitacaoVista.nomeMedico}</TableCell>
              <TableCell>{ultimaSolicitacaoVista.nomePaciente}</TableCell>
              <TableCell>
                {!ultimaSolicitacaoVista.qtdOrcamentos ? (
                  <Typography variant="body2" style={{ marginTop: '10px' }}>
                    Nenhum orçamento ativo
                  </Typography>
                ) : (
                  <Grid container>
                    {ultimaSolicitacaoVista.qtdOrcamentosRecusados > 0 && (
                      <Grid item xs={12} sm={12}>
                        <Typography variant="body2" style={{ color: 'red' }}>
                          {ultimaSolicitacaoVista.qtdOrcamentosRecusados === 1
                            ? '1 Recusado'
                            : `${ultimaSolicitacaoVista.qtdOrcamentosRecusados} Recusados`}
                        </Typography>
                      </Grid>
                    )}
                    {ultimaSolicitacaoVista.qtdOrcamentosOPME > 0 && (
                      <Grid item xs={12} sm={12}>
                        <Typography variant="body2">
                          {ultimaSolicitacaoVista.qtdOrcamentosOPME} OPME
                        </Typography>
                      </Grid>
                    )}
                    {ultimaSolicitacaoVista.qtdOrcamentosHospital > 0 && (
                      <Grid item xs={12} sm={12}>
                        <Typography variant="body2">
                          {ultimaSolicitacaoVista.qtdOrcamentosHospital}{' '}
                          Hospital
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                )}
              </TableCell>
              <TableCell>
                {!ultimaSolicitacaoVista.qtdPropostas ? (
                  <Typography variant="body2">
                    Nenhuma proposta ainda
                  </Typography>
                ) : (
                  <Grid container>
                    {ultimaSolicitacaoVista.qtdPropostasVencedoras > 0 && (
                      <Grid item xs={12} sm={12}>
                        <Typography
                          style={{
                            color: StatusProposta.VENCEDORA.Color
                          }}
                          variant="body2"
                        >
                          1 Proposta vencedora
                        </Typography>
                      </Grid>
                    )}
                    {ultimaSolicitacaoVista.qtdPropostasEnviadas > 0 && (
                      <Grid item xs={12} sm={12}>
                        <Typography
                          style={{
                            color:
                              StatusProposta.AGUARDANDO_APROVACAO_MEDICO.Color
                          }}
                          variant="body2"
                        >
                          {ultimaSolicitacaoVista.qtdPropostasEnviadas}{' '}
                          Aguardando aprovação médica
                        </Typography>
                      </Grid>
                    )}
                    {ultimaSolicitacaoVista.qtdPropostasAprovadas > 0 && (
                      <Grid item xs={12} sm={12}>
                        <Typography
                          style={{
                            color: StatusProposta.APROVADA_PELO_MEDICO.Color
                          }}
                          variant="body2"
                        >
                          {ultimaSolicitacaoVista.qtdPropostasAprovadas === 1
                            ? '1 Aprovada pelo médico'
                            : `${ultimaSolicitacaoVista.qtdPropostasAprovadas} Aprovadas pelo médico`}
                        </Typography>
                      </Grid>
                    )}
                    {ultimaSolicitacaoVista.qtdPropostasRecusadas > 0 && (
                      <Grid item xs={12} sm={12}>
                        <Typography
                          style={{
                            color: StatusProposta.RECUSADA_PELO_MEDICO.Color
                          }}
                          variant="body2"
                        >
                          {ultimaSolicitacaoVista.qtdPropostasRecusadas === 1
                            ? '1 Recusada'
                            : `${ultimaSolicitacaoVista.qtdPropostasRecusadas} Recusadas`}
                        </Typography>
                      </Grid>
                    )}
                    {ultimaSolicitacaoVista.qtdPropostasAguardandoPaciente >
                      0 && (
                      <Grid item xs={12} sm={12}>
                        <Typography
                          style={{
                            color:
                              StatusProposta.AGUARDANDO_APROVACAO_PACIENTE.Color
                          }}
                          variant="body2"
                        >
                          {`${ultimaSolicitacaoVista.qtdPropostasAguardandoPaciente} Aguardando paciente`}
                        </Typography>
                      </Grid>
                    )}
                    {ultimaSolicitacaoVista.qtdPropostasAprovadasPaciente >
                      0 && (
                      <Grid item xs={12} sm={12}>
                        <Typography
                          style={{
                            color: StatusProposta.APROVADA_PELO_PACIENTE.Color
                          }}
                          variant="body2"
                        >
                          {ultimaSolicitacaoVista.qtdPropostasAprovadasPaciente ===
                          1
                            ? '1 Aprovada pelo paciente'
                            : `${ultimaSolicitacaoVista.qtdPropostasAprovadasPaciente} Aprovadas pelo paciente`}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                )}
              </TableCell>
              <TableCell>
                <ChipStatusOrcamento
                  statusId={ultimaSolicitacaoVista.status?.id}
                />
              </TableCell>
              <TableCell>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {popupState => (
                    <React.Fragment>
                      <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        aria-label="mais"
                        {...bindTrigger(popupState)}
                      >
                        <MoreVertIcon />
                      </IconButton>

                      <Menu
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                        {...bindMenu(popupState)}
                      >
                        {!!ultimaSolicitacaoVista.qtdOrcamentos && (
                          <>
                            {perfilAtual !== SynPerfis.PARCEIRO_EXTERNO && (
                              <MenuItem
                                onClick={() => {
                                  popupState.close()
                                  history.push(
                                    `${SynRoutes.dadosSolicitacaoOperacionalOPME}?id=${ultimaSolicitacaoVista.id}`
                                  )
                                  setUltimaSolicitacao(ultimaSolicitacaoVista)
                                }}
                              >
                                Ver orçamentos
                              </MenuItem>
                            )}
                            <MenuItem
                              onClick={() => {
                                popupState.close()
                                handleVisualizarParceiros(
                                  ultimaSolicitacaoVista
                                )
                                setUltimaSolicitacao(ultimaSolicitacaoVista)
                              }}
                            >
                              Ver parceiros
                            </MenuItem>
                          </>
                        )}
                        {!!ultimaSolicitacaoVista.qtdPropostas && (
                          <MenuItem
                            onClick={() => {
                              if (perfilAtual === SynPerfis.PARCEIRO_EXTERNO) {
                                history.push(
                                  `${SynRoutes.detalhesPropostasMedico}?id=${ultimaSolicitacaoVista.id}`
                                )
                              } else {
                                history.push(
                                  `${SynRoutes.detalhesPropostas}?id=${ultimaSolicitacaoVista.id}`
                                )
                              }
                              setUltimaSolicitacao(ultimaSolicitacaoVista)
                            }}
                          >
                            Ver propostas
                          </MenuItem>
                        )}
                        <UBox
                          render={perfilAtual !== SynPerfis.PARCEIRO_EXTERNO}
                        >
                          <MenuItem
                            onClick={() => {
                              popupState.close()
                              handleAbrirModalTag(ultimaSolicitacaoVista)
                            }}
                          >
                            Tags
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              popupState.close()
                              handleAlterarStatus(ultimaSolicitacaoVista)
                            }}
                          >
                            Alterar status
                          </MenuItem>
                        </UBox>
                        <MenuItem
                          onClick={() => {
                            popupState.close()
                            handleLogStatus(ultimaSolicitacaoVista)
                          }}
                        >
                          Histórico de status
                        </MenuItem>
                        <UBox
                          render={perfilAtual !== SynPerfis.PARCEIRO_EXTERNO}
                        >
                          <MenuItem
                            onClick={() => {
                              popupState.close()
                              handleExcluir(ultimaSolicitacaoVista.id)
                            }}
                            className="danger"
                          >
                            Excluir
                          </MenuItem>
                        </UBox>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </TableCell>
            </TableRow>
          )}
          {data?.map(item => {
            if (ultimaSolicitacaoVista === undefined) {
              return (
                <TableRow key={item.id}>
                  <TableCell>
                    {perfilAtual !== SynPerfis.PARCEIRO_EXTERNO ? (
                      <Link
                        component="button"
                        variant="body1"
                        color="primary"
                        align="left"
                        onClick={() => handleVisualizarDetalhes(item)}
                      >
                        {`${item.codigo} - `}
                        {item.descricaoPacoteProcedimento}
                      </Link>
                    ) : (
                      <>
                        {`${item.codigo} - `}
                        {item.descricaoPacoteProcedimento}
                      </>
                    )}
                  </TableCell>
                  <TableCell>{item.nomeMedico}</TableCell>
                  <TableCell>{item.nomePaciente}</TableCell>
                  <TableCell>
                    {!item.qtdOrcamentos ? (
                      <Typography variant="body2" style={{ marginTop: '10px' }}>
                        Nenhum orçamento ativo
                      </Typography>
                    ) : (
                      <Grid container>
                        {item.qtdOrcamentosRecusados > 0 && (
                          <Grid item xs={12} sm={12}>
                            <Typography
                              variant="body2"
                              style={{ color: 'red' }}
                            >
                              {item.qtdOrcamentosRecusados === 1
                                ? '1 Recusado'
                                : `${item.qtdOrcamentosRecusados} Recusados`}
                            </Typography>
                          </Grid>
                        )}
                        {item.qtdOrcamentosOPME > 0 && (
                          <Grid item xs={12} sm={12}>
                            <Typography variant="body2">
                              {item.qtdOrcamentosOPME} OPME
                            </Typography>
                          </Grid>
                        )}
                        {item.qtdOrcamentosHospital > 0 && (
                          <Grid item xs={12} sm={12}>
                            <Typography variant="body2">
                              {item.qtdOrcamentosHospital} Hospital
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </TableCell>
                  <TableCell>
                    {!item.qtdPropostas ? (
                      <Typography variant="body2">
                        Nenhuma proposta ainda
                      </Typography>
                    ) : (
                      <Grid container>
                        {item.qtdPropostasVencedoras > 0 && (
                          <Grid item xs={12} sm={12}>
                            <Typography
                              style={{
                                color: StatusProposta.VENCEDORA.Color
                              }}
                              variant="body2"
                            >
                              1 Proposta vencedora
                            </Typography>
                          </Grid>
                        )}
                        {item.qtdPropostasEnviadas > 0 && (
                          <Grid item xs={12} sm={12}>
                            <Typography
                              style={{
                                color:
                                  StatusProposta.AGUARDANDO_APROVACAO_MEDICO
                                    .Color
                              }}
                              variant="body2"
                            >
                              {item.qtdPropostasEnviadas} Aguardando aprovação
                              médica
                            </Typography>
                          </Grid>
                        )}
                        {item.qtdPropostasAprovadas > 0 && (
                          <Grid item xs={12} sm={12}>
                            <Typography
                              style={{
                                color: StatusProposta.APROVADA_PELO_MEDICO.Color
                              }}
                              variant="body2"
                            >
                              {item.qtdPropostasAprovadas === 1
                                ? '1 Aprovada pelo médico'
                                : `${item.qtdPropostasAprovadas} Aprovadas pelo médico`}
                            </Typography>
                          </Grid>
                        )}
                        {item.qtdPropostasRecusadas > 0 && (
                          <Grid item xs={12} sm={12}>
                            <Typography
                              style={{
                                color: StatusProposta.RECUSADA_PELO_MEDICO.Color
                              }}
                              variant="body2"
                            >
                              {item.qtdPropostasRecusadas === 1
                                ? '1 Recusada'
                                : `${item.qtdPropostasRecusadas} Recusadas`}
                            </Typography>
                          </Grid>
                        )}
                        {item.qtdPropostasAguardandoPaciente > 0 && (
                          <Grid item xs={12} sm={12}>
                            <Typography
                              style={{
                                color:
                                  StatusProposta.AGUARDANDO_APROVACAO_PACIENTE
                                    .Color
                              }}
                              variant="body2"
                            >
                              {`${item.qtdPropostasAguardandoPaciente} Aguardando paciente`}
                            </Typography>
                          </Grid>
                        )}
                        {item.qtdPropostasAprovadasPaciente > 0 && (
                          <Grid item xs={12} sm={12}>
                            <Typography
                              style={{
                                color:
                                  StatusProposta.APROVADA_PELO_PACIENTE.Color
                              }}
                              variant="body2"
                            >
                              {item.qtdPropostasAprovadasPaciente === 1
                                ? '1 Aprovada pelo paciente'
                                : `${item.qtdPropostasAprovadasPaciente} Aprovadas pelo paciente`}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </TableCell>
                  <TableCell>
                    <ChipStatusOrcamento
                      statusId={item.status?.id}
                      fontSize="12px"
                    />
                  </TableCell>
                  <TableCell>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {popupState => (
                        <React.Fragment>
                          {perfilAtual !== SynPerfis.PARCEIRO_EXTERNO && (
                            <>
                              <IconButton
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                aria-label="mais"
                                {...bindTrigger(popupState)}
                              >
                                <MoreVertIcon />
                              </IconButton>

                              <Menu
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                                {...bindMenu(popupState)}
                              >
                                {!!item.qtdOrcamentos && (
                                  <>
                                    <UBox
                                      render={
                                        perfilAtual !==
                                        SynPerfis.PARCEIRO_EXTERNO
                                      }
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          popupState.close()
                                          history.push(
                                            `${SynRoutes.dadosSolicitacaoOperacionalOPME}?id=${item.id}`
                                          )
                                          setUltimaSolicitacao(item)
                                        }}
                                      >
                                        Ver orçamentos
                                      </MenuItem>
                                    </UBox>
                                    <MenuItem
                                      onClick={() => {
                                        popupState.close()
                                        handleVisualizarParceiros(item)
                                        setUltimaSolicitacao(item)
                                      }}
                                    >
                                      Ver parceiros
                                    </MenuItem>
                                  </>
                                )}
                                {!!item.qtdPropostas && (
                                  <MenuItem
                                    onClick={() => {
                                      if (
                                        perfilAtual ===
                                        SynPerfis.PARCEIRO_EXTERNO
                                      ) {
                                        history.push(
                                          `${SynRoutes.detalhesPropostasMedico}?id=${item.id}`
                                        )
                                      } else {
                                        history.push(
                                          `${SynRoutes.detalhesPropostas}?id=${item.id}`
                                        )
                                      }

                                      setUltimaSolicitacao(item)
                                    }}
                                  >
                                    Ver propostas
                                  </MenuItem>
                                )}

                                <MenuItem
                                  onClick={() => {
                                    popupState.close()
                                    handleAbrirModalTag(item)
                                  }}
                                >
                                  Tags
                                </MenuItem>

                                <MenuItem
                                  onClick={() => {
                                    popupState.close()
                                    handleAlterarStatus(item)
                                  }}
                                >
                                  Alterar status
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    popupState.close()
                                    handleLogStatus(item)
                                  }}
                                >
                                  Histórico de status
                                </MenuItem>
                                {/* <MenuItem
                              onClick={() => {
                                popupState.close()
                                handleExcluir(item.id)
                              }}
                              className="danger"
                            >
                              Excluir
                            </MenuItem> */}
                              </Menu>
                            </>
                          )}
                        </React.Fragment>
                      )}
                    </PopupState>
                  </TableCell>
                </TableRow>
              )
            } else {
              return <></>
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
