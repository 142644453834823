import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import 'react-credit-cards/es/styles-compiled.css'
import { useHistory } from 'react-router-dom'
import {
  PagamentoResumoResponse,
  realizarPagamentoPix
} from 'src/api/pagamento'
import Button from 'src/components/ui/Button'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import UBox from 'src/components/ui/Ubox'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import usePagamento from 'src/hooks/usePagamento'
import { useQuery } from 'src/hooks/useQuery'

const useStyles = makeStyles(theme => ({}))
interface IProps {
  integral: boolean
  valor?: number
  handleProximoPagamento?: () => void
}
export default function Boleto({
  integral = true,
  valor,
  handleProximoPagamento
}: IProps) {
  const styles = useStyles()
  const theme = useTheme()
  const query = useQuery()
  const history = useHistory()

  const { user } = useAuth()

  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))
  function obterValor() {
    return integral ? resumo.valorTotal : valor
  }

  function obterBoleto() {
    var b = integral ? boleto : boletoFracionado
    console.log(b)
    return b
  }
  const {
    loadingBoleto,
    resumo,
    boleto,
    boletoFracionado,
    pagamentoBoleto,
    copiarNumeroBoleto
  } = usePagamento()

  function navigateBlank(url) {
    window.open(url, '_blank')
  }

  useEffect(() => {}, [])

  if (!resumo) return <LoadingGeral />

  return (
    <UBox>
      {/* rend{JSON.stringify(obterBoleto)} */}
      <UBox render={!obterBoleto()}>
        <UBox
          mt={2}
          display="flex"
          alignItems="center"
          className="box-instructions"
        >
          <div className="icon-instructions">
            <i className="fa fa-print" />
          </div>
          <Typography variant="body2">
            Imprima o boleto e <b>pague no banco</b>
          </Typography>
        </UBox>
        <UBox
          mt={1}
          display="flex"
          alignItems="center"
          className="box-instructions"
        >
          <div className="icon-instructions">
            <i className="fa fa-barcode" />
          </div>
          <Typography variant="body2">
            <b>Ou pague pela internet</b> utilizando o código de barras do
            boleto
          </Typography>
        </UBox>
        <UBox
          mt={1}
          display="flex"
          alignItems="center"
          className="box-instructions"
        >
          <div className="icon-instructions">
            <i className="fa fa-calendar" />
          </div>
          <Typography variant="body2">
            O prazo de validade do boleto é de <b>3 dias corridos</b>
          </Typography>
        </UBox>
        <UBox
          mt={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body1">
            <b>Total:</b>
          </Typography>
          <Typography variant="body1">
            <b>{formatarDinheiro(obterValor())}</b>
          </Typography>
        </UBox>
        <UBox my={3}>
          <Button
            disabled={loadingBoleto || !obterValor()}
            color="primary"
            fullWidth
            loading={loadingBoleto}
            onClick={() =>
              pagamentoBoleto({ integral: integral, valor: obterValor() })
            }
          >
            Gerar boleto
          </Button>
        </UBox>
        <UBox>
          <b>Importante</b>
          <br />
          <ul>
            {/* <li>
              no período de 30 dias, se houver cinco ou mais boletos bancários
              de um mesmo cliente, não pagos, poderá ocorrer bloqueio temporário
              desta forma de quitação, por até 30 dias.
            </li> */}
            <li>
              Caso o seu computador tenha um programa anti pop-up, será preciso
              desativá-lo antes de finalizar sua compra e imprimir o boleto ou
              pagar pelo internet banking.
            </li>
            <li>
              Não faça depósito ou transferência entre contas. <br />O boleto
              não é enviado pelos correios. Imprima-o e pague-o no banco ou pela
              internet.
            </li>
            {/* <li>
              se o boleto não for pago até a data de vencimento, o pedido será
              automaticamente cancelado;
            </li> */}
            <li>
              O prazo de confirmação de pagamento é de três dias depois do
              pagamento do boleto, tempo necessário para que a instituição
              bancária confirme o pagamento.
            </li>
          </ul>
        </UBox>
      </UBox>
      <UBox
        render={!!obterBoleto()}
        style={{ background: '#f2f2f2' }}
        borderRadius="8px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={2}
        p={3}
      >
        <UBox mt={2} textAlign="center">
          <div
            className="icon-instructions back-green"
            style={{ margin: '0 auto' }}
          >
            <i className="fa fa-check" />
          </div>
          <UBox mt={1} textAlign="center">
            <Typography variant="body1" className="text-green">
              <b>Pedido concluído</b>
            </Typography>
          </UBox>
          <UBox my={2} textAlign="center">
            {/* número do pedido: 02-962114480
                  <br /> */}
            <Typography variant="body2">
              Confirmação do pedido enviada:
              <br />
              <b>Por favor verifique sua caixa de email e telefone.</b>
              <br />
            </Typography>
          </UBox>
          <UBox>
            <Button
              color="secondary"
              fullWidth
              loading={loadingBoleto}
              onClick={() => navigateBlank(obterBoleto().urlBoleto)}
            >
              Visualizar boleto
            </Button>
          </UBox>
          <UBox mt={1}>
            <Button
              color="primary"
              fullWidth
              variant="outlined"
              loading={loadingBoleto}
              onClick={() => copiarNumeroBoleto(obterBoleto())}
            >
              Copiar número do boleto
            </Button>
          </UBox>
        </UBox>
        {/* {JSON.stringify(boleto)} */}
      </UBox>
      <UBox render={!integral && !!obterBoleto()} my={3}>
        <Button color="primary" fullWidth onClick={handleProximoPagamento}>
          Próximo pagamento
        </Button>
      </UBox>
    </UBox>
  )
}
