import { useLocation } from 'react-router-dom'

export default function NotFound() {
  let location = useLocation()

  return (
    <div>
      <h3>
        Não foi possível encontrar a página <code>{location.pathname}</code>
      </h3>
    </div>
  )
}
