import { AxiosResponse } from 'axios';
import api from 'src/services/api'
import { ItemAtualizacao, ListagemResponse } from 'src/types';

export function listarAtualizacoes(): Promise<AxiosResponse<ListagemResponse<ItemAtualizacao>>> {
  try {
    return api.get('/atualizacoes/listagem')
  } catch (err) {
    console.log('@Error: ', err)
  }
}

export const toggleVerNovamente: (
  verAtualizacoes: boolean
) => Promise<AxiosResponse> = verAtualizacoes => {
  try {
    return api.post('/atualizacoes/toggleVerResumoAtualizacoes', verAtualizacoes)
  } catch (err) {
    console.log('@Error: ', err)
  }
}

export function obterUltimaAtualizacao(): Promise<AxiosResponse<ItemAtualizacao>> {
  try {
    return api.get('/atualizacoes/ultima')
  } catch (err) {
    console.log('@Error: ', err)
  }
}
