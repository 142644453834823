import { AxiosResponse } from 'axios'
import api from 'src/services/api'

export interface DashboardResumoResponse {
  qtdSolicitacoes: number
  qtdPropostas: number
  qtdSolicitacoesConvertidas: number
  qtdSolicitacoesNaoConvertidas: number
  solicitacoesStatus: ItemGenerico[]
  solicitacoesMaisPedidas: ItemGenerico[]
  solicitacoesPorMes: ItemGenerico[]
  solicitacoesPorMesAnoAnterior: ItemGenerico[]
  propostasStatus: ItemGenerico[]
  qtdOrcamentosHospitais: number
  qtdOrcamentosOpme: number
  valorTotalOrcamentosHospitais: number
  orcamentosPorHospitais: ItemGenerico[]
  valorTotalOrcamentosOpme: number
  qtdItensOpme: number
  orcamentosPorOpmes: ItemGenerico[]
  mediaDescontoSyn: number
  valorTotalPropostasRealizadas: number
  valorTotalPropostasEnviadas: number
  valorTotalComissaoSyn: number
  valorTotalHospitalRealizado: number
  valorTotalMedicoRealizado: number
  valorTotalOpmeRealizado: number
}

export interface ItemGenerico {
  descricao: string
  valor: number
  convertido: number
}
export function obterResumoDashboard(): Promise<
  AxiosResponse<DashboardResumoResponse>
> {
  try {
    return api.get('/dashboard/resumo')
  } catch (err) {
    console.log('@Error: ', err)
  }
}
