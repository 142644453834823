import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Search } from '@material-ui/icons'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useState, useEffect } from 'react'
import useListagem from 'src/hooks/useListagem'
import { listagemPacote } from 'src/api/pacote/index'
import {
  EspecialidadeResponse,
  ItemListagemPacoteProcedimentoResponse
} from 'src/types'
import DrawerVisualizarDetalhes from './drawerVisualizarDetalhes'
import useWindowInfiniteScroll from '../../../../hooks/useWindowInfiniteScroll'
import Button from 'src/components/ui/Button'
import { MdKeyboardArrowDown } from 'react-icons/md'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import { listarTodasEspecilidades } from 'src/api/especialidade'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import UBox from 'src/components/ui/Ubox'
import { SynPerfis } from 'src/core/consts/synPerfis'

function listagemPacotes() {
  const { perfilAtual } = useAuth()
  const [mostrarDetalhe, setMostrarDetalhe] = useState(false)
  const [
    itemSelecionado,
    setItemSelecionado
  ] = useState<ItemListagemPacoteProcedimentoResponse>(undefined)

  const {
    loading,
    dados,
    handleTermoPesquisa,
    termoPesquisa,
    nenhumDado,
    nenhumResultado,
    dadosCarregados,
    allDadosCarregados,
    allDados,
    qtdAllDados,
    handleProxPagina,
    filtroGeral,
    loadingPagina,
    setFiltroGeral
  } = useListagem(9, listagem)

  function listagem(query, primeiraBusca?: boolean) {
    query['perfilAtual'] = perfilAtual
    return listagemPacote(query)
  }
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState(null)
  const [especialidades, setEspecialidades] = useState<
    EspecialidadeResponse[]
  >()
  const [loadingEspecialidadeFiltro, setLoadingEspecialidadeFiltro] = useState(
    false
  )

  useWindowInfiniteScroll({
    loadMoreFunc: handleProxPagina,
    data: allDados,
    stopLoadingData: allDados.length >= qtdAllDados
  })

  const handleVisualizarDetalhes = (
    item: ItemListagemPacoteProcedimentoResponse
  ) => {
    if (perfilAtual !== SynPerfis.PARCEIRO_EXTERNO) {
      setItemSelecionado(item)
      setMostrarDetalhe(true)
    }
  }

  const handleFecharDetalhes = () => {
    setMostrarDetalhe(false)
  }

  useEffect(() => {
    setLoadingEspecialidadeFiltro(true)

    listarTodasEspecilidades()
      .then(response => setEspecialidades(response.data))
      .catch(err => showNotificationErrorAPI(err))

    setLoadingEspecialidadeFiltro(false)
  }, [])

  function setClassToHighlight(item) {
    if (item?.id === itemSelecionado?.id) return 'highlight'

    return null
  }

  return (
    <Box>
      {/* <Box mb={2}>
        <Typography variant="h5">Pacotes de procedimentos</Typography>
      </Box> */}
      <Box mb={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              placeholder="Pesquise por procedimento, código ou nome do médico..."
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
              value={termoPesquisa}
              onChange={handleTermoPesquisa}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" fullWidth size="small">
              <Select
                value={filtroGeral?.especialidade || ' '}
                fullWidth
                endAdornment={
                  <InputAdornment
                    position="end"
                    style={{ marginRight: '16px' }}
                  >
                    {loadingEspecialidadeFiltro ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <></>
                    )}
                  </InputAdornment>
                }
                onChange={e => {
                  setEspecialidadeSelecionada(e.target.value)
                  setFiltroGeral({
                    especialidade: e.target.value,
                    pagina: 1,
                    qtdPorPagina: 9
                  })
                }}
              >
                <MenuItem value=" ">Especialidade</MenuItem>
                {especialidades?.map((especialidade, i) => (
                  <MenuItem key={i} value={especialidade.id}>
                    {especialidade.descricao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {!loading && (
        <Box mt={3} mb={2} textAlign="left" my={3}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color="primary">
                {qtdAllDados
                  ? qtdAllDados > 1
                    ? `${qtdAllDados} pacotes`
                    : '1 pacote'
                  : '0 pacotes'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}

      {nenhumResultado && (
        <div>Nenhum resultado encontrado para sua pesquisa.</div>
      )}

      {allDadosCarregados && !loadingPagina && (
        <div className="fadeInTransition">
          <Grid container spacing={2}>
            {allDados.map(item => (
              <Grid item xs={12} md={4} key={item.id}>
                <Card variant="outlined" className={setClassToHighlight(item)}>
                  <CardHeader
                    action={
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {popupState => (
                          <React.Fragment>
                            <UBox
                              render={
                                perfilAtual !== SynPerfis.PARCEIRO_EXTERNO
                              }
                            >
                              <IconButton
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                aria-label="mais"
                                {...bindTrigger(popupState)}
                              >
                                <MoreVertIcon />
                              </IconButton>

                              <Menu
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                                {...bindMenu(popupState)}
                              >
                                <MenuItem
                                  onClick={() => {
                                    popupState.close()
                                    handleVisualizarDetalhes(item)
                                  }}
                                >
                                  Visualizar
                                </MenuItem>
                              </Menu>
                            </UBox>
                          </React.Fragment>
                        )}
                      </PopupState>
                    }
                    title={
                      <Link
                        component="button"
                        variant="body1"
                        color="primary"
                        align="left"
                        onClick={() => handleVisualizarDetalhes(item)}
                      >
                        {item.descricao}
                      </Link>
                    }
                    titleTypographyProps={{ variant: 'subtitle1' }}
                    subheaderTypographyProps={{ variant: 'body2' }}
                  />
                  <CardContent style={{ paddingTop: 0 }}>
                    <Chip
                      label={
                        !!item.nomeParceiro
                          ? item.nomeParceiro
                          : `Médico: ${item.nomeMedico}`
                      }
                      size="small"
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {loading && <LoadingGeral />}

      <Box
        mt={3}
        hidden={qtdAllDados === allDados.length || loading}
        textAlign="center"
      >
        <Button
          fullWidth
          onClick={handleProxPagina}
          variant="text"
          disabled={loading}
          loading={loading}
          endIcon={<MdKeyboardArrowDown />}
        >
          Carregar mais
        </Button>
      </Box>

      <DrawerVisualizarDetalhes
        id={itemSelecionado?.id}
        open={mostrarDetalhe}
        handleOnClose={handleFecharDetalhes}
      />
    </Box>
  )
}

export default listagemPacotes
