import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import yup from 'src/components/yupCustom'
import Button from 'src/components/ui/Button'
import { DropzoneArea } from 'material-ui-dropzone'
import {
  showNotification,
  showNotificationErrorAPI,
  showNotificationImage
} from 'src/core/helpers/notification'
import {
  SalvarLogomarca,
  BuscarLogomarca,
  RemoverLogomarca
} from 'src/api/logomarca-medico'
import Ubox from 'src/components/ui/Ubox'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ModalCropper from 'src/components/cropper/ModalCropper'

const btnRed = {
  border: '1px solid red',
  color: 'red'
}

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDropzoneArea-root': {
      minHeight: '200px'
    },
    '& .MuiDropzoneArea-text': {
      marginBottom: 0
    },
    '& .MuiDropzoneArea-text.MuiTypography-h5': {
      fontSize: '1.2rem',
      padding: '20px 40px 10px'
    },
    '& .MuiDropzoneArea-icon': {
      color: 'rgba(100, 100, 100, 0.87)'
    }
  },
  boxLogomarca: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    height: '100px',
    width: '240px',
    background: theme.palette.grey[100],
    border: '1px solid ' + theme.palette.grey[200],
    borderRadius: '2px',
    backgroundRepeat: 'none',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%'
    // cursor: 'pointer'
  }
}))

const schema = yup.object().shape({
  arquivo: yup.string().email().label('E-mail')
})

function LogomarcaMedico() {
  const [loading, setLoading] = useState(true)
  const [arquivo, setArquivo] = useState(null)
  const [urlArquivo, setUrlArquivo] = useState('')
  const [arquivoCropper, setArquivoCropper] = useState(null)
  const [urlArquivoCropper, setUrlArquivoCropper] = useState('')
  const [ehEdicao, setEdicao] = useState(false)
  const [ehVoltar, setVoltar] = useState(false)
  const [showCropper, setShowCropper] = useState(false)

  const classes = useStyles()
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))

  const onSubmit = async arquivo => {
    try {
      if (!arquivo) {
        return
      }
      setLoading(true)
      await SalvarLogomarca({ arquivo: arquivo })

      showNotification(
        'Logomarca!',
        'A logomarca foi envida com sucesso!',
        'success'
      )
    } catch (error) {
      showNotificationErrorAPI(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    carregarDados()
  }, [])

  useEffect(() => {
    if (arquivo) {
      const novaUrl = URL.createObjectURL(arquivo)
      setUrlArquivo(novaUrl)
    }
  }, [arquivo])

  useEffect(() => {
    if (arquivoCropper) {
      const novaUrl = URL.createObjectURL(arquivoCropper)
      setUrlArquivoCropper(novaUrl)
      setShowCropper(true)
    }
  }, [arquivoCropper])

  function carregarDados() {
    return BuscarLogomarca()
      .then(({ data }) => {
        setUrlArquivo(data.urlLogomarca)
        setEdicao(!data.urlLogomarca)
        setVoltar(!!data.urlLogomarca)
        setTimeout(() => setLoading(false), 100)
      })
      .catch(err => {
        showNotificationErrorAPI(err)
        setLoading(false)
      })
  }

  function abrirEdicao() {
    setEdicao(true)
    setVoltar(true)
  }

  function atualizarArquivo(files: File[]) {
    setArquivoCropper(files[0])
  }

  function atualizarPosCropper(file) {
    setEdicao(!file)
    setArquivo(file)
    setShowCropper(false)
    onSubmit(file)
  }

  function apagarLogomarca() {
    setLoading(true)
    setVoltar(false)
    RemoverLogomarca()
      .then(({ data }) => {
        setUrlArquivo('')
        setLoading(false)
        setEdicao(!data.urlLogomarca)
        showNotification(
          'Logomarca!',
          'A logomarca foi removida com sucesso!',
          'success'
        )
      })
      .catch(err => {
        showNotificationErrorAPI(err)
        setLoading(false)
      })
  }

  function abrirModalLogomarca() {
    showNotificationImage(urlArquivo, 300)
  }

  function voltarEdicao() {
    setEdicao(false)
  }

  return (
    <Ubox pb={6} mb={2}>
      <Grid container spacing={3} justify="center">
        <Grid item lg={6} xl={5}>
          <Box mb={2}>
            <Typography variant="h5">Personalize seu cadastro!</Typography>
          </Box>
          <Box pr={ehXS ? 0 : 4}>
            <p>
              Pensando em aproximar cada vez mais o seu paciente com a sua
              marca, criamos a funcionalidade de personalização de imagem.
            </p>
            <p>
              Caso você possua uma imagem com a sua logomarca, você pode
              salvá-la aqui.
            </p>
            <p>
              Sua logomarca sairá impressa em toda guia de solicitação de
              cirurgia, desta forma seu paciente sempre visualizará de forma
              fácil a sua marca.
            </p>
          </Box>
        </Grid>

        <Grid item xs={12} lg={4} xl={3}>
          <Grid item xs={12}>
            <ModalCropper
              open={showCropper}
              image={urlArquivoCropper}
              onClose={() => setShowCropper(false)}
              onCropped={atualizarPosCropper}
            />
          </Grid>
          <Grid item xs={12}>
            <Ubox hidden={ehEdicao}>
              <Ubox
                className={classes.boxLogomarca}
                // onClick={abrirModalLogomarca}
                style={{ backgroundImage: `url(${urlArquivo})` }}
              >
                {loading && <CircularProgress />}
              </Ubox>
              <Box mt={3}>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="button"
                  onClick={abrirEdicao}
                >
                  Alterar
                </Button>
              </Box>
              <Box mt={1}>
                <Button
                  disabled={loading}
                  style={{ ...btnRed }}
                  color="default"
                  variant="outlined"
                  fullWidth
                  onClick={apagarLogomarca}
                  type="button"
                >
                  Remover
                </Button>
              </Box>
            </Ubox>

            <Ubox hidden={!ehEdicao || loading} className={classes.root}>
              <Box mb={2}>
                <Typography variant="subtitle2">
                  Insira arquivos do tipo JPG, JPEG ou PNG
                </Typography>
              </Box>
              <DropzoneArea
                onChange={atualizarArquivo}
                dropzoneText="Clique aqui ou arraste para anexar sua logomarca"
                acceptedFiles={['image/jpeg, image/png, image/jpg,']}
                onAlert={(message, variant) =>
                  console.log(`${variant}: ${message}`)
                }
                showPreviews={false}
                showPreviewsInDropzone={false}
                filesLimit={1}
                showAlerts={[]}
                alertSnackbarProps={{
                  anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
                }}
                onDrop={e => {
                  // showNotification('Logomarca!', 'Arquivo adicionado', 'success')
                }}
                onDelete={e => {
                  showNotification('Logomarca!', 'Arquivo removido', 'success')
                }}
                onDropRejected={e => {
                  showNotification('Ops...', 'Arquivo inválido', 'error')
                }}
              />
              {/* <Typography align="center" variant="subtitle2">
                Tamanho recomentado 170 x 170
              </Typography> */}
              <Ubox hidden={!ehVoltar}>
                <Box mt={3}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    type="button"
                    onClick={voltarEdicao}
                  >
                    Voltar
                  </Button>
                </Box>
              </Ubox>
            </Ubox>
          </Grid>
        </Grid>
      </Grid>
    </Ubox>
  )
}

export default LogomarcaMedico
