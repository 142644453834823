import { makeStyles } from '@material-ui/core'
import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

/* html {
  min-height:100vh;
  background: rgb(233,255,249);
  background: linear-gradient(63deg, rgba(233,255,249,1) 0%, rgba(241,203,220,1) 26%, rgba(221,254,247,1) 100%);
} */


 @media all {
  .pagebreak-syn{
   /* display: none !important */
  }
}

 @media print {
  .pagebreak-syn{
    clear: both !important;
    page-break-before: always !important;
    break-before: always !important;
    break-after:avoid !important;
    display: block !important;
    padding-top:100px ;
   /* padding-top:100px ; */
  }
}

.m-0{
  margin:0
}

body{
  overflow: ${props => (props.autocompleteMobileOpen ? 'hidden' : 'auto')};
}

@keyframes fadeInMove {
  0% {
    opacity: 0;
    top: 40px;
    overflow: hidden;
    position: absolute;
  }
  100% {
    opacity: 1;
    top: 0;
    overflow: hidden;
    position: static;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    overflow: hidden;
  }

  100% {
    opacity: 1;
    overflow: hidden;
  }
}

.pageTransition {
  animation: fadeInMove 0.4s both;
  /* position: absolute; */
}

.fadeInTransition {
  animation: fadeIn 0.4s both;
  /* position: absolute; */
}

.swal2-container {
  z-index: 1300;
}

.danger {
  color: #F44639;
}

.highlight {
  border: 2px solid #455880;
}

.link {
  cursor: pointer;
}

.m-1 {
  width: 100%;
  height: 1px;
  margin: 1em 0;
}

/* -------------- material ui ajuste ------------------- */
.MuiAccordion-root:before {
  height: 0;
}

  `

export const useStylesGlobal = makeStyles(theme => ({
  body: {
    backgroundColor: 'red'
  }

  // container: {
  //   padding: '50px 50px 60px ',
  //   margin: '30px auto 60px',
  //   borderRadius: '4px',
  //   background: '#fff',
  //   boxShadow: '0px 0px 5px rgba(0,0,0,0.1)'
  // },
  // [theme.breakpoints.down('xs')]: {
  //   container: {
  //     margin: '0 auto',
  //     padding: '20px ',
  //     borderRadius: '0',
  //     boxShadow: 'none'
  //   }
  // },
  // logo: {
  //   width: 120,
  //   cursor: 'pointer'
  // },
  // inputIcon: { color: theme.palette.grey[500] },
  // form: { width: '100%' },
  // formControl: {
  //   margin: theme.spacing(1, 0, 0)
  //   // minWidth: 120
  // },
  // subTitle: {
  //   padding: theme.spacing(3, 0, 0, 0)
  // },
  // noMargin: {
  //   margin: theme.spacing(0)
  // }
}))
