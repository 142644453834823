import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField as TextFieldMU,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import {
  adicionarComorbidade,
  adicionarLocalAtendimento,
  obterComorbidades,
  obterExames,
  obterLocaisAtendimentoPorUfMedico,
  obterLocaisAtendimentoPorUfMedicoComId,
  obterPacientePorCPF
} from 'src/api/dominio'
import { listagemPacote } from 'src/api/pacote'
import { salvarSolicitacaoOrcamento } from 'src/api/solicitacao-orcamento'
import { ModalAddGenerico } from 'src/components/modals/ModalAddGenerico'
import {
  StepConnectorSyn,
  StepIconSyn
} from 'src/components/stepper/stepConnector'
import { StepContentSyn } from 'src/components/stepper/stepContentSyn'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import { TextField } from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import equipeAnestesias from 'src/core/consts/equipeAnestesia'
import genero from 'src/core/consts/genero'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { formatarIdade } from 'src/core/formatacoes/formatarData'
import { onlyNumber, verificarCPF } from 'src/core/helpers/common'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useListagem from 'src/hooks/useListagem'
import { useQuery } from 'src/hooks/useQuery'
import {
  IComboResponse,
  IExame,
  IOrcamentoDetalhadoResponse,
  IPaciente,
  ObjetoDescricaoGenerico,
  SalvarPacoteCommand,
  SolicitarOcamentoCommand
} from 'src/types'
import Swal from 'sweetalert2'
import { IPacoteForm, mapearPacoteParaRequest } from '../pacote/formPacote'
import ResumoPacote from '../pacote/resumo'
import DrawerAjustarPacote from './drawerAjustarPacote'
import { ResumoOrcamento } from './resumo'

import {
  ItemListagemMembroEquipe,
  buscarTodosMembrosEquipe
} from 'src/api/membroEquipe'
import DateInput from 'src/components/ui/DateInput'
import { NumberFormatCustom } from 'src/components/ui/NumberField'
import Ubox from 'src/components/ui/Ubox'
import estados from 'src/core/consts/estados'
import { TiposAnestesias } from 'src/core/consts/tiposAnestesia'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import useAuth from 'src/hooks/useAuth'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { ModalTransformTemplate } from './modalTransformtemplate/modalTransformTemplate'

const useStyles = makeStyles(theme => ({
  container: {},
  BtnCancel: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.common.white,
    '@media (max-width: 600px)': {
      marginTop: '-166px',
      marginBottom: '112px'
    }
  },
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  infoTxt: {
    color: theme.palette.info.light
  },
  noMargin: {
    margin: theme.spacing(0)
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))

export interface IOrcamentoForm {
  id?: string
  codigo?: number
  criado?: string
  dataHoraServidor?: string
  pacienteId?: string
  nomePaciente: string
  cpfPaciente: string
  telefonePaciente: string
  dataNascimentoPaciente: string
  emailPaciente: string
  sexoPaciente: number
  temPlanoDeSaude?: number

  responsavelPacienteId?: string
  cpfResponsavel?: string
  nomeResponsavel?: string

  medicoId?: string
  medicoNome?: string
  medicoEspecialidades?: ObjetoDescricaoGenerico[]

  exames: IExame[]
  avaliacaoPreAnestesica?: boolean
  pacoteInstantaneo?: boolean

  riscoCirurgico?: boolean
  observacaoExames?: string

  pacote: ObjetoDescricaoGenerico
  comorbidades: ObjetoDescricaoGenerico[]
  reservaSangue: string
  hospitais: ObjetoDescricaoGenerico[]
  observacaoSangue?: string
  observacaoComorbidades?: string

  solicitacaoSemLocalAtendimento: boolean
  temDisponibilidade: boolean

  disponibilidade: string
  anestesia?: string
  observacoes: string
  uf?: string

  combos?: IComboResponse[]
  semComorbidade?: boolean

  valorMinHonorarios?: number
  valorMaxHonorarios?: number
  valorEquipeAnestesia?: number
  equipeAnestesia?: number
  urlLogomarca?: string
  medicoSolicitacaoId?: string
  nomeAssistente?: string
}

export function mapToForm(item: IOrcamentoDetalhadoResponse) {
  let orcamento: IOrcamentoForm = {
    id: item.id,
    pacoteInstantaneo: item.pacoteInstantaneo,
    medicoSolicitacaoId: item.medicoSolicitacaoId,
    codigo: item.codigo,
    criado: item.criado,
    dataHoraServidor: item.dataHoraServidor,
    cpfPaciente: item.pacienteCpf,
    nomePaciente: item.pacienteNome,
    emailPaciente: item.pacienteEmail,
    telefonePaciente: item.pacienteTelefone,
    sexoPaciente: item.pacienteSexo,
    pacienteId: item.pacienteId,
    dataNascimentoPaciente: item.pacienteDataNascimento,
    comorbidades: item.comorbidades,
    pacote: item.pacote,
    reservaSangue: item.qtdReservaSange.toString(),
    hospitais: item.locaisAtendimento,
    disponibilidade: item.disponibilidade,
    solicitacaoSemLocalAtendimento: item.solicitacaoSemLocalAtendimento,
    temDisponibilidade: !!item.disponibilidade,
    anestesia: item.anestesia,
    observacoes: item.observacoes,
    medicoId: item.medicoId,
    medicoNome: item.medicoNome,
    medicoEspecialidades: item.medicoEspecialidades,
    riscoCirurgico: item.riscoCirurgico,
    avaliacaoPreAnestesica: item.avaliacaoPreAnestesica,
    observacaoExames: item.observacaoExames,
    cpfResponsavel: item.cpfResponsavel,
    nomeResponsavel: item.nomeResponsavel,
    observacaoSangue: item.observacaoSangue,
    exames: item.exames,
    observacaoComorbidades: item.observacaoComorbidades,
    uf: item.uf,
    semComorbidade: item.semComorbidade,
    valorMaxHonorarios: item.valorMaxHonorarios,
    valorMinHonorarios: item.valorMinHonorarios,
    valorEquipeAnestesia: item.valorEquipeAnestesia,
    nomeAssistente: item.nomeAssistente,
    equipeAnestesia:
      !!item.valorEquipeAnestesia || item.solicitacaoSemLocalAtendimento
        ? equipeAnestesias.medica
        : equipeAnestesias.hospital,
    urlLogomarca: item.urlLogomarca,
    responsavelPacienteId: item.responsavelPacienteId
  }

  return orcamento
}

export enum StepsCotacao {
  resumo = 'resumo',
  paciente = 'paciente',
  comorbidades = 'comorbidades',
  reservaSangue = 'reservaSangue',
  localAtendimento = 'localAtendimento',
  honorarioFlexivel = 'honorarioFlexivel',
  internacao = 'internacao',
  anestesia = 'anestesia',
  observacao = 'observacao',
  revisao = 'revisao'
}
interface Etapa {
  step: StepsCotacao
  validacao: string[]
  ativo: boolean
}

let stepsFormulario: Etapa[] = [
  { step: StepsCotacao.resumo, validacao: ['pacote'], ativo: true },
  {
    step: StepsCotacao.paciente,
    validacao: [
      'cpfPaciente',
      'nomePaciente',
      'telefonePaciente',
      'sexoPaciente',
      'dataNascimentoPaciente',
      'temPlanoDeSaude'
    ],
    ativo: true
  },
  { step: StepsCotacao.comorbidades, validacao: ['comorbidades'], ativo: true },
  {
    step: StepsCotacao.reservaSangue,
    validacao: ['reservaSangue'],
    ativo: true
  },
  {
    step: StepsCotacao.localAtendimento,
    validacao: ['disponibilidade', 'uf', 'hospitais'],
    ativo: true
  },
  {
    step: StepsCotacao.honorarioFlexivel,
    validacao: ['valorMinHonorarios', 'valorMaxHonorarios'],
    ativo: true
  },
  {
    step: StepsCotacao.anestesia,
    validacao: ['valorEquipeAnestesia', 'equipeAnestesia'],
    ativo: true
  },
  // { step: Steps.reservaSangue, validacao: [], ativo: true },
  { step: StepsCotacao.observacao, validacao: ['observacoes'], ativo: true },
  { step: StepsCotacao.revisao, validacao: [], ativo: true }
]

type Props = {
  fecharForm?: () => void
  pacoteSelecionado?: IPacoteForm
  setPacoteSelecionado?: (pacote: IPacoteForm) => void
  activeStep?: StepsCotacao
  setActiveStep?: (step: StepsCotacao) => void
  orcamentoDetalhado?: IOrcamentoDetalhadoResponse
  medicoId?: string
  medicoCpf?: string
  isback?: (boolean) => void
}

export const FormOrcamento = (props: Props) => {
  const classes = useStyles()
  const history = useHistory()

  const query = useQuery()
  const {
    medicoCpf,
    medicoId,
    pacoteSelecionado,
    setPacoteSelecionado,
    activeStep,
    setActiveStep,
    orcamentoDetalhado,
    isback
  } = props

  //#region Definicao de listas e loading
  const [loadingOrcamento, setLoadingOrcamento] = useState(true)

  const [listaPacotes, setListaPacotes] = useState<ObjetoDescricaoGenerico[]>(
    []
  )

  const [listaHospitais, setListaHospitais] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [loadingHospitais, setLoadingHospitais] = useState<boolean>(true)

  const [estadosHospitais, setEstadosHospitais] = useState<any[]>([])

  const [listaComorbidades, setListaComorbidades] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [loadingComorbidades, setLoadingComorbidades] = useState<boolean>(true)
  const [semComorbidade, setSemComorbidade] = useState<boolean>(false)
  const [verificaAnestesia, setVerificaAnestesia] = useState<boolean>(false)

  const [
    comorbidadeMensagemErro,
    setComorbidadeMensagemErro
  ] = useState<boolean>(false)

  const [loadingPaciente, setLoadingPaciente] = useState(false)
  const [showFormPaciente, setShowFormPaciente] = useState(false)
  const [pacienteResponsavel, setPacienteResponsavel] = useState(false)

  const [loadingPacote, setLoadingPacote] = useState(false)

  const [ajustarPacote, setAjustarPacote] = useState(false)
  const [etapaAjuste, setEtapaAjuste] = useState<number>(undefined)

  const [verificouEquipe, setVerificouEquipe] = useState(false)

  const [erroTempoCirurgico, setErroTempoCirurgico] = useState(false)

  const [listaExames, setListaExames] = useState<IExame[]>([])
  const [loadingExames, setLoadingExames] = useState<boolean>(true)
  const [ehFocoValor, setFocoValor] = useState(0)
  const [isloading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const {
    signOut,
    user,
    perfilAtual,
    ehTenantSyn,
    obterTenantAtual
  } = useAuth()

  const handleDisponibilidade = e => {
    if (!command.temDisponibilidade) {
      setValue('disponibilidade', '')
    }

    handleItemValue('temDisponibilidade', !command.temDisponibilidade)
  }

  const handleSolicitacaoSemLocalAtendimento = e => {
    setValue('hospitais', [])
    if (estadosHospitais.length !== 1) {
      setValue('uf', '')
    }
    setValue('locaisAtendimento', [])
    setValue('temDisponibilidade', false)
    setValue('disponibilidade', '')
    if (!command.solicitacaoSemLocalAtendimento) {
      setValue('equipeAnestesia', equipeAnestesias.medica)
    } else {
      setValue('equipeAnestesia', 0)
    }

    handleItemValue(
      'solicitacaoSemLocalAtendimento',
      !command.solicitacaoSemLocalAtendimento
    )
  }
  //#endregion

  //#region Modal
  const [textoModal, setTextoModal] = useState('')
  const [showModal, toggleModal] = useState<string>(null)
  const handleShowModal = (texto, modal) => {
    setTextoModal(texto)
    toggleModal(modal)
  }
  const dadosModal = () => {
    if (!showModal) return
    let dados = null
    switch (showModal) {
      case 'hospitais':
        dados = {
          titulo: 'Adicionar local',
          label: 'local de atendimento',
          saveSubmit: adicionarLocalAtendimento,
          handleValue: valor => {
            handleNovoValor('hospitais', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
      case 'comorbidades':
        dados = {
          titulo: 'Adicionar comorbidade',
          label: 'Comorbidade',
          saveSubmit: adicionarComorbidade,
          handleValue: valor => {
            handleNovoValor('comorbidades', valor)
          },
          descricao: textoModal,
          handleClose: () => toggleModal(null)
        }
        break
    }
    return dados
  }
  //#endregion

  //#region Formulário

  const schema = yup.object().shape({
    nomePaciente: yup
      .string()
      .required()
      .test('nome válido', 'Deve informar nome e sobrenome', value => {
        if (!value) return false
        let nomeArray = value.split(' ').filter(texto => texto.trim())
        return nomeArray.length > 1
      })
      .label('Nome completo'),
    nomeResponsavel: yup
      .string()
      .test('nome-responsavel', 'Nome do responsável é obrigatório', value => {
        if (pacienteResponsavel && !value) {
          return false
        }
        return true
      })
      .test('nome válido', 'Deve informar nome e sobrenome', value => {
        if (pacienteResponsavel) {
          let nomeArray = value.split(' ').filter(texto => texto.trim())

          return nomeArray.length > 1
        }

        return true
      })
      .label('Nome completo'),
    cpfPaciente: yup
      .string()
      .nullable()
      .test('cpf-teste', 'CPF inválido', value => {
        if (pacienteResponsavel) return true

        if (!value) return false

        return verificarCPF(onlyNumber(value))
      })
      .label('CPF'),
    cpfResponsavel: yup
      .string()
      .nullable()
      .test(
        'cpf-responsavel',
        'CPF do responsável é obrigatório',
        function (value) {
          if (pacienteResponsavel && !value) {
            return false
          }
          return true
        }
      )
      .test('cpf-teste', 'CPF inválido', value => {
        if (pacienteResponsavel && value) {
          return verificarCPF(onlyNumber(value))
        }

        return true
      })
      .label('CPF'),
    telefonePaciente: yup.string().required().label('Telefone'),
    dataNascimentoPaciente: yup
      .string()
      .required()
      .test('nas', 'Por favor escolha uma data válida', value => {
        return moment(value).isBetween('1922-12-31', moment(), 'day')
      })
      .label('Data de nascimento'),
    emailPaciente: yup.string().email().label('E-mail'),
    sexoPaciente: yup
      .number()
      .required()
      .label('Sexo')
      .test('sexo-teste', 'Informe o sexo', value => {
        if (!value) return false
        return true
      }),
    temPlanoDeSaude: yup
      .number()
      .required()
      .label('Pode Plano de Saúde')
      .test(
        'pode-plano-de-saude',
        'Informe se o paciente possui plano de saúde',
        value => {
          if (!value) return false
          return true
        }
      ),
    exames: yup.array().label('Exames'),
    pacote: yup.object().nullable().required().label('Pacote de procedimento'),
    comorbidades: yup.array().label('Comorbidades'),
    avaliacaoPreAnestesica: yup.bool(),
    riscoCirurgico: yup.bool(),
    solicitacaoSemLocalAtendimento: yup.bool(),
    observacaoExames: yup.string().label('Observações dos exames'),
    observacaoSangue: yup.string().label('Observações'),
    reservaSangue: yup.string().label('Reserva de sangue'),
    hospitais: yup
      .array()
      .nullable()
      .when('solicitacaoSemLocalAtendimento', {
        is: false,
        then: yup
          .array()
          .nullable()
          .min(1, 'Escolha ao menos 1 hospital')
          .max(3, 'Escolha no máximo 3 hospitais')
          .label('Sugestões de hospitais')
      }),
    temDisponibilidade: yup.bool(),
    disponibilidade: yup.string().when('solicitacaoSemLocalAtendimento', {
      is: false,
      then: yup.string().when('temDisponibilidade', {
        is: true,
        then: yup.string().required().label('Disponibilidade')
      })
    }),
    observacoes: yup.string().label('Observações'),
    observacaoComorbidades: yup.string().label('Observação das comorbidades'),
    combos: yup.array().label('Combos'),
    uf: yup.string().nullable().required().label('UF'),
    valorMinHonorarios: yup
      .string()
      .required()
      .label('Valor mínimo do honorário da equipe')
      .test(
        'equipeAnestesia',
        'O valor minímo não pode ser 0 ou maior que o honorário médico',
        value => {
          if (Number(value) === 0 && !pacoteSelecionado?.semHonorarioMedico) {
            return false
          }
          if (Number(value) > pacoteSelecionado?.honorarioEquipeMedica) {
            return false
          }
          return true
        }
      ),
    valorMaxHonorarios: yup
      .string()
      .label('Valor máximo do honorário da equipe'),
    equipeAnestesia: yup
      .number()
      // .required()
      .label('Equipe anestesia')
      .test('equipeAnestesia', 'Informe a equipe da anestesia', value => {
        if (
          !value &&
          pacoteSelecionado?.tiposAnestesia?.length > 0 &&
          !verificaAnestesia
        ) {
          return false
        }
        return true
      }),
    valorEquipeAnestesia: yup
      .string()
      .nullable()
      .test(
        'valorAnestesia',
        'Informe o valor da equipe de anestesia',
        (value, context) => {
          let ehLocal = verificaAnestesiaLocal(
            pacoteSelecionado?.tiposAnestesia
          )

          if (!value && !ehLocal && context.parent.equipeAnestesia === 1) {
            return false
          }
          return true
        }
      )
  })

  let defaultValues: IOrcamentoForm = {
    pacienteId: '',
    nomePaciente: '',
    emailPaciente: '',
    telefonePaciente: '',
    cpfPaciente: '',
    dataNascimentoPaciente: '',
    sexoPaciente: 0,
    temPlanoDeSaude: 0,

    exames: [],
    avaliacaoPreAnestesica: false,
    riscoCirurgico: false,
    observacaoExames: '',

    pacote: null,
    comorbidades: [],
    hospitais: [],
    observacaoComorbidades: '',

    reservaSangue: '',
    temDisponibilidade: false,
    equipeAnestesia: 0,
    valorEquipeAnestesia: 0,
    anestesia: '',
    disponibilidade: '',
    observacoes: '',
    solicitacaoSemLocalAtendimento: false,
    valorMinHonorarios: 0,
    valorMaxHonorarios: pacoteSelecionado.honorarioEquipeMedica,

    combos: [],
    urlLogomarca: undefined
  }

  // defaultValues = solicitacaoMock

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    formState,
    handleSubmit,
    trigger
  } = useForm<IOrcamentoForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  const command = watch()

  // REGISTRA VALORES REACT FORM HOOKS
  useEffect(() => {
    register('medicoSolicitacaoId')
    register('id')
    register('sexoPaciente')
    register('hospitais')
    register('temDisponibilidade')
    register('solicitacaoSemLocalAtendimento')
    register('pacote')
    register('telefonePaciente')
    register('comorbidades')
    register('riscoCirurgico')
    register('avaliacaoPreAnestesica')
    register('dataNascimentoPaciente')
    register('pacienteId')
    register('exames')
    register('uf')
    register('combos')
    register('valorMinHonorarios')
    register('valorMaxHonorarios')
    register('valorEquipeAnestesia')
    register('equipeAnestesia')
    register('responsavelPacienteId')
    register('temPlanoDeSaude')
  }, [register])

  // VINCULAR MUDANÇA DE VALOR
  const handleItemValue = (name: string, item) => {
    setValue(name, item, {
      shouldValidate: true
    })
  }

  const handleNovoValor = (name, item: any) => {
    switch (name) {
      case 'comorbidades':
        carregarComorbidades()
        setValue('comorbidades', [...command.comorbidades, item], {
          shouldValidate: true
        })
        break
      case 'hospitais':
        carregarHospitais()
        setValue('hospitais', [...command.hospitais, item], {
          shouldValidate: true
        })
        break
    }
  }
  //#endregion
  async function carregarMembros() {
    // let medicoSolicitacaoId = query.get('medicoSolicitacaoId')
    var cpfOuMedicoId = perfilAtual === SynPerfis.MEDICO ? user.cpf : medicoId

    //TODO AJustar para quando é o próprio médico
    var response = await buscarTodosMembrosEquipe(cpfOuMedicoId)

    var { data } = response
    setLoadingMembros(false)
    setListaMembrosEquipe(data)
  }

  //#region MOUNT
  useEffect(() => {
    if (!ehTenantSyn) {
      var index = stepsFormulario.findIndex(
        s => s.step === StepsCotacao.localAtendimento
      )
      stepsFormulario[index].ativo = false

      var tenantAtual = obterTenantAtual()
      var localAtendimento: ObjetoDescricaoGenerico = {
        id: tenantAtual.localAtendimentoId,
        descricao: tenantAtual.descricao
      }
      setValue('hospitais', [localAtendimento])
      setValue('uf', tenantAtual.uf)
    }

    carregarMembros()
    // carregarPacotes()
    carregarComorbidades()
    carregarExames()
    verificaSeTemPacoteSelecionado()
    verificaEhEdicao()
    verificaEhSecretaria()
  }, [])

  useEffect(() => {
    var index = stepsFormulario.findIndex(
      s => s.step === StepsCotacao.honorarioFlexivel
    )

    if (
      pacoteSelecionado.semHonorarioMedico ||
      perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
      perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL
    ) {
      stepsFormulario[index].ativo = false
      setValue('valorMinHonorarios', pacoteSelecionado?.honorarioEquipeMedica)
    } else {
      stepsFormulario[index].ativo = true
    }
  }, [pacoteSelecionado.semHonorarioMedico])

  function verificaEhSecretaria() {
    let medicoSolicitacaoId = query.get('medicoSolicitacaoId')
    medicoSolicitacaoId = medicoSolicitacaoId || medicoId

    if (medicoSolicitacaoId) {
      setValue('medicoSolicitacaoId', medicoSolicitacaoId)
      obterLocaisAtendimentoPorUfMedicoCom(medicoSolicitacaoId)
    } else {
      carregarHospitais()
    }
  }

  const obterLocaisAtendimentoPorUfMedicoCom = async (medicoSolicitacaoId?) => {
    return obterLocaisAtendimentoPorUfMedicoComId(medicoSolicitacaoId)
      .then(response => {
        setListaHospitais(response.data)
        let listaTodosEstadosHospitais = response.data?.map(item => item.grupo)
        let estadosHospitais = [...new Set(listaTodosEstadosHospitais)]
        if (estadosHospitais.length === 1) {
          setValue('uf', estadosHospitais[0])
        }
        setEstadosHospitais(
          estados.filter(e => estadosHospitais.includes(e.Initials)).sort()
        )
      })
      .finally(() => {
        setLoadingHospitais(false)
      })
  }

  function verificaEhEdicao() {
    const id = query.get('id')
    if (!id) {
      setLoadingOrcamento(false)
      return
    }
    const orcamento = orcamentoDetalhado
    const form = mapToForm(orcamento)
    setPacienteResponsavel(!!form.responsavelPacienteId)

    for (const key in form) {
      let value = form[key]
      if (key === 'dataNascimentoPaciente') {
        value = moment(value).format('yyyy-MM-DD')
      }

      if (key === 'semComorbidade') {
        setSemComorbidade(value)
        continue
      }
      setValue(key, value, { shouldValidate: false })
      if (key === 'medicoId') {
        setValue('medicoSolicitacaoId', value)
      }
    }
    setLoadingOrcamento(false)
  }

  function verificaSeTemPacoteSelecionado() {
    const idPacote = query.get('idPacote')
    const descricaoPacote = query.get('descricaoPacote')

    if (!!idPacote && !!descricaoPacote) {
      let pacote: ObjetoDescricaoGenerico = {
        id: idPacote,
        descricao: descricaoPacote
      }

      setValue('pacote', pacote)
    }

    if (!!pacoteSelecionado) {
      let pacote: ObjetoDescricaoGenerico = {
        id: pacoteSelecionado.id,
        descricao: pacoteSelecionado.descricao
      }

      setValue('pacote', pacote)
    }
  }
  //#endregion

  //#region Carregar Opções do Sistema
  const { loading, dados } = useListagem(1000, listagemPacote)

  const [loadingMembros, setLoadingMembros] = useState<boolean>(true)

  const [listaMembrosEquipe, setListaMembrosEquipe] = useState<
    ItemListagemMembroEquipe[]
  >([])

  useEffect(() => {
    const medicoSolicitacaoId = query.get('medicoSolicitacaoId')
    if (medicoSolicitacaoId) {
      const filtrado = dados.filter(w => w.medicoId === medicoSolicitacaoId)
      setListaPacotes(filtrado)
    } else {
      setListaPacotes(dados)
    }
  }, [dados])
  const carregarExames = async () => {
    return obterExames()
      .then(response => setListaExames(response.data || []))
      .finally(() => {
        setLoadingExames(false)
      })
    // return setListaExames(mockExames)
  }

  const carregarHospitais = async () => {
    return obterLocaisAtendimentoPorUfMedico()
      .then(response => {
        setListaHospitais(response.data)
        let listaTodosEstadosHospitais = response.data?.map(item => item.grupo)
        let estadosHospitais = [...new Set(listaTodosEstadosHospitais)]
        if (estadosHospitais.length === 1) {
          setValue('uf', estadosHospitais[0])
        }
        setEstadosHospitais(
          estados.filter(e => estadosHospitais.includes(e.Initials)).sort()
        )
      })
      .finally(() => {
        setLoadingHospitais(false)
      })
  }

  const carregarComorbidades = async () => {
    return obterComorbidades()
      .then(response => {
        setListaComorbidades(response.data)
      })
      .finally(() => {
        setLoadingComorbidades(false)
      })
  }

  //#endregion

  const onSubmit = async (data: IOrcamentoForm, tornarTemplate?: boolean) => {
    setIsLoading(true)
    const aceitouTemplate = tornarTemplate

    let paciente: IPaciente = {
      id: command.pacienteId,
      comorbidades: command.comorbidades.map(c => c.id),
      cpf: onlyNumber(command.cpfPaciente),
      dataNascimento: moment(command.dataNascimentoPaciente).toJSON(),
      email: command.emailPaciente,
      nome: command.nomePaciente,
      telefone: onlyNumber(command.telefonePaciente),
      sexo: command.sexoPaciente,
      cpfResponsavel: pacienteResponsavel ? command.cpfResponsavel : null,
      nomeResponsavel: command.nomeResponsavel
    }

    let pacote: SalvarPacoteCommand = mapearPacoteParaRequest(
      pacoteSelecionado,
      true
    )
    //pacote.descricao = command.pacote.descricao
    let valuePlanoDeSaude = false
    if (command.temPlanoDeSaude === 1) {
      valuePlanoDeSaude = true
    }

    let request: SolicitarOcamentoCommand = {
      id: command.id,
      locaisAtendimento: command.hospitais.map(h => h.id),
      paciente: paciente,
      pacoteProcedimento: pacote,
      qtdReservaSangue: !!command.reservaSangue
        ? parseInt(command.reservaSangue)
        : 0,
      disponibilidade: command.disponibilidade,
      observacoes: command.observacoes,
      avaliacaoPreAnestesica: command.avaliacaoPreAnestesica,
      riscoCirurgico: command.riscoCirurgico,
      observacaoExames: command.observacaoExames,
      observacaoSangue: command.observacaoSangue,
      exames: command.exames.map(e => e.id),
      observacaoComorbidades: command.observacaoComorbidades,
      valorMinHonorarios: command.valorMinHonorarios,
      valorMaxHonorarios:
        pacoteSelecionado?.honorarioEquipeMedica || command.valorMaxHonorarios,
      valorEquipeAnestesia: command.valorEquipeAnestesia,
      equipeAnestesia: command.equipeAnestesia,
      responsavelPacienteId: pacienteResponsavel
        ? command.responsavelPacienteId
        : null,
      solicitacaoSemLocalAtendimento: command.solicitacaoSemLocalAtendimento,
      uf: command.uf,
      medicoSolicitacaoId: command.medicoSolicitacaoId,
      perfilAtual: perfilAtual,
      temPlanoDeSaude: valuePlanoDeSaude,
      template: aceitouTemplate
    }

    return salvarSolicitacaoOrcamento(request)
      .then(response => {
        const id = response.data
        Swal.fire({
          title: 'Parabéns!',
          text: 'Sua solicitação de orçamento foi salva com sucesso.',
          icon: 'success',
          confirmButtonText: 'Continuar',
          showCancelButton: !(
            perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
            perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL
          ),
          cancelButtonText: 'Imprimir guia'
        }).then(result => {
          if (
            perfilAtual === SynPerfis.ADMIN_HOSPITAL ||
            perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL
          ) {
            history.replace(SynRoutes.listagemOrcamentosHospital)
            setIsLoading(false)
            return
          }

          if (result.dismiss === Swal.DismissReason.cancel) {
            history.replace(SynRoutes.listagemOrcamentos + `?print=${id}`)
            setIsLoading(false)
            return
          }
          if (
            command.solicitacaoSemLocalAtendimento === true &&
            pacote.opmes.length === 0
          ) {
            history.replace(`${SynRoutes.detalhesPropostasMedico}?id=${id}`)
            setIsLoading(false)
          } else {
            history.replace(SynRoutes.listagemOrcamentos)
            setIsLoading(false)
          }
        })
      })
      .catch(e => showNotificationErrorAPI(e))
  }

  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  //#region stepper

  // MIN = Minimum expected value
  // MAX = Maximium expected value
  // Function to normalise the values (MIN / MAX could be integrated)
  useEffect(() => {
    var index = stepsFormulario.findIndex(
      s => s.step === StepsCotacao.anestesia
    )
    stepsFormulario[index].ativo = false
    setValue('valorEquipeAnestesia', 0)
    handleItemValue('equipeAnestesia', equipeAnestesias.hospital)

    //to do incluir logica no hospital escolher se aceita anestesista de fora
    // if (perfilAtual === SynPerfis.ADMIN_HOSPITAL || perfilAtual === SynPerfis.OPERACIONAL_HOSPITAL) {
    //   stepsFormulario[index].ativo = false
    //   setValue('valorEquipeAnestesia', 0)
    //   handleItemValue('equipeAnestesia', equipeAnestesias.hospital)
    // } else {
    //   let ehAnestesiaLocal = verificaAnestesiaLocal(
    //     pacoteSelecionado?.tiposAnestesia
    //   )
    //   setVerificaAnestesia(ehAnestesiaLocal)

    //   stepsFormulario[index].ativo = !ehAnestesiaLocal

    //   verificarPacoteValido()
    // }
    // if (!!pacoteSelecionado) setValue('pacote', pacoteSelecionado)
  }, [pacoteSelecionado])

  function verificarPacoteValido() {
    if (
      !pacoteSelecionado?.mediaTempoCirurgico ||
      pacoteSelecionado?.mediaTempoCirurgico === '00:00'
    ) {
      setErroTempoCirurgico(true)
      Swal.fire({
        title: 'Atenção!',
        icon: 'warning',
        text: 'Média de tempo cirúrgico é um campo obrigatório. Favor ajustar',
        willClose: () => {
          handleAjusteEtapa(5)
          setActiveStep(StepsCotacao.resumo)
        }
      })
      return false
    }
    return true
  }

  function verificaAnestesiaLocal(anestesias) {
    let ehAnestesiaLocal =
      anestesias?.filter(
        w =>
          w.id === TiposAnestesias.local_realizado_pelo_medico ||
          w.id === TiposAnestesias.local_realizado_pelo_medico_dev
      ).length > 0 && pacoteSelecionado?.tiposAnestesia?.length === 1
    return ehAnestesiaLocal
  }

  let lastStep = stepsFormulario.filter(step => step.ativo).length

  const normalise = value => ((value - 0) * 100) / (lastStep - 0)

  const formularioCamposDoStep =
    pacoteSelecionado?.tiposAnestesia?.length === 0 || verificaAnestesia
      ? [
        ['pacote'],
        [
          'cpfPaciente',
          'nomePaciente',
          'telefonePaciente',
          'sexoPaciente',
          'dataNascimentoPaciente',
          'temPlanoDeSaude'
        ],
        ['comorbidades'],
        // [],
        ['reservaSangue'],
        ['disponibilidade', 'uf', 'hospitais'],
        ['valorMinHonorarios', 'valorMaxHonorarios'],
        ['observacoes']
      ]
      : [
        ['pacote'],
        [
          'cpfPaciente',
          'nomePaciente',
          'telefonePaciente',
          'sexoPaciente',
          'dataNascimentoPaciente'
        ],
        ['comorbidades'],
        ['reservaSangue'],
        ['disponibilidade', 'uf', 'hospitais'],
        ['valorMinHonorarios', 'valorMaxHonorarios'],
        ['valorEquipeAnestesia', 'equipeAnestesia'],
        ['observacoes']
      ]

  const handleNext = async () => {
    var indexAtual = obterStepAtual()
    var ativos = obterStepsAtivos()
    var stepAtual = ativos[indexAtual]

    if (stepAtual.step === StepsCotacao.paciente) {
      const idade = formatarIdade(stringToDate(command.dataNascimentoPaciente))

      if (idade < 18) {
        await setPacienteResponsavel(true)
        const idadeResult = await trigger(['cpfResponsavel', 'nomeResponsavel'])
        if (!idadeResult) return
      }
    }

    var campos = stepAtual.validacao

    if (campos) {
      const result = await trigger(campos)
      if (!result) {
        return
      }
    }

    setActiveStep(ativos[indexAtual + 1].step)
  }

  function obterStepsAtivos(): Etapa[] {
    let stepsAtivos = stepsFormulario.filter(step => step.ativo)
    return stepsAtivos
  }

  function obterStepAtual() {
    let stepsAtivos = obterStepsAtivos()
    let index = stepsAtivos.findIndex(step => step.step === activeStep)
    return index
  }

  const debugSteps = false
  const handleBackToStep = stepNumber => {
    let stepsAtivos = obterStepsAtivos()
    var indexAtual = obterStepAtual()

    if (!stepNumber && stepNumber !== 0) {
      setActiveStep(stepsAtivos[indexAtual - 1].step)
      return
    }

    if (debugSteps || stepNumber < activeStep) {
      setActiveStep(stepNumber)
    }

    // if (!stepNumber && stepNumber !== 0) {
    //   setActiveStep(activeStep - 1)
    //   return
    // }

    // if (debugSteps || stepNumber < activeStep) {
    //   setActiveStep(stepNumber)
    // }
  }

  const getSteps = number => {
    const steps = []
    for (let index = 0; index < number; index++) {
      steps.push(
        <Step key={index}>
          <StepLabel
            StepIconComponent={v => StepIconSyn({ ...v, key: index + 1 })}
            onClick={() => handleBackToStep(index)}
          ></StepLabel>
          <StepContent></StepContent>
        </Step>
      )
    }
    return steps
  }

  function handleAjusteEtapa(etapa: number) {
    if (etapa === 8) {
      setVerificouEquipe(true)
    }

    setAjustarPacote(true)
    setEtapaAjuste(etapa)
  }

  function handleFecharAjustarPacote() {
    setAjustarPacote(false)
  }

  function handleSalvarAjuste(dados, ehEtapaMembro = false) {
    var membros = dados?.membros?.map(i => {
      i.valor = i.valor / 100
      return i
    })

    dados.membros = membros

    setPacoteSelecionado(dados)
    setAjustarPacote(false)
    setErroTempoCirurgico(false)
  }

  const handleRadios = e => {
    handleItemValue('sexoPaciente', parseInt(e.target.value))
  }
  const handleRadiosEquipeAnestesia = e => {
    handleItemValue('equipeAnestesia', parseInt(e.target.value))
    if (e.target.value === '2') {
      setValue('valorEquipeAnestesia', 0)
      focarCampoValor(0)
    } else {
      focarCampoValor(1)
    }
  }
  //#endregion

  useEffect(() => {
    if (!command.cpfPaciente && !command.cpfResponsavel) return

    const cpfFormatado = onlyNumber(
      !!pacienteResponsavel ? command.cpfResponsavel : command.cpfPaciente
    )
    if (cpfFormatado.length === 11) {
      setLoadingPaciente(true)
      obterPacientePorCPF(cpfFormatado)
        .then(response => {
          const paciente = response.data

          if (!!paciente && !pacienteResponsavel) {
            setValue('comorbidades', paciente.comorbidades, {
              shouldValidate: true
            })
            setValue('nomePaciente', paciente.nome, { shouldValidate: true })
            if (!!paciente.dataNascimento) {
              let dataFormatada = moment(paciente.dataNascimento).format(
                'yyyy-MM-DD'
              )
              setValue('dataNascimentoPaciente', dataFormatada, {
                shouldValidate: true
              })
            }
            setValue('telefonePaciente', paciente.telefone, {
              shouldValidate: true
            })
            setValue('pacienteId', paciente.id)
            setValue('sexoPaciente', paciente.sexo)
            setValue('emailPaciente', paciente.email, { shouldValidate: true })
            setValue('responsavelPacienteId', null)
          } else if (!!paciente && pacienteResponsavel) {
            setValue('pacienteId', null)
            setValue('cpfPaciente', '')
            setValue('nomeResponsavel', paciente.nome)
            setValue('responsavelPacienteId', paciente.id)
          } else {
            limparFormPac(pacienteResponsavel)
          }
          setShowFormPaciente(true)
        })
        .catch(e => {
          showNotificationErrorAPI(e)
          limparFormPac(pacienteResponsavel)
          setShowFormPaciente(false)
        })
        .finally(() => {
          setTimeout(() => setLoadingPaciente(false), 100)
        })
    }
  }, [command.cpfPaciente, command.cpfResponsavel])

  function limparFormPac(ehResponsavel: boolean) {
    if (ehResponsavel) {
      setValue('nomeResponsavel', '')
      setValue('responsavelPacienteId', null)
      return
    }
    setValue('pacienteId', null)
    setValue('nomePaciente', null)
    setValue('dataNascimentoPaciente', '')
    setValue('telefonePaciente', '')
    setValue('emailPaciente', null)
    setValue('sexoPaciente', 0)
  }

  const handleNovoPacote = texto => {
    history.replace(
      `${SynRoutes.formPacote}?returnUrl=${SynRoutes.formOrcamentoUnificado}`
    )
  }

  function toggleComorbidades() {
    setValue('comorbidades', [])
    setSemComorbidade(!semComorbidade)
    setComorbidadeMensagemErro(false)
  }

  function stringToDate(str: string) {
    if (!str) {
      return
    }
    const isoString = str
      .replace('/', '-')
      .replace('/', '-')
      .split('-')
      .reverse()
      .join('-')

    return new Date(isoString)
  }

  async function addCombosSolicitacao(combos: IComboResponse[]) {
    setValue('combos', combos)
    var exames = []

    combos.forEach(combo => {
      combo.exames.forEach(e => {
        if (!exames.find(exame => exame.codigo === e.codigo)) {
          exames.push(e)
        }
      })
    })

    setValue('exames', exames)
  }

  function focarCampoValor(foco) {
    setFocoValor(foco)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  function retornaPrimeiraEtapa(callback) {
    if (activeStep === StepsCotacao.resumo) return 0

    return callback
  }

  function handleTornarTemplate() {
    setOpenModal(true)
    setIsLoading(true)
  }

  function handleConfirmarTornarTemplate(tornarTemplate: boolean) {
    onSubmit(command, tornarTemplate)
    setOpenModal(false)
  }

  const handlePodePlanoDeSaude = e => {
    let valueRadio = e.target.value
    let valorBoolean = false

    handleItemValue('temPlanoDeSaude', parseInt(valueRadio))

    if (valueRadio === '1') {
      valorBoolean = true
    }
    pacoteSelecionado.temPlanoDeSaude = valorBoolean
    setPacoteSelecionado(pacoteSelecionado)
  }

  return (
    <>
      {loadingOrcamento && (
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      )}

      <Box
        hidden={loadingOrcamento}
        component="main"
        className={`fadeInTransition ${classes.container}`}
      >
        <CssBaseline />
        {/* {JSON.stringify(stepsFormulario)} */}
        {/* command: {JSON.stringify(command.valorEquipeAnestesia)}
        command: {JSON.stringify(command.equipeAnestesia)} */}
        {/* pacoteSelecionado: {JSON.stringify(pacoteSelecionado)} */}
        {/* <br /> */}
        {/* erros: {JSON.stringify(errors)} */}
        {/* <br /> */}
        {/* comorbidades: {JSON.stringify(listaComorbidades)} */}
        {ehXS && activeStep !== StepsCotacao.resumo && (
          <LinearProgress
            variant="determinate"
            value={normalise(activeStep + 1)}
          />
        )}
        <Grid container>
          {!ehXS && activeStep !== StepsCotacao.resumo && (
            <Grid item xs={12} sm={1}>
              <Box mt={5} pt={2} ml={4}>
                {/* {obterStepsAtivos()?.length} */}
                <Stepper
                  connector={<StepConnectorSyn />}
                  activeStep={obterStepAtual()}
                  style={{ margin: 0, padding: 0, background: 'none' }}
                  orientation="vertical"
                >
                  {getSteps(lastStep)}
                </Stepper>
              </Box>
            </Grid>
          )}

          <Grid item xs={12} sm={activeStep === StepsCotacao.resumo ? 12 : 10}>
            <Box
              mt={retornaPrimeiraEtapa(ehXS ? 2 : 5)}
              pt={retornaPrimeiraEtapa(ehXS ? 0 : 2)}
              p={retornaPrimeiraEtapa(ehXS ? 2 : 0)}
            >
              <form className={classes.form} noValidate={true}>
                <StepContentSyn
                  noMarginTop
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === StepsCotacao.resumo}
                  hideBack
                  handleBackToStep={handleBackToStep}
                  handleNext={() => {
                    if (!verificarPacoteValido()) return

                    // if (listaMembrosEquipe?.length > 0 && !verificouEquipe) {
                    //   handleAjusteEtapa(8)
                    //   return
                    // }

                    handleNext()
                  }}
                  textoTitulo={''}
                >
                  {/* <Autocomplete
                    erro={errors}
                    name="pacote"
                    label="Pacote de procedimento"
                    loading={loading}
                    closeOnSelect
                    value={command.pacote}
                    options={listaPacotes}
                    getOptionLabel={option => `${option.descricao}`}
                    onChange={(event, newValue) => {
                      handleItemValue(
                        'pacote',
                        newValue as ObjetoDescricaoGenerico
                      )
                    }}
                    showAddButton={perfilAtual !== SynPerfis.SECRETARIA}
                    onClickAddButton={handleNovoPacote}
                    addButtonProps={{
                      text: 'Não encontrou? clique aqui'
                    }}
                  /> */}
                  {loadingPacote || loadingMembros ? (
                    <LoadingGenerico />
                  ) : (
                    !!pacoteSelecionado &&
                    !loadingMembros && (
                      <ResumoPacote
                        telaOrcamento
                        pacote={pacoteSelecionado}
                        listaMembros={listaMembrosEquipe}
                        exibirAjusteEtapa
                        handleAjusteEtapa={handleAjusteEtapa}
                        erro={erroTempoCirurgico}
                      />
                    )
                  )}
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === StepsCotacao.paciente}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={'Quem é o seu paciente?'}
                >
                  <Grid container spacing={1}>
                    <Alert severity="info">
                      Caso seja menor de idade ou não possuir CPF, marque a
                      opção abaixo "Paciente necessita de responsável"
                    </Alert>
                    <Box>
                      <Grid
                        container
                        spacing={1}
                        style={{ width: '100%' }}
                        className={classes.noMargin}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Checkbox
                              checked={pacienteResponsavel}
                              onClick={() =>
                                setPacienteResponsavel(!pacienteResponsavel)
                              }
                            />
                            Paciente necessita de responsável
                          </Typography>
                        </Grid>
                        {pacienteResponsavel && (
                          <>
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                              <Typography variant="subtitle2">
                                Responsável
                              </Typography>
                              <Divider style={{ margin: '10px 0' }} />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                erro={errors}
                                loading={loadingPaciente}
                                name="cpfResponsavel"
                                placeholder="Ex: 999.999.999-99"
                                label="CPF do responsável"
                                type="tel"
                                hiddenLabel
                                control={control}
                                mask="999.999.999-99"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                InputProps={{
                                  autoComplete: 'new-password'
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                loading={loadingPaciente}
                                // disabled={
                                //   loadingPaciente ||
                                //   !!command.responsavelPacienteId
                                // }
                                erro={errors}
                                name="nomeResponsavel"
                                label="Nome completo do responsável"
                                inputRef={register}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                inputProps={{ maxLength: 100 }}
                              />
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                              <Typography variant="subtitle2">
                                Paciente
                              </Typography>
                              <Divider style={{ margin: '10px 0' }} />
                            </Grid>
                          </>
                        )}
                        {!pacienteResponsavel && (
                          <Grid item xs={12}>
                            <TextField
                              loading={loadingPaciente}
                              erro={errors}
                              name="cpfPaciente"
                              placeholder="Ex: 999.999.999-99"
                              label="CPF"
                              type="tel"
                              hiddenLabel
                              control={control}
                              mask="999.999.999-99"
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={6}>
                          <TextField
                            erro={errors}
                            name="nomePaciente"
                            label="Nome completo"
                            inputRef={register}
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{ maxLength: 100 }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          {/* <button
                          onClick={value =>
                            handleItemValue(
                              'dataNascimentoPaciente',
                              '10/10/2000'
                            )
                          }
                          type="button"
                        >
                          mudar valor
                        </button>
                        data:{command.dataNascimentoPaciente} */}

                          <DateInput
                            errors={errors}
                            name="dataNascimentoPaciente"
                            label="Data de nascimento"
                            value={command.dataNascimentoPaciente}
                            control={control}
                            handleChange={value =>
                              handleItemValue('dataNascimentoPaciente', value)
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            erro={errors}
                            name="telefonePaciente"
                            maskTelCel
                            value={command.telefonePaciente}
                            label="Telefone"
                            type="tel"
                            control={control}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            erro={errors}
                            name="emailPaciente"
                            label="E-mail"
                            inputRef={register}
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{ maxLength: 100 }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <RadioGroup
                            onChange={handleRadios}
                            value={command.sexoPaciente}
                          >
                            <Grid container direction="row">
                              <FormControlLabel
                                control={
                                  <Radio
                                    value={genero.Masculino}
                                    color="primary"
                                    name="pacienteSexo"
                                  />
                                }
                                label="Masculino"
                              />
                              <FormControlLabel
                                control={
                                  <Radio
                                    value={genero.Feminino}
                                    color="primary"
                                    name="pacienteSexo"
                                  />
                                }
                                label="Feminino"
                              />
                            </Grid>
                          </RadioGroup>
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            <FormHelperText error={!!errors.sexoPaciente}>
                              {errors.sexoPaciente?.message}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Ubox ml={0.5} >
                          <Typography variant="h6">
                            Paciente possui plano de saúde?
                          </Typography>
                          <Grid container spacing={0}>
                            <Grid item xs={12}>
                              <RadioGroup
                                style={{ flexDirection: 'row' }}
                                onChange={handlePodePlanoDeSaude}
                                value={command?.temPlanoDeSaude || 0}
                              >
                                <FormControlLabel
                                  control={
                                    <Radio
                                      value={1}
                                      color="primary"
                                      name="temPlanoDeSaude"
                                    />
                                  }
                                  label="Sim"
                                />
                                <FormControlLabel
                                  control={
                                    <Radio
                                      value={2}
                                      color="primary"
                                      name="temPlanoDeSaude"
                                    />
                                  }
                                  label="Não"
                                />
                              </RadioGroup>
                              <FormControl fullWidth className={classes.formControl}>
                                <FormHelperText error={!!errors.temPlanoDeSaude}>
                                  {errors.temPlanoDeSaude?.message}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Ubox>
                      </Grid>
                    </Box>
                  </Grid>
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === StepsCotacao.comorbidades}
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  handleNext={() => {
                    if (!semComorbidade && command.comorbidades.length === 0) {
                      return setComorbidadeMensagemErro(true)
                    }
                    handleNext()
                  }}
                  textoTitulo={
                    'Seu paciente possui comorbidades? Caso não tenha clique em "CONTINUAR"'
                  }
                >
                  <>
                    <Typography style={{ margin: '20px 0 0' }}>
                      <Checkbox
                        checked={semComorbidade}
                        onChange={() => toggleComorbidades()}
                      />{' '}
                      Não possui comorbidade
                    </Typography>
                    {comorbidadeMensagemErro && (
                      <Typography variant="body2" style={{ color: 'red' }}>
                        Marcar caso o paciente não tenha comorbidade
                      </Typography>
                    )}
                    {!semComorbidade && (
                      <Autocomplete
                        multiple
                        erro={errors}
                        name="comorbidades"
                        label="Comorbidades"
                        loading={loadingComorbidades}
                        options={listaComorbidades}
                        value={command.comorbidades}
                        getOptionSelected={option =>
                          !!command.comorbidades.find(l => l.id === option.id)
                        }
                        showCheck
                        showAddButton
                        getOptionLabel={option => option.descricao}
                        onChange={(
                          event,
                          newValue: ObjetoDescricaoGenerico[]
                        ) => {
                          setSemComorbidade(false)
                          setComorbidadeMensagemErro(false)
                          handleItemValue(
                            'comorbidades',
                            newValue as ObjetoDescricaoGenerico[]
                          )
                        }}
                      />
                    )}
                    <Typography variant="body2" style={{ marginTop: '20px' }}>
                      Caso não encontre o item na lista acima, favor descrevê-lo
                      no campo abaixo
                    </Typography>
                    <TextField
                      erro={errors}
                      multiline={true}
                      rows={4}
                      inputRef={register}
                      name="observacaoComorbidades"
                      label="Observações"
                      inputProps={{ maxLength: 500 }}
                    />
                  </>
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === StepsCotacao.reservaSangue}
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Será necessário reserva de sangue? Caso não necessite clique em "Continuar"'
                  }
                >
                  <TextField
                    erro={errors}
                    name="reservaSangue"
                    placeholder="Ex: 2 concentrados de hemácias"
                    label="Qtd. concentrados de hemácias"
                    control={control}
                    type="tel"
                    mask="999"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <Alert severity="info" style={{ marginTop: '20px' }}>
                    Caso necessitar de plasma, concentrados de plaquetas ou
                    outros, favor informar no campo abaixo de observações
                  </Alert>
                  <TextField
                    erro={errors}
                    multiline={true}
                    rows={4}
                    name="observacaoSangue"
                    label="Observações"
                    inputRef={register}
                    inputProps={{ maxLength: 500 }}
                  />
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === StepsCotacao.localAtendimento}
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={'Precisamos de mais algumas informações.'}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={command.solicitacaoSemLocalAtendimento}
                            onChange={handleSolicitacaoSemLocalAtendimento}
                            color="primary"
                            name="solicitacaoSemLocalAtendimento"
                          />
                        }
                        label="Não há necessidade de orçamento de hospital nessa solicitação"
                      />
                      <Alert severity="info">
                        Por exemplo, o paciente irá usar o plano de saúde para
                        os custos hospitalares ou caso o procedimento for feito
                        na própria clínica. (Nesse caso, incluir as taxas de
                        sala nos honorários da equipe).
                      </Alert>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography>
                        Por favor informe a UF de onde o procedimento será
                        realizado
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControlUf}
                        fullWidth
                      >
                        <Select
                          error={!!errors.uf}
                          displayEmpty
                          className={classes.selectEmpty}
                          labelId="label-uf"
                          placeholder="UF Local de atendimento"
                          name="uf"
                          inputRef={register}
                          disabled={
                            !command.solicitacaoSemLocalAtendimento &&
                            estadosHospitais?.length <= 1
                          }
                          onChange={(e, v) => {
                            handleItemValue('hospitais', [])
                            handleItemValue('uf', e.target.value)
                          }}
                          value={command.uf || ''}
                          defaultValue={
                            estados.find(i => i.Initials === command.uf)
                              ?.Initials
                          }
                        >
                          <MenuItem value="">
                            <em>UF</em>
                          </MenuItem>

                          {!command.solicitacaoSemLocalAtendimento &&
                            estadosHospitais.map((estado, i) => (
                              <MenuItem key={i} value={estado.Initials}>
                                {estado.Initials}
                              </MenuItem>
                            ))}

                          {command.solicitacaoSemLocalAtendimento &&
                            estados.map((estado, i) => (
                              <MenuItem key={i} value={estado.Initials}>
                                {estado.Initials}
                              </MenuItem>
                            ))}
                        </Select>

                        <FormHelperText error={!!errors.uf}>
                          {errors.uf?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    {!command.solicitacaoSemLocalAtendimento && (
                      <>
                        <Grid item xs={12}>
                          <Autocomplete
                            multiple
                            erro={errors}
                            name="hospitais"
                            label="Sugestões de hospitais"
                            loading={loadingHospitais}
                            value={command.hospitais}
                            options={listaHospitais?.filter(
                              item => item.grupo === command.uf
                            )}
                            getOptionLabel={option => `${option.descricao}`}
                            onChange={(event, newValue) => {
                              handleItemValue(
                                'hospitais',
                                newValue as ObjetoDescricaoGenerico
                              )
                            }}
                            // showAddButton
                            getOptionSelected={option =>
                              !!command.hospitais?.find(l => l.id === option.id)
                            }
                            showCheck
                            showAddButton
                          // onClickAddButton={texto =>
                          //   handleShowModal(texto, 'hospitais')
                          // }
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={command.temDisponibilidade}
                                onChange={handleDisponibilidade}
                                color="primary"
                                name="temDisponibilidade"
                              />
                            }
                            label="Disponibilidade para operar em horários de baixo fluxo?"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Box hidden={!command.temDisponibilidade}>
                            <TextField
                              erro={errors}
                              name="disponibilidade"
                              inputRef={register}
                              placeholder="Descreva sua disponibilidade... "
                              label="Disponibilidade"
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{ maxLength: 500 }}
                            />
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === StepsCotacao.honorarioFlexivel}
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Por favor revise o honorário para essa cirurgia'
                  }
                >
                  <Alert severity="info">
                    Você pode alterar os valores de honorário para essa
                    cirurgia.
                    <br />
                    Os valores aqui alterados, só servirão para essa
                    solicitação, não alterando na origem do seu pacote (você
                    pode alterar depois de forma definitiva indo até seus
                    pacotes no menu).
                  </Alert>
                  <Ubox mt={2}>
                    <Typography variant="body1">
                      Seu honorário padrão para esse pacote é:
                    </Typography>
                    <Typography variant="h5">
                      {formatarDinheiro(
                        pacoteSelecionado?.honorarioEquipeMedica
                      )}
                    </Typography>
                  </Ubox>

                  <Ubox
                    mt={3}
                    component="div"
                  // style={{ wordBreak: 'break-all' }}
                  >
                    <Typography variant="body1">
                      Em caso de negociação com o paciente, qual o valor mínimo
                      do honorário para essa cirurgia?
                    </Typography>
                    <Typography variant="subtitle2" className={classes.infoTxt}>
                      Caso não tenha negociação para essa cirurgia, deixe o
                      mesmo valor nos dois campos
                    </Typography>
                    {/* {JSON.stringify(pacoteSelecionado)} */}
                  </Ubox>

                  <Grid container alignItems="flex-start">
                    <Grid item xs={12} sm={5}>
                      <TextFieldMU
                        data-cy="valorMinHonorarios"
                        error={!!errors.valorMinHonorarios}
                        helperText={errors.valorMinHonorarios?.message}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        label="Valor mínimo"
                        value={command.valorMinHonorarios}
                        onFocus={e => e.target.select()}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const value = event.target.value
                          handleItemValue('valorMinHonorarios', value)
                        }}
                        InputProps={{
                          inputComponent: NumberFormatCustom as any
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Ubox pt={5}>
                        <Typography variant="body2" align="center">
                          até
                        </Typography>
                      </Ubox>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextFieldMU
                        data-cy="valorMaxHonorarios"
                        error={!!errors.valorMaxHonorarios}
                        helperText={errors.valorMaxHonorarios?.message}
                        variant="outlined"
                        onFocus={e => e.target.select()}
                        fullWidth
                        margin="normal"
                        label="Valor máximo"
                        disabled={true}
                        value={pacoteSelecionado?.honorarioEquipeMedica}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const value = event.target.value
                          handleItemValue('valorMaxHonorarios', value)
                        }}
                        InputProps={{
                          inputComponent: NumberFormatCustom as any
                        }}
                      />
                    </Grid>
                  </Grid>
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === StepsCotacao.anestesia}
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={'A respeito da anestesia, revise abaixo:'}
                >
                  <Alert severity="info">
                    Precisamos que você revise as informações abaixo para essa
                    cirurgia.
                    <br />
                    Lembrando que as alterações aqui feitas, servirão apenas
                    para essa solicitação, não mudando na origem do pacote(você
                    pode fazer a alteração no pacote em qualquer momento direto
                    no menu "Meus pacotes")
                  </Alert>
                  <Ubox mt={3} component="div">
                    <Typography variant="body1">
                      <b>Anestesia para essa cirurgia definida no pacote é:</b>
                    </Typography>
                    <Typography variant="body2">
                      {pacoteSelecionado?.tiposAnestesia?.length === 0 &&
                        'Nenhuma anestesia'}
                      {!!pacoteSelecionado?.tiposAnestesia?.length &&
                        pacoteSelecionado?.tiposAnestesia
                          ?.map(e => e.descricao)
                          .join(', ')}
                    </Typography>
                  </Ubox>
                  <Ubox
                    render={!command?.solicitacaoSemLocalAtendimento}
                    mt={3}
                    mb={2}
                    component="div"
                  >
                    <Typography variant="body1">
                      <b>A equipe de anestesistas, será sua ou do hospital?</b>
                    </Typography>
                    <Alert severity="info">
                      Caso você marque a opção "A equipe de anestesistas será do
                      hospital", o mesmo cotará o valor do anestesista.
                    </Alert>
                    <Grid item xs={12}>
                      <RadioGroup
                        onChange={handleRadiosEquipeAnestesia}
                        value={command.equipeAnestesia}
                      >
                        <Grid container direction="row">
                          <FormControlLabel
                            control={
                              <Radio
                                value={equipeAnestesias.medica}
                                color="primary"
                                name="anestesia"
                              />
                            }
                            label="Irei usar minha equipe de anestesistas"
                          />

                          <FormControlLabel
                            control={
                              <Radio
                                value={equipeAnestesias.hospital}
                                color="primary"
                                name="anestesia"
                              />
                            }
                            label="A equipe de anestesistas será do hospital"
                          />
                        </Grid>
                      </RadioGroup>
                      <FormControl fullWidth className={classes.formControl}>
                        <FormHelperText error={!!errors.equipeAnestesia}>
                          {errors.equipeAnestesia?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Ubox>
                  <Ubox
                    key={command?.equipeAnestesia}
                    hidden={command.equipeAnestesia !== 1}
                    mt={1}
                    component="div"
                  >
                    <Typography variant="body1">
                      <b>Qual será o honorário da equipe de anestesia?</b>
                    </Typography>
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        <TextFieldMU
                          key={ehFocoValor}
                          autoFocus={ehFocoValor === 1}
                          data-cy="valorEquipeAnestesia"
                          error={!!errors.valorEquipeAnestesia}
                          helperText={errors.valorEquipeAnestesia?.message}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          label="Honorários"
                          value={command.valorEquipeAnestesia}
                          onFocus={e => e.target.select()}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value = event.target.value
                            handleItemValue('valorEquipeAnestesia', value)
                          }}
                          InputProps={{
                            inputComponent: NumberFormatCustom as any
                          }}
                        />
                      </Grid>
                    </Grid>{' '}
                  </Ubox>
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === StepsCotacao.observacao}
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  handleNext={handleNext}
                  textoTitulo={
                    'Caso tenha alguma consideração a fazer, sinta-se à vontade para informar abaixo'
                  }
                >
                  <TextField
                    erro={errors}
                    multiline={true}
                    rows={4}
                    inputRef={register}
                    name="observacoes"
                    label="Observações"
                    inputProps={{ maxLength: 500 }}
                  />
                </StepContentSyn>

                <StepContentSyn
                  activeStep={activeStep}
                  lastStep={lastStep}
                  show={activeStep === StepsCotacao.revisao}
                  hideBack={false}
                  handleBackToStep={handleBackToStep}
                  textoTitulo={
                    'Ótimo! Agora revise as informações com ATENÇÃO antes de salvar'
                  }
                  showFinish
                  handleFinish={handleSubmit(handleTornarTemplate)}
                  textFinish="Salvar solicitação de orçamento"
                  loading={isloading}
                // formState.isSubmitting
                >
                  <Box mb={ehXS ? 3 : 0}>
                    <Typography variant="subtitle1">
                      Caso precise alterar alguma informação, clique no botão
                      'Voltar' até chegar na etapa desejada.
                    </Typography>

                    <ResumoOrcamento
                      pacoteSelecionado={pacoteSelecionado}
                      orcamento={command}
                      isMedico
                    />
                  </Box>
                </StepContentSyn>
              </form>
            </Box>
          </Grid>
        </Grid>
        {!!showModal && <ModalAddGenerico open={true} {...dadosModal()} />}
      </Box>
      <DrawerAjustarPacote
        open={ajustarPacote}
        etapa={etapaAjuste}
        pacote={pacoteSelecionado}
        handleOnClose={handleFecharAjustarPacote}
        handleSalvarAjuste={handleSalvarAjuste}
      />

      {openModal && (
        <ModalTransformTemplate
          open={openModal}
          handleClose={() => handleConfirmarTornarTemplate(false)}
          showConfirm={() => handleConfirmarTornarTemplate(true)}
        />
      )}
    </>
  )
}

function LoadingGenerico(props) {
  return (
    <Grid
      direction="column"
      item
      xs={12}
      style={{
        padding: 48,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <CircularProgress />
      <Typography variant="subtitle1" component="p">
        Carregando...
      </Typography>
    </Grid>
  )
}
