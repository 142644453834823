import MoreVertIcon from '@material-ui/icons/MoreVert'
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { AiOutlineCalendar } from 'react-icons/ai'
import { FiClock } from 'react-icons/fi'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import { IAtividade } from 'src/contexts/Atividade/context'
import { SynRoutes } from 'src/core/consts/synRoutes'
import {
  formatarDataHoraLocal,
  formatarDataLocal,
  formatarHoraLocal
} from 'src/core/formatacoes/formatarData'
import useAtividade from 'src/hooks/useAtividade'
import { ItemListagemSolicitacaoOrcamentoResponse } from 'src/types'
import UBox from '../ui/Ubox'
import Swal from 'sweetalert2'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
const useStyles = makeStyles(theme => ({
  tarefa: {
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    margin: '4px 8px',
    padding: '12px 24px 12px 4px',
    '&:hover': {
      borderRadius: '4px',
      backgroundColor: theme.palette.grey[100]
    }
  },
  flag: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: '8px',
    cursor: 'pointer',
    fontWeight: 'bold',
    color: theme.palette.grey[700],
    marginRight: '8px',
    padding: '2px 12px',
    '&:hover': {
      backgroundColor: theme.palette.grey[300]
    }
  }
}))

export interface TarefaProps {
  atividade: IAtividade
  key?: string
  mostrarHora?: boolean
  concluida?: boolean
  handleEditar?: (atividade: IAtividade) => void
}

function Tarefa({
  atividade,
  concluida = false,
  mostrarHora = false,
  handleEditar
}: TarefaProps) {
  const s = useStyles()
  const { removerAtividade, concluirAtividade } = useAtividade()
  async function handleExcluir() {
    try {
      await Swal.fire({
        title: 'Atenção!',
        text: 'Deseja realmente excluir essa atividade?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Excluir'
      }).then(async result => {
        if (result.isConfirmed) {
          await removerAtividade(atividade?.id)
        }
      })
    } catch (err) {
      showNotificationErrorAPI(err)
    }
  }

  function obterUsuarios(usuarios) {
    let montarUsuarios = []
    for (let index = 0; index < usuarios?.length; index++) {
      let nome = usuarios[index].nome
      if (index === usuarios?.length - 1) {
        montarUsuarios.push(nome + '.')
      } else {
        montarUsuarios.push(nome + ', ')
      }
    }
    return montarUsuarios
  }

  return (
    <UBox display="flex" alignItems="flex-start" className={s.tarefa}>
      {!concluida && (
        <Checkbox
          checked={atividade.concluida}
          onChange={() => concluirAtividade(atividade.id)}
          color="primary"
          name="solicitacaoSemLocalAtendimento"
        />
      )}

      <UBox display="flex" pt={1} alignItems="start" flexDirection="column">
        <Typography variant="body2" color="primary">
          <b>{atividade.titulo}</b>
        </Typography>
        {/* {!concluida && ( */}
        <UBox py={1}>
          <Typography variant="body2" color="primary">
            {atividade.descricao}
          </Typography>
        </UBox>
        {/* )} */}
        <Typography variant="caption" color="secondary">
          <UBox render={!concluida} display="flex" alignItems="center">
            <span className={s.flag}>{atividade.solicitacao.codigo}</span>
            {mostrarHora ? (
              <>
                <FiClock style={{ marginRight: 4 }} />
                {formatarHoraLocal(atividade.dataHora)}
              </>
            ) : (
              <>
                <AiOutlineCalendar style={{ marginRight: 4 }} />
                {formatarDataHoraLocal(atividade.dataHora)}
              </>
            )}
          </UBox>

          <UBox render={concluida} display="flex" alignItems="center">
            <span className={s.flag}>{atividade.solicitacao.codigo}</span>

            <>
              <span>
                Concluida por <b>{atividade.concluidaPor}</b> em
                {` ${formatarDataLocal(
                  atividade.dataConcluida
                )} ás ${formatarHoraLocal(atividade.dataConcluida)}`}
              </span>
            </>
          </UBox>
        </Typography>
        <Typography
          variant="body2"
          color="primary"
          style={{ fontStyle: 'italic' }}
        >
          <small>
            {' '}
            Criada por: {atividade.criadaPor} para{' '}
            {obterUsuarios(atividade.usuarios)}
          </small>
        </Typography>{' '}
      </UBox>
      <UBox render={!concluida} width="30px" style={{ marginLeft: 'auto' }}>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {popupState => (
            <React.Fragment>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                aria-label="mais"
                {...bindTrigger(popupState)}
              >
                <MoreVertIcon />
              </IconButton>

              <Menu
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                {...bindMenu(popupState)}
              >
                {/* <MenuItem
                  onClick={() => {
                    popupState.close()
                  }}
                >
                  Ver resumo solicitação
                </MenuItem> */}

                <MenuItem
                  onClick={() => {
                    handleEditar(atividade)
                    popupState.close()
                  }}
                >
                  Editar
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleExcluir()
                    popupState.close()
                  }}
                  className="danger"
                  style={{ color: '#ee4343' }}
                >
                  Excluir
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </UBox>
    </UBox>
  )
}

export default Tarefa
