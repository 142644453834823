import React from 'react'

import NumberFormat from 'react-number-format'

import { Container, Calendar, Icon } from 'src/styles/components/dateInput'
import { CircularProgress, TextField } from '@material-ui/core'
import CalendarToday from '@material-ui/icons/CalendarToday'

import moment from 'moment'
import { Close } from '@material-ui/icons'
import UBox from './Ubox'
interface Props {
  value: string
  errors: any
  name: string
  label: string
  control: any
  showIcon?: boolean
  variant?: 'outlined' | 'filled'

  edicao?: boolean
  handleChange: (v: any) => void
  disabled?: boolean
}

export default function DateInput({
  value,
  name,
  errors,
  label,
  disabled = false,
  showIcon = true,
  variant,
  control,
  handleChange
}: Props) {
  function obterCloseIcon() {
    let InputProps = {
      InputProps: {
        endAdornment: (
          <UBox onClick={() => handleChange('')} style={{ cursor: 'pointer' }}>
            <Close />
          </UBox>
        )
      }
    }

    return !showIcon ? InputProps : null
  }
  return (
    <Container>
      {/* <UBox width="100%">{name}</UBox> */}
      <NumberFormat
        format="##/##/####"
        mask="_"
        customInput={TextField}
        label={label}
        error={!!errors[name]}
        disabled={disabled}
        helperText={errors[name]?.message}
        placeholder="dd/mm/aaaa"
        fullWidth
        onChange={e => {
          let input = e.target.value
          var data = input.replaceAll('_', '')
          if (data.length !== 'DD/MM/YYYY'.length) return
          if (!moment(data, 'DD/MM/yyyy').isValid()) {
            handleChange('')
          } else {
            handleChange(moment(data, 'DD/MM/yyyy').format('yyyy-MM-DD'))
          }
        }}
        onBlur={e => {
          let input = e.target.value
          var data = input.replaceAll('_', '')
          if (data.length !== 'DD/MM/YYYY'.length) {
            handleChange('')
            return
          }

          if (!moment(data, 'DD/MM/yyyy').isValid()) {
            handleChange('')
          } else {
            handleChange(moment(data, 'DD/MM/yyyy').format('yyyy-MM-DD'))
          }
        }}
        variant={variant === 'filled' ? 'filled' : 'outlined'}
        margin="normal"
        value={!!value && moment(value).format('DD/MM/YYYY')}
        InputLabelProps={{
          shrink: true
        }}
        {...obterCloseIcon()}
      />
      {showIcon && (
        <Calendar onClick={() => document.getElementById('btnDate').focus()}>
          <CalendarToday style={{ position: 'absolute' }} />
          <Icon
            style={{ zIndex: '20' }}
            id="btnDate"
            type="date"
            onChange={e => {
              handleChange(e.target.value)
            }}
          />
        </Calendar>
      )}
    </Container>
  )
}
