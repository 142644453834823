import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'

import TermoDeUso from 'src/core/docs/TermoDeUso7'

export default function TermosDeUsoTexto() {
  return (
    <Container
      component="main"
      className="fadeInTransition"
      maxWidth="md"
      style={{
        padding: '24px'
        // background: '#fff'
        // margin: '10px auto 10px',
        // width: '100% !important'
        // height: 'calc(100vh - 20px)'
      }}
    >
      <CssBaseline />
      <div
        style={{
          fontSize: '2rem',
          overflow: 'auto'
        }}
        dangerouslySetInnerHTML={{ __html: TermoDeUso }}
        data-cy="termoDeUso"
      ></div>
    </Container>
  )
}
