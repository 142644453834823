import Drawer from 'src/components/ui/Drawer'

import { ComprovanteGenerico } from 'src/pages/dashboard/operacional/solicitacao/drawerAnexos'

type Props = {
  solicitacaoId: string
  destinatarioId: string
  tipo: number
  open: boolean
  handleOnClose: (boolean?) => void
}
function DrawerComprovanteDestinatario({
  solicitacaoId,
  tipo,
  destinatarioId,
  ...props
}: Props) {
  return (
    <Drawer
      anchor="right"
      open={props.open}
      titulo="Comprovantes de pagamento"
      handleOnClose={() => props.handleOnClose()}
    >
      <ComprovanteGenerico
        destinatarioId={destinatarioId}
        solicitacaoId={solicitacaoId}
        tipo={tipo}
      />
    </Drawer>
  )
}

export default DrawerComprovanteDestinatario
