import {
  Button as ButtonMU,
  ButtonProps,
  CircularProgress,
  createStyles,
  makeStyles
} from '@material-ui/core'
import React from 'react'

type ButtonCustom = ButtonProps & {
  loading?: boolean
  secondaryLoading?: boolean
}

const useStyles = makeStyles(theme =>
  createStyles({
    buttonProgress: {
      margin: '0 auto'
    }
  })
)

export const Button: React.FC<ButtonCustom> = ({
  children,
  loading,
  secondaryLoading,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <ButtonMU
      variant="contained"
      color={loading ? 'default' : 'primary'}
      disabled={!!loading}
      {...rest}
    >
      {!!loading ? (
        <CircularProgress
          color={secondaryLoading ? 'secondary' : 'primary'}
          size={24}
          className={classes.buttonProgress}
        />
      ) : (
        children
      )}
    </ButtonMU>
  )
}

export default Button
