import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'

import { RiArrowLeftLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'

import { SynRoutes } from 'src/core/consts/synRoutes'
import React, { useEffect, useState } from 'react'

import TermoDeUso from 'src/core/docs/TermoDeUso7'

import useAuth from 'src/hooks/useAuth'

import { aceitarTermoDeUso } from '../../api/auth'
import { showNotificationErrorAPI } from '../../core/helpers/notification'
import { Button } from '../../components/ui/Button'
import TipoTermo from 'src/core/consts/tipoTermo'
import { FiExternalLink } from 'react-icons/fi'
import UBox from 'src/components/ui/Ubox'
export default function PreCadastroMedico() {
  const history = useHistory()
  const [termoAceitado, setTermoAceitado] = useState(false)
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const ehSM = useMediaQuery(theme.breakpoints.down('sm'))
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))
  const { signed } = useAuth()

  const { setAceitouTermo, signOut } = useAuth()

  useEffect(() => {
    if (!signed) {
      history.push('sign-in')
    }
  }, [])
  function lerTermo(e) {
    if (
      Math.ceil(e.target.scrollHeight * 0.8) - e.target.scrollTop <=
      e.target.clientHeight
    ) {
      // setTermoLido(true)
    }
  }

  async function aceitarTermo() {
    setLoading(true)

    try {
      await aceitarTermoDeUso(TipoTermo.uso)
      setAceitouTermo(TipoTermo.privacidade)
      setAceitouTermo(TipoTermo.uso)
      history.push('/')
    } catch (err) {
      showNotificationErrorAPI(err)
    }

    setLoading(false)
  }

  return (
    <Container
      component="main"
      className="fadeInTransition syn-container-out"
      maxWidth="md"
      style={
        {
          // padding: '30px',
          // margin: '10px auto 10px',
          // width: '100% !important'
          // height: 'calc(100vh - 20px)'
        }
      }
    >
      <CssBaseline />
      <Box
        className="syn-box-out"
        style={{
          width: ehSM ? '100%' : '800px',
          height: ehSM ? '100vh' : 'auto'
        }}
      >
        <Grid container justify="space-between">
          <Grid item xs={6}>
            <Link
              component="button"
              color="secondary"
              onClick={() => signOut()}
            >
              <RiArrowLeftLine />
              <Typography
                style={{ marginLeft: 10 }}
                variant="subtitle1"
                component="span"
              >
                Voltar
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box textAlign="center">
            <img src="/image/logo.png" alt="logo syn saúde" width="45" />
            <Typography variant="body1" component="h3">
              <b>Precisamos que leia e aceite nosso termo de uso e políticas</b>
            </Typography>
            <Typography variant="body1">
              Ao continuar, você declara que leu e aceitou nossos termos de uso
              e política de privacidade.
            </Typography>
          </Box>
          <Box mt={ehXS ? 4 : 2}>
            <Button
              type="button"
              variant="text"
              onClick={() => window.open(SynRoutes.termosDeUsoTexto, '_blank')}
            >
              Ver termo de uso <FiExternalLink style={{ marginLeft: '10px' }} />
            </Button>
            <br />
            <Button
              type="button"
              variant="text"
              onClick={() =>
                window.open(SynRoutes.politicaDePrivacidadeTexto, '_blank')
              }
            >
              Ver políticas de privacidade{' '}
              <FiExternalLink style={{ marginLeft: '10px' }} />
            </Button>
          </Box>
          <Box
            mt={3}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              fontSize: '12px'
            }}
          >
            <UBox mb={2}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={loading}
                      checked={termoAceitado}
                      onChange={(e, value) => setTermoAceitado(value)}
                      value={termoAceitado}
                    />
                  }
                  label="Li e aceito os termos de uso e as políticas de privacidade"
                />
              </FormGroup>
            </UBox>
            <Button
              variant="contained"
              color="primary"
              disabled={!termoAceitado || loading}
              onClick={() => aceitarTermo()}
              loading={loading}
              data-cy="aceitarTermo"
              size="small"
            >
              Continuar
            </Button>
          </Box>
        </Grid>
      </Box>
    </Container>
  )
}
