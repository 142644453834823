import NumberFormat from 'react-number-format'

interface NumberFormatCustomProps {
  inputRef?: (instance: NumberFormat | null) => void
  onChange?: (event: { target: { name: string; value: string } }) => void
  name?: string
}

export function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      decimalScale={2}
      fixedDecimalScale
      allowedDecimalSeparators={[',']}
      decimalSeparator=","
      thousandSeparator="."
      isNumericString
      prefix="R$ "
      type="tel"
    />
  )
}
