import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'

import { useHistory } from 'react-router-dom'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { LockOutlined, DynamicFeed, SdCardOutlined } from '@material-ui/icons'

function menuLateral() {
  const history = useHistory()

  return (
    <List>
      <ListItem button onClick={() => history.push('/')}>
        <ListItemIcon>
          <AssignmentOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Solicitações" />
      </ListItem>
      <ListItem button onClick={() => history.push(SynRoutes.alterarSenha)}>
        <ListItemIcon>
          <LockOutlined />
        </ListItemIcon>
        <ListItemText primary="Alterar senha" />
      </ListItem>
      <ListItem button onClick={() => history.push(SynRoutes.termos)}>
        <ListItemIcon>
          <SdCardOutlined />
        </ListItemIcon>
        <ListItemText primary="Termos" />
      </ListItem>
      {/* <ListItem button onClick={() => history.push(SynRoutes.historicoAtualizacoes)}>
        <ListItemIcon>
          <DynamicFeed />
        </ListItemIcon>
        <ListItemText primary="Atualizações" />
      </ListItem> */}
    </List>
  )
}

export default menuLateral
