import { AxiosResponse } from 'axios'
import api from 'src/services/api'
import { LogomarcaCommand, LogomarcaMedicoResponse } from 'src/types'

export const SalvarLogomarca: (
  command: LogomarcaCommand
) => Promise<AxiosResponse<void>> = command => {
  try {
    const fromData = new FormData()
    fromData.append('Arquivo', command.arquivo)

    return api.post('/medico/logomarca', fromData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const BuscarLogomarca: () => Promise<
  AxiosResponse<LogomarcaMedicoResponse>
> = () => {
  try {
    return api.get('medico/buscarLogomarca')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const RemoverLogomarca: () => Promise<
  AxiosResponse<LogomarcaMedicoResponse>
> = () => {
  try {
    return api.delete('medico/excluirLogomarca')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}
