import { Box, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import Button from 'src/components/ui/Button'
import Drawer from 'src/components/ui/Drawer'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { verificarCPF, onlyNumber } from 'src/core/helpers/common'

import yup from 'src/components/yupCustom'
import { TextField } from 'src/components/ui/TextField'
import { cadastrarCpf } from 'src/api/paciente'
import Swal from 'sweetalert2'
import { enviarInstrucoesNF } from 'src/api/proposta'
import UBox from '../ui/Ubox'
import {
  ItemListagemMembroEquipe,
  salvarContatoMembroEquipe
} from 'src/api/membroEquipe'

interface IMembroContato {
  id?: string
  email: string
  telefone: string
}

const DEFAULT: IMembroContato = {
  email: '',
  telefone: ''
}

type Props = {
  id: string
  item: ItemListagemMembroEquipe
  open: boolean
  handleOnClose: (boolean?) => void
}

const schema = yup.object().shape({
  email: yup.string().email().required().label('E-mail'),
  telefone: yup
    .string()
    .required()
    .test('tel-válido', 'O telefone é inválido', function (value) {
      if (!value) return false

      var qtdNumero = onlyNumber(value).length

      return qtdNumero === 11 || qtdNumero === 10
    })
    .label('Telefone')
})

function DrawerSalvarContato(props: Props) {
  const [loading, setLoading] = useState(false)

  const { errors, watch, control, register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  })

  const command = watch()

  async function submit() {
    setLoading(true)

    try {
      await salvarContatoMembroEquipe({
        id: props.id,
        email: command.email,
        telefone: command.telefone
      })

      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Dados de contato salvo com sucesso',
        confirmButtonText: 'Ok'
      })

      props.handleOnClose()
    } catch (err) {
      showNotificationErrorAPI(err)
    }

    setLoading(false)
  }

  return (
    <Drawer
      anchor="right"
      open={props.open}
      titulo={`Contato de ${props.item.descricao}`}
      handleOnClose={() => props.handleOnClose()}
    >
      <form onSubmit={handleSubmit(submit)}>
        <Typography variant="subtitle1" style={{ marginBottom: '1em' }}>
          Insira os novos dados de contato do membro{' '}
          <b>{props.item.descricao}</b> para envio de comprovantes de pagamento.
        </Typography>
        <UBox>
          <TextField
            control={control}
            name="telefone"
            placeholder="Telefone"
            label="Telefone (Whatsapp)"
            variant="outlined"
            fullWidth
            size="small"
            maskTelCel
            erro={errors}
            value={command.telefone}
          />
        </UBox>
        <UBox>
          <TextField
            // disabled={loadingDados}
            size="small"
            erro={errors}
            inputRef={register}
            inputProps={{ maxLength: 64 }}
            name="email"
            label="E-mail"
            InputLabelProps={{
              shrink: true
            }}
          />
        </UBox>
        <Box mt={3}>
          <Button
            fullWidth
            // onClick={submit}
            type="submit"
            loading={loading}
            disabled={loading}
            secondaryLoading
          >
            Enviar
          </Button>
        </Box>
      </form>
    </Drawer>
  )
}

export default DrawerSalvarContato
