import Holidays from 'date-holidays';
import moment from 'moment';
import { formatarData } from 'src/core/formatacoes/formatarData';

export const getBrazilianHolidays = (year: number): string[] => {
  const hd = new Holidays('BR');
  const holidays = hd.getHolidays(year);
  return holidays.map(holiday => holiday.date);
};

export const isHolidayOrWeekend = (date: string, time: string): boolean => {
  const momentDate = moment(date, 'DD/MM/YYYY');
  const holidays = getBrazilianHolidays(momentDate.year());
  const holidaysFormatted = holidays.map(holiday => formatarData(holiday));
  const isHoliday = holidaysFormatted.includes(momentDate.format('DD/MM/YYYY'));
  const dayOfWeek = momentDate.day();

  if (isHoliday) {
    return true;
  }

  if (dayOfWeek === 0) {
    return true;
  }

  // Se for sábado, só permite agendamento após as 12:00
  if (dayOfWeek === 6) {
    const momentTime = moment(time, 'HH:mm');
    const noon = moment('12:00', 'HH:mm');
    return momentTime.isSameOrAfter(noon);
  }
};
