import { IconButton, InputAdornment, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { FiLock } from 'react-icons/fi'
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri'
import { TextField, TextFieldPropCustom } from './TextField'

const useStyles = makeStyles(theme => ({
  inputIcon: { color: theme.palette.grey[500] }
}))

export const PasswordField: React.FC<TextFieldPropCustom> = ({ ...rest }) => {
  const classes = useStyles()
  const [mostrarSenha, setMostrarSenha] = useState<boolean>(false)
  return (
    <TextField
      type={mostrarSenha ? 'text' : 'password'}
      inputProps={{
        form: {
          autoComplete: 'off'
        }
      }}
      InputProps={{
        autoComplete: 'off',
        startAdornment: (
          <InputAdornment position="start">
            <FiLock className={classes.inputIcon} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setMostrarSenha(!mostrarSenha)
              }}
              aria-label="mostrar/esconder senha"
              color="primary"
            >
              {mostrarSenha ? <RiEyeCloseLine /> : <RiEyeLine />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...rest}
    />
  )
}

export default PasswordField
