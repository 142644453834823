import { Typography } from '@material-ui/core'
import { useEffect } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { FcInfo } from 'react-icons/fc'
import { GiAlarmClock } from 'react-icons/gi'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { useHistory } from 'react-router-dom'
import { toast, Bounce } from 'react-toastify'
import UBox from 'src/components/ui/Ubox'
import { SynRoutes } from 'src/core/consts/synRoutes'
import useAtividade from 'src/hooks/useAtividade'
import useAuth from 'src/hooks/useAuth'
import { UserAuth } from 'src/types'

interface IProps {
  notificacao: INotificacao
  user?: UserAuth
}

export interface INotificacao {
  id: string
  tipo: number
  criado: string
  titulo: string
  texto: string
  urllink: string
  solicitacaoOrcamentoId: string
  destinatarioid: string
  visualizado: boolean
  codigo: string
}

export const callbackNotificacao = (notificacao, user = null) => {
  // console.log('@signal: ', notificacao, user)

  //   if (notificacao.from !== user.id) {
  toast.info(<Notificacao notificacao={notificacao} user={user} />, {
    autoClose: false,
    transition: Bounce,
    delay: 250
  })
  //   }
}

export default function Notificacao({ notificacao, user }: IProps) {
  const { atualizarResumo } = useAuth()
  const history = useHistory()
  function play() {
    var audio = new Audio('sound/notification.mp3')
    audio.play()
  }
  useEffect(() => {
    play()
    setTimeout(() => {
      atualizarResumo()
    }, 1000)
  }, [])

  function handleOnClick() {
    if (notificacao.urllink) {
      history.push(notificacao.urllink)
    }
  }

  return (
    <UBox onClick={handleOnClick}>
      <UBox mb={2} display="flex">
        <HiOutlineLightBulb
          style={{ marginRight: '8px', fontSize: '1.3rem', color: '#fff' }}
        />
        <Typography variant="body2">
          <b>{notificacao.titulo}</b>
        </Typography>
      </UBox>
      <Typography variant="body2">
        {notificacao.texto}
        {/* {JSON.stringify(notificacao)} */}
        <br />
        {/* {JSON.stringify(user)} */}
      </Typography>
    </UBox>
  )
}
