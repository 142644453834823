import { Box, Button, ButtonGroup, Grid, Typography } from '@material-ui/core'
import Atividades from 'src/components/atividade/Atividades'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import { SynPerfis } from 'src/core/consts/synPerfis'
import useAtividade from 'src/hooks/useAtividade'
import useAuth from 'src/hooks/useAuth'

function tarefas() {
  const { loading } = useAtividade()
  const { perfilAtual } = useAuth()
  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5">Atividades programadas</Typography>
      </Box>

      {loading && <LoadingGeral />}

      {!loading && <Atividades />}
    </Box>
  )
}

export default tarefas
