import { Box, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Button from 'src/components/ui/Button'
import Drawer from 'src/components/ui/Drawer'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import {
  alterarStatusProposta,
  alterarStatusPropostaEncerrada
} from 'src/api/solicitacao-orcamento'
import { AlterarStatusPropostaCommand, DadosPropostaHistorico } from 'src/types'
import { Alert } from '@material-ui/lab'
import StatusProposta from 'src/core/consts/statusProposta'

type Props = {
  proposta: DadosPropostaHistorico
  open: boolean
  ehAguardandoRespostaPaciente?: boolean
  handleOnClose: (boolean?) => void
  obterPropostas: () => void
  isDisabled?: boolean
}

function DrawerAlterarStatusProposta(props: Props) {
  const [loadingStatus, setLoadingStatus] = useState(true)
  const [loadingSalvar, setLoadingSalvar] = useState(false)
  const [observacao, setObservacao] = useState('')
  const [qtdCaracteres] = useState(props.isDisabled ? 150 : 50)
  const [qtdObservacao, setQtdObservacao] = useState(qtdCaracteres)

  useEffect(() => {
    if (props.open === true) {
      setLoadingStatus(false)
    }
    if (!props.proposta) {
      return
    }
    setObservacao('')
  }, [props.open])

  async function handleSalvar() {
    try {
      setLoadingSalvar(true)
      var command: AlterarStatusPropostaCommand = {
        idProposta: props.proposta.id,
        observacao: observacao
      }

      if (props.isDisabled === true) {
        command.status = StatusProposta.DESATIVADA.Id
      }

      if (props?.proposta?.status === StatusProposta.ENCERRADA.Id) {
        await alterarStatusPropostaEncerrada(command)
      } else {
        await alterarStatusProposta(command)
      }

      setLoadingSalvar(false)
      props.handleOnClose(true)
    } catch (e) {
      setLoadingSalvar(false)
      showNotificationErrorAPI(e)
      props.handleOnClose()
    }
  }

  return (
    <Drawer
      loading={loadingStatus}
      anchor="right"
      open={props.open}
      titulo={
        props?.isDisabled ? 'Desativar a proposta' : 'Estornar a proposta'
      }
      handleOnClose={() => props.handleOnClose()}
    >
      {props?.proposta?.status !== StatusProposta.ENCERRADA.Id &&
        props?.isDisabled === false && (
          <Alert severity="info">
            {props.ehAguardandoRespostaPaciente ? (
              <>
                <Typography variant="subtitle2">
                  A proposta será estornada para o status Aguardando aprovação
                  médica.
                </Typography>
              </>
            ) : (
              <Typography variant="subtitle2">
                A proposta será estornada para o status Aguardando aprovação
                paciente.
              </Typography>
            )}
          </Alert>
        )}

      {props?.isDisabled === true && (
        <Alert severity="warning">
          <Typography variant="subtitle2">
            A proposta será desativada, e não podera ser utilizada
            posteriormente.
          </Typography>
        </Alert>
      )}
      <Box mt={2}>
        <TextField
          fullWidth
          label={
            props?.isDisabled ? 'Motivo da desativação' : 'Motivo do estorno'
          }
          multiline
          rows={6}
          placeholder="Digite o motivo da alteração do status"
          variant="outlined"
          value={observacao}
          onChange={e => {
            setObservacao(e.target.value)
            setQtdObservacao(qtdCaracteres - e.target.value.length)
          }}
          inputProps={{ maxLength: qtdCaracteres }}
        />
        {qtdObservacao <= 10 ? (
          <Typography variant="subtitle2" component="h3" color="error">
            {qtdObservacao} caracteres restantes
          </Typography>
        ) : (
          <Typography variant="subtitle2" component="h3" color="primary">
            {qtdObservacao} caracteres restantes
          </Typography>
        )}
      </Box>

      <Box mt={3}>
        <Button
          disabled={loadingSalvar}
          fullWidth
          onClick={handleSalvar}
          loading={loadingSalvar}
          secondaryLoading
        >
          {props?.isDisabled ? 'Desativar' : 'Salvar'}
        </Button>
      </Box>
    </Drawer>
  )
}

export default DrawerAlterarStatusProposta
