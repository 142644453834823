import React from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'

type Props = {}

const LoadingPage = (props: Props) => {
  return (
    <Backdrop
      className="fadeInTransition"
      open={true}
      style={{ backgroundColor: '#eee' }}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  )
}

export default LoadingPage
