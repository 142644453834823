import {
  CircularProgress,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useEffect } from 'react'
import 'react-credit-cards/es/styles-compiled.css'
import { useHistory } from 'react-router-dom'
import Button from 'src/components/ui/Button'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import UBox from 'src/components/ui/Ubox'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import usePagamento from 'src/hooks/usePagamento'
import { useQuery } from 'src/hooks/useQuery'

const useStyles = makeStyles(theme => ({}))
interface IProps {
  integral: boolean
  valor?: number
  handleProximoPagamento?: () => void
}

export default function Pix({
  integral = true,
  valor,
  handleProximoPagamento
}: IProps) {
  const styles = useStyles()
  const theme = useTheme()
  const query = useQuery()
  const history = useHistory()

  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    loadingPix,
    propostaId,
    resumo,
    urlPix,
    urlPixFracionado,
    pixCopiaCola,
    pixCopiaColaFracionado,
    copyTextToClipboard,
    pagamentoPix,
    obterResumoPagamento
  } = usePagamento()

  function obterValor() {
    return integral ? resumo.valorTotal : valor
  }

  function obterQrCode() {
    // console.log('integral', integral, urlPix, urlPixFracionado)
    return integral ? urlPix : urlPixFracionado
  }

  function obterPixCopiaCola() {
    // console.log('integral', integral, urlPix, urlPixFracionado)
    return integral ? pixCopiaCola : pixCopiaColaFracionado
  }
  function verificaPagamentoPago() {
    if (
      !!obterQrCode() &&
      resumo.pedidos
        .find(p => p.urlQrCode === obterQrCode())
        ?.status.toLowerCase() === 'pago'
    ) {
      return true
    }

    return false
  }
  useEffect(() => {}, [])

  useEffect(() => {
    if (!!obterQrCode()) {
      var intervalHub = setInterval(() => {
        obterResumoPagamento(propostaId, false)
      }, 1 * 60 * 1000)
    }

    return () => {
      // console.log('destroycontextatividade')
      clearInterval(intervalHub)
    }
  }, [urlPix, urlPixFracionado])

  if (!resumo) return <LoadingGeral />

  return (
    <UBox>
      {/* {JSON.stringify(resumo)} */}
      {/* <br />
      {JSON.stringify(urlPixFracionado)}
      <br />
      {JSON.stringify(urlPix)}
      <br />
      {JSON.stringify(obterQrCode())} */}
      <UBox p={ehXS ? 0 : 2} my={1}>
        <Typography variant="body2">
          Pague com Pix em qualquer dia e a qualquer hora! O pagamento é
          instantâneo, prático e pode ser feito em poucos segundos. É muito
          rápido e seguro.
        </Typography>
      </UBox>
      <UBox p={ehXS ? 0 : 2} render={!obterQrCode()} my={3}>
        <UBox
          mt={3}
          mb={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body1">
            <b>Total:</b>
          </Typography>
          <Typography variant="body1">
            <b>{formatarDinheiro(obterValor())}</b>
          </Typography>
        </UBox>
        <Button
          color="primary"
          fullWidth
          disabled={loadingPix || !obterValor()}
          loading={loadingPix}
          onClick={() =>
            pagamentoPix({ integral: integral, valor: obterValor() })
          }
        >
          Gerar qr code
        </Button>
      </UBox>
      <UBox render={!!obterQrCode()}>
        {!verificaPagamentoPago() && (
          <UBox display="flex" alignItems="center">
            <CircularProgress size="24px" style={{ marginRight: '10px' }} />
            <b>Estamos aguardando o pagamento! </b>
          </UBox>
        )}
        {verificaPagamentoPago() && (
          <Alert severity="success">
            <b>Parabéns! O pagamento foi identificado.</b>
          </Alert>
        )}
      </UBox>
      <UBox
        render={!!obterQrCode()}
        display="flex"
        alignItems="center"
        flexDirection={ehXS ? 'column' : 'row'}
        justifyContent="center"
      >
        {!!obterQrCode() ? (
          <img
            className="qr-code"
            src={obterQrCode()}
            alt="QR Code pagamento"
            title="QR Code pagamento"
          />
        ) : (
          <div style={{ width: 240, height: 240, background: '#eee' }} />
        )}
        <UBox maxWidth={ehXS ? '100%' : '50%'} ml={ehXS ? 0 : 2}>
          <UBox
            mt={2}
            display="flex"
            alignItems="center"
            className="box-instructions"
          >
            <div className="icon-instructions">
              <i className="fa fa-mobile-screen-button" />
            </div>
            <Typography variant="body2">
              <b>1.</b> Abra o app do seu banco ou instituição financeira e
              entre no ambiente <b>Pix</b>
            </Typography>
          </UBox>
          <UBox
            mt={2}
            display="flex"
            alignItems="center"
            className="box-instructions"
          >
            <div className="icon-instructions">
              <i className="fa fa-qrcode" />
            </div>
            <Typography variant="body2">
              <b>2.</b> Escolha a opção <b>pagar com qr code</b> e escaneie o
              código ao lado
            </Typography>
          </UBox>
          <UBox
            mt={2}
            display="flex"
            alignItems="center"
            className="box-instructions"
          >
            <div className="icon-instructions">
              <i className="fa fa-check" />
            </div>
            <Typography variant="body2">
              <b>3.</b> Confirme as informações e finalize a compra total:{' '}
              <b>{formatarDinheiro(obterValor())}</b>
            </Typography>
          </UBox>
        </UBox>
      </UBox>
      <UBox render={!!obterQrCode()} mt={1}>
        <Button
          color="primary"
          fullWidth
          variant="outlined"
          loading={loadingPix}
          onClick={() => copyTextToClipboard(obterPixCopiaCola())}
        >
          Pix copia e cola
        </Button>
      </UBox>
      <UBox render={!integral && !!obterQrCode()} my={3}>
        <Button color="primary" fullWidth onClick={handleProximoPagamento}>
          Próximo pagamento
        </Button>
      </UBox>
    </UBox>
  )
}
