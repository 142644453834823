import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { buscarAssistentePorId, salvarAssistente } from 'src/api/medico'
import Button from 'src/components/ui/Button'
import Drawer from 'src/components/ui/Drawer'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import { TextField } from 'src/components/ui/TextField'
import Ubox from 'src/components/ui/Ubox'
import yup from 'src/components/yupCustom'
import { onlyNumber, verificarCPF } from 'src/core/helpers/common'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import {
  ItemListagemAssistenteMedicoResponse,
  SalvarAssistenteCommand
} from 'src/types'
import Swal from 'sweetalert2'

type Props = {
  assistente: ItemListagemAssistenteMedicoResponse
  open: boolean
  handleOnClose: (boolean?) => void
  obterAssistentes: () => void
}

const useStyles = makeStyles(theme => ({
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  formControl: {
    margin: theme.spacing(1, 0, 0)
    // minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  noMargin: {
    margin: theme.spacing(0)
  }
}))

const schema = yup.object().shape({
  cpf: yup
    .string()
    .required()
    .test('cpf-válido', 'O CPF é inválido', function (value) {
      if (!value) return false
      return verificarCPF(onlyNumber(value))
    })
    .label('CPF'),
  id: yup.string().label('Id'),
  nome: yup.string().required().min(3).max(100).label('Nome'),
  telefone: yup.string().required().label('Telefone'),
  email: yup.string().email().label('E-mail'),
  ehCriarSolicitacao: yup.bool().label('Pode criar Orçamentos'),
  ehReceberNotificacao: yup
    .bool()
    .label('Receber notificações das minhas solicitações')
})

interface Assistente {
  id: string
  nome: string
  telefone: string
  email: string
  cpf: string
  ehCriarSolicitacao: boolean
  ehReceberNotificacao: boolean
  podeEditarSolicitacao: boolean
  podeAprovarRecusarProposta: boolean
}

function DrawerCadastrarAssistente(props: Props) {
  const classes = useStyles()
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingSalvar, setLoadingSalvar] = useState<boolean>(false)

  const [usuarioComCPF, setUsuarioComCPF] = useState<boolean>(false)

  const [ehEdicao, setEdicao] = useState<boolean>(false)
  let defaultValues: Assistente = {
    telefone: '',
    cpf: '',
    ehCriarSolicitacao: false,
    ehReceberNotificacao: false,
    podeAprovarRecusarProposta: false,
    podeEditarSolicitacao: false,
    email: '',
    id: '',
    nome: ''
  }

  const {
    control,
    register,
    errors,
    watch,
    setValue,
    clearErrors,
    reset,
    handleSubmit
  } = useForm<Assistente>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: defaultValues
  })
  const command = watch()

  useEffect(() => {
    verificaEhEdicao(props?.assistente?.id)
  }, [props.assistente])

  useEffect(() => {
    register('telefone')
    register('id')
    register('cpf')
    register('ehCriarSolicitacao')
    register('ehReceberNotificacao')
    register('podeAprovarRecusarProposta')
    register('podeEditarSolicitacao')
    register('email')
    register('nome')
  }, [register])

  function verificaEhEdicao(id) {
    if (id) {
      setLoading(true)
      return obterAssistente(id)
    }
    return
  }

  useEffect(() => {
    if (props.open) {
      reset()
      setEdicao(false)
    }
  }, [props.open])

  const obterAssistente = id => {
    return buscarAssistentePorId(id)
      .then(response => {
        const form = response.data

        for (const key in form) {
          let value = form[key]
          if (
            key === 'ehCriarSolicitacao' ||
            key === 'ehReceberNotificacao' ||
            key === 'podeAprovarRecusarProposta' ||
            key === 'podeEditarSolicitacao'
          ) {
            setValue(key as keyof Assistente, !!value, {
              shouldValidate: false
            })
            continue
          }
          setValue(key as keyof Assistente, value, { shouldValidate: false })
        }
        setEdicao(true)
        setUsuarioComCPF(!!form.cpf)
        setLoading(false)
      })
      .catch(err => {
        showNotificationErrorAPI(err)
        setLoading(false)
      })
  }

  function clearForm() {
    clearErrors()
    reset()
  }

  async function onSubmit() {
    var body: SalvarAssistenteCommand = {
      id: ehEdicao ? command.id : null,
      cpf: onlyNumber(command.cpf),
      nome: command.nome,
      telefone: command.telefone,
      email: command.email,
      ehCriarSolicitacao: command.ehCriarSolicitacao,
      ehReceberNotificacao: command.ehReceberNotificacao,
      podeAprovarRecusarProposta: command.podeAprovarRecusarProposta,
      podeEditarSolicitacao: command.podeEditarSolicitacao
    }
    setLoadingSalvar(true)

    try {
      await salvarAssistente(body)

      Swal.fire({
        title: 'Sucesso!',
        text: 'Assistente adicionado!',
        icon: 'success',
        confirmButtonText: 'Ok'
      })

      props.obterAssistentes()
      props.handleOnClose()
      clearForm()
    } catch (err) {
      showNotificationErrorAPI(err)
    }

    setLoadingSalvar(false)
  }

  return (
    <Drawer
      anchor="right"
      open={props.open}
      titulo={ehEdicao ? 'Editar assistente' : 'Cadastrar assistente'}
      handleOnClose={() => props.handleOnClose()}
    >
      {/* <div style={{ wordBreak: 'break-all' }}>
        command: {JSON.stringify(command)}
      </div> */}
      {loading && <LoadingGeral />}
      <Ubox hidden={loading}>
        {/* {!ehXS && (
          <Box mb={2}>
            <Typography variant="subtitle2">
              <Alert severity="warning">Preencha os campos abaixo.</Alert>
            </Typography>
          </Box>
        )} */}
        <Box>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.form}
            noValidate={true}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                {/* {JSON.stringify(ehEdicao && !!command.cpf)} */}
                <TextField
                  disabled={ehEdicao && usuarioComCPF}
                  name="cpf"
                  placeholder="CPF"
                  label="CPF*"
                  variant="outlined"
                  mask={'999.999.999-99'}
                  erro={errors}
                  control={control}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  erro={errors}
                  name="telefone"
                  maskTelCel
                  value={command.telefone}
                  label="Telefone*"
                  type="tel"
                  control={control}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                erro={errors}
                inputRef={register}
                variant="outlined"
                name="nome"
                label="Nome*"
                placeholder="Nome"
                value={command.nome}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  autoComplete: 'new-password'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                erro={errors}
                name="email"
                label="E-mail"
                inputRef={register}
                value={command.email}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  autoComplete: 'new-password'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box mb={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ehCriarSolicitacao"
                      checked={command.ehCriarSolicitacao}
                      onClick={() =>
                        setValue(
                          'ehCriarSolicitacao',
                          !command.ehCriarSolicitacao
                        )
                      }
                    />
                  }
                  label=" Criar solicitações"
                />

                <Alert severity="info">
                  Ao marcar esta opção, você autoriza seu assistente a solicitar
                  e acompanhar na plataforma as solicitações de cirurgias em seu
                  nome.
                </Alert>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ehReceberNotificacao"
                      checked={command.ehReceberNotificacao}
                      onClick={() =>
                        setValue(
                          'ehReceberNotificacao',
                          !command.ehReceberNotificacao
                        )
                      }
                    />
                  }
                  label="Receber notificações"
                />

                <Alert severity="info">
                  Ao marcar esta opção, você autoriza seu assistente a receber
                  uma cópia de todas as notificações que a plataforma envia a
                  respeito das solicitações.
                  <br /> O número cadastrado precisa ser WhatsApp. Esta opção te
                  ajudará com lembretes no dia-a-dia.
                </Alert>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box mb={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="podeEditarSolicitacao"
                      checked={command.podeEditarSolicitacao}
                      onClick={() =>
                        setValue(
                          'podeEditarSolicitacao',
                          !command.podeEditarSolicitacao
                        )
                      }
                    />
                  }
                  label=" Pode editar solicitação"
                />

                {/* <Alert severity="info">
                  Ao marcar esta opção, você autoriza seu assistente a solicitar
                  e acompanhar na plataforma as solicitações de cirurgias em seu
                  nome.
                </Alert> */}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box mb={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="podeAprovarRecusarProposta"
                      checked={command.podeAprovarRecusarProposta}
                      onClick={() =>
                        setValue(
                          'podeAprovarRecusarProposta',
                          !command.podeAprovarRecusarProposta
                        )
                      }
                    />
                  }
                  label=" Pode aprovar / recusar propostas"
                />

                {/* <Alert severity="info">
                  Ao marcar esta opção, você autoriza seu assistente a solicitar
                  e acompanhar na plataforma as solicitações de cirurgias em seu
                  nome.
                </Alert> */}
              </Box>
            </Grid>
            <Box mt={3}>
              <Button
                loading={loadingSalvar}
                disabled={loadingSalvar}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Box>{' '}
          </form>
        </Box>
      </Ubox>
    </Drawer>
  )
}

export default DrawerCadastrarAssistente
