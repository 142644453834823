import { makeStyles, Typography } from '@material-ui/core'
import { useEffect } from 'react'
import codigoBancos from 'src/core/consts/listaBancos'
import { IAny } from 'src/types'

import useAuth from 'src/hooks/useAuth'

import { MembroEquipeResponse } from 'src/api/membroEquipe'
import UBox from 'src/components/ui/Ubox'
import formatarDocumento from 'src/core/formatacoes/formatarDocumento'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'

interface IResumoProps extends IAny {
  membro: MembroEquipeResponse
}

const useStylesResumo = makeStyles(theme => ({
  boxResumo: {
    border: '1px solid #eee',
    backgroundColor: '#efefef',
    borderRadius: 4,
    '& .MuiTableCell-root.MuiTableCell-head': {
      padding: theme.spacing(1, 2),
      background: '#f9f9f9',
      color: theme.palette.grey[800]
    },
    '& h3': {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  esconderEditar: {
    '& button': {
      display: 'none'
    }
  },
  fontMarca: {
    fontStyle: 'italic',
    fontWeight: 500,
    fontSize: '0.8rem',
    color: theme.palette.grey[700]
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))

const ResumoMembroEquipe = (props: IResumoProps) => {
  const { membro } = props
  const classes = useStylesResumo()

  const { user, perfilAtual } = useAuth()
  useEffect(() => {}, [])

  type BtnAjustarProps = {
    onClick: () => void
    erro?: boolean
  }

  function obterNomeBanco(codigo) {
    var c = codigoBancos.find(w => w.id === codigo)
    return c.descricao
  }
  return (
    <UBox mt={2} p={2} className={classes.boxResumo}>
      {/* <UBox style={{ wordBreak: 'break-all' }}>
        {JSON.stringify(pacote.valoresInternacao)}
      </UBox> */}

      <Typography variant="subtitle2" component="h3" color="primary">
        Descrição
      </Typography>
      <Typography variant="body2">{membro?.descricao}</Typography>
      <br />
      <Typography variant="subtitle2" component="h3" color="primary">
        Nome / Razão social
      </Typography>
      <Typography variant="body2">{membro?.nomeRazaoSocial}</Typography>
      <br />
      <Typography variant="subtitle2" component="h3" color="primary">
        Documento
      </Typography>
      <Typography variant="body2">
        {formatarDocumento(membro?.documento)}
      </Typography>
      <br />
      <Typography variant="subtitle2" component="h3" color="primary">
        Banco
      </Typography>
      <Typography variant="body2">
        {membro?.codigoBanco}-{obterNomeBanco(membro?.codigoBanco)}
      </Typography>
      <br />
      <Typography variant="subtitle2" component="h3" color="primary">
        Conta
      </Typography>
      <Typography variant="body2">
        {membro?.agencia}
        {membro?.digitoAgencia ? '-' + membro?.digitoAgencia : ''}
      </Typography>
      <Typography variant="body2">
        {membro?.numeroConta}
        {membro?.digitoConta ? '-' + membro?.digitoConta : ''}
      </Typography>
      <br />
      <Typography variant="subtitle2" component="h3" color="primary">
        WhatsApp (Comprovante)
      </Typography>
      <Typography variant="body2">
        {!!membro?.telefone
          ? formatarTelefone(membro.telefone)
          : 'Não informado'}
      </Typography>
      <br />
      <Typography variant="subtitle2" component="h3" color="primary">
        E-mail (Comprovante)
      </Typography>
      <Typography variant="body2">
        {!!membro?.email ? membro.email : 'Não informado'}
      </Typography>
    </UBox>
  )
}

export default ResumoMembroEquipe
