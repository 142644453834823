import { IconButton, TextField as TextFieldMU } from '@material-ui/core'
import React, { CSSProperties, FC, KeyboardEvent, useCallback } from 'react'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import Button from 'src/components/ui/Button'
import CurrencyInput from 'src/components/ui/CurrencyInput'
import TextField, { TextFieldPropCustom } from 'src/components/ui/TextField'
import UBox from 'src/components/ui/Ubox'

type Props = {
  // className?: string
  max?: number
  min?: number
  onValueChange: (value: number) => void
  // style?: CSSProperties
  value: number
}

const VALID_FIRST = /^[1-9]{1}$/
const VALID_NEXT = /^[0-9]{1}$/
const DELETE_KEY_CODE = 8

const inputQuantidade = ({
  // className = '',
  max = Number.MAX_SAFE_INTEGER,
  min = 0,
  onValueChange,
  // style = {},

  value,
  ...rest
}: Props) => {
  function removeCount() {
    if (value <= min) {
      return
    }
    onValueChange(value - 1)
  }
  function addCount() {
    if (value >= max) {
      return
    }
    onValueChange(value + 1)
  }
  return (
    <UBox>
      {/* <div>{JSON.stringify(rest)}</div> */}
      <UBox display="flex" alignItems="center">
        <IconButton
          disabled={value <= min}
          title="Remover item"
          onClick={() => {
            removeCount()
          }}
        >
          <FiMinusCircle />
        </IconButton>

        <UBox width="40px" textAlign="center">
          <b>{value}</b>
        </UBox>
        <IconButton
          title="Adicionar item"
          disabled={value >= max}
          onClick={() => addCount()}
        >
          <FiPlusCircle />
        </IconButton>
      </UBox>
    </UBox>
  )
}

export default inputQuantidade
