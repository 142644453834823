import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useEffect, useState } from 'react';
import yup from 'src/components/yupCustom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import TextField from 'src/components/ui/TextField';
import Drawer from 'src/components/ui/Drawer';
import UBox from 'src/components/ui/Ubox';
import Button from 'src/components/ui/Button';
import { cancelarAgendamento } from 'src/api/agendamento';
import { showNotificationErrorAPI } from 'src/core/helpers/notification';
import { ICancelamentoAgedamentoResponse, ItemListagemSolicitacaoOrcamentoResponse } from 'src/types';
import { toast } from 'react-toastify';

type Props = {
  open: boolean;
  handleFecharCancelamento: (cancelamento: ICancelamentoAgedamentoResponse) => void;
  handleOnClose: () => void
  solicitacaoItem?: ItemListagemSolicitacaoOrcamentoResponse;
};

export interface ICancelamentoAgendamentoForm {
  motivo: string;
}

const schema = yup.object().shape({
  motivo: yup.string().required().label('Motivo do agendamento')
})

function DrawerCancelamento({ open, handleOnClose, solicitacaoItem, handleFecharCancelamento }: Props) {
  const theme = useTheme();
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'));
  const [loading, setLoading] = useState(false)

  const { errors, watch, control, register, handleSubmit, setValue } = useForm<ICancelamentoAgendamentoForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      motivo: ''
    }
  })

  const command = watch()

  const onSubmit = async (data: ICancelamentoAgendamentoForm) => {
    setLoading(true)
    try {
      const payload = {
        id: solicitacaoItem.agendamento?.id,
        motivo: data.motivo
      }
      await cancelarAgendamento(payload)
      toast.success('Cancelamento realizado com sucesso!')
      handleFecharCancelamento(payload)
    } catch (error) {
      setLoading(false)
      showNotificationErrorAPI(error)
    }
  }

  useEffect(() => {
    register('motivo')
  }, [register])

  return (
    <Drawer anchor="right" open={open} titulo='Realizar Cancelamento' handleOnClose={handleOnClose}>
      <Box mt={3}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="subtitle1" style={{ marginBottom: '1em' }}>
            Insira o motivo para cancelar.
          </Typography>

          <UBox>
            <TextField
              erro={errors}
              control={control}
              name="motivo"
              multiline={true}
              rows={4}
              value={command.motivo}
              defaultValue={command.motivo}
              label="Motivo"
              inputProps={{ maxLength: 500 }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </UBox>
          <Box mt={3}>
            <Button
              fullWidth
              type="submit"
              loading={loading}
              disabled={loading}
              secondaryLoading
            >
              Cancelar
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
}

export default DrawerCancelamento;
