import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import UBox from 'src/components/ui/Ubox'
import { ItemOpmeInteligente } from '../FormPacoteInteligente'
import { yupResolver } from '@hookform/resolvers/yup'
import yup from 'src/components/yupCustom'
import TextField from 'src/components/ui/TextField'
import CurrencyInput from 'src/components/ui/CurrencyInput'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import { ObjetoDescricaoGenerico } from 'src/types'
import { useForm } from 'react-hook-form'
import Button from 'src/components/ui/Button'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { Alert } from '@material-ui/lab'
import { useDebouncedCallback } from 'use-debounce'

const useStyles = makeStyles(theme => ({
  formControl2: {
    top: '16px'
  }
}))

interface IProps {
  incluso?: boolean
  perfilMedico?: boolean
  opmes?: ItemOpmeInteligente[]
  fornecedores?: ObjetoDescricaoGenerico[]
  handleChange?: (item: ItemOpmeInteligente[], valido: boolean) => void
}
function TabelaOpme({
  opmes = [],
  fornecedores,
  handleChange,
  perfilMedico = false,
  incluso = false
}: IProps) {
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  const [loading, setLoading] = useState<boolean>(false)
  const [listaOpme, setListaOpme] = useState<ItemOpmeInteligente[]>(opmes)

  // const [listaOpme, setListaOpme] = useState<ItemOpmeInteligente[]>([])
  function addOpme() {
    var novo: ItemOpmeInteligente = {
      descricao: '',
      quantidadeIncluso: incluso ? 1 : 0,
      podeSubtrair: false,
      podeAcrescentar: false,
      quantidade: 0,
      fornecedor: null,
      hospitalFornece: false,
      valorUnitario: 0,
      valido: false
    }
    setListaOpme([...listaOpme, novo])
  }

  function removerOpme(key: number) {
    var novaLista = listaOpme.filter((o, i) => i !== key)

    atualizarPai(novaLista)
  }

  function atualizarPai(lista) {
    var formInvalido = lista.find(i => !i.valido)
    setListaOpme(lista)
    handleChange(lista, !!formInvalido)
  }

  function atualizarItem(
    key: number,
    value: ItemOpmeInteligente,
    valido: boolean
  ) {
    value['valido'] = valido
    listaOpme[key] = value

    // if (JSON.stringify(opmes) !== JSON.stringify(listaOpme)) {
    atualizarPai(listaOpme)
    // }
  }

  return (
    <UBox
      width="100%"
      // style={{ wordBreak: 'break-all' }}
    >
      <UBox render={opmes?.length > 0}>
        <Alert severity="info">
          Itens marcados como pode acrescentar / subtrair serão calculados com
          base no valor unitário informado.
        </Alert>
      </UBox>
      {/* <UBox>{JSON.stringify(listaOpme)}</UBox> */}
      {!loading &&
        listaOpme.map((opme, i) => (
          <UBox
            key={listaOpme.length + '-' + i + opme.valido ? 'v' : 'f'}
            // style={{
            //   background: !!opme.valido ? 'none' : '#fee',
            //   borderRadius: '4px'
            // }}
            px={ehXS ? 0 : 2}
          >
            <ItemOpme
              incluso={incluso}
              perfilMedico={perfilMedico}
              fornecedores={fornecedores}
              opme={opme}
              index={i}
              atualizar={atualizarItem}
              key={listaOpme.length + '-' + i + opme.valido ? 'verd' : 'fals'}
              remover={removerOpme}
            />
          </UBox>
        ))}
      <Button
        variant="text"
        color="primary"
        onClick={() => addOpme()}
        startIcon={<FiPlus style={{ color: '#4fd0b9' }} />}
      >
        Adicionar OPME
      </Button>
    </UBox>
  )
}

export default TabelaOpme

interface PropItemOpme {
  index: number
  opme: ItemOpmeInteligente
  incluso?: boolean
  fornecedores?: ObjetoDescricaoGenerico[]
  perfilMedico: boolean
  remover: (key: number) => void
  atualizar: (key: number, value: ItemOpmeInteligente, valido: boolean) => void
}

let validando = false

function ItemOpme({
  opme,
  index,
  atualizar,
  remover,
  fornecedores,
  perfilMedico,
  incluso
}: PropItemOpme) {
  const styles = useStyles()
  const [debouncedTermoPesquisa, setDebouncedTermoPesquisa] = useState(opme)

  const debounced = useDebouncedCallback(() => {
    setDebouncedTermoPesquisa(command)
    return validar()
  }, 0)

  let defaultValues: ItemOpmeInteligente = {
    descricao: '', //M- médico | H - hospital
    valorUnitario: 0,
    hospitalFornece: false,
    fornecedor: null
  }

  if (opme !== null) {
    defaultValues = opme
  }

  const schema = yup.object().shape({
    descricao: yup.string().required().label('Descrição'),
    valorUnitario: yup.string().required().label('Valor unitário'),
    quantidadeIncluso: yup.number().required().label('Quantidade incluso'),
    fornecedor: yup.object().when('descricao', {
      is: tipo => !incluso,
      then: yup.object().nullable().required().label('Fornecedor')
    }),
    hospitalFornece: yup.bool().label('Hospital fornece')
  })

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    formState,
    handleSubmit,
    trigger
  } = useForm<ItemOpmeInteligente>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  const command = watch()

  // REGISTRA VALORES REACT FORM HOOKS
  useEffect(() => {
    register('id')
    register('valorUnitario')
    register('fornecedor')

    register('quantidadeIncluso')
    register('quantidade')
    register('podeAcrescentar')
    register('podeSubtrair')
  }, [register])

  useEffect(() => {
    // if (!validando) {
    //   validar()
    // }
    // validando = true
    // setTimeout(() => {
    //   validando = false
    // }, 100)
    debounced.callback()
  }, [command])

  async function validar() {
    // var valido = false
    var valido = false
    if (incluso) {
      valido = await trigger(['descricao'])
    } else {
      valido = await trigger(['descricao', 'fornecedor'])
    }
    control.updateFormState()
    // console.log('opme', opme, command)
    // console.log('atualizou', valido, index, command, formState)

    // if (JSON.stringify(opme) !== JSON.stringify(command)) {
    atualizar(index, command, valido)
    // }
  }

  function handleChangeValor(key: keyof ItemOpmeInteligente, valor: number) {
    let valorFormatado = valor / 100
    if (!valorFormatado || valorFormatado < 0) {
      valor = 0
    }

    // return valorFormatado
    setValue(key, valor, { shouldValidate: true })
  }

  const handleItemValue = (name: keyof ItemOpmeInteligente, item) => {
    //console.log(name, item)
    setValue(name, item, {
      shouldValidate: true
    })
  }

  const verificarForm = () => {
    var vazio = {}
    var resultado = JSON.stringify(errors) === JSON.stringify(vazio)
    return resultado
  }

  return (
    <UBox
      key={index}
      style={{
        background: !!verificarForm() ? 'none' : '#fee',
        borderRadius: '4px'
      }}
    >
      {/* o:{JSON.stringify(opme)} */}
      {/* {index} */}
      {/* <br />
      c:{JSON.stringify(command)}
      <br />
      e:{JSON.stringify(errors)}
      <br />
      f:{JSON.stringify(verificarForm())} */}
      {/* <UBox style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
        c:{JSON.stringify(command)}
        <br />
        c:{JSON.stringify(errors)}
      </UBox> */}
      <Grid container spacing={1} direction="row">
        <Grid item xs={12} sm={perfilMedico || incluso ? 8 : 11}>
          <TextField
            erro={errors}
            inputRef={register}
            autoFocus
            size="small"
            name="descricao"
            label="Descrição"
            inputProps={{
              maxLength: 500
            }}
          />
        </Grid>
        <UBox component={Grid} item xs={12} sm={3} render={perfilMedico}>
          <FormControl
            className={styles.formControl2}
            fullWidth
            size="medium"
            variant="outlined"
          >
            <CurrencyInput
              fullWidth
              size="small"
              noMoney
              erro={errors}
              //   autoFocus
              variant="outlined"
              // margin="normal"
              name="quantidade"
              label="Qtd. incluso"
              hiddenLabel
              control={control}
              type="tel"
              InputLabelProps={{
                shrink: true
              }}
              // InputProps={{
              //   endAdornment: <>%</>
              // }}
              // max={100000}
              onValueChange={value => handleChangeValor('quantidade', value)}
              value={command?.quantidade || 0}
            />
            <FormHelperText error={!!errors?.quantidade}>
              {errors?.quantidade?.message}
            </FormHelperText>
          </FormControl>
        </UBox>
        <UBox component={Grid} item xs={12} sm={3} render={incluso}>
          <FormControl
            className={styles.formControl2}
            fullWidth
            size="medium"
            variant="outlined"
          >
            <CurrencyInput
              fullWidth
              size="small"
              noMoney
              erro={errors}
              //   autoFocus
              variant="outlined"
              // margin="normal"
              name="quantidade"
              label="Qtd. incluso"
              hiddenLabel
              control={control}
              type="tel"
              InputLabelProps={{
                shrink: true
              }}
              // InputProps={{
              //   endAdornment: <>%</>
              // }}
              // max={100000}
              onValueChange={value =>
                handleChangeValor('quantidadeIncluso', value)
              }
              value={command?.quantidadeIncluso || 0}
            />
            <FormHelperText error={!!errors?.quantidadeIncluso}>
              {errors?.quantidadeIncluso?.message}
            </FormHelperText>
          </FormControl>
        </UBox>
        <Grid
          item
          xs={12}
          sm={1}
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            padding: 0,
            paddingTop: 16
            // order: perfilMedico ? 4 : 0
          }}
        >
          <IconButton
            className="danger"
            title="Remover OPME"
            onClick={() => remover(index)}
          >
            <FiTrash />
          </IconButton>
        </Grid>

        <UBox
          component={Grid}
          item
          xs={12}
          sm={7}
          style={{ display: 'flex', alignItems: 'center' }}
          render={incluso}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  //   disabled={submitting || !!dadosBancarioId}
                  checked={command.podeAcrescentar}
                  onChange={(e, value) =>
                    handleItemValue('podeAcrescentar', value)
                  }
                  value={command.podeAcrescentar}
                />
              }
              label="pode add"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  //   disabled={submitting || !!dadosBancarioId}
                  checked={command.podeSubtrair}
                  onChange={(e, value) =>
                    handleItemValue('podeSubtrair', value)
                  }
                  value={command.podeSubtrair}
                />
              }
              label="pode subtrair"
            />
          </FormGroup>
        </UBox>

        <UBox
          component={Grid}
          item
          xs={12}
          sm={4}
          render={
            incluso && (command?.podeAcrescentar || command?.podeSubtrair)
          }
        >
          <FormControl
            // className={styles.formControl2}
            fullWidth
            size="medium"
            variant="outlined"
          >
            <CurrencyInput
              fullWidth
              size="small"
              erro={errors}
              //   autoFocus
              variant="outlined"
              margin="normal"
              name="valorUnitario"
              label="Valor un."
              hiddenLabel
              control={control}
              type="tel"
              InputLabelProps={{
                shrink: true
              }}
              // InputProps={{
              //   endAdornment: <>%</>
              // }}
              // max={100000}
              onValueChange={value => handleChangeValor('valorUnitario', value)}
              value={command?.valorUnitario || 0}
            />
            <FormHelperText error={!!errors?.valorUnitario}>
              {errors?.valorUnitario?.message}
            </FormHelperText>
          </FormControl>
        </UBox>

        <UBox component={Grid} item xs={12} sm={8} render={!incluso}>
          <Autocomplete
            erro={errors}
            name="hospital"
            size="small"
            label="Informe o fornecedor"
            errorMessage={(errors?.fornecedor as any)?.message}
            //   loading={loadingLocaisAtendimentos}
            //   options={locaisAtendimentos}
            loading={false}
            options={fornecedores}
            value={command.fornecedor}
            // showAddButton
            closeOnSelect
            // getOptionLabel={option => `${option}`}
            getOptionLabel={option => `${option.descricao}`}
            onClickAddButton={() => {}}
            onChange={(event, newValue: ObjetoDescricaoGenerico[]) => {
              console.log(newValue, 'fornec')
              handleItemValue(
                'fornecedor',
                newValue as ObjetoDescricaoGenerico[]
              )
            }}
          />
        </UBox>

        <UBox component={Grid} item xs={12} sm={4} render={!incluso}>
          <FormControl
            // className={styles.formControl2}
            fullWidth
            size="medium"
            variant="outlined"
          >
            <CurrencyInput
              fullWidth
              size="small"
              erro={errors}
              //   autoFocus
              variant="outlined"
              margin="normal"
              name="valorUnitario"
              label="Valor un."
              hiddenLabel
              control={control}
              type="tel"
              InputLabelProps={{
                shrink: true
              }}
              // InputProps={{
              //   endAdornment: <>%</>
              // }}
              // max={100000}
              onValueChange={value => handleChangeValor('valorUnitario', value)}
              value={command?.valorUnitario || 0}
            />
            <FormHelperText error={!!errors?.valorUnitario}>
              {errors?.valorUnitario?.message}
            </FormHelperText>
          </FormControl>
        </UBox>
      </Grid>
      <UBox my={1}>
        <Divider />
      </UBox>
    </UBox>
  )
}
