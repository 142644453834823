import React, { useEffect, useState } from 'react'
import Drawer from 'src/components/ui/Drawer'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { obterPacotePorId } from 'src/api/pacote'
import ResumoPacote from '../../medico/pacote/resumo'

type Props = {
  id: string
  open: boolean
  handleOnClose: () => void
}

function DrawerVisualizarDetalhes({ id, open, handleOnClose }: Props) {
  const [detalheVisualizacao, setDetalheVisualizacao] = useState(null)
  const [loadingDetalhe, setLoadingDetalhe] = useState(false)

  useEffect(() => {
    if (!id) {
      return
    }
    setDetalheVisualizacao(null)
    setLoadingDetalhe(true)
    obterPacotePorId(id)
      .then(response => {
        setDetalheVisualizacao(response.data)
        setLoadingDetalhe(false)
      })
      .catch(e => {
        showNotificationErrorAPI(e)
        handleOnClose()
      })
  }, [open])

  return (
    <Drawer
      loading={loadingDetalhe}
      anchor="right"
      open={open}
      titulo="Visualizar pacote"
      handleOnClose={handleOnClose}
    >
      {!!detalheVisualizacao && <ResumoPacote pacote={detalheVisualizacao} />}
    </Drawer>
  )
}

export default DrawerVisualizarDetalhes
