import {
  Box,
  TextField,
  Checkbox,
  Typography,
  Menu,
  MenuItem,
  IconButton
} from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import Button from 'src/components/ui/Button'
import Drawer from 'src/components/ui/Drawer'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import {
  obterStatusSolicitacaoOrcamento,
  alterarStatusSolicitacaoOrcamento,
  cadastrarResponsavelSolicitacaoOrcamento
} from 'src/api/solicitacao-orcamento'
import {
  AlterarStatusSolicitacaoOcamentoCommand,
  ObjetoDescricaoGenerico,
  OperacionalResponse
} from 'src/types'
import Swal from 'sweetalert2'
import { statusOrcamento } from 'src/core/consts/statusSolicitacao'

type Props = {
  id: string
  lstOperacional: OperacionalResponse[]
  open: boolean
  handleOnClose: (boolean?) => void
}

function DrawerCadastrarDono(props: Props) {
  const [usuarioSelecionado, setUsuarioSelecionado] = useState(undefined)
  const [loadingSalvar, setLoadingSalvar] = useState(false)

  useEffect(() => {
    if (!props.id) {
      return
    }
  }, [props.open])

  function handleOnChange(event, newValue) {
    setUsuarioSelecionado(newValue)
  }

  async function handleSalvar() {
    try {
      setLoadingSalvar(true)
      await cadastrarResponsavelSolicitacaoOrcamento(
        usuarioSelecionado.id,
        props.id
      )
      setLoadingSalvar(false)
      setUsuarioSelecionado(undefined)
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Responsavel cadastrado com sucesso',
        confirmButtonText: 'Ok'
      })
      props.handleOnClose(true)
    } catch (e) {
      setLoadingSalvar(false)
      showNotificationErrorAPI(e)
      props.handleOnClose()
    }
  }

  return (
    <Drawer
      anchor="right"
      open={props.open}
      titulo="Cadastrar Responsável da solicitaçao"
      handleOnClose={() => props.handleOnClose()}
    >
      <Autocomplete
        erro={{}}
        name="pacote"
        label="Responsável"
        value={usuarioSelecionado}
        options={props.lstOperacional}
        getOptionLabel={option => `${option.descricao}`}
        onChange={handleOnChange}
      />
      <Box mt={3}>
        <Button
          disabled={!usuarioSelecionado || loadingSalvar}
          fullWidth
          onClick={handleSalvar}
          loading={loadingSalvar}
          secondaryLoading
        >
          Salvar
        </Button>
      </Box>
    </Drawer>
  )
}

export default DrawerCadastrarDono
