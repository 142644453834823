export const MEIO_PAGAMENTO = {
  MAQUININHA: { id: 1, value: 'maquininha', name: 'Maquininha Stone' },
  LINK_PAGAMENTO: { id: 2, value: 'linkPagamento', name: 'Link de pagamento Pagarme' },
  CONTA_BANCARIA: { id: 3, value: 'contaBancariaSyn', name: 'Conta Bancária SYN' }
}

export default [
  { id: 1, value: 'maquininha', name: 'Maquininha Stone' },
  { id: 2, value: 'linkPagamento', name: 'Link de pagamento Pagarme' },
  { id: 3, value: 'contaBancariaSyn', name: 'Conta Bancária SYN' }
]
