import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

import React from 'react'
import { GoPackage, GoProject } from 'react-icons/go'
import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import { RiTeamFill } from 'react-icons/ri'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import AdminPanelSettings from '@material-ui/icons/PersonRounded'
import ImageSharpIcon from '@material-ui/icons/ImageSharp'
import TelegramIcon from '@material-ui/icons/Telegram'
import { useHistory } from 'react-router-dom'
import { SynRoutes } from 'src/core/consts/synRoutes'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import AddToQueueIcon from '@material-ui/icons/AddToQueue'
import { ChromeReaderMode, SdCardOutlined } from '@material-ui/icons'
import useAuth from 'src/hooks/useAuth'

function menuLateral(props) {
  const { ehTenantSyn, obterTenantAtual } = useAuth()
  const history = useHistory()

  return (
    <>
      <List>
        <ListItem button onClick={() => history.push(SynRoutes.listagemEquipe)}>
          <ListItemIcon style={{ fontSize: '1.4rem' }}>
            <RiTeamFill />
          </ListItemIcon>
          <ListItemText primary="Minha equipe" />
        </ListItem>
        {ehTenantSyn && (
          <ListItem
            button
            onClick={() => history.push(SynRoutes.listagemPacotes)}
          >
            <ListItemIcon style={{ fontSize: '1.4rem' }}>
              <GoPackage />
            </ListItemIcon>
            <ListItemText primary="Meus pacotes" />
          </ListItem>
        )}
        {ehTenantSyn && (
          <ListItem
            button
            onClick={() => history.push(SynRoutes.listagemPacotesSugeridos)}
          >
            <ListItemIcon style={{ fontSize: '1.4rem' }}>
              {/* <GoPackage /> */}
              <AiOutlineAppstoreAdd />
            </ListItemIcon>
            <ListItemText primary="Pacotes sugeridos" />
          </ListItem>
        )}
        <ListItem
          button
          onClick={() => history.push(SynRoutes.listagemOrcamentos)}
        >
          <ListItemIcon style={{ fontSize: '1.4rem' }}>
            <AssignmentOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Solicitações" />
        </ListItem>
        {ehTenantSyn && (
          <ListItem
            button
            onClick={() => history.push(SynRoutes.listagemFinanciamentos)}
          >
            <ListItemIcon>
              <ChromeReaderMode />
            </ListItemIcon>
            <ListItemText primary="Financiamentos" />
          </ListItem>
        )}
        <ListItem
          button
          onClick={() => history.push(SynRoutes.assistentesMedico)}
        >
          <ListItemIcon style={{ fontSize: '1.4rem' }}>
            <AssignmentIndIcon />
          </ListItemIcon>
          <ListItemText primary="Assistentes" />
        </ListItem>
        {ehTenantSyn && (
          <ListItem
            button
            onClick={() => history.push(SynRoutes.convidarColega)}
          >
            <ListItemIcon style={{ fontSize: '1.4rem' }}>
              <RecordVoiceOverIcon />
            </ListItemIcon>
            <ListItemText primary="Convidar colega" />
          </ListItem>
        )}
        {ehTenantSyn && (
          <ListItem
            button
            onClick={() => history.push(SynRoutes.logomarcaMedico)}
          >
            <ListItemIcon style={{ fontSize: '1.4rem' }}>
              <ImageSharpIcon />
            </ListItemIcon>
            <ListItemText primary="Logomarca" />
          </ListItem>
        )}
        {/* <ListItem button onClick={() => history.push(SynRoutes.formMedico)}>
          <ListItemIcon>
            <AdminPanelSettings />
          </ListItemIcon>
          <ListItemText primary="Editar dados" />
        </ListItem> */}
        <ListItem button onClick={() => history.push(SynRoutes.termos)}>
          <ListItemIcon>
            <SdCardOutlined />
          </ListItemIcon>
          <ListItemText primary="Termos" />
        </ListItem>

        {/* <ListItem button onClick={() => history.push(SynRoutes.autorizarHospitais)}>
          <ListItemIcon style={{ fontSize: '1.4rem' }}>
            <AddToQueueIcon />
          </ListItemIcon>
          <ListItemText primary="Autorizar hospitais" />
        </ListItem> */}
        {/* <ListItem
          button
          onClick={() => history.push(SynRoutes.historicoAtualizacoes)}
        >
          <ListItemIcon style={{ fontSize: '1.4rem' }}>
            <DynamicFeedIcon />
          </ListItemIcon>
          <ListItemText primary="Atualizações" />
        </ListItem> */}
      </List>
    </>
  )
}

export default menuLateral
