export const NACIONALIDADE = ['BRASILEIRO', 'ESTRANGEIRO', 'NATURALIZADO']

export const SITUACAO_IMOVEL = [
  {
    codigo: 3,
    descricao: 'ALUGADO',
    ativo: true
  },
  {
    codigo: 8,
    descricao: 'CEDIDO',
    ativo: true
  },
  {
    codigo: 2,
    descricao: 'FAMILIAR',
    ativo: true
  },
  {
    codigo: 1,
    descricao: 'PROPRIO',
    ativo: true
  },
  {
    codigo: 5,
    descricao: 'PROPRIO FINANCIADO',
    ativo: true
  }
]

export const ESTADO_CIVIL = [
  {
    codigo: null,
    descricao: 'INFORME ESTADO CIVIL',
    ativo: true
  },
  {
    codigo: 5,
    descricao: 'CASADO',
    ativo: true
  },
  {
    codigo: 2,
    descricao: 'DIVORCIADO',
    ativo: true
  },
  {
    codigo: 0,
    descricao: 'NAO INFORMADO',
    ativo: true
  },
  {
    codigo: 3,
    descricao: 'SEPARADO',
    ativo: true
  },
  {
    codigo: 4,
    descricao: 'SOLTEIRO',
    ativo: true
  },
  {
    codigo: 6,
    descricao: 'VIUVO',
    ativo: true
  }
]

export const TIPOS_PROFISSOES = [
  {
    codigo: 7,
    descricao: 'APOSENTADO OU PENSIONISTA',
    obrigaTipoFontePagadora: false,
    ativo: true
  },
  {
    codigo: 1,
    descricao: 'ASSALARIADO',
    obrigaTipoFontePagadora: false,
    ativo: true
  },
  {
    codigo: 3,
    descricao: 'AUTONOMO',
    obrigaTipoFontePagadora: false,
    ativo: true
  },
  // {
  //   codigo: 2,
  //   descricao: 'EMPRESARIO',
  //   obrigaTipoFontePagadora: false,
  //   ativo: true
  // },
  {
    codigo: 6,
    descricao: 'FUNCIONARIO PUBLICO',
    obrigaTipoFontePagadora: false,
    ativo: true
  },
  // {
  //   codigo: 8,
  //   descricao: 'INVESTIDOR',
  //   obrigaTipoFontePagadora: false,
  //   ativo: true
  // },
  {
    codigo: 4,
    descricao: 'LIBERAL',
    obrigaTipoFontePagadora: false,
    ativo: true
  }
]

export const PROFISSOES = [
  {
    codigo: 525,
    descricao: 'ACOUGUEIRO',
    ativo: true
  },
  {
    codigo: 1017,
    descricao: 'ACUPUNTURISTA',
    ativo: true
  },
  {
    codigo: 125,
    descricao: 'ADMINISTRADOR',
    ativo: true
  },
  {
    codigo: 1069,
    descricao: 'ADMINISTRADOR DE COMARCA',
    ativo: true
  },
  {
    codigo: 1111,
    descricao: 'ADMINISTRADOR DE TRIBUNAL',
    ativo: true
  },
  {
    codigo: 131,
    descricao: 'ADVOGADO',
    ativo: true
  },
  {
    codigo: 1018,
    descricao: 'AERONAUTA',
    ativo: true
  },
  {
    codigo: 189,
    descricao: 'AEROVIARIO',
    ativo: true
  },
  {
    codigo: 405,
    descricao: 'AGENCIADOR DE PROPAGANDA',
    ativo: true
  },
  {
    codigo: 1004,
    descricao: 'AGENTE ADMINISTRATIVO',
    ativo: true
  },
  {
    codigo: 1100,
    descricao: 'AGENTE COMUNITARIO DE SAUDE',
    ativo: true
  },
  {
    codigo: 1009,
    descricao: 'AGENTE DE SERVICOS FUNERARIOS E EMBALSAMADOR',
    ativo: true
  },
  {
    codigo: 594,
    descricao: 'AGENTE DE VIAGEM E GUIA DE TURISMO',
    ativo: true
  },
  {
    codigo: 601,
    descricao: 'AGRICULTOR',
    ativo: true
  },
  {
    codigo: 1062,
    descricao: 'AGRIMENSOR',
    ativo: true
  },
  {
    codigo: 103,
    descricao: 'AGRONOMO',
    ativo: true
  },
  {
    codigo: 1068,
    descricao: 'AGROPECUARISTA',
    ativo: true
  },
  {
    codigo: 591,
    descricao: 'ALFAIATE',
    ativo: true
  },
  {
    codigo: 410,
    descricao: 'AMBULANTE',
    ativo: true
  },
  {
    codigo: 381,
    descricao: 'ANALISTA',
    ativo: true
  },
  {
    codigo: 126,
    descricao: 'ANALISTA DE SISTEMA',
    ativo: true
  },
  {
    codigo: 1050,
    descricao: 'ANTIQUARIO',
    ativo: true
  },
  {
    codigo: 1019,
    descricao: 'ANTROPOLOGO',
    ativo: true
  },
  {
    codigo: 1051,
    descricao: 'APICULTOR',
    ativo: true
  },
  {
    codigo: 923,
    descricao: 'APOSENTADO / PENSIONISTA',
    ativo: true
  },
  {
    codigo: 1108,
    descricao: 'APRENDIZ BANCO DO BRASIL',
    ativo: true
  },
  {
    codigo: 1079,
    descricao: 'AQUICULTOR',
    ativo: true
  },
  {
    codigo: 1020,
    descricao: 'ARMEIRO',
    ativo: true
  },
  {
    codigo: 1047,
    descricao: 'ARQUEOLOGO E MUSEOLOGO',
    ativo: true
  },
  {
    codigo: 102,
    descricao: 'ARQUITETO OU PAISAGISTA',
    ativo: true
  },
  {
    codigo: 199,
    descricao: 'ARTESAO',
    ativo: true
  },
  {
    codigo: 1021,
    descricao: 'ARTISTA PLASTICO',
    ativo: true
  },
  {
    codigo: 134,
    descricao: 'ASSISTENTE SOCIAL',
    ativo: true
  },
  {
    codigo: 1022,
    descricao: 'ASTROLOGO, NUMEROLOGO E AFINS',
    ativo: true
  },
  {
    codigo: 128,
    descricao: 'ASTRONOMO',
    ativo: true
  },
  {
    codigo: 168,
    descricao: 'ATLETA PROFISSIONAL E TECNICO EM DESPORTOS',
    ativo: true
  },
  {
    codigo: 162,
    descricao: 'ATOR E DIRETOR DE ESPETACULOS PUBLICOS',
    ativo: true
  },
  {
    codigo: 802,
    descricao: 'AUXILIAR DE ENFERMAGEM',
    ativo: true
  },
  {
    codigo: 801,
    descricao: 'AUXILIAR DE ESCRITORIO E ASSEMELHADOS',
    ativo: true
  },
  {
    codigo: 1010,
    descricao: 'AUXILIAR DE LABORATORIO E RAIOS X',
    ativo: true
  },
  {
    codigo: 145,
    descricao: 'AUXILIAR DE NUTRICAO',
    ativo: true
  },
  {
    codigo: 805,
    descricao: 'AUXILIAR DE SERVICOS GERAIS',
    ativo: true
  },
  {
    codigo: 1052,
    descricao: 'AVICULTOR',
    ativo: true
  },
  {
    codigo: 395,
    descricao: 'BANCARIO E ECONOMIARIO',
    ativo: true
  },
  {
    codigo: 511,
    descricao: 'BARBEIRO, CABELEIREIRO, MANICURE E PEDICURE',
    ativo: true
  },
  {
    codigo: 135,
    descricao: 'BIBLIOTECARIO OU ARQUIVISTA',
    ativo: true
  },
  {
    codigo: 1023,
    descricao: 'BIOFISICO',
    ativo: true
  },
  {
    codigo: 116,
    descricao: 'BIOLOGO OU BIOMEDICO',
    ativo: true
  },
  {
    codigo: 1024,
    descricao: 'BIOQUIMICO',
    ativo: true
  },
  {
    codigo: 1101,
    descricao: 'BOLSISTA MESTRADO/DOUTORADO',
    ativo: true
  },
  {
    codigo: 1104,
    descricao: 'BOLSISTA OUTROS',
    ativo: true
  },
  {
    codigo: 1008,
    descricao: 'BOMBEIRO E INSTALADOR DE GAS, AGUA, ESGOTO E ASSEMELHADOS',
    ativo: true
  },
  {
    codigo: 549,
    descricao: 'BORRACHEIRO',
    ativo: true
  },
  {
    codigo: 163,
    descricao: 'CANTOR E COMPOSITOR',
    ativo: true
  },
  {
    codigo: 1115,
    descricao: 'CAPITALISTA',
    ativo: true
  },
  {
    codigo: 907,
    descricao: 'CAPITALISTA/RENTISTA/LOCADOR',
    ativo: true
  },
  {
    codigo: 1025,
    descricao: 'CARTEIRO',
    ativo: true
  },
  {
    codigo: 1032,
    descricao: 'CENOGRAFO',
    ativo: true
  },
  {
    codigo: 1054,
    descricao: 'CERAMISTA',
    ativo: true
  },
  {
    codigo: 1026,
    descricao: 'CHAVEIRO',
    ativo: true
  },
  {
    codigo: 537,
    descricao: 'COBRADOR DE TRANSPORTE COLETIVO',
    ativo: true
  },
  {
    codigo: 419,
    descricao: 'COMERCIARIO',
    ativo: true
  },
  {
    codigo: 136,
    descricao: 'COMUNICOLOGO',
    ativo: true
  },
  {
    codigo: 1118,
    descricao: 'CONSELHEIRO TUTELAR',
    ativo: true
  },
  {
    codigo: 1097,
    descricao: 'CONSULTOR LEGISLATIVO',
    ativo: true
  },
  {
    codigo: 124,
    descricao: 'CONTADOR',
    ativo: true
  },
  {
    codigo: 165,
    descricao: 'COREOGRAFO E BAILARINO',
    ativo: true
  },
  {
    codigo: 1098,
    descricao: 'CORREGEDOR',
    ativo: true
  },
  {
    codigo: 403,
    descricao: 'CORRETOR',
    ativo: true
  },
  {
    codigo: 740,
    descricao: 'COSTUREIRO',
    ativo: true
  },
  {
    codigo: 529,
    descricao: 'COZINHEIRO, COPEIRO, GARCOM, BARMEN E CAMAREIRO',
    ativo: true
  },
  {
    codigo: 193,
    descricao: 'DECORADOR',
    ativo: true
  },
  {
    codigo: 1107,
    descricao: 'DEFENSOR PUBLICO',
    ativo: true
  },
  {
    codigo: 1003,
    descricao: 'DELEGADO DE POLICIA',
    ativo: true
  },
  {
    codigo: 1088,
    descricao: 'DEPUTADO ESTADUAL/DISTRITAL',
    ativo: true
  },
  {
    codigo: 1087,
    descricao: 'DEPUTADO FEDERAL',
    ativo: true
  },
  {
    codigo: 205,
    descricao: 'DESEMBARGADOR',
    ativo: true
  },
  {
    codigo: 1120,
    descricao: 'DESENHISTA',
    ativo: true
  },
  {
    codigo: 192,
    descricao: 'DESENHISTA COMERCIAL',
    ativo: true
  },
  {
    codigo: 105,
    descricao: 'DESENHISTA INDUSTRIAL',
    ativo: true
  },
  {
    codigo: 158,
    descricao: 'DESENHISTA TECNICO',
    ativo: true
  },
  {
    codigo: 593,
    descricao: 'DESPACHANTE, INCLUSIVE O ADUANEIRO',
    ativo: true
  },
  {
    codigo: 1081,
    descricao: 'DETETIVE PARTICULAR',
    ativo: true
  },
  {
    codigo: 212,
    descricao: 'DIPLOMATA',
    ativo: true
  },
  {
    codigo: 1110,
    descricao: 'DISCOTECARIO, VIDEOTECARIO E AFINS',
    ativo: true
  },
  {
    codigo: 932,
    descricao: 'DO LAR',
    ativo: true
  },
  {
    codigo: 1027,
    descricao: 'ECOLOGISTA',
    ativo: true
  },
  {
    codigo: 121,
    descricao: 'ECONOMISTA',
    ativo: true
  },
  {
    codigo: 542,
    descricao: 'ELETRICISTA E ASSEMELHADOS',
    ativo: true
  },
  {
    codigo: 1028,
    descricao: 'ELETRICITARIO',
    ativo: true
  },
  {
    codigo: 999,
    descricao: 'em branco',
    ativo: true
  },
  {
    codigo: 811,
    descricao: 'EMPREGADO DOMESTICO',
    ativo: true
  },
  {
    codigo: 906,
    descricao: 'EMPRESARIO',
    ativo: true
  },
  {
    codigo: 161,
    descricao: 'EMPRESARIO E PRODUTOR DE ESPETACULOS PUBLICOS',
    ativo: true
  },
  {
    codigo: 113,
    descricao: 'ENFERMEIRO',
    ativo: true
  },
  {
    codigo: 101,
    descricao: 'ENGENHEIRO',
    ativo: true
  },
  {
    codigo: 397,
    descricao: 'ESCREVENTE/AUXILIAR DE CARTORIO',
    ativo: true
  },
  {
    codigo: 1029,
    descricao: 'ESCRITOR',
    ativo: true
  },
  {
    codigo: 191,
    descricao: 'ESCULTOR/PINTOR OU ASSEMELHADO',
    ativo: true
  },
  {
    codigo: 1117,
    descricao: 'ESPOLIO',
    ativo: true
  },
  {
    codigo: 931,
    descricao: 'ESTAGIARIO E ASSEMELHADOS',
    ativo: true
  },
  {
    codigo: 122,
    descricao: 'ESTATISTICO',
    ativo: true
  },
  {
    codigo: 1080,
    descricao: 'ESTETICISTA',
    ativo: true
  },
  {
    codigo: 1011,
    descricao: 'ESTIVADOR, CARREGADOR, EMBALADOR E ASSEMELHADOS',
    ativo: true
  },
  {
    codigo: 1002,
    descricao: 'ESTUDANTE',
    ativo: true
  },
  {
    codigo: 117,
    descricao: 'FARMACEUTICO',
    ativo: true
  },
  {
    codigo: 1072,
    descricao: 'FAXINEIRO',
    ativo: true
  },
  {
    codigo: 413,
    descricao: 'FEIRANTE',
    ativo: true
  },
  {
    codigo: 755,
    descricao: 'FERRAMENTEIRO',
    ativo: true
  },
  {
    codigo: 1074,
    descricao: 'FERROVIARIO',
    ativo: true
  },
  {
    codigo: 1030,
    descricao: 'FILOLOGO',
    ativo: true
  },
  {
    codigo: 1031,
    descricao: 'FILOSOFO',
    ativo: true
  },
  {
    codigo: 539,
    descricao: 'FISCAL, AUDITOR E ASSEMELHADOS',
    ativo: true
  },
  {
    codigo: 106,
    descricao: 'FISICO',
    ativo: true
  },
  {
    codigo: 114,
    descricao: 'FISIOTERAPEUTA OU TERAPEUTA OCUPACIONAL',
    ativo: true
  },
  {
    codigo: 1033,
    descricao: 'FLORICULTOR',
    ativo: true
  },
  {
    codigo: 118,
    descricao: 'FONOAUDIOLOGO',
    ativo: true
  },
  {
    codigo: 1000,
    descricao: 'FOTOGRAFO',
    ativo: true
  },
  {
    codigo: 806,
    descricao: 'FRENTISTA',
    ativo: true
  },
  {
    codigo: 543,
    descricao: 'FUNILEIRO',
    ativo: true
  },
  {
    codigo: 742,
    descricao: 'GARI OU VARREDOR DE LOGRADOUROS PUBLICOS',
    ativo: true
  },
  {
    codigo: 605,
    descricao: 'GARIMPEIRO',
    ativo: true
  },
  {
    codigo: 127,
    descricao: 'GEOGRAFO',
    ativo: true
  },
  {
    codigo: 107,
    descricao: 'GEOLOGO',
    ativo: true
  },
  {
    codigo: 1086,
    descricao: 'GOVERNADOR DE ESTADO/DISTRITO FEDERAL',
    ativo: true
  },
  {
    codigo: 1055,
    descricao: 'HISTORIADOR',
    ativo: true
  },
  {
    codigo: 903,
    descricao: 'INDUSTRIARIO',
    ativo: true
  },
  {
    codigo: 1071,
    descricao: 'INSTRUTOR',
    ativo: true
  },
  {
    codigo: 810,
    descricao: 'JARDINEIRO',
    ativo: true
  },
  {
    codigo: 717,
    descricao: 'JOALHEIRO E OURIVES',
    ativo: true
  },
  {
    codigo: 412,
    descricao: 'JORNALEIRO',
    ativo: true
  },
  {
    codigo: 171,
    descricao: 'JORNALISTA',
    ativo: true
  },
  {
    codigo: 1007,
    descricao: 'LANTERNEIRO E PINTOR DE VEICULOS',
    ativo: true
  },
  {
    codigo: 404,
    descricao: 'LEILOEIRO, AVALIADOR E ASSEMELHADOS',
    ativo: true
  },
  {
    codigo: 166,
    descricao: 'LOCUTOR E COMENTARISTA DE RADIO E TELEVISAO E RADIALISTA',
    ativo: true
  },
  {
    codigo: 1070,
    descricao: 'MAGISTRADO',
    ativo: true
  },
  {
    codigo: 535,
    descricao: 'MAQUINISTA E FOGUISTA DE EMBARCACOES,LOCOMOTIVAS E ASSEMELHA',
    ativo: true
  },
  {
    codigo: 774,
    descricao: 'MARCENEIRO E TRAB.ARTEFATOS MADEIRA',
    ativo: true
  },
  {
    codigo: 534,
    descricao: 'MARINHEIRO',
    ativo: true
  },
  {
    codigo: 899,
    descricao: 'MASSAGISTA',
    ativo: true
  },
  {
    codigo: 123,
    descricao: 'MATEMATICO E ATUARIO',
    ativo: true
  },
  {
    codigo: 762,
    descricao: 'MECANICO DE MANUTENCAO DE MAQUINAS INDUSTRIAIS',
    ativo: true
  },
  {
    codigo: 541,
    descricao: 'MECANICO DE MANUTENCAO DE VEICULOS AUTOMOTORES',
    ativo: true
  },
  {
    codigo: 111,
    descricao: 'MEDICO',
    ativo: true
  },
  {
    codigo: 1102,
    descricao: 'MENOR SEM ATIVIDADE',
    ativo: true
  },
  {
    codigo: 1099,
    descricao: 'MERGULHADOR',
    ativo: true
  },
  {
    codigo: 701,
    descricao: 'MESTRE E CONTRAMESTRE',
    ativo: true
  },
  {
    codigo: 719,
    descricao: 'METALURGICO E SIDERURGICO',
    ativo: true
  },
  {
    codigo: 1048,
    descricao: 'METEOROLOGISTA',
    ativo: true
  },
  {
    codigo: 1114,
    descricao: 'MILITAR DAS FORCAS ARMADAS - AERONAUTICA',
    ativo: true
  },
  {
    codigo: 1112,
    descricao: 'MILITAR DAS FORCAS ARMADAS - EXERCITO',
    ativo: true
  },
  {
    codigo: 1113,
    descricao: 'MILITAR DAS FORCAS ARMADAS - MARINHA',
    ativo: true
  },
  {
    codigo: 294,
    descricao: 'MILITAR EM GERAL',
    ativo: true
  },
  {
    codigo: 202,
    descricao: 'MINISTRO DE ESTADO',
    ativo: true
  },
  {
    codigo: 1001,
    descricao: 'MISSIONARIO',
    ativo: true
  },
  {
    codigo: 1083,
    descricao: 'MODELISTA E/OU CORTADOR - VESTUARIO',
    ativo: true
  },
  {
    codigo: 492,
    descricao: 'MODELO DE MODAS',
    ativo: true
  },
  {
    codigo: 1035,
    descricao: 'MODISTA',
    ativo: true
  },
  {
    codigo: 809,
    descricao: 'MOTOCICLISTA (TRANSP. DE MERCADORIAS) E/OU ASSEMELHADOS',
    ativo: true
  },
  {
    codigo: 1121,
    descricao: 'MOTORISTA',
    ativo: true
  },
  {
    codigo: 532,
    descricao: 'MOTORISTA DE VEICULOS DE TRANSPORTE DE CARGA',
    ativo: true
  },
  {
    codigo: 531,
    descricao: 'MOTORISTA DE VEICULOS DE TRANSPORTE DE PASSAGEIROS',
    ativo: true
  },
  {
    codigo: 769,
    descricao: 'MOTORISTA/OPERADOR DE MAQUINAS PESADAS',
    ativo: true
  },
  {
    codigo: 164,
    descricao: 'MUSICO',
    ativo: true
  },
  {
    codigo: 1049,
    descricao: 'NUTRICIONISTA',
    ativo: true
  },
  {
    codigo: 1036,
    descricao: 'OCEANOGRAFO',
    ativo: true
  },
  {
    codigo: 115,
    descricao: 'ODONTOLOGO',
    ativo: true
  },
  {
    codigo: 216,
    descricao: 'OFICIAL DAS FORCAS ARMADAS E FORCAS AUXILIARES',
    ativo: true
  },
  {
    codigo: 1095,
    descricao: 'OFICIAL DE JUSTICA',
    ativo: true
  },
  {
    codigo: 1037,
    descricao: 'OLEIRO',
    ativo: true
  },
  {
    codigo: 386,
    descricao: 'OPERADOR DE COMPUTADORES',
    ativo: true
  },
  {
    codigo: 1078,
    descricao: 'OPERADOR DE MAQUINAS AGROPECUARIAS',
    ativo: true
  },
  {
    codigo: 1109,
    descricao: 'OPERADOR DE TELEMARKETING',
    ativo: true
  },
  {
    codigo: 1056,
    descricao: 'OPTICO',
    ativo: true
  },
  {
    codigo: 1057,
    descricao: 'ORNITOLOGO',
    ativo: true
  },
  {
    codigo: 1038,
    descricao: 'PADEIRO E CONFEITEIRO',
    ativo: true
  },
  {
    codigo: 925,
    descricao: 'PASTOR',
    ativo: true
  },
  {
    codigo: 602,
    descricao: 'PECUARISTA',
    ativo: true
  },
  {
    codigo: 144,
    descricao: 'PEDAGOGO',
    ativo: true
  },
  {
    codigo: 604,
    descricao: 'PESCADOR',
    ativo: true
  },
  {
    codigo: 1067,
    descricao: 'PESCADOR COM FINS COMERCIAIS',
    ativo: true
  },
  {
    codigo: 1063,
    descricao: 'PESQ/PRODUC DE MUDAS OU SEMENT FISCALIZADAS/CERTIFICADAS',
    ativo: true
  },
  {
    codigo: 1064,
    descricao: 'PESQ/PRODUC DE SEMEM PARA INSEMINACAO ARTIFICIAL',
    ativo: true
  },
  {
    codigo: 181,
    descricao: 'PILOTO DE AERONAVES',
    ativo: true
  },
  {
    codigo: 1039,
    descricao: 'POLICIAL CIVIL',
    ativo: true
  },
  {
    codigo: 501,
    descricao: 'PORTEIRO DE EDIFICIO, ASCENSORISTA E GARAGISTA',
    ativo: true
  },
  {
    codigo: 1073,
    descricao: 'PORTUARIO',
    ativo: true
  },
  {
    codigo: 1090,
    descricao: 'PREFEITO MUNICIPAL',
    ativo: true
  },
  {
    codigo: 1084,
    descricao: 'PRESIDENTE DA REPUBLICA',
    ativo: true
  },
  {
    codigo: 1066,
    descricao: 'PREST SERV DE INSEMINACAO ARTIFICIAL, EM IMOVEL RURAL',
    ativo: true
  },
  {
    codigo: 1065,
    descricao: 'PREST SERV MECANIZAD,NATURAL,RURAL INCLUSIVE PROTECAO SOLO',
    ativo: true
  },
  {
    codigo: 211,
    descricao: 'PROCURADOR DA JUSTICA E ASSEMELHADOS',
    ativo: true
  },
  {
    codigo: 143,
    descricao: 'PROFESSOR DE ENSINO FUNDAMENTAL E MEDIO',
    ativo: true
  },
  {
    codigo: 142,
    descricao: 'PROFESSOR DE ENSINO SUPERIOR',
    ativo: true
  },
  {
    codigo: 1119,
    descricao: 'PROFESSOR/DEMAIS',
    ativo: true
  },
  {
    codigo: 138,
    descricao: 'PROFISSIONAL DE LETRAS E ARTES',
    ativo: true
  },
  {
    codigo: 388,
    descricao: 'PROGRAMADOR DE SISTEMA',
    ativo: true
  },
  {
    codigo: 1089,
    descricao: 'PROMOTOR DE JUSTICA',
    ativo: true
  },
  {
    codigo: 499,
    descricao: 'PROMOTOR DE VENDAS E DEMONSTRADOR',
    ativo: true
  },
  {
    codigo: 592,
    descricao: 'PROTETICO',
    ativo: true
  },
  {
    codigo: 132,
    descricao: 'PSICOLOGO E PSICANALISTA',
    ativo: true
  },
  {
    codigo: 172,
    descricao: 'PUBLICITARIO',
    ativo: true
  },
  {
    codigo: 104,
    descricao: 'QUIMICO',
    ativo: true
  },
  {
    codigo: 1040,
    descricao: 'RADIOTECNICO',
    ativo: true
  },
  {
    codigo: 1053,
    descricao: 'REDATOR',
    ativo: true
  },
  {
    codigo: 137,
    descricao: 'RELACOES PUBLICAS',
    ativo: true
  },
  {
    codigo: 1041,
    descricao: 'RELOJOEIRO',
    ativo: true
  },
  {
    codigo: 1116,
    descricao: 'RENTISTA OU LOCADOR',
    ativo: true
  },
  {
    codigo: 402,
    descricao: 'REPRESENTANTE COMERCIAL',
    ativo: true
  },
  {
    codigo: 1058,
    descricao: 'RODOVIARIO',
    ativo: true
  },
  {
    codigo: 1042,
    descricao: 'SACERDOTE',
    ativo: true
  },
  {
    codigo: 1077,
    descricao: 'SALVA-VIDAS OU BOMBEIRO PARTICULAR/AUTONOMO',
    ativo: true
  },
  {
    codigo: 596,
    descricao: 'SAPATEIRO E DEMAIS TRAB DE CALCADOS E ARTEFATOS DE COURO',
    ativo: true
  },
  {
    codigo: 1093,
    descricao: 'SECRETARIO ESTADUAL',
    ativo: true
  },
  {
    codigo: 1092,
    descricao: 'SECRETARIO FEDERAL',
    ativo: true
  },
  {
    codigo: 1094,
    descricao: 'SECRETARIO MUNICIPAL',
    ativo: true
  },
  {
    codigo: 393,
    descricao: 'SECRETARIO/ESTENOGRAFO/DATIL/RECEP/TELEF TRAB ASSEMELHADOS',
    ativo: true
  },
  {
    codigo: 396,
    descricao: 'SECURITARIO',
    ativo: true
  },
  {
    codigo: 807,
    descricao: 'SEGURANCA/VIGILANTE',
    ativo: true
  },
  {
    codigo: 201,
    descricao: 'SENADOR DA REPUBLICA',
    ativo: true
  },
  {
    codigo: 758,
    descricao: 'SERRALHEIRO',
    ativo: true
  },
  {
    codigo: 293,
    descricao: 'SERVENTUARIO DE JUSTICA',
    ativo: true
  },
  {
    codigo: 297,
    descricao: 'SERVIDOR PUBLICO ESTADUAL',
    ativo: true
  },
  {
    codigo: 296,
    descricao: 'SERVIDOR PUBLICO FEDERAL',
    ativo: true
  },
  {
    codigo: 298,
    descricao: 'SERVIDOR PUBLICO MUNICIPAL',
    ativo: true
  },
  {
    codigo: 133,
    descricao: 'SOCIOLOGO',
    ativo: true
  },
  {
    codigo: 1043,
    descricao: 'SUCATEIRO',
    ativo: true
  },
  {
    codigo: 760,
    descricao: 'SUPERVISOR, INSPETOR E AGENTE DE COMPRAS E VENDAS',
    ativo: true
  },
  {
    codigo: 1005,
    descricao: 'TABELIAO/OFICIAL DE REGISTRO',
    ativo: true
  },
  {
    codigo: 1059,
    descricao: 'TAPECEIRO',
    ativo: true
  },
  {
    codigo: 1044,
    descricao: 'TAQUIGRAFO',
    ativo: true
  },
  {
    codigo: 536,
    descricao: 'TAXISTA',
    ativo: true
  },
  {
    codigo: 153,
    descricao: 'TECNICO AGRICOLA',
    ativo: true
  },
  {
    codigo: 152,
    descricao: 'TECNICO DE BIOLOGIA',
    ativo: true
  },
  {
    codigo: 151,
    descricao: 'TECNICO DE CONTABILIDADE E DE ESTATISTICA',
    ativo: true
  },
  {
    codigo: 1082,
    descricao: 'TECNICO DE EDIFICACOES',
    ativo: true
  },
  {
    codigo: 156,
    descricao: 'TECNICO DE ELETRICIDADE, ELETRONICA E TELECOMUNICACOES',
    ativo: true
  },
  {
    codigo: 1106,
    descricao: 'TECNICO DE ENFERMAGEM',
    ativo: true
  },
  {
    codigo: 1076,
    descricao: 'TECNICO DE INFORMATICA',
    ativo: true
  },
  {
    codigo: 157,
    descricao: 'TECNICO DE LABORATORIO E RAIOS X',
    ativo: true
  },
  {
    codigo: 155,
    descricao: 'TECNICO DE MECANICA',
    ativo: true
  },
  {
    codigo: 154,
    descricao: 'TECNICO DE QUIMICA',
    ativo: true
  },
  {
    codigo: 167,
    descricao: 'TECNICO EM AUDIO E CENOGRAFIA',
    ativo: true
  },
  {
    codigo: 1075,
    descricao: 'TECNICO EM SEGURANCA DO TRABALHO',
    ativo: true
  },
  {
    codigo: 761,
    descricao: 'TECNICO INSTRUMENTISTA',
    ativo: true
  },
  {
    codigo: 108,
    descricao: 'TECNOLOGO',
    ativo: true
  },
  {
    codigo: 1060,
    descricao: 'TINTUREIRO',
    ativo: true
  },
  {
    codigo: 759,
    descricao: 'TIPOGRAFO E DEMAIS TRABALHADORES DE ARTES GRAFICAS',
    ativo: true
  },
  {
    codigo: 1061,
    descricao: 'TOPOGRAFO',
    ativo: true
  },
  {
    codigo: 765,
    descricao: 'TORNEIRO MECANICO',
    ativo: true
  },
  {
    codigo: 392,
    descricao: 'TRAB DE SERVICOS DE CONTABILIDADE, CAIXA E ASSEMELHADOS',
    ativo: true
  },
  {
    codigo: 771,
    descricao: 'TRABALHADOR DE CONSTRUCAO CIVIL',
    ativo: true
  },
  {
    codigo: 1034,
    descricao: 'TRABALHADOR DE EXTRACAO DE MINERIOS',
    ativo: true
  },
  {
    codigo: 1105,
    descricao: 'TRABALHADOR DE EXTRACAO VEGETAL',
    ativo: true
  },
  {
    codigo: 1015,
    descricao: 'TRABALHADOR DE FABRICACAO DE PAPEL E PAPELAO',
    ativo: true
  },
  {
    codigo: 1016,
    descricao: 'TRABALHADOR DE FABRICACAO DE PRODUTOS DE BORRACHA E PLASTICO',
    ativo: true
  },
  {
    codigo: 1013,
    descricao: 'TRABALHADOR DE FABRICACAO DE ROUPAS',
    ativo: true
  },
  {
    codigo: 1014,
    descricao: 'TRABALHADOR DE FABRICACAO E PREPARACAO DE ALIMENTOS E BEBIDA',
    ativo: true
  },
  {
    codigo: 1012,
    descricao: 'TRABALHADOR DE INSTALACOES DE PROCESSAMENTO QUIMICO',
    ativo: true
  },
  {
    codigo: 756,
    descricao: 'TRABALHADOR DE USINAGEM DE METAIS',
    ativo: true
  },
  {
    codigo: 603,
    descricao: 'TRABALHADOR FLORESTAL',
    ativo: true
  },
  {
    codigo: 998,
    descricao: 'TRABALHADOR INFORMAL',
    ativo: true
  },
  {
    codigo: 812,
    descricao: 'TRABALHADOR RURAL',
    ativo: true
  },
  {
    codigo: 1045,
    descricao: 'TRADUTOR',
    ativo: true
  },
  {
    codigo: 411,
    descricao: 'VENDEDOR DE COMERCIO VAREJISTA E ATACADISTA',
    ativo: true
  },
  {
    codigo: 1006,
    descricao: 'VENDEDOR PRACISTA E CAIXEIRO VIAJANTE',
    ativo: true
  },
  {
    codigo: 1096,
    descricao: 'VEREADOR',
    ativo: true
  },
  {
    codigo: 112,
    descricao: 'VETERINARIO OU ZOOTECNISTA',
    ativo: true
  },
  {
    codigo: 1103,
    descricao: 'VICE-GOVERNADOR DE ESTADO/DISTRITO FEDERAL',
    ativo: true
  },
  {
    codigo: 1091,
    descricao: 'VICE-PREFEITO MUNICIPAL',
    ativo: true
  },
  {
    codigo: 1085,
    descricao: 'VICE-PRESIDENTE DA REPUBLICA',
    ativo: true
  },
  {
    codigo: 1046,
    descricao: 'VIDRACEIRO',
    ativo: true
  },
  {
    codigo: 502,
    descricao: 'ZELADOR DE EDIFICIO',
    ativo: true
  }
]
