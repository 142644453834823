import React from 'react'
import Drawer from 'src/components/ui/Drawer'
import Resumo from './hospital/components/resumoOrcamento'

interface DrawerVisualizarParceiros {
  open: boolean
  handleOnClose: () => void
  solicitacaoId?: string
}

function DrawerOrcamentoEnviado({
  open,
  handleOnClose,
  solicitacaoId
}: DrawerVisualizarParceiros) {
  return (
    <Drawer
      anchor="right"
      open={open}
      titulo="Dados do orçamento"
      handleOnClose={handleOnClose}
    >
      <Resumo solicitacaoId={solicitacaoId} isHospital />
    </Drawer>
  )
}

export default DrawerOrcamentoEnviado
