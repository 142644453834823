import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import formatarCPF from 'src/core/formatacoes/formatarCPF'
import {
  formatarData,
  formatarDataHora,
  formatarHora
} from 'src/core/formatacoes/formatarData'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { obterOrcamentoPorId } from 'src/api/solicitacao-orcamento'
import { ItemListagemSolicitacaoOrcamentoResponse } from 'src/types'
import { mapToForm } from './formV2'
import Ubox from 'src/components/ui/Ubox'
import Button from 'src/components/ui/Button'
import { RiArrowDownSFill } from 'react-icons/ri'
import useAuth from 'src/hooks/useAuth'
import logoHSD from 'src/images/LOGO-HSD-VERDE.png'

const logoWidth = 240 / 1.5
const logoHeight = 100 / 1.5

const useStylesResumo = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 10000,
    color: '#fff'
  },
  boxPrint: {
    padding: theme.spacing(1, 3, 3, 3),
    width: '100%',
    '& @page': {
      size: '210mm 297mm'
    },

    '& .header': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'end',
      width: '100%',
      padding: '8px 0 12px',
      borderBottom: '2px solid #eee',

      '& .logo-guia': {
        width: logoWidth + 'px',
        height: logoHeight + 'px',
        borderRadius: '2px',
        backgroundPosition: 'center center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
      },
      '& .codigo-guia': {
        width: '160px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '12px',
        border: '2px solid #ccc'
      }
    },
    '& .main': {},
    '& .footer': {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      padding: '0 32px',
      height: '160px',
      margin: 0,

      '& .box-footer': {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        borderTop: '4px solid #eee'
      },

      '& .logo-footer': {
        width: logoWidth / 1.5 + 'px',
        height: logoHeight / 1.5 + 'px',
        borderRadius: '2px',
        backgroundPosition: 'center center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        top: '-56px',
        left: '4px'
      },

      '& .box-qrcode': {
        background: '#eee',
        position: 'absolute',
        top: '-75px',
        right: 0,
        borderRadius: '8px'
      },

      '& .link-syn': {
        position: 'fixed',
        bottom: 12,
        right: 0,
        width: '100%',
        borderRadius: '8px'
      }
    }
  },
  boxResumo: {
    border: '1px solid #eee',
    // backgroundColor: '#efefef',
    borderRadius: 4,
    '& .MuiTableCell-root.MuiTableCell-head': {
      padding: theme.spacing(1, 2),
      // background: '#f9f9f9',
      color: theme.palette.grey[800]
    }
  },
  fontMarca: {
    fontStyle: 'italic',
    fontWeight: 500,
    fontSize: '0.8rem',
    color: theme.palette.grey[700]
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))
interface PropsResumo {
  item: ItemListagemSolicitacaoOrcamentoResponse
  onClose: () => void
}
export const ResumoImpressaoOrcamento = (props: PropsResumo) => {
  const { item, onClose } = props
  const { tenantAtual } = useAuth()
  const permitirLogo = ['640f7ab6-ddb5-4ba5-9237-e26c9e156980', 'd70861b3-5c24-4a55-8ddc-85c7dd21be2b']
  const [mostrarDetalhe, setMostrarDetalhe] = useState(false)
  const [detalheVisualizacao, setDetalheVisualizacao] = useState(null)
  const [pacoteDetalhe, setPacoteDetalhe] = useState(null)
  const [loading, setLoading] = useState(false)
  const [ehHSD, setEhHSD] = useState(false)

  const classes = useStylesResumo()

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: onClose
  })

  useEffect(() => {
    if (!item) return

    setDetalheVisualizacao(null)
    setLoading(true)

    obterOrcamentoDetalhe(item, (orcamento, pacote) => {
      // console.log('teste', item, orcamento, pacote)
      setDetalheVisualizacao(mapToForm(orcamento))
      setPacoteDetalhe(pacote)

      setTimeout(() => handlePrint(), 100)
      setTimeout(() => setLoading(false), 1000)
    })
  }, [item])

  const obterOrcamentoDetalhe = (id, callback) => {
    obterOrcamentoPorId(id)
      .then(async response => {
        const orcamento = response.data

        const pacote = {
          descricao: orcamento.pacote.descricao
        }

        callback(orcamento, pacote)
      })
      .catch(tratarErroApi)
  }

  const tratarErroApi = e => {
    setLoading(false)
    setMostrarDetalhe(false)
    showNotificationErrorAPI(e)
  }
  useEffect(() => {
    verificarHSD()
  }, [tenantAtual])

  function verificarHSD() {
    return setEhHSD(permitirLogo.includes(tenantAtual))
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ display: 'none' }}>
        {!!item && !!detalheVisualizacao && !!pacoteDetalhe && (
          <div ref={componentRef} className={classes.boxPrint}>
            {/* <Ubox>
              <Button onClick={() => handlePrint()}>Imprimir</Button>
            </Ubox> */}
            <Ubox className="header">
              <div
                className="logo-guia"
                style={{
                  backgroundImage: `url(${!!ehHSD ? logoHSD : detalheVisualizacao.urlLogomarca || 'image/logo-2.png'
                    })`
                }}
              />

              <Ubox
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
                justifyContent="space-between"
                style={{ height: '72px' }}
              >
                <Typography variant="body2" className="codigo-guia">
                  <b>Código: {detalheVisualizacao.codigo}</b>
                </Typography>
                <Typography variant="body2" align="right">
                  {`Guia gerada em ${formatarData(
                    detalheVisualizacao.dataHoraServidor
                  )} ás ${formatarHora(detalheVisualizacao.dataHoraServidor)}`}
                </Typography>
              </Ubox>
            </Ubox>
            <Ubox className="main">
              <Box mt={3}>
                <Ubox mt={2} mb={3}>
                  <Typography variant="body1" align="center">
                    <b>Guia de solicitação de procedimento</b>
                  </Typography>
                </Ubox>
                <Typography variant="body2" align="left">
                  Prezado(a) <b>{detalheVisualizacao.nomePaciente}</b>,<br />
                  a solicitação do seu procedimento foi recebida pela SYN. Em
                  breve, entraremos em contato com o orçamento para sua
                  aprovação.
                  <br />
                  <br />
                  Data da solicitação:{' '}
                  {formatarDataHora(detalheVisualizacao.criado)}
                  <br />
                  <br />
                  <b>Equipe SYN</b>
                </Typography>
              </Box>
              <Box mt={2} p={2} className={classes.boxResumo}>
                <Typography variant="subtitle2" color="primary">
                  Paciente
                </Typography>
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="body2" component="span">
                      <strong>Nome: </strong> {detalheVisualizacao.nomePaciente}
                    </Typography>
                  </Grid>
                  {detalheVisualizacao.dataNascimentoPaciente && (
                    <Grid item xs={6} sm={6}>
                      <Typography variant="body2">
                        <strong>Data de nascimento: </strong>
                        {formatarData(
                          detalheVisualizacao.dataNascimentoPaciente
                        )}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={6} sm={6}>
                    <Typography variant="body2">
                      <strong>CPF: </strong>{' '}
                      {formatarCPF(detalheVisualizacao.cpfPaciente)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="body2">
                      <strong>Tel: </strong>
                      {formatarTelefone(detalheVisualizacao.telefonePaciente)}
                    </Typography>
                  </Grid>
                  {detalheVisualizacao.emailPaciente && (
                    <Grid item xs={6} sm={6}>
                      <Typography variant="body2">
                        <strong>E-mail: </strong>
                        {detalheVisualizacao.emailPaciente}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <br />

                {!!detalheVisualizacao.medicoNome && (
                  <>
                    <Typography variant="subtitle2" color="primary">
                      Médico Solicitante
                    </Typography>
                    <Typography variant="body2">
                      {`Dr(a). ${detalheVisualizacao.medicoNome}`}
                    </Typography>
                    <br />
                  </>
                )}

                {!!pacoteDetalhe.descricao && (
                  <>
                    <Typography variant="subtitle2" color="primary">
                      Procedimento
                    </Typography>
                    <Typography variant="body2">
                      {pacoteDetalhe.descricao}
                    </Typography>
                    <br />
                  </>
                )}
              </Box>
            </Ubox>

            <Ubox className="footer">
              <Ubox className="box-footer">
                <Ubox>
                  <div
                    className="logo-footer"
                    style={{
                      backgroundImage: 'url(image/logo-2.png)'
                    }}
                  />
                </Ubox>
                <Ubox
                  className="box-qrcode"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  p={2}
                >
                  <Ubox>
                    <Typography variant="body2" align="center">
                      <b>
                        Acesse nossa
                        <br />
                        plataforma
                      </b>
                      <br />
                      <RiArrowDownSFill />
                    </Typography>
                  </Ubox>
                  <img
                    src="https://arquivosyn.blob.core.windows.net/imagens-syn/QRCode.png"
                    style={{
                      textAlign: 'center',
                      width: '100px',
                      margin: '0 auto'
                    }}
                  />
                </Ubox>
                <Ubox className="link-syn">
                  <Typography variant="body2" align="center">
                    www.synsaude.com.br
                  </Typography>
                </Ubox>
              </Ubox>
              <Ubox>
                <Typography variant="body2" align="left">
                  <br />
                  <b>Horário de funcionamento:</b>
                  <br />
                  Segunda a sexta das 08h ás 18h
                  <br />
                  <b>Telefone:</b>
                  <br />
                  (98) 99229-3099
                </Typography>
              </Ubox>
            </Ubox>
          </div>
        )}
      </div>
    </>
  )
}
