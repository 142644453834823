import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Search } from '@material-ui/icons'
import { useContext, useEffect, useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import {
  alterarStatusProcedimentoRealizado,
  listagemOrcamentoHospital
} from 'src/api/solicitacao-orcamento/index'
import DrawerVisualizarOcorrencias from 'src/components/drawers/medico/DrawerVisualizarOcorrencias'
import ChipStatusOrcamento from 'src/components/solicitacao/ChipStatusOrcamento'
import ButtonUI from 'src/components/ui/Button'
import { UltimaSolicitacaoContext } from 'src/contexts/UltimaSolicitacao'
import { statusOrcamento } from 'src/core/consts/statusSolicitacao'
import { SynRoutes } from 'src/core/consts/synRoutes'
import useAuth from 'src/hooks/useAuth'
import useListagem from 'src/hooks/useListagem'
import { useQuery } from 'src/hooks/useQuery'
import useWindowInfiniteScroll from 'src/hooks/useWindowInfiniteScroll'
import {
  IAgedamentoResponse,
  ICancelamentoAgedamentoResponse,
  ItemListagemSolicitacaoOrcamentoResponse
} from 'src/types'
import { ResumoImpressaoOrcamento } from '../../../medico/solicitacao/resumoImpressao'
import CardHospitalListagem from '../components/cardListagem'
import DrawerVisualizarDetalhes from '../components/drawerVisualizarDetalhes'
import DrawerAgendamento from './drawerAgendamento/drawerAgendamento'
import DrawerCancelamento from './drawerCancelamento/drawerCancelamento'

function ListagemSolicitacoesPendencias() {
  const { user, perfilAtual } = useAuth()
  //#region  USE STATE
  const history = useHistory()
  const query = useQuery()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [mostrarDetalhe, setMostrarDetalhe] = useState(false)
  const [detalheVisualizacao, setDetalheVisualizacao] = useState(null)
  const [loadingDetalhe, setLoadingDetalhe] = useState(false)
  const [itemPrint, setItemPrint] = useState(null)
  const [lastSelectedCard, setLastSelectedCard] = useState(null)

  const { setUltimaSolicitacao, ultimaSolicitacaoVista } = useContext(
    UltimaSolicitacaoContext
  )
  const [visualizarOcorrencias, setVisualizarOcorrencias] = useState({
    show: false,
    id: ''
  })
  const [openDrawerAgendamento, setOpenDrawerAgendamento] = useState(false)
  const [openDrawerCancelamento, setOpenDrawerCancelamento] = useState(false)
  const [dataInicio, setDataInicio] = useState('')
  const [dataFim, setDataFim] = useState(null)
  const ehPendenteRevisaoValores = true
  //#endregion
  //#region LISTAGEM
  const {
    loading,
    dados,
    handleTermoPesquisa,
    termoPesquisa,
    nenhumDado,
    nenhumResultado,
    dadosCarregados,
    carregarListagem,
    allDados,
    allDadosCarregados,
    qtdAllDados,
    handleProxPagina,
    atualizarItemListagem
  } = useListagem(9, listagem)

  async function listagem(query) {
    return await listagemOrcamentoHospital(query, ehPendenteRevisaoValores)
  }

  const [solicitacaoItem, setSolicitacaoItem] = useState(null)

  useWindowInfiniteScroll({
    data: allDados,
    loadMoreFunc: handleProxPagina,
    stopLoadingData: allDados.length >= qtdAllDados
  })
  //#endregion
  function handleNovoOrcamento() {
    history.push(SynRoutes.medicoAssistente)
  }

  useEffect(() => {
    if (query.has('print')) {
      let print = query.get('print')
      setItemPrint(print)
      history.replace(SynRoutes.listagemOrcamentosHospital)
    }
  }, [])

  const handleFecharDetalhes = () => {
    setDetalheVisualizacao(null)
    setMostrarDetalhe(false)
  }

  function handleEditar(item: ItemListagemSolicitacaoOrcamentoResponse) {
    history.push(
      `${SynRoutes.formOrcamentoUnificado}?id=${item.id}&idPacote=${item.id}&descricaoPacote=${item.descricaoPacoteProcedimento}&tipo=cotacao`
    )
  }

  function handleImprimir(item: ItemListagemSolicitacaoOrcamentoResponse) {
    setItemPrint(item.id)
  }

  function handleFecharImprimir() {
    setItemPrint(null)
  }

  const handleVisualizarDetalhes = (
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) => {
    setDetalheVisualizacao(item)
    setLoadingDetalhe(true)
    setMostrarDetalhe(true)
  }

  async function handleAlterarStatusProcedimentoRealizado(
    idSolicitacao: string
  ) {
    await alterarStatusProcedimentoRealizado(idSolicitacao, perfilAtual)

    carregarListagem({
      resetarPaginacao: true,
      dataInicio: dataInicio,
      dataFim: dataFim
    })
  }

  // const tratarErroApi = e => {
  //   setMostrarDetalhe(false)
  //   showNotificationErrorAPI(e)
  // }

  function setClassToHighlight(item) {
    if (item?.id === lastSelectedCard?.id) return 'highlight'

    return null
  }

  function renderStatusSolicitacao(
    item: ItemListagemSolicitacaoOrcamentoResponse
  ) {
    return (
      <ChipStatusOrcamento
        statusId={item?.status?.id}
        perfil="parceiro"
        orcamentoAprovado={item.orcamentoAprovado}
      />
    )
  }

  function handleVisualizarOcorrencias(solicitacaoId: string) {
    setVisualizarOcorrencias({
      show: true,
      id: solicitacaoId
    })
  }

  function handleAgendar(
    itemAgendamento: ItemListagemSolicitacaoOrcamentoResponse
  ) {
    setSolicitacaoItem(itemAgendamento)
    setOpenDrawerAgendamento(true)
  }

  function handleCancelarAgendamento(
    itemCancelarAgendamento: ItemListagemSolicitacaoOrcamentoResponse
  ) {
    setSolicitacaoItem(itemCancelarAgendamento)
    setOpenDrawerCancelamento(true)
  }

  function handleFecharAgendamento(agendamento: IAgedamentoResponse = null) {
    if (!!agendamento) {
      var novoItemSolicitacao = solicitacaoItem
      if (!solicitacaoItem?.agendamento) {
        novoItemSolicitacao.status = {
          id: statusOrcamento.AGENDADO.Id,
          descricao: statusOrcamento.AGENDADO.Parceiro.Descricao
        }
      }
      novoItemSolicitacao.agendamento = agendamento

      atualizarItemListagem(solicitacaoItem.id, novoItemSolicitacao)
    }
    setOpenDrawerAgendamento(false)
  }

  function handleFecharCancelamento(
    cancelamento: ICancelamentoAgedamentoResponse = null
  ) {
    if (!!cancelamento) {
      var novoItemSolicitacao = solicitacaoItem
      novoItemSolicitacao.status = {
        id: statusOrcamento.EM_AGENDAMENTO.Id,
        descricao: statusOrcamento.EM_AGENDAMENTO.Parceiro.Descricao
      }
      novoItemSolicitacao.agendamento = null

      atualizarItemListagem(solicitacaoItem.id, novoItemSolicitacao)
    }
    setOpenDrawerCancelamento(false)
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5">Solicitações de Orçamentos Pendentes</Typography>
      </Box>
      {
        //#region Filtro
      }
      <Box mb={2}>
        {/* Refatorar Nenhum orçamento*/}
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              placeholder="Pesquise pelo nome do pacote ou do paciente..."
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: loading && (
                  <CircularProgress
                    style={{ fontSize: '20px', width: '20px', height: '20px' }}
                  />
                )
              }}
              value={termoPesquisa}
              onChange={handleTermoPesquisa}
            />
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
      </Box>
      {
        //#endregion
      }

      {
        //#region CARDS LISTAGEM
      }
      {!loading && (
        <Box mt={3} mb={2} textAlign="left" my={3}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color="primary">
                {!!ultimaSolicitacaoVista
                  ? 'Última solicitação vista'
                  : qtdAllDados
                    ? qtdAllDados > 1
                      ? `${qtdAllDados} solicitações`
                      : '1 solicitação'
                    : '0 Solicitações'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {!!ultimaSolicitacaoVista && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setUltimaSolicitacao()}
                >
                  Mostrar todas as solicitações
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      )}

      {nenhumDado && !nenhumResultado && allDados.length === 0 && (
        <>
          <Box textAlign="center" py={3}>
            <Grid container justify="center">
              <Grid item xs={12}>
                <Typography variant="h6" color="primary">
                  Nenhuma solicitação pendente de revisão de valores.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      {nenhumResultado && (
        <div style={{ marginTop: 20 }}>
          Nenhum resultado encontrado para sua pesquisa.
        </div>
      )}
      {allDadosCarregados && (
        <div className="fadeInTransition">
          <Grid container spacing={2}>
            {ultimaSolicitacaoVista !== undefined && (
              <Grid item xs={12} md={4} key={ultimaSolicitacaoVista.id}>
                <CardHospitalListagem
                  solicitacao={ultimaSolicitacaoVista}
                  className={setClassToHighlight(ultimaSolicitacaoVista)}
                  handleImprimir={handleImprimir}
                  handleVisualizarDetalhes={handleVisualizarDetalhes}
                  item={ultimaSolicitacaoVista}
                  renderStatusSolicitacao={renderStatusSolicitacao}
                  handleVisualizarOcorrencias={handleVisualizarOcorrencias}
                  handleAgendar={handleAgendar}
                  handleCancelarAgendamento={handleCancelarAgendamento}
                  handleAlterarStatusProcedimentoRealizado={
                    handleAlterarStatusProcedimentoRealizado
                  }
                  navigate={history.push}
                />
              </Grid>
            )}
            {allDados.map(item => {
              if (ultimaSolicitacaoVista === undefined) {
                return (
                  <Grid item xs={12} md={4} key={item.id}>
                    <CardHospitalListagem
                      solicitacao={item}
                      className={setClassToHighlight(item)}
                      handleImprimir={handleImprimir}
                      handleVisualizarDetalhes={handleVisualizarDetalhes}
                      item={item}
                      renderStatusSolicitacao={renderStatusSolicitacao}
                      handleVisualizarOcorrencias={handleVisualizarOcorrencias}
                      handleAgendar={handleAgendar}
                      handleCancelarAgendamento={handleCancelarAgendamento}
                      handleAlterarStatusProcedimentoRealizado={
                        handleAlterarStatusProcedimentoRealizado
                      }
                      navigate={history.push}
                    />
                  </Grid>
                )
              } else {
                return <></>
              }
            })}
          </Grid>
        </div>
      )}

      <Box mt={3} hidden={qtdAllDados === allDados.length} textAlign="center">
        <ButtonUI
          fullWidth
          onClick={handleProxPagina}
          variant="text"
          disabled={loading}
          loading={loading}
          endIcon={<MdKeyboardArrowDown />}
        >
          Carregar mais
        </ButtonUI>
      </Box>

      {
        //#endregion
      }
      {
        //#region Drawer Detalhes
      }
      <DrawerVisualizarDetalhes
        id={detalheVisualizacao?.id}
        exibirValores={true}
        open={mostrarDetalhe}
        handleOnClose={handleFecharDetalhes}
      />

      {
        //#endregion
      }
      {
        //#region Drawer Ocorrencias
      }
      <DrawerVisualizarOcorrencias
        open={visualizarOcorrencias.show}
        handleOnClose={() =>
          setVisualizarOcorrencias({
            show: false,
            id: ''
          })
        }
        solicitacaoId={visualizarOcorrencias.id}
        parceiro={true}
      />
      {
        //#endregion
      }
      {
        //#region IMPRESSÃO
      }
      <ResumoImpressaoOrcamento
        item={itemPrint}
        onClose={handleFecharImprimir}
      />
      {
        //#endregion
      }
      {openDrawerAgendamento && (
        <DrawerAgendamento
          solicitacaoItem={solicitacaoItem}
          open={true}
          handleFecharAgendamento={(agendamento) => handleFecharAgendamento(agendamento)}
          handleOnClose={() => setOpenDrawerAgendamento(false)}
        />
      )}

      {openDrawerCancelamento && (
        <DrawerCancelamento
          solicitacaoItem={solicitacaoItem}
          open={true}
          handleFecharCancelamento={(cancelamento) => handleFecharCancelamento(cancelamento)}
          handleOnClose={() => setOpenDrawerCancelamento(false)}
        />
      )}
    </Box>
  )
}

export default ListagemSolicitacoesPendencias
