import { yupResolver } from '@hookform/resolvers/yup'
import {
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'

import { Alert, Skeleton } from '@material-ui/lab'
import moment from 'moment'
import { useEffect, useState } from 'react'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Button from 'src/components/ui/Button'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import TextField from 'src/components/ui/TextField'
import UBox from 'src/components/ui/Ubox'
import yup from 'src/components/yupCustom'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import {
  onlyNumber,
  removerAcento,
  verificarCPF
} from 'src/core/helpers/common'
import usePagamento from 'src/hooks/usePagamento'
import { useQuery } from 'src/hooks/useQuery'
import InfoResponsabilidade from './infoResponsabilidade'
import ScrollToBottom from './scrollToBottom'

const useStyles = makeStyles(theme => ({
  bandeiras: {
    '& img': {
      maxWidth: '34px'
    }
  },
  root: {
    '& input.MuiInputBase-input': {
      textTransform: 'uppercase'
    }
  }
}))

export class ICartaoForm {
  integral?: boolean
  valor?: number

  tokenCartao: string
  numeroParcela: number

  cidade: string
  cep: string
  endereco: string
  estado: string

  cpf: string
  email: string
  nome: string
  telefone: string

  cvc: string
  expiry: string
  focus: string
  number: string
}

const DEFAULT_CARTAO: ICartaoForm = {
  valor: null,
  tokenCartao: null,
  numeroParcela: 1,
  cidade: '',
  cep: '',
  endereco: '',
  estado: '',
  cpf: '',
  email: '',
  nome: '',
  telefone: '',

  cvc: '',
  expiry: '',
  focus: '',
  number: ''
}

const cartaoSchema = yup.object().shape({
  // cpf: yup
  //   .string()
  //   .required()
  //   .test('cpf-teste', 'Documento inválido', value => {
  //     if (!value) return false

  //     const documento = onlyNumber(value)

  //     return verificarCPF(documento)
  //   })
  //   .label('CPF'),
  // email: yup.string().email().required(),
  // telefone: yup.string().required(),
  cvc: yup
    .string()
    .required()
    .test(
      'cvc-teste',
      'Código de segurança deve ter 3 ou 4 caracteres',
      value => {
        if (!value) return false

        const cvc = onlyNumber(value)
        return cvc.length >= 3 || cvc.length <= 4
      }
    )
    .label('Código de segurança'),
  expiry: yup
    .string()
    .required()
    .test(
      'expiry-teste',
      'Informe Validade deve estar no formato MM/AA',
      value => {
        if (!value) return false

        const numero = onlyNumber(value)
        if (numero.length !== 'ddyy'.length) {
          return false
        }
        const validadeMes = parseInt(numero.substring(0, 2))
        const validadeAno = parseInt(numero.substring(2))

        if (validadeMes < 1 || validadeMes > 12) return false

        let dataValida = moment(
          `20${validadeAno}-${
            validadeMes < 10 ? '0' + validadeMes : validadeMes
          }`
        )
          .endOf('month')
          .isBetween(moment(), '2099-12-31', 'day')

        if (!dataValida) return false

        return true
      }
    )
    .label('Validade do cartão'),
  nome: yup.string().required().label('Nome impresso no cartão'),
  number: yup
    .string()
    .required()
    .test(
      'number-teste',
      'Número do cartão deve ter entre 13 e 19 caracteres',
      value => {
        if (!value) return false

        const numero = onlyNumber(value)
        return numero.length >= 13 && numero.length <= 19
      }
    )
    .label('Número do cartão'),
  numeroParcela: yup
    .number()
    .nullable()
    .min(1)
    .max(12)
    .required()
    .label('Quantidade de parcelas')
})

interface IProps {
  integral: boolean
  valor?: number
  handleProximoPagamento?: () => void
}

export default function CartaoCredito({
  integral,
  valor,
  handleProximoPagamento
}: IProps) {
  const styles = useStyles()
  const theme = useTheme()
  const query = useQuery()
  const history = useHistory()

  const {
    loadingCartao,
    pagamentoCartaoCredito,
    selecionarFormaPagamento,
    pagamentoCreditoFracionado,
    resumo,
    obterParcelas,
    parcelamento,
    loadingParcelas
  } = usePagamento()

  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))
  const [focus, setFocus] = useState('')
  const {
    handleSubmit,
    errors,
    register,
    watch,
    control,
    setValue
  } = useForm<ICartaoForm>({
    mode: 'all',
    resolver: yupResolver(cartaoSchema),
    defaultValues: DEFAULT_CARTAO
  })
  const commandCartao = watch()

  const handleInputFocus = e => {
    setFocus(e.target.name)
  }

  function preencherCliente() {
    setValue('number', '4000 0000 0000 0010', { shouldValidate: true })
    setValue('numeroParcela', 1, { shouldValidate: true })
    setValue('nome', 'Fulano Pereira', { shouldValidate: true })
    setValue('expiry', '12/23', { shouldValidate: true })
    setValue('cvc', '1234', { shouldValidate: true })
    setValue('cpf', '668.551.160-51', { shouldValidate: true })
    setValue('email', 'fulano@email.cum', { shouldValidate: true })
    setValue('telefone', '(61) 99999-9999', { shouldValidate: true })
  }

  function obterValorSemJuros() {
    return integral ? resumo.valorTotal : valor
  }

  function obterValor() {
    let parcela = obterParcelamento()?.parcelas.find(
      p => p.qtdeParcelas === commandCartao.numeroParcela
    )

    if (parcela) return parcela.valorTotal

    return obterValorSemJuros()
  }

  function obterParcelamento() {
    return integral ? resumo.parcelamento : parcelamento
  }

  function submit(form: ICartaoForm) {
    form.integral = integral
    form.valor = obterValorSemJuros()
    form.nome = removerAcento(form.nome)
    pagamentoCartaoCredito(form)
  }

  useEffect(() => {
    let valorTotalSemJuros = obterValorSemJuros()
    obterParcelas(valorTotalSemJuros)
  }, [])

  useEffect(() => {
    register('number')
    register('expiry')
    register('cvc')
    register('cpf')
    register('numeroParcela')
  }, [register])

  if (!resumo) return <LoadingGeral />

  return (
    <UBox>
      {/* cartao:{JSON.stringify(commandCartao)}
      <br />
      focus:{JSON.stringify(focus)}
      <br />
      p:{JSON.stringify(pagamentoCreditoFracionado)} */}
      <ScrollToBottom cssSeletor="#btnPagar" />
      <UBox
        render={!pagamentoCreditoFracionado}
        component="form"
        onSubmit={handleSubmit(submit)}
        // noValidate={true}
      >
        <UBox mb={4}>
          <Typography variant="subtitle1" color="primary">
            <b>Aceitamos as bandeiras</b>
          </Typography>

          <UBox
            display="flex"
            alignItems="center"
            justifyContent="start"
            flexWrap="wrap"
            className={styles.bandeiras}
          >
            <img
              src="image/bandeiras/ic-master.svg"
              alt="master"
              title="master"
            />
            <img src="image/bandeiras/ic-visa.svg" alt="visa" title="visa" />
            <img src="image/bandeiras/ic-elo.svg" alt="Elo" title="Elo" />
            <img src="image/bandeiras/ic-america.svg" alt="amex" title="amex" />
            <img src="image/bandeiras/ic-jcb.svg" alt="jcb" title="jcb" />
            <img src="image/bandeiras/ic-aura.svg" alt="aura" title="aura" />
            <img src="image/bandeiras/ic-hiper.svg" alt="hiper" title="hiper" />
            <img
              src="image/bandeiras/ic-diners.svg"
              alt="diners"
              title="diners"
            />
            <img
              src="image/bandeiras/ic-discover.svg"
              alt="discover"
              title="discover"
            />
          </UBox>
        </UBox>

        <Grid container className={styles.root}>
          <Grid item xs={12} sm={12}>
            <UBox hidden={ehXS}>
              <Cards
                cvc={commandCartao?.cvc || ''}
                expiry={commandCartao?.expiry || ''}
                focused={focus || ''}
                name={commandCartao?.nome || ''}
                number={commandCartao?.number || ''}
              />
            </UBox>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <UBox mt={2}>
                  <Typography variant="subtitle1" color="primary">
                    <b>Dados do cartão</b>
                  </Typography>
                  <Divider />
                </UBox>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  disabled={loadingCartao}
                  size="small"
                  erro={errors}
                  autoFocus
                  type="tel"
                  value={commandCartao.number}
                  defaultValue={commandCartao.number}
                  mask="9999 9999 9999 9999"
                  onFocus={handleInputFocus}
                  control={control}
                  inputProps={{ maxLength: 100 }}
                  name="number"
                  label="Número do cartão"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={loadingCartao}
                  erro={errors}
                  size="small"
                  type="tel"
                  mask="99/99"
                  onFocus={handleInputFocus}
                  control={control}
                  name="expiry"
                  label="Validade"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  size="small"
                  disabled={loadingCartao}
                  erro={errors}
                  onFocus={handleInputFocus}
                  inputRef={register}
                  inputProps={{ maxLength: 64 }}
                  name="nome"
                  label="Nome impresso no cartão"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={loadingCartao}
                  erro={errors}
                  size="small"
                  mask="9999"
                  type="tel"
                  control={control}
                  onChange={e => {
                    handleInputFocus(e)
                  }}
                  onFocus={e => {
                    handleInputFocus(e)
                  }}
                  onBlur={() => setFocus(null)}
                  name="cvc"
                  label="Código de segurança"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  disabled={loadingCartao}
                  erro={errors}
                  mask="999.999.999-99"
                  // value={command.nome}
                  // showCaracteres={100
                  control={control}
                  onFocus={e => {
                    handleInputFocus(e)
                  }}
                  name="cpf"
                  label="CPF do titular do cartão"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <UBox mt={2}>
                  <FormControl
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errors.numeroParcela}
                    disabled={loadingCartao}
                  >
                    <InputLabel id="parcela-label" shrink={true}>
                      Parcelas
                    </InputLabel>
                    <Select
                      labelId="parcela-label"
                      label="Parcelas"
                      // InputLabelProps={{
                      //   shrink: true
                      // }}
                      startAdornment={
                        loadingParcelas && (
                          <CircularProgress
                            style={{
                              fontSize: '20px',
                              width: '20px',
                              height: '20px'
                            }}
                          />
                        )
                      }
                      disabled={loadingCartao || loadingParcelas}
                      value={commandCartao.numeroParcela}
                      onChange={e => {
                        console.log('s', e)
                        setValue('numeroParcela', e.target.value, {
                          shouldValidate: true
                        })
                      }}
                    >
                      {obterParcelamento()?.parcelas.map(p => (
                        <MenuItem
                          key={'p-' + p.qtdeParcelas}
                          value={p.qtdeParcelas}
                          selected={
                            commandCartao?.numeroParcela === p.qtdeParcelas
                          }
                        >
                          {`${p.qtdeParcelas} x ${formatarDinheiro(p.valor)}  `}
                          {/* (${formatarDinheiro(p.valorTotal)}) */}
                        </MenuItem>
                      ))}
                      {/* <MenuItem value={2}>2x sem juros</MenuItem>
                      <MenuItem value={3}>3x sem juros</MenuItem>
                      <MenuItem value={4}>4x sem juros</MenuItem>
                      <MenuItem value={5}>5x sem juros</MenuItem>
                      <MenuItem value={6}>6x sem juros</MenuItem> */}
                    </Select>
                    <FormHelperText error={!!errors.numeroParcela}>
                      {errors.numeroParcela?.message}
                    </FormHelperText>
                    {commandCartao?.numeroParcela && (
                      <FormHelperText>
                        Parcelamento com juros de{' '}
                        {
                          obterParcelamento()?.parcelas?.find(
                            x => x.qtdeParcelas === commandCartao?.numeroParcela
                          ).juros
                        }
                        % a.m
                      </FormHelperText>
                    )}
                  </FormControl>
                </UBox>
              </Grid>
              {/* <Grid item xs={12}>
                <UBox mt={2}>
                  <Typography variant="subtitle1" color="primary">
                    Dados do cliente
                  </Typography>
                  <Divider />
                </UBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={loadingCartao}
                  erro={errors}
                  inputRef={register}
                  inputProps={{ maxLength: 64 }}
                  onFocus={e => {
                    handleInputFocus(e)
                  }}
                  name="email"
                  label="E-mail do cliente"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={loadingCartao}
                  erro={errors}
                  maskTelCel
                  control={control}
                  inputProps={{ maxLength: 100 }}
                  onFocus={e => {
                    handleInputFocus(e)
                  }}
                  name="telefone"
                  label="Telefone"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <UBox mt={2}>
          <InfoResponsabilidade />
          <br />
          <Alert severity="info">
            O pagamento via cartão de crédito possui taxas de juros que são
            aplicadas sobre o valor total.
          </Alert>
        </UBox>
        <UBox
          mt={1}
          mb={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body1">
            <b>Total:</b>
          </Typography>
          <Typography variant="body1">
            {loadingParcelas ? (
              <Skeleton variant="text" width={120} />
            ) : (
              <b>{formatarDinheiro(obterValor())}</b>
            )}
          </Typography>
        </UBox>
        <UBox mt={3}>
          <Button
            color="primary"
            fullWidth
            loading={loadingCartao}
            disabled={loadingCartao || loadingParcelas}
            // onClick={() => pagamentoBoleto()}
            type="submit"
            id="btnPagar"
          >
            Confirmar pagamento
          </Button>
        </UBox>
        {/* <UBox my={1}>
          <Button
            color="default"
            fullWidth
            loading={loadingCartao}
            onClick={() => preencherCliente()}
          >
            Preencher cliente teste
          </Button>
        </UBox> */}
      </UBox>
      <UBox render={!integral && !!pagamentoCreditoFracionado} my={2}>
        <UBox mb={2}>Parabéns pagamento foi realizado com sucesso!</UBox>

        <Button color="primary" fullWidth onClick={handleProximoPagamento}>
          Próximo pagamento
        </Button>
      </UBox>
    </UBox>
  )
}
