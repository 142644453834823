import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Search } from '@material-ui/icons'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useState, useEffect } from 'react'
import useListagem from 'src/hooks/useListagem'
import {
  excluirPacote,
  listagemPacote,
  obterPacotePorId
} from 'src/api/pacote/index'
import {
  EspecialidadeResponse,
  ItemListagemPacoteProcedimentoResponse,
  ObjetoDescricaoGenerico
} from 'src/types'

import useWindowInfiniteScroll from '../../../../hooks/useWindowInfiniteScroll'
import Button from 'src/components/ui/Button'
import { MdKeyboardArrowDown } from 'react-icons/md'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import { listarTodasEspecilidades } from 'src/api/especialidade'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import UBox from 'src/components/ui/Ubox'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { useHistory } from 'react-router-dom'
import {
  ItemListagemPacoteInteligente,
  ItemListagemPacoteUnificado,
  listagemMeusPacotesInteligentes,
  listagemPacoteInteligente,
  ListagemPacoteInteligenteQuery,
  listagemPacotesUnificados,
  ListagemPacoteUnificadoQuery
} from 'src/api/pacoteInteligente'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import DrawerPacoteInteligenteVisualizarDetalhes from 'src/components/pacoteInteligente/Drawer/resumo'
import { BsCheckCircle, BsInfoCircle } from 'react-icons/bs'
import { Alert } from '@material-ui/lab'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { amber } from '@material-ui/core/colors'
import Swal from 'sweetalert2'
import { BiNetworkChart } from 'react-icons/bi'
import { AiTwotoneThunderbolt } from 'react-icons/ai'
import ResumoPacote from './resumo'
import DrawerCompartilharPacote from './drawerCompartilharPacote'
import Drawer from 'src/components/ui/Drawer'
import { formatarData } from 'src/core/formatacoes/formatarData'
import moment from 'moment'

function ListagemPacoteUnificado() {
  const { perfilAtual, user } = useAuth()
  const [mostrarDetalhe, setMostrarDetalhe] = useState(false)
  const [
    itemSelecionado,
    setItemSelecionado
  ] = useState<ItemListagemPacoteUnificado>(undefined)

  const history = useHistory()

  const {
    loading,
    dados,
    handleTermoPesquisa,
    termoPesquisa,
    nenhumDado,
    nenhumResultado,
    dadosCarregados,
    allDadosCarregados,
    loadingPagina,
    allDados,
    qtdAllDados,
    handleProxPagina,
    removerItemListagem,
    filtroGeral,
    carregarListagem,
    setFiltroGeral
  } = useListagem(9, listagem)

  function listagem(
    query: ListagemPacoteUnificadoQuery,
    primeiraBusca?: boolean
  ) {
    query['perfilAtual'] = perfilAtual
    return listagemPacotesUnificados(
      user.cpf,
      query,
      mostrarCotacao,
      mostrarInteligente
    )
  }

  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState<
    ObjetoDescricaoGenerico[]
  >([])
  const [especialidades, setEspecialidades] = useState<
    EspecialidadeResponse[]
  >()
  const [loadingEspecialidades, setLoadingEspecialidades] = useState(false)
  const [mostrarCotacao, setMostrarCotacao] = useState(true)
  const [mostrarInteligente, setMostrarInteligente] = useState(true)

  useWindowInfiniteScroll({
    loadMoreFunc: handleProxPagina,
    data: allDados,
    stopLoadingData: allDados.length >= qtdAllDados
  })

  const handleVisualizarDetalhesInteligente = (
    item: ItemListagemPacoteUnificado
  ) => {
    if (perfilAtual !== SynPerfis.PARCEIRO_EXTERNO) {
      setItemSelecionado(item)
      setMostrarDetalhe(true)
    }
  }

  const handleConfigurarPacoteInteligente = (
    item: ItemListagemPacoteInteligente
  ) => {
    history.push(
      `${SynRoutes.formConfiguracaoPacoteInteligente}?idPacote=${item.id}`
    )
  }
  const handleSolicitarOrcamentoInteligente = (
    item: ItemListagemPacoteInteligente
  ) => {
    history.push(
      `${SynRoutes.formOrcamentoUnificado}?idPacote=${item.id}&descricaoPacote=${item.descricao}&tipo=inteligente`
    )
    // history.push(
    //   `${SynRoutes.formOrcamentoInteligente}?idPacote=${item.id}&descricaoPacote=${item.descricao}`
    // )
  }

  const handleFecharDetalhes = () => {
    if (itemSelecionado.tipo === 'cotacao') {
      setDetalhesVisualizacao(null)
    }
    setMostrarDetalhe(false)
  }

  function handleChangeEspecialidade(
    event,
    newValue: ObjetoDescricaoGenerico[]
  ) {
    var value = newValue.map(v => v.id)
    setEspecialidadeSelecionada(newValue)
    setFiltroGeral({
      especialidades: value,
      pagina: 1,
      qtdPorPagina: 9
    })
  }

  // Pacote cotação

  const [detalhesVisualizacao, setDetalhesVisualizacao] = useState(null)
  const [loadingDetalhes, setLoadingDetalhes] = useState(false)
  const [openCompartilharPacote, setOpenCompartilharPacote] = useState(false)
  const [pacoteSelecionado, setPacoteSelecionado] = useState()
  const [lastSelectedCard, setLastSelectedCard] = useState(null)

  const handleVisualizarDetalhesCotacao = (
    item: ItemListagemPacoteUnificado
  ) => {
    setDetalhesVisualizacao(null)
    setLoadingDetalhes(true)
    setItemSelecionado(item)
    obterPacotePorId(item.id)
      .then(response => {
        setDetalhesVisualizacao(response.data)
        setLastSelectedCard(item)
        setLoadingDetalhes(false)
        setMostrarDetalhe(true)
      })
      .catch(e => {
        setMostrarDetalhe(false)
        showNotificationErrorAPI(e)
      })
  }

  const handleCompartilharPacoteCotacao = pacote => {
    setOpenCompartilharPacote(true)
    setPacoteSelecionado(pacote)
  }

  const handleCloseCompartilharPacoteCotacao = () => {
    setOpenCompartilharPacote(false)
    setPacoteSelecionado(undefined)
  }

  function handleNovoPacoteCotacao() {
    history.push(SynRoutes.formPacote)
  }

  function handleEditarCotacao(item: ItemListagemPacoteUnificado) {
    history.push(`${SynRoutes.formPacote}?idPacote=${item.id}`)
  }

  function handleSolicitarOrcamentoCotacao(item: ItemListagemPacoteUnificado) {
    history.push(
      `${SynRoutes.formOrcamentoUnificado}?idPacote=${item.id}&descricaoPacote=${item.descricao}&tipo=cotacao`
    )
  }

  async function handleExcluirCotacao(item: ItemListagemPacoteUnificado) {
    var result = await Swal.fire({
      title: 'Deseja excluir o pacote de procedimento?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await excluirPacote(item.id)
      },
      allowOutsideClick: () => !Swal.isLoading()
    })

    if (result.isDismissed) {
      return
    }

    if (result.value) {
      Swal.fire('Pacote de procedimento excluído com sucesso!', '', 'success')

      removerItemListagem(item.id, 'id')
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text:
          'Não foi possível excluir o pacote de procedimento. Tente novamente mais tarde ou contate o suporte.'
      })
    }
  }
  //Pacote cotação

  useEffect(() => {
    setLoadingEspecialidades(true)

    listarTodasEspecilidades()
      .then(response => setEspecialidades(response.data))
      .catch(err => showNotificationErrorAPI(err))

    setLoadingEspecialidades(false)
  }, [])

  useEffect(() => {
    carregarListagem({ resetarPaginacao: true })
  }, [mostrarCotacao, mostrarInteligente])

  function setClassToHighlight(item) {
    if (item?.id === itemSelecionado?.id) return 'highlight'

    return null
  }

  return (
    <Box>
      <UBox>
        {/* e:{JSON.stringify(especialidades)} */}
        {/* <br /> */}
        {/* e:{JSON.stringify(especialidadeSelecionada)}
        <br />
        f:{JSON.stringify(filtroGeral?.especialidades)} */}
        <Box mb={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <UBox pt={2}>
                <TextField
                  fullWidth
                  placeholder="Pesquise por procedimento, código ou nome do médico..."
                  size="small"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  value={termoPesquisa}
                  onChange={handleTermoPesquisa}
                />
              </UBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormControlLabel
                  value={mostrarInteligente}
                  control={
                    <Checkbox
                      checked={mostrarInteligente}
                      onChange={(e, value) => {
                        setMostrarInteligente(value)
                      }}
                    />
                  }
                  label="Mostrar pacotes instantâneos"
                />
                <FormControlLabel
                  value={mostrarCotacao}
                  control={
                    <Checkbox
                      checked={mostrarCotacao}
                      onChange={(e, value) => {
                        setMostrarCotacao(value)
                      }}
                    />
                  }
                  label="Mostrar pacotes cotações"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Box>

        {!loading && (
          <Box mt={3} mb={2} textAlign="left" my={3}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" color="primary">
                  {qtdAllDados
                    ? qtdAllDados > 1
                      ? `${qtdAllDados} pacotes`
                      : '1 pacote'
                    : '0 pacotes'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {nenhumResultado && (
          <div>Nenhum resultado encontrado para sua pesquisa.</div>
        )}
        {allDadosCarregados && !loadingPagina && (
          <div className="fadeInTransition">
            <Grid container spacing={2}>
              {allDados.map(item =>
                item.tipo === 'cotacao' ? (
                  <Grid item xs={12} md={4} key={item.id}>
                    <CardCotacao
                      item={item}
                      handleEditar={handleEditarCotacao}
                      handleSolicitarOrcamento={handleSolicitarOrcamentoCotacao}
                      handleVisualizarDetalhes={handleVisualizarDetalhesCotacao}
                      handleCompartilharPacote={handleCompartilharPacoteCotacao}
                      handleExcluir={handleExcluirCotacao}
                      setClassToHighlight={setClassToHighlight}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} md={4} key={item.id}>
                    <CardInteligente
                      item={item}
                      handleConfigurarPacote={handleConfigurarPacoteInteligente}
                      handleSolicitarOrcamento={
                        handleSolicitarOrcamentoInteligente
                      }
                      handleVisualizarDetalhes={
                        handleVisualizarDetalhesInteligente
                      }
                      setClassToHighlight={setClassToHighlight}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </div>
        )}
        {loading && <LoadingGeral />}
        <Box
          mt={3}
          hidden={qtdAllDados === allDados.length || loading}
          textAlign="center"
        >
          <Button
            fullWidth
            onClick={handleProxPagina}
            variant="text"
            disabled={loading}
            loading={loading}
            endIcon={<MdKeyboardArrowDown />}
          >
            Carregar mais
          </Button>
        </Box>
        {!!mostrarDetalhe && itemSelecionado?.tipo === 'inteligente' && (
          <DrawerPacoteInteligenteVisualizarDetalhes
            id={itemSelecionado?.id}
            medico={true}
            open={true}
            configurado={itemSelecionado?.configurado}
            selecionado={itemSelecionado?.configurado}
            handleOnClose={handleFecharDetalhes}
          />
        )}

        {!!mostrarDetalhe && itemSelecionado?.tipo === 'cotacao' && (
          <Drawer
            loading={loadingDetalhes}
            anchor="right"
            open={true}
            titulo="Visualizar pacote"
            handleOnClose={handleFecharDetalhes}
          >
            {!!detalhesVisualizacao && !loadingDetalhes && (
              <ResumoPacote pacote={detalhesVisualizacao} />
            )}
          </Drawer>
        )}

        <DrawerCompartilharPacote
          pacote={pacoteSelecionado}
          open={openCompartilharPacote}
          handleOnClose={handleCloseCompartilharPacoteCotacao}
        />
      </UBox>
    </Box>
  )
}

export default ListagemPacoteUnificado

interface PropCardCotacao {
  item: ItemListagemPacoteUnificado
  setClassToHighlight: (item: ItemListagemPacoteUnificado) => string
  handleVisualizarDetalhes: (item: ItemListagemPacoteUnificado) => void
  handleSolicitarOrcamento: (item: ItemListagemPacoteUnificado) => void
  handleEditar: (item: ItemListagemPacoteUnificado) => void
  handleCompartilharPacote: (item: ItemListagemPacoteUnificado) => void
  handleExcluir: (item: ItemListagemPacoteUnificado) => void
}

function CardCotacao({
  item,
  setClassToHighlight,
  handleVisualizarDetalhes,
  handleSolicitarOrcamento,
  handleEditar,
  handleCompartilharPacote,
  handleExcluir
}: PropCardCotacao) {
  return (
    <Card variant="outlined" className={setClassToHighlight(item)}>
      <CardHeader
        style={{ paddingLeft: '8px' }}
        action={
          <PopupState variant="popover" popupId="demo-popup-menu">
            {popupState => (
              <React.Fragment>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  aria-label="mais"
                  {...bindTrigger(popupState)}
                >
                  <MoreVertIcon />
                </IconButton>

                <Menu
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  {...bindMenu(popupState)}
                >
                  {!item.revisarDados && (
                    <MenuItem
                      onClick={() => {
                        popupState.close()
                        handleVisualizarDetalhes(item)
                      }}
                    >
                      Visualizar
                    </MenuItem>
                  )}
                  <MenuItem
                    style={{ width: '200px' }}
                    onClick={() => {
                      popupState.close()
                      handleEditar(item)
                    }}
                  >
                    {item.revisarDados ? 'Revisar os dados' : 'Editar'}
                  </MenuItem>
                  {!item.revisarDados && (
                    <MenuItem
                      onClick={() => {
                        popupState.close()
                        handleSolicitarOrcamento(item)
                      }}
                    >
                      Solicitar orçamento
                    </MenuItem>
                  )}
                  {!item.revisarDados && (
                    <MenuItem
                      onClick={() => {
                        popupState.close()
                        handleCompartilharPacote(item)
                      }}
                    >
                      Compartilhar pacote
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      popupState.close()
                      handleExcluir(item)
                    }}
                    className="danger"
                  >
                    Excluir
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        }
        title={
          <Typography variant="body1" align="left">
            <Tooltip title="Pacote cotação">
              <Chip
                style={{
                  //  border: '1px solid #aaa',
                  background: 'none'
                }}
                label={
                  <BiNetworkChart
                    className="icon"
                    style={{
                      fontSize: '26px',
                      paddingTop: '6px',
                      color: '#1cc3a5'
                    }}
                  />
                }
                size="small"
              />
            </Tooltip>

            <Link
              component="button"
              variant="body1"
              color="primary"
              align="left"
              className="titulo-card"
              onClick={() =>
                item.revisarDados
                  ? handleEditar(item)
                  : handleVisualizarDetalhes(item)
              }
            >
              <b style={{ textTransform: 'uppercase' }}>{item.descricao}</b>
              {/* {item.descricao} */}
            </Link>
          </Typography>
        }
        titleTypographyProps={{ variant: 'subtitle1' }}
        subheaderTypographyProps={{ variant: 'body2' }}
      />

      <CardContent
        style={{
          borderTop: '1px solid #eee',
          paddingTop: 3,
          position: 'relative'
        }}
      >
        <Box mt={0}>
          <Typography color="primary" variant="subtitle2">
            Informação do pacote
          </Typography>{' '}
        </Box>
        <Box mt={0}>
          <Typography variant="body2">
            Honorário da equipe: {formatarDinheiro(item.honorarioEquipeMedica)}
          </Typography>
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        <Box>
          <Box mt={0}>
            <Typography variant="body2" noWrap style={{ display: 'inline' }}>
              <Link
                component="button"
                variant="body2"
                color="primary"
                align="left"
                onClick={() => handleVisualizarDetalhes(item)}
              >
                Visualizar
              </Link>
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" noWrap style={{ display: 'grid' }}>
              <Link
                component="button"
                variant="body2"
                style={{
                  color: 'orange'
                }}
                align="left"
                onClick={() => handleEditar(item)}
              >
                Editar
              </Link>
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" noWrap style={{ display: 'grid' }}>
              <Link
                component="button"
                variant="body2"
                style={{
                  color: 'green'
                }}
                align="left"
                onClick={() => handleSolicitarOrcamento(item)}
              >
                Solicitar orçamento
              </Link>
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" noWrap style={{ display: 'grid' }}>
              <Link
                component="button"
                variant="body2"
                color="primary"
                align="left"
                onClick={() => handleCompartilharPacote(item)}
              >
                Compartilhar pacote
              </Link>
            </Typography>
          </Box>
        </Box>
        {item.revisarDados && (
          <>
            <Box mb={2}>
              <Divider />
            </Box>
            <div>
              <Chip
                label="Pacote compartilhado"
                size="small"
                style={{
                  color: '#fff',
                  backgroundColor: amber[700]
                }}
              />
              <br />
              <Typography variant="caption">
                Antes de utilizar é necessário clicar no pacote e revisar os
                dados.
              </Typography>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  )
}

interface PropCardInteligente {
  item: ItemListagemPacoteUnificado
  setClassToHighlight: (item: ItemListagemPacoteUnificado) => string
  handleVisualizarDetalhes: (item: ItemListagemPacoteUnificado) => void
  handleConfigurarPacote: (item: ItemListagemPacoteUnificado) => void
  handleSolicitarOrcamento: (item: ItemListagemPacoteUnificado) => void
}

function CardInteligente({
  item,
  setClassToHighlight,
  handleConfigurarPacote,
  handleVisualizarDetalhes,
  handleSolicitarOrcamento
}: PropCardInteligente) {
  function expirado(data) {
    if (!!data) {
      var hoje = moment()
      var dataExpiracao = moment(data)
      var expirado = dataExpiracao <= hoje
      return expirado
    }
    return false
  }
  return (
    <Card
      variant="outlined"
      className={setClassToHighlight(item)}
      style={{ background: expirado(item?.dataExpiracao) ? '#ffe0e0' : '#fff' }}
    >
      <CardHeader
        style={{ paddingLeft: '8px' }}
        action={
          <PopupState variant="popover" popupId="demo-popup-menu">
            {popupState => <></>}
          </PopupState>
        }
        title={
          <Typography variant="body1" align="left">
            <Tooltip title="Pacote instantâneo">
              <Chip
                style={{
                  //  border: '1px solid #aaa',
                  background: 'none'
                }}
                label={
                  <AiTwotoneThunderbolt
                    className="icon"
                    style={{
                      fontSize: '26px',
                      paddingTop: '6px',
                      color: '#ebb852'
                    }}
                  />
                }
                size="small"
              />
            </Tooltip>

            <Link
              component="button"
              variant="body1"
              color="primary"
              align="left"
              onClick={() =>
                item.configurado
                  ? handleVisualizarDetalhes(item)
                  : handleConfigurarPacote(item)
              }
            >
              <b style={{ textTransform: 'uppercase' }}>{item.descricao}</b>
              <UBox>
                <Typography variant="body2" color="secondary">
                  <b>{item.hospital}</b>
                </Typography>
                <Tooltip
                  title={
                    item.status === 1
                      ? 'Disponivel para solicitações'
                      : 'Necessário revisar itens de OPME'
                  }
                >
                  <Box
                    mr={1}
                    // '#20a773'
                    style={{
                      width: '40px',
                      height: '6px',
                      background: item.status === 1 ? '#1cc3a5' : '#ff9800',
                      borderRadius: '6px'
                    }}
                  ></Box>
                </Tooltip>
              </UBox>
            </Link>
          </Typography>
        }
        titleTypographyProps={{ variant: 'subtitle1' }}
        subheaderTypographyProps={{ variant: 'body2' }}
      />
      <CardContent style={{ borderTop: '1px solid #eee' }}>
        <UBox mb={1}>
          <Typography
            variant="body2"
            color="primary"
            style={{
              color: !expirado(item?.dataExpiracao) ? '#455880' : '#970c16'
            }}
          >
            <small>
              <b>Data de expiração:</b>
            </small>
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            style={{
              color: !expirado(item?.dataExpiracao) ? '#455880' : '#970c16',
              fontWeight: !expirado(item?.dataExpiracao) ? 400 : 600
            }}
          >
            {!!item.dataExpiracao
              ? formatarData(item.dataExpiracao)
              : 'sem expiração'}
          </Typography>
        </UBox>
        <UBox mb={2}>
          <Typography variant="body2" color="primary">
            <small>
              <b>Honorário da equipe médica:</b>
            </small>
          </Typography>
          <Typography variant="body2" color="primary">
            {formatarDinheiro(item.honorarioEquipeMedica)}
          </Typography>
        </UBox>
        <UBox render={item?.configurado && item?.status === 1}>
          <Box mt={0}>
            <Typography variant="body2" noWrap style={{ display: 'inline' }}>
              <Link
                component="button"
                variant="body2"
                color="primary"
                align="left"
                onClick={() => handleVisualizarDetalhes(item)}
              >
                Visualizar
              </Link>
            </Typography>
          </Box>
          <UBox render={!expirado(item?.dataExpiracao)}>
            <Typography variant="body2" noWrap style={{ display: 'grid' }}>
              <Link
                component="button"
                variant="body2"
                // style={{
                //   color: 'green'
                // }}
                align="left"
                onClick={() => handleSolicitarOrcamento(item)}
              >
                Solicitar orçamento
              </Link>
            </Typography>
          </UBox>
          <UBox mt={0} render={!expirado(item?.dataExpiracao)}>
            <Link
              component="button"
              variant="body2"
              align="left"
              onClick={() => handleConfigurarPacote(item)}
            >
              Reconfigurar
            </Link>
          </UBox>
        </UBox>
        <UBox render={item.status === 2}>
          <Alert severity="warning">Aguardando revisão dos itens de OPME</Alert>
        </UBox>
        <UBox render={!item?.configurado}>
          <Typography
            color="secondary"
            variant="body1"
            style={{
              color: item?.configurado ? '#1cc3a5' : 'orange'
            }}
          >
            <UBox
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                color: item?.configurado ? '#1cc3a5' : 'orange'
              }}
            >
              <BsInfoCircle />

              <small style={{ marginLeft: '4px', display: 'block' }}>
                Pendente de configuração
              </small>
            </UBox>
            <small style={{ fontSize: '0.8rem' }}>
              Antes de utilizar é necessário clicar no pacote e revisar os
              dados.
            </small>
            {/* <br /> */}
          </Typography>
          <Link
            component="button"
            variant="body2"
            align="left"
            onClick={() => handleConfigurarPacote(item)}
            style={{
              marginTop: '12px'
            }}
          >
            Configurar agora
          </Link>
        </UBox>
      </CardContent>
    </Card>
  )
}
