import { useEffect, useState } from 'react';
import Drawer from 'src/components/ui/Drawer';
import { IUsuario, ObjetoDescricaoGenerico } from 'src/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, IconButton } from '@material-ui/core';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import yup from 'src/components/yupCustom';
import { onlyNumber, verificarCPF } from 'src/core/helpers/common';
import TextField from 'src/components/ui/TextField';
import TextFieldMU from '@material-ui/core/TextField';
import Button from 'src/components/ui/Button';
import { obterSetoresInternos, obterTenants } from 'src/api/dominio';
import Swal from 'sweetalert2';
import { salvarUsuarioInterno } from 'src/api/usuarioInterno';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { J } from 'chart.js/dist/chunks/helpers.core';
import AutocompleteMU from '@material-ui/lab/Autocomplete';
import { Autocomplete } from 'src/components/ui/Autocomplete';
import { showNotificationErrorAPI } from 'src/core/helpers/notification';

type Props = {
  item: IUsuario,
  open: boolean,
  handleOnClose: () => void,
  handleFecharSalvar: () => void
}

const contactSchema = yup.object().shape({
  id: yup.string().nullable(),
  nome: yup.string().required().label('Nome do responsável'),
  telefone: yup.string().min(11).label('Telefone'),
  email: yup.string().email().required().label('E-mail'),
  cpf: yup.string().test('cpf-teste', 'CPF inválido', value => {
    if (!value) return false;
    return verificarCPF(onlyNumber(value));
  }),
  usuariosPerfis: yup.array(
    yup.object({
      tenantId: yup.string().required(),
      perfis: yup.array().nullable().min(1).label('Setor').test('setores-validacoes', 'Setor inválido', value => {
        if (!value || value?.length <= 0) return false
        return true
      })
    })
  ).required()
});

function DrawerSalvarUsuario({ item, open, handleOnClose, handleFecharSalvar }: Props) {
  const [loadingSubmitButton, setLoadingSubmitButton] = useState(false);
  const [setores, setSetores] = useState<ObjetoDescricaoGenerico[]>([]);
  const [tenants, setTenants] = useState<ObjetoDescricaoGenerico[]>([]);
  const [loadingSetores, setLoadingSetores] = useState(false);
  const [loadingTenants, setLoadingTenants] = useState(false);

  const edicao = !!item

  const defaultValuesContato: IUsuario = {
    id: item?.id || null,
    nome: item?.nome || '',
    telefone: item?.telefone || '',
    cpf: item?.cpf || '',
    email: item?.email || '',
    usuariosPerfis: item?.usuariosPerfis || [{ tenantId: '', perfis: [] }]
  };

  if (item?.perfisTenant) {
    defaultValuesContato.usuariosPerfis = item?.perfisTenant?.map(p => ({
      tenantId: p.tenantId,
      perfis: p.perfis.map((itemPerfil) => ({ id: itemPerfil.idPerfil }))
    }));
  }

  const { watch, register, setValue, errors, control, handleSubmit, reset } = useForm<IUsuario>({
    mode: 'onChange',
    resolver: yupResolver(contactSchema),
    defaultValues: defaultValuesContato
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'usuariosPerfis'
  });

  const command = watch();

  useEffect(() => {
    carregarSetores();
    carregarTenants();
  }, []);

  async function carregarSetores() {
    setLoadingSetores(true);
    return obterSetoresInternos()
      .then(response => setSetores(response.data))
      .finally(() => setLoadingSetores(false));
  }

  async function carregarTenants() {
    setLoadingTenants(true);
    return obterTenants()
      .then(response => setTenants(response.data))
      .finally(() => setLoadingTenants(false));
  }

  const onSubmit = async (data: IUsuario) => {
    setLoadingSubmitButton(true);
    var funcionario = JSON.parse(JSON.stringify(data));
    const { telefone, cpf, usuariosPerfis, ...rest } = funcionario;
    const newTelefone = onlyNumber(telefone);
    const newCpf = onlyNumber(cpf);
    const usuariosPerfisFormatted = usuariosPerfis.map(up => ({
      tenantId: up.tenantId,
      perfisId: up.perfis.map(p => p.id)
    }));
    var payload = {
      ...rest,
      telefone: newTelefone,
      cpf: newCpf,
      usuariosPerfis: usuariosPerfisFormatted
    };
    salvarUsuarioInterno(payload)
      .then(() => {
        Swal.fire({
          title: 'Parabéns!',
          text: 'O usuário foi salvo com sucesso.',
          icon: 'success',
          confirmButtonText: 'Continuar'
        }).then(() => {
          handleFecharSalvar();
          setLoadingSubmitButton(false);
        });
      })
      .catch(err => {
        showNotificationErrorAPI(err);
        setLoadingSubmitButton(false);
      });
  }

  useEffect(() => {
    register('usuariosPerfis')
    register('id')
  }, [register])

  return (
    <Drawer
      anchor="right"
      open={open}
      titulo="Salvar usuário"
      handleOnClose={handleOnClose}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }} noValidate={true}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              erro={errors}
              name="nome"
              placeholder="Nome..."
              label="Nome"
              inputRef={register}
              control={control}
              value={command.nome}
              InputProps={{ autoComplete: 'new-password' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              maskTelCel
              style={{ marginTop: 0 }}
              control={control}
              erro={errors}
              name="telefone"
              value={command.telefone}
              placeholder="Telefone..."
              label="Telefone"
              InputProps={{ autoComplete: 'new-password' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              style={{ marginTop: 0 }}
              erro={errors}
              name="cpf"
              placeholder="CPF..."
              label="CPF"
              mask="999.999.999-99"
              disabled={!!command.id || edicao}
              value={command.cpf}
              control={control}
              InputProps={{ autoComplete: 'new-password' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ marginTop: 0 }}
              erro={errors}
              name="email"
              placeholder="E-mail..."
              label="E-mail"
              inputRef={register}
              value={command.email}
              control={control}
              InputProps={{ autoComplete: 'new-password' }}
            />
          </Grid>
          {fields.map((field, index) => (

            <Grid container item xs={12} spacing={1} key={field.id}>

              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name={`usuariosPerfis.${index}.tenantId`}
                  render={(props) =>
                    <>
                      <AutocompleteMU
                        key={JSON.stringify(tenants)}
                        options={tenants}
                        renderInput={params => (
                          <TextFieldMU {...params} variant="outlined" label="Tenants" />
                        )}
                        loading={loadingTenants}
                        getOptionSelected={option => option?.id === props.value}
                        getOptionLabel={option => option?.descricao}
                        onChange={(event, newValue: ObjetoDescricaoGenerico) => {
                          setValue(`usuariosPerfis.${index}.tenantId`, newValue?.id, { shouldValidate: true });
                        }}
                        value={tenants?.find(option => option.id === props.value)}
                      />
                      {errors?.usuariosPerfis?.[index]?.tenantId && <span style={{ color: 'red' }}>Deve selecionar um tenant</span>}
                    </>
                  }
                />

              </Grid>

              <Grid item xs={12} sm={5}>
                <Controller
                  control={control}
                  name={`usuariosPerfis.${index}.perfis`}
                  render={(props) =>
                    <>
                      {setores?.length > 0 && (
                        <Box mt={-2}>
                          <Autocomplete
                            style={{ marginTop: 0 }}
                            key={JSON.stringify(setores)}
                            multiple
                            erro={errors}
                            name="perfis"
                            label="Perfis"
                            value={setores?.filter(setor => props.value?.find(perfil => perfil?.id === setor?.id))}
                            getOptionSelected={option => !!props.value?.find(perfil => perfil?.id === option?.id)}
                            options={setores}
                            loading={loadingSetores}
                            showCheck
                            getOptionLabel={option => option.descricao}
                            onChange={(event, newValue: ObjetoDescricaoGenerico[]) => {
                              setValue(`usuariosPerfis.${index}.perfis`, newValue, { shouldValidate: true });
                            }}
                          />
                          {errors?.usuariosPerfis?.[index]?.perfis && <span style={{ color: 'red' }}>Deve selecionar um perfil</span>}
                        </Box>
                      )}
                    </>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={1} style={{ display: 'flex', alignItems: 'baseline' }}>
                <IconButton onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>

            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              startIcon={<AddIcon />}
              onClick={() => append({ tenantId: '', perfis: [] })}
            >
              Adicionar Tenant/Perfis
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                loading={loadingSubmitButton}
              >
                Salvar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Drawer>
  );
}

export default DrawerSalvarUsuario;
