import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import {
  Alert
} from '@material-ui/lab'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { salvarAgendamento } from 'src/api/agendamento'
import Button from 'src/components/ui/Button'
import Drawer from 'src/components/ui/Drawer'
import HourInput from 'src/components/ui/HourInput'
import TextField from 'src/components/ui/TextField'
import UBox from 'src/components/ui/Ubox'
import yup from 'src/components/yupCustom'
import { statusOrcamento } from 'src/core/consts/statusSolicitacao'
import {
  formatarDataLocal,
  formatarHoraLocal
} from 'src/core/formatacoes/formatarData'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import {
  IAgedamentoResponse,
  ItemListagemSolicitacaoOrcamentoResponse
} from 'src/types'
import { isHolidayOrWeekend } from 'src/api/feriados'

type Props = {
  open: boolean
  handleFecharAgendamento: (agendado: IAgedamentoResponse) => void
  handleOnClose: () => void
  solicitacaoItem?: ItemListagemSolicitacaoOrcamentoResponse
}

export interface IAgendamentoForm {
  dataProcedimento: string
  dataAgendamento?: string
  dataPreAgendamento?: string
  horaAgendamento: string
  observacaoAgendamento: string
}

function DrawerAgendamento({
  open,
  handleOnClose,
  solicitacaoItem,
  handleFecharAgendamento
}: Props) {
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))
  const [loading, setLoading] = useState(false)

  const schema = yup.object().shape({
    dataProcedimento: yup
      .string()
      .required('Data é obrigatória')
      .test('data-valida', solicitacaoItem.propostaVencedoraFinalDeSemana ? 'Por favor escolha uma data válida para finais de semana ou feriados' : 'Por favor escolha uma data válida', function (value) {
        const time = this.parent.horaAgendamento;
        if (!value || value.replace(' ', '').length < 10) return false;

        const dateIsValid = moment(value, 'DD/MM/YYYY').isAfter(moment(), 'day');
        if (!dateIsValid) return false;

        if (solicitacaoItem.propostaVencedoraFinalDeSemana) {
          return isHolidayOrWeekend(value, time);
        }
        return true;
      })
      .label('Data de agendamento'),
    horaAgendamento: yup
      .string()
      .required()
      .test('hora-valida', solicitacaoItem.propostaVencedoraFinalDeSemana ? 'Por favor escolha um horário válido para finais de semana ou feriados' : 'Por favor escolha um horário válido', function (value) {
        const date = this.parent.dataProcedimento;
        if (!date || date.replace(' ', '').length < 10) return false;
        if (solicitacaoItem.propostaVencedoraFinalDeSemana) {
          return isHolidayOrWeekend(date, value);
        }
        return true;
      })
      .label('Horário de agendamento'),
    observacaoAgendamento: yup.string().label('Observações do agendamento')
  })

  const {
    errors,
    watch,
    control,
    register,
    handleSubmit,
    setValue
  } = useForm<IAgendamentoForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      dataProcedimento: '',
      horaAgendamento: '',
      observacaoAgendamento: ''
    }
  })
  async function verificarEdicao() {
    if (!solicitacaoItem?.agendamento) return

    var preAgendamento = [
      statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id,
      statusOrcamento.PAGAMENTO_PARCIAL.Id
    ].includes(solicitacaoItem.status.id)

    const dataHora = moment(
      preAgendamento
        ? solicitacaoItem?.agendamento?.dataPreAgendamento
        : solicitacaoItem?.agendamento?.dataAgendamento || solicitacaoItem?.agendamento?.dataPreAgendamento
    )
    const dataEdicao = formatarDataLocal(dataHora)
    const horaEditada = formatarHoraLocal(dataHora)

    setValue('dataProcedimento', dataEdicao)
    setValue('horaAgendamento', horaEditada)
    setValue('observacaoAgendamento', solicitacaoItem?.agendamento?.observacao)
  }

  const command = watch()
  const onSubmit = async (data: IAgendamentoForm) => {
    setLoading(true)
    try {
      var dataAgendamento = moment(
        `${data.dataProcedimento} ${data.horaAgendamento}`,
        'DD/MM/YYYY HH:mm'
      )
      const payload = {
        id: !solicitacaoItem?.agendamento
          ? null
          : solicitacaoItem.agendamento?.id,
        solicitacaoOrcamentoId: solicitacaoItem.id,
        observacao: data.observacaoAgendamento,
        dataAgendamento: dataAgendamento.toISOString()
      }

      var response = await salvarAgendamento(payload)
      payload.id = response.data
      toast.success('Agendamento realizado com sucesso!')
      payload.dataAgendamento = payload.dataAgendamento.slice(0, payload.dataAgendamento.length - 1)
      handleFecharAgendamento(payload)
    } catch (error) {
      setLoading(false)
      showNotificationErrorAPI(error)
    }
  }
  const handleItemHoraValue = (name: keyof IAgendamentoForm, item) => {
    setValue(name, item, {
      shouldValidate: true
    })
  }

  useEffect(() => {
    register('dataProcedimento')
    register('horaAgendamento')
    register('observacaoAgendamento')
  }, [register])

  useEffect(() => {
    verificarEdicao()
  }, [])

  return (
    <Drawer
      anchor="right"
      open={open}
      titulo={
        solicitacaoItem?.status.id === statusOrcamento.PAGAMENTO_PARCIAL.Id ||
          solicitacaoItem?.status.id ===
          statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id
          ? 'Realizar pré agendamento'
          : 'Realizar Agendamento'
      }
      handleOnClose={handleOnClose}
    >
      <Box mt={3}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {solicitacaoItem.propostaVencedoraFinalDeSemana && (
            <Alert severity="warning">Solicitação disponivel apenas para finais de semana (sábado a partir de 12h até domingo 23h59 e feriados).</Alert>
          )}
          <Typography variant="subtitle1" style={{ marginBottom: '1em' }}>
            {`Insira as informações para ${solicitacaoItem?.status.id ===
              statusOrcamento.PAGAMENTO_PARCIAL.Id ||
              solicitacaoItem?.status.id ===
              statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id
              ? 'pré agendar'
              : 'agendar'
              } o procedimento.`}
          </Typography>
          <UBox style={{ display: 'flex', gap: '12px' }}>
            <Grid item xs={12} md={6}>
              <TextField
                erro={errors}
                control={control}
                name="dataProcedimento"
                label={
                  solicitacaoItem?.status.id ===
                    statusOrcamento.PAGAMENTO_PARCIAL.Id ||
                    solicitacaoItem?.status.id ===
                    statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id
                    ? 'Data de pré agendamento *'
                    : 'Data de agendamento *'
                }
                mask="99/99/9999"
                value={command.dataProcedimento}
                defaultValue={command.dataProcedimento}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <HourInput
                errors={errors}
                name="horaAgendamento"
                label="Horário"
                value={command.horaAgendamento}
                control={control}
                handleChange={value => {
                  handleItemHoraValue('horaAgendamento', value)
                }}
              />
            </Grid>
          </UBox>

          <UBox>
            <TextField
              erro={errors}
              control={control}
              name="observacaoAgendamento"
              multiline={true}
              rows={4}
              value={command.observacaoAgendamento}
              defaultValue={command.observacaoAgendamento}
              label="Observações"
              inputProps={{ maxLength: 500 }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </UBox>

          <Box mt={3}>
            <Button
              fullWidth
              type="submit"
              loading={loading}
              disabled={loading}
              secondaryLoading
            >
              {solicitacaoItem?.status.id ===
                statusOrcamento.PAGAMENTO_PARCIAL.Id ||
                solicitacaoItem?.status.id ===
                statusOrcamento.AGUARDANDO_CONFIRMACAO_PAGAMENTO.Id
                ? 'Pré agendar'
                : 'Agendar'}
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  )
}

export default DrawerAgendamento
