import {
  ISimulacaoFinanciamento,
  SimulacaoFavorecidosResponse,
  SimulacaoFinanciamentoResponse,
  SimulacaoResumoFinanciamentoResponse
} from 'src/types'
import api from 'src/services/api'
import { AxiosResponse } from 'axios'
import { IFormFinanciamento } from 'src/pages/dashboard/paciente/financiamento/financiamentoSteps'

export interface IPreAnaliseResponse {
  id: string
  idProposta: string
  criado: string
  status: number
  solicitacaoOrcamentoId: string
  nome: string
  uf: string
  dataNascimento: string
  cpf: string
  telefone: string
  valorTotalFinanciamento: number
  renda: number
}

export async function cadastrarPreFinanciamento(
  command: IFormFinanciamento
): Promise<AxiosResponse<IPreAnaliseResponse>> {
  return api.post('/financiamento/cadastrarPreAnaliseFinanciamento', command)
}

export interface ISimulacaoCommand {
  Cpf: string
  Uf: string
  DataNascimento: string
  ValorSolicitado: number
  SolicitacaoFinanciamentoId?: string
}

export interface ListaStatusRespostaServico {
  codigo: number
  mensagem: string
  criticidade: string
  sistemaOrigem: string
  servicoOrigem: string
}

export interface CustoFinanciamento {
  codigo: number
  descricao: string
}

export interface ListaDadosCustoFinanciamento {
  custoFinanciamento: CustoFinanciamento
  aliquota: number
  valorCusto: number
  aplicaCalculoPmt: boolean
  somaCusto: boolean
}

export interface ParcelaBV {
  quantidadeParcelas: number
  elegivel: boolean
  valorParcelaComSeguro: number
  valorParcelaSemSeguro: number
  coeficiente: number
  taxaNet: number
  taxaFinanciamentoMensal: number
  taxaFinanciamentoAnual: number
  valorPercentualCetAnual: number
  valorPercentualCetMensal: number
  valorJurosPrimeiraParcela: number
  valorLiberado: number
  valorFinanciamentoComSeguro: number
  valorFinanciamentoSemSeguro: number
  valorIof: number
  valorTotalCusto: number
  listaSegurosPrestamista: any[]
  listaDadosCustoFinanciamento: ListaDadosCustoFinanciamento[]
}

export interface SimulacaoResponse {
  listaStatusRespostaServico: ListaStatusRespostaServico[]
  codigoSimulacaoProposta: number
  indicadorRestricaoCredito: boolean
  codigoTabelaFinanciamento: string
  dataMinimaCarenciaFinanciamento: string
  dataMaximaCarenciaFinanciamento: string
  simulacaoInteligente: boolean
  valorMaximoDeParcelaPermitido: number
  numeroMaximoDeParcelasPermitidas: number
  listaParcelas: ParcelaBV[]
  listaPrazoNaoCalculado: any[]
  valorMaximoFinanciado: number
  existeValorParcial: boolean
}

export async function simularCondicoes(
  command: ISimulacaoCommand
): Promise<AxiosResponse<SimulacaoResponse>> {
  return api.post('/financiamento/simularCondicoes', command)
}

export async function originarProposta(
  command: IFormFinanciamento,
  qtdParcelas: number,
  solicitacaoFinanciamentoId: string
): Promise<AxiosResponse<any>> {
  var body = {
    ...command,
    sexo: command.sexo === 1 ? 'M' : 'F',
    tipoProfissao: command?.tipoProfissao?.codigo,
    profissao: command?.profissao?.codigo,
    renda: command?.renda / 100,
    patrimonio: command?.patrimonio / 100,
    valorTotalFinanciamento: command?.valorTotalFinanciamento / 100,
    qtdParcelas,
    solicitacaoFinanciamentoId
  }

  return api.post('/financiamento/originarProposta', body)
}

export const obterSolicitacaoFinanciamento: (
  perfilAtual
) => Promise<AxiosResponse<SimulacaoFinanciamentoResponse[]>> = perfilAtual => {
  return api.get('/financiamento?perfilAtual=' + perfilAtual)
}

export interface AlterarStatusSolicitacaoFinanciamentoCommand {
  id: string
  status: number
}

export const alterarStatusSolicitacaoFinanciamento: (
  command: AlterarStatusSolicitacaoFinanciamentoCommand
) => Promise<AxiosResponse<void>> = command => {
  return api.post('/financiamento/alterarStatus', command)
}

export const obterSolicitacaoFinanciamentoDetalhado: (
  id: string
) => Promise<AxiosResponse<SimulacaoResumoFinanciamentoResponse>> = id => {
  return api.get(`/financiamento/obterResumoSimulacaoDetalhado/${id}`)
}
export const obterFavorecidosBV: (
  id: string
) => Promise<AxiosResponse<SimulacaoFavorecidosResponse[]>> = id => {
  return api.get(`/financiamento/obterFavorecidosBV/${id}`)
}
