import React from 'react'
import Drawer from 'src/components/ui/Drawer'
import Resumo from './resumo'
import { IParceiro } from 'src/types'

interface DrawerVisualizarParceiros {
  open: boolean
  parceiro: IParceiro
  handleOnClose: () => void
}

function DrawerCompartilharPacote(props: DrawerVisualizarParceiros) {
  return !props.parceiro ? (
    <></>
  ) : (
    <Drawer
      anchor="right"
      open={props.open}
      titulo="Parceiro"
      handleOnClose={props.handleOnClose}
    >
      <Resumo parceiro={props.parceiro} />
    </Drawer>
  )
}

export default DrawerCompartilharPacote
