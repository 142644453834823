import { Box, Grid, Link, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useEffect, useState } from 'react'
import { BuscarMedicoPorNome, obterMedicos } from 'src/api/medico'
import Drawer from 'src/components/ui/Drawer'
import {
  showNotification,
  showNotificationErrorAPI
} from 'src/core/helpers/notification'
import {
  CompartilharPacoteProcedimentoCommand,
  ItemListagemPacoteProcedimentoResponse,
  ResumoMedicoResponse
} from 'src/types'
import { useDebouncedCallback } from 'use-debounce/lib'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import Button from 'src/components/ui/Button'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { useHistory } from 'react-router-dom'
import { compartilharPacote } from 'src/api/pacote'

interface DrawerCompartilharPacoteProps {
  pacote: ItemListagemPacoteProcedimentoResponse
  open: boolean
  handleOnClose: () => void
}

function DrawerCompartilharPacote(props: DrawerCompartilharPacoteProps) {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [loadingSalvar, setLoadingSalvar] = useState(false)
  const [options, setOptions] = useState<ResumoMedicoResponse[]>([])
  const [termoPesquisa, setTermoPesquisa] = useState('')
  const [medicoSelecionado, setMedicoSelecionado] = useState<
    ResumoMedicoResponse[]
  >([])
  const buscarMedicoDebounced = useDebouncedCallback(buscarMedico, 1200)

  const [listaMedicos, setListaMedicos] = useState<ResumoMedicoResponse[]>([])

  const termoPesquisaMinLength = 3

  useEffect(() => {
    carregarMedicos()
  }, [])

  useEffect(() => {
    if (!termoPesquisaValido()) {
      setLoading(false)
      return
    }
    setLoading(true)
    buscarMedicoDebounced.callback()
  }, [termoPesquisa])

  async function buscarMedico() {
    try {
      if (!termoPesquisaValido()) {
        return
      }
      const response = await BuscarMedicoPorNome(termoPesquisa)
      setOptions(response.data)
    } catch (error) {
      showNotificationErrorAPI(error)
    }
    setLoading(false)
  }

  const carregarMedicos = async () => {
    return obterMedicos()
      .then(response => {
        setListaMedicos(response.data)
      })
      .finally(() => {
        setLoadingSalvar(false)
      })
  }

  async function handleCompartilhar() {
    try {
      if (!medicoSelecionado || !props.pacote) {
        return
      }

      setLoadingSalvar(true)
      var command: CompartilharPacoteProcedimentoCommand = {
        id: props.pacote.id,
        medicoDestinatario: medicoSelecionado
      }
      await compartilharPacote(command)
      showNotification(
        'Pacote compartilhado com sucesso!',
        'Em breve a pessoa receberá uma notificação do seu compartilhamento.',
        'success'
      )
      props.handleOnClose()
    } catch (error) {
      showNotificationErrorAPI(error)
    }
    setLoadingSalvar(false)
  }

  function termoPesquisaValido() {
    return termoPesquisa && termoPesquisa.length >= termoPesquisaMinLength
  }

  const medicoNaoEncontrado = !termoPesquisaValido()
    ? `Digite ao menos ${termoPesquisaMinLength} letras.`
    : 'Nenhum médico cadastrado com esse nome'

  return !props.pacote ? (
    <></>
  ) : (
    <Drawer
      anchor="right"
      open={props.open}
      titulo="Compartilhar pacote"
      handleOnClose={props.handleOnClose}
    >
      <Box mt={2} mb={3}>
        Escolha abaixo com qual médico deseja compartilhar o pacote:
        <Typography variant="subtitle1">
          <strong>{props.pacote.descricao}</strong>
        </Typography>
      </Box>
      <Autocomplete
        multiple
        loading={loadingSalvar}
        id="tags-standard"
        options={listaMedicos}
        getOptionLabel={option => option.nome}
        onChange={(event, newValue) => {
          setMedicoSelecionado(newValue as ResumoMedicoResponse[])
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label="Selecione o Médico"
            placeholder="Médicos"
          />
        )}
      />
      {!medicoSelecionado && termoPesquisaValido() && !loading && (
        <Box mt={10}>
          <Typography variant="subtitle2">Não encontrou?</Typography>
          <Grid container spacing={3}>
            <Grid item>
              <Box textAlign="right">
                <RecordVoiceOverIcon fontSize="large" />
              </Box>
            </Grid>
            <Grid item xs>
              <Typography variant="body2">
                <Link
                  href="#"
                  component="button"
                  onClick={() => history.push(SynRoutes.convidarColega)}
                >
                  Clique aqui
                </Link>{' '}
                para convidar os médicos colegas para fazerem parte da
                plataforma SYN.
              </Typography>
              {/* <Box mt={2}>
          <Link>Convidar colega</Link>
          </Box> */}
            </Grid>
          </Grid>
        </Box>
      )}

      {!!medicoSelecionado && (
        <Box mt={3}>
          <Button
            fullWidth
            loading={loadingSalvar}
            disabled={loadingSalvar}
            onClick={handleCompartilhar}
          >
            Compartilhar pacote
          </Button>
        </Box>
      )}
    </Drawer>
  )
}

export default DrawerCompartilharPacote
