/* Página não utilizada */
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Typography } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { resetarSenha } from 'src/api/auth'
import { Button } from 'src/components/ui/Button'
import TextField from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import { onlyNumber, verificarCPF } from 'src/core/helpers/common'
import {
  showNotification,
  showNotificationErrorAPI
} from 'src/core/helpers/notification'
import { ResetarSenhaCommand } from 'src/types'
import Swal from 'sweetalert2'

const useStyles = makeStyles(theme => ({
  inputIcon: { color: theme.palette.grey[500] },
  form: { width: '100%' },
  // formControl: {
  //   minWidth: 250,
  //   marginTop: 15
  // },
  selectEmpty: {
    marginTop: theme.spacing(1)
  },
  subTitle: {
    padding: theme.spacing(3, 0, 0, 0)
  },
  noMargin: {
    margin: theme.spacing(0)
  }
}))

const schema = yup.object().shape({
  cpf: yup
    .string()
    .required()
    .test('cpf-teste', 'CPF inválido', value => {
      if (!value) return false

      return verificarCPF(onlyNumber(value))
    })
    .label('CPF')
})

export interface IResetSenha {
  cpf: string
}

export default function ResetSenha() {
  const classes = useStyles()

  const [loading, setLoading] = useState<boolean>(false)

  const {
    watch,
    register,
    setValue,
    errors,
    control,
    handleSubmit,
    reset
  } = useForm<IResetSenha>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      cpf: ''
    }
  })
  const command = watch()

  useEffect(() => {
    register('cpf')
  }, [register])

  const onSubmit = async (data: IResetSenha) => {
    setLoading(true)
    try {
      let command: ResetarSenhaCommand = { cpf: data.cpf }

      var response = await resetarSenha(command)
      Swal.fire('A senha do usuário foi resetada com sucesso!', '', 'success')
      reset()
      setLoading(false)
    } catch (erro) {
      setLoading(false)
      showNotificationErrorAPI(erro)
    }
  }

  return (
    <div>
      <CssBaseline />
      <Grid item xs={12}>
        <Typography variant="h5">Resetar senha de usuário</Typography>
      </Grid>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
        noValidate={true}
      >
        <Grid container spacing={1} direction="row">
          <Grid item xs={12} sm={4}>
            <Box mt={3} mb={2}>
              <Alert severity="info">
                A senha atual do usuário será substituída pelos 6 primeiros
                digitos do seu CPF.
              </Alert>
            </Box>
            <TextField
              erro={errors}
              name="cpf"
              placeholder="Ex: 999.999.999-99"
              label="CPF"
              hiddenLabel
              control={control}
              mask="999.999.999-99"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: '24px' }}>
          <Grid item xs={12}>
            <Button
              loading={loading}
              disabled={loading}
              type="submit"
              variant="contained"
              color="primary"
            >
              Resetar senha
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}
