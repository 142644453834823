import React, { useEffect, useRef, useState } from 'react'
import Drawer from 'src/components/ui/Drawer'
import {
  FormControl,
  Typography,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress
} from '@material-ui/core'
import estados from 'src/core/consts/estados'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import yup from 'src/components/yupCustom'
import Button from 'src/components/ui/Button'
import { Autocomplete } from 'src/components/ui/Autocomplete'
import {
  obterUfsAtendimento,
  salvarUfsAtendimento
} from '../../../../api/medico'
import { showNotificationErrorAPI } from '../../../../core/helpers/notification'
import Swal from 'sweetalert2'
import useAuth from 'src/hooks/useAuth'
import { SynPerfis, SynPerfisArray } from 'src/core/consts/synPerfis'

interface Props {
  open: boolean
  handleOnClose: () => void
  medicoId: string
}

const schema = yup.object().shape({
  uf: yup.array().required().label('UFs')
})

export default function DrawerDefinirUf({
  open,
  handleOnClose,
  medicoId
}: Props) {
  const [loading, setLoading] = useState(false)
  const { signed, user, perfilAtual, signOut, setPerfilAtual } = useAuth()
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const { register, handleSubmit, setValue, errors, watch, reset } = useForm({
    resolver: yupResolver(schema)
  })
  const command = watch()

  async function carregarUfsMedico() {
    setLoading(true)

    try {
      const response = await obterUfsAtendimento(medicoId)

      const ufs = response.data.map(item => {
        const uf = estados.find(e => e.Initials === item.descricao)

        return {
          ...uf,
          id: uf.Id
        }
      })

      setValue('uf', ufs)
    } catch (err) {
      showNotificationErrorAPI(err)
    }

    setLoading(false)
  }

  useEffect(() => {
    carregarUfsMedico()
  }, [])

  useEffect(() => {
    register('uf')
  }, [register])

  async function submitUfs(values: { uf: typeof estados }) {
    setLoadingSubmit(true)
    try {
      const body = {
        medicoId,
        ufs: values.uf.map(item => item.Initials)
      }
      await salvarUfsAtendimento(body)

      handleOnClose()
      Swal.fire({
        title: 'Sucesso!',
        text: 'UFs do médico alteradas com sucesso',
        confirmButtonText: 'Ok',
        icon: 'success'
      })
    } catch (err) {
      showNotificationErrorAPI(err)
    }
    setLoadingSubmit(false)
  }

  return (
    <Drawer
      open={open}
      anchor="right"
      handleOnClose={handleOnClose}
      titulo="UFs de atendimento do médico"
    >
      {loading && (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        </>
      )}

      {!loading && (
        <>
          <Typography variant="subtitle1">
            Defina as UFs em que o médico poderá realizar seus procedimentos
          </Typography>

          <form onSubmit={handleSubmit(submitUfs)}>
            <Autocomplete
              disabled={perfilAtual === SynPerfis.PARCEIRO_EXTERNO}
              options={estados.map(item => ({
                ...item,
                id: item.Id
              }))}
              showCheck
              showAddButton
              value={command.uf}
              name="uf"
              label="UFs"
              erro={errors}
              multiple
              getOptionLabel={option => option.Initials}
              getOptionSelected={option =>
                !!command.uf?.find(l => l.Initials === option.Initials)
              }
              onChange={(e, value) => {
                setValue('uf', value)
              }}
            />

            <>
              {perfilAtual !== SynPerfis.PARCEIRO_EXTERNO && (
                <Button
                  type="submit"
                  loading={loadingSubmit}
                  disabled={loadingSubmit}
                  fullWidth
                >
                  Salvar
                </Button>
              )}
            </>
          </form>
        </>
      )}
    </Drawer>
  )
}
