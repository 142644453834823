import {
  makeStyles,
  StepConnector,
  Tooltip,
  withStyles
} from '@material-ui/core'

import clsx from 'clsx'

const useStepIconStyles = makeStyles(theme => ({
  root: {
    color: '#bfe0e6',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    '& .MuiPaper-root, .MuiStepper-vertical.MuiPaper-elevation0': {
      background: 'red'
    }
  },
  active: {
    color: theme.palette.primary.main,
    '& div': {
      width: 20,
      height: 20,
      marginLeft: 2
    }
  },
  circle: {
    width: 8,
    height: 8,
    marginLeft: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  },
  completed: {
    // color: '#8cd6aa',
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 25
  }
}))

export function StepIconSyn(props: any) {
  const classes = useStepIconStyles()
  const { active, completed } = props

  return (
    <Tooltip title={props.key} placement="right">
      <div
        style={{ position: 'relative' }}
        className={clsx(classes.root, {
          [classes.active]: active
        })}
      >
        {completed ? (
          // <Check className={classes.completed} />
          <div className={`${classes.circle} ${classes.completed}`}></div>
        ) : (
          <div className={classes.circle} />
        )}
        {active && (
          <span
            style={{
              position: 'absolute',
              right: 6,
              top: 2,
              color: '#fff'
            }}
          >
            {props.key}
          </span>
        )}
      </div>
    </Tooltip>
  )
}

export const StepConnectorSyn = withStyles({
  root: {
    background: 'none !important'
  },
  lineVertical: {
    minHeight: 0
  },
  vertical: { padding: '0 0 0 0' }
})(StepConnector)
