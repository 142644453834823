import { yupResolver } from '@hookform/resolvers/yup'
import { Box, InputAdornment, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FiUser } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components/ui/Button'
import PasswordField from 'src/components/ui/PasswordField'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { showNotification } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import { LoginQuery } from 'src/types'
import Swal from 'sweetalert2'
import { TextField } from '../../components/ui/TextField'
import yup from '../../components/yupCustom'

const useStyles = makeStyles(theme => ({
  loginContainer: {
    width: '100vw',
    minWidth: '100vw',
    height: '100vh',
    margin: 0,
    background: '#374666',
    padding: 0
    // background: '#dedede',
    // backgroundImage:
    //   'linear-gradient(to right bottom, #006986, #128284, #68d5d8)'
  },
  loginBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#fff',
    borderRadius: '8px',
    width: '480px',
    boxSizing: 'border-box',
    padding: '40px',
    margin: '40px auto 0'
  },
  logo: {
    margin: theme.spacing(1),
    width: 80
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(2, 0, 3)
  },
  inputIcon: { color: theme.palette.grey[500] },

  [theme.breakpoints.down('sm')]: {
    container: {
      minHeight: '100vh'
    },
    loginBox: {
      borderRadius: '0',
      width: '100%',
      height: '100%',
      padding: '40px',
      margin: 0
    }
  }
}))

const schema = yup.object().shape({
  cpf: yup.string().required().label('CPF'),
  senha: yup.string().required().label('Senha')
})

export default function LoginPage() {
  const classes = useStyles()
  const { signIn, signed, user, perfilAtual } = useAuth()
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)

  const {
    register,
    errors,
    control,
    watch,
    handleSubmit
  } = useForm<LoginQuery>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: { cpf: '', senha: '' }
  })
  const command = watch()

  const onSubmit = async (data: LoginQuery) => {
    setLoading(true)
    await signIn(data).catch(e => {
      Swal.fire('Ops...', 'Usuário ou senha inválidos', 'error')
      // showNotification('Ops...', 'Usuário ou senha inválidos', 'error', true)
    })
    setLoading(false)
  }
  useEffect(() => {
    if (signed) {
      if (perfilAtual === SynPerfis.SECRETARIA) {
        history.push(SynRoutes.listagemOrcamentos)
      } else {
        history.push(SynRoutes.home)
      }
    }
  }, [signed])

  return (
    <Container
      component="main"
      maxWidth="xs"
      className={classes.loginContainer + ' fadeInTransition'}
    >
      <CssBaseline />
      <div className={classes.loginBox}>
        <img
          src="/image/logo.png"
          className={classes.logo}
          alt="logo syn saúde"
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            mask={'999.999.999-99'}
            control={control}
            value={command.cpf}
            erro={errors}
            name="cpf"
            type="tel"
            label="CPF"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FiUser className={classes.inputIcon} />
                </InputAdornment>
              )
            }}
          />
          <PasswordField
            erro={errors}
            name="senha"
            label="Senha"
            inputRef={register}
          />
          <Button
            loading={loading}
            disabled={loading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Entrar
          </Button>
          <Box textAlign="center">
            <Link
              color="secondary"
              variant="body2"
              onClick={() => history.push(SynRoutes.esqueciSenha)}
              component="button"
            >
              Esqueceu sua senha?
            </Link>
          </Box>
          <Box my={3}>
            <hr />
          </Box>
          {/* <Box textAlign="center">
            <Typography variant="subtitle2">
              Deseja se tornar um parceiro SYN?
            </Typography>
          </Box>
          <Button
            fullWidth
            color="secondary"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(SynRoutes.cadastro)}
            variant="text"
          >
            Crie sua conta agora!
          </Button> */}
        </form>
        <Box textAlign="center" mt={1}>
          <Typography variant="subtitle2">
            {`versão ${process.env.REACT_APP_VERSION}`}
          </Typography>
        </Box>
      </div>
    </Container>
  )
}
