import { AxiosResponse } from 'axios'
import api from 'src/services/api'

import {
  SalvarPacoteCommand,
  ItemListagemPacoteProcedimentoResponse,
  ListagemQuery,
  ListagemResponse,
  IPacoteDetalhadoResponse,
  CompartilharPacoteProcedimentoCommand
} from 'src/types'

export const listagemPacote: (
  query: ListagemQuery
) => Promise<
  AxiosResponse<ListagemResponse<ItemListagemPacoteProcedimentoResponse>>
> = query => {
  try {
    return api.get(`/pacoteProcedimento/listagem?${new URLSearchParams(query)}`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const listagemPacoteIncluirCompartilhados: (
  query: ListagemQuery
) => Promise<
  AxiosResponse<ListagemResponse<ItemListagemPacoteProcedimentoResponse>>
> = query => {
  try {
    return api.get(
      `/pacoteProcedimento/listagem?${new URLSearchParams(
        query
      )}&incluirCompartilhados=true`
    )
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterPacotePorId: (
  id: string
) => Promise<AxiosResponse<IPacoteDetalhadoResponse>> = id => {
  try {
    return api.get(`/pacoteProcedimento/${id}`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const obterPacotePorIdPaciente: (
  id: string
) => Promise<AxiosResponse<IPacoteDetalhadoResponse>> = id => {
  try {
    return api.get(`/pacoteProcedimento/${id}/paciente`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const salvarPacote: (
  command: SalvarPacoteCommand
) => Promise<AxiosResponse<string>> = command => {
  try {
    return api.post('/pacoteProcedimento', command)
  } catch (err) {
    // ;+arguments.callee.name
    console.log('@ERROR: ', err)
  }
}

export const excluirPacote: (id: string) => Promise<boolean> = async id => {
  try {
    await api.delete(`/pacoteProcedimento/${id}`)
    return true
  } catch (err) {
    console.log('@ERROR: ', err)
    return false
  }
}

export const compartilharPacote: (
  command: CompartilharPacoteProcedimentoCommand
) => Promise<AxiosResponse> = command => {
  try {
    return api.post('/pacoteProcedimento/compartilhar', command)
  } catch (err) {
    // ;+arguments.callee.name
    console.log('@ERROR: ', err)
  }
}

export function listarTodosPacotesHospital(
  query: ListagemQuery
): Promise<
  AxiosResponse<ListagemResponse<ItemListagemPacoteProcedimentoResponse>>
> {
  return api.get(
    `/pacoteProcedimento/listagem/hospital?${new URLSearchParams(query)}`
  )
}

export function listarPacotesHospitalPorMedicoId(
  medicoId: string
): Promise<AxiosResponse<ItemListagemPacoteProcedimentoResponse[]>> {
  return api.get(`/pacoteProcedimento/listagem/hospital/medico/${medicoId}`)
}

export const obterPacoteHospitalPorId: (
  id: string
) => Promise<AxiosResponse<IPacoteDetalhadoResponse>> = id => {
  try {
    return api.get(`/pacoteProcedimento/${id}/hospital`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const excluirPacoteHospital: (
  id: string
) => Promise<AxiosResponse> = async id => {
  try {
    return api.delete(`/pacoteProcedimento/${id}/hospital`)
  } catch (err) {}
}

export const verificarPacote: () => Promise<AxiosResponse> = () => {
  try {
    return api.get('/pacoteProcedimento/existePacote')
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}
