import axios, { AxiosResponse } from 'axios'

import {
  IParceiro,
  ListagemParceirosQuery,
  ListagemResponse
} from '../../types'

export interface ICepResponse {
  success: any
  city(arg0: string, city: any, arg2: { shouldValidate: true }): unknown
  state(arg0: string, state: any, arg2: { shouldValidate: true }): unknown
  address(arg0: string, address: any, arg2: { shouldValidate: true }): unknown
  district(arg0: string, district: any, arg2: { shouldValidate: true }): unknown
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  gia: string
  ddd: string
  siafi: string
}

export const ObterCep: (
  cep: string
) => Promise<AxiosResponse<ICepResponse>> = cep => {
  try {
    return axios.get(`https://viacep.com.br/ws/${cep}/json/`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}
