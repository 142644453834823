import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Search } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useListagem from 'src/hooks/useListagem'
import {
  IParceiroFuncionario,
  ResetarSenhaCommand
} from 'src/types'
import ButtonCostom from '../../../../../src/components/ui/Button'

import ClearIcon from '@material-ui/icons/Clear'
import { MdKeyboardArrowDown } from 'react-icons/md'
import ButtonUI from 'src/components/ui/Button'
import Swal from 'sweetalert2'
import useInfiniteScroll from '../../../../hooks/useWindowInfiniteScroll'

import { GoSearch } from 'react-icons/go'
import { resetarSenha } from 'src/api/auth'
import { excluirUsuario, listagemUsuariosQuery } from 'src/api/usuarios'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import { IResetSenha } from '../../operacional/reset/resetSenha'
import DrawerSalvarUsuario from './drawerSalvarUsuario'
import DrawerVisualizarDetalhes from './drawerVisualizarDetalhes'
import TabelaUsuarios from './tabelaUsuario'

const useStyles = makeStyles(theme => ({
  layoutFiltro: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '4px',
    margin: '4px 8px',
    padding: '12px 24px'
  }
}))

const data = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}))

function listagemUsuarios() {
  var style = useStyles()
  const { user, perfilAtual, ehTenantSyn, obterTenantAtual } = useAuth()

  const [itemSelecionado, setItemSelecionado] = useState<IParceiroFuncionario>(
    undefined
  )

  const history = useHistory()

  const [showFiltros, setShowFiltros] = useState(false)
  const [loadingResetarSenha, setLoadingResetarSenha] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [mostrarDetalhe, setMostrarDetalhe] = useState(false)
  const [mostrarDrawerSalvar, setMostrarDrawerSalvar] = useState(false)

  const {
    loading,
    dados,
    handleTermoPesquisa,
    termoPesquisa,
    nenhumDado,
    nenhumResultado,
    dadosCarregados,
    carregarListagem,
    handleProxPagina,
    handleCustomPagina,
    pagina,
    allDados,
    qtdAllDados,
    allDadosCarregados,
    filtroGeral,
    setFiltroGeral,
    handleSetTermoPesquisar,
    handleSetTermoPesquisarCodigo,
    handlePesquisarDados,
    atualizarItemListagem,
    handleLimparFiltros
  } = useListagem(12, listagem)

  function listagem(query, primeiraBusca?: boolean) {
    return listagemUsuariosQuery(query)
  }

  useInfiniteScroll({
    loadMoreFunc: () => {
      handleCustomPagina(pagina + 1)
    },
    data: allDados,
    stopLoadingData: allDados.length >= qtdAllDados
  })

  useEffect(() => {}, [])

  const handleFecharDetalhes = () => {
    setMostrarDetalhe(false)
  }

  const handleFecharSalvar = edicao => {
    setMostrarDrawerSalvar(false)
    carregarListagem({ resetarPaginacao: true })
  }

  const handleVisualizarDetalhes = (item: IParceiroFuncionario) => {
    setItemSelecionado(item)
    setMostrarDetalhe(true)
  }

  function setClassToHighlight(item) {
    if (item?.id === itemSelecionado?.id) return 'highlight'

    return null
  }

  function handleKeyDownPesquisar(e) {
    // carregarListagem({
    //   resetarPaginacao: true,
    //   dataInicio: dataInicio,
    //   dataFim: dataFim
    // })
    if (e.key === 'Enter' && termoPesquisa) {
      handlePesquisarDados()
    }
  }

  async function handleResetarSenha(item: IParceiroFuncionario) {
    var result = await Swal.fire({
      title: `<div>
          Deseja realmente resetar a senha do usuário
          <b>${item.nome.toUpperCase()}</b>?
        </div>`,
      icon: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        var command: IResetSenha = { cpf: item.cpf }
        return await onSubmit(command)
      },
      allowOutsideClick: () => !Swal.isLoading()
    })

    if (result.isDismissed) {
      return
    }
  }

  const onSubmit = async (data: IResetSenha) => {
    setLoadingResetarSenha(true)
    try {
      let command: ResetarSenhaCommand = { cpf: data.cpf }

      var response = await resetarSenha(command)
      Swal.fire('A senha do usuário foi resetada com sucesso!', '', 'success')
      setLoadingResetarSenha(false)
    } catch (erro) {
      setLoadingResetarSenha(false)
      showNotificationErrorAPI(erro)
    }
  }

  async function handleEditar(item: IParceiroFuncionario) {
    setItemSelecionado(item)
    setMostrarDrawerSalvar(true)
  }

  async function handleExcluir(id: string) {
    var result = await Swal.fire({
      title: 'Deseja excluir esse usuário?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        // return true
        return await excluirUsuario(id)
      },
      allowOutsideClick: () => !Swal.isLoading()
    })

    if (result.isDismissed) {
      return
    }

    if (result.value) {
      Swal.fire('Usuário excluído com sucesso!', '', 'success')

      carregarListagem({ resetarPaginacao: true })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text:
          'Não foi possível excluir o usuário. Tente novamente mais tarde ou contate o suporte.'
      })
    }
  }

  const classes = data()

  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))

  function handleNovo() {
    setItemSelecionado(null)
    setMostrarDrawerSalvar(true)
  }

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box mb={2}>
            <Typography variant="h5">Usuários</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box textAlign="right" mb={2}>
            <Button
              fullWidth={ehXS}
              variant="contained"
              color="primary"
              onClick={handleNovo}
              startIcon={<AddIcon />}
            >
              Cadastrar novo usuário
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              onKeyDown={e => {
                handleKeyDownPesquisar(e)
              }}
              fullWidth
              placeholder="Pesquise pelo nome do usuário, cpf..."
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: loading && (
                  <CircularProgress
                    style={{ fontSize: '20px', width: '20px', height: '20px' }}
                  />
                )
              }}
              value={termoPesquisa}
              onChange={e => {
                if (!e.target.value) {
                  handleSetTermoPesquisar(e as any, true)
                } else {
                  handleSetTermoPesquisar(e as any, false)
                }
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            {/* <Button
              color="default"
              variant="text"
              onClick={() => setShowFiltros(!showFiltros)}
              startIcon={<FiFilter />}
            >
              {showFiltros ? 'Esconder' : 'Mostrar'} filtros
            </Button> */}
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <ButtonCostom
              loading={loading}
              disabled={loading}
              size="small"
              fullWidth={true}
              variant="contained"
              color="secondary"
              startIcon={<GoSearch />}
              onClick={e => {
                carregarListagem({
                  resetarPaginacao: true
                })
              }}
            >
              Pesquisar (Enter)
            </ButtonCostom>
          </Grid>
          <Grid item xs={12} sm={3}>
            <ButtonCostom
              size="small"
              fullWidth={true}
              variant="contained"
              color="primary"
              startIcon={<ClearIcon />}
              onClick={e => {
                handleLimparFiltros()
              }}
            >
              Limpar filtros
            </ButtonCostom>
          </Grid>
        </Grid>
      </Box>
      {!loading && (
        <Box mt={3} mb={2} textAlign="left" my={3}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color="primary">
                {qtdAllDados
                  ? qtdAllDados > 1
                    ? `${qtdAllDados} usuários`
                    : '1 usuário'
                  : '0 usuários'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}

      {!loading && nenhumResultado && (
        <div>Nenhum resultado encontrado para sua pesquisa.</div>
      )}

      {allDadosCarregados && !loading && (
        <TabelaUsuarios
          data={allDados}
          handleExcluir={handleExcluir}
          handleEditar={handleEditar}
          handleResetarSenha={handleResetarSenha}
          handleVisualizarDetalhes={handleVisualizarDetalhes}
        />
      )}

      {loading && <LoadingGeral />}

      <Box
        mt={3}
        hidden={qtdAllDados === allDados.length || loading}
        textAlign="center"
      >
        <ButtonUI
          fullWidth
          onClick={handleProxPagina}
          variant="text"
          disabled={loading}
          loading={loading}
          endIcon={<MdKeyboardArrowDown />}
        >
          Carregar mais
        </ButtonUI>
      </Box>

      <DrawerVisualizarDetalhes
        id={itemSelecionado?.id}
        open={mostrarDetalhe}
        handleOnClose={handleFecharDetalhes}
      />

      {!!mostrarDrawerSalvar && (
        <DrawerSalvarUsuario
          item={itemSelecionado}
          open={true}
          handleOnClose={() => handleFecharSalvar(!!itemSelecionado)}
        />
      )}
    </Box>
  )
}

export default listagemUsuarios
