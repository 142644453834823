import * as signalR from '@microsoft/signalr'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import useAuth from 'src/hooks/useAuth'
import { UserAuth } from 'src/types'
import { callbackNotificacao } from './Notificacao/notificacao'
import { callbackLembreteAtividade } from './LembreteAtividade/LembreteAtividade'
import { callbackLembreteAtividadeAtrasada } from './LembreteAtividade/LembreteAtividadeAtrasada'

//ACTION passar para outro arquivo

const callbackLog = (message, user = null) => {
  console.log('@signal: ', message, user)
  if (message.from !== user.id) {
    toast.info(message)
  }
}

interface INotificacaoSignalR {
  evento: string
  callback: (message: any, user?: UserAuth) => any | void
}

export const NOTIFICACOES_SYN: INotificacaoSignalR[] = [
  {
    evento: 'notificacao',
    callback: callbackNotificacao
  },
  // {
  //   evento: 'notificacao',
  //   callback: callbackLog
  // },
  {
    evento: 'LembreteAtividade',
    callback: callbackLembreteAtividade
  },
  {
    evento: 'LembreteAtividadeAtrasada',
    callback: callbackLembreteAtividadeAtrasada
  },
  { evento: 'teste', callback: callbackLog }
]

const NotificacoesSignalR = ({ hub }: { hub: signalR.HubConnection }) => {
  const { user } = useAuth()
  const notificacoes = NOTIFICACOES_SYN

  useEffect(() => {
    //Configura hub para escutar todas as notificações cadastradas
    notificacoes.forEach(n => {
      hub.on(n.evento, m => n.callback(m, user))
    })
  }, [])

  return null
}

export default NotificacoesSignalR
