import React, { useContext, useState, useEffect } from 'react'
import ModalGenerico from './ModalGenerico'
import { ModalAtualizacoesContext } from '../../contexts/ModalAtualizacoes'
import Button from '../ui/Button'
import { Checkbox, Typography, makeStyles, Box } from '@material-ui/core'
import { ItemAtualizacao } from 'src/types'
import { obterUltimaAtualizacao } from 'src/api/atualizacoes'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import {
  formatarData,
  formatarDataHoraLocal
} from 'src/core/formatacoes/formatarData'
import useAuth from 'src/hooks/useAuth'
import {
  marcarComoVistoPacotesSugeridos,
  mostrarAlertaPacotesSugeridos
} from 'src/api/usuario'
import { useHistory } from 'react-router-dom'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { SynPerfis } from 'src/core/consts/synPerfis'

const useStyles = makeStyles({
  modalButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '20px'
  },
  mainDate: {
    color: '#717171',
    margin: '5px 0'
  },
  normalDescription: {
    marginTop: '5px',
    whiteSpace: 'pre-line'
  }
})

export default function ModalPacotesSugeridos() {
  const styles = useStyles()
  // const { aberto, fecharModal } = useContext(ModalAtualizacoesContext)
  const history = useHistory()
  const { perfilAtual, user, signed } = useAuth()
  const [Loading, setLoading] = useState(false)
  const [aberto, setAberto] = useState(false)

  const [verNovamente, setVerNovamente] = useState(true)

  useEffect(() => {
    setLoading(true)
    if (perfilAtual !== SynPerfis.MEDICO || !signed) {
      return
    }

    setTimeout(() => {
      mostrarAlertaPacotesSugeridos(perfilAtual)
        .then(response => {
          setAberto(response.data)
          setLoading(false)
        })
        .catch(err => {
          showNotificationErrorAPI(err)
          setLoading(false)
        })
    }, 1000)
  }, [perfilAtual])

  useEffect(() => {
    setVerNovamente(true)
  }, [aberto])

  function fecharModal() {
    marcarComoVistoPacotesSugeridos(perfilAtual)
    setAberto(false)
    history.push(SynRoutes.listagemPacotesSugeridos)
    // .then(response => {
    //   setAberto(false)
    // })
    // .catch(err => {
    //   showNotificationErrorAPI(err)
    //   setLoading(false)
    // })
  }
  return (
    <ModalGenerico open={aberto} title="Pacotes sugeridos">
      <Box>
        <Box px={2}>
          <Typography variant="body2">
            <strong>Conheça nossos pacotes de procedimentos sugeridos.</strong>
          </Typography>
          {/* <Typography variant="subtitle2" className={styles.mainDate}>
            Lançamento:
          </Typography> */}
          <Typography variant="body1" className={styles.normalDescription}>
            Visando mais velocidade e praticidade para seus orçamentos estamos
            disponibilizando alguns dos principais pacotes de procedimentos na
            modalidade de orçamento instantâneo.
          </Typography>
        </Box>
        <div className={styles.modalButtons}>
          <Typography variant="body1">
            {/* <Checkbox
              checked={!verNovamente}
              onChange={() => setVerNovamente(!verNovamente)}
            />
            Não quero ver novamente */}
          </Typography>

          <Button style={{ marginLeft: '20px' }} onClick={() => fecharModal()}>
            Quero conhecer
          </Button>
        </div>
      </Box>
    </ModalGenerico>
  )
}
