import {
  CircularProgress,
  createStyles,
  Drawer,
  DrawerProps,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useTheme,
  Grid,
  Box,
  Divider,
  useMediaQuery
} from '@material-ui/core'
import React, { useContext } from 'react'
import { MdClose } from 'react-icons/md'
import { MobileContext } from 'src/contexts/Mobile'
import synTheme from 'src/styles/synTheme'

export type DrawerCustomProps = DrawerProps & {
  loading?: boolean
  titulo?: string
  handleOnClose?: (atualizou, id?, tipoConta?) => void
  noPadding?: boolean
}

const drawerWidth = 600
const drawerHeaderHeight = 60

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerSyn: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      '& .noPadding': {
        padding: 0
      },
      [theme.breakpoints.down('xs')]: {
        width: '100vw',
        '& .synBox': {
          padding: '0'
        },
        '& .synContent': {
          marginTop: `calc(${theme.spacing(2)}px)`
        },
        '& .synHeader': {
          paddingLeft: 8,
          zIndex: 10,
          // position: '',
          // top: 0,
          // bottom: 0,
          height: drawerHeaderHeight,
          borderBottom: `1px solid ${theme.palette.grey[100]}`,
          background: '#fff'
        }
      }
    }
  })
)

function DrawerSyn(props: DrawerCustomProps) {
  const { children, loading, titulo, handleOnClose, ...rest } = props
  const { autocompleteMobileOpen } = useContext(MobileContext)
  const classes = useStyles()
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      className={classes.drawerSyn}
      onClose={handleOnClose}
      classes={{ paper: classes.drawerSyn }}
      //  paper: clsx({
      //       [classes.drawerOpen]: open,
      //       [classes.drawerClose]: !open,
      //     }),
      {...rest}
    >
      <>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%'
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Box className="synBox" pt={1} px={1}>
            {!autocompleteMobileOpen && (
              <Grid
                container
                justify="center"
                alignItems="center"
                className="synHeader"
              >
                <Grid item xs={10}>
                  <Box pl={1}>
                    <Typography
                      variant={ehXS ? 'subtitle1' : 'h6'}
                      style={{ fontWeight: 500 }}
                    >
                      {titulo}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                  <IconButton onClick={() => handleOnClose(false)}>
                    <MdClose />
                  </IconButton>
                </Grid>
              </Grid>
            )}
            <Box
              className={props.noPadding ? 'noPadding' : 'synContent'}
              pb={12}
              px={2}
            >
              {children}
            </Box>
          </Box>
        )}
      </>
    </Drawer>
  )
}

export default DrawerSyn
