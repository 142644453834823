import { AxiosResponse } from 'axios'
import api from 'src/services/api'

export interface SalvarDadosBancarioRequest {
  id?: string
  medicoId?: string
  parceiroId?: string
  nomeBanco: string
  codigoBanco: string
  agencia: string
  digitoAgencia: string
  numeroConta: string
  digitoConta: string
  cpfCnpj: string

  nome: string
  tipoConta: string
  name: string
  email: string
  document: string
  birthdate: string
  monthlyIncome: number
  professionalOccupation: string
  phoneNumbers: string

  companyName?: string
  tradingName?: string
  companyEmail?: string
  companyDocument?: string
  siteUrl?: string
  annualRevenue?: number
  corporationType?: string
  foundingDate?: string
  companyPhoneNumbers?: string
  selfDeclaredLegalRepresentative?: boolean

  address: Address
  mainAddress: Address
}
interface Address {
  cep: string
  street: string
  complementary: string
  streetNumber: string
  neighborhood: string
  city: string
  state: string
  referencePoint: string
}

export interface IDadosBancario {
  id?: string
  nome: string
  tipoRecebedor: string
  ativo: boolean
  nomeBanco: string
  codigoBanco: string
  agencia: string
  digitoAgencia: string
  numeroConta: string
  digitoConta: string
  cpfCnpj: string
  pessoaJuridica: boolean
  tipoConta: boolean
}

export const salvarDadosBancario: (
  dadosBancario: SalvarDadosBancarioRequest
) => Promise<AxiosResponse<void>> = dadosBancario => {
  return api.post('/dadosbancario', dadosBancario)
}

export const obterDadosBancario: (
  id: string
) => Promise<AxiosResponse<IDadosBancario>> = id => {
  return api.get(`/dadosbancario/${id}`)
}

export const alterarDadosBancario: (
  dadosBancario: SalvarDadosBancarioRequest
) => Promise<AxiosResponse<void>> = dadosBancario => {
  return api.put('/dadosbancario', dadosBancario)
}

export const alterarStatusDadosBancario: (
  dadosBancario: SalvarDadosBancarioRequest
) => Promise<AxiosResponse<void>> = dadosBancario => {
  return api.post('/dadosbancario', dadosBancario)
}
