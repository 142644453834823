/*
==============================================================================
Pagina não utilizada
==============================================================================
*/

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { obterLocaisAtendimento } from 'src/api/dominio'
import {
  ObjetoDescricaoGenerico,
  ItemListagemVinculoMedicoLocalAtendimento
} from '../../../../types'
import { showNotificationErrorAPI } from '../../../../core/helpers/notification'
import {
  vincularPacoteMedicoLocalAtendimento,
  obterVinculosPacoteMedicoLocalAtendimento,
  excluirVinculoPacoteMedicoLocalAtendimento,
  obterLocaisAtendimentoSemVinculo,
  aprovarVinculoPacoteMedicoLocalAtendimento
} from 'src/api/medico'
import useAuth from 'src/hooks/useAuth'

import {
  Box,
  Link,
  Typography,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  TextField,
  CircularProgress,
  Menu,
  MenuItem
} from '@material-ui/core'
import { RiArrowLeftLine } from 'react-icons/ri'
import { Delete, Check } from '@material-ui/icons'
import { Autocomplete, Alert } from '@material-ui/lab'
import Button from 'src/components/ui/Button'
import Swal from 'sweetalert2'
import {
  renderChipStatusMedicoLocalAtendimento
} from '../../../../core/consts/statusMedicoLocalAtendimento'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import MoreVertIcon from '@material-ui/icons/MoreVert'

export default function AutorizarHospitais() {
  const history = useHistory()

  const [listaHsopitais, setListaHospitais] = useState<
    ObjetoDescricaoGenerico[]
  >()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)

  const [
    hospitalSelecionado,
    setHospitalSelecionado
  ] = useState<ObjetoDescricaoGenerico>()

  const [listaVinculos, setListaVinculos] = useState<
    ItemListagemVinculoMedicoLocalAtendimento[]
  >()

  function carregarVinculos() {
    setLoadingTable(true)

    obterVinculosPacoteMedicoLocalAtendimento()
      .then(response => setListaVinculos(response.data.items))
      .catch(err => showNotificationErrorAPI(err))

    setLoadingTable(false)
  }

  function carregarHospitais() {
    obterLocaisAtendimentoSemVinculo()
      .then(response => setListaHospitais(response.data))
      .catch(err => showNotificationErrorAPI(err))
  }

  useEffect(() => {
    carregarHospitais()

    carregarVinculos()
  }, [])

  async function submit() {
    setLoadingSubmit(true)

    try {
      await vincularPacoteMedicoLocalAtendimento(hospitalSelecionado.id)

      Swal.fire({
        icon: 'success',
        title: 'Hospital vinculado'
      })

      carregarVinculos()
      carregarHospitais()
    } catch (err) {
      showNotificationErrorAPI(err)
    }

    setLoadingSubmit(false)
  }

  async function excluirVinculo(vinculoId: string) {
    try {
      await excluirVinculoPacoteMedicoLocalAtendimento(vinculoId)

      Swal.fire({
        icon: 'success',
        title: 'Hospital desvinculado'
      })

      carregarVinculos()
      carregarHospitais()
    } catch (err) {
      showNotificationErrorAPI(err)
    }
  }

  async function aprovarVinculo(vinculoId: string) {
    try {
      await aprovarVinculoPacoteMedicoLocalAtendimento(vinculoId)

      Swal.fire({
        icon: 'success',
        title: 'Hospital aprovado'
      })

      carregarVinculos()
      carregarHospitais()
    } catch (err) {
      showNotificationErrorAPI(err)
    }
  }

  return (
    <Box>
      <Link
        component="button"
        color="primary"
        onClick={() => history.goBack()}
        style={{ marginBottom: '20px' }}
      >
        <RiArrowLeftLine />
        <Typography
          style={{ marginLeft: 10 }}
          variant="subtitle1"
          component="span"
        >
          Voltar
        </Typography>
      </Link>

      <Typography variant="h5" style={{ marginBottom: '.5em' }}>Autorizar hospitais</Typography>

      <Alert severity="info">
        <Typography variant="subtitle1">
        Ao autorizar um hospital, o mesmo terá acesso aos seus pacotes cirúrgicos cadastrados para realizar uma solicitação de cirurgia em seu nome.
        </Typography>
      </Alert>

      <Grid container spacing={2} style={{ marginTop: '1em' }}>
        <Grid item xs={12} sm={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Hospital</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            {loadingTable ? (
              <CircularProgress />
            ) : (
              <TableBody>
                {listaVinculos?.length === 0 && (
                  <Typography style={{ marginTop: '1em' }}>
                    Você não possui nenhum hospital vinculado. Escolha um
                    hospital ao lado.
                  </Typography>
                )}
                {listaVinculos?.map(item => {
                  return (
                    <TableRow key={item.vinculoId}>
                      <TableCell>{item.descricao}</TableCell>
                      <TableCell>
                        {renderChipStatusMedicoLocalAtendimento(item.status)}
                      </TableCell>
                      <TableCell>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {popupState => (
                            <React.Fragment>
                              <MoreVertIcon
                                style={{ cursor: 'pointer' }}
                                {...bindTrigger(popupState)}
                              />
                              <Menu
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                                {...bindMenu(popupState)}
                              >
                                {item.status !== 2 && (
                                  <MenuItem
                                    onClick={() =>
                                      aprovarVinculo(item.vinculoId)
                                    }
                                  >
                                    <Check />
                                    Aprovar
                                  </MenuItem>
                                )}
                                <MenuItem
                                  className="danger"
                                  onClick={() => excluirVinculo(item.vinculoId)}
                                >
                                  <Delete />
                                  Excluir
                                </MenuItem>
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            )}
          </Table>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            key={JSON.stringify(listaHsopitais)}
            options={listaHsopitais}
            renderInput={params => (
              <TextField {...params} variant="outlined" label="Hospitais" />
            )}
            getOptionLabel={option => option.descricao}
            onChange={(e, values) =>
              setHospitalSelecionado(values as ObjetoDescricaoGenerico)
            }
          />

          <Button
            loading={loadingSubmit}
            disabled={loadingSubmit}
            fullWidth
            style={{ marginTop: '1em' }}
            onClick={() => submit()}
          >
            Adicionar
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
