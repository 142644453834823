import { useTheme, useMediaQuery, DialogActions, Box } from '@material-ui/core'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { ReactElement } from 'react'

interface Props extends DialogProps {
  children?: ReactElement
  open: boolean
  onClose?: () => void
  title?: string
  actions?: ReactElement
}

export default function ModalGenerico(props: Props) {
  const { children, open, onClose, actions, title } = props
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Dialog
        open={open}
        fullScreen={ehXS}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...props}
      >
        <DialogTitle hidden={!title} id="alert-dialog-title">
          {title}
        </DialogTitle>

        <DialogContent>{children}</DialogContent>
        {actions && (
          <Box mx={2} mb={2}>
            <DialogActions>{actions}</DialogActions>
          </Box>
        )}
      </Dialog>
    </>
  )
}
