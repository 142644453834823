import { Box, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { RiArrowLeftLine } from 'react-icons/ri'
import { GoSignOut } from 'react-icons/go'

import { useHistory } from 'react-router-dom'
import Button from 'src/components/ui/Button'
import UBox from 'src/components/ui/Ubox'
import {
  SynPerfis,
  SynPerfisSetorHospital,
  SynPerfisSetorHospitalArray
} from 'src/core/consts/synPerfis'
import useAuth from 'src/hooks/useAuth'
import { SynRoutes } from 'src/core/consts/synRoutes'

const useStyles = makeStyles(theme => ({
  container: {
    // background: '#fff !important'
  },
  paper: {
    height: '100vh !important',

    '& .container-perfil': {
      overflow: 'auto',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingBottom: '40px',
      flexDirection: 'column'
    },

    '& .box-perfil': {
      width: '120px',
      height: '120px',
      flexBasis: '120px',
      flexGrow: 0,
      flexShrink: 0,
      border: '2px solid #eee',
      background: theme.palette.grey[50],
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      textAlign: 'center',

      '& b': {
        paddingTop: '8px'
      },

      '& img': {
        filter: 'grayscale(100%)'
      },

      '&:hover': {
        '& img': {
          filter: 'grayscale(0)'
        }
      }
    }
  },
  [theme.breakpoints.up('md')]: {
    container: {
      minHeight: '100vh'
    },
    paper: {
      height: '100% !important',
      width: `${theme.breakpoints.values.md}px !important`,

      '& .container-perfil': {
        justifyContent: 'center',
        overflow: 'auto',
        width: '100%',
        flexDirection: 'row'
      }
    }
  }
}))

export default function selecionarSetorParceiro() {
  const classes = useStyles()
  const { signed, user, perfilAtual, signOut, setSetorParceiro } = useAuth()
  const history = useHistory()
  const [setores, setSetores] = useState<any>(null)

  useEffect(() => {
    if (!signed) {
      history.replace(SynRoutes.signin)
    }
    // let perfisUsuario = SynPerfisArray
    let tiposSetores = SynPerfisSetorHospitalArray.filter(p =>
      user?.lstSetorFuncionario.includes(p.id)
    )
    setSetores(tiposSetores)
  }, [])

  function selecionarPerfil(setor: any) {
    setSetorParceiro(setor.id)
    history.push(SynRoutes.home)
  }

  function obterImagemSetor(setor) {
    switch (setor.id) {
      case SynPerfisSetorHospital.TESOURARIA:
        return <img src="image/perfil/operacional.png" />
      case SynPerfisSetorHospital.COTACAO:
        return <img src="image/perfil/comercial.png" />
      case SynPerfisSetorHospital.OPERACIONAL:
        return <img src="image/perfil/secretaria.png" />
      case SynPerfisSetorHospital.ADMIN:
        return <img src="image/perfil/secretaria.png" />
    }
  }

  return (
    <Container
      component="main"
      className={classes.container + ' fadeInTransition syn-container-out'}
    >
      <CssBaseline />
      <UBox className={classes.paper + ' syn-box-out'}>
        <UBox
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link
            component="button"
            color="secondary"
            onClick={() => history.goBack()}
          >
            <UBox hidden={!perfilAtual}>
              <RiArrowLeftLine />
              <Typography
                style={{ marginLeft: 10 }}
                variant="subtitle1"
                component="span"
              >
                Voltar
              </Typography>
            </UBox>
          </Link>
          <Button color="secondary" size="medium" onClick={() => signOut()}>
            <GoSignOut style={{ fontSize: '1.2rem' }} />
            <Typography
              style={{ marginLeft: 8 }}
              variant="subtitle1"
              component="span"
            >
              Sair
            </Typography>
          </Button>
        </UBox>
        <Box my={5}>
          <Typography variant="h6">
            Olá, verificamos que seu perfil possui mais de um setor. <br />
            Deseja navegar com qual setor nessa sessão?
          </Typography>
        </Box>
        {/* <Typography variant="body1">
          Digite o CPF da sua conta para que possamos enviar um e-mail com as
          instruções para definir uma nova senha.
        </Typography> */}
        <UBox display="flex" gridGap={20} className="container-perfil">
          {setores?.map(setore => (
            <UBox
              key={setore.id}
              className="box-perfil"
              onClick={() => selecionarPerfil(setore)}
            >
              {obterImagemSetor(setore)}
              <b>{setore.descricao}</b>
            </UBox>
          ))}
        </UBox>
      </UBox>
    </Container>
  )
}
