import { AxiosResponse } from 'axios'
import { IUsuarioAtividade } from 'src/contexts/Atividade/context'
import SynTipoParceiro from 'src/core/consts/synTipoParceiro'
import api from 'src/services/api'

import {
  IParceiroFuncionario,
  ListagemQuery,
  ListagemResponse
} from 'src/types'

export const listagemUsuariosQuery: (
  query: ListagemQuery
) => Promise<AxiosResponse<ListagemResponse<IParceiroFuncionario>>> = query => {
  return api.get(`/parceiroFuncionario/listagem?${new URLSearchParams(query)}`)
}

export const excluirUsuario: (
  id: string
) => Promise<AxiosResponse<string>> = id => {
  return api.delete(`/parceiroFuncionario/${id}`)
}

export const salvarUsuario: (
  funcionario: IParceiroFuncionario
) => Promise<AxiosResponse<string>> = funcionario => {
  return api.post('/parceiroFuncionario', funcionario)
}
