import {
  Box,
  BoxProps,
  CircularProgress,
  Divider,
  Typography
} from '@material-ui/core'
import React from 'react'

type UBoxProps = BoxProps & {
  src?: string
  alt?: string
  render?: any
  html?: string
  href?: string
  target?: string
  align?: string
  loading?: boolean
  secondaryLoading?: boolean
  textLoading?: any
  severity?: string
  item?: any
  xs?: any
  md?: any
  sm?: any
  direction?: any
  justify?: any
  container?: boolean
  spacing?: number
}

const UBox = React.forwardRef<HTMLElement, UBoxProps>((props, ref) => {
  const {
    html,
    href,
    target,
    render,
    loading,
    secondaryLoading,
    textLoading
  } = props
  const pHtml = html
    ? {
        dangerouslySetInnerHTML: {
          __html: html
        }
      }
    : {}
  const pHref = href
    ? {
        href
      }
    : {}

  const pTarget = target
    ? {
        target
      }
    : {}

  if (render === false) return <div />

  if (loading) {
    return (
      // width: 100%
      <Box
        display="flex"
        style={{ alignItems: 'center', flexDirection: 'column' }}
        mt={1}
      >
        <CircularProgress
          color={secondaryLoading ? 'secondary' : 'primary'}
          size={24}
        />
        {!!textLoading && (
          <Typography variant="body2">{textLoading}</Typography>
        )}
      </Box>
    )
  }

  return (
    <Box
      itemRef={ref as any}
      {...pTarget}
      {...pHtml}
      {...pHref}
      {...props}
    ></Box>
  )
})

export default UBox
