import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import UBox from 'src/components/ui/Ubox'
import genero from 'src/core/consts/genero'
import formatarCPF from 'src/core/formatacoes/formatarCPF'
import {
  formatarData,
  formatarDiferencaData,
  formatarIdade
} from 'src/core/formatacoes/formatarData'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'
import { IPacoteForm } from '../pacote/formPacote'
import ResumoPacote from '../pacote/resumo'
import { IOrcamentoForm } from './formV2'
import { SynPerfis } from 'src/core/consts/synPerfis'
import useAuth from 'src/hooks/useAuth'
import ResumoPacoteInteligenteOps from 'src/components/pacoteInteligente/resumoOperacional'
import { IPacoteInteligenteForm } from 'src/components/pacoteInteligente/FormPacoteInteligente'

const useStylesResumo = makeStyles(theme => ({
  boxResumo: {
    border: '1px solid #eee',
    backgroundColor: '#efefef',
    borderRadius: 4,
    '& .MuiTableCell-root.MuiTableCell-head': {
      padding: theme.spacing(1, 2),
      background: '#f9f9f9',
      color: theme.palette.grey[800]
    }
  },
  fontMarca: {
    fontStyle: 'italic',
    fontWeight: 500,
    fontSize: '0.8rem',
    color: theme.palette.grey[700]
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))

export const ResumoOrcamento = ({
  orcamento,
  pacoteSelecionado,
  pacoteInteligente = false,
  forcarHonorarios = false,
  exibirValores = false,
  esconderIncluso = false,
  isMedico,
  isHospital,
  dadosSensiveis,
  exibirAjusteEtapa,
  orcamentoSolicitacao,
  handleAjusteEtapa,
  handleAjusteEtapaSolicitacao
}: {
  orcamento: IOrcamentoForm
  pacoteSelecionado?: IPacoteForm
  orcamentoSolicitacao?: IOrcamentoForm
  pacoteInteligente?: boolean
  esconderIncluso?: boolean
  exibirValores?: boolean
  forcarHonorarios?: boolean
  isMedico?: boolean
  isHospital?: boolean
  dadosSensiveis?: boolean
  exibirAjusteEtapa?: boolean
  handleAjusteEtapa?: (etapa: any) => void
  handleAjusteEtapaSolicitacao?: (etapa: any) => void
}) => {
  const classes = useStylesResumo()
  const { signOut, user, perfilAtual } = useAuth()
  return (
    <Box mt={2} p={2} className={classes.boxResumo}>
      {/* <UBox style={{ wordBreak: 'break-all' }}>
        c:{JSON.stringify(orcamento)}
        <br />
        <br />
        c:{JSON.stringify(pacoteSelecionado)}
      </UBox>
      <br /> */}
      {!isMedico && (
        <>
          <Typography variant="subtitle2" color="primary">
            Médico
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" component="span">
                <strong>Nome: </strong> {orcamento?.medicoNome}
              </Typography>
            </Grid>
            {!isHospital && (
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  <strong>Especialidade(s): </strong> <br />
                  {orcamento?.medicoEspecialidades?.map(d => {
                    return (
                      <>
                        - {d.descricao} <br />
                      </>
                    )
                  })}
                </Typography>
              </Grid>
            )}
          </Grid>

          <br />
          <Divider />
          <br />
        </>
      )}
      {orcamento.nomeAssistente && (
        <>
          <Typography variant="subtitle2" color="primary">
            Essa solicitação foi feita por:
          </Typography>
          <Typography variant="body2">{orcamento.nomeAssistente}</Typography>
        </>
      )}
      <Typography variant="subtitle2" color="primary">
        Paciente
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" component="span">
            <strong>Nome: </strong> {orcamento.nomePaciente}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2"></Typography>
        </Grid>
        {orcamento.emailPaciente && perfilAtual !== SynPerfis.PARCEIRO_EXTERNO && (
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>E-mail: </strong>
              {orcamento.emailPaciente}
            </Typography>
          </Grid>
        )}
        {orcamento.sexoPaciente && (
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>Sexo: </strong>
              {orcamento.sexoPaciente === genero.Masculino
                ? 'Masculino'
                : 'Feminino'}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body2">
            <strong>Data de nascimento: </strong>
            {orcamento.dataNascimentoPaciente ? (
              `${formatarData(
                orcamento.dataNascimentoPaciente
              )} - ${formatarIdade(orcamento.dataNascimentoPaciente)} anos`
            ) : (
              <span className={classes.dataNascimentoSpan}>Não informado</span>
            )}
          </Typography>
        </Grid>
        {perfilAtual !== SynPerfis.PARCEIRO_EXTERNO && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">
                <strong>Tel: </strong>
                {formatarTelefone(orcamento.telefonePaciente)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">
                <strong>CPF: </strong>{' '}
                {orcamento.cpfPaciente
                  ? formatarCPF(orcamento.cpfPaciente)
                  : 'Não informado'}
              </Typography>
            </Grid>
          </>
        )}
        {pacoteSelecionado.planoDeSaude === true && (
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              {orcamento.temPlanoDeSaude === 1 ? ' Possui plano de saúde' : ' Não possui plano de saúde'}
            </Typography>
          </Grid>
        )}
      </Grid>
      <br />
      {orcamento.cpfResponsavel && (
        <>
          <Typography variant="subtitle2" color="primary">
            Responsável{' '}
            {formatarIdade(orcamento.dataNascimentoPaciente) < 18 &&
              '(menor de idade)'}
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" component="span">
                <strong>Nome: </strong> {orcamento.nomeResponsavel}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" component="span">
                <strong>CPF: </strong> {orcamento.cpfResponsavel}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      <br />
      <Typography variant="subtitle2" color="primary">
        Comorbidades
      </Typography>
      <Typography variant="body2">
        {orcamento.comorbidades?.length !== 0
          ? orcamento.comorbidades?.map(e => e.descricao).join(', ')
          : 'Não possui comorbidade'}
      </Typography>
      <br />
      {orcamento.observacaoComorbidades && (
        <>
          <Typography variant="subtitle2" color="primary">
            Observação das comorbidades
          </Typography>
          <Typography variant="body2">
            {orcamento.observacaoComorbidades}
          </Typography>
          <br />
        </>
      )}
      {!!orcamento.pacote && (
        <>
          <Divider />
          {/* <br /> */}
          {/* <Typography variant="subtitle2" color="primary">
            Pacote de procedimento
          </Typography>
          <Typography variant="body2">{orcamento.pacote.descricao}</Typography> */}
          {!!pacoteSelecionado && !pacoteInteligente && (
            <ResumoPacote
              telaOrcamento
              style={{ padding: 0 }}
              pacote={pacoteSelecionado}
              isHospital={isHospital}
              dadosSensiveis={dadosSensiveis}
              valorEquipeAnestesia={orcamento.valorEquipeAnestesia}
              temPlanoDeSaude={!!orcamentoSolicitacao?.temPlanoDeSaude}
            />
          )}

          {!!pacoteSelecionado && pacoteInteligente && (
            <ResumoPacoteInteligenteOps
              // <RevisaoPacoteInteligente
              exibirValor={exibirValores}
              unificarOpmes
              medico
              selecionado
              pacote={pacoteSelecionado as any}
              adaptarValor={false}
              forcarHonorarios={forcarHonorarios}
              esconderIncluso={esconderIncluso}
              exibirAjusteEtapa={exibirAjusteEtapa}
              orcamentoSolicitacao={orcamentoSolicitacao}
              handleAjusteEtapa={handleAjusteEtapa}
              handleAjusteEtapaSolicitacao={handleAjusteEtapaSolicitacao}
              exibirDadosMedicos
            // erro={erroTempoCirurgico}
            />
          )}
          <Divider />
          <br />
        </>
      )}
      {perfilAtual !== SynPerfis.ADMIN_HOSPITAL &&
        perfilAtual !== SynPerfis.OPERACIONAL_HOSPITAL &&
        perfilAtual !== SynPerfis.PACIENTE ? (
        <>
          <UBox render={!!orcamento.valorMinHonorarios}>
            <Typography variant="subtitle2" component="h3" color="primary">
              Valores para negociação com o paciente (honorários do médico):
            </Typography>
            <Typography variant="body2">
              {`${formatarDinheiro(
                orcamento.valorMinHonorarios
              )} até ${formatarDinheiro(
                pacoteSelecionado?.honorarioEquipeMedica
              )}`}
            </Typography>
          </UBox>
          <br />
        </>
      ) : (
        <span></span>
      )}
      {/* <Typography variant="subtitle2" component="h3" color="primary">
        Exames pré-operatórios
      </Typography>
      <Typography variant="body2">
        {orcamento.exames?.length === 0 && 'Nenhum exame'}
        {!!orcamento.exames?.length &&
          orcamento.exames?.map(e => e.descricao).join(', ')}
      </Typography> <br /> */}
      {!!orcamento.avaliacaoPreAnestesica && (
        <>
          <Typography variant="subtitle2" color="primary">
            Avaliação pré-anestésica
          </Typography>
          <Typography variant="body2">Sim</Typography>
          <br />
        </>
      )}
      {!!orcamento.riscoCirurgico && (
        <>
          <Typography variant="subtitle2" color="primary">
            Avaliação risco cirúrgico
          </Typography>
          <Typography variant="body2">Sim</Typography>
          <br />
        </>
      )}
      {!!orcamento.observacaoExames && (
        <>
          <Typography variant="subtitle2" color="primary">
            Observação dos exames
          </Typography>
          <Typography variant="body2">{orcamento.observacaoExames}</Typography>
          <br />
        </>
      )}
      {!!orcamento.reservaSangue && (
        <>
          <Typography variant="subtitle2" color="primary">
            Reserva de sangue
          </Typography>
          <Typography variant="body2">
            {orcamento.reservaSangue} concentrados de hemácias
          </Typography>
          <br />
        </>
      )}
      {!!orcamento.observacaoSangue && (
        <>
          <Typography variant="subtitle2" color="primary">
            Observação reserva de sangue
          </Typography>
          <Typography variant="body2">{orcamento.observacaoSangue}</Typography>
          <br />
        </>
      )}
      {!!orcamento.uf && (
        <>
          <Typography variant="subtitle2" color="primary">
            Estado do procedimento:
          </Typography>
          <Typography variant="body2">{orcamento.uf}</Typography>
          <br />
        </>
      )}
      {!!orcamento.hospitais?.length && (
        <>
          <Typography variant="subtitle2" color="primary">
            Sugestões de hospitais
          </Typography>
          <Typography variant="body2">
            {orcamento.hospitais?.map(e => e.descricao).join(', ')}
          </Typography>
          <br />
        </>
      )}
      {!!orcamento.disponibilidade && (
        <>
          <Typography variant="subtitle2" color="primary">
            Disponibilidade para operar em horários de baixo fluxo
          </Typography>
          <Typography variant="body2">{orcamento.disponibilidade}</Typography>
          <br />
        </>
      )}
      {!!orcamento.observacoes && (
        <>
          <Typography variant="subtitle2" color="primary">
            Considerações finais
          </Typography>
          <Typography variant="body2">{orcamento.observacoes}</Typography>
        </>
      )}
    </Box>
  )
}
