import { AxiosResponse } from 'axios'
import api from 'src/services/api'
import { SenhaMasterCommand } from 'src/types'

export const validarAcesso: (
  command: SenhaMasterCommand
) => Promise<AxiosResponse<string>> = command => {
  try {
    return api.post('/usuario/validarAcesso', command)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export const mostrarTutorial: (
  habilitado: boolean
) => Promise<AxiosResponse<string>> = habilitado => {
  try {
    return api.post(`/usuario/MostrarTutorial/${habilitado}`)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export function mostrarAlertaPacotesSugeridos(
  perfilAtual: string
): Promise<AxiosResponse<boolean>> {
  return api.get(`/usuario/${perfilAtual}/mostrarAlertaPacoteSugerido`)
}

export function marcarComoVistoPacotesSugeridos(
  perfilAtual: string
): Promise<AxiosResponse<boolean>> {
  return api.post(`/usuario/${perfilAtual}/marcarComoVistoPacoteSugerido`)
}
