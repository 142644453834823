import React, { useEffect, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Search } from '@material-ui/icons'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { excluirPacote, obterPacotePorId } from 'src/api/pacote/index'
import useListagem from 'src/hooks/useListagem'
import { EspecialidadeResponse, ObjetoDescricaoGenerico } from 'src/types'

import { Alert } from '@material-ui/lab'
import { AiTwotoneThunderbolt } from 'react-icons/ai'
import { BsInfoCircle } from 'react-icons/bs'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { listarTodasEspecilidades } from 'src/api/especialidade'
import {
  ItemListagemPacoteInteligente,
  ItemListagemPacoteUnificado,
  listagemPacotesUnificados,
  ListagemPacoteUnificadoQuery
} from 'src/api/pacoteInteligente'
import DrawerPacoteInteligenteVisualizarDetalhes from 'src/components/pacoteInteligente/Drawer/resumo'
import Button from 'src/components/ui/Button'
import LoadingGeral from 'src/components/ui/LoadingGeral'
import UBox from 'src/components/ui/Ubox'
import { SynPerfis } from 'src/core/consts/synPerfis'
import { SynRoutes } from 'src/core/consts/synRoutes'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import Swal from 'sweetalert2'
import useWindowInfiniteScroll from '../../../../hooks/useWindowInfiniteScroll'
import ResumoPacote from './resumo'

import Drawer from 'src/components/ui/Drawer'
import { formatarData } from 'src/core/formatacoes/formatarData'
import {
  excluirMembroEquipe,
  ItemListagemMembroEquipe,
  listagemMembroEquipe,
  ListagemMembroEquipeQuery
} from 'src/api/membroEquipe'
import DrawerResumoMembro from 'src/components/membroEquipe/DrawerResumoMembro'
import formatarDocumento from 'src/core/formatacoes/formatarDocumento'
import DrawerSalvarMembro from 'src/components/membroEquipe/DrawerSalvarMembro'
import DrawerSalvarContato from 'src/components/membroEquipe/DrawerSalvarContato'

function ListagemEquipe() {
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))
  const { perfilAtual, user } = useAuth()
  const [mostrarDetalhe, setMostrarDetalhe] = useState(false)
  const [mostrarDrawerContato, setMostrarDrawerContato] = useState(false)
  const [mostrarDrawerSalvar, setMostrarDrawerSalvar] = useState(false)
  const [
    itemSelecionado,
    setItemSelecionado
  ] = useState<ItemListagemMembroEquipe>(undefined)

  const history = useHistory()

  const {
    loading,
    dados,
    handleTermoPesquisa,
    termoPesquisa,
    nenhumDado,
    nenhumResultado,
    dadosCarregados,
    allDadosCarregados,
    allDados,
    qtdAllDados,
    handleProxPagina,
    removerItemListagem,
    filtroGeral,
    carregarListagem,
    setFiltroGeral
  } = useListagem(9, listagem)

  function listagem(query: ListagemMembroEquipeQuery, primeiraBusca?: boolean) {
    query['perfilAtual'] = perfilAtual
    return listagemMembroEquipe(query)
  }

  useWindowInfiniteScroll({
    loadMoreFunc: handleProxPagina,
    data: allDados,
    stopLoadingData: allDados.length >= qtdAllDados
  })

  const handleVisualizarDetalhes = (item: ItemListagemMembroEquipe) => {
    if (perfilAtual !== SynPerfis.PARCEIRO_EXTERNO) {
      setItemSelecionado(item)
      setMostrarDetalhe(true)
    }
  }

  const handleAbrirDrawer = (item: ItemListagemMembroEquipe) => {
    if (perfilAtual !== SynPerfis.PARCEIRO_EXTERNO) {
      setItemSelecionado(item)
      setMostrarDrawerContato(true)
    }
  }

  const handleFecharDetalhes = () => {
    setMostrarDetalhe(false)
    setItemSelecionado(null)
  }

  const handleFecharDrawerSalvar = () => {
    setMostrarDrawerSalvar(false)
    setItemSelecionado(null)
    carregarListagem({ resetarPaginacao: true })
  }

  const handleFecharDrawerContato = () => {
    setMostrarDrawerContato(false)
    setItemSelecionado(null)
  }

  function handleNovo() {
    setItemSelecionado(null)
    setMostrarDrawerSalvar(true)
  }

  function handleEditar(item: ItemListagemMembroEquipe) {
    setItemSelecionado(item)
    setMostrarDrawerSalvar(true)
  }

  async function handleExcluirMembro(item: ItemListagemMembroEquipe) {
    var result = await Swal.fire({
      title: `Deseja excluir o membro "${item.descricao.toUpperCase()}" da equipe?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await excluirMembroEquipe(item.id)
      },
      allowOutsideClick: () => !Swal.isLoading()
    })

    if (result.isDismissed) {
      return
    }

    if (result.value) {
      Swal.fire(
        `"${item.descricao.toUpperCase()}" excluído com sucesso!`,
        '',
        'success'
      )

      removerItemListagem(item.id, 'id')
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text:
          'Não foi possível excluir o membro da equipe. Tente novamente mais tarde ou contate o suporte.'
      })
    }
  }
  //Pacote cotação

  useEffect(() => {}, [])

  function setClassToHighlight(item) {
    if (item?.id === itemSelecionado?.id) return 'highlight'

    return null
  }

  return (
    <Box>
      <UBox>
        {/* e:{JSON.stringify(especialidades)} */}
        {/* <br /> */}
        {/* e:{JSON.stringify(especialidadeSelecionada)}
        <br />
        f:{JSON.stringify(filtroGeral?.especialidades)} */}
        <Box
          display="flex"
          flexDirection={ehXS ? 'column' : 'row'}
          justifyContent="space-between"
          alignItems={ehXS ? 'start' : 'center'}
        >
          <Typography variant="h5">Minha equipe</Typography>

          <Box textAlign="right" width={ehXS ? '100%' : 'auto'}>
            <Button
              onClick={() => handleNovo()}
              fullWidth={ehXS}
              variant="contained"
              color="primary"
            >
              <AddIcon />
              Adicionar membro
            </Button>
          </Box>
        </Box>
        <Box mb={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <UBox pt={2}>
                <TextField
                  fullWidth
                  placeholder="Pesquise por identificador, documento ou nome..."
                  size="small"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  value={termoPesquisa}
                  onChange={handleTermoPesquisa}
                />
              </UBox>
            </Grid>
          </Grid>
        </Box>

        {!loading && (
          <Box mt={3} mb={2} textAlign="left" my={3}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" color="primary">
                  {qtdAllDados
                    ? qtdAllDados > 1
                      ? `${qtdAllDados} membros`
                      : '1 membro'
                    : '0 membros'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {nenhumResultado && (
          <div>Nenhum resultado encontrado para sua pesquisa.</div>
        )}
        {allDadosCarregados && !(loading && !!termoPesquisa) && (
          <div className="fadeInTransition">
            <Grid container spacing={2}>
              {allDados.map(item => (
                <Grid item xs={12} md={4} key={item.id}>
                  <CardMembroEquipe
                    item={item}
                    handleVisualizarDetalhes={handleVisualizarDetalhes}
                    handleAbrirDrawer={handleAbrirDrawer}
                    handleRemoverMembro={handleExcluirMembro}
                    setClassToHighlight={setClassToHighlight}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
        {loading && <LoadingGeral />}
        <Box
          mt={3}
          hidden={qtdAllDados === allDados.length || loading}
          textAlign="center"
        >
          <Button
            fullWidth
            onClick={handleProxPagina}
            variant="text"
            disabled={loading}
            loading={loading}
            endIcon={<MdKeyboardArrowDown />}
          >
            Carregar mais
          </Button>
        </Box>

        {!!mostrarDetalhe && !!itemSelecionado?.id && (
          <DrawerResumoMembro
            id={itemSelecionado?.id}
            handleOnClose={handleFecharDetalhes}
            open={true}
          />
        )}

        {!!mostrarDrawerSalvar && (
          <DrawerSalvarMembro
            id={itemSelecionado?.id}
            handleOnClose={handleFecharDrawerSalvar}
            open={true}
          />
        )}

        {!!mostrarDrawerContato && (
          <DrawerSalvarContato
            id={itemSelecionado?.id}
            item={itemSelecionado}
            handleOnClose={handleFecharDrawerContato}
            open={true}
          />
        )}
      </UBox>
    </Box>
  )
}

export default ListagemEquipe

interface PropCardInteligente {
  item: ItemListagemMembroEquipe
  setClassToHighlight: (item: ItemListagemMembroEquipe) => string
  handleVisualizarDetalhes: (item: ItemListagemMembroEquipe) => void
  handleAbrirDrawer: (item: ItemListagemMembroEquipe) => void
  handleRemoverMembro: (item: ItemListagemMembroEquipe) => void
}

function CardMembroEquipe({
  item,
  setClassToHighlight,
  handleVisualizarDetalhes,
  handleAbrirDrawer,
  handleRemoverMembro
}: PropCardInteligente) {
  return (
    <Card variant="outlined" className={setClassToHighlight(item)}>
      <CardHeader
        action={
          <PopupState variant="popover" popupId="demo-popup-menu">
            {popupState => (
              <React.Fragment>
                <UBox>
                  <IconButton
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    aria-label="mais"
                    {...bindTrigger(popupState)}
                  >
                    <MoreVertIcon />
                  </IconButton>

                  <Menu
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    {...bindMenu(popupState)}
                  >
                    <MenuItem
                      onClick={() => {
                        popupState.close()
                        handleVisualizarDetalhes(item)
                      }}
                    >
                      Visualizar
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        popupState.close()
                        handleAbrirDrawer(item)
                      }}
                    >
                      Alterar contato (comprovante)
                    </MenuItem>
                    <MenuItem
                      style={{ color: 'red' }}
                      onClick={() => {
                        popupState.close()
                        handleRemoverMembro(item)
                      }}
                    >
                      Excluir
                    </MenuItem>
                  </Menu>
                </UBox>
              </React.Fragment>
            )}
          </PopupState>
        }
        title={
          <Typography variant="body1" align="left">
            <Link
              component="button"
              variant="body1"
              color="primary"
              align="left"
              onClick={() => handleVisualizarDetalhes(item)}
            >
              <b style={{ textTransform: 'uppercase' }}>{item.descricao}</b>
            </Link>
          </Typography>
        }
        titleTypographyProps={{ variant: 'subtitle1' }}
        subheaderTypographyProps={{ variant: 'body2' }}
      />
      <CardContent style={{ borderTop: '1px solid #eee' }}>
        <UBox>
          <Typography variant="body2" color="primary">
            <small>
              <b>Nome:</b>
            </small>
          </Typography>
          <Typography variant="body2" color="primary">
            {item.nomeRazaoSocial}
          </Typography>
        </UBox>
        <UBox>
          <Typography variant="body2" color="primary">
            <small>
              <b>Documento:</b>
            </small>
          </Typography>
          <Typography variant="body2" color="primary">
            {formatarDocumento(item.documento)}
          </Typography>
        </UBox>
      </CardContent>
    </Card>
  )
}
