import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Alert } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { RiArrowLeftLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import Sticky from 'react-sticky-el'
import { obterOrcamentosParaSolicitacao } from 'src/api/solicitacao-orcamento'
import DadosOPMEModal from 'src/components/modals/DadosOPMEModal'
import ModalSubmitProposta from 'src/components/modals/ModalSubmitProposta'
import HeaderSolicitacao from 'src/components/solicitacao/HeaderSolicitacao'
import Button from 'src/components/ui/Button'
import Ubox from 'src/components/ui/Ubox'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { useQuery } from 'src/hooks/useQuery'
import {
  IMedico,
  IOPMEResponse,
  IOrcamentoLocal,
  IPaciente,
  IPacoteOrcado,
  ItemExameLaboratorioResponse,
  ObjetoDescricaoGenerico,
  OPMEOrcado
} from 'src/types'
import Swal from 'sweetalert2'

const COR_STATUS = {
  NAO_CONFIGURADO: '#ea3f3f',
  CONFIGURADO: '#8bc34a'
}

const useStyles = makeStyles(theme => ({
  body: {
    marginTop: '-60px'
  },
  accordion: {
    border: '1px solid #efefef',
    boxShadow: 'none',
    boxSizing: 'border-box',
    borderRadius: '4px',
    marginBottom: '16px',
    background: theme.palette.grey[50]
  },
  accordionSemOPME: {
    border: '1px solid #efefef',
    boxShadow: 'none',
    boxSizing: 'border-box',
    borderRadius: '4px',
    marginBottom: '16px',
    background: theme.palette.grey[20],
    color: '#717171',
    cursor: 'default !important'
  },
  accordionSummary: {
    paddingRight: '10px'
  },
  accordionSummarySemOPME: {
    cursor: 'default',
    paddingRight: '10px'
  },
  accordionDetails: {},
  disponivelQuestion: {
    marginRight: '20px',
    marginBottom: '20px'
  },
  inputLabel: {
    marginRight: '20px'
  },
  calculoContent: {
    marginTop: '50px',
    background: 'linear-gradient(to bottom, #455880, #486085)',
    borderRadius: '10px',
    color: '#fff',
    maxHeight: 'calc(100vh - 30px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginLeft: '20px'
  },
  calculoContentXS: {
    marginTop: '50px',
    background: 'linear-gradient(to bottom, #455880, #486085)',
    borderRadius: '10px',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  divider: {
    margin: '10px 0'
  },
  headerDivider: {
    margin: '10px 0 30px 0'
  },
  calculoText: {
    fontSize: '10px'
  },
  submitButton: {
    backgroundColor: '#59F0D5',
    marginTop: '15px',
    color: '#455880',
    height: '50px',
    borderRadius: '10px'
  },
  statusOPME: {
    display: 'inline-block',
    minHeight: '16px',
    minWidth: '16px',
    maxHeight: '16px',
    maxWidth: '16px',
    borderRadius: '50%'
  },
  OPMECard: {
    width: '100%',
    height: '80px',
    margin: '4px 0',
    display: 'flex',
    boxShadow: 'none',
    alignItems: 'center',
    borderRadius: '8px',
    border: '1px solid #ececec',
    justifyContent: 'space-between',
    padding: '10px'
  },
  hospitalCard: {
    color: '#465a81',
    padding: '12px 0 12px 16px',
    textAlign: 'left',
    border: '1px solid #465b82'
  },
  hospitalCardText: {
    margin: '3px 0'
  },
  examesCard: {
    backgroundColor: theme.palette.grey[50],
    padding: '15px'
  },
  numberFormat: {
    width: '160px',
    backgroundColor: 'rgba(255,255,255,0.1) ',
    border: 'none',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottom: '2px solid white',
    outline: 'none',
    fontSize: '18px',
    padding: '8px'
  },
  hospitalGreyCard: {
    color: '#717171',
    padding: '12px 0 12px 16px',
    textAlign: 'center',
    border: '1px solid #717171'
  },
  hospitalRedCard: {
    color: 'red',
    padding: '12px 0 12px 16px',
    textAlign: 'center',
    border: '1px solid red'
  }
}))

const DadosSolicitacao = () => {
  const styles = useStyles()
  const query = useQuery()
  const history = useHistory()

  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))

  const [infoModal, setInfoModal] = useState(false)
  const [valorEquipeAnestesia, setValorEquipeAnestesia] = useState(null)
  const [dadosInfoModal, setDadosInfoModal] = useState<OPMEOrcado>()

  const [loading, setLoading] = useState(true)
  const [enviarLoading, setEnviarLoading] = useState(false)
  const [expanded, setExpanded] = useState<string>('')
  const [valorTotal, setValorTotal] = useState<number>()
  const [propostaSemOPME, setPropostaSemOPME] = useState(false)
  const [
    propostaSemLocalAtendimento,
    setPropostaSemLocalAtendimento
  ] = useState(false)

  const [propostaFinaisDeSemana, setPropostaFinaisDeSemana] = useState(false)

  // PropostaFinaisDeSemana

  const [solicitacaoId, setSolicitacaoId] = useState<string>()
  const [medico, setMedico] = useState<IMedico>()
  const [paciente, setPaciente] = useState<IPaciente>()
  const [solicitacaoSemOPME, setSolicitacaoSemOPME] = useState(false)
  const [
    solicitacaoSemLocalAtendimento,
    setSolicitacaoSemLocalAtendimento
  ] = useState(false)

  const [pacoteProcedimento, setPacoteProcedimento] = useState<IPacoteOrcado>()
  const [listaOPME, setListaOPME] = useState<IOPMEResponse[]>()
  const [locaisAtendimento, setLocaisAtendimento] = useState<
    IOrcamentoLocal[]
  >()

  const [listaExames, setListaExames] = useState<
    ItemExameLaboratorioResponse[]
  >()
  const [
    labSelecionado,
    setLabSelecionado
  ] = useState<ItemExameLaboratorioResponse>()

  const [submitModal, setSubmitModal] = useState(false)

  useEffect(() => {
    const id = query.get('id')
    obterOrcamentosParaSolicitacao(id)
      .then(response => {
        const orcamento = response.data

        setSolicitacaoId(orcamento.id)
        setValorEquipeAnestesia(orcamento.valorEquipeAnestesia)
        setMedico(orcamento.medico)
        setPacoteProcedimento(orcamento.pacoteProcedimento)
        setPaciente(orcamento.paciente)
        setListaOPME(orcamento.listaOPME)
        setListaExames(orcamento.listaExames)
        if (!orcamento.listaOPME || orcamento.listaOPME.length === 0) {
          setSolicitacaoSemOPME(true)
        }
        if (
          !orcamento.listaLocalAtendimento ||
          orcamento.listaLocalAtendimento.length === 0
        ) {
          setSolicitacaoSemLocalAtendimento(true)
        }
        setLocaisAtendimento(orcamento.listaLocalAtendimento)
        setLoading(false)
      })
      .catch(err => {
        showNotificationErrorAPI(err)
      })
  }, [])

  useEffect(() => {
    let valor = 0

    listaOPME?.forEach(o => {
      o.listaOrcamentoOPME?.forEach(i => {
        if (i.selecionado) {
          valor += i.valorUnitario * o.quantidade
        }
      })
    })

    setValorTotal(valor)
  }, [listaOPME])

  /* --------------- handleChangeOPME --------------------- */

  /*
    OBS: os TextFields devem ser multiline pras funções funcionarem.
    Sem tempo pra arrumar
  */
  function obterHospitaisComOrcamento() {
    return locaisAtendimento?.filter(h => h.orcamentoId && !h.justificativa)
  }
  function obterHospitaisSemOrcamento() {
    return locaisAtendimento?.filter(h => !h.orcamentoId)
  }
  function obterHospitaisRecusados() {
    return locaisAtendimento?.filter(h => !!h.justificativa)
  }

  function handleOpenAccordion(panel: string) {
    return (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : '')
    }
  }

  function handleCheckOPME(itemId: string, opmeId: string) {
    const newListaOPME = listaOPME?.map(i => {
      if (i.pacoteProcedimentoOpmeId === opmeId) {
        const newOrcamentos = i.listaOrcamentoOPME?.map(o => {
          if (o.id === itemId) {
            o.selecionado = !o.selecionado
          } else {
            o.selecionado = false
          }

          return o
        })

        i.listaOrcamentoOPME = newOrcamentos
      }

      return i
    })

    setListaOPME(newListaOPME)
  }

  function handleCheckHospital(itemId: string) {
    const newListaHospitais = locaisAtendimento.map(l => {
      if (l.localAtendimentoId === itemId) {
        l.selecionado = !l.selecionado
      }

      return l
    })

    setLocaisAtendimento(newListaHospitais)
  }

  function handlePropostaSemOPME() {
    setPropostaSemOPME(!propostaSemOPME)

    const newListaOPME = listaOPME?.map(i => {
      const newOrcamentos = i.listaOrcamentoOPME?.map(o => {
        o.selecionado = false

        return o
      })

      i.listaOrcamentoOPME = newOrcamentos
      return i
    })

    setExpanded(undefined)
    setListaOPME(newListaOPME)
  }

  function handlePropostaSemLocalAtendimento() {
    setPropostaSemLocalAtendimento(!propostaSemLocalAtendimento)

    const novaListaLocalAtendimento = locaisAtendimento?.map(local => {
      local.selecionado = false
      return local
    })

    setLocaisAtendimento(novaListaLocalAtendimento)
  }
  /* -------------------------------------------------- */

  async function onSubmit() {
    const opmeValid = listaOPME.map(o => {
      let valid = false
      if (o.listaOrcamentoOPME?.find(orcamento => orcamento.selecionado)) {
        valid = true
      }
      return valid
    })
    const canSubmit = opmeValid.find(o => !o)

    const hospitalSelecionado = locaisAtendimento.find(h => !!h.selecionado)
    if (
      !hospitalSelecionado &&
      !solicitacaoSemLocalAtendimento &&
      !propostaSemLocalAtendimento
    ) {
      return Swal.fire({
        title: 'Atenção!',
        text: 'Selecione pelo menos 1 hospital solicitado',
        icon: 'error',
        confirmButtonText: 'Fechar'
      })
    }

    if (canSubmit === false && !propostaSemOPME) {
      return Swal.fire({
        title: 'Atenção!',
        text: 'Selecione um orçamento para cada OPME solicitado',
        icon: 'error',
        confirmButtonText: 'Fechar'
      })
    }

    if (!labSelecionado && !!listaExames.length) {
      return Swal.fire({
        title: 'Atenção!',
        text: 'Selecione um laboratório para realizar os exames',
        icon: 'error',
        confirmButtonText: 'Fechar'
      })
    }

    setSubmitModal(true)
  }

  if (loading) {
    return (
      <Box minHeight="300px" textAlign="center">
        <CircularProgress />
        <div>Carregando...</div>
      </Box>
    )
  }

  function setStatusOPME(item: IOPMEResponse) {
    const selecionado = item.listaOrcamentoOPME?.find(i => i.selecionado)
    let background = selecionado
      ? COR_STATUS.CONFIGURADO
      : COR_STATUS.NAO_CONFIGURADO

    return (
      <Box
        mr={2}
        className={styles.statusOPME}
        style={{ backgroundColor: background }}
      />
    )
  }

  return (
    <Box>
      <Link
        component="button"
        color="primary"
        onClick={() => history.goBack()}
        style={{ marginBottom: '20px' }}
      >
        <RiArrowLeftLine />
        <Typography
          style={{ marginLeft: 10 }}
          variant="subtitle1"
          component="span"
        >
          Voltar
        </Typography>
      </Link>
      <HeaderSolicitacao
        solicitacao
        paciente={paciente}
        pacoteProcedimento={pacoteProcedimento}
        medicoNome={medico.nome}
        comorbidades={paciente.comorbidades as ObjetoDescricaoGenerico[]}
        obsComorbidades={paciente.observacaoComorbidades}
      />

      <Grid container spacing={1} className={styles.body}>
        {/* ------------------- Accordion ----------------- */}
        <Grid item xs={12} sm={8}>
          <Box mt={5}>
            {!solicitacaoSemOPME && (
              <Box my={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={propostaSemOPME}
                  onChange={() => handlePropostaSemOPME()}
                />
                <Typography variant="subtitle1">
                  Desejo gerar proposta sem OPME
                </Typography>
              </Box>
            )}
            <Box mb={1} hidden={!propostaSemOPME}>
              <Alert severity="info">
                <Typography variant="body2">
                  Essa proposta não irá conter lista de OPME.
                </Typography>
              </Alert>
            </Box>
            <Box mb={1} hidden={!solicitacaoSemOPME}>
              <Alert severity="info">
                <Typography variant="body2">
                  A solicitação atual não possui lista de OPME.
                </Typography>
              </Alert>
            </Box>
            <Box hidden={propostaSemOPME || solicitacaoSemOPME}>
              <Box mb={1}>
                <Typography variant="h6">Lista de OPME solicitados</Typography>
                <Box display="flex" alignItems="center">
                  <Box
                    mr={1}
                    className={styles.statusOPME}
                    style={{ backgroundColor: COR_STATUS.NAO_CONFIGURADO }}
                  />
                  <Typography variant="subtitle2">Não selecionado</Typography>

                  <Box
                    ml={2}
                    mr={1}
                    className={styles.statusOPME}
                    style={{ backgroundColor: COR_STATUS.CONFIGURADO }}
                  />
                  <Typography variant="subtitle2">Selecionado</Typography>
                </Box>
              </Box>
              {listaOPME?.map(item => (
                <Accordion
                  expanded={
                    expanded === item.pacoteProcedimentoOpmeId &&
                    !propostaSemOPME
                  }
                  onChange={handleOpenAccordion(item.pacoteProcedimentoOpmeId)}
                  className={
                    propostaSemOPME ? styles.accordionSemOPME : styles.accordion
                  }
                  key={item.pacoteProcedimentoOpmeId}
                >
                  <AccordionSummary
                    expandIcon={!propostaSemOPME ? <ExpandMoreIcon /> : null}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    style={propostaSemOPME ? { cursor: 'default' } : null}
                  >
                    <Grid
                      className={
                        propostaSemOPME
                          ? styles.accordionSummarySemOPME
                          : styles.accordionSummary
                      }
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {setStatusOPME(item)}
                        <Typography variant="body1">
                          <Typography component="span">
                            {`${item.quantidade}x `}
                          </Typography>
                          {item.nome}
                        </Typography>
                      </div>
                      {!propostaSemOPME && (
                        <Typography>
                          <Chip
                            size="small"
                            label={`${
                              item.listaOrcamentoOPME
                                ? item.listaOrcamentoOPME?.length
                                : 0
                            } ${
                              item.listaOrcamentoOPME?.length === 1
                                ? 'orçamento'
                                : 'orçamentos'
                            }
                        `}
                          />
                        </Typography>
                      )}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails className={styles.accordionDetails}>
                    <Grid container direction="column">
                      <Box mb={2}>
                        <Alert severity="info">
                          <Typography variant="body2">
                            <b>Marca preferida: </b>
                            {item.marca ? item.marca : 'Sem preferência'}
                            <br />
                            <b>Observação do médico: </b>
                            {item.observacao
                              ? item.observacao
                              : 'Sem Observação'}
                          </Typography>
                        </Alert>
                      </Box>
                      {!item.listaOrcamentoOPME ? (
                        <Typography>
                          Nenhum orçamento realizado para este OPME.
                        </Typography>
                      ) : (
                        item.listaOrcamentoOPME?.map(f => (
                          <Grid container key={f.id}>
                            <Grid item xs={12}>
                              <Card className={!ehXS ? styles.OPMECard : null}>
                                <Grid container>
                                  <Grid item md={1}>
                                    {f.disponivel && (
                                      <Checkbox
                                        key={f.id + f.selecionado}
                                        checked={f.selecionado}
                                        onChange={() =>
                                          handleCheckOPME(
                                            f.id,
                                            item.pacoteProcedimentoOpmeId
                                          )
                                        }
                                      />
                                    )}
                                  </Grid>
                                  <Grid item md={11}>
                                    <Grid
                                      container
                                      direction="row"
                                      justify="space-between"
                                      alignItems="center"
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'flex-start',
                                          marginLeft: '20px'
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          component="span"
                                        >
                                          {f.nomeParceiro}
                                        </Typography>
                                        <Link
                                          component="button"
                                          variant="subtitle2"
                                          onClick={() => {
                                            setDadosInfoModal(f)
                                            setInfoModal(true)
                                          }}
                                        >
                                          Ver mais
                                        </Link>
                                      </div>

                                      {f.disponivel && (
                                        <Typography variant="body2">
                                          <Box mr={1} component="span">
                                            <Typography
                                              variant="subtitle2"
                                              component="span"
                                            >
                                              Fabricante:
                                            </Typography>
                                          </Box>
                                          <br />
                                          {f.fabricante
                                            ? f.fabricante
                                            : 'Não informado'}
                                        </Typography>
                                      )}
                                      {!f.disponivel ? (
                                        <Typography variant="body2">
                                          Não disponível
                                        </Typography>
                                      ) : (
                                        <>
                                          <div
                                            style={
                                              !ehXS
                                                ? {
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                  }
                                                : null
                                            }
                                          >
                                            <div>
                                              <Typography
                                                variant="subtitle2"
                                                component="span"
                                              >
                                                Valor un.
                                              </Typography>
                                              <Typography variant="body2">
                                                {formatarDinheiro(
                                                  f.valorUnitario
                                                )}
                                              </Typography>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Card>
                            </Grid>
                          </Grid>
                        ))
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>

            {listaExames.length !== 0 && (
              <Box width="100" mt="40px">
                <Typography variant="h6">Exames solicitados</Typography>
                <Box mt={2}>
                  {listaExames.map(item => {
                    return (
                      <Accordion
                        key={item.laboratorioId}
                        className={styles.accordion}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              width: '100%'
                            }}
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Checkbox
                                checked={labSelecionado === item}
                                onChange={() => setLabSelecionado(item)}
                              />

                              <Typography variant="subtitle2">
                                {item.laboratorioNome}
                              </Typography>
                            </div>

                            <div>
                              <Typography variant="subtitle2">
                                <strong>Valor Total: </strong>
                                {formatarDinheiro(item.valorTotal)}
                              </Typography>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Código</TableCell>
                                <TableCell>Descricao</TableCell>
                                <TableCell>Valor</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item.exames.map(e => {
                                return (
                                  <TableRow key={e.id}>
                                    <TableCell>{e.codigo}</TableCell>
                                    <TableCell>{e.descricao}</TableCell>
                                    <TableCell>
                                      {formatarDinheiro(e.valor)}
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </Box>
              </Box>
            )}

            <Box width="100%">
              {!solicitacaoSemLocalAtendimento && (
                <Box mt={2} style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={propostaSemLocalAtendimento}
                    onChange={() => handlePropostaSemLocalAtendimento()}
                  />
                  <Typography variant="subtitle1">
                    Desejo gerar proposta sem local de atendimento
                  </Typography>
                </Box>
              )}
              <Box mb={1} hidden={!propostaSemLocalAtendimento}>
                <Alert severity="info">
                  <Typography variant="body2">
                    Essa proposta não irá conter local de atendimento.
                  </Typography>
                </Alert>
              </Box>
              <Box mb={1} hidden={!solicitacaoSemLocalAtendimento}>
                <Alert severity="info">
                  <Typography variant="body2">
                    A solicitação atual não possui local de atendimento.
                  </Typography>
                </Alert>
              </Box>

              <Box
                style={{
                  display: 'flex',
                  alignItems: 'end'
                }}
                mb={2}
              >
                <Checkbox
                  value={propostaFinaisDeSemana}
                  checked={propostaFinaisDeSemana}
                  onChange={(e, value) => setPropostaFinaisDeSemana(value)}
                />
                <Typography variant="subtitle1" style={{ lineHeight: '1rem' }}>
                  Gerar proposta para finais de semana e feriados
                  <br />
                  <small>
                    <strong>
                      (sábado a partir de 12h até domingo 23h59 e feriados)
                    </strong>
                  </small>
                </Typography>
              </Box>
            </Box>

            <Ubox
              render={
                !propostaSemLocalAtendimento && !solicitacaoSemLocalAtendimento
              }
            >
              <Box mb={1}>
                <Typography variant="h6">Hospitais solicitados</Typography>
              </Box>
              <Box mt={1}>
                <Typography variant="subtitle2">
                  Orçamentos realizados
                </Typography>
              </Box>

              <Divider style={{ marginBottom: '20px' }} />
              {!obterHospitaisComOrcamento().length && (
                <Box py={2}>Ainda não possui orçamentos realizados</Box>
              )}
              <Grid container spacing={2}>
                {obterHospitaisComOrcamento()?.map(h => (
                  <Grid item xs={12} key={h.localAtendimentoId}>
                    <Card
                      className={styles.hospitalCard}
                      key={h.localAtendimentoId}
                    >
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        alignItems="center"
                      >
                        <Grid item xs={1}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginRight: '10px'
                            }}
                          >
                            <Checkbox
                              checked={h.selecionado}
                              onChange={() =>
                                handleCheckHospital(h.localAtendimentoId)
                              }
                            />
                          </div>
                        </Grid>
                        <Grid item xs={11}>
                          <Typography variant="subtitle1">
                            <b>{h.nome}</b>
                          </Typography>
                          <Box>
                            <Grid container spacing={0} alignItems="flex-end">
                              <Grid
                                item
                                xs={8}
                                className={styles.hospitalCardText}
                              >
                                <Typography variant="subtitle2">
                                  Valor hospital:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                className={styles.hospitalCardText}
                              >
                                <Typography align="left" variant="body2">
                                  {h.valorSala
                                    ? formatarDinheiro(h.valorSala)
                                    : 'R$ 0,00'}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={8}
                                className={styles.hospitalCardText}
                              >
                                <Typography
                                  variant="subtitle2"
                                  className={styles.hospitalCardText}
                                >
                                  Valor anestesia
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                className={styles.hospitalCardText}
                              >
                                <Typography align="left" variant="body2">
                                  {h.ehAnestesiaEquipeMedica ? (
                                    '(Orçado pela equipe médica)'
                                  ) : (
                                    <>
                                      {h.valorAnestesia >= 0
                                        ? formatarDinheiro(h.valorAnestesia)
                                        : 'Não informado'}
                                    </>
                                  )}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={8}
                                className={styles.hospitalCardText}
                              >
                                <Typography
                                  variant="subtitle2"
                                  className={styles.hospitalCardText}
                                >
                                  Valor bolsa de sangue:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                className={styles.hospitalCardText}
                              >
                                <Typography align="left" variant="body2">
                                  {h.valorBolsaSangue >= 0
                                    ? formatarDinheiro(h.valorBolsaSangue || 0)
                                    : 'Não informado'}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={8}
                                className={styles.hospitalCardText}
                              >
                                <Typography
                                  variant="subtitle2"
                                  className={styles.hospitalCardText}
                                >
                                  Valor reserva de sangue:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                className={styles.hospitalCardText}
                              >
                                <Typography align="left" variant="body2">
                                  {h.valorReservaSangue >= 0
                                    ? formatarDinheiro(
                                        h.valorReservaSangue || 0
                                      )
                                    : 'Não informado'}
                                </Typography>
                              </Grid>

                              {h.valorPreAnestesica != null &&
                                h.valorPreAnestesica >= 0 && (
                                  <>
                                    <Grid
                                      item
                                      xs={8}
                                      className={styles.hospitalCardText}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        className={styles.hospitalCardText}
                                      >
                                        Valor avaliação pré-anestésica:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      className={styles.hospitalCardText}
                                    >
                                      <Typography align="left" variant="body2">
                                        {h.valorPreAnestesica >= 0
                                          ? formatarDinheiro(
                                              h.valorPreAnestesica
                                            )
                                          : 'R$ 0, 00'}
                                      </Typography>
                                    </Grid>
                                  </>
                                )}
                              {h.valorRiscoCirurgico != null &&
                                h.valorRiscoCirurgico >= 0 && (
                                  <>
                                    <Grid
                                      item
                                      xs={8}
                                      className={styles.hospitalCardText}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        className={styles.hospitalCardText}
                                      >
                                        Valor avaliação risco cirúrgico:
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={4}
                                      className={styles.hospitalCardText}
                                    >
                                      <Typography align="left" variant="body2">
                                        {h.valorRiscoCirurgico >= 0
                                          ? formatarDinheiro(
                                              h.valorRiscoCirurgico
                                            )
                                          : 'R$ 0,00'}
                                      </Typography>
                                    </Grid>
                                  </>
                                )}
                              <Grid
                                item
                                xs={8}
                                className={styles.hospitalCardText}
                              >
                                <Typography variant="subtitle2">
                                  Taxa OPME ({h.taxa}%):
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                className={styles.hospitalCardText}
                              >
                                <Typography align="left" variant="body2">
                                  {formatarDinheiro(
                                    (valorTotal * h.taxa) / 100
                                  ) || ' R$ 0,00'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>

                        {h.observacao && (
                          <>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={11}>
                              <Divider />
                              <Typography variant="subtitle2">
                                <strong>Observação:</strong>
                              </Typography>
                              <Typography variant="subtitle2">
                                {h.observacao}
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              {obterHospitaisRecusados().length > 0 && (
                <>
                  <Box mt={3}>
                    <Typography variant="subtitle2">
                      Orçamentos recusados
                    </Typography>
                  </Box>
                  <Divider style={{ marginBottom: '20px' }} />
                  <Grid container spacing={2}>
                    {obterHospitaisRecusados().map(h => (
                      <Grid item xs={12} sm={6} key={h.localAtendimentoId}>
                        <Card
                          className={styles.hospitalRedCard}
                          key={h.localAtendimentoId}
                        >
                          <Grid container spacing={1}>
                            <Grid item xs={11}>
                              <Typography align="left" variant="subtitle1">
                                <b>{h.nome}</b>
                              </Typography>
                              <Box>
                                <Typography align="left" variant="subtitle2">
                                  <strong>Justificativa: </strong>
                                  {h.justificativa}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}

              <Box mt={3}>
                <Typography variant="subtitle2">
                  Aguardando orçamento
                </Typography>
              </Box>

              <Divider style={{ marginBottom: '20px' }} />
              {!obterHospitaisSemOrcamento().length && (
                <Box py={2}>Todos os hospitais foram orçados</Box>
              )}
              <Grid container spacing={2}>
                {obterHospitaisSemOrcamento().map(h => (
                  <Grid item xs={12} sm={6} key={h.localAtendimentoId}>
                    <Card
                      className={styles.hospitalGreyCard}
                      key={h.localAtendimentoId}
                    >
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        alignItems="center"
                      >
                        <Grid item xs={11}>
                          <Typography variant="subtitle1">
                            <b>{h.nome}</b>
                          </Typography>
                          <Box>
                            <Grid container spacing={0} alignItems="flex-end">
                              <Grid
                                item
                                xs={6}
                                className={styles.hospitalCardText}
                              >
                                <Typography variant="subtitle2">
                                  Valor hospital:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                className={styles.hospitalCardText}
                              >
                                <Typography align="left" variant="body2">
                                  {h.valorSala
                                    ? formatarDinheiro(h.valorSala)
                                    : 'Não informado'}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                className={styles.hospitalCardText}
                              >
                                <Typography
                                  variant="subtitle2"
                                  className={styles.hospitalCardText}
                                >
                                  Valor anestesia:
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                className={styles.hospitalCardText}
                              >
                                <Typography align="left" variant="body2">
                                  {h.valorAnestesia
                                    ? formatarDinheiro(h.valorAnestesia)
                                    : 'Não informado'}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                className={styles.hospitalCardText}
                              >
                                <Typography variant="subtitle2">
                                  Taxa OPME ({h.taxa}%):
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                className={styles.hospitalCardText}
                              >
                                <Typography align="left" variant="body2">
                                  {formatarDinheiro(
                                    (valorTotal * h.taxa) / 100
                                  ) || ' R$ 0,00'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Ubox>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Sticky>
            <Box
              px={ehXS ? 1 : 5}
              py={2}
              className={ehXS ? styles.calculoContentXS : styles.calculoContent}
              width="100%"
            >
              <Box minHeight="200px">
                {listaOPME?.map(opme => {
                  return opme.listaOrcamentoOPME?.map(item => {
                    return (
                      item.selecionado && (
                        <Box key={item.id} mb={1}>
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-end"
                          >
                            <Typography
                              variant="body2"
                              className={ehXS && styles.calculoText}
                              style={{ maxWidth: '40%' }}
                            >
                              {opme.nome}:
                            </Typography>
                            <Typography
                              variant="body2"
                              className={ehXS && styles.calculoText}
                            >
                              x{'   '}
                              {opme.quantidade} ={' '}
                              {formatarDinheiro(
                                opme.quantidade * item.valorUnitario
                              ) || ' R$ 0,00'}
                            </Typography>
                          </Grid>
                          <Divider className={styles.divider} />
                        </Box>
                      )
                    )
                  })
                })}
              </Box>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Typography variant="h6">Total OPME:</Typography>
                <Typography variant="h6">
                  {formatarDinheiro(valorTotal) || ' R$ 0,00'}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  className={styles.submitButton}
                  onClick={() => onSubmit()}
                  loading={enviarLoading}
                  disabled={enviarLoading}
                  secondaryLoading
                >
                  Gerar proposta
                </Button>
              </Grid>
            </Box>
          </Sticky>
        </Grid>
      </Grid>
      {infoModal && (
        <DadosOPMEModal
          open={infoModal}
          onClose={() => setInfoModal(false)}
          item={dadosInfoModal}
        />
      )}
      {submitModal && (
        <ModalSubmitProposta
          open={submitModal}
          onClose={() => setSubmitModal(false)}
          listaOpme={!propostaSemOPME && !solicitacaoSemOPME && listaOPME}
          valorOpme={valorTotal}
          valorEquipeAnestesia={valorEquipeAnestesia}
          laboratorio={labSelecionado}
          hospitais={locaisAtendimento
            .map(i => {
              if (i.selecionado) {
                return i
              }

              return
            })
            .filter(i => !!i)}
          honorarioMedico={pacoteProcedimento.honorarioEquipeMedica}
          solicitacaoId={solicitacaoId}
          propostaFinaisDeSemana={propostaFinaisDeSemana}
        />
      )}
    </Box>
  )
}

export default DadosSolicitacao
