import Chip from '@material-ui/core/Chip'

export const PERFIS_MEDICOS_PIPEDRIVE = [
  'Detrator',
  'Neutro',
  'Novo',
  'Adotador',
  'Promotor'
]
export const PerfilPipedrive = {
  DETRATOR: {
    backgroundColor: '#fab4b4',
    descricao: 'Detrator'
  },
  NEUTRO: {
    backgroundColor: '#eee',
    descricao: 'Neutro'
  },
  NOVO: {
    backgroundColor: '#7fe7ff',
    descricao: 'Novo'
  },
  ADOTADOR: {
    backgroundColor: '#FF8C00',
    descricao: 'Adotador'
  },
  PROMOTOR: {
    backgroundColor: '#aafaa4',
    descricao: 'Promotor'
  }
}

export function renderChipPerfilPipedrive(perfil: string) {
  if (perfil === PerfilPipedrive.DETRATOR.descricao) {
    return (
      <Chip
        label={PerfilPipedrive.DETRATOR.descricao}
        size="small"
        style={{
          backgroundColor: PerfilPipedrive.DETRATOR.backgroundColor
        }}
      />
    )
  } else if (perfil === PerfilPipedrive.NOVO.descricao) {
    return (
      <Chip
        label={PerfilPipedrive.NOVO.descricao}
        size="small"
        style={{
          backgroundColor: PerfilPipedrive.NOVO.backgroundColor
        }}
      />
    )
  } else if (perfil === PerfilPipedrive.NEUTRO.descricao) {
    return (
      <Chip
        label={PerfilPipedrive.NEUTRO.descricao}
        size="small"
        style={{
          backgroundColor: PerfilPipedrive.NEUTRO.backgroundColor
        }}
      />
    )
  } else if (perfil === PerfilPipedrive.ADOTADOR.descricao) {
    return (
      <Chip
        label={PerfilPipedrive.ADOTADOR.descricao}
        size="small"
        style={{
          backgroundColor: PerfilPipedrive.ADOTADOR.backgroundColor
        }}
      />
    )
  } else if (perfil === PerfilPipedrive.PROMOTOR.descricao) {
    return (
      <Chip
        label={PerfilPipedrive.PROMOTOR.descricao}
        size="small"
        style={{
          backgroundColor: PerfilPipedrive.PROMOTOR.backgroundColor
        }}
      />
    )
  }
}
