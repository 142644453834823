import { CircularProgress, Grid, Typography } from '@material-ui/core'

interface IProps {
  title?: string
  small?: boolean
}
export default function LoadingGeral({
  small = false,
  title = 'Carregando...'
}: IProps) {
  return (
    <Grid
      direction={small ? 'row' : 'column'}
      item
      xs={12}
      style={{
        padding: small ? 0 : 48,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <CircularProgress />
      <Typography variant="subtitle1" component="p">
        {title}
      </Typography>
    </Grid>
  )
}
