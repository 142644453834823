const INTERNACOES = [
  {
    id: '7b4b9b2d-8739-408b-9315-c079b0ed19f3',
    tipo: 'U',
    codigo: 'PQA',
    descricao: 'PQA (até 6h de internação)',
    valor: 0
  },
  {
    id: '2b1dfe58-3bab-4e80-934e-14ce960412d9',
    tipo: 'U',
    codigo: 'DAY_CLINIC',
    descricao: 'Day Clinic (até 12h de internação)',
    valor: 0
  },
  {
    id: '14ceed1f-ce1e-4fca-8bba-90496f5d8b61',
    tipo: 'D',
    codigo: 'INT',
    descricao: 'Internação',
    ehInternacao: true,
    valor: 0
  },
  // {
  //   id: 'feafd42a-8907-487c-a7e5-7f1827a23dca',
  //   tipo: 'D',
  //   codigo: 'APT',
  //   descricao: 'Apartamento',
  //   valor: 0
  // },
  {
    id: '99dd63a7-15d5-4552-aea1-dd6303974550',
    tipo: 'D',
    codigo: 'UTI',
    descricao: 'Diária de UTI',
    valor: 0
  }
]

export const INTERNACOES_OBJ = {
  PQA: {
    id: '7b4b9b2d-8739-408b-9315-c079b0ed19f3',
    tipo: 'U',
    codigo: 'PQA',
    descricao: 'PQA (até 6h de internação)',
    valor: 0
  },
  DAY_CLINIC: {
    id: '2b1dfe58-3bab-4e80-934e-14ce960412d9',
    tipo: 'U',
    codigo: 'DAY_CLINIC',
    descricao: 'Day Clinic (até 12h de internação)',
    valor: 0
  },
  INT: {
    id: '14ceed1f-ce1e-4fca-8bba-90496f5d8b61',
    tipo: 'D',
    codigo: 'INT',
    descricao: 'Internação',
    valor: 0
  },
  // APT: {
  //   id: 'feafd42a-8907-487c-a7e5-7f1827a23dca',
  //   tipo: 'D',
  //   codigo: 'APT',
  //   descricao: 'Apartamento',
  //   valor: 0
  // },
  UTI: {
    id: '99dd63a7-15d5-4552-aea1-dd6303974550',
    tipo: 'D',
    codigo: 'UTI',
    descricao: 'Diária de UTI',
    valor: 0
  }
}

export default INTERNACOES
