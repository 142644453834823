import { AxiosResponse } from 'axios'
import {
  AlterarSenhaCommand,
  AuthResponse,
  DefinirSenhaCommand,
  EsqueciSenhaCommand,
  LoginQuery,
  ResetarSenhaCommand
} from 'src/types'
import api from '../../services/api'

export function authLogin(
  query: LoginQuery
): Promise<AxiosResponse<AuthResponse>> {
  try {
    return api.post('/auth/login', query)
  } catch (err) {
    // ;+arguments.callee.name
    console.log('@ERROR: ', err)
  }
}

export function esqueciSenha(
  command: EsqueciSenhaCommand
): Promise<AxiosResponse> {
  try {
    return api.post('/auth/EsqueciSenha', command)
  } catch (err) {
    // ;+arguments.callee.name
    console.log('@ERROR: ', err)
  }
}

export function definirSenhaToken(
  command: DefinirSenhaCommand
): Promise<AxiosResponse> {
  try {
    return api.post('/auth/DefinirSenhaToken', command)
  } catch (err) {
    // ;+arguments.callee.name
    console.log('@ERROR: ', err)
  }
}

export function definirSenha(
  command: DefinirSenhaCommand
): Promise<AxiosResponse> {
  try {
    return api.post('/auth/DefinirSenha', command)
  } catch (err) {
    // ;+arguments.callee.name
    console.log('@ERROR: ', err)
  }
}

export const alterarSenha: (
  command: AlterarSenhaCommand
) => Promise<AxiosResponse<string | undefined>> = command => {
  try {
    const body: AlterarSenhaCommand = {
      senhaAtual: command.senhaAtual,
      novaSenha: command.novaSenha
    }
    return api.post('/auth/alterarSenha', body)
  } catch (err) {
    console.log('@ERROR: ', err)
  }
}

export function aceitarTermoDeUso(tipoTermo: number): Promise<AxiosResponse> {
  return api.post(`/auth/aceitarTermo/${tipoTermo}`)
}

export function resetarSenha(
  command: ResetarSenhaCommand
): Promise<AxiosResponse> {
  try {
    return api.post('/auth/resetarsenha', command)
  } catch (err) {
    // ;+arguments.callee.name
    console.log('@ERROR: ', err)
  }
}
