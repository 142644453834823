import {
  Box,
  Button,
  Checkbox,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useEffect, useState } from 'react'
import useAuth from 'src/hooks/useAuth'
import ModalGenerico from './ModalGenerico'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import UBox from '../ui/Ubox'

// Import Swiper styles
import { Keyboard, Navigation, Pagination } from 'swiper'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/swiper-bundle.css'
import 'swiper/swiper.min.css'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialogContent-root': {
      padding: '0 !important'
    }
  },
  body: {},
  bgPurple: {
    // background: theme.palette.secondary.main,
    color: '#fff',
    marginTop: 'auto'
  },
  closeButton: {
    cursor: 'pointer',
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  modalButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '20px'
  },
  mainDate: {
    color: '#717171',
    margin: '5px 0'
  },
  normalDescription: {
    marginTop: '5px',
    whiteSpace: 'pre-line'
  },
  swiper: {
    '& .swiper': {
      width: '100%',
      height: '100%'
    },
    '& .swiper-slide': {
      textAlign: 'center',
      fontSize: '18px',
      // background: '#333',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      minHeight: theme.breakpoints.down('sm') ? 'calc(60vh - 40px)' : '45vh',
      marginTop: theme.breakpoints.down('sm') ? 'auto' : '0'
    },

    '& .swiper-slide img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  }
}))
const tutorial = [
  {
    xs: (
      <img
        src="/image/logo.png"
        style={{ maxWidth: '130px' }}
        alt="Como criar pacote"
      />
    ),
    lg: (
      <img
        src="/image/logo.png"
        style={{ maxWidth: '130px' }}
        alt="Como criar pacote"
      />
    ),
    // titulo: 'Pacote de procedimento',
    texto: (
      <p style={{ textAlign: 'center' }}>
        Olá, separamos algumas dicas para o melhor uso da plataforma.
        <br />
        Confira nos próximos slides!
      </p>
    ),
    background: '#1cc3a5'
  },
  {
    xs: (
      <img
        src="/image/tutorial/1-xs.png"
        style={{ maxWidth: '380px' }}
        alt="Como criar pacote"
      />
    ),
    lg: (
      <img
        src="/image/tutorial/1.png"
        style={{ maxWidth: '850px' }}
        alt="Como criar pacote"
      />
    ),
    // titulo: 'Pacote de procedimento',
    texto: (
      <p>
        Pacotes são procedimentos médicos pré configurados para solicitar
        orçamentos com mais facilidade e rapidez.
        <br />
        Você pode criar um novo pacote á qualquer momento acessando o menu
        superior e clicando em "NOVO PACOTE"
        <br />
        {'Menu > Meus pacotes > Novo pacote'}
      </p>
    ),
    background: '#1cc3a5'
  },
  {
    xs: (
      <img
        src="/image/tutorial/2-xs.png"
        style={{ maxWidth: '360px', alignSelf: 'flex-end' }}
        alt="Como criar pacote"
      />
    ),
    lg: (
      <img
        src="/image/tutorial/2.png"
        style={{ maxWidth: '850px' }}
        alt="Como criar pacote"
      />
    ),
    texto: (
      <p>
        Você pode solicitar um orçamento a partir de um pacote configurado.
        Clicando no ícone mais e "Solicitar orçamento."
        <br />
        {'Menu > Solicitações >  Solicitar cirurgia'}
      </p>
    ),
    background: '#f5444f'
  },
  {
    xs: (
      <img
        src="/image/tutorial/3-xs.png"
        style={{ maxWidth: '360px', alignSelf: 'flex-end' }}
        alt="Como criar pacote"
      />
    ),
    lg: (
      <img
        src="/image/tutorial/3.png"
        style={{ maxWidth: '900px' }}
        alt="Como criar pacote"
      />
      // <div
      //   style={{
      //     maxWidth: '900px',
      //     width: '900px',
      //     height: '360px',
      //     background: 'url(/image/tutorial/3.png) '
      //   }}
      // />
    ),
    texto: (
      <p>
        Cadastre seus assistentes para acompanhar suas solicitações de
        cirurgias.
        <br />
        {'Menu > Assistentes > Adicionar assistente'}
      </p>
    ),
    background: '#455880'
  },
  {
    xs: (
      <img
        src="/image/tutorial/4-xs.png"
        style={{ maxWidth: '360px', alignSelf: 'end' }}
        alt="Como criar pacote"
      />
    ),
    lg: (
      <img
        src="/image/tutorial/4.png"
        style={{ maxWidth: '900px', alignSelf: 'end' }}
        alt="Como criar pacote"
      />
      // <div
      //   style={{
      //     maxWidth: '960px',
      //     width: '960px',
      //     height: '360px',
      //     background: 'url(/image/tutorial/4.png) ',
      //     backgroundSize: '100%'
      //   }}
      // />
    ),
    texto: (
      <p>
        Convide seus colegas médicos para fazerem parte da SYN! Ajude a
        transformar a vida de cada vez mais pessoas, provendo acessibilidade e
        praticidade para realizar os procedimentos cirúrgicos.
        <br />
        {'Menu > Convidar colega > Enviar convite'}
      </p>
    ),
    background: '#455880'
  }
]

export default function ModalTutorial() {
  const styles = useStyles()
  const theme = useTheme()
  const { user, toggleTutorial, tutorialAberto } = useAuth()

  const ehXS = useMediaQuery(theme.breakpoints.down('sm'))

  const [verNovamente, setVerNovamente] = useState(true)
  const [position, setPosition] = useState(0)

  useEffect(() => {}, [])

  async function onClose() {
    toggleTutorial(false, verNovamente)
  }

  useEffect(() => {
    setPosition(0)
  }, [tutorialAberto])

  useEffect(() => {
    setVerNovamente(!!user?.mostrarTutorial)
  }, [user?.mostrarTutorial])

  return (
    <ModalGenerico
      maxWidth="md"
      open={tutorialAberto}
      title={ehXS ? 'Primeiros passos' : ''}
      className={styles.root}
    >
      <Box p={0} className={styles.body} display="flex" flexDirection="column">
        <UBox mb={5}>
          <UBox pt={ehXS ? 0 : 2} pl={2}>
            <Typography variant="body1">
              <Checkbox
                checked={!verNovamente}
                onChange={() => setVerNovamente(!verNovamente)}
              />
              Não quero ver novamente
            </Typography>
          </UBox>
          <IconButton
            aria-label="close"
            className={styles.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </UBox>

        <UBox minHeight={ehXS ? 'calc(60vh - 40px)' : '45vh'}>
          <Swiper
            // spaceBetween={50}
            slidesPerView={1}
            keyboard={{ enabled: true }}
            onSlideChange={swiper => setPosition(swiper.activeIndex)}
            onSwiper={swiper => console.log(swiper)}
            pagination={ehXS}
            navigation={true}
            modules={[Keyboard, Navigation, Pagination]}
            className={styles.swiper}
          >
            {tutorial.map((t, index) => (
              <SwiperSlide key={index}>{ehXS ? t.xs : t.lg}</SwiperSlide>
            ))}
          </Swiper>
        </UBox>
        <UBox
          className={styles.bgPurple}
          p={3}
          pb={5}
          minHeight={ehXS ? '30vh' : 'auto'}
          style={{ background: tutorial[position].background }}
        >
          {/* <Typography variant="h6" style={{ fontWeight: 500 }}>
            {tutorial[position].titulo}
          </Typography> */}
          <Typography variant="body2" style={{ fontWeight: 500 }}>
            {tutorial[position].texto}
          </Typography>
          <UBox render={ehXS} className={styles.modalButtons}>
            <Button
              style={{
                background: '#FF8C00',
                color: '#fff',
                padding: '6px 6px',
                borderRadius: '12px',
                fontSize: '0.7rem',
                fontWeight: 600,
                width: '140px',
                margin: '0 auto',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onClick={onClose}
            >
              Fechar
            </Button>
          </UBox>
        </UBox>
      </Box>
    </ModalGenerico>
  )
}
