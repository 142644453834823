import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { useEffect } from 'react'

import { GrPrevious, GrNext } from 'react-icons/gr'
import { StyledButton } from '../styled/index'
import UBox from 'src/components/ui/Ubox'
import { FiLock } from 'react-icons/fi'

const useStyles = makeStyles(theme => ({
  stepContent: {
    padding: theme.spacing(0, 0, 4, 0),
    minHeight: 400
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  [theme.breakpoints.down('sm')]: {
    stepContent: {
      padding: theme.spacing(3, 1.5, 14, 1.5),
      minHeight: '100vh'
    },
    buttons: {
      position: 'fixed',
      padding: theme.spacing(2, 3),
      bottom: 0,
      margin: 0,
      left: 0,
      width: '100vw',
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: '#fff',
      zIndex: 10
    }
  }
}))

export const StepContentSyn = ({
  children,
  textoTitulo,
  handleBackToStep,
  handleNext,
  disabledButton,
  hideBack,
  showFinish,
  show,
  loading,
  handleFinish,
  textFinish,
  textNext,
  activeStep,
  lastStep,
  corBotaoNext,
  large,
  hideNext
}: any) => {
  const classes = useStyles()
  const deveMostrar = !show && {
    style: { display: 'none' }
  }

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [show])

  return (
    <Container
      maxWidth={large ? 'md' : 'sm'}
      {...deveMostrar}
      className={`fadeInTransition ${classes.stepContent}`}
    >
      {!!textoTitulo && (
        <Typography variant="h6" style={{ marginBottom: 16 }}>
          {textoTitulo}
        </Typography>
      )}
      <Grid>
        <Box pb={showFinish ? 6 : 0}>{children}</Box>
      </Grid>

      <Grid
        container
        className={classes.buttons}
        style={{
          marginTop: 16
        }}
      >
        {!!showFinish ? (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <StyledButton
                onClick={handleFinish}
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                loading={loading}
                disabled={loading || !!disabledButton}
              >
                {!!textFinish ? textFinish : 'Salvar'}
              </StyledButton>
            </Grid>
            <Grid item xs={12}>
              {!hideBack && (
                <StyledButton
                  type="button"
                  fullWidth
                  variant="text"
                  color="default"
                  onClick={() => handleBackToStep()}
                >
                  Voltar
                </StyledButton>
              )}
            </Grid>
          </Grid>
        ) : (
          <>
            {/* <Grid item xs={4}>
              {!hideBack && (
                <>
                  {fullScreen ? (
                    <StyledButton
                      type="button"
                      fullWidth
                      variant="text"
                      color="default"
                      onClick={() => handleBackToStep()}
                    >
                      Voltar
                    </StyledButton>
                  ) : (
                    <Box
                      display="flex"
                      className={classes.cursorPointer}
                      alignItems="center"
                      onClick={() => handleBackToStep()}
                    >
                      <Box mr={2} component={GrPrevious} /> VOLTAR
                    </Box>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={3} style={{ display: 'flex' }}></Grid> */}
            <Grid
              item
              xs={12}
              container
              justify="flex-end"
              style={{ display: 'block' }}
            >
              {!hideNext && (
                <>
                  <UBox>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ textAlign: 'center', color: '#36BF76' }}
                    >
                      <FiLock style={{ marginRight: 4 }} /> Seus dados estão
                      100% seguros conosco.
                    </Typography>
                  </UBox>
                  {fullScreen ? (
                    <StyledButton
                      variant="contained"
                      type="button"
                      fullWidth
                      color="primary"
                      onClick={handleNext}
                      disabled={loading || !!disabledButton}
                      className={corBotaoNext === 'verde' ? 'verde' : ''}
                      key={corBotaoNext}
                    >
                      {!!textNext ? textNext : 'Continuar'}
                    </StyledButton>
                  ) : (
                    <Box
                      display="flex"
                      className={classes.cursorPointer}
                      alignItems="center"
                      onClick={handleNext}
                    >
                      {!!textNext ? textNext : 'CONTINUAR'}

                      <Box ml={2} component={GrNext} />
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  )
}
