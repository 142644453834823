import {
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import {
  BusinessCenter,
  DynamicFeed,
  LockOpenOutlined,
  People
} from '@material-ui/icons'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import React from 'react'
import { FaTasks } from 'react-icons/fa'
import { GoPackage } from 'react-icons/go'
import { useHistory } from 'react-router-dom'
import UBox from 'src/components/ui/Ubox'
import { SynRoutes } from 'src/core/consts/synRoutes'
import useAtividade from 'src/hooks/useAtividade'
function menuLateral(props) {
  const history = useHistory()
  const { obterQtdePendente } = useAtividade()

  return (
    <List>
      <ListItem button onClick={() => history.push(SynRoutes.listagemMedicos)}>
        <ListItemIcon>
          <People />
        </ListItemIcon>
        <ListItemText primary="Médicos" />
      </ListItem>

      {/* <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemPacotesOperacional)}
      >
        <ListItemIcon style={{ fontSize: '1.4rem' }}>
          <GoPackage />
        </ListItemIcon>
        <ListItemText primary="Pacotes" />
      </ListItem> */}

      <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemOrcamentosOperacional)}
      >
        <ListItemIcon>
          <AssignmentOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Solicitações" />
      </ListItem>

      <ListItem
        button
        onClick={() => history.push(SynRoutes.listagemParceirosOperacional)}
      >
        <ListItemIcon>
          <BusinessCenter />
        </ListItemIcon>
        <ListItemText primary="Parceiros" />
      </ListItem>

      <ListItem
        button
        onClick={() => history.push(SynRoutes.historicoAtualizacoes)}
      >
        <ListItemIcon>
          <DynamicFeed />
        </ListItemIcon>
        <ListItemText primary="Atualizações" />
      </ListItem>

      {/* <ListItem button onClick={() => history.push(SynRoutes.resetSenha)}>
        <ListItemIcon>
          <LockOpenOutlined />
        </ListItemIcon>
        <ListItemText primary="Resetar senha" />
      </ListItem> */}

      <ListItem button onClick={() => history.push(SynRoutes.tarefas)}>
        <ListItemIcon style={{ fontSize: '1.3rem' }}>
          {/* <FcTodoList /> */}
          <FaTasks />
        </ListItemIcon>
        <ListItemText
          primary={
            <UBox>
              Atividades{' '}
              {obterQtdePendente() > 0 && (
                <Chip
                  variant="default"
                  color="secondary"
                  size="small"
                  label={obterQtdePendente()}
                />
              )}
            </UBox>
          }
        />
      </ListItem>
    </List>
  )
}

export default menuLateral
