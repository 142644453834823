import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { useState } from 'react'
import { FiTrash } from 'react-icons/fi'
import { ListChildComponentProps, VariableSizeList } from 'react-window'
import { listagemProcedimentos } from 'src/api/dominio'
import useListagem from 'src/hooks/useListagem'
import { ObjetoDescricaoGenerico } from 'src/types'
import { Autocomplete, AutoCompleteCustomProps } from './Autocomplete'

const LISTBOX_PADDING = 8 // px
const QUEBRA_LINHA = 58 //chars
const QUEBRA_LINHA_XS = 36 //chars
function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  return React.cloneElement(data[index], {
    style: {
      ...style
    }
  })
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props
    const itemData = React.Children.toArray(children)
    const theme = useTheme()
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
    const itemCount = itemData.length
    const itemSize = smUp ? 48 : 48

    const getChildSize = (child: React.ReactNode) => {
/*       let linhas = 1
      try {
        linhas = (child as any).props.children.key.split('-')[1]
      } catch (error) {
        console.log(error)
      } */

      const itemLength = (child as any).props.children.length

      return itemLength < 40 ? 60 : 30 * Math.ceil(itemLength / 40)
    }

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
    }

    const gridRef = useResetCache(itemCount)

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={index => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    )
  }
)

const useStyles = makeStyles(theme => ({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
  // tabela: {
  //   '& .MuiGrid-container:hover': {
  //     // cursor: 'pointer',
  //     background: theme.palette.grey[100]
  //   }
  // }
}))

interface CustomProps extends AutoCompleteCustomProps {
  value: ObjetoDescricaoGenerico[]
  handleValue: (newValue: ObjetoDescricaoGenerico[]) => void
}
export default function AutocompleteVirtualized(props: CustomProps) {
  const { handleValue, options, ...rest } = props
  const {
    loading,
    dados,
    handleTermoPesquisa,
    termoPesquisa,
    pagina,
    qtdPaginas,
    nenhumDado,
    nenhumResultado,
    dadosCarregados,
    carregarListagem
  } = useListagem(100, listagemProcedimentos)

  const classes = useStyles()

  const [termoBusca, setTermoBusca] = useState('')

  function buscarPorTermo(event: React.ChangeEvent<HTMLInputElement>) {
    setTermoBusca(event.target.value)
    if (event.target.value.length > 3) {
      handleTermoPesquisa(event)
    }
  }

  function limparTermoBusca() {
    setTermoBusca('')
  }

  const filteredList: (item) => ObjetoDescricaoGenerico[] = item =>
    rest.value.filter((v, i) => v.id !== item.id)

  const removeItem = item => {
    const filtered = filteredList(item)

    handleValue(filtered)
  }
  const theme = useTheme()
  const ehXS = useMediaQuery(theme.breakpoints.down('xs'))

  const optionsProps =
    termoBusca.length > 3 ? (ehXS ? (loading ? [] : dados) : options) : options

  return (
    <>
      <Autocomplete
        loading={loading}
        multiple
        hideSelected
        hideChips
        options={optionsProps}
        ehProcedimento
        buscarPorTermo={buscarPorTermo}
        limparTermoBusca={limparTermoBusca}
        disableListWrap
        classes={classes}
        ListboxComponent={
          ListboxComponent as React.ComponentType<
            React.HTMLAttributes<HTMLElement>
          >
        }
        onChange={(event, newValue: ObjetoDescricaoGenerico[]) => {
          handleValue(newValue)
        }}
        /* renderOption={(option, { selected, inputValue }) => {
          let i = 0
          function getKey(op) {
            i++
            let linhas = 1
            try {
              let charsNumber = !!op.descricao ? op.descricao?.length : 0
              charsNumber += 11
              linhas = +!op.descricao
                ? 1
                : Math.ceil(
                  charsNumber / (!ehXS ? QUEBRA_LINHA : QUEBRA_LINHA_XS)
                )
            } catch (e) {
              console.log(e)
            }

            return 'option-' + linhas + '-' + i
          }

          return (
            <React.Fragment key={getKey(option)}>
              {!!option.noOptions ? (
                <Box
                  onClick={e => {
                    e.preventDefault()
                  }}
                >
                  <em>Nenhum resultado para "{option.inputText}"</em>
                </Box>
              ) : !!option.btnAdd ? (
                <Grid container>
                  <Divider />
                  <Button
                    fullWidth
                    variant="text"
                    onClick={() => {
                      rest.onClickAddButton(option.inputText)
                    }}
                  >
                    {rest.addButtonProps?.text
                      ? rest.addButtonProps.text
                      : 'Não encontrou? Clique aqui'}
                  </Button>
                </Grid>
              ) : (
                <Box position="relative">
                  <Box pr={2} component="span">
                    <Chip size="small" label={`Cod. ${option.detalhe}`} />
                  </Box>

                  <span>{option.descricao}</span>
                </Box>
              )}
            </React.Fragment>
          )
        }} */
        {...rest}
      />
      {!!rest.value?.length && (
        <Box mb={4}>
          <Box mt={4} mb={2} flex justifyItems="space-between">
            <Typography variant="h6" component="p">
              Procedimentos
            </Typography>
            <Typography variant="subtitle2" component="span">
              <Chip
                size="small"
                // style={{ marginLeft: 32 }}
                label={`Total ${rest.value.length} `}
              />
            </Typography>
          </Box>
          <Divider />
          <Grid container>
            {rest.value?.map((p, i) => (
              <Grid container justify="center" alignItems="center" key={i}>
                <Grid item xs={10}>
                  <Typography variant="subtitle2" component="p">
                    <span>{`Cod. ${p.detalhe} - ${p.descricao}`}</span>
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                  <IconButton
                    className="danger"
                    title="Remover procedimento"
                    onClick={() => removeItem(p)}
                  >
                    <FiTrash />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  )
}
