import { Box, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { RiArrowLeftLine } from 'react-icons/ri'
import { GoSignOut } from 'react-icons/go'

import { useHistory } from 'react-router-dom'
import Button from 'src/components/ui/Button'
import UBox from 'src/components/ui/Ubox'
import { SynPerfis, SynPerfisArray } from 'src/core/consts/synPerfis'
import useAuth from 'src/hooks/useAuth'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { PerfilTentantResponse } from 'src/types'

const useStyles = makeStyles(theme => ({
  container: {
    // background: '#fff !important'
  },
  paper: {
    height: '100vh !important',

    '& .container-perfil': {
      overflow: 'auto',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingBottom: '40px',
      flexDirection: 'column'
    },

    '& .box-perfil': {
      width: '120px',
      height: '120px',
      flexBasis: '120px',
      flexGrow: 0,
      flexShrink: 0,
      border: '2px solid #eee',
      background: theme.palette.grey[50],
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      textAlign: 'center',

      '& b': {
        paddingTop: '8px'
      },

      '& img': {
        filter: 'grayscale(100%)'
      },

      '&:hover': {
        border: '2px solid #374666',

        '& img': {
          filter: 'grayscale(0)'
        }
      }
    }
  },
  [theme.breakpoints.up('md')]: {
    container: {
      minHeight: '100vh'
    },
    paper: {
      height: '100% !important',
      width: `${theme.breakpoints.values.md}px !important`,

      '& .container-perfil': {
        justifyContent: 'center',
        overflow: 'auto',
        width: '100%',
        flexDirection: 'row'
      }
    }
  }
}))

export default function SelecionarPerfil() {
  const classes = useStyles()
  const {
    signed,
    user,
    perfilAtual,
    signOut,
    setPerfilAtual,
    setSetorParceiro
  } = useAuth()
  const history = useHistory()
  const [perfis, setPerfis] = useState<any>(null)
  const [
    tenantSelecionado,
    setTenantSelecionado
  ] = useState<PerfilTentantResponse>(null)

  useEffect(() => {
    if (!signed) {
      history.replace(SynRoutes.signin)
    }
    // let perfisUsuario = SynPerfisArray
    if (user?.perfisPorTenant?.length === 1) {
      var tenant = user.perfisPorTenant[0]
      var perfisTenant = tenant.perfis.map(p => p.idPerfil)
      selecionarPerfisPorTenant(perfisTenant)
      selecionarTenant(tenant)
    }

    // let perfisUsuario = SynPerfisArray.filter(p => user?.perfis.includes(p.id))
    // setPerfis(perfisUsuario)
  }, [user])

  function selecionarPerfisPorTenant(perfisTenant: string[]) {
    let perfisUsuario = SynPerfisArray.filter(p => perfisTenant.includes(p.id))
    setPerfis(perfisUsuario)
  }

  function selecionarPerfil(
    perfil: any,
    tenantSelecionado: PerfilTentantResponse
  ) {
    setPerfilAtual(perfil.id, tenantSelecionado)
    // setSetorParceiro(null)
    if (perfil.id === SynPerfis.SECRETARIA) {
      history.push(SynRoutes.listagemOrcamentos)
    } else {
      history.push(SynRoutes.home)
    }
  }

  function selecionarTenant(tenant: PerfilTentantResponse) {
    if (tenant.perfis.length === 1) {
      selecionarPerfil({ id: tenant.perfis[0].idPerfil }, tenant)
    }

    var perfisTenant = tenant.perfis.map(p => p.idPerfil)
    selecionarPerfisPorTenant(perfisTenant)
    setTenantSelecionado(tenant)
  }

  function obterImagemPerfil(perfil) {
    switch (perfil.id) {
      case SynPerfis.PACIENTE:
        return <img src="image/perfil/paciente1.png" />
      case SynPerfis.MEDICO:
        return <img src="image/perfil/medico.png" />
      case SynPerfis.COMERCIAL_B2B:
      case SynPerfis.COMERCIAL_EXPANSAO:
      case SynPerfis.COMERCIAL_MEDICOS:
      case SynPerfis.PARCEIRO_EXTERNO:
        return <img src="image/perfil/comercial.png" />
      case SynPerfis.OPERACIONAL:
        return <img src="image/perfil/operacional.png" />
      case SynPerfis.SECRETARIA:
        return <img src="image/perfil/secretaria.png" />
      //Hospital
      case SynPerfis.PARCEIRO:
      case SynPerfis.ADMIN_HOSPITAL:
        return <img src="image/perfil/parceiro.png" />
      case SynPerfis.OPERACIONAL_HOSPITAL:
        return <img src="image/perfil/parceiro.png" />
      case SynPerfis.TESOURARIA_HOSPITAL:
        return <img src="image/perfil/parceiro.png" />
      case SynPerfis.AGENDAMENTO:
      case SynPerfis.COTACAO_HOSPITAL:
        return <img src="image/perfil/parceiro.png" />
    }
  }

  return (
    <Container
      component="main"
      className={classes.container + ' fadeInTransition syn-container-out'}
    >
      <CssBaseline />
      <UBox className={classes.paper + ' syn-box-out'}>
        <UBox
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link
            component="button"
            color="secondary"
            onClick={() => history.goBack()}
          >
            <UBox hidden={!perfilAtual}>
              <RiArrowLeftLine />
              <Typography
                style={{ marginLeft: 10 }}
                variant="subtitle1"
                component="span"
              >
                Voltar
              </Typography>
            </UBox>
          </Link>
          <Button color="secondary" size="medium" onClick={() => signOut()}>
            <GoSignOut style={{ fontSize: '1.2rem' }} />
            <Typography
              style={{ marginLeft: 8 }}
              variant="subtitle1"
              component="span"
            >
              Sair
            </Typography>
          </Button>
        </UBox>

        <UBox render={!tenantSelecionado}>
          <Box my={5}>
            <Typography variant="h6">
              Olá, deseja iniciar a sessão em que hospital?
            </Typography>
          </Box>
          <UBox display="flex" gridGap={20} className="container-perfil">
            {user?.perfisPorTenant?.map(item => (
              <UBox
                key={item.tenantId}
                className="box-perfil"
                onClick={() => selecionarTenant(item)}
              >
                <b>{item.descricao}</b>
              </UBox>
            ))}
          </UBox>
        </UBox>
        <UBox render={!!tenantSelecionado}>
          <Box my={5}>
            <Typography variant="h6">
              Olá, deseja navegar com qual perfil nessa sessão?
            </Typography>
          </Box>
          {/* <Typography variant="body1">
          Digite o CPF da sua conta para que possamos enviar um e-mail com as
          instruções para definir uma nova senha.
        </Typography> */}
          <UBox display="flex" gridGap={20} className="container-perfil">
            {perfis?.map(perfil => (
              <UBox
                key={perfil.id}
                className="box-perfil"
                onClick={() => selecionarPerfil(perfil, tenantSelecionado)}
              >
                {obterImagemPerfil(perfil)}
                <b>{perfil.descricao}</b>
              </UBox>
            ))}
          </UBox>
        </UBox>
      </UBox>
    </Container>
  )
}
