import React, { Component } from 'react'
import {
  Card,
  CardHeader,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  CardContent,
  Box,
  makeStyles,
  Link,
  CircularProgress,
  Chip
} from '@material-ui/core'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import formatarCPF from 'src/core/formatacoes/formatarCPF'
import {
  formatarDataHoraLocal,
  formatarData
} from 'src/core/formatacoes/formatarData'
import formatarDinheiro from 'src/core/formatacoes/formatarDinheiro'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'
import UBox from '../ui/Ubox'
import useAuth from 'src/hooks/useAuth'
import { SimulacaoFinanciamentoResponse } from 'src/types'
import {
  StatusFinanciamento,
  StatusFinanciamentoArray
} from 'src/core/consts/statusFinanciamento'
import { SynPerfis } from 'src/core/consts/synPerfis'

const useStyles = makeStyles(theme => ({
  cardListagem: {
    borderColor: '#eee',
    '& .titulo-card': {
      fontWeight: 600
    },
    '&.MuiPaper-rounded': {
      borderRadius: '8px'
    },
    '& .MuiCardHeader-root': {
      borderBottom: '1px solid #efefef',
      marginBottom: '8px'
    },
    // background: 'rgba(255, 255, 255, 0.88)',
    // borderRadius: '4px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    // backdropFilter: 'blur(5px)',
    border: '1px solid rgba(255, 255, 255, 0.3)'
  },
  menu: {
    fontWeight: 600,
    color: '#9b00008a'
  },

  badgeCustom: {
    '& .MuiBadge-colorSecondary': {
      // backgroundColor: theme.palette.grey[100]
      backgroundColor: '#e91e63'
    }
  },

  [theme.breakpoints.down('sm')]: {
    cardListagem: {}
  }
}))
export interface CardFinanciamentoProps {
  handleVisualizarDetalhes: (item) => void
  handleAlterarStatus: (item) => void
  handleMostrarFavorecidos: (item) => void
  item: SimulacaoFinanciamentoResponse
  loading?: boolean
}
export default function CardFinanciamento({
  handleVisualizarDetalhes,
  handleAlterarStatus,
  handleMostrarFavorecidos,
  item,
  loading = false
}: CardFinanciamentoProps) {
  const { perfilAtual } = useAuth()
  const style = useStyles()

  function obterStyleDataSolicitado(): any {
    var style = {
      position: 'absolute',
      top: '-32px',
      textAlign: 'left',
      fontStyle: 'italic',
      fontSize: '12px',
      fontWeSight: 300,
      color: '#919191'
    }
    // style['right'] = '24px'

    return style
  }

  return (
    <Card className={style.cardListagem} variant="outlined">
      <CardHeader
        // className={style.menu}
        title={
          <Typography
            style={{ cursor: 'pointer', position: 'relative' }}
            variant="body1"
            align="left"
          >
            <Link
              component="button"
              variant="body1"
              color="primary"
              align="left"
              className="titulo-card"
              onClick={() => {
                handleVisualizarDetalhes(item)
              }}
            >
              <UBox
                display="flex"
                alignItems="center"
                style={{ position: 'absolute', top: '-20px' }}
                render={loading}
              >
                <CircularProgress size={16} style={{ marginRight: '8px' }} />
                <Typography variant="body2" component="p">
                  salvando...
                </Typography>
              </UBox>
              {/* <LoadingGeral title="salvando..." /> */}
              {`${item.codigoSolicitacao} - ${item.descricaoProcedimento}`}
            </Link>
          </Typography>
        }
        // subheader={`${item.codigoSolicitacao} - ${item.descricaoProcedimento}`}
        action={
          <PopupState variant="popover" popupId="demo-popup-menu">
            {popupState => (
              <React.Fragment>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  aria-label="mais"
                  {...bindTrigger(popupState)}
                >
                  <MoreVertIcon />
                </IconButton>

                <Menu
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  {...bindMenu(popupState)}
                >
                  {/* <MenuItem
              onClick={() => {
                // popupState.close()
                handleRefazerPreAnalise(item)
              }}
            >
              Refazer pré-análise
            </MenuItem> */}
                  {(perfilAtual === SynPerfis.OPERACIONAL ||
                    perfilAtual === SynPerfis.FINANCEIRO) && (
                    <MenuItem
                      onClick={() => {
                        popupState.close()
                        handleAlterarStatus(item)
                      }}
                    >
                      Alterar status
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      popupState.close()
                      handleVisualizarDetalhes(item)
                    }}
                  >
                    Detalhe
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        }
        titleTypographyProps={{ variant: 'subtitle1' }}
        subheaderTypographyProps={{ variant: 'body2' }}
      />

      <CardContent style={{ paddingTop: 0, position: 'relative' }}>
        <UBox style={obterStyleDataSolicitado()}>
          Solicitado em {formatarDataHoraLocal(item.criado)}
        </UBox>
        {/* <StatusSolicitacaoFinanciamento
          financiamento={item}
          status={item.status}
          loadingStatus={false}
        /> */}
        <Box mt={1}>
          <Typography variant="body2">
            <small style={{ fontWeight: 600 }}>Nome:</small>
            <br />
            {item.nome}
          </Typography>
        </Box>
        {/* <Box>
          <Typography variant="body2">
            <small style={{ fontWeight: 600 }}>Data de nascimento</small>
            <br />
            {formatarData(item.dataNascimento)}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">
            <small style={{ fontWeight: 600 }}>CPF</small>
            <br />

            {formatarCPF(item.cpf)}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">
            <small style={{ fontWeight: 600 }}>Telefone</small>
            <br />
            {formatarTelefone(item.telefone)}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">
            <small style={{ fontWeight: 600 }}>Renda</small>
            <br />
            {formatarDinheiro(item.renda)}
          </Typography>
        </Box> */}
        <Box>
          <Typography variant="body2">
            <small style={{ fontWeight: 600 }}>Valor solicitado</small>
            <br />
            {formatarDinheiro(item.valorTotalFinanciamento)}
          </Typography>
        </Box>
        <UBox render={!!item.valorParcialLiberado}>
          <Typography variant="body2">
            <small style={{ fontWeight: 600 }}>Valor parcial liberado</small>
            <br />
            <Chip
              label={formatarDinheiro(item.valorParcialLiberado)}
              variant="default"
              size="small"
              style={{
                backgroundColor: '#82c05f',
                fontWeight: 500,
                color: '#fff'
              }}
            />
            {/* {formatarDinheiro(item.valorTotalFinanciamento)} */}
          </Typography>
        </UBox>
        {/* <Box hidden={item.status !== 5}>
          <Typography variant="body2">
            <small style={{ fontWeight: 600, marginRight: 5 }}>
              Qtd favorecidos na proposta:
            </small>
            <small>{item.qtdFavorecidos}</small>
          </Typography>
        </Box>
        <Box hidden={!item.faltaFavorecido}>
          <Typography
            variant="body2"
            style={{
              color: 'Red',
              fontStyle: 'italic',
              cursor: 'pointer'
            }}
          >
            {item.qtdNaoCadastrado === 1 && (
              <small
                onClick={() => {
                  handleMostrarFavorecidos(item)
                }}
              >
                Obs: Nesta proposta falta {item.qtdNaoCadastrado} favorecido
              </small>
            )}
            {item.qtdNaoCadastrado > 1 && (
              <small
                onClick={() => {
                  handleMostrarFavorecidos(item)
                }}
              >
                Obs: Nesta proposta faltam {item.qtdNaoCadastrado} favorecidos
              </small>
            )}
          </Typography>{' '}
        </Box>
        <Box hidden={item.faltaFavorecido && item.qtdFavorecidos > 0}>
          <Typography
            variant="body2"
            style={{
              color: 'Red',
              fontStyle: 'italic',
              cursor: 'pointer'
            }}
          >
            {item.qtdNaoCadastrado === 1 && (
              <small
                onClick={() => {
                  handleMostrarFavorecidos(item)
                }}
              >
                Obs: Nesta proposta falta {item.qtdNaoCadastrado} favorecido
              </small>
            )}
            {item.qtdNaoCadastrado > 1 && (
              <small
                onClick={() => {
                  handleMostrarFavorecidos(item)
                }}
              >
                Obs: Nesta proposta faltam {item.qtdNaoCadastrado} favorecidos
              </small>
            )}
          </Typography>{' '}
        </Box> */}
      </CardContent>
    </Card>
  )
}

interface IPropsStatus {
  financiamento: SimulacaoFinanciamentoResponse
  status: number
  loadingStatus: boolean
}

const StatusSolicitacaoFinanciamento = ({
  financiamento,
  status,
  loadingStatus
}: IPropsStatus) => {
  function obterStyle(status) {
    switch (status) {
      case 0:
        return { border: '2px solid #ff9800', color: '#ff9800' }
      case 1:
      case 5:
        return { border: '2px solid #5cb85c', color: '#5cb85c' }
      case 2:
      case 6:
        return { border: '2px solid #d9534f', color: '#d9534f' }
      case 3:
        return { border: '2px solid #428bca', color: '#428bca' }
      default:
        return { border: '2px solid #428bca', color: '#428bca' }
    }
  }
  function obterDescricao(status) {
    var statusFinanciamento = StatusFinanciamentoArray.find(
      s => s.id === status
    )
    return statusFinanciamento.descricao
    switch (status) {
      case StatusFinanciamento.PENDENTE.Id:
        return StatusFinanciamento.PENDENTE.Descricao
      case StatusFinanciamento.APROVADA.Id:
        return StatusFinanciamento.APROVADA.Descricao
      case StatusFinanciamento.RECUSADA.Id:
        return StatusFinanciamento.RECUSADA.Descricao
      case StatusFinanciamento.LANCADA_NA_BV.Id:
        return StatusFinanciamento.LANCADA_NA_BV.Descricao
    }
  }
  if (loadingStatus) {
    return (
      <UBox>
        <CircularProgress />
      </UBox>
    )
  }

  return (
    <Chip
      style={{
        ...obterStyle(status),
        background: '#ffff',
        fontWeight: 600,
        cursor: 'pointer'
      }}
      label={obterDescricao(status)}
      size="small"
    />
  )
}
