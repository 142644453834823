import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  CircularProgress,
  Card,
  makeStyles,
  Divider
} from '@material-ui/core'
import { listarAtualizacoes } from 'src/api/atualizacoes'
import { ItemAtualizacao } from 'src/types'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import {
  formatarData,
  formatarDataHoraLocal
} from 'src/core/formatacoes/formatarData'

const styles = {
  mainDate: {
    color: '#717171',
    margin: '5px 0'
  },
  mainDescription: {
    marginTop: '10px',
    color: '#000'
  },
  normalDescription: {
    marginTop: '5px',
    whiteSpace: 'pre'
    // whiteSpace: 'pre-line'
  }
}

const useStyles = makeStyles(theme => ({
  normalDescription: {
    marginTop: '5px',
    whiteSpace: 'pre-line'
  },
  card: {
    // margin: '20px',
    padding: '16px',
    boxShadow: 'none'
  },
  cardAtual: {
    background: theme.palette.grey[200],
    // margin: '20px',
    padding: '16px',
    boxShadow: 'none'
  }
}))

export default function HistoricoAtualizacoes() {
  const estilo = useStyles()
  const [atualizacoes, setAtualizacoes] = useState<ItemAtualizacao[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    listarAtualizacoes()
      .then(response => {
        setAtualizacoes(response.data.items)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        showNotificationErrorAPI(err)
      })
  }, [])

  return (
    <Box>
      <Typography variant="h5">Histórico de atualizações</Typography>

      {loading && (
        <Box textAlign="center">
          <CircularProgress />
          <div>Carregando...</div>
        </Box>
      )}

      {atualizacoes &&
        atualizacoes?.map((item, index) => {
          return (
            <Box key={item.id} mt={2}>
              <Card className={index === 0 ? estilo.cardAtual : estilo.card}>
                <Typography variant="body2">
                  <strong>
                    Versão {index === 0 && 'atual '}
                    {item.versao}
                  </strong>
                </Typography>
                <Typography variant="subtitle2" style={styles.mainDate}>
                  Lançamento: {formatarDataHoraLocal(item.dataPublicacao)}
                </Typography>
                <Typography
                  variant="body1"
                  className={estilo.normalDescription}
                >
                  {item.descricao}
                </Typography>
              </Card>
              {index !== 0 && <Divider />}
            </Box>
          )
        })}
    </Box>
  )
}
