import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ConvidarMedico } from 'src/api/medico'
import Button from 'src/components/ui/Button'
import TextField from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import {
  showNotification,
  showNotificationErrorAPI
} from 'src/core/helpers/notification'

const schema = yup.object().shape({
  email: yup.string().email().label('E-mail'),
  telefone: yup
    .string()
    .test('validar-telefone', 'Telefone inválido', value => {
      var numerosTelefone = value.replace(/[^0-9]/g, '')
      return (
        numerosTelefone.length === 0 ||
        numerosTelefone.length === 10 ||
        numerosTelefone.length === 11
      )
    })
    .label('Telefone')
  //.trim().min(14, 'Telefone inválido').label('Telefone')
})

function ConvidarColega(props) {
  const [loading, setLoading] = useState(false)
  const { register, errors, control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      telefone: ''
    }
  })

  const onSubmit = async data => {
    try {
      if (!data.telefone && !data.email) {
        showNotification('Atenção!', 'Preencha algum dos campos.', 'error')
        return
      }

      setLoading(true)

      await ConvidarMedico({ email: data.email, telefone: data.telefone })

      showNotification(
        'Convite enviado!',
        'Em breve um e-mail ou WhatsApp deve chegar para a pessoa convidada.',
        'success'
      )
    } catch (error) {
      showNotificationErrorAPI(error)
    }
    setLoading(false)
  }

  return (
    <div>
      <Grid container spacing={3} justify="center">
        <Grid item lg={5} xl={4}>
          <Box mb={2}>
            <Typography variant="h5">
              Convide seus colegas médicos para fazerem parte da SYN!
            </Typography>
          </Box>
          <p>
            Ajude a transformar a vida de cada vez mais pessoas, provendo
            acessibilidade e praticidade para realizar os procedimentos
            cirúrgicos.
          </p>
          <p>
            Além disso, com a SYN os médicos podem ampliar os seus clientes e
            potencializar a sua receita através de um grande público em
            potencial pouco assistido.
          </p>
        </Grid>
        <Grid item xs={12} lg={5} xl={4}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
            <TextField
              inputRef={register}
              erro={errors}
              name="email"
              label="E-mail do(a) colega"
              defaultValue=""
              InputProps={{
                autoComplete: 'off'
              }}
            />
            <Box textAlign="center">ou</Box>
            <TextField
              maskTelCel
              control={control}
              erro={errors}
              name="telefone"
              label="Telefone do(a) colega"
              defaultValue=""
              InputProps={{
                autoComplete: 'off'
              }}
            />
            <Box mt={3}>
              <Button
                loading={loading}
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Enviar convite
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    </div>
  )
}

export default ConvidarColega
