import { Box, Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from 'src/components/ui/Button'
import Ubox from 'src/components/ui/Ubox'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDropzoneArea-root': {
      minHeight: '200px'
    },
    '& .MuiDropzoneArea-text': {
      marginBottom: 0
    },
    '& .MuiDropzoneArea-text.MuiTypography-h5': {
      fontSize: '1.2rem',
      padding: '20px 40px 10px'
    },
    '& .MuiDropzoneArea-icon': {
      color: 'rgba(100, 100, 100, 0.87)'
    }
  },
  boxLogomarca: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    height: '100px',
    width: '240px',
    background: theme.palette.grey[100],
    border: '1px solid ' + theme.palette.grey[200],
    borderRadius: '2px',
    backgroundRepeat: 'none',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%'
    // cursor: 'pointer'
  }
}))
function termos() {
  function download() {
    window.open(
      'https://arquivosyn.blob.core.windows.net/arquivos-termo-uso/Termos%20de%20Uso%20-%20Syn%20Sa%C3%BAde%20-%2001.12.22.pdf',
      '_blank'
    )
  }
  function downloadPrivacidade() {
    window.open(
      'https://arquivosyn.blob.core.windows.net/arquivos-termo-uso/PoliticasdePrivacidade3.0.pdf',
      '_blank'
    )
  }

  return (
    <Container
      component="main"
      className="fadeInTransition syn-container "
      maxWidth="xl"
    >
      <Ubox pb={4}>
        <Box mb={2}>
          <Typography variant="h5">Termos</Typography>
        </Box>
        <Box mb={2}>
          <Typography>
            Para sua comodidade, disponibilizamos nossos termos que você aceitou
            no primeiro acesso em versão PDF.
            <br />
            Caso queira salvá-lo basta baixar clicando no botão abaixo.
          </Typography>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box mb={4} textAlign="center">
              <img
                onClick={download}
                style={{ cursor: 'pointer' }}
                src="/pdf-svgrepo-com.svg"
                height="80px"
              />
            </Box>
            <Button color="primary" fullWidth type="button" onClick={download}>
              Baixar
            </Button>
            <Box mt={2} textAlign="center">
              <Typography variant="subtitle2">Termo de uso</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box mt={3} mb={1} textAlign="center">
              <img
                onClick={downloadPrivacidade}
                style={{ cursor: 'pointer' }}
                src="/pdf-svgrepo-com.svg"
                height="80px"
              />
            </Box>
            <Button
              color="primary"
              fullWidth
              type="button"
              onClick={downloadPrivacidade}
            >
              Baixar
            </Button>
            <Box mt={2} textAlign="center">
              <Typography variant="subtitle2">
                Política de privacidade
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Ubox>
    </Container>
  )
}

export default termos
