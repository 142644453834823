import { AxiosResponse } from 'axios'
import api from 'src/services/api'

import {
  IUsuario,
  ListagemQuery,
  ListagemResponse
} from 'src/types'

export const listagemUsuariosQueryInterno: (
  query: ListagemQuery
) => Promise<AxiosResponse<ListagemResponse<IUsuario>>> = query => {
  return api.get(`/usuario/interno?${new URLSearchParams(query)}`)
}

export const excluirUsuarioInterno: (
  id: string
) => Promise<AxiosResponse<string>> = id => {
  return api.delete(`/usuario/interno/${id}`)
}

export const salvarUsuarioInterno: (
  funcionario: IUsuario
) => Promise<AxiosResponse<string>> = funcionario => {
  return api.post('/usuario/interno', funcionario)
}
