export const SynPerfis = {
  MEDICO: '2225358b-c52a-44fa-9e37-81e2fad6484d',
  PACIENTE: '3e00b20f-4d97-4e26-939a-8a707fb77f1e',
  OPERACIONAL: '764e784d-b8d4-483e-968b-e42f88048d17',
  COMERCIAL_B2B: 'df2d0e9d-9af0-4ae8-b366-e5899463fddf',
  COMERCIAL_MEDICOS: '726c4da8-4332-40e8-8844-9ce985531e7e',
  COMERCIAL_EXPANSAO: 'ddf8dc87-d864-4f73-8047-ee7e481a672e',
  PARCEIRO: 'd719fb23-4682-44df-9aa7-2fd614cc4395',
  SECRETARIA: '79caac24-248d-4ef6-bb8b-5a9590457d8a',
  PARCEIRO_EXTERNO: '0f4d8c48-c9c2-428a-a1bc-7a6981a978c5',
  FINANCEIRO: '82809cbc-421f-44a9-b7ed-0156667c9644',
  //Perfil Tenant
  ADMIN_HOSPITAL: '4576c9b8-c8a8-49a1-bd45-1f7abe881ba9',
  TESOURARIA_HOSPITAL: 'fff64ee2-19b2-4cc1-8abf-b67852f4791d',
  COTACAO_HOSPITAL: '423a6b18-7499-4168-9dff-c1b5842161fd',
  OPERACIONAL_HOSPITAL: '5d89f15c-4d2e-43ce-8eac-b64a525f2b66',
  AGENDAMENTO: '0a65575e-fe88-4e5b-b995-d20d2b7ae73e' //0a65575e-fe88-4e5b-b995-d20d2b7ae73e
}

export const SynPerfisArray = [
  { descricao: 'Médico', id: '2225358b-c52a-44fa-9e37-81e2fad6484d' },
  { descricao: 'Financeiro', id: '82809cbc-421f-44a9-b7ed-0156667c9644' },
  { descricao: 'Agendamento', id: '0a65575e-fe88-4e5b-b995-d20d2b7ae73e' },
  { descricao: 'Paciente', id: '3e00b20f-4d97-4e26-939a-8a707fb77f1e' },
  { descricao: 'Operacional', id: '764e784d-b8d4-483e-968b-e42f88048d17' },
  { descricao: 'Comercial B2B', id: 'df2d0e9d-9af0-4ae8-b366-e5899463fddf' },
  {
    descricao: 'Comercial expansão',
    id: 'ddf8dc87-d864-4f73-8047-ee7e481a672e'
  },
  {
    descricao: 'Comercial médicos',
    id: '726c4da8-4332-40e8-8844-9ce985531e7e'
  },
  { descricao: 'Parceiro', id: 'd719fb23-4682-44df-9aa7-2fd614cc4395' },
  { descricao: 'Assistente', id: '79caac24-248d-4ef6-bb8b-5a9590457d8a' },
  { descricao: 'Parceiro externo', id: '0f4d8c48-c9c2-428a-a1bc-7a6981a978c5' },
  //Hospital tenant
  { descricao: 'Admin Hospital', id: '4576c9b8-c8a8-49a1-bd45-1f7abe881ba9' },
  {
    descricao: 'Tesouraria Hospital',
    id: 'fff64ee2-19b2-4cc1-8abf-b67852f4791d'
  },
  { descricao: 'Cotação Hospital', id: '423a6b18-7499-4168-9dff-c1b5842161fd' },
  {
    descricao: 'Operacional Hospital',
    id: '5d89f15c-4d2e-43ce-8eac-b64a525f2b66'
  }
]

export const SynPerfisSetorHospital = {
  TESOURARIA: 'fff64ee2-19b2-4cc1-8abf-b67852f4791d',
  OPERACIONAL: '5d89f15c-4d2e-43ce-8eac-b64a525f2b66',
  ADMIN: '4576c9b8-c8a8-49a1-bd45-1f7abe881ba9',
  COTACAO: '423a6b18-7499-4168-9dff-c1b5842161fd'
}

export const SynPerfisSetorHospitalArray = [
  { descricao: 'tesouraria', id: 'fff64ee2-19b2-4cc1-8abf-b67852f4791d' },
  { descricao: 'operacional', id: '5d89f15c-4d2e-43ce-8eac-b64a525f2b66' },
  { descricao: 'cotação', id: '423a6b18-7499-4168-9dff-c1b5842161fd' }
]
