import React, { createContext, useState } from 'react'
import { listagemOrcamentoId } from 'src/api/solicitacao-orcamento'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import useAuth from 'src/hooks/useAuth'
import { ItemListagemSolicitacaoOrcamentoResponse } from '../types'

interface ContextContent {
  ultimaSolicitacaoVista: ItemListagemSolicitacaoOrcamentoResponse
  setUltimaSolicitacao: (
    solicitacao?: ItemListagemSolicitacaoOrcamentoResponse
  ) => void
  setUltimaSolicitacaoEnviado: () => void
  atualizarSolicitacao: () => void
  loading: boolean
}

export const UltimaSolicitacaoContext = createContext<ContextContent>(
  {} as ContextContent
)

export default function UltimaSolicitacaoProvider({ children }) {
  const [ultimaSolicitacaoVista, setUltimaSolicitacaoVista] = useState<
    ItemListagemSolicitacaoOrcamentoResponse | undefined
  >()

  const [loading, setLoading] = useState<boolean>(false)
  const { perfilAtual } = useAuth()

  async function setUltimaSolicitacao(
    solicitacao?: ItemListagemSolicitacaoOrcamentoResponse
  ) {
    if (!solicitacao) {
      return setUltimaSolicitacaoVista(undefined)
    }

    try {
      await setUltimaSolicitacaoVista(solicitacao)
    } catch (err) {}
  }

  async function setUltimaSolicitacaoEnviado() {
    if (!ultimaSolicitacaoVista) {
      return setUltimaSolicitacaoVista(undefined)
    }

    ultimaSolicitacaoVista.enviado = true

    try {
      await setUltimaSolicitacaoVista(ultimaSolicitacaoVista)
    } catch (err) {}
  }

  async function atualizarSolicitacao() {
    if (!ultimaSolicitacaoVista?.codigo || loading) return
    setLoading(true)
    listagemOrcamentoId(ultimaSolicitacaoVista?.codigo.toString(), perfilAtual)
      .then(response => {
        setUltimaSolicitacao(response.data)
        setLoading(false)
      })
      .catch(err => showNotificationErrorAPI(err))
  }

  return (
    <UltimaSolicitacaoContext.Provider
      value={{
        ultimaSolicitacaoVista,
        setUltimaSolicitacao,
        setUltimaSolicitacaoEnviado,
        atualizarSolicitacao,
        loading
      }}
    >
      {children}
    </UltimaSolicitacaoContext.Provider>
  )
}
