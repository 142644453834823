import api from 'src/services/api'
import { AxiosResponse } from 'axios'
import { CadastrarCpfPacienteCommand } from '../../types'

export interface ISalvarDadosPacienteRequest {
  id: string
  nome: string
  email: string
  cpf: string
  dataNascimento: string
  telefone: string
}

interface IFormDadosPaciente {
  id: string
  nome: string
  email: string
  cpf: string
  dataNascimento: string
  telefone: string
}

export async function cadastrarCpf(
  command: CadastrarCpfPacienteCommand
): Promise<AxiosResponse> {
  return await api.post(`/paciente/cadastrarCpf/${command.solicitacaoId}`, {
    cpf: command.cpf
  })
}

export const obterDadosPacientePorId: (
  id: string
) => Promise<AxiosResponse<IFormDadosPaciente>> = id => {
  return api.get(`/paciente/${id}`)
}

export const alterarDadosPaciente: (
  dadosPaciente: ISalvarDadosPacienteRequest
) => Promise<AxiosResponse<void>> = dadosPaciente => {
  return api.put('/paciente', dadosPaciente)
}
