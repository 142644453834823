import React, { useEffect, useState } from 'react'
import Drawer from 'src/components/ui/Drawer'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { obterOrcamentoPorId } from 'src/api/solicitacao-orcamento'
import { obterPacotePorId, obterPacotePorIdPaciente } from 'src/api/pacote'
import ResumoNotificacoes from './resumo'

type Props = {
  open: boolean
  handleOnClose: () => void
}

function DrawerNotificacoes({ open, handleOnClose }: Props) {
  //   const [detalheVisualizacao, setDetalheVisualizacao] = useState(null)
  //   const [loading, setLoadingDetalhe] = useState(false)

  useEffect(() => {}, [open])

  return (
    <Drawer
      loading={false}
      anchor="right"
      open={open}
      titulo="Notificações"
      handleOnClose={handleOnClose}
      noPadding
    >
      <ResumoNotificacoes ehMobile />
    </Drawer>
  )
}

export default DrawerNotificacoes
