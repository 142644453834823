import { yupResolver } from '@hookform/resolvers/yup'
import {
  AppBar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Tab,
  Tabs,
  TextField as TextFieldUI
} from '@material-ui/core'

import Close from '@material-ui/icons/Close'
import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Button } from 'src/components/ui/Button'
import TextField from 'src/components/ui/TextField'
import yup from 'src/components/yupCustom'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import NumberFormat from 'react-number-format'
import { NumberFormatPorcentagem } from 'src/components/ui/PorcentagemField'

export function ModalAddGenerico({
  descricao,
  titulo,
  open,
  handleClose,
  label,
  handleValue,
  saveSubmit,
  dinheiro,
  buttonLabel,
  submitDinheiro
}: {
  descricao: any
  titulo: string
  open: boolean
  handleClose: any
  label: string
  handleValue: any
  saveSubmit: any
  dinheiro?: boolean
  buttonLabel?: string
  submitDinheiro?: (valor: string, tipo: 0 | 1) => void
}) {
  const { watch, register, errors, control, handleSubmit } = useForm<{
    descricao: string
  }>({
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        descricao: yup.string().required().label(label)
      })
    ),
    defaultValues: { descricao: descricao }
  })
  const [loading, setLoading] = useState(false)
  const command = watch()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [tabValue, setTabValue] = useState<0 | 1>(0)

  const onSubmit = async data => {
    if (dinheiro) {
      submitDinheiro(command.descricao, tabValue)
    } else {
      setLoading(true)
      saveSubmit(command.descricao)
        .then(response => {
          handleValue(response.data)
        })
        .catch(error => showNotificationErrorAPI(error))
        .finally(() => setLoading(false))
    }
    handleClose()
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
        {fullScreen && (
          <AppBar style={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
              <Typography style={{ flex: 1 }} variant="h6"></Typography>
              <Button autoFocus variant="text" type="submit" color="inherit">
                Salvar
              </Button>
            </Toolbar>
          </AppBar>
        )}
        {/* <DialogTitle id="form-dialog-title"></DialogTitle> */}
        <DialogContent>
          <Box mb={2} mt={1}>
            <Typography variant="h6">{titulo}</Typography>
          </Box>

          {dinheiro && (
            <Tabs
              value={tabValue}
              onChange={(e, value) => setTabValue(value)}
              textColor="primary"
              indicatorColor="primary"
              style={{ marginBottom: '1em' }}
            >
              <Tab label="Valor monetário (R$)" />
              <Tab label="Porcentagem (%)" />
            </Tabs>
          )}

          {dinheiro ? (
            <>
              {tabValue === 0 ? (
                <Controller
                  render={({ onChange, value }) => (
                    <NumberFormat
                      decimalScale={2}
                      fixedDecimalScale
                      allowedDecimalSeparators={[',']}
                      onFocus={e => e.target.select()}
                      decimalSeparator=","
                      thousandSeparator="."
                      isNumericString
                      prefix="R$ "
                      type="tel"
                      value={value}
                      onValueChange={values => onChange(values.floatValue)}
                      customInput={TextFieldUI}
                      variant="outlined"
                    />
                  )}
                  name="descricao"
                  control={control}
                />
              ) : (
                <Controller
                  render={({ onChange, value }) => (
                    <NumberFormat
                      decimalScale={2}
                      fixedDecimalScale
                      allowedDecimalSeparators={[',']}
                      decimalSeparator=","
                      thousandSeparator="."
                      isNumericString
                      type="tel"
                      value={value}
                      onValueChange={values => onChange(values.floatValue)}
                      allowNegative={false}
                      suffix=" %"
                      isAllowed={values => {
                        if (
                          !values.floatValue ||
                          (values.floatValue <= 100 && values.floatValue >= 0)
                        ) {
                          return true
                        }
                      }}
                      customInput={TextFieldUI}
                      variant="outlined"
                    />
                  )}
                  name="descricao"
                  control={control}
                />
              )}
            </>
          ) : (
            <TextField
              erro={errors}
              autoFocus
              name="descricao"
              label={label}
              inputRef={register}
              type="text"
            />
          )}
        </DialogContent>
        {!fullScreen && (
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="default">
              Cancelar
            </Button>
            <Button loading={loading} disabled={loading} type="submit" color="primary">
              {buttonLabel || 'Adicionar'}
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  )
}
