import { makeStyles, Typography } from '@material-ui/core'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'
import { RiArrowDownSFill } from 'react-icons/ri'
import Ubox from 'src/components/ui/Ubox'
import {
  formatarData,
  formatarDataLocal,
  formatarHora,
  formatarHoraLocal
} from 'src/core/formatacoes/formatarData'
import useAuth from 'src/hooks/useAuth'
import { SynPerfis } from 'src/core/consts/synPerfis'

const logoWidth = 240 / 1.5
const logoHeight = 100 / 1.5

const useStylesResumo = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 10000,
    color: '#fff'
  },
  boxPrint: {
    padding: theme.spacing(1, 3, 3, 3),
    width: '100%',
    '& @page': {
      size: '210mm 297mm'
    },
    '& .header': {
      position: 'fixed',
      top: 0,
      left: 0,
      // padding: '8px 0 12px',
      padding: '0 32px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'end',
      width: '100%',
      borderBottom: '2px solid #eee',
      height: '80px',

      '& .logo-guia': {
        width: logoWidth + 'px',
        height: logoHeight + 'px',
        borderRadius: '2px',
        backgroundPosition: 'center center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
      },
      '& .codigo-guia': {
        width: '160px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '12px',
        border: '2px solid #ccc'
      }
    },
    '& .main': {
      marginTop: '80px'
    },
    '& .footer': {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      padding: '0 32px',
      height: '160px',
      margin: 0,

      '& .box-footer': {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        borderTop: '4px solid #eee'
      },

      '& .logo-footer': {
        width: logoWidth / 1.5 + 'px',
        height: logoHeight / 1.5 + 'px',
        borderRadius: '2px',
        backgroundPosition: 'center center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        top: '-56px',
        left: '4px'
      },

      '& .box-qrcode': {
        background: '#eee',
        position: 'absolute',
        top: '-75px',
        right: 0,
        borderRadius: '8px'
      },

      '& .link-syn': {
        position: 'absolute',
        bottom: 15,
        right: -490,
        top: '121px',
        width: '100%',
        borderRadius: '8px',
        fontSize: '12px'
      },
      '& .info': {
        position: 'absolute',
        bottom: 7,
        right: 140,
        width: '60%',
        fontSize: '10px'
      }
    }
  },
  boxResumo: {
    border: '1px solid #eee',
    // backgroundColor: '#efefef',
    borderRadius: 4,
    '& .MuiTableCell-root.MuiTableCell-head': {
      padding: theme.spacing(1, 2),
      // background: '#f9f9f9',
      color: theme.palette.grey[800]
    }
  },
  fontMarca: {
    fontStyle: 'italic',
    fontWeight: 500,
    fontSize: '0.8rem',
    color: theme.palette.grey[700]
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 auto',
      boxShadow: 'none'
    }
  }
}))

interface PropsResumo {
  codigo: string
  criado: string
  urlLogomarca?: string
  onClose?: () => void
  children?: ReactElement
}
export const ModeloImpressaoPadrao = (props: PropsResumo) => {
  const { urlLogomarca, codigo, children, criado } = props
  const classes = useStylesResumo()
  const dataHoraLocal = moment()
  const { signOut, user, perfilAtual } = useAuth()
  return (
    <div className={classes.boxPrint}>
      {/* <Ubox>
              <Button onClick={() => handlePrint()}>Imprimir</Button>
            </Ubox> */}
      <Ubox className="header">
        <div
          className="logo-guia"
          style={{
            backgroundImage: `url(${urlLogomarca || 'image/logo-2.png'})`
          }}
        />

        <Ubox
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="space-between"
          style={{ height: '72px' }}
        >
          <Typography variant="body2" className="codigo-guia">
            <b>Código: {codigo}</b>
          </Typography>
          <Typography variant="body2" align="right">
            {`Esta proposta foi gerada em ${formatarDataLocal(
              criado
            )} às ${formatarHoraLocal(criado)}`}
          </Typography>
        </Ubox>
      </Ubox>
      <Ubox className="main" >{children}</Ubox>

      <Ubox className="footer">
        <Ubox className="box-footer">
          <Ubox>
            <div
              className="logo-footer"
              style={{
                backgroundImage: 'url(image/logo-2.png)'
              }}
            />
          </Ubox>
          <Ubox
            className="box-qrcode"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            p={2}
          >
            <Ubox>
              <Typography variant="body2" align="center">
                <b>
                  Acesse nossa
                  <br />
                  plataforma
                </b>
                <br />
                <RiArrowDownSFill />
              </Typography>
            </Ubox>
            <img
              src="https://arquivosyn.blob.core.windows.net/imagens-syn/QRCode.png"
              style={{
                textAlign: 'center',
                width: '100px',
                margin: '0 auto'
              }}
            />
          </Ubox>
          <Ubox className="link-syn">
            {`Esta guia foi impressa em ${formatarData(
              dataHoraLocal
            )} ás ${formatarHora(dataHoraLocal)}`}
          </Ubox>
          <Ubox
            className="info"
            align="right"
            // render={
            //   perfilAtual === SynPerfis.MEDICO ||
            //   perfilAtual === SynPerfis.PACIENTE
            // }
          >
            {' '}
            <i>
              Caso sejam solicitados itens extra orçamento supracitado, o
              pagamento por estes produtos/serviços deverá ser alinhado
              diretamente pelo(a) responsável/paciente para com o(s) parceiro(s)
              responsável(eis).
            </i>
          </Ubox>
        </Ubox>
        <Ubox>
          <Typography variant="body2" align="left">
            <br />
            <b>Horário de funcionamento:</b>
            <br />
            Segunda a sexta das 08h ás 18h
            <br />
            <b>Telefone:</b>
            <br />
            (98) 99229-3099
            <br /> <br />
            <b> www.synsaude.com.br</b>
          </Typography>
        </Ubox>
      </Ubox>
    </div>
  )
}
