import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { obterLogStatusSolicitacaoOrcamento } from 'src/api/solicitacao-orcamento'
import ChipStatusOrcamento from 'src/components/solicitacao/ChipStatusOrcamento'
import Drawer from 'src/components/ui/Drawer'
import formatarCPF from 'src/core/formatacoes/formatarCPF'
import { formatarDataHoraLocal } from 'src/core/formatacoes/formatarData'
import { primeiroNome } from 'src/core/helpers/common'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { LogStatusSolicitacaoOrcamentoResponse } from 'src/types'
import { RiHospitalFill, RiStethoscopeLine } from 'react-icons/ri'
import { FaHandHoldingHeart } from 'react-icons/fa'
import { AiOutlineNotification } from 'react-icons/ai'
import useAuth from 'src/hooks/useAuth'
import UBox from 'src/components/ui/Ubox'
import SynTipoParceiro from 'src/core/consts/synTipoParceiro'

const useStyles = makeStyles({
  resposta: {
    // border: '1px solid #aac7e3',
    borderRadius: '10px',
    borderBottomLeftRadius: '0',
    background: '#f3f9ff',
    padding: '8px'
  },
  ocorrencia: {
    borderRadius: '10px',
    borderBottomRightRadius: '0',
    background: '#defbcd',
    padding: '8px'
  },

  badge: {
    width: '28px',
    height: '28px',
    lineHeight: 0,
    // background: '#eee',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
    // border: '1px solid #ddd'
  },
  iconBadge: { width: '20px', height: 'auto', color: '#373737' },
  edited: {
    textAlign: 'right',
    color: '#a1a1a1',
    fontStyle: 'italic',
    fontWeight: 500,
    '& b': {
      fontWeight: 500
      // color: '#808080'
    }
  }
})

type Props = {
  id: string
  open: boolean
  handleOnClose: () => void
}

function DrawerLogStatus(props: Props) {
  const [loading, setLoading] = useState(true)
  const [listaLogStatus, setListaLogStatus] = useState<
    LogStatusSolicitacaoOrcamentoResponse[]
  >([])

  const styles = useStyles()
  const { signOut, user, perfilAtual } = useAuth()

  useEffect(() => {
    if (!props.id) {
      return
    }
    popularLogStatus()
  }, [props.open])

  async function popularLogStatus() {
    try {
      setListaLogStatus([])
      setLoading(true)
      let response = await obterLogStatusSolicitacaoOrcamento(
        props.id,
        perfilAtual
      )

      setListaLogStatus(response?.data || [])
      setLoading(false)
    } catch (e) {
      showNotificationErrorAPI(e)
      props.handleOnClose()
    }
  }

  return (
    <Drawer
      loading={loading}
      anchor="right"
      open={props.open}
      titulo="Histórico da solicitaçao de orçamento"
      handleOnClose={props.handleOnClose}
    >
      {listaLogStatus.length === 0 && (
        <Box mt={2}>
          <Typography variant="subtitle1">Nenhum registro</Typography>
        </Box>
      )}
      {listaLogStatus.length > 0 && (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px'
            }}
          >
            <RiStethoscopeLine
              style={{ fontSize: '20px', marginRight: '4px' }}
            />
            <span> Médico recebeu ocorrência</span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px'
            }}
          >
            <RiHospitalFill style={{ fontSize: '20px', marginRight: '4px' }} />
            <span> Hospital recebeu ocorrência</span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px'
            }}
          >
            <FaHandHoldingHeart
              style={{ fontSize: '20px', marginRight: '4px' }}
            />
            <span> Fornecedor OPME recebeu ocorrência</span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '16px'
            }}
          >
            <AiOutlineNotification
              style={{ fontSize: '20px', marginRight: '4px' }}
            />
            <span> Enviou notificação para parceiros</span>
          </div>
          <div>
            <UBox display="flex" alignItems="center">
              <span
                style={{
                  display: 'inline-block',
                  marginRight: '5px',
                  background: '#defbcd',
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%'
                }}
              />
              <span>Enviado pela equipe Syn</span>
            </UBox>

            <UBox mt={1} mb={2} display="flex" alignItems="center">
              <span
                style={{
                  display: 'inline-block',
                  marginRight: '5px',
                  background: '#f3f9ff',
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%'
                }}
              />
              <span>Enviado por médico, parceiro ou paciente</span>
            </UBox>

            <div></div>
          </div>
          <Divider />

          <Box mt={2}>
            {listaLogStatus.map((item, index) => (
              <div key={index}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <ChipStatusOrcamento statusId={item.statusId} />

                  <div style={{ display: 'flex', gap: '8px' }}>
                    {item.medicoVisualiza && (
                      <Tooltip title="Médico pode visualizar essa ocorrência">
                        <div className={styles.badge}>
                          <RiStethoscopeLine className={styles.iconBadge} />
                        </div>
                      </Tooltip>
                    )}

                    {item.hospitalVisualiza && (
                      <Tooltip title="Hospital pode visualizar essa ocorrência">
                        <div className={styles.badge}>
                          <RiHospitalFill className={styles.iconBadge} />
                        </div>
                      </Tooltip>
                    )}
                    {item.opmeVisualiza && (
                      <Tooltip title="Fornecedor OPME pode visualizar essa ocorrência">
                        <div className={styles.badge}>
                          <FaHandHoldingHeart className={styles.iconBadge} />
                        </div>
                      </Tooltip>
                    )}

                    {item.enviouNotificacaoParceiro && (
                      <Tooltip title="Parceiros foram notificados nessa ocorrência">
                        <div className={styles.badge}>
                          <AiOutlineNotification className={styles.iconBadge} />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>

                {item.listaParceiros && (
                  <Box mt={1}>
                    <Grid container>
                      {item.listaParceiros?.filter(
                        w =>
                          w.idTipoParceiro ===
                          SynTipoParceiro.HOSPITAL_CLINICA.id
                      ).length > 0 && (
                        <Grid item xs={12}>
                          <Typography variant="subtitle2" color="primary">
                            Hospitais que visualizam:
                          </Typography>
                          <Typography variant="body2">
                            {item.listaParceiros
                              ?.filter(
                                w =>
                                  w.idTipoParceiro ===
                                  SynTipoParceiro.HOSPITAL_CLINICA.id
                              )
                              .map(e => e.nome)
                              .join(', ')}
                          </Typography>
                        </Grid>
                      )}
                      {item.listaParceiros?.filter(
                        w => w.idTipoParceiro === SynTipoParceiro.OPME.id
                      ).length > 0 && (
                        <Grid item xs={12}>
                          <Typography variant="subtitle2" color="primary">
                            Fornecedores que visualizam:
                          </Typography>
                          <Typography variant="body2">
                            {item.listaParceiros
                              ?.filter(
                                w =>
                                  w.idTipoParceiro === SynTipoParceiro.OPME.id
                              )
                              .map(e => e.nome)
                              .join(', ')}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                )}

                <Box
                  mt={1}
                  className={
                    item.ehExterno ? styles.resposta : styles.ocorrencia
                  }
                  style={{ whiteSpace: 'pre-line' }}
                >
                  <Typography variant="body2">
                    <small>
                      {item.ehExterno ? 'Resposta ocorrência:' : 'Ocorrência:'}
                    </small>
                    <br />
                    {item.observacao || 'Nenhuma ocorrência informada'}{' '}
                  </Typography>
                </Box>

                <Box mt={1}>
                  <Grid container>
                    <Grid item xs={12} className={styles.edited}>
                      <small>
                        {/* Alterado  */}
                        por
                        <b>
                          {item.criadoPor
                            ? ` ${item.ehMedico ? 'Dr(a).' : ''} ${
                                item.criadoPor
                              } `
                            : formatarCPF(item.criadoPorCpf)}
                        </b>
                        em <b>{formatarDataHoraLocal(item.data)}</b>
                      </small>
                    </Grid>
                  </Grid>
                </Box>
                <Box my={2}>
                  <Divider />
                </Box>
              </div>
            ))}
          </Box>
        </>
      )}
    </Drawer>
  )
}

export default DrawerLogStatus
