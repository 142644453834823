import {
  Box,
  Card,
  CardHeader,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { excluirAnotacaoMedico, salvarAnotacao } from 'src/api/medico'
import Button from 'src/components/ui/Button'
import Drawer from 'src/components/ui/Drawer'
import { obterNomeSobrenome } from 'src/core/helpers/common'
import { showNotificationErrorAPI } from 'src/core/helpers/notification'
import { ItemAnotacaoMedico } from 'src/types'
import Swal from 'sweetalert2'
import { formatarDiferencaData } from 'src/core/formatacoes/formatarData'
import { SynPerfis, SynPerfisArray } from 'src/core/consts/synPerfis'
import useAuth from 'src/hooks/useAuth'

const useStyles = makeStyles({
  date: {
    color: '#717171'
  },
  note: {
    padding: '0 10px'
  },
  comment: {
    margin: '0 10px'
  }
})

interface Props {
  open: boolean
  handleOnClose: () => void
  medicoId: string
  anotacoes: ItemAnotacaoMedico[]
  carregarListagem: any
}

function DrawerObs(props: Props) {
  const [addObs, setAddObs] = useState(false)
  const [isSubmit, toggleSubmit] = useState(false)
  const [anotacao, setAnotacao] = useState<ItemAnotacaoMedico>(null)
  const { signed, user, perfilAtual, signOut, setPerfilAtual } = useAuth()

  const styles = useStyles()

  useEffect(() => {
    if (props.open === true) {
      setAddObs(false)
    }
  }, [props.open])

  function handleNovaObs() {
    setAddObs(true)
  }

  function handleCloseObs() {
    setAnotacao(null)
    props.handleOnClose()
  }

  function handleChangeInput(e) {
    setAnotacao({
      id: anotacao?.id,
      descricao: e.target.value
    })
  }

  function handleSalvar() {
    toggleSubmit(true)

    salvarAnotacao(props.medicoId, anotacao)
      .then(() => {
        setAnotacao(null)
        props.handleOnClose()
        props.carregarListagem({ resetarPaginacao: true })
      })
      .catch(e => showNotificationErrorAPI(e))
      .finally(() => toggleSubmit(false))
  }

  function handleEdit(item: ItemAnotacaoMedico) {
    setAddObs(true)
    setAnotacao(item)
  }

  async function handleExcluir(item: ItemAnotacaoMedico) {
    var result = await Swal.fire({
      title: 'Deseja excluir a anotação?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          return await excluirAnotacaoMedico(item.id)
        } catch (e) {
          showNotificationErrorAPI(e)
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    })

    if (result.isDismissed) {
      return
    }

    if (result.value) {
      Swal.fire('Anotação exluída com sucesso!', '', 'success')
      props.handleOnClose()
      props.carregarListagem({ resetarPaginacao: true })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text:
          'Não foi possível excluir a anotação. Tente novamente mais tarde ou contate o suporte.'
      })
    }
  }

  return (
    <Drawer
      anchor="right"
      open={props.open}
      titulo="Visualizar anotações"
      handleOnClose={handleCloseObs}
    >
      <Box mt={3}>
        {addObs ? (
          <>
            <Box mt={2}>
              <TextField
                fullWidth
                label="Anotações"
                multiline
                rows={3}
                placeholder="Adicionar anotação"
                variant="outlined"
                value={anotacao?.descricao}
                InputLabelProps={{ shrink: true }}
                onChange={handleChangeInput}
              />
            </Box>

            <Box mt={3}>
              <Button
                fullWidth
                onClick={() => {
                  handleSalvar()
                }}
                variant="contained"
                color="primary"
                loading={isSubmit}
                disabled={isSubmit}
              >
                Salvar
              </Button>
            </Box>
          </>
        ) : (
          <>
            {perfilAtual !== SynPerfis.PARCEIRO_EXTERNO && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleNovaObs}
                startIcon={<AddIcon />}
              >
                Adicionar nova anotação
              </Button>
            )}
          </>
        )}

        {props.anotacoes &&
          props.anotacoes?.map(item => (
            <Box key={`${item.id}`} mt={2}>
              <Card style={{ padding: 5 }}>
                <CardHeader
                  action={
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {popupState => (
                        <React.Fragment>
                          <>
                            {perfilAtual !== SynPerfis.PARCEIRO_EXTERNO && (
                              <>
                                <IconButton
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  aria-label="mais"
                                  {...bindTrigger(popupState)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                  }}
                                  {...bindMenu(popupState)}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      handleEdit(item)
                                    }}
                                  >
                                    Editar
                                  </MenuItem>
                                  <MenuItem
                                    className="danger"
                                    onClick={() => {
                                      handleExcluir(item)
                                    }}
                                  >
                                    Excluir
                                  </MenuItem>
                                </Menu>
                              </>
                            )}
                          </>
                        </React.Fragment>
                      )}
                    </PopupState>
                  }
                  title={
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="space-between"
                      className={styles.note}
                    >
                      <Typography variant="subtitle2" component="span">
                        {obterNomeSobrenome(item.criadoPor)}
                      </Typography>

                      <Typography
                        className={styles.date}
                        variant="subtitle2"
                        component="h3"
                      >
                        há {formatarDiferencaData(item.dataModificado)}
                      </Typography>
                    </Grid>
                  }
                  subheader={
                    <Typography className={styles.comment} variant="body2">
                      {item.descricao}
                    </Typography>
                  }
                />
              </Card>
            </Box>
          ))}
      </Box>
    </Drawer>
  )
}

export default DrawerObs
