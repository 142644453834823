import { AxiosResponse } from 'axios'

import api from 'src/services/api'

import {
  ListagemQueryPai,
  ListagemResponse
} from 'src/types'

//#region TIPOS
export interface SalvarMembroEquipeCommand {
  id?: string
  descricao: string
  documento: string
  nomeRazaoSocial: string

  nomeBanco: string
  codigoBanco: string
  agencia: string
  digitoAgencia: string
  numeroConta: string
  digitoConta: string
  cpfCnpj: string

  telefone: string
  medicoId?: string
  parceiroId?: string

  nome: string
  tipoConta: string
  name: string
  email: string
  document: string
  birthdate: string
  monthlyIncome: number
  professionalOccupation: string
  phoneNumbers: string

  companyName?: string
  tradingName?: string
  companyEmail?: string
  companyDocument?: string
  siteUrl?: string
  annualRevenue?: number
  corporationType?: string
  foundingDate?: string
  companyPhoneNumbers?: string
  selfDeclaredLegalRepresentative?: boolean

  address: Address
  mainAddress: Address
}

interface Address {
  cep: string
  street: string
  complementary: string
  streetNumber: string
  neighborhood: string
  city: string
  state: string
  referencePoint: string
}

export interface ListagemMembroEquipeQuery extends ListagemQueryPai {
  termoPesquisa?: string
}

export interface ItemListagemMembroEquipe {
  id: string
  descricao: string
  documento: string
  nomeRazaoSocial: string
  valor?: number
}

export interface MembroEquipeResponse {
  id: string
  descricao: string
  documento: string
  nomeRazaoSocial: string
  nomeBanco: string
  codigoBanco: string
  agencia: string
  digitoAgencia: string
  numeroConta: string
  digitoConta: string
  cpfCnpj: string
  tipoConta: string
  nome: string
  email: string
  telefone: string
}

//#endregion
export const salvarMembroEquipe: (
  command: SalvarMembroEquipeCommand
) => Promise<AxiosResponse<string>> = command => {
  return api.post('/membroEquipe', command)
}

export interface SalvarContatoMembroEquipeCommand {
  id?: string
  email: string
  telefone: string
}

export const salvarContatoMembroEquipe: (
  command: SalvarContatoMembroEquipeCommand
) => Promise<AxiosResponse<string>> = command => {
  return api.post('/membroEquipe/contato', command)
}

export const listagemMembroEquipe: (
  query: ListagemMembroEquipeQuery
) => Promise<
  AxiosResponse<ListagemResponse<ItemListagemMembroEquipe>>
> = query => {
  var params = new URLSearchParams(query)

  return api.get(`/membroequipe?${params}`)
}

export const buscarTodosMembrosEquipe: (
  medicoIdOuCPF: string
) => Promise<AxiosResponse<ItemListagemMembroEquipe[]>> = (
  medicoIdOuCPF: string
) => {
    return api.get(`/membroequipe/${medicoIdOuCPF}/todos`)
  }

export const obterMembroEquipePorId: (
  id: string
) => Promise<AxiosResponse<MembroEquipeResponse>> = id => {
  return api.get(`/MembroEquipe/${id}`)
}

export const excluirMembroEquipe: (
  id: string
) => Promise<boolean> = async id => {
  await api.delete(`/MembroEquipe/${id}`)
  return true
}
