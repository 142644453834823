import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

import React from 'react'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import { useHistory } from 'react-router-dom'
import { SynRoutes } from 'src/core/consts/synRoutes'
import { ChromeReaderMode } from '@material-ui/icons'

function menuLateralSecretaria(props) {
  const history = useHistory()

  return (
    <>
      <List>
        <ListItem
          button
          onClick={() => history.push(SynRoutes.listagemOrcamentos)}
        >
          <ListItemIcon style={{ fontSize: '1.4rem' }}>
            <AssignmentOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Solicitações" />
        </ListItem>
        <ListItem
          button
          onClick={() => history.push(SynRoutes.listagemFinanciamentos)}
        >
          <ListItemIcon>
            <ChromeReaderMode />
          </ListItemIcon>
          <ListItemText primary="Financiamentos" />
        </ListItem>
      </List>
    </>
  )
}

export default menuLateralSecretaria
