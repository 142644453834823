import {
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'

import ChipStatusOrcamento from 'src/components/solicitacao/ChipStatusOrcamento'

import { SynPerfis } from 'src/core/consts/synPerfis'
import useAuth from 'src/hooks/useAuth'
import { IParceiroFuncionario } from 'src/types'
import formatarDocumento from 'src/core/formatacoes/formatarDocumento'
import formatarTelefone from 'src/core/formatacoes/formatarTelefone'

interface Props {
  data: IParceiroFuncionario[]
  handleExcluir: (id: string) => void
  handleResetarSenha: (item: IParceiroFuncionario) => void
  handleEditar: (item: IParceiroFuncionario) => void
  handleVisualizarDetalhes: (item: IParceiroFuncionario) => void
}

export default function TabelaUsuarios({
  data,
  handleExcluir,
  handleVisualizarDetalhes,
  handleEditar,
  handleResetarSenha
}: Props) {
  const history = useHistory()
  const { signOut, user, perfilAtual } = useAuth()
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>CPF</TableCell>
            <TableCell>Celular</TableCell>
            <TableCell>E-mail</TableCell>
            <TableCell>Setores</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map(item => {
            return (
              <TableRow key={item.id}>
                <TableCell>
                  {/* <Link
                    component="button"
                    variant="body1"
                    color="primary"
                    align="left"
                    onClick={() => handleVisualizarDetalhes(item)}
                  > */}
                  {item.nome}
                  {/* </Link> */}
                </TableCell>
                <TableCell>{formatarDocumento(item.cpf)}</TableCell>
                <TableCell>{formatarTelefone(item.celular)}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>
                  {item.especialidades.map(i => i.descricao).join(',')}
                </TableCell>

                <TableCell style={{ textAlign: 'right' }}>
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {popupState => (
                      <React.Fragment>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          aria-label="mais"
                          {...bindTrigger(popupState)}
                        >
                          <MoreVertIcon />
                        </IconButton>

                        <Menu
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          {...bindMenu(popupState)}
                        >
                          <MenuItem
                            onClick={() => {
                              popupState.close()
                              handleEditar(item)
                            }}
                          >
                            Editar
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              popupState.close()
                              handleResetarSenha(item)
                            }}
                          >
                            Resetar senha
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              popupState.close()
                              handleExcluir(item.id)
                            }}
                            className="danger"
                          >
                            Excluir
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
